import React, { FC } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import LinearProgress from "@material-ui/core/LinearProgress";
import { HeadlineFigureWidget } from "./HeadlineFigureWidget";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import { VTextLink } from "../../../common/VTextLink";

export const NewestProgrammeHeadlineWidget: FC = () => {
    const { item } = useFetch<ActiveProgramStats>();
    const hasNoActiveProgrammes = item?.programmes.length === 0;
    const newestProgramme = item?.programmes[item?.programmes.length - 1];
    const totalSessions = newestProgramme?._count?.Sessions ?? 0;
    const completedSessions = newestProgramme?.Sessions.length ?? 0;

    return (
        <HeadlineFigureWidget centeredHeader={false} header="Newest programme">
            {hasNoActiveProgrammes ? (
                <Box>
                    <Typography variant="h5" gutterBottom>
                        You have 0 programmes
                    </Typography>
                </Box>
            ) : (
                <>
                    <VTextLink color={"textPrimary"} to={`/programmes/view/${newestProgramme?.id}`}>
                        <Box>
                            <SpinWhileFetching>
                                <Typography variant="h5" gutterBottom>
                                    {newestProgramme?.name}
                                </Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={(completedSessions / totalSessions) * 100}
                                />
                            </SpinWhileFetching>
                        </Box>
                    </VTextLink>
                    <Box>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Box mr={1}>
                                    <SpinWhileFetching>
                                        <Typography variant="h2">
                                            {newestProgramme?._count?.ParticipantToProgramme}
                                        </Typography>
                                    </SpinWhileFetching>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    Participants <br /> enrolled
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
        </HeadlineFigureWidget>
    );
};
