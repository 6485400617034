import { createStyles, makeStyles } from "@material-ui/core";
import { verbalTheme } from "../themes/verbal.theme";

export const drawerWidth = 220;

const styles = createStyles({
    main: {
        flex: 1,
        background: verbalTheme.palette.background.default,
    },
    infoSection: {
        [verbalTheme.breakpoints.down("sm")]: {
            display: "none",
        },
    },
});

export const useLoginLayoutStyle = makeStyles(styles);
