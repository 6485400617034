import React, { FC } from "react";
import { StackedBarChartWidget } from "./StackedBarChartWidget";
import { Typography } from "@material-ui/core";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { VTextLink } from "../../../common/VTextLink";
import { useHistory } from "react-router-dom";
import { VictoryBar, VictoryLabel, VictoryTheme, VictoryTooltip } from "victory";
import { useAuth } from "../../../../contexts/auth.context";
import { Alert } from "@material-ui/lab";

export const ActiveProgrammeSessionsBarWidget: FC<{ isFacilitator?: boolean }> = ({ isFacilitator }) => {
    const { item } = useFetch<ActiveProgramStats>();
    const { hasPermission } = useAuth();
    const hasProgrammes = item?.programmes && item.programmes.length > 0 ? true : false;
    const programmes = item?.programmes;
    const history = useHistory();
    const labelData = {} as Record<string, unknown>;
    const chartData = [
        programmes?.map((programme, id) => ({
            x: `${programme.name} (${id + 1})`,
            y: (programme.Sessions.length / (programme._count?.Sessions ?? 0)) * 100,
            programmeId: programme.id,
            sessionsComplete: programme.Sessions.length,
        })),
        programmes?.map((programme, id) => ({
            x: `${programme.name} (${id + 1})`,
            y: 100 - (programme.Sessions.length / (programme._count?.Sessions ?? 0)) * 100,
            programmeId: programme.id,
            sessionsRemaining: (programme._count?.Sessions ?? 0) - programme.Sessions.length,
        })),
    ];
    const legend = [
        { name: "Completed", symbol: { fill: "#1EAE8C", type: "square" } },
        { name: "Remaining", symbol: { fill: "#131739", type: "square" } },
    ];
    if (programmes) {
        for (let id = 0; id < programmes.length; id++) {
            const programme = programmes[id];
            labelData[`${programme.name} (${id + 1})`] = programme.id;
        }
    }

    return (
        <StackedBarChartWidget
            programmeCount={programmes?.length ?? 0}
            legend={legend}
            xAxisLabelComponent={
                <VictoryLabel
                    style={[
                        {
                            fontSize: 10,
                            fontWeight: "bold",
                            cursor: "pointer",
                        },
                    ]}
                />
            }
            xAxisEvents={[
                {
                    target: "tickLabels",
                    eventHandlers: {
                        onClick: (_event: unknown, data: { text: string; data: never[] }) => {
                            history.push({
                                pathname: `/programmes/view/${labelData[data?.text]}/`,
                                search: hasPermission("ProgrammeReportView") ? "?tab=data" : undefined,
                            });
                        },
                    },
                },
            ]}
            barCharts={chartData.map((chartData, index) => (
                <VictoryBar
                    groupComponent={<g style={{ cursor: "pointer" }} />}
                    key={index}
                    theme={VictoryTheme.material}
                    data={chartData}
                    events={[
                        {
                            target: "data",
                            eventHandlers: {
                                onClick: (_event, data) => {
                                    history.push(`/programmes/view/${data.datum.programmeId}`);
                                },
                            },
                        },
                    ]}
                    labels={({ datum }) => {
                        return `${datum.x}: \n ${
                            datum.sessionsRemaining
                                ? `${datum.sessionsRemaining} ${
                                      datum.sessionsRemaining === 1 ? `Session` : `Sessions`
                                  } Remaining`
                                : `${datum.sessionsComplete} ${
                                      datum.sessionsComplete === 1 ? `Session` : `Sessions`
                                  } Complete`
                        }`;
                    }}
                    labelComponent={
                        <VictoryTooltip
                            cornerRadius={() => 4}
                            flyoutPadding={4}
                            flyoutStyle={{
                                fill: "white",
                                stroke: "#e3e3e3",
                                fontSize: 6,
                            }}
                            constrainToVisibleArea
                        />
                    }
                />
            ))}
            spinWhileFetching
            header="Active Programme Sessions"
            subHeader={<Alert severity="info">Click the programme name to view programme data</Alert>}
            hasNoData={!hasProgrammes}
            noDataMessage={
                <>
                    <Typography variant={"body1"}>
                        Follow progress on active sessions and easily see how far along and how much is left of a
                        programme
                    </Typography>
                    <Typography variant={"body2"}>
                        To see this you must first{" "}
                        {isFacilitator ? (
                            "create a programme "
                        ) : (
                            <VTextLink to="/programmes/add">schedule a programme </VTextLink>
                        )}
                    </Typography>
                </>
            }
        />
    );
};
