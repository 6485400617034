import {
    Box,
    Button,
    Typography,
    Divider,
    IconButton,
    Card,
    CardContent,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { format } from "date-fns";
import React, { useState, useEffect, useRef } from "react";
import { SessionType } from "../../../models/modelTypes";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { API_URL } from "../../../client/api/rest";
import { sortSessions } from "../../../utils/sorts";
import { useAuth } from "../../../contexts/auth.context";
import { Create } from "@material-ui/icons";
import { checkIfPrepared } from "../../../client/api/session";
import { DestructiveButton, PrimaryButton, SecondaryButton } from "../../layout/Button/Button";
import { useSetTourState } from "../../tour/Store";

interface NextSessionCardProps {
    sessions: SessionType[];
    classroomId?: number;
}

export const NextSessionCard: React.FC<NextSessionCardProps> = (props: NextSessionCardProps) => {
    const { hasPermission, user } = useAuth();
    const [admitted, setAdmitted] = useState(false);
    const { sessions, classroomId } = props;
    const orderedSessions = [...sessions].sort(sortSessions);

    const idx = orderedSessions.findIndex((p) => p.state === "InProgress" || p.state === "NotStarted");
    const nextSession = orderedSessions[idx];

    const history = useHistory();

    const [hasPrepared, setHasPrepared] = useState<boolean>(false);
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [openNotPreparedDialog, setOpenNotPreparedDialog] = useState<boolean>(false);

    const setTourState = useSetTourState();
    const refPrepare = useRef<HTMLButtonElement | null>(null);
    const refDeliver = useRef<HTMLButtonElement | null>(null);
    const refStartSession = useRef<HTMLDivElement | null>(null);
    const refCancelStartSession = useRef<HTMLDivElement | null>(null);
    const refStartSession2 = useRef<HTMLDivElement | null>(null);
    const refCancelStartSession2 = useRef<HTMLDivElement | null>(null);

    const url =
        user?.Organisation?.country === "Republic of Ireland"
            ? nextSession?.MappedStory?.Story?.resourcesROI
            : nextSession?.MappedStory?.Story?.resources;

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("refPrepare", refPrepare) }));
    }, [refPrepare]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("deliverSessionBtn", refDeliver) }));
    }, [refDeliver, refDeliver.current]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("startSessionBtn", refStartSession) }));
    }, [refStartSession, refStartSession.current]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("cancelStartSessionBtn", refCancelStartSession) }));
    }, [refCancelStartSession, refCancelStartSession.current]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("startSessionBtn2", refStartSession2) }));
    }, [refStartSession2]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("cancelStartSessionBtn2", refCancelStartSession2) }));
    }, [refCancelStartSession2]);

    useEffect(() => {
        if (nextSession && nextSession.id) {
            checkIfPrepared(nextSession.id).then(async (res) => {
                setHasPrepared(res);
            });
        }
    }, [nextSession]);

    useEffect(() => {
        const events = new EventSource(`${API_URL}/session/${nextSession.id}/updates`, {
            withCredentials: true,
        });

        events.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setAdmitted(data.isUsersAdmitted);
        };

        return () => {
            events.close();
        };
    }, []);

    const useStyles = makeStyles(() =>
        createStyles({
            borderPos: {
                border: "2px solid",
                borderColor: verbalTheme.palette.primary.main,
            },
            iconButton: {
                padding: "0px",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Box mb={2}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                    Next up
                </Typography>
            </Box>
            <React.Fragment key={nextSession.id}>
                <Card className={classes.borderPos}>
                    <CardContent>
                        <Box display="flex" justifyContent="space-between">
                            <Typography variant="h6" color={"textSecondary"} gutterBottom>
                                Session {nextSession.sessionNumber}
                            </Typography>
                            {nextSession.state !== "Complete" && hasPermission("SessionReschedule") && (
                                <IconButton
                                    id="edit-session"
                                    className={classes.iconButton}
                                    onClick={() => history.push(`/sessions/edit/${nextSession.id}`)}
                                >
                                    <Create color="action" fontSize="small" />
                                </IconButton>
                            )}
                        </Box>

                        <Typography variant="h6" color={"textSecondary"} gutterBottom>
                            Status: {admitted ? "Participants Admitted" : "Not Started"}
                        </Typography>

                        <Box mb={2} id="session-story">
                            <Typography variant="h6">{nextSession.MappedStory?.Story?.storyName}</Typography>
                            <Typography variant="body2" color="textSecondary">
                                By {nextSession.MappedStory?.Story?.author}
                            </Typography>
                        </Box>

                        <Box mb={2}>
                            <Divider light />
                        </Box>

                        <Box mb={2} id="session-date">
                            <Typography variant="body1">
                                {format(Date.parse(nextSession.date.toLocaleString()), "EE")}
                            </Typography>
                            <Typography variant="h4">
                                {format(Date.parse(nextSession.date.toLocaleString()), "d MMM")}
                            </Typography>
                            <Typography variant="body2">
                                {format(Date.parse(nextSession.date.toLocaleString()), "YYY")} at{" "}
                                {format(Date.parse(nextSession.date.toLocaleString()), "HH:mm")}
                            </Typography>
                        </Box>

                        <Box mb={1} id="prepare-session">
                            <Button
                                ref={refPrepare}
                                variant={"outlined"}
                                color={"primary"}
                                fullWidth
                                onClick={() =>
                                    history.push(`/prepare-session/${nextSession.id}`, { classroomId: classroomId })
                                }
                            >
                                Prepare Session
                            </Button>
                        </Box>

                        <Box mb={1} id="deliver-session">
                            <Button
                                ref={refDeliver}
                                variant={"contained"}
                                color={"primary"}
                                fullWidth
                                onClick={() => {
                                    setOpenConfirmDialog(true);
                                }}
                            >
                                Deliver Session
                            </Button>
                        </Box>
                        {url ? (
                            <Box textAlign="center" id="session-resources">
                                <Button
                                    fullWidth
                                    color="secondary"
                                    variant="outlined"
                                    href={url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Learning Resources
                                </Button>
                            </Box>
                        ) : null}
                    </CardContent>
                </Card>
            </React.Fragment>
            <Dialog
                open={openNotPreparedDialog}
                onClose={() => setOpenNotPreparedDialog(false)}
                PaperProps={{ className: "openSessionNotPreparedDialog" }}
            >
                <DialogTitle>Are you sure you would like to deliver the session without preparing?</DialogTitle>
                <DialogContent>
                    It appears you have not prepared this session. It is highly recommended to prepare each session
                    before delivering it to a live group as there is a lot of information in the prepare page that is
                    not shown when delivering the session. You can continue to deliver the session without preparing but
                    this may effect how smoothly the session goes.
                </DialogContent>
                <DialogActions>
                    <div ref={refStartSession2}>
                        <DestructiveButton
                            onClick={() => {
                                if (classroomId !== undefined) {
                                    history.push(`/classrooms/start-session/${nextSession?.id}`);
                                } else {
                                    history.push(`/start-session/${nextSession?.id}`);
                                }
                            }}
                            className="openSessionSkipPrepareButton"
                        >
                            Continue to Session
                        </DestructiveButton>
                    </div>
                    <PrimaryButton onClick={() => history.push(`/prepare-session/${nextSession.id}`)}>
                        Prepare Session Now
                    </PrimaryButton>
                    <div ref={refCancelStartSession2}>
                        <SecondaryButton onClick={() => setOpenNotPreparedDialog(false)}>Cancel</SecondaryButton>
                    </div>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openConfirmDialog}
                onClose={() => setOpenConfirmDialog(false)}
                PaperProps={{ className: "openSessionConfirmDialog" }}
            >
                <DialogTitle>Are you sure you would like to start?</DialogTitle>
                <DialogContent>If you open your session now you will be unable to access this again.</DialogContent>
                <DialogActions>
                    <div ref={refStartSession} className="refStartSession">
                        <DestructiveButton
                            onClick={() => {
                                if (!hasPrepared) {
                                    setOpenConfirmDialog(false);
                                    setOpenNotPreparedDialog(true);
                                } else {
                                    if (classroomId !== undefined) {
                                        history.push(`/classrooms/start-session/${nextSession.id}`, {
                                            classroomId: classroomId,
                                        });
                                    } else {
                                        history.push(`/start-session/${nextSession?.id}`);
                                    }
                                }
                            }}
                            className="openSessionContinueButton"
                        >
                            Continue to Session
                        </DestructiveButton>
                    </div>
                    <div ref={refCancelStartSession} /* className="refCancelStartSession" */>
                        <SecondaryButton onClick={() => setOpenConfirmDialog(false)}>Cancel</SecondaryButton>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    );
};
