import { useCallback, useState } from "react";
import { getCurriculums, getCurriculumById, add, list, get, update } from "../client/api/curriculum";
import { CurriculumType } from "../models/modelTypes";

interface CurriculumI {
    loading: boolean;
    curriculumInfo?: CurriculumType;
    curriculums?: CurriculumType[];

    getCurriculum: (id: number) => Promise<void>;
    getAllCurriculums: (ageRange: string) => Promise<void>;
    listAll: () => Promise<void>;
    getCurriculumInfo: (ageRange: string, curriculumId: number) => Promise<void>;
    addCurriculum: (
        curriculum: Partial<CurriculumType>,
        // weeks: { order: number; learningObjective: string; themeId: number }[],
    ) => Promise<void>;
    updateCurriculum: (id: number, curriculum: Partial<CurriculumType>) => Promise<void>;
}

/**
 * Custom hook to get, list and add curriculums
 */
export const useCurriculum = (): CurriculumI => {
    const [loading, setLoading] = useState<boolean>(false);
    const [curriculumInfo, setCurriculumInfo] = useState<CurriculumType>();
    const [curriculums, setCurriculums] = useState<CurriculumType[]>([]);

    const listAll = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            list({ take: "1000", includeCollections: true })
                .then(async (response) => {
                    setCurriculums(response.items);
                    resolve();
                })
                .catch(() => {
                    setCurriculums([]);
                    reject("Unable to retrieve curriculums");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getAllCurriculums = useCallback((ageRange: string) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            getCurriculums(ageRange)
                .then(async (response: CurriculumType[]) => {
                    setCurriculums(response);
                    resolve();
                })
                .catch((error) => {
                    setCurriculums([]);
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const getCurriculumInfo = useCallback((ageRange: string, curriculumId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            getCurriculumById(ageRange, curriculumId)
                .then(async (response: CurriculumType) => {
                    setCurriculumInfo(response);
                    resolve();
                })
                .catch((error) => {
                    setCurriculumInfo(undefined);
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const addCurriculum = useCallback(
        (
            curriculum: Partial<CurriculumType>,
            // weeks: { order: number; learningObjective: string; themeId: number }[],
        ) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                add(curriculum)
                    .then(async (response) => {
                        if (response.ok) {
                            const curriculumResponse: CurriculumType = await response.json();
                            setCurriculumInfo(curriculumResponse);
                            resolve();
                        } else {
                            setCurriculumInfo(undefined);
                            reject("Unable to add curriculum");
                        }
                    })
                    .catch(() => {
                        setCurriculumInfo(undefined);
                        reject("Unable to add curriculum");
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const getCurriculum = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            get(id, { includeCollections: true, includeThemes: true })
                .then(async (response: CurriculumType) => {
                    setCurriculumInfo(response);
                    resolve();
                })
                .catch(() => {
                    setCurriculumInfo(undefined);
                    reject();
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const updateCurriculum = useCallback(
        (
            id: number,
            curriculum: Partial<CurriculumType>,
            // weeks: { order: number; learningObjective: string; themeId: number }[],
        ) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                update(id, curriculum)
                    .then(async (response) => {
                        if (response.ok) {
                            const curriculumResponse: CurriculumType = await response.json();
                            setCurriculumInfo(curriculumResponse);
                            resolve();
                        } else {
                            setCurriculumInfo(undefined);
                            reject("Unable to add curriculum");
                        }
                    })
                    .catch(() => {
                        setCurriculumInfo(undefined);
                        reject("Unable to add curriculum");
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    return {
        loading,
        curriculums,
        curriculumInfo,
        getAllCurriculums,
        getCurriculumInfo,
        addCurriculum,
        listAll,
        getCurriculum,
        updateCurriculum,
    };
};
