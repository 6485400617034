import React, { FC } from "react";
import { Grid } from "@material-ui/core";
import { useGridSharedStyles } from "./GridShared.style";
import { GridProps } from "@material-ui/core/Grid/Grid";

export const VGridContainer: FC<GridProps> = (props) => {
    const classes = useGridSharedStyles();
    const { children, ...rest } = props;
    return (
        <Grid container {...rest} className={classes.container}>
            {children}
        </Grid>
    );
};
