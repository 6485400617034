import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./components/routing/Routes";

import "./App.css";
import ApplicationProvider from "./contexts/app.provider";
import ParticipantRoutes from "./components/routing/participant/Routes";
import { ParticipantAuthProvider } from "./contexts/participant-auth.context";
import { InvitationPage } from "./pages/invitationPage";
import { PublicLayout } from "./components/layout/public/PublicLayout";
import { ParticipantRoute } from "./components/routing/participant/ParticipantRoute";
import { ScrollToTop } from "./utils/ScrollToTop";

export default function App(): React.ReactElement {
    return (
        <Router>
            <Fragment>
                <Switch>
                    <ParticipantAuthProvider>
                        <ParticipantRoute
                            isPublic
                            layout={PublicLayout}
                            path="/"
                            exact
                            component={() => <InvitationPage isSignIn />}
                        />
                        <Route component={ParticipantRoutes} />
                    </ParticipantAuthProvider>
                </Switch>

                <Switch>
                    <ApplicationProvider>
                        <ScrollToTop />
                        <Route component={Routes} />
                    </ApplicationProvider>
                </Switch>
            </Fragment>
        </Router>
    );
}
