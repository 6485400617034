import { Box, Grid, GridProps, Typography } from "@material-ui/core";
import React from "react";

type CheckInMoodCardProps = GridProps & {
    title: string;
    img: string;
    selected?: string;
    selectMood: () => void;
};

export const CheckInMoodCard: React.FC<CheckInMoodCardProps> = (props: CheckInMoodCardProps): JSX.Element => {
    const { title, img, selected, selectMood, ...gridProps } = props;
    return (
        <Grid
            {...gridProps}
            item
            justifyContent="center"
            style={{
                display: "flex",
                cursor: "pointer",
                backgroundColor: `${selected === title ? "grey" : "transparent"}`,
            }}
        >
            <Box display="flex" flexDirection={"column"} justifyContent="center" onClick={selectMood}>
                <img src={img} height="150px" width={"150px"} />
                <Typography variant="h4" align="center">
                    <strong>{title}</strong>
                </Typography>
            </Box>
        </Grid>
    );
};
