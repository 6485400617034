import { useMediaQuery } from "@material-ui/core";
import { useEffect, useState } from "react";
import { verbalTheme } from "../components/layout/themes/verbal.theme";

type MobileProps = {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
};

/**
 * Hook to get current screen size
 * isMobile is true if screen size below 600px
 * isTable is true if screen size is between 600px and 900px
 * isDesktop is true id screen size is above 1200px
 */
export const useMobile = (): MobileProps => {
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const [isTablet, setIsTablet] = useState<boolean>(false);
    const [isDesktop, setIsDesktop] = useState<boolean>(false);

    const matchesXs = useMediaQuery(verbalTheme.breakpoints.down("sm"));
    const matchesMd = useMediaQuery(verbalTheme.breakpoints.between("sm", "md"));
    const matchesLg = useMediaQuery(verbalTheme.breakpoints.up("lg"));

    useEffect(() => {
        setIsMobile(matchesXs);
        setIsTablet(matchesMd);
        setIsDesktop(matchesLg);
    }, [matchesXs, matchesMd, matchesLg]);

    return { isMobile, isTablet, isDesktop };
};
