import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Backdrop, Box, Fade, Modal } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

type StoryImageProps = {
    url: string;
    alt?: string;
};

const useStyles = makeStyles(
    createStyles({
        storyImageContainer: {
            position: "sticky",
            top: 0,
        },
        imageContainer: {
            backgroundColor: "#FFFFFF",
        },
        image: {
            width: "100%",
            maxHeight: "500px",
            objectFit: "contain",
            cursor: "pointer",
        },
        imageBottomFadeBar: {
            width: "100%",
            height: "35px",
            background: "linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0))",
        },
        imageBottomStopBar: {
            width: "100%",
            height: 0,
        },
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
                backgroundcolor: "red",
            },
        },
        fadeImg: {
            outline: "none",
        },
    }),
);

function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

export const StoryImage = (props: StoryImageProps): JSX.Element => {
    const { url, alt } = props;
    const [open, setOpen] = useState(false);
    const [image, setImage] = useState("false");
    const [loading, setLoading] = useState<boolean>(true);
    const [imageUrl, setImageUrl] = useState("");
    const classes = useStyles();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    const handleClose = () => {
        setOpen(false);
    };

    const handleImage = (value: string) => {
        setImage(value);
        setOpen(true);
        console.log(image);
    };

    if (imageUrl != url) {
        setImageUrl(url);
        setLoading(true);
    }

    return (
        <>
            <Box className={windowSize.innerHeight > 900 ? classes.storyImageContainer : ""}>
                <Box pb={1} pt={1} className={classes.imageContainer}>
                    <div style={{ display: loading ? "block" : "none" }}>
                        <Skeleton variant="rect" animation="wave" width={754} height={430} />
                    </div>
                    <div style={{ display: loading ? "none" : "block" }}>
                        <img
                            src={url}
                            alt={alt}
                            key={url}
                            className={classes.image}
                            onClick={() => handleImage(url)}
                            onLoad={() => {
                                setLoading(false);
                            }}
                        />
                    </div>
                </Box>
                <Box className={classes.imageBottomFadeBar}></Box>
                <Box className={classes.imageBottomStopBar}></Box>
            </Box>
            <Modal
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open} timeout={500}>
                    <img
                        src={url}
                        alt={alt}
                        key={url}
                        style={{ maxHeight: "90%", maxWidth: "90%" }}
                        className={classes.fadeImg}
                    />
                </Fade>
            </Modal>
        </>
    );
};
