import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useAuth } from "../../../../contexts/auth.context";
import { SimpleBlankSlate } from "../../../../core/components/SimpleBlankSlate";
import { HeadlineFigureWidget } from "../../../dashboard/Widgets/Headlines/HeadlineFigureWidget";
import { verbalTheme } from "../../../layout/themes/verbal.theme";

export const ClassWellbeingWidget: FC<{ percent: number; empty: boolean }> = (props: {
    percent: number;
    empty: boolean;
}): JSX.Element => {
    const { hasPermission } = useAuth();
    const { percent, empty } = props;
    return (
        <HeadlineFigureWidget
            centeredHeader={true}
            header={hasPermission("ClassroomReportView") ? "Our Class Wellbeing Trend" : ""}
        >
            {hasPermission("ClassroomReportView") ? (
                <>
                    <Box display={"flex"} flexDirection="row" justifyContent={"center"} alignItems="center">
                        <Typography variant="h2">{empty ? 0 : Math.round(percent)}%</Typography>
                        {!empty ? (
                            <Typography>
                                <span style={{ fontSize: "40px", paddingLeft: verbalTheme.spacing(1) }}>
                                    {percent > 60 ? <>&#128513;</> : percent < 40 ? <>&#128532;</> : <>&#128528;</>}
                                </span>
                            </Typography>
                        ) : null}
                    </Box>
                    <Box>
                        <Typography variant="body1">(overall)</Typography>
                    </Box>
                </>
            ) : (
                <SimpleBlankSlate
                    header={"Our Class Wellbeing Trend"}
                    content={`You do not have permission to view the class wellbeing trend`}
                    extra={
                        <>
                            <Box mb={2}>
                                <Typography variant={"body2"} color={"primary"}>
                                    (Available for Staff Members and School Administrators only)
                                </Typography>
                            </Box>
                        </>
                    }
                />
            )}
        </HeadlineFigureWidget>
    );
};
