import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { VModule, VModuleType } from "../../../core/components/VModule";
import { RelatedQuestion } from "common/build/prisma/client";

type RelatedQuestionCardProps = {
    relatedQuestion: Partial<RelatedQuestion>;
};

export const RelatedQuestionCard: FC<RelatedQuestionCardProps> = (props: RelatedQuestionCardProps): JSX.Element => {
    const { relatedQuestion } = props;

    return (
        <>
            <Box pb={2} pt={2}>
                <VModule type={VModuleType.Shade1}>
                    <Typography gutterBottom>
                        <strong>
                            If the group say{" "}
                            <span
                                style={{
                                    color: "#1FAF8C",
                                }}
                            >
                                {(relatedQuestion.condition as string).toUpperCase()}
                            </span>{" "}
                            ask
                        </strong>
                    </Typography>
                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                        {relatedQuestion.question}
                    </Typography>
                </VModule>
            </Box>
        </>
    );
};
