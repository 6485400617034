const fieldSeparator = ",";

const makeStringCSVSafe = (checkString: string): string =>
    checkString.search(/("|,|\n)/g) >= 0 ? `"${checkString.replaceAll('"', '""')}"` : checkString;

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const CSVExport = (headers: any[], data: any[], filename: string): void => {
    const keys: string[] = Object.keys(data[0]);

    const csvContent = `${headers.map((header) => makeStringCSVSafe(header)).join(fieldSeparator)}\n${data
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        .map((row: any) =>
            keys
                // eslint-disable-next-line  @typescript-eslint/no-explicit-any
                .map((k: any) => {
                    let cell = row[k] === null || row[k] === undefined ? "" : row[k];

                    cell = cell instanceof Date ? cell.toLocaleString() : cell.toString().replace(/"/g, '""');

                    return makeStringCSVSafe(cell);
                })
                .join(fieldSeparator),
        )
        .join("\n")}`;

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${filename}.csv`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
};
