import React, { ReactNode } from "react";
import { AlertProvider } from "./alert.context";
import { AuthProvider } from "./auth.context";
import { TourStateProvider } from "../components/tour/Store";
import { RTour } from "../components/tour/Tour";
import { FreshdeskWidget } from "../components/support/FreshdeskWidget";
import { UserRatingDialog } from "../components/organisation/UserRatingDialog";

export interface ApplicationProviderProps {
    children: ReactNode;
}

function ApplicationProvider(props: ApplicationProviderProps): React.ReactElement {
    const { children } = props;

    return (
        <AlertProvider>
            <AuthProvider>
                <TourStateProvider>
                    <>
                        <RTour />
                        <UserRatingDialog />
                        <FreshdeskWidget />
                        {children}
                    </>
                </TourStateProvider>
            </AuthProvider>
        </AlertProvider>
    );
}

export default ApplicationProvider;
