import React from "react";
import { Box, Container, Grid } from "@material-ui/core";
import { SimpleBlankSlate } from "../../core/components/SimpleBlankSlate";
import NoSurveysImg from "../../img/no-sessions.svg";

export const NoUpcoming: React.FC<{ nounPlural: string }> = ({ nounPlural }) => (
    <Container maxWidth="sm">
        <Grid item xs={12}>
            <SimpleBlankSlate
                image={
                    <Box width="100px" margin="auto">
                        <img src={NoSurveysImg} alt="" />
                    </Box>
                }
                header={`No Upcoming ${nounPlural}`}
                content={`You currently have no upcoming ${nounPlural.toLowerCase()}, browse our collections to continue the story at home`}
            />
        </Grid>
    </Container>
);
