import React from "react";
import { Container } from "@material-ui/core";
import { ReadStory } from "../components/session/sessionMode/wizard/steps/ReadStory";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import { useStory } from "../hooks/public/useStory";
import { useEffect } from "react";
import { verbalTheme } from "../components/layout/themes/verbal.theme";
import { SessionModeProvider } from "../components/session/sessionMode/contexts/sessionMode.context";
import { useStoryStyles } from "../hooks/styles/StoryStyles";
import { StoryFetchParameters } from "../../../common/build/api-parameters/story";

export const ReadStoryPage = (): JSX.Element => {
    const { id, sessionId } = useParams<{ id?: string; sessionId?: string }>();
    const { getMappedStory, mappedStory } = useStory();

    useEffect(() => {
        if (id) {
            getMappedStory(
                parseInt(id),
                sessionId ? ({ sessionId: parseInt(sessionId) } as StoryFetchParameters) : undefined,
            );
        }
    }, [id]);

    const useStyles = makeStyles(
        createStyles({
            sessionModeLayout: {
                display: "block",
            },
            storyPanel: {
                //paddingTop: verbalTheme.spacing(8),
                paddingBottom: verbalTheme.spacing(8),
            },
        }),
    );

    const classes = useStyles();
    const storyClasses = useStoryStyles();

    return (
        <div className={classes.sessionModeLayout}>
            <div className={classes.storyPanel}>
                <SessionModeProvider>
                    <Container className={storyClasses.storyContainer}>
                        {" "}
                        {mappedStory && (
                            <ReadStory
                                mappedStory={mappedStory}
                                session={mappedStory?.Session ? mappedStory?.Session[0] : undefined}
                            />
                        )}{" "}
                    </Container>
                </SessionModeProvider>
            </div>
        </div>
    );
};
