import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import { publicTheme } from "../layout/themes/public.theme";
import { Home, LibraryBooks } from "@material-ui/icons";

const useStyles = makeStyles(
    createStyles({
        bottomNav: {
            position: "fixed",
            bottom: 0,
            width: "100%",
            backgroundColor: publicTheme.palette.secondary.dark,
        },
    }),
);

type NavigationProps = {
    navIndex: number;
    changeNavIndex: (newIndex: number) => void;
};

export const Navigation = (props: NavigationProps): JSX.Element => {
    const classes = useStyles();
    return (
        <>
            <BottomNavigation
                value={props.navIndex}
                onChange={(event, newIndex) => {
                    props.changeNavIndex(newIndex);
                }}
                showLabels
                className={classes.bottomNav}
            >
                <BottomNavigationAction label="Home" icon={<Home />} />
                <BottomNavigationAction label="Collections" icon={<LibraryBooks />} />
            </BottomNavigation>
        </>
    );
};
