import { Box, Button, Checkbox, DialogContentText, FormControlLabel, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { JitsiVideoConference } from "./JitsiVideoConference";
import { useSessionMode } from "../contexts/sessionMode.context";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../../../../contexts/auth.context";
import { ProgrammeType, SessionType } from "../../../../models/modelTypes";
import { ExternalVideoConference } from "./ExternalVideoConference";
import { ConferenceProviderType } from "common/build/programmes/conferenceProviderType";
import ConfirmDialog from "../../../../core/components/ConfirmDialog";
import { updateSessionState } from "../../../../client/api/session";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import { optOutAdmitParticipants, get } from "../../../../client/api/programme";

interface MeetingRoomProps {
    session: SessionType;
    meetingName?: string;
}

export const MeetingRoom: React.FC<MeetingRoomProps> = (props: MeetingRoomProps): JSX.Element => {
    const { admitParticipants, participantsAdmitted, hidden, toggleHideSession, onParticipantCountChange } =
        useSessionMode();
    const { user } = useAuth();
    const [openAdmit, setOpenAdmit] = useState<boolean>(false);
    const [optOut, setOptOut] = useState<boolean>(false);
    const [programme, setProgramme] = useState<ProgrammeType>();
    const [facilitatorId, setFacilitatorId] = useState<number>();

    useEffect(() => {
        if (props.session.programmeId) {
            get(props.session.programmeId, { includeFacilitators: true }).then((response) => {
                setProgramme(response);
            });
        }
    }, [optOut, props.session]);

    useEffect(() => {
        if (user && user.Facilitator) {
            setFacilitatorId(user.Facilitator.id);
        }
    }, [user]);

    const admitUsers = async () => {
        setOpenAdmit(false);
        admitParticipants(props.session.id);
        toggleHideSession(false);
        await updateSessionState(props.session.id, { state: "InProgress" });
        if (optOut && props.session.programmeId) {
            await optOutAdmitParticipants(props.session.programmeId);
        }
    };

    const useStyles = makeStyles(() =>
        createStyles({
            meetingRoomHeader: {
                backgroundColor: "#F9F9FB",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                p={3}
                className={classes.meetingRoomHeader}
            >
                <Typography variant="h5" color="textPrimary">
                    Meeting Room
                </Typography>
                {!participantsAdmitted && !props.session.isUsersAdmitted ? (
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                            let facilitator;
                            if (programme && programme.Facilitators && facilitatorId) {
                                facilitator = programme?.Facilitators.find((f) => f.facilitatorId === facilitatorId);
                            }
                            if (facilitator && facilitator.optedOutAdmit) {
                                admitUsers();
                            } else {
                                setOpenAdmit(true);
                            }
                        }}
                    >
                        Admit Participants
                    </Button>
                ) : (
                    <Button
                        variant="text"
                        style={{ color: "red", backgroundColor: "transparent", height: "20px" }}
                        size="large"
                        startIcon={<OndemandVideoIcon style={{ fill: "red" }} />}
                        disableElevation
                        disableRipple
                        disableFocusRipple
                    >
                        LIVE
                    </Button>
                )}
            </Box>
            {hidden ? (
                <Box p={3}>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography variant="h4">Session is Hidden</Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                The video meeting is not live when the session has been hidden. Show the session again
                                or admit participants to start the meeting again
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            ) : props.session.providerType === "BuiltIn" || !props.session.providerURL ? (
                <JitsiVideoConference
                    setParticipantCount={onParticipantCountChange}
                    user={user}
                    roomName={props.meetingName ?? props.session.id.toString()}
                />
            ) : (
                <ExternalVideoConference
                    provider={ConferenceProviderType[props.session.providerType]}
                    isSessionStarted={
                        props.session.state !== "NotStarted" && (participantsAdmitted || props.session.isUsersAdmitted)
                    }
                    isParticipant={false}
                    providerURL={props.session.providerURL}
                />
            )}
            <ConfirmDialog
                open={openAdmit}
                title="Admit Participants?"
                onConfirm={admitUsers}
                onCancel={() => setOpenAdmit(false)}
                confirmButtonText="Admit"
                cancelButtonText="Back to Register"
            >
                <DialogContentText>{`If you admit the participants this session will begin and must be completed. Are you sure you want to admit participants?`}</DialogContentText>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={optOut}
                            onChange={(event) => setOptOut(event.target.checked)}
                            name="dont-show-admit-dialog"
                        />
                    }
                    label="Don't show this again"
                />
            </ConfirmDialog>
        </>
    );
};
