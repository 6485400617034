import { Organisation, User } from "common/build/prisma/client";
import { getData, postData } from "../rest";

export const addOrganisation = async (organisationDetails: Partial<Organisation & User>): Promise<Response> =>
    await postData("/public/organisation", organisationDetails);

export const addSchool = async (organisationDetails: Partial<Organisation & User>): Promise<Response> =>
    await postData("/public/organisation/school", organisationDetails);

export const checkEmail = async (orgName: string, email: string): Promise<Response> =>
    await getData(`/public/organisation/${orgName}/unique-email/${email}`);
