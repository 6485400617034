import React from "react";
import { Crud } from "../../../../core/components/crud/Crud";
import { get, list } from "../../../../client/api/story";
import { storiesCRUDModel } from "../model";
import { StoryType } from "../../../../models/modelTypes";
import { StoryListParameters } from "../../../../../../common/build/api-parameters/story";
import { useAuth } from "../../../../contexts/auth.context";
import { Redirect } from "react-router-dom";
import { ViewStory } from "./ViewStory";
import { CreateStoryWizard } from "../wizard/CreateStoryWizard";
import { StoryProvider } from "../story.context";

export const StoriesScreen = (): JSX.Element => {
    const { user, hasPermission } = useAuth();

    if (!user) {
        return <Redirect to={"/403"} />;
    }

    return (
        <StoryProvider>
            <Crud<StoryType, StoryListParameters>
                model={storiesCRUDModel}
                noun="Story"
                plural="Stories"
                getData={list}
                getItem={get}
                labelProperty={"storyName"}
                searchable
                addComponent={CreateStoryWizard}
                viewComponent={ViewStory}
                viewOnClick
                canAdd={hasPermission("StoryCreate")}
            />
        </StoryProvider>
    );
};
export default StoriesScreen;
