import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Organisation, Permission, Role, RolePermission, User, UserRating } from "common/build/prisma/client";
import {
    get,
    loginUser,
    setPassword as apiSetPassword,
    addUserRating as apiAddUserRating,
    initReset as apiInitReset,
    InitResetParams,
} from "../client/api/auth";
import { node } from "prop-types";
import { SetPasswordParams } from "../client/api/auth";
import { FacilitatorType } from "../models/modelTypes";
import { userHasPermission } from "common/build/utils/permissions";

interface AuthContextI {
    user?: User & {
        Organisation?: Organisation;
        Role: Role & { RolePermissions: RolePermission[] };
        Facilitator?: FacilitatorType;
        lastUserRatingDate?: Date;
        isVerbal?: boolean;
        isSchool?: boolean;
    };
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    loading: boolean;
    token: string | null;

    hasPermission: (permission: Permission) => boolean;

    login: (email: string, password: string) => Promise<void>;
    setPassword: (params: SetPasswordParams) => Promise<void>;
    addUserRating: (params?: Partial<UserRating>) => Promise<void>;
    initReset: (params: InitResetParams) => Promise<boolean>;
    logout: () => void;
    addToken: (token: string) => void;
}

const AuthContext = React.createContext({} as AuthContextI);

const storedToken = window.localStorage.getItem("token");

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<
        User & {
            Organisation?: Organisation;
            Role: Role & { RolePermissions: RolePermission[] };
            Facilitator?: FacilitatorType;
            lastUserRatingDate?: Date;
            isVerbal?: boolean;
            isSchool?: boolean;
        }
    >();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(!!storedToken);
    const [loading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(storedToken);

    useEffect(() => {
        setLoading(true);
        updateUser();
    }, [token]);

    const addToken = useCallback((token: string) => {
        localStorage.setItem("token", token);
        setToken(token);
    }, []);

    const updateUser = useCallback(() => {
        if (token) {
            setIsAuthenticating(true);
            get()
                .then(
                    (
                        user: User & {
                            Organisation?: Organisation;
                            Role: Role & { RolePermissions: RolePermission[] };
                            Facilitator?: FacilitatorType;
                        },
                    ) => {
                        setUser(user);
                        setIsAuthenticated(true);
                    },
                )
                .catch(() => {
                    setUser(undefined);
                    setIsAuthenticated(false);
                })
                .finally(() => {
                    setIsAuthenticating(false);
                    setLoading(false);
                });
        } else {
            setUser(undefined);
            setIsAuthenticated(false);
            setIsAuthenticating(false);
            setLoading(false);
        }
    }, [token]);

    const setSessionToken = useCallback((sessionToken) => {
        localStorage.setItem("token", sessionToken);
        setToken(sessionToken);
    }, []);

    const login = useCallback((email, password) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            loginUser(email, password)
                .then(async (response) => {
                    if (response.ok) {
                        const { token: tokenFromApi }: { token: string } = await response.json();
                        setSessionToken(tokenFromApi);
                        resolve();
                    } else {
                        setToken(null);
                        const { errors } = await response.json();
                        if (errors && errors.length > 0) {
                            reject(errors[0].msg);
                        } else {
                            reject("Error logging in");
                        }
                    }
                })
                .catch(() => {
                    setToken(null);
                    reject("Error logging in");
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const setPassword = useCallback(async (params: SetPasswordParams) => {
        setLoading(true);
        try {
            const response = await apiSetPassword(params);
            if (response.ok) {
                const { token: newToken }: { token: string } = await response.json();
                setSessionToken(newToken);
            } else {
                throw new Error();
            }
        } finally {
            setLoading(false);
        }
    }, []);

    const addUserRating = useCallback(async (params?: Partial<UserRating>) => {
        setLoading(true);
        try {
            const response = await apiAddUserRating(params);
            if (response.ok) {
                setLoading(false);
            } else {
                setLoading(false);
            }
        } finally {
            setLoading(false);
        }
    }, []);

    const initReset = useCallback(async (params: InitResetParams) => {
        setLoading(true);
        try {
            const response = await apiInitReset(params);
            return response.ok;
        } finally {
            setLoading(false);
        }
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        setUser(undefined);
    }, []);

    const hasPermission = useCallback((permission: Permission) => userHasPermission(user, permission), [user]);

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthenticated,
                isAuthenticating,
                loading,
                token,
                login,
                logout,
                addToken,
                setPassword,
                addUserRating,
                initReset,
                hasPermission,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: node,
};

export const useAuth = (): AuthContextI => React.useContext(AuthContext);
