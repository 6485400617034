import { Box, Container } from "@material-ui/core";
import React from "react";
import { StepperWizard } from "../../../../core/components/wizard/StepperWizard";
import WizardStep from "../../../../core/components/wizard/WizardStep";
import { AddStory } from "./steps/AddStory";
import { useStoryContext } from "../story.context";
import { StoryParts } from "./steps/StoryParts";

export const CreateStoryWizard: React.FC = (): JSX.Element => {
    const { activeStep, completedSteps, changeStep } = useStoryContext();

    return (
        <Box p={4}>
            <Container maxWidth={"md"}>
                <StepperWizard activeStep={activeStep} goToStep={changeStep} orientation="horizontal">
                    <WizardStep identifier={0} heading="Story Details" completed={completedSteps[0]}>
                        <AddStory />
                    </WizardStep>
                    <WizardStep identifier={1} heading="Story Parts" completed={completedSteps[1]}>
                        <StoryParts />
                    </WizardStep>
                </StepperWizard>
            </Container>
        </Box>
    );
};
