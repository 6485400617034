import { Typography, Box, Button, Grid } from "@material-ui/core";
import { RelatedQuestion } from "common/build/prisma/client";
import { SimplePopper } from "../../../../../core/components/simplePopper";
import { useSessionMode } from "../../contexts/sessionMode.context";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useUtilityStyles } from "../../../../../hooks/styles/UtilityStyles";
import { VPill, VPillClosedQuestion } from "../../../../../core/components/VPill";
import { MappedStoryQuestionType } from "../../../../../models/modelTypes";
import { RelatedQuestionCard } from "../../../components/RelatedQuestionCard";
import { VModule, VModuleType } from "../../../../../core/components/VModule";
import { useSetTourState } from "../../../../tour/Store";
import clsx from "clsx";

interface StoryQuestionProps {
    currentStoryPartsQuestions: MappedStoryQuestionType[];
    inProgress: boolean;
    isAtHome: boolean;
}

const useStyles = makeStyles(
    createStyles({
        actions: {
            display: "block",
            clear: "both",
            overflow: "auto",
        },
        keywordChip: {
            borderRadius: 0,
            margin: "0 0.5rem 0.5rem 0",
        },
    }),
);

export const StoryQuestions: React.FC<StoryQuestionProps> = (props: StoryQuestionProps) => {
    const { currentStoryPartsQuestions, inProgress, isAtHome } = props;

    const {
        currentQuestion: currentQuestionIndex,
        changeQuestion,
        toggleShowQuestions,
        showQuestions,
        presentationMode,
        currentPart,
    } = useSessionMode();

    const { id } = useParams<{ id: string }>();
    const sessionId = parseInt(id);

    const [currentRelatedQuestion, setCurrentRelatedQuestion] = useState<Partial<RelatedQuestion>>();
    const [relatedQuestions, setRelatedQuestions] = useState<Partial<RelatedQuestion>[]>([]);
    const [openPrompt, setOpenPrompt] = useState<boolean>(false);

    const setTourState = useSetTourState();
    const refShowQuestionsBtn = useRef<HTMLButtonElement | null>(null);
    const refNextQuestionBtn = useRef<HTMLButtonElement | null>(null);

    let hint1, hint2, question, helpPrompt, closed, keywords;
    if (currentStoryPartsQuestions[currentQuestionIndex] && currentStoryPartsQuestions[currentQuestionIndex].question) {
        hint1 = currentStoryPartsQuestions[currentQuestionIndex].hint1;
        hint2 = currentStoryPartsQuestions[currentQuestionIndex].hint2;
        question = currentStoryPartsQuestions[currentQuestionIndex].question;
        helpPrompt = currentStoryPartsQuestions[currentQuestionIndex].prompt;
        closed = currentStoryPartsQuestions[currentQuestionIndex].category === "Closed";
        keywords = currentStoryPartsQuestions[currentQuestionIndex].keywords;
    }

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("showQuestionsBtn", refShowQuestionsBtn) }));
    }, [refShowQuestionsBtn]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("nextQuestionBtn", refNextQuestionBtn) }));
    }, [refNextQuestionBtn]);

    useEffect(() => {
        if (
            currentStoryPartsQuestions[currentQuestionIndex] &&
            currentStoryPartsQuestions[currentQuestionIndex].question
        ) {
            setRelatedQuestions(currentStoryPartsQuestions[currentQuestionIndex].RelatedQuestion ?? []);
            setCurrentRelatedQuestion(undefined);
        }
    }, [currentStoryPartsQuestions[currentQuestionIndex], currentQuestionIndex]);

    // Toggle next question and show questions
    useEffect(() => {
        if (!showQuestions && currentQuestionIndex > 0) {
            toggleShowQuestions();
        }

        changeQuestion(currentQuestionIndex, sessionId, true);
    }, [currentStoryPartsQuestions]);

    // Added this second effect as the dependency (currentStoryPartQuestions) in the above effect was being changed by the parent component which was causing
    // a rerender and not allowing the user to show questions.
    // Issue started appearing when filter was added to currentStoryPartQuestions array in parent component.
    // Suspected it is due to the filter method not mutating the oiginal array and returning a new array instead, which is causing the component to rerender
    useEffect(() => {
        if (showQuestions && currentQuestionIndex === 0) {
            toggleShowQuestions();
        }
        setOpenPrompt(false);
    }, [currentPart]);

    const currentQuestion = currentQuestionIndex + 1;
    const nextQuestionIndex = currentQuestionIndex + 1;
    const previousQuestionIndex = currentQuestionIndex - 1;
    const hasAnotherQuestion = Boolean(
        currentStoryPartsQuestions && currentStoryPartsQuestions[currentQuestionIndex + 1],
    );

    const classes = useStyles();
    const utilityClasses = useUtilityStyles();

    if (!inProgress && !showQuestions) {
        toggleShowQuestions();
    }

    // Show card if presentation mode
    return (
        <>
            {question && (
                <>
                    {showQuestions && (
                        <>
                            <Box mb={3}>
                                <Grid container spacing={2} direction="row">
                                    <Grid item>
                                        <Typography variant="h5">
                                            Question {currentQuestion} of {currentStoryPartsQuestions.length}
                                        </Typography>
                                    </Grid>
                                    {!isAtHome && closed ? (
                                        <Grid item>
                                            <VPillClosedQuestion />
                                        </Grid>
                                    ) : null}
                                </Grid>
                                <Typography gutterBottom={true} style={{ whiteSpace: "pre-line" }}>
                                    {question}
                                </Typography>
                                {!isAtHome && (
                                    <Box py={1}>
                                        {keywords &&
                                            keywords.length > 0 &&
                                            keywords?.split(" ").map((kw, index) => (
                                                <VPill
                                                    key={kw}
                                                    style={
                                                        index % 2 === 0
                                                            ? {
                                                                  backgroundColor: "#28B5BE",
                                                              }
                                                            : {
                                                                  backgroundColor: "#D3B61A",
                                                              }
                                                    }
                                                    className={classes.keywordChip}
                                                    label={kw}
                                                />
                                            ))}
                                    </Box>
                                )}
                                <Grid container spacing={1} direction="row">
                                    {closed &&
                                        relatedQuestions.length > 0 &&
                                        relatedQuestions.map((rq, index) => {
                                            const condition = rq.condition;
                                            return (
                                                <Fragment key={index}>
                                                    {condition === "Yes" ? (
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                color={
                                                                    rq === currentRelatedQuestion
                                                                        ? "primary"
                                                                        : "default"
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        currentRelatedQuestion?.condition ===
                                                                        rq.condition
                                                                    ) {
                                                                        setCurrentRelatedQuestion(undefined);
                                                                    } else {
                                                                        setCurrentRelatedQuestion(rq);
                                                                    }
                                                                }}
                                                            >
                                                                If group say <br /> YES
                                                            </Button>
                                                        </Grid>
                                                    ) : (
                                                        <Grid item>
                                                            <Button
                                                                variant="contained"
                                                                color={
                                                                    rq === currentRelatedQuestion
                                                                        ? "primary"
                                                                        : "default"
                                                                }
                                                                onClick={() => {
                                                                    if (
                                                                        currentRelatedQuestion?.condition ===
                                                                        rq.condition
                                                                    ) {
                                                                        setCurrentRelatedQuestion(undefined);
                                                                    } else {
                                                                        setCurrentRelatedQuestion(rq);
                                                                    }
                                                                }}
                                                            >
                                                                If group say <br /> NO
                                                            </Button>
                                                        </Grid>
                                                    )}
                                                </Fragment>
                                            );
                                        })}
                                </Grid>

                                <Box>
                                    {closed && currentRelatedQuestion && (
                                        <RelatedQuestionCard relatedQuestion={currentRelatedQuestion} />
                                    )}
                                </Box>

                                {closed && (currentRelatedQuestion || openPrompt) && (
                                    <Box pt={currentRelatedQuestion ? 0 : 2}>
                                        <Button
                                            onClick={() => {
                                                setOpenPrompt(!openPrompt);
                                            }}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            Still No Response?
                                        </Button>
                                    </Box>
                                )}

                                {!closed && (
                                    <Box pt={1}>
                                        <Button
                                            onClick={() => {
                                                setOpenPrompt(!openPrompt);
                                            }}
                                            variant="contained"
                                            color="secondary"
                                        >
                                            No Response?
                                        </Button>
                                    </Box>
                                )}

                                {openPrompt ? (
                                    <Box py={2}>
                                        <VModule type={VModuleType.Shade1}>
                                            <Typography gutterBottom>
                                                <strong>If the group are still not responding, try asking:</strong>
                                            </Typography>
                                            <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                                {helpPrompt}
                                            </Typography>
                                            <Typography>
                                                <strong>
                                                    If this still doesn&apos;t generate a discussion you can move on to
                                                    the next question
                                                </strong>
                                            </Typography>
                                        </VModule>
                                    </Box>
                                ) : null}
                                {isAtHome && (
                                    <Box display="flex">
                                        {hint1 && hint1 != null && <SimplePopper label="Hint 1" content={hint1} />}
                                        {hint2 && hint2 != null && <SimplePopper label="Hint 2" content={hint2} />}
                                    </Box>
                                )}
                            </Box>
                            <div className={classes.actions}>
                                {currentQuestionIndex > 0 && (
                                    <Box mr={1}>
                                        <Button
                                            className={utilityClasses.floatLeft}
                                            variant="contained"
                                            onClick={() => {
                                                setOpenPrompt(false);
                                                changeQuestion(previousQuestionIndex, sessionId, true);
                                            }}
                                        >
                                            Previous
                                        </Button>
                                    </Box>
                                )}
                                {hasAnotherQuestion && (
                                    <Button
                                        ref={refNextQuestionBtn}
                                        className={clsx(
                                            utilityClasses.floatRight,
                                            "nextQuestionBtn",
                                            "question" + nextQuestionIndex,
                                        )}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setOpenPrompt(false);
                                            changeQuestion(nextQuestionIndex, sessionId, true);
                                        }}
                                    >
                                        Next
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                    {!showQuestions && !presentationMode && (
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Box mb={2}>
                                <Typography variant="body1">
                                    To continue the story work through the questions with the participants
                                </Typography>
                            </Box>
                            <Button
                                ref={refShowQuestionsBtn}
                                variant="contained"
                                color="primary"
                                onClick={() => toggleShowQuestions()}
                                className="showQuestionsBtn"
                            >
                                Show Questions
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </>
    );
};
