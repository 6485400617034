import { Card, CardProps } from "@material-ui/core";
import React, { FC } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
    createStyles({
        root: {
            padding: "0 8px !important",
        },
        container: {
            margin: "0 -8px !important",
            width: "unset",
        },
    }),
);

export const VCard: FC<CardProps> = (props) => {
    const { children, ...rest } = props;
    const classes = useStyles();
    return (
        <Card {...rest} className={classes.root}>
            {children}
        </Card>
    );
};
