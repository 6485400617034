import { Box, createStyles, Divider, Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import React from "react";
import { VPill } from "../../../core/components/VPill";
import { useMobile } from "../../../hooks/useMobile";
import { MappedStoryType, WeekType } from "../../../models/modelTypes";
import { verbalTheme } from "../../layout/themes/verbal.theme";

type StorySynopsisProps = {
    story?: MappedStoryType;
    week?: WeekType;
};

export const StorySynopsis = (props: StorySynopsisProps): JSX.Element => {
    const { story, week } = props;
    const { isMobile, isTablet } = useMobile();

    const useStyles = makeStyles(
        createStyles({
            keywordChip: {
                borderRadius: 0,
                margin: "0 0.5rem 0.5rem 0",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Box pt={3}>
                <Box px={!isTablet && isMobile ? 2 : 0}>
                    <Grid container spacing={1} direction="row">
                        {story?.Story?.ageRange ? (
                            <Grid item data-tour="prep-story-age">
                                <VPill
                                    className={classes.keywordChip}
                                    label={`Age Range ${story?.Story?.ageRange} Years`}
                                />
                            </Grid>
                        ) : null}

                        {week?.Theme?.name ? (
                            <Grid item data-tour="prep-story-theme">
                                <VPill className={classes.keywordChip} label={week.Theme.name} />
                            </Grid>
                        ) : null}
                    </Grid>
                </Box>

                <Box mt={1}>
                    <Box
                        padding={2}
                        width={"100%"}
                        bgcolor={"secondary.main"}
                        color={verbalTheme.palette.common.white}
                        style={{ border: `3px solid ${verbalTheme.palette.primary.main}` }}
                    >
                        <Typography data-tour="prep-story-author">
                            Author: <span style={{ fontWeight: "bold" }}>{story?.Story?.author}</span>
                        </Typography>
                        <Divider
                            style={{
                                marginTop: verbalTheme.spacing(1),
                                marginBottom: verbalTheme.spacing(1),
                                backgroundColor: verbalTheme.palette.primary.main,
                            }}
                        />
                        {story?.Story?.overview ? (
                            <>
                                <Typography style={{ fontWeight: "bold" }}>Contextual Overview</Typography>
                                <Typography>{story?.Story?.overview}</Typography>
                                <Divider
                                    style={{
                                        marginTop: verbalTheme.spacing(1),
                                        marginBottom: verbalTheme.spacing(1),
                                        backgroundColor: verbalTheme.palette.primary.main,
                                    }}
                                />
                            </>
                        ) : null}
                        <Typography style={{ fontWeight: "bold" }}>Synopsis</Typography>
                        <Typography data-tour="prep-story-synopsis">{story?.Story?.synopsis}</Typography>
                    </Box>
                </Box>
            </Box>
            {week?.learningObjective ? (
                <Box my={3} mx={!isTablet && isMobile ? 2 : 0}>
                    <Paper elevation={0} data-tour="prep-story-objective">
                        <Typography variant="h6">Learning Objective</Typography>
                        <Typography>{week?.learningObjective}</Typography>
                    </Paper>
                </Box>
            ) : null}
        </>
    );
};
