import React, { useEffect } from "react";
import { VCard } from "../../../core/components/VCard";
import {
    VictoryChart,
    VictoryGroup,
    VictoryLine,
    VictoryScatter,
    VictoryTooltip,
    VictoryPie,
    VictoryBar,
    VictoryAxis,
    VictoryTheme,
    VictoryContainer,
} from "victory";
import { AttendanceData, getProgrammeReportData, ProgrammeReportData, WellbeingData } from "../../../client/api/report";
import { useState } from "react";
import Spinner from "../../layout/Spinner";
import {
    Grid,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { VModule, VModuleType } from "../../../core/components/VModule";
import { Session } from "common/build/prisma/client";
import { SimpleBlankSlate } from "../../../core/components/SimpleBlankSlate";
import barchartImg from "../../../img/bars.svg";
import piechartImg from "../../../img/pie.svg";
import { Alert } from "@material-ui/lab";
import { HeadlineFigureWidget } from "../../dashboard/Widgets/Headlines/HeadlineFigureWidget";

type ProgrammeReportProps = {
    programmeId: number;
};

export const ProgrammeReport = (props: ProgrammeReportProps): JSX.Element => {
    const { programmeId } = props;
    const [reportData, setReportData] = useState<ProgrammeReportData>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isProgrammeComplete, setIsProgrammeComplete] = useState<boolean>(false);
    const [isProgrammeStarted, setIsProgrammeStarted] = useState<boolean>(false);
    const [isPostSurveyStarted, setIsPostSurveyStarted] = useState<boolean>(false);
    const [isChildProgramme, setIsChildProgramme] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const useStyles = makeStyles(() =>
        createStyles({
            chartContainer: {
                "& svg": {
                    maxHeight: "350px",
                },
            },
        }),
    );

    const classes = useStyles();
    const isSmall = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    useEffect(() => {
        if (programmeId) {
            setLoading(true);
            getProgrammeReportData(programmeId)
                .then((data) => {
                    setError(false);
                    setReportData(data);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [programmeId]);

    useEffect(() => {
        if (reportData && reportData.programme.Sessions) {
            setIsProgrammeComplete(
                reportData.programme.Sessions.filter((session: Session) => session.state !== "Complete")?.length > 0
                    ? false
                    : true,
            );
            setIsProgrammeStarted(
                reportData.programme.Sessions.filter(
                    (session: Session) => session.state === "InProgress" || session.state === "Complete",
                )?.length > 0,
            );
            setIsChildProgramme(reportData.programme.ageRange === "18+" ? false : true);
            setIsPostSurveyStarted(
                reportData.programme.Sessions.some((session: Session) => {
                    if (
                        reportData.programme.Sessions?.length &&
                        session.sessionNumber >= reportData.programme.Sessions?.length - 2
                    ) {
                        return session.state === "Complete";
                    }
                }),
            );
        }
    }, [reportData]);

    if (loading) {
        return <Spinner />;
    }

    if (error || !reportData) {
        return <Alert severity="error">{`Unable to load programme report`}</Alert>;
    }

    const attendanceMaxAxis = Math.max(
        ...reportData.attendance.map(function (o) {
            return o.attendance;
        }),
    );

    const wellbeingMaxAxis = Math.max(
        ...reportData.wellbeingBreakdown.map(function (o) {
            return o.wellbeing;
        }),
    );

    return (
        <>
            {!isProgrammeStarted ? (
                <>
                    <Box mb={2}>
                        <VCard>
                            <SimpleBlankSlate
                                image={<img src={barchartImg} />}
                                header={"Programme Report"}
                                content={
                                    "When the programme has started and sessions are delivered, this section will show infographics on: participant attendance by session, gender demographic breakdown, and group wellbeing by session"
                                }
                                extra={
                                    <>
                                        <Box mb={2}>
                                            <Typography variant={"body2"} color={"primary"}>
                                                (Available after the first session has been delivered)
                                            </Typography>
                                        </Box>
                                    </>
                                }
                            />
                        </VCard>
                    </Box>
                </>
            ) : (
                <>
                    <Box mb={2}>
                        <Alert severity="info">
                            The programme is {isProgrammeComplete ? "complete" : "in progress"}
                        </Alert>
                    </Box>

                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <HeadlineFigureWidget header="Survey Opt Outs">
                                    <Typography variant="h2">{reportData.optedOut}</Typography>
                                    {/* to keep the count in the centre as space-between style in HeadlineFigureWidget puts it at the bottom */}
                                    <div style={{ visibility: "hidden" }} />
                                </HeadlineFigureWidget>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={3}>
                                <HeadlineFigureWidget header="Pre-Programme Survey Responses">
                                    <Box>
                                        <Typography variant="h2">
                                            {reportData.preProgrammeResponses.expected} /{" "}
                                            {reportData.preProgrammeResponses.actual}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography>(Expected / Actual)</Typography>
                                    </Box>
                                </HeadlineFigureWidget>
                            </Grid>
                            {isPostSurveyStarted && (
                                <Grid item xs={12} sm={6} lg={3}>
                                    <HeadlineFigureWidget header="Post-Programme Survey Responses">
                                        <Box>
                                            <Typography variant="h2">
                                                {reportData.postProgrammeResponses.expected} /{" "}
                                                {reportData.postProgrammeResponses.actual}
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography>(Expected / Actual)</Typography>
                                        </Box>
                                    </HeadlineFigureWidget>
                                </Grid>
                            )}
                        </Grid>
                    </Box>

                    <Box mb={2}>
                        <VCard
                            title={"Attendance by session"}
                            subtitle={"Track participant attendance throughout the course of a programme"}
                        >
                            <Box className={classes.chartContainer}>
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    containerComponent={<VictoryContainer responsive={true} />}
                                    width={isSmall ? 500 : 900}
                                    height={isSmall ? 350 : 300}
                                >
                                    <VictoryAxis
                                        tickValues={reportData.attendance.map((val: AttendanceData) => val.session)}
                                        tickFormat={(x) => `Session ${x}`}
                                        domain={[1, reportData.attendance.length]}
                                    />
                                    <VictoryAxis
                                        dependentAxis
                                        tickFormat={(y) => y}
                                        tickValues={[
                                            0,
                                            ...Array.from(
                                                { length: attendanceMaxAxis > 10 ? attendanceMaxAxis : 10 },
                                                (_, index) => index + 1,
                                            ),
                                        ]}
                                        domain={[0, attendanceMaxAxis > 10 ? attendanceMaxAxis : 10]}
                                    />
                                    <VictoryGroup
                                        data={reportData.attendance.map((val: AttendanceData) => ({
                                            x: val.session,
                                            y: val.attendance,
                                        }))}
                                    >
                                        <VictoryLine
                                            style={{
                                                data: { stroke: "#75C5FA" },
                                                parent: { border: "1px solid #ccc" },
                                            }}
                                            animate={{
                                                duration: 2000,
                                                onLoad: { duration: 1000 },
                                            }}
                                        />
                                        <VictoryScatter
                                            labels={({ datum }) => datum.y}
                                            animate={{
                                                duration: 2000,
                                                onLoad: { duration: 1000 },
                                            }}
                                            style={{ data: { fill: "#75C5FA" } }}
                                            size={6}
                                            labelComponent={
                                                <VictoryTooltip
                                                    cornerRadius={({ datum }) => (datum.x > 6 ? 0 : 4)}
                                                    pointerLength={0}
                                                    flyoutPadding={8}
                                                    flyoutStyle={{
                                                        fill: "white",
                                                        stroke: "#e3e3e3",
                                                    }}
                                                />
                                            }
                                        />
                                    </VictoryGroup>
                                </VictoryChart>
                            </Box>
                        </VCard>
                    </Box>

                    <Box mb={2}>
                        <VCard title={"Gender Demographic"} subtitle={"See a breakdown of your programme by gender"}>
                            <Grid container spacing={1}>
                                {!loading && reportData.genderBreakdown.length === 0 ? (
                                    <Grid item xs={12} md={12}>
                                        <SimpleBlankSlate
                                            image={<img src={piechartImg} />}
                                            extra={
                                                <Box mb={2}>
                                                    <Typography variant={"body1"}>
                                                        No genders have been provided yet.
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </Grid>
                                ) : (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <Box className={classes.chartContainer}>
                                                <VictoryPie
                                                    theme={VictoryTheme.material}
                                                    colorScale={["tomato", "orange", "gold", "cyan"]}
                                                    animate={{
                                                        duration: 2000,
                                                    }}
                                                    style={{
                                                        labels: {
                                                            fill: verbalTheme.palette.secondary.main,
                                                            fontFamily: verbalTheme.typography.fontFamily,
                                                        },
                                                    }}
                                                    data={reportData.genderBreakdown}
                                                />
                                            </Box>
                                        </Grid>

                                        <Grid item xs={12} md={6}>
                                            <VModule type={VModuleType.Shade1} padded={false}>
                                                <Table size={"small"} className="c-table-no-border-bottom">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Gender</TableCell>
                                                            <TableCell></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {reportData.genderBreakdown.map(
                                                            (gb: { x: string; y: number }) => (
                                                                <TableRow key={gb.x}>
                                                                    <TableCell>{gb.x}</TableCell>
                                                                    <TableCell>{gb.y}</TableCell>
                                                                </TableRow>
                                                            ),
                                                        )}
                                                    </TableBody>
                                                </Table>
                                            </VModule>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </VCard>
                    </Box>

                    <Box mb={2}>
                        <VCard
                            title={"Group Wellbeing by session"}
                            subtitle={"Track participant wellbeing throughout the course of a programme"}
                        >
                            <Box className={classes.chartContainer}>
                                <VictoryChart
                                    theme={VictoryTheme.material}
                                    containerComponent={<VictoryContainer responsive={true} />}
                                    width={isSmall ? 500 : 900}
                                    height={isSmall ? 350 : 300}
                                >
                                    <VictoryAxis
                                        tickValues={reportData.wellbeingBreakdown.map(
                                            (val: WellbeingData) => val.session,
                                        )}
                                        tickFormat={(x) => `Session ${x}`}
                                        domain={[1, reportData.wellbeingBreakdown.length]}
                                    />
                                    <VictoryAxis
                                        dependentAxis
                                        tickFormat={(y) => y}
                                        tickValues={[
                                            0,
                                            ...Array.from(
                                                { length: wellbeingMaxAxis > 10 ? wellbeingMaxAxis : 10 },
                                                (_, index) => index + 1,
                                            ),
                                        ]}
                                        domain={[0, wellbeingMaxAxis > 10 ? wellbeingMaxAxis : 10]}
                                    />
                                    <VictoryGroup
                                        data={reportData.wellbeingBreakdown.map((val) => ({
                                            x: val.session,
                                            y: val.wellbeing,
                                        }))}
                                    >
                                        <VictoryLine
                                            style={{
                                                data: { stroke: "#75C5FA" },
                                                parent: { border: "1px solid #ccc" },
                                            }}
                                        />
                                        <VictoryScatter
                                            labels={({ datum }) => datum.y}
                                            animate={{
                                                duration: 2000,
                                                onLoad: { duration: 1000 },
                                            }}
                                            style={{ data: { fill: "#75C5FA" } }}
                                            size={6}
                                            labelComponent={
                                                <VictoryTooltip
                                                    cornerRadius={({ datum }) => (datum.x > 6 ? 0 : 4)}
                                                    pointerLength={0}
                                                    flyoutPadding={8}
                                                    flyoutStyle={{
                                                        fill: "white",
                                                        stroke: "#e3e3e3",
                                                    }}
                                                />
                                            }
                                        />
                                    </VictoryGroup>
                                </VictoryChart>
                            </Box>
                        </VCard>
                    </Box>
                </>
            )}

            {isProgrammeComplete ? (
                <>
                    <Box mb={2}>
                        <VCard
                            title={"Participant Emotional Wellbeing"}
                            subtitle={
                                "See a comparison of the changes in participant emotional wellbeing across the programme"
                            }
                        >
                            <Box className={classes.chartContainer}>
                                <VictoryChart
                                    domainPadding={{ x: 200 }}
                                    theme={VictoryTheme.material}
                                    animate={{
                                        duration: 2000,
                                        onLoad: { duration: 1000 },
                                    }}
                                    containerComponent={<VictoryContainer responsive={true} />}
                                    width={isSmall ? 500 : 900}
                                    height={isSmall ? 350 : 300}
                                >
                                    <VictoryAxis tickValues={[1, 2]} tickFormat={["Pre Programme", "Post Programme"]} />
                                    <VictoryAxis dependentAxis tickFormat={(x) => x} />
                                    <VictoryBar
                                        data={[
                                            {
                                                programme: 1,
                                                wellbeing: reportData.programmeWellbeing.pre,
                                                label: reportData.programmeWellbeing.pre,
                                            },
                                            {
                                                programme: 2,
                                                wellbeing: reportData.programmeWellbeing.post,
                                                label: reportData.programmeWellbeing.post,
                                            },
                                        ]}
                                        x="programme"
                                        y="wellbeing"
                                        labelComponent={
                                            <VictoryTooltip
                                                cornerRadius={({ datum }) => (datum.x > 6 ? 0 : 4)}
                                                pointerLength={0}
                                                flyoutPadding={8}
                                                flyoutStyle={{
                                                    fill: "white",
                                                    stroke: "#e3e3e3",
                                                }}
                                            />
                                        }
                                    />
                                </VictoryChart>
                            </Box>
                        </VCard>
                    </Box>
                    {isChildProgramme ? (
                        <>
                            <Box mb={2}>
                                <VCard
                                    title={"Participant Resilience"}
                                    subtitle={
                                        "See a comparison of the changes in participant resilience scores across the programme"
                                    }
                                >
                                    <Box className={classes.chartContainer}>
                                        <VictoryChart
                                            domainPadding={{ x: 200 }}
                                            theme={VictoryTheme.material}
                                            animate={{
                                                duration: 2000,
                                                onLoad: { duration: 1000 },
                                            }}
                                            containerComponent={<VictoryContainer responsive={true} />}
                                            width={isSmall ? 500 : 900}
                                            height={isSmall ? 350 : 300}
                                        >
                                            <VictoryAxis
                                                tickValues={[1, 2]}
                                                tickFormat={["Pre Programme", "Post Programme"]}
                                            />
                                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                                            <VictoryBar
                                                data={[
                                                    {
                                                        programme: 1,
                                                        resilience: reportData.resilience.pre,
                                                        label: reportData.resilience.pre,
                                                    },
                                                    {
                                                        programme: 2,
                                                        resilience: reportData.resilience.post,
                                                        label: reportData.resilience.post,
                                                    },
                                                ]}
                                                x="programme"
                                                y="resilience"
                                                labelComponent={
                                                    <VictoryTooltip
                                                        cornerRadius={({ datum }) => (datum.x > 6 ? 0 : 4)}
                                                        pointerLength={0}
                                                        flyoutPadding={8}
                                                        flyoutStyle={{
                                                            fill: "white",
                                                            stroke: "#e3e3e3",
                                                        }}
                                                    />
                                                }
                                            />
                                        </VictoryChart>
                                    </Box>
                                </VCard>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box mb={2}>
                                <VCard
                                    title={"Sense of Community"}
                                    subtitle={
                                        "See a comparison of the changes in sense of community scores across the programme"
                                    }
                                >
                                    <Box className={classes.chartContainer}>
                                        <VictoryChart
                                            domainPadding={{ x: 200 }}
                                            theme={VictoryTheme.material}
                                            animate={{
                                                duration: 2000,
                                                onLoad: { duration: 1000 },
                                            }}
                                            containerComponent={<VictoryContainer responsive={true} />}
                                            width={isSmall ? 500 : 900}
                                            height={isSmall ? 350 : 300}
                                        >
                                            <VictoryAxis
                                                tickValues={[1, 2]}
                                                tickFormat={["Pre Programme", "Post Programme"]}
                                            />
                                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                                            <VictoryBar
                                                data={[
                                                    {
                                                        programme: 1,
                                                        senseOfCommunity: reportData.senseOfCommunity.pre,
                                                        label: reportData.senseOfCommunity.pre,
                                                    },
                                                    {
                                                        programme: 2,
                                                        senseOfCommunity: reportData.senseOfCommunity.post,
                                                        label: reportData.senseOfCommunity.post,
                                                    },
                                                ]}
                                                x="programme"
                                                y="senseOfCommunity"
                                                labelComponent={
                                                    <VictoryTooltip
                                                        cornerRadius={({ datum }) => (datum.x > 6 ? 0 : 4)}
                                                        pointerLength={0}
                                                        flyoutPadding={8}
                                                        flyoutStyle={{
                                                            fill: "white",
                                                            stroke: "#e3e3e3",
                                                        }}
                                                    />
                                                }
                                            />
                                        </VictoryChart>
                                    </Box>
                                </VCard>
                            </Box>
                            <Box mb={2}>
                                <VCard
                                    title={"Programme Experience"}
                                    subtitle={"See how your programme has effected the participants"}
                                >
                                    <Box className={classes.chartContainer}>
                                        <VictoryChart
                                            domainPadding={{ x: 75 }}
                                            theme={VictoryTheme.material}
                                            animate={{
                                                duration: 2000,
                                                onLoad: { duration: 1000 },
                                            }}
                                            containerComponent={<VictoryContainer responsive={true} />}
                                            width={isSmall ? 500 : 900}
                                            height={isSmall ? 350 : 300}
                                        >
                                            <VictoryAxis
                                                tickValues={[1, 2, 3]}
                                                tickFormat={[
                                                    "Likely to recommend",
                                                    "Improved Mental Health",
                                                    "New perspective",
                                                ]}
                                            />
                                            <VictoryAxis dependentAxis tickFormat={(x) => x} />
                                            <VictoryBar
                                                style={{
                                                    data: { fill: "#c43a31" },
                                                }}
                                                data={[
                                                    {
                                                        metric: 1,
                                                        result: reportData.likelyToRecommend,
                                                        label: reportData.likelyToRecommend,
                                                    },
                                                    {
                                                        metric: 2,
                                                        result: reportData.improvedMentalHealth,
                                                        label: reportData.improvedMentalHealth,
                                                    },
                                                    {
                                                        metric: 3,
                                                        result: reportData.newPerspectives,
                                                        label: reportData.newPerspectives,
                                                    },
                                                ]}
                                                x="metric"
                                                y="result"
                                                labelComponent={
                                                    <VictoryTooltip
                                                        cornerRadius={({ datum }) => (datum.x > 6 ? 0 : 4)}
                                                        pointerLength={0}
                                                        flyoutPadding={8}
                                                        flyoutStyle={{
                                                            fill: "white",
                                                            stroke: "#e3e3e3",
                                                        }}
                                                    />
                                                }
                                            />
                                        </VictoryChart>
                                    </Box>
                                </VCard>
                            </Box>
                        </>
                    )}
                </>
            ) : !isProgrammeStarted ? null : (
                <Box mb={2}>
                    <VCard>
                        <SimpleBlankSlate
                            image={<img src={barchartImg} />}
                            header={"Programme Experience"}
                            content={
                                "When the programme is complete and participants start to submit their post programme surveys, you will see information such as likely to recommend, improved mental health and new perspective."
                            }
                            extra={
                                <>
                                    <Box mb={2}>
                                        <Typography variant={"body2"} color={"primary"}>
                                            (This will be available from the end of the overall programme&apos;s
                                            penultimate session)
                                        </Typography>
                                    </Box>
                                </>
                            }
                        />
                    </VCard>
                </Box>
            )}
        </>
    );
};
export default ProgrammeReport;
