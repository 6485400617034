import { node } from "prop-types";
import React, { ReactNode, useCallback, useState } from "react";
import { ParticipantToProgrammeType, SessionType } from "../../models/modelTypes";
import { SurveyType } from "../session/components/SurveyReminderCard";
import dayjs from "dayjs";

interface ProgrammeParticipantContextI {
    configureNextSession: (nextSession: SessionType) => void;
    configurePreviousSession: (previousSession?: SessionType) => void;
    configureSurveyState: (isFinalSessionOrAllComplete: boolean, finalSession?: SessionType) => void;
    previousSession?: SessionType;
    nextSession?: SessionType;
    surveyType?: SurveyType;
    loading: boolean;
    participantToProgramme: ParticipantToProgrammeType;
}

const ProgrammeParticipantContext = React.createContext({} as ProgrammeParticipantContextI);

interface ProgrammeParticipantProviderProps {
    children: ReactNode;
    participantToProgramme: ParticipantToProgrammeType;
}
export const ProgrammeParticipantProvider: React.FC<ProgrammeParticipantProviderProps> = ({
    children,
    participantToProgramme,
}) => {
    const [surveyType, setSurveyType] = useState<SurveyType>();
    const [nextSession, setNextSession] = React.useState<SessionType>();
    const [previousSession, setPreviousSession] = React.useState<SessionType>();
    const [loading, setLoading] = useState<boolean>(true);

    const configureNextSession = useCallback(async (nextSession: SessionType) => {
        setNextSession(nextSession);
    }, []);

    const configurePreviousSession = useCallback(async (previousSession?: SessionType) => {
        setPreviousSession(previousSession);
    }, []);

    const configureSurveyState = useCallback(
        async (isFinalSessionOrAllComplete: boolean, nextOrLastSession?: SessionType): Promise<void> => {
            setLoading(true);
            if (!participantToProgramme.hasOptedOutOfSurveys) {
                if (
                    !participantToProgramme.hasCompletedPreSurvey &&
                    (!nextOrLastSession || nextOrLastSession.sessionNumber < 2)
                ) {
                    setSurveyType(SurveyType.Pre);
                } else if (
                    !participantToProgramme.hasCompletedPostSurvey &&
                    participantToProgramme.hasCompletedPreSurvey
                ) {
                    const finalReminderDate = dayjs(nextOrLastSession?.date).add(14, "day");
                    if (isFinalSessionOrAllComplete && dayjs().isBefore(finalReminderDate)) {
                        setSurveyType(SurveyType.Post);
                    }
                }
            }
            setLoading(false);
        },
        [],
    );

    return (
        <ProgrammeParticipantContext.Provider
            value={{
                configureNextSession,
                configurePreviousSession,
                configureSurveyState,
                surveyType,
                loading,
                previousSession,
                nextSession,
                participantToProgramme,
            }}
        >
            {children}
        </ProgrammeParticipantContext.Provider>
    );
};

ProgrammeParticipantProvider.propTypes = {
    children: node,
};

export const useProgrammeParticipant = (): ProgrammeParticipantContextI =>
    React.useContext<ProgrammeParticipantContextI>(ProgrammeParticipantContext);
