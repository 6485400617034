import React, { FC } from "react";
import { Chip, ChipProps } from "@material-ui/core";
import { makeStyles, withStyles, createStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";
import { VTooltip } from "./VTooltip";
import { publicTheme } from "../../components/layout/themes/public.theme";

export type VPillProps = ChipProps & {
    label: string;
};

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
const NegativePillStyles = withStyles({
    root: {
        background: verbalTheme.palette.error.main,
        color: verbalTheme.palette.common.white,
    },
    label: {
        textTransform: "capitalize",
    },
})(Chip);

const PositivePillStyles = withStyles({
    root: {
        background: verbalTheme.palette.success.main,
        color: verbalTheme.palette.common.white,
    },
    label: {
        textTransform: "capitalize",
    },
})(Chip);

const AmberPillStyles = withStyles({
    root: {
        background: verbalTheme.palette.warning.main,
        color: verbalTheme.palette.common.white,
    },
    label: {
        textTransform: "capitalize",
    },
})(Chip);

export const VPill: React.FC<VPillProps> = (props: VPillProps): JSX.Element => {
    const { label, ...rest } = props;

    return <Chip label={label} {...rest} />;
};

export const VPillNegative: FC<ChipProps> = (props) => {
    const { children, ...rest } = props;
    return <NegativePillStyles {...rest}>{children}</NegativePillStyles>;
};

export const VPillPositive: FC<ChipProps> = (props) => {
    const { children, ...rest } = props;
    return <PositivePillStyles {...rest}>{children}</PositivePillStyles>;
};

export const VPillAmber: FC<ChipProps> = (props) => {
    const { children, ...rest } = props;
    return <AmberPillStyles {...rest}>{children}</AmberPillStyles>;
};

export const VPillClosedQuestion = (): JSX.Element => {
    const useStyles = makeStyles(() =>
        createStyles({
            pill: {
                backgroundColor: publicTheme.palette.themePill.main,
                color: verbalTheme.palette.common.white,
            },
        }),
    );
    const classes = useStyles();

    return (
        <VTooltip placement="right-start" title="Closed questions expect a yes/no answer">
            <div>
                <VPill className={classes.pill} size="small" label={"Closed Question"} />
            </div>
        </VTooltip>
    );
};
