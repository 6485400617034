import React, { PropsWithChildren } from "react";

type WizardStepProps = PropsWithChildren<{
    identifier: number;
    heading: string;
    completed: boolean;
}>;

export const WizardStep: React.FC<WizardStepProps> = (props: WizardStepProps) => {
    return <>{props.children}</>;
};

export default WizardStep;
