import { useState } from "react";
import { createContainer } from "react-tracked";
import { ReactourStep } from "reactour";

export interface TourGuideI {
    routeRegExp: RegExp | undefined;
    routeDefault: boolean;
    scrollOffset?: number;
    disableDotsNavigation?: boolean;
    disableKeyboard?: boolean | ("right" | "left" | "esc")[];
    disableClose?: boolean;
    onAfterOpen?(): void;
    onBeforeClose?(): void;
    steps: ReactourStep[];
}

export const TourGuideDefaults: TourGuideI = {
    routeRegExp: undefined,
    routeDefault: false,
    scrollOffset: 0,
    disableDotsNavigation: false,
    disableKeyboard: false,
    disableClose: false,
    onAfterOpen: function () {},
    onBeforeClose: function () {},
    steps: [] as ReactourStep[],
};

export type TourState = {
    tours: Map<string, TourGuideI>;
    refs: Map<string, React.MutableRefObject<HTMLElement | null>>;
    currentTour: TourGuideI | undefined;
    openTour: boolean;
    nextTourKey: string;
    currentTourKey: string;
    currentRoute: string;
};

const useValue = () =>
    useState<TourState>({
        tours: new Map<string, TourGuideI>(),
        refs: new Map<string, React.MutableRefObject<HTMLElement | null>>(),
        currentTour: undefined,
        openTour: false,
        nextTourKey: "",
        currentTourKey: "",
        currentRoute: "",
    });

export const {
    Provider: TourStateProvider,
    useTrackedState: useTrackedTourState,
    useUpdate: useSetTourState,
} = createContainer(useValue);
