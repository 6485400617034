import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { PublicUser } from "common/build/prisma/client";
import { get, loginUser } from "../client/api/public/auth";
import { node } from "prop-types";

interface ParticipantAuthContextI {
    user?: PublicUser;
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    loading: boolean;
    /*
     Keeps track of whether the user has performed a login action in SPA session.
     Used to differentiate when checking auth after a login action, and checking auth on page refresh.
     */
    performedLogin: boolean;
    token: string | null;

    login: (loginToken: string) => Promise<void>;
    logout: () => void;
}

const ParticipantAuthContext = React.createContext({} as ParticipantAuthContextI);

const storedToken = window.localStorage.getItem("token");

export const ParticipantAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<PublicUser>();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isAuthenticating, setIsAuthenticating] = useState<boolean>(!!storedToken);
    const [loading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(storedToken);

    const [performedLogin, setPerformedLogin] = useState<boolean>(false);
    useEffect(() => {
        // When the token is set and is not the same as the initially stored token, we've performed a login.
        // Logouts etc clear token and therefore also clear this flag.
        setPerformedLogin(!!token && token !== storedToken);
    }, [token]);

    useEffect(() => {
        setLoading(true);
        updateUser();
    }, [token]);

    const updateUser = useCallback(() => {
        if (token) {
            setIsAuthenticating(true);
            get()
                .then((user: PublicUser) => {
                    setUser(user);
                    setIsAuthenticated(true);
                })
                .catch(() => {
                    setUser(undefined);
                    setIsAuthenticated(false);
                })
                .finally(() => {
                    setIsAuthenticating(false);
                    setLoading(false);
                });
        } else {
            setUser(undefined);
            setIsAuthenticated(false);
            setIsAuthenticating(false);
            setLoading(false);
        }
    }, [token]);

    const login = useCallback((loginToken: string) => {
        setLoading(true);
        return loginUser(loginToken)
            .then(async (response) => {
                if (response.ok) {
                    const { token: sessionToken }: { token: string } = await response.json();
                    localStorage.setItem("token", sessionToken);
                    setToken(sessionToken);
                } else {
                    setToken(null);
                    const { errors } = await response.json();
                    if (errors && errors.length > 0) {
                        throw new Error(errors[0].msg);
                    } else {
                        throw new Error("Error logging in");
                    }
                }
            })
            .catch((error) => {
                setToken(null);
                setLoading(false);
                throw error;
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const logout = useCallback(() => {
        setToken(null);
        localStorage.removeItem("token");
        setIsAuthenticated(false);
        setUser(undefined);
    }, []);

    return (
        <ParticipantAuthContext.Provider
            value={{ user, isAuthenticated, isAuthenticating, loading, token, login, logout, performedLogin }}
        >
            {children}
        </ParticipantAuthContext.Provider>
    );
};

ParticipantAuthProvider.propTypes = {
    children: node,
};

export const useParticipantAuth = (): ParticipantAuthContextI => React.useContext(ParticipantAuthContext);
