import React from "react";
import { get } from "../../../client/api/session";
import { FetchProvider } from "../../../core/components/crud/contexts/fetch.context";
import { PrepareStory } from "./PrepareStory";
import { Box } from "@material-ui/core";
import { SessionFetchParameters } from "common/build/api-parameters/session";
import { useLocation } from "react-router-dom";

const params: SessionFetchParameters = {
    includeStory: true,
};

export const PrepareSession = (): JSX.Element => {
    const location = useLocation<{ classroomId?: number }>();
    const classroomId = location.state?.classroomId;
    return (
        <Box>
            <FetchProvider noun={"session"} getItem={get} includes={params}>
                <PrepareStory classroomId={classroomId} />
            </FetchProvider>
        </Box>
    );
};
export default PrepareSession;
