import { Dialog, DialogContentText, useMediaQuery } from "@material-ui/core";
import React from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { verbalTheme } from "../layout/themes/verbal.theme";

type SubscriptionExpiredDialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const SubscriptionExpiredDialog = (props: SubscriptionExpiredDialogProps): JSX.Element => {
    const { open, setOpen } = props;
    const fullScreen = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
            <MuiDialogTitle>Subscription Expired</MuiDialogTitle>
            <MuiDialogContent>
                <DialogContentText>
                    Please renew your subscription to continue using Verbal Wellbeing{" "}
                </DialogContentText>
            </MuiDialogContent>
        </Dialog>
    );
};
