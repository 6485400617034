import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../components/layout/Spinner";
import { Breadcrumb } from "../../../core/components/Breadcrumb";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { useCrud } from "../../../core/components/crud/contexts/crud.context";
import { ProgrammeType } from "../../../models/modelTypes";
import { ProgrammeParticipantScreen } from "../participants/ProgrammeParticipantScreen";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { AllSessionsGrid } from "../../session/components/AllSessionsGrid";
import { NextSessionCard } from "../../session/components/NextSessionCard";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { Error } from "../../../core/components/Error";
import { useAuth } from "../../../contexts/auth.context";
import { ProgrammeReport } from "../report/ProgrammeReport";
import ManageProgrammeFacilitators from "../wizard/steps/ManageProgrammeFacilitators";
import { useQueryStringState } from "../../../hooks/useQueryString";
import { useMobile } from "../../../hooks/useMobile";
import { DestructiveButton, SecondaryButton } from "../../layout/Button/Button";
import { deleteProgramme, update } from "../../../client/api/programme";
import { Delete, RestoreFromTrash } from "@material-ui/icons";
import { useAlert } from "../../../contexts/alert.context";
import { VTooltip } from "../../../core/components/VTooltip";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
    padding?: number;
}

export function TabPanel(props: TabPanelProps): JSX.Element {
    const { children, value, index, padding, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={padding !== undefined ? padding : 3} height="100%">
                    {children}
                </Box>
            )}
        </div>
    );
}

type ViewProgrammeProps = {
    labelProperty?: string;
};

const tabs = ["sessions", "participants", "facilitators", "data"];

export const ViewProgramme = (props: ViewProgrammeProps): JSX.Element => {
    const { labelProperty } = props;

    const history = useHistory();
    const { hasPermission } = useAuth();
    const { isMobile, isTablet } = useMobile();
    const { item, fetching, fetchItem } = useFetch<ProgrammeType>();
    const { noun } = useCrud<ProgrammeType>();
    const { setAlert } = useAlert();

    const [error, setError] = useState<boolean>(false);
    const [tabValue, setTabValue] = useQueryStringState("tab", tabs[0]);
    const tabIndex = Math.max(tabs.indexOf(tabValue), 0);

    const [guideTooltip, setGuideTooltip] = useState<boolean>(false);

    // delete dialog
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
    const fullScreen = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setTabValue(tabs[newValue]);
    };

    const { id } = useParams<{ id: string }>();

    const useStyles = makeStyles(() =>
        createStyles({
            headerColor: {
                backgroundColor: "#F9F9FB",
            },
            sessionsOverview: {
                display: "block",
                [verbalTheme.breakpoints.up("md")]: {
                    display: "flex",
                },
            },
            sessionsAll: {
                [verbalTheme.breakpoints.up("md")]: {
                    flex: 1,
                },
            },
            sessionsNext: {
                maxWidth: "100%",
                marginRight: verbalTheme.spacing(3),
                marginBottom: verbalTheme.spacing(3),
                [verbalTheme.breakpoints.up("md")]: {
                    maxWidth: "220px",
                    marginBottom: verbalTheme.spacing(0),
                },
            },
        }),
    );

    const classes = useStyles();

    useEffect(() => {
        if (parseInt(id) !== item?.id) {
            fetchItem(parseInt(id))
                .then(() => {
                    setError(false);
                })
                .catch(() => setError(true));
        }
    }, [id, item?.id]);

    useEffect(() => {
        if (!fetching && item && tabIndex === 0) {
            if (parseInt(id) !== item?.id) {
                setGuideTooltip(true);
            }

            const timeout = setTimeout(() => {
                setGuideTooltip(false);
            }, 4000);

            return () => clearTimeout(timeout);
        }
    }, [item, fetching, tabIndex]);

    if (error) {
        return <Error description={`Unable to load ${noun}`} />;
    }

    const summaryLabel =
        labelProperty &&
        item &&
        [
            item.ageRange !== null ? `${item.ageRange} Years` : null,
            item.frequency,
            item.Sessions?.length !== null ? `${item.Sessions?.length} Sessions` : null,
        ]
            .filter((value) => value != null)
            .join(" / ");

    return (
        <>
            {!isTablet && isMobile && <div style={{ marginTop: 56 }} />}
            <Box className={classes.headerColor}>
                <Box p={4}>
                    <Box mb={2}>
                        <Breadcrumb
                            current={
                                labelProperty && item && item[labelProperty] ? `${item[labelProperty]}` : `${noun}`
                            }
                        />
                    </Box>
                    <Box mb={2}>
                        <Typography variant="h2" component="h1" color="textPrimary" align="left">
                            {`${labelProperty && item && item[labelProperty] ? `${item[labelProperty]}` : `${noun}`}`}
                        </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                        <Typography variant="body2" color="textPrimary" align="left">
                            {`${summaryLabel}`}
                        </Typography>

                        {tabIndex === 0 ? (
                            <DestructiveButton
                                startIcon={item?.deleted ? <RestoreFromTrash /> : <Delete />}
                                size="small"
                                onClick={() => setConfirmDelete(true)}
                            >
                                {item?.deleted ? "Restore" : "Delete"} Programme
                            </DestructiveButton>
                        ) : null}
                    </Box>
                </Box>
            </Box>

            {!fetching && item ? (
                <>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={classes.headerColor}
                    >
                        <Tab label="Sessions" id="session-tab" />

                        <Tab label="Participants" id="participant-tab" />

                        <VTooltip
                            open={guideTooltip}
                            title="You can use these tabs to view/manage participants, facilitators and to view programme reports"
                        >
                            <Tab label="Facilitators" id="facilitators-tab" />
                        </VTooltip>
                        {hasPermission("ProgrammeReportView") && !item?.deleted && <Tab label="Data" id="data-tab" />}
                    </Tabs>
                    <TabPanel index={0} value={tabIndex}>
                        <Box mb={3}>
                            {item && item.Sessions && item.Sessions.length > 0 && (
                                <>
                                    <div className={classes.sessionsOverview}>
                                        {item.Sessions.filter((s) => s.state !== "Complete").length > 0 && (
                                            <div className={classes.sessionsNext}>
                                                <NextSessionCard sessions={item?.Sessions} />
                                            </div>
                                        )}
                                        <div className={classes.sessionsAll}>
                                            <AllSessionsGrid sessions={item?.Sessions} excludeNextSession={true} />
                                        </div>
                                    </div>
                                </>
                            )}
                            {(!item || !item.Sessions || item.Sessions.length === 0) && (
                                <>
                                    <Typography variant="h2">No Sessions Found</Typography>
                                </>
                            )}
                        </Box>
                    </TabPanel>
                    <TabPanel index={1} value={tabIndex}>
                        <ProgrammeParticipantScreen programmeId={item.id} />
                    </TabPanel>
                    <TabPanel index={2} value={tabIndex}>
                        {item && (
                            <ManageProgrammeFacilitators
                                programme={item}
                                programmeCreate={false}
                                onSubmit={() => {
                                    // Formik makes onSubmit required. We only need it in programme creation and not here
                                    // This is just to keep Formik and lint happy as empty functions aren't aloud
                                    // See https://github.com/formium/formik/issues/2675 for GitHub issue
                                    console.log();
                                }}
                            />
                        )}
                    </TabPanel>
                    {hasPermission("ProgrammeReportView") && !item?.deleted && (
                        <TabPanel index={3} value={tabIndex}>
                            <Container component="div">
                                <ProgrammeReport programmeId={parseInt(id)} />
                            </Container>
                        </TabPanel>
                    )}
                </>
            ) : (
                <Spinner />
            )}
            <Dialog open={confirmDelete} onClose={() => setConfirmDelete(false)} fullScreen={fullScreen}>
                <DialogTitle>{item?.deleted ? "Restore" : "Delete"} Programme?</DialogTitle>
                <DialogContent>
                    <DialogContentText>{`Are you sure you want to ${
                        item?.deleted ? "restore" : "delete"
                    } this programme?`}</DialogContentText>
                    {item?.deleted ? (
                        <DialogContentText>
                            If you restore this programme it will be available in the programme list again. Any report
                            data will also be restored. If you choose to delete this programme again, it will be
                            archived for 30 days then it will be deleted permanently
                        </DialogContentText>
                    ) : (
                        <DialogContentText>
                            If you delete this programme it willl be archived for 30 days. You can restore the programme
                            at any time before 30 days. After 30 days the programme will be permanently deleted. You can
                            view Archived programmes on the programme list using the Archived filter
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <DestructiveButton
                        startIcon={item?.deleted ? <RestoreFromTrash /> : <Delete />}
                        onClick={() => {
                            if (item?.id) {
                                if (item.deleted) {
                                    update(item.id, { deleted: false }).then(() => {
                                        setAlert("Programme successfully restored", "success");
                                        history.push(`/programmes`);
                                    });
                                } else {
                                    deleteProgramme(item.id).then(() => {
                                        setAlert(
                                            "Programme successfully deleted. Go to the status drop down menu if you need to revisit any of your archived programmes",
                                            "success",
                                            6000,
                                        );
                                        history.push("/programmes");
                                    });
                                }
                            }
                        }}
                    >
                        {item?.deleted ? "Restore" : "Delete"} Programme
                    </DestructiveButton>
                    <SecondaryButton onClick={() => setConfirmDelete(false)}>Cancel</SecondaryButton>
                </DialogActions>
            </Dialog>
        </>
    );
};
export default ViewProgramme;
