import { node } from "prop-types";
import { admitSessionParticipants, update, updateSessionStoryPart } from "../../../../client/api/session";
import React, { ReactNode, useCallback, useState } from "react";
import { ToolbarMode, useBaseLayout } from "../../../layout/BaseLayout.context";
import { useIntQueryStringState } from "../../../../hooks/useQueryString";

interface SessionModeContextI {
    activeStep: number;
    completedSteps: { [k: number]: boolean };
    presentationMode: boolean;
    annotationsMode: boolean;
    currentQuestion: number;
    currentPart: number;
    showQuestions: boolean;
    participantsAdmitted: boolean;
    completedParts: number[];
    hidden: boolean;
    participantCount: number;

    changeStep: (newStep: number) => void;
    changeQuestion: (newQuestion: number, sessiondId?: number, isAuthenticated?: boolean) => void;
    changePart: (newPart: number, sessiondId?: number, isAuthenticated?: boolean) => void;
    submitStep: () => void;
    togglePresentationMode: () => void;
    toggleAnnotationsMode: () => void;
    toggleShowQuestions: () => void;
    admitParticipants: (sessionId: number) => void;
    onAdmitParticipants: () => void;
    toggleHideSession: (hide?: boolean) => void;
    onParticipantCountChange: (count: number) => void;
}

const SessionModeContext = React.createContext({} as SessionModeContextI);

export const SessionModeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [activeStep, setActiveStep] = useIntQueryStringState("step", 0); //useState<number>(0);
    const [completedSteps, setCompletedSteps] = React.useState<{ [k: number]: boolean }>({});
    const [presentationMode, setPresentationMode] = React.useState<boolean>(false);
    const [annotationsMode, setAnnotationsMode] = React.useState<boolean>(true);
    const [currentPart, setCurrentPart] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [showQuestions, setShowQuestions] = useState<boolean>(false);
    const [participantsAdmitted, setParticipantsAdmitted] = useState<boolean>(false);
    const [completedParts, setCompletedParts] = useState<number[]>([]);
    const [hidden, setHidden] = useState<boolean>(false);
    const [participantCount, setParticipantCount] = useState<number>(0);

    const { setToolbarMode } = useBaseLayout();

    const changeStep = useCallback((newStep: number) => {
        setActiveStep(newStep);
    }, []);

    const changeQuestion = useCallback(async (newQuestion: number, sessionId?: number, isAuthenticated?: boolean) => {
        setCurrentQuestion(newQuestion);
        if (isAuthenticated && sessionId) {
            await update(sessionId, { currentQuestion: newQuestion });
        }
    }, []);

    const changePart = useCallback(async (newPart: number, sessionId?: number, isAuthenticated?: boolean) => {
        setCurrentPart(newPart);
        setCurrentQuestion(0);
        if (isAuthenticated && sessionId) {
            setCompletedParts((prevParts) => [...prevParts, newPart]);
            await updateSessionStoryPart(sessionId, { currentPart: newPart });
        }
    }, []);

    const togglePresentationMode = useCallback(() => {
        if (setToolbarMode) {
            setToolbarMode(presentationMode ? ToolbarMode.Dark : ToolbarMode.Light);
        }
        setPresentationMode((prev) => !prev);
    }, [presentationMode]);

    const toggleAnnotationsMode = useCallback(() => {
        setAnnotationsMode((prev) => !prev);
    }, []);

    const toggleShowQuestions = useCallback(() => {
        setShowQuestions((prev) => !prev);
    }, []);

    const submitStep = useCallback(() => {
        setCompletedSteps((prevCompletedSteps) => ({ ...prevCompletedSteps, [activeStep]: true }));
    }, [activeStep]);

    const admitParticipants = useCallback(async (sessionId: number) => {
        await admitSessionParticipants(sessionId, { isUsersAdmitted: true });
        onAdmitParticipants();
    }, []);

    const onAdmitParticipants = useCallback(() => {
        setParticipantsAdmitted(true);
    }, []);

    const toggleHideSession = useCallback((hide?: boolean) => {
        if (hide !== undefined) {
            setHidden(hide);
        } else {
            setHidden((prev) => !prev);
        }
    }, []);

    const onParticipantCountChange = useCallback((count: number) => {
        setParticipantCount(count);
    }, []);

    return (
        <SessionModeContext.Provider
            value={{
                activeStep,
                completedSteps,
                changeStep,
                submitStep,
                togglePresentationMode,
                presentationMode,
                toggleAnnotationsMode,
                annotationsMode,
                currentPart,
                currentQuestion,
                changePart,
                changeQuestion,
                showQuestions,
                toggleShowQuestions,
                admitParticipants,
                participantsAdmitted,
                completedParts,
                onAdmitParticipants,
                hidden,
                toggleHideSession,
                participantCount,
                onParticipantCountChange,
            }}
        >
            {children}
        </SessionModeContext.Provider>
    );
};

SessionModeProvider.propTypes = {
    children: node,
};

export const useSessionMode = (): SessionModeContextI => React.useContext<SessionModeContextI>(SessionModeContext);
