import { createStyles, Dialog, DialogContentText, IconButton, makeStyles, useMediaQuery } from "@material-ui/core";
import React from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import { verbalTheme } from "../layout/themes/verbal.theme";

type SubscriptionContactDialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const SubscriptionContactDialog = (props: SubscriptionContactDialogProps): JSX.Element => {
    const { open, setOpen } = props;
    const fullScreen = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
        }),
    );

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullScreen={fullScreen}>
            <MuiDialogTitle>
                <IconButton className={classes.closeButton} onClick={() => setOpen(false)}>
                    <Close />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent>
                <DialogContentText>
                    Please contact your Administrator for Account Subscription Information{" "}
                </DialogContentText>
            </MuiDialogContent>
        </Dialog>
    );
};
