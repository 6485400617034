import { useCallback, useState } from "react";
import { getMappedStory as getMappedStoryDetails } from "../../client/api/public/story";
import { MappedStoryType } from "../../models/modelTypes";
import { StoryFetchParameters } from "../../../../common/build/api-parameters/story";

interface StoryI {
    mappedStory?: MappedStoryType;

    loading: boolean;

    getMappedStory: (mappedStoryId: number, args?: StoryFetchParameters) => Promise<void>;
}

export const useStory = (): StoryI => {
    const [mappedStory, setMappedStory] = useState<MappedStoryType>();

    const [loading, setLoading] = useState<boolean>(false);

    const getMappedStory = useCallback((mappedStoryId: number, args?) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            getMappedStoryDetails(mappedStoryId, args)
                .then(async (response: MappedStoryType) => {
                    setMappedStory(response);
                    resolve();
                })
                .catch((error) => {
                    setMappedStory(undefined);
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    return {
        mappedStory,
        loading,
        getMappedStory,
    };
};
