import { node } from "prop-types";
import React, { useContext, createContext, useCallback, useState, FC, ReactNode } from "react";
import {
    add,
    addMood,
    addTeacher,
    get,
    list,
    editClassroom,
    removeTeacher,
    addParticipant,
} from "../../../client/api/classroom";
import { ClassroomType, MoodType, ParticipantToClassroomType } from "../../../models/modelTypes";

interface ClassroomContextI {
    allClassrooms: ClassroomType[];
    classroom?: ClassroomType;
    loading: boolean;
    error?: string;
    currentParticipant?: ParticipantToClassroomType;

    setCurrentParticipant: (p?: ParticipantToClassroomType) => void;
    setClassroom: (classroom?: ClassroomType) => void;
    getClassroom: (id: number) => Promise<void>;
    listClassrooms: () => Promise<void>;
    addClassroom: (details: Partial<ClassroomType>) => Promise<void>;
    addTeacherToClassroom: (id: number, userId: number) => Promise<void>;
    addStudentToClassroom: (
        id: number,
        student: {
            id: number;
            name?: string;
            email?: string | null;
            phoneNumber?: string | null;
            organisationId?: number;
        },
    ) => Promise<void>;
    removeTeacherFromClassroom: (id: number, userId: number) => Promise<void>;
    addStudentMood: (classroomId: number, moodDetails: Partial<MoodType>) => Promise<void>;
    editClassroomState: (id: number, classroomId: number) => Promise<void>;
    getMoodToday: (s: ParticipantToClassroomType, type: string, sessionId: number | null) => MoodType | undefined;
    clearProvider: () => void;
}

const ClassroomContext = createContext({} as ClassroomContextI);

export const ClassroomProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [allClassrooms, setAllClassrooms] = useState<ClassroomType[]>([]);
    const [classroom, setClassroom] = useState<ClassroomType>();
    const [currentParticipant, setCurrentParticipant] = useState<ParticipantToClassroomType>();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    const getClassroom = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            get(id, {})
                .then((value) => {
                    setClassroom(value);
                    setError(undefined);
                    resolve();
                })
                .catch(() => {
                    setClassroom(undefined);
                    reject("Unable to get classroom");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const listClassrooms = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            list({ take: 1000 })
                .then((values) => {
                    setAllClassrooms(values.items.sort((a, b) => a.id - b.id));
                    setError(undefined);
                    resolve();
                })
                .catch(() => {
                    setAllClassrooms([]);
                    reject("Unable to get all classrooms");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addClassroom = useCallback((details: Partial<ClassroomType>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            add(details)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setClassroom(undefined);
                        reject("Unable to add classroom as response not OK");
                    }
                })
                .catch(() => {
                    setClassroom(undefined);
                    reject("Unable to add classroom");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addTeacherToClassroom = useCallback((id: number, userId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            addTeacher(id, userId)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        setAllClassrooms((prev) =>
                            [...prev.filter((cr) => cr.id !== classroomResponse.id), classroomResponse].sort(
                                (a, b) => a.id - b.id,
                            ),
                        );
                        resolve();
                    } else {
                        setError("Unable to add teacher because response not OK");
                        reject("Unable to add teacher because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to add teacher to classroom");
                    reject("Unable to add teacher to classroom");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addStudentToClassroom = useCallback(
        (
            id: number,
            student: {
                id: number;
                name?: string;
                email?: string | null;
                phoneNumber?: string | null;
                organisationId?: number;
            },
        ) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                addParticipant(id, student)
                    .then(async (response) => {
                        if (response.ok) {
                            const classroomResponse: ClassroomType = await response.json();
                            setClassroom(classroomResponse);
                            setAllClassrooms((prev) =>
                                [...prev.filter((cr) => cr.id !== classroomResponse.id), classroomResponse].sort(
                                    (a, b) => a.id - b.id,
                                ),
                            );
                            setError(undefined);
                            resolve();
                        } else {
                            setError("Unable to add student because response not OK");
                            reject("Unable to add student because response not OK");
                        }
                    })
                    .catch(() => {
                        setError("Unable to add student to classroom");
                        reject("Unable to add student to classroom");
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const removeTeacherFromClassroom = useCallback((id: number, userId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            removeTeacher(id, userId)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to remove teacher because response not OK");
                        reject("Unable to remove teacher because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to remove teacher");
                    reject("Unable to remove teacher");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addStudentMood = useCallback((classroomId: number, moodDetails: Partial<MoodType>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            addMood(classroomId, moodDetails)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to add mood because response not OK");
                        reject("Unable to add mood because response not OK");
                    }
                })
                .catch(() => {
                    setError("Unable to add Mood");
                    reject("Unable to add mood");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const editClassroomState = useCallback((id: number, classroomId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            editClassroom(id, classroomId)
                .then(async (response) => {
                    if (response.ok) {
                        const classroomResponse: ClassroomType = await response.json();
                        setClassroom(classroomResponse);
                        setError(undefined);
                        resolve();
                    } else {
                        setError("Unable to edit classroom details as response NOT OK");
                        reject("Unable to edit classroom details as response NOT OK");
                    }
                })
                .catch(() => {
                    setError("Unable to Edit Class");
                    reject("Unable to Edit Class");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getMoodToday = (
        s: ParticipantToClassroomType,
        type: string,
        sessionId: number | null,
    ): MoodType | undefined => {
        const todayDate = new Date();
        const moodToday = s.Moods?.find((m) => {
            const moodDate = new Date(m.date);
            if (
                m.type === type &&
                (sessionId === null || m.sessionId === sessionId) &&
                moodDate?.getDate() === todayDate.getDate() &&
                moodDate?.getMonth() === todayDate.getMonth() &&
                moodDate?.getFullYear() === todayDate.getFullYear()
            ) {
                return m;
            } else {
                return undefined;
            }
        });

        return moodToday;
    };

    const clearProvider = useCallback(() => {
        setClassroom(undefined);
        setError(undefined);
        setLoading(false);
    }, []);

    return (
        <ClassroomContext.Provider
            value={{
                allClassrooms,
                classroom,
                currentParticipant,
                loading,
                setCurrentParticipant,
                getClassroom,
                listClassrooms,
                addClassroom,
                addTeacherToClassroom,
                addStudentToClassroom,
                setClassroom,
                removeTeacherFromClassroom,
                addStudentMood,
                editClassroomState,
                getMoodToday,
                clearProvider,
                error,
            }}
        >
            {children}
        </ClassroomContext.Provider>
    );
};

ClassroomProvider.propTypes = {
    children: node,
};

export const useClassroom = (): ClassroomContextI => useContext<ClassroomContextI>(ClassroomContext);
