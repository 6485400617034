import { useCallback, useState } from "react";
import {
    get,
    getFacilitatorMapped,
    addFacilitatorMapped,
    publishMappedStory as publishStory,
    addMappedStoryPartQuestion,
    removeMappedStoryPartQuestion,
    getMapped,
    list,
} from "../client/api/story";
import { useAlert } from "../contexts/alert.context";
import {
    FacilitatorStoryPartType,
    MappedStoryPartType,
    MappedStoryQuestionType,
    MappedStoryType,
    StoryType,
} from "../models/modelTypes";

interface StoryI {
    stories: StoryType[];
    story?: StoryType;
    facilitatorStoryParts?: FacilitatorStoryPartType[];

    loading: boolean;

    listStories: () => void;
    getStory: (id: number) => void;
    getFacilitatorStoryParts: (storyId: number) => Promise<void>;
    addFacilitatorStoryPart: (facilitatorStoryPart: Partial<FacilitatorStoryPartType>) => Promise<void>;

    // Mapped story
    mappedStory?: MappedStoryType;
    currentPart?: MappedStoryPartType;
    addStoryPartQuestion: (
        mappedStoryId: number,
        partNum: number,
        collectionId: number,
        question: Partial<MappedStoryQuestionType>,
    ) => Promise<void>;
    removeStoryPartQuestion: (mappedStoryId: number, partNum: number, order: number) => Promise<void>;
    setCurrentMappedStory: (mappedStory: MappedStoryType) => void;
    publishMappedStory: (mappedStoryId: number) => Promise<void>;

    getMappedStory: (id: number) => Promise<void>;
}

export const useStory = (): StoryI => {
    const [stories, setStories] = useState<StoryType[]>([]);
    const [story, setStory] = useState<StoryType>();
    const [facilitatorStoryParts, setFacilitatorStoryParts] = useState<FacilitatorStoryPartType[]>([]);
    const [mappedStory, setMappedStory] = useState<MappedStoryType>();

    const [loading, setLoading] = useState<boolean>(false);

    const { setAlert } = useAlert();

    const listStories = useCallback(() => {
        setLoading(true);

        list({ filterPublished: true, take: 1000 })
            .then((values: { items: StoryType[]; total: number }) => {
                setStories(values.items);
                setLoading(false);
            })
            .catch(() => {
                setStories([]);
                setAlert("Error loading stories", "error");
                setLoading(false);
            });
    }, []);

    const getStory = useCallback((id: number) => {
        setLoading(true);

        get(id, {})
            .then(async (story: StoryType) => {
                setStory(story);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const getFacilitatorStoryParts = useCallback((storyId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            getFacilitatorMapped(storyId)
                .then(async (response: FacilitatorStoryPartType[]) => {
                    setFacilitatorStoryParts(response);
                    resolve();
                })
                .catch((error) => {
                    setFacilitatorStoryParts([]);
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const addFacilitatorStoryPart = useCallback((facilitatorStoryPart: Partial<FacilitatorStoryPartType>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            addFacilitatorMapped(facilitatorStoryPart)
                .then(async (response: Response) => {
                    if (response.ok) {
                        resolve();
                    } else {
                        setFacilitatorStoryParts([]);
                        setAlert("Error adding story", "error");
                    }
                })
                .catch((error) => {
                    setFacilitatorStoryParts([]);
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const addStoryPartQuestion = useCallback(
        (mappedStoryId: number, partNum: number, collectionId: number, question: Partial<MappedStoryQuestionType>) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                addMappedStoryPartQuestion(mappedStoryId, partNum, collectionId, question)
                    .then(async (response: Response) => {
                        if (response.ok) {
                            const mappedStoryResponse: MappedStoryType = await response.json();
                            setMappedStory(mappedStoryResponse);
                            resolve();
                        } else {
                            reject("Unable to add question to story part");
                        }
                    })
                    .catch((error) => reject(error.message))
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const removeStoryPartQuestion = useCallback((mappedStoryId: number, partNum: number, order: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            removeMappedStoryPartQuestion(mappedStoryId, partNum, order)
                .then(async (response: Response) => {
                    if (response.ok) {
                        const mappedStoryResponse: MappedStoryType = await response.json();
                        setMappedStory(mappedStoryResponse);
                        resolve();
                    } else {
                        reject("Unable to remove question from story part");
                    }
                })
                .catch((error) => reject(error.message))
                .finally(() => setLoading(false));
        });
    }, []);

    const setCurrentMappedStory = useCallback((mappedStory: MappedStoryType) => {
        setMappedStory(mappedStory);
    }, []);

    const publishMappedStory = useCallback((mappedStoryId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            publishStory(mappedStoryId)
                .then(async (response: Response) => {
                    if (response.ok) {
                        const mappedStoryResponse: MappedStoryType = await response.json();
                        setMappedStory(mappedStoryResponse);
                        resolve();
                    } else {
                        reject("Unable to publish story");
                    }
                })
                .catch((error) => {
                    reject(error.message);
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getMappedStory = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            getMapped(id, {})
                .then(async (response: MappedStoryType) => {
                    setMappedStory(response);
                    resolve();
                })
                .catch((error) => reject(error.message))
                .finally(() => setLoading(false));
        });
    }, []);

    return {
        stories,
        story,
        facilitatorStoryParts,
        loading,
        listStories,
        getStory,
        getFacilitatorStoryParts,
        addFacilitatorStoryPart,
        mappedStory,
        setCurrentMappedStory,
        publishMappedStory,
        addStoryPartQuestion,
        removeStoryPartQuestion,
        getMappedStory,
    };
};
