import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Spinner from "../../components/layout/Spinner";

type AsyncSearchProps<T> = {
    label: string;
    property: string;
    onSearchChange: (searchTerm: string) => Promise<{ items: T & { [key: string]: string }[]; total: number }>;
    onSelected: (property: string, newValue: unknown) => void;
    valueProperty?: string;
    textProperty?: string;
    value: unknown;
    error: boolean;
    helperText?: string;
    disabled?: boolean;
};

type Option = {
    value: string;
    text: string;
};

export const AsyncSearch = <T,>(props: AsyncSearchProps<T>): JSX.Element => {
    const {
        label,
        property,
        onSearchChange,
        onSelected,
        valueProperty = "id",
        textProperty = "name",
        value: currentValue,
        error,
        helperText,
        disabled,
    } = props;

    const [options, setOptions] = useState<Option[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [defaultValue, setDefaultValue] = useState<Option>();
    const [value, setValue] = useState<Option>();

    const search = async (searchTerm: string) => {
        setSearching(true);
        setSearchTerm(searchTerm);
        const { items } = await onSearchChange(searchTerm);
        const options: Option[] = items.map((item) => ({
            value: `${item[valueProperty]}`,
            text: item[textProperty],
        }));
        setOptions(options);
        setSearching(false);
    };

    useEffect(() => {
        setLoading(true);
        onSearchChange(searchTerm).then(({ items }) => {
            const options: Option[] = items.map((item) => ({
                value: `${item[valueProperty]}`,
                text: item[textProperty],
            }));
            setOptions(options);
            const selectedOption = options.find((option) => option.value === `${currentValue}`);
            if (selectedOption) {
                setDefaultValue(selectedOption);
            }
            setLoading(false);
        });
    }, []);

    return (
        <>
            {loading && <Spinner />}
            {!loading && (
                <Autocomplete
                    id={property}
                    style={{ width: 300 }}
                    loading={searching}
                    options={options}
                    defaultValue={defaultValue}
                    value={value}
                    getOptionSelected={(option, value) => option.value === value.value}
                    getOptionLabel={(option: Option) => (option.text ? option.text : "")}
                    onChange={(_, newValue: Option | null) => {
                        setValue(newValue ?? undefined);
                        onSelected(property, newValue?.value);
                    }}
                    onInputChange={(_, newInputValue) => {
                        search(newInputValue);
                    }}
                    inputValue={searchTerm}
                    renderInput={(params) => (
                        <TextField {...params} label={label} variant="outlined" error={error} helperText={helperText} />
                    )}
                    disabled={disabled}
                />
            )}
        </>
    );
};
export default AsyncSearch;
