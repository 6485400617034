import React, { useState } from "react";
import { Box, Button, CircularProgress, Grid, Link, TextField, Typography } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { HomeScreenView, useHome } from "../hooks/home.context";
import { PublicUserInputFields } from "../../../models/modelTypes";
import { useParams } from "react-router-dom";
import { phoneNumberValidation } from "../../../utils/phoneNumberValidation";

export const EnterDetails = (): JSX.Element => {
    const { onConfirmDetails, publicUser, participant, details, changeView } = useHome();
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const { jwt } = useParams<{ jwt?: string }>();
    const [participantForename, ...participantName] = participant?.name.split(" ") || [];
    const initialValues: PublicUserInputFields = {
        firstName: publicUser?.firstName ?? participantForename ?? "",
        lastName: publicUser?.lastName ?? participantName.join(" ") ?? "",
        phoneNumber: publicUser?.phoneNumber ?? participant?.phoneNumber ?? "",
    };

    const onSubmit = (values: PublicUserInputFields, formikHelpers: FormikHelpers<PublicUserInputFields>) => {
        const { setSubmitting } = formikHelpers;
        onConfirmDetails(jwt || "", values)
            .then(() => {
                setError(undefined);
            })
            .catch(() => {
                setError("Error setting up account");
            })
            .finally(() => {
                setProcessing(false);
                setSubmitting(false);
            });
    };

    if (
        publicUser &&
        publicUser.Participant &&
        participant &&
        publicUser.Participant.filter((p) => p.id === participant.id).length > 0
    ) {
        return (
            <Box>
                <Box mb={3}>
                    <Typography variant="h5" component="h1" align="left">
                        This invitation is no longer active, if you&apos;ve created an account please{" "}
                        <Link href="/sign-in" color="textPrimary" underline="always">
                            Sign In
                        </Link>
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <>
            <Box>
                <Box mb={3}>
                    <Typography variant="h5" component="h1" align="left">
                        Tell us a little about you
                    </Typography>
                </Box>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={yup.object({
                        firstName: yup.string().required("First name is required"),
                        lastName: yup.string().required("Last name is required"),
                        phoneNumber: phoneNumberValidation,
                    })}
                    validateOnChange={false}
                >
                    {({ submitForm, errors, values, handleChange, touched, isSubmitting }) => {
                        return (
                            <>
                                <Box mb={1}>
                                    <Box display="flex" alignItems="center" mb={0.5}>
                                        <Box mr={1}>
                                            <Typography variant="body2">
                                                <Box component="span" fontWeight="700">
                                                    Email
                                                </Box>
                                            </Typography>
                                        </Box>
                                        <Link
                                            href="#"
                                            onClick={() => changeView(HomeScreenView.Email)}
                                            color="textPrimary"
                                            underline="always"
                                        >
                                            <Box component="span" fontWeight="700">
                                                {"Change Email"}
                                            </Box>
                                        </Link>
                                    </Box>
                                    <Typography variant="body1" align="left">
                                        {details?.email}
                                    </Typography>
                                </Box>

                                <TextField
                                    autoFocus
                                    fullWidth={true}
                                    id="firstName"
                                    label="First name"
                                    variant="outlined"
                                    value={values.firstName}
                                    onChange={handleChange}
                                    helperText={errors.firstName && touched.firstName && errors.firstName}
                                    error={errors.firstName ? true : false}
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                />

                                <TextField
                                    fullWidth={true}
                                    id="lastName"
                                    label="Last name"
                                    variant="outlined"
                                    value={values.lastName}
                                    onChange={handleChange}
                                    helperText={errors.lastName && touched.lastName && errors.lastName}
                                    error={errors.lastName ? true : false}
                                    required
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                />

                                <TextField
                                    fullWidth={true}
                                    id="phoneNumber"
                                    label="Phone number"
                                    variant="outlined"
                                    value={values.phoneNumber}
                                    onChange={handleChange}
                                    helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                                    error={errors.phoneNumber ? true : false}
                                    inputProps={{
                                        maxLength: 50,
                                    }}
                                />

                                <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    Input fields marked with an asterisk (*) are required
                                </Grid>

                                <Box mt={2}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        size="large"
                                        fullWidth
                                        color="secondary"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                    >
                                        {!processing ? "Continue" : <CircularProgress />}
                                    </Button>
                                </Box>
                                {error && (
                                    <Grid item xs={12}>
                                        <Typography color="error">{error}</Typography>
                                    </Grid>
                                )}
                            </>
                        );
                    }}
                </Formik>
            </Box>
        </>
    );
};
export default EnterDetails;
