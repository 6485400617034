import {
    Box,
    Button,
    Chip,
    createStyles,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import { Block, Delete } from "@material-ui/icons";
import { Formik, FormikHelpers } from "formik";
import React, { useEffect } from "react";
import { VTooltip } from "../../../core/components/VTooltip";
import { useFacilitator } from "../../../hooks/useFacilitator";
import * as yup from "yup";
import { useClassroom } from "../contexts/classroom.context";

export const ClassroomTeacherScreen = (): JSX.Element => {
    const { facilitators, getAllFacilitators } = useFacilitator();
    const { addTeacherToClassroom, classroom, removeTeacherFromClassroom } = useClassroom();

    useEffect(() => {
        getAllFacilitators();
    }, []);

    const onSubmit = (values: { teacherId: string }, helpers: FormikHelpers<{ teacherId: string }>) => {
        const { setSubmitting } = helpers;
        if (parseInt(values.teacherId) && classroom?.id) {
            addTeacherToClassroom(classroom.id, parseInt(values.teacherId));
        }
        setSubmitting(false);
    };

    const useStyles = makeStyles(
        createStyles({
            table: {
                boxShadow: "none",
                marginBottom: "32px",
                //backgroundColor: "rgba(0,0,0,0.1)",
            },
            facilitatorMenuItem: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "right",
            },
        }),
    );

    const classes = useStyles();

    return (
        <Grid container spacing={3}>
            <Formik
                initialValues={{ teacherId: "" }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    teacherId: yup
                        .string()
                        .required("You must select a teacher from the list before trying to add them to the classroom"),
                })}
            >
                {({ submitForm, errors, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <Grid item xs={12} className="classAddTeacherContainer">
                            <Grid
                                item
                                xs={12}
                                style={{ color: "rgba(0, 0, 0, 0.54)", paddingTop: "8px", paddingBottom: "10px" }}
                            >
                                Please select your staff member from the drop-down box below. If the staff member&apos;s
                                name does not appear please speak to your school administrator to have them added.
                            </Grid>
                            <FormControl variant="outlined" fullWidth error={!!errors.teacherId}>
                                <Box mb={2}>
                                    <Grid container wrap="nowrap" spacing={1}>
                                        <Grid item xs>
                                            <InputLabel id="teacher-label" required>
                                                Choose a Staff Member
                                            </InputLabel>
                                            <Select
                                                inputProps={{
                                                    className: classes.facilitatorMenuItem,
                                                }}
                                                className={classes.facilitatorMenuItem}
                                                required
                                                fullWidth
                                                label="Choose a Staff Member"
                                                name="facilitator"
                                                id="facilitator"
                                                onChange={(e) => setFieldValue("teacherId", e.target.value)}
                                            >
                                                {facilitators
                                                    ?.filter((f) => {
                                                        if (
                                                            classroom?.Teachers?.find((e) => e.id === f.User?.id) ||
                                                            classroom?.PrimaryTeacher?.id === f.User.id
                                                        ) {
                                                            return false;
                                                        } else {
                                                            return true;
                                                        }
                                                    })
                                                    .map((facilitator) => (
                                                        <MenuItem
                                                            className={classes.facilitatorMenuItem}
                                                            key={facilitator.id}
                                                            value={facilitator.User.id}
                                                        >
                                                            {facilitator.User.firstName} {facilitator.User.lastName}
                                                            {
                                                                <>
                                                                    <Chip label={facilitator.User.email} />
                                                                </>
                                                            }
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                size="large"
                                                disabled={isSubmitting}
                                                onClick={submitForm}
                                            >
                                                Add
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)", paddingTop: "8px" }}>
                                        Input fields marked with an asterisk (*) are required
                                    </Grid>
                                </Box>

                                <FormHelperText>
                                    {errors.teacherId && touched.teacherId && errors.teacherId}
                                </FormHelperText>
                            </FormControl>
                        </Grid>
                    );
                }}
            </Formik>
            <Grid item xs={12} className="classTeacherListContainer">
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Email</TableCell>
                                <TableCell align="right">Remove</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {classroom?.PrimaryTeacher?.firstName} {classroom?.PrimaryTeacher?.lastName}
                                </TableCell>
                                <TableCell align="right">{classroom?.PrimaryTeacher?.email}</TableCell>

                                <TableCell align="right">
                                    <VTooltip title="Cannot remove the main teacher of the classroom">
                                        <Block color="error" />
                                    </VTooltip>
                                </TableCell>
                            </TableRow>
                            {classroom?.Teachers?.map((teacher) => (
                                <TableRow key={teacher.id}>
                                    <TableCell>
                                        {teacher.firstName} {teacher.lastName}
                                    </TableCell>
                                    <TableCell align="right">{teacher.email}</TableCell>

                                    <TableCell align="right">
                                        <IconButton
                                            color="primary"
                                            onClick={() => removeTeacherFromClassroom(classroom.id, teacher.id)}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
};
