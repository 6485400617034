import { Organisation, User } from "common/build/prisma/client";
import { Product } from "../../../components/organisation/onboarding/entities/Products";
import { postData, getData } from "../rest";

export interface CreateSubscriptionRequest {
    product: Product;
    details: Partial<Organisation & User>;
    paymentMethod: string;
}

export const createSubscription = async (request: CreateSubscriptionRequest): Promise<Response> => {
    return await postData(`/stripe/checkout/create-session`, request);
};

export const getCustomerPortalUrl = async (stripeCustomerId: string): Promise<Response> => {
    return await getData(`/stripe/checkout/view-my-account/${stripeCustomerId}`);
};
