import React, { FC } from "react";
import { darken, withStyles } from "@material-ui/core/styles";
import Button, { ButtonProps } from "@material-ui/core/Button";
import { verbalTheme } from "../themes/verbal.theme";

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `Button` component.
const DestructiveButtonStyles = withStyles({
    root: {
        background: verbalTheme.palette.error.light,
        color: verbalTheme.palette.error.main,
        "&:hover": {
            background: darken(verbalTheme.palette.error.light, 0.05),
            color: verbalTheme.palette.error.dark,
        },
    },
    label: {
        textTransform: "capitalize",
    },
})(Button);

export const PrimaryButton: FC<ButtonProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Button variant={"contained"} color={"primary"} type="submit" {...rest}>
            {children}
        </Button>
    );
};

export const SecondaryButton: FC<ButtonProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Button variant={"outlined"} color={"primary"} {...rest}>
            {children}
        </Button>
    );
};

export const DestructiveButton: FC<ButtonProps> = (props) => {
    const { children, ...rest } = props;
    return <DestructiveButtonStyles {...rest}>{children}</DestructiveButtonStyles>;
};
