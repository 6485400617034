import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { HeadlineFigureWidget } from "./HeadlineFigureWidget";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { useMobile } from "../../../../hooks/useMobile";

export const ParticipantsHeadlineWidget: FC<{ isFacilitator: boolean }> = ({ isFacilitator }) => {
    const { item } = useFetch<ActiveProgramStats>();
    const count = item?.totalActiveParticipants || 0;
    const { isTablet, isMobile } = useMobile();

    return (
        <HeadlineFigureWidget header={isFacilitator ? "Your Participants" : "Total Participants"}>
            <Box pb={!isTablet && isMobile ? 1 : 0}>
                <SpinWhileFetching>
                    <Typography variant="h2">{count}</Typography>
                </SpinWhileFetching>
            </Box>
            <Box p={!isTablet && isMobile ? 2 : 1}>
                <Typography variant="body2">(All time)</Typography>
            </Box>
        </HeadlineFigureWidget>
    );
};
