import { getData, postData, putData, deleteData, patchData } from "./rest";
import { FacilitatorType } from "../../models/modelTypes";
import { ListParameters } from "../../../../common/build/api-parameters/common";
import { objectToQueryString } from "../../utils/api";
import { Facilitator } from "common/build/prisma/client";

export const getFacilitators = async (args: Record<string, unknown>): Promise<FacilitatorType[]> =>
    ((await getData(`/facilitator${objectToQueryString(args)}`)) as { items?: FacilitatorType[] })?.items ?? [];

/**
 * @deprecated
 */
export const getFacilitatorInfo = async (id: number): Promise<FacilitatorType> => await getData(`/facilitator/${id}`);

/**
 * @deprecated
 */
export const addFacilitator = async (facilitatorDetails: Partial<FacilitatorType>): Promise<Response> =>
    await postData("/facilitator", facilitatorDetails);

/**
 * @deprecated
 */
export const updateFacilitator = async (updatedFacilitatorDetails: Partial<FacilitatorType>): Promise<Response> =>
    await putData(`/facilitator`, updatedFacilitatorDetails);

export const deleteFacilitator = async (id: number): Promise<Response> => await deleteData(`/facilitator/${id}`);

export const list = async (
    args: Record<string, unknown> & ListParameters,
): Promise<{ items: FacilitatorType[]; total: number }> => await getData(`/facilitator${objectToQueryString(args)}`);

export const get = async (id: number, args: Record<string, unknown>): Promise<FacilitatorType> =>
    await getData(`/facilitator/${id}${objectToQueryString(args)}`);

export const add = async (facilitator: FacilitatorType): Promise<Response> =>
    await postData(`/facilitator/`, { ...facilitator });

export const update = async (id: number, editedFacilitator: Partial<Facilitator>): Promise<Response> =>
    await patchData(`/facilitator/${id}`, { ...editedFacilitator });
