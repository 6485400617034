import { getData, patchData, postData } from "./rest";
import { objectToQueryString } from "../../utils/api";
import { ThemeType } from "../../models/modelTypes";

export const list = async (args: Record<string, unknown>): Promise<{ items: ThemeType[]; total: number }> =>
    await getData(`/themes${objectToQueryString(args)}`);

export const get = async (id: number, args: Record<string, unknown>): Promise<ThemeType> =>
    await getData(`/themes/${id}${objectToQueryString(args)}`);

export const add = async (theme: ThemeType): Promise<Response> => await postData(`/themes/`, { ...theme });

export const update = async (id: number, theme: Partial<ThemeType>): Promise<Response> =>
    await patchData(`/themes/${id}`, { ...theme });
