import { Box, Typography } from "@material-ui/core";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import React from "react";
import { useAuth } from "../../../../../contexts/auth.context";
import { useFetch } from "../../../../../core/components/crud/contexts/fetch.context";
import { SpinWhileFetching } from "../../../../common/SpinWhileFetching";
import { VTextLink } from "../../../../common/VTextLink";
import { SecondaryButton } from "../../../../layout/Button/Button";
import { HeadlineFigureWidget } from "../HeadlineFigureWidget";

export const CurriculumHeadlineWidget = (): JSX.Element => {
    const { item } = useFetch<ActiveProgramStats>();
    const { hasPermission } = useAuth();
    return (
        <HeadlineFigureWidget header={"Total Curriculums"}>
            <Box>
                <SpinWhileFetching>
                    <Typography variant="h2">{item?.curriculums}</Typography>
                </SpinWhileFetching>
            </Box>
            <Box>
                {hasPermission("StoryCollectionCreate") && (
                    <>
                        {item?.curriculums && item?.curriculums > 0 ? (
                            <VTextLink to="/curriculums">
                                <SecondaryButton fullWidth>View Curriculums</SecondaryButton>
                            </VTextLink>
                        ) : (
                            <VTextLink to="/curriculums/add">
                                <SecondaryButton fullWidth>Create Curriculum</SecondaryButton>
                            </VTextLink>
                        )}
                    </>
                )}
            </Box>
        </HeadlineFigureWidget>
    );
};
