import React, { FC } from "react";
import { Typography } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography/Typography";

export const TypePageHeading: FC<TypographyProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Typography variant={"h1"} gutterBottom {...rest}>
            {children}
        </Typography>
    );
};

export const TypeHeading1: FC<TypographyProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Typography variant={"h1"} gutterBottom {...rest}>
            {children}
        </Typography>
    );
};

export const TypeHeading2: FC<TypographyProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Typography variant={"h2"} gutterBottom {...rest}>
            {children}
        </Typography>
    );
};

export const TypeHeading3: FC<TypographyProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Typography variant={"h3"} gutterBottom {...rest}>
            {children}
        </Typography>
    );
};

export const TypeHeading4: FC<TypographyProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Typography variant={"h4"} gutterBottom {...rest}>
            {children}
        </Typography>
    );
};

export const TypeHeading5: FC<TypographyProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Typography variant={"h5"} gutterBottom {...rest}>
            {children}
        </Typography>
    );
};

export const TypeHeading6: FC<TypographyProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <Typography variant={"h6"} gutterBottom {...rest}>
            {children}
        </Typography>
    );
};
