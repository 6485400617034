import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    Dialog,
    Grid,
    IconButton,
    makeStyles,
    createStyles,
    Tab,
    Tabs,
    Typography,
    Modal,
    Backdrop,
    Fade,
} from "@material-ui/core";
import { Close, ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { TabPanel } from "../../../programme/screens/ViewProgramme";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { MappedStoryQuestionType, MappedStoryType } from "../../../../models/modelTypes";
import { RelatedQuestion } from "common/build/prisma/client";
import { Alert, Skeleton } from "@material-ui/lab";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { PrepareQuestionModule } from "../../../session/prepareSession/questions/PrepareQuestionModule";
import { StoryBody } from "../../../session/sessionMode/wizard/steps/StoryBody";
import { useStoryStyles } from "../../../../hooks/styles/StoryStyles";
import { VTooltip } from "../../../../core/components/VTooltip";
import { useMobile } from "../../../../hooks/useMobile";
import { PrimaryButton } from "../../../layout/Button/Button";

type PreviewDialogProps = {
    previewStory?: MappedStoryType;
    openPreview: boolean;
    setOpenPreview: (open: boolean) => void;
};

export const PreviewDialog = (props: PreviewDialogProps): JSX.Element => {
    const { previewStory, openPreview, setOpenPreview } = props;
    const { isMobile } = useMobile();

    const [previewTabValue, setPreviewTabValue] = useState<number>(0);
    const [currentPartIndex, setCurrentPartIndex] = useState<number>(0);
    const [currentStoryPartQuestions, setCurrentStoryPartQuestions] = useState<MappedStoryQuestionType[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [currentRelatedQuestion, setCurrentRelatedQuestion] = useState<Partial<RelatedQuestion>>();
    const [isQuestionsOpen, setIsQuestionsOpen] = useState<Map<number, boolean>>(new Map());

    const [loadingImagePreviews, setLoadingImagePreviews] = useState<boolean[]>();
    const [openModalImagePreview, setOpenModalImagePreview] = useState(false);
    const [modalImage, setModalImage] = useState("false");

    useEffect(() => {
        if (previewStory) {
            setCurrentStoryPartQuestions(
                previewStory.Story?.StoryParts?.[currentPartIndex]?.MappedStoryQuestion?.filter(
                    (q) => q.mappedStoryId === previewStory.id,
                ) ?? [],
            );
        }
        const arrayLength = previewStory?.Story?.StoryParts ? previewStory?.Story?.StoryParts.length : 0;
        const loadingImagePreviewArray: boolean[] = [...Array<boolean>(arrayLength)].fill(true);
        setLoadingImagePreviews(loadingImagePreviewArray);
    }, [previewStory, currentPartIndex]);

    const handleModalImagePreviewClose = () => {
        setOpenModalImagePreview(false);
    };

    const handleModalImage = (value: string) => {
        setModalImage(value);
        setOpenModalImagePreview(true);
    };

    const handleLoadingImagePreviews = (index: number, value: boolean) => {
        if (loadingImagePreviews) {
            const newLoadingImagePreview = [...loadingImagePreviews];
            newLoadingImagePreview[index] = value;
            setLoadingImagePreviews(newLoadingImagePreview);
        }
    };

    const useStyles = makeStyles(() =>
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
            accordion: {
                padding: "0 16px 0 16px",
                marginBottom: "16px!important",
                backgroundColor: "rgba(0,0,0,0.05)",
            },
            accordionHeader: {
                minHeight: "64px",
            },
            storyPanel: {
                overflow: "auto",
            },
            modal: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                    backgroundcolor: "red",
                },
            },
            fadeImg: {
                outline: "none",
            },
        }),
    );
    const classes = useStyles();
    const storyClasses = useStoryStyles();

    return (
        <Dialog fullScreen open={openPreview}>
            <MuiDialogTitle>
                <Typography variant="h4" component="h3">
                    Preview Story
                </Typography>
                <IconButton className={classes.closeButton} aria-label="close" onClick={() => setOpenPreview(false)}>
                    <Close />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent>
                <Tabs
                    textColor="primary"
                    indicatorColor="secondary"
                    value={previewTabValue}
                    onChange={(_event: React.ChangeEvent<unknown>, value) => setPreviewTabValue(value)}
                >
                    <Tab label="Preparation" id="prep-tab" />
                    <Tab label="Delivery" id="delivery-tab" />
                </Tabs>
                <TabPanel index={0} value={previewTabValue}>
                    <Box>
                        {previewStory &&
                            previewStory.Story?.StoryParts?.map((part, index) => {
                                if (part && part.content) {
                                    return (
                                        <div key={part.id}>
                                            <Accordion className={classes.accordion} defaultExpanded={true}>
                                                <AccordionSummary
                                                    style={{ padding: "0px", transform: "none" }}
                                                    className={classes.accordionHeader}
                                                    expandIcon={<ExpandMore />}
                                                >
                                                    <Typography>
                                                        <strong>Part {index + 1}</strong> of{" "}
                                                        {previewStory?.Story?.StoryParts?.length}
                                                    </Typography>
                                                </AccordionSummary>
                                                <AccordionDetails
                                                    style={{
                                                        height: "100%",
                                                        padding: "0px",
                                                        paddingBottom: verbalTheme.spacing(2),
                                                    }}
                                                >
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Alert severity="warning" variant="filled">
                                                                Please read through the full section before reviewing
                                                                the discussion questions
                                                            </Alert>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={isQuestionsOpen.get(part.id) ? 6 : 10}
                                                        >
                                                            <div
                                                                style={{
                                                                    whiteSpace: "pre-line",
                                                                    padding: verbalTheme.spacing(2),
                                                                    backgroundColor: verbalTheme.palette.common.white,
                                                                }}
                                                            >
                                                                {part.content}
                                                            </div>
                                                        </Grid>
                                                        <Grid item sm={12} md={isQuestionsOpen.get(part.id) ? 6 : 2}>
                                                            {isQuestionsOpen.get(part.id) || isMobile ? (
                                                                <>
                                                                    <PrepareQuestionModule
                                                                        part={part}
                                                                        mappedStoryId={previewStory.id}
                                                                    />
                                                                    {!isMobile ? (
                                                                        <Box mt={3}>
                                                                            <VTooltip
                                                                                title={`Hide Part ${
                                                                                    index + 1
                                                                                } Questions`}
                                                                            >
                                                                                <PrimaryButton
                                                                                    onClick={() =>
                                                                                        setIsQuestionsOpen(
                                                                                            new Map(
                                                                                                isQuestionsOpen.set(
                                                                                                    part.id,
                                                                                                    false,
                                                                                                ),
                                                                                            ),
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Hide part {index + 1} questions
                                                                                </PrimaryButton>
                                                                            </VTooltip>
                                                                        </Box>
                                                                    ) : null}
                                                                </>
                                                            ) : (
                                                                <Box
                                                                    display="flex"
                                                                    flexDirection={"column"}
                                                                    justifyContent={"space-between"}
                                                                    height={"100%"}
                                                                >
                                                                    <Box
                                                                        width={"100%"}
                                                                        display="flex"
                                                                        justifyContent={"center"}
                                                                        mt={1}
                                                                    >
                                                                        <VTooltip
                                                                            title={`Show Part ${index + 1} Questions`}
                                                                        >
                                                                            <PrimaryButton
                                                                                onClick={() =>
                                                                                    setIsQuestionsOpen(
                                                                                        new Map(
                                                                                            isQuestionsOpen.set(
                                                                                                part.id,
                                                                                                true,
                                                                                            ),
                                                                                        ),
                                                                                    )
                                                                                }
                                                                            >
                                                                                Show part {index + 1} questions
                                                                            </PrimaryButton>
                                                                        </VTooltip>
                                                                    </Box>
                                                                    {part?.imageUrl && (
                                                                        <Box width={"100%"} mt={5} sx={{ flexGrow: 1 }}>
                                                                            <div
                                                                                style={{
                                                                                    display: loadingImagePreviews?.[
                                                                                        index
                                                                                    ]
                                                                                        ? "block"
                                                                                        : "none",
                                                                                }}
                                                                            >
                                                                                <Skeleton
                                                                                    variant="rect"
                                                                                    animation="wave"
                                                                                    width={"100%"}
                                                                                    height={168}
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    display: loadingImagePreviews?.[
                                                                                        index
                                                                                    ]
                                                                                        ? "none"
                                                                                        : "block",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={part?.imageUrl}
                                                                                    width={"100%"}
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() =>
                                                                                        handleModalImage(
                                                                                            part?.imageUrl as string,
                                                                                        )
                                                                                    }
                                                                                    onLoad={() => {
                                                                                        handleLoadingImagePreviews(
                                                                                            index,
                                                                                            false,
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                            <Typography align="center">
                                                                                Image Preview
                                                                            </Typography>
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                    );
                                }
                            })}
                        <Modal
                            className={classes.modal}
                            open={openModalImagePreview}
                            onClose={handleModalImagePreviewClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={openModalImagePreview} timeout={500}>
                                <img
                                    src={modalImage}
                                    key={modalImage}
                                    style={{
                                        maxHeight: "90%",
                                        maxWidth: "90%",
                                    }}
                                    className={classes.fadeImg}
                                />
                            </Fade>
                        </Modal>
                    </Box>
                </TabPanel>
                <TabPanel index={1} value={previewTabValue}>
                    <div className={classes.storyPanel}>
                        <Container className={storyClasses.storyContainer}>
                            <StoryBody
                                story={previewStory?.Story}
                                parts={previewStory?.Story?.StoryParts}
                                currentPartIndex={currentPartIndex}
                                currentPartQuestions={currentStoryPartQuestions}
                                currentQuestionIndex={currentQuestionIndex}
                                currentRelatedQuestion={currentRelatedQuestion}
                                setCurrentPartIndex={setCurrentPartIndex}
                                setCurrentQuestionIndex={setCurrentQuestionIndex}
                                setCurrentRelatedQuestion={setCurrentRelatedQuestion}
                            />
                        </Container>
                    </div>
                </TabPanel>
            </MuiDialogContent>
        </Dialog>
    );
};
