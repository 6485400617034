import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { useHomeStyles } from "../Home.style";

export const ComingSoonCard = (): JSX.Element => {
    const classes = useHomeStyles();
    return (
        <Card className={classes.storyCard} elevation={2} style={{ cursor: "auto" }}>
            <CardContent
                style={{
                    height: "100%",
                    padding: 0,
                    display: "flex",
                }}
            >
                <Box position="relative" height="100%" width="100%">
                    <div
                        style={{
                            cursor: "not-allowed",
                            height: "100%",
                            width: "100%%",
                            margin: "auto",
                            border: "1px solid black",
                            borderRadius: verbalTheme.spacing(1),
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="75%"
                            viewBox="0 0 24 24"
                            width="75%"
                            fill={verbalTheme.palette.secondary.main}
                        >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                        </svg>
                    </div>
                    <Box position={"absolute"} bottom={0} bgcolor={"rgba(0,0,0, 0.5)"} width="100%">
                        <Box
                            m={1}
                            display="flex"
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Typography
                                style={{
                                    color: verbalTheme.palette.background.default,
                                }}
                            >
                                Coming Soon....
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
