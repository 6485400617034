import { createStyles, makeStyles } from "@material-ui/core";
import { verbalTheme } from "../../themes/verbal.theme";
const styles = createStyles({
    root: {
        display: "flex",
        height: "100%",
        marginTop: "80px",
        backgroundColor: verbalTheme.palette.background.default,
    },
    main: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
    },
});

export const useReadingLayoutStyle = makeStyles(styles);
