import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { node } from "prop-types";
import { surveyComplete, surveyOptOut } from "../../../client/api/public/participant";
import { useHistory } from "react-router-dom";
import { SurveyType } from "common/build/prisma/client";
import { useList } from "../../../core/components/crud/contexts/list.context";
import { PublicParticipantListType } from "../../../models/modelTypes";

export enum SurveyDialogView {
    Main = "Main",
    OptOut = "OptOut",
    OptedOut = "OptedOut",
}

interface SurveyDialogContextI {
    view: SurveyDialogView;
    programmeId: number;
    participantId: number;
    preSurveyId: string;
    openDialog: boolean;
    hasOpenedSurvey: boolean;
    optedOutId?: number;

    configureProgrammeId: (id: number) => void;
    configureParticipantId: (id: number) => void;
    configurePreSurveyId: (id: string) => void;
    changeView: (view: SurveyDialogView) => void;
    optOut: () => void;
    completeSurvey: (surveyType: SurveyType) => void;
    shouldOpenDialog: (openDialog: boolean) => void;
    setHasOpenedSurvey: (opened: boolean) => void;
}

const SurveyDialogContext = React.createContext({} as SurveyDialogContextI);

export const SurveyDialogProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const postLogin = new URLSearchParams(window.location.search).get("postLogin");
    const history = useHistory();

    const [view, setView] = useState<SurveyDialogView>(SurveyDialogView.Main);
    const [programmeId, setProgrammeId] = useState<number>(0);
    const [participantId, setParticipantId] = useState<number>(0);
    const [preSurveyId, setPreSurveyId] = useState<string>("");
    const [openDialog, setOpen] = React.useState<boolean>(false);
    const [optedOutId, setOptedOutId] = React.useState<number>();
    const [hasOpenedSurvey, setHasOpenedSurvey] = useState<boolean>(false);

    const [dialogInit, setDialogInit] = useState(false);

    const { list, loading } = useList<PublicParticipantListType>();
    useEffect(() => {
        if (!loading && list.length > 0 && !dialogInit && postLogin) {
            const outStandingPreSurveys = list.flatMap(({ ParticipantToProgramme }) =>
                ParticipantToProgramme.filter(
                    ({ hasCompletedPreSurvey, hasOptedOutOfSurveys }) =>
                        !hasCompletedPreSurvey && !hasOptedOutOfSurveys,
                ),
            );

            if (outStandingPreSurveys.length > 0) {
                // It'd be nice if the dialog handled multiple surveys being required.
                // This was determined to be an edge case as most participants are likely on a single programme.
                const prompt = outStandingPreSurveys[0];

                // // check what session programme is currently on, don't show dialog if any sessions after session 1 are marked as complete
                let whatSession = 0;
                if (prompt?.Programme?.Sessions) {
                    // length will be > 0 if any sessions after session 1 are marked as complete
                    whatSession = prompt.Programme.Sessions.filter(
                        (session) => session.state === "Complete" && session.sessionNumber > 1,
                    ).length;
                }

                if (prompt?.Programme?.Survey?.preSurveyId && whatSession === 0) {
                    setProgrammeId(prompt.programmeId);
                    setParticipantId(prompt.participantId);
                    setPreSurveyId(prompt.Programme.Survey.preSurveyId);
                    setOpen(true);
                }
            }
            setDialogInit(true);
        }
    }, [loading, list, dialogInit, postLogin]);

    const changeView = useCallback((newView: SurveyDialogView) => {
        setView(newView);
    }, []);

    const configureProgrammeId = useCallback(async (programmeId: number) => {
        setProgrammeId(programmeId);
    }, []);

    const configureParticipantId = useCallback(async (participantId: number) => {
        setParticipantId(participantId);
    }, []);

    const configurePreSurveyId = useCallback(async (preSurveyId: string) => {
        setPreSurveyId(preSurveyId);
    }, []);

    const shouldOpenDialog = useCallback(async (openDialog: boolean) => {
        setOpen(openDialog);

        if (!openDialog) {
            history.push("/participant/dashboard");
        }
    }, []);

    const optOut = useCallback(async () => {
        const response = await surveyOptOut(participantId, programmeId);
        let json;

        if (response.ok) {
            json = await response.json();
            history.push("/participant/dashboard");
        }

        setOptedOutId(json.id);
    }, [participantId, programmeId]);

    const completeSurvey = useCallback(
        async (type: SurveyType) => {
            const response = await surveyComplete(participantId, programmeId, type);
            let json;

            if (response.ok) {
                json = await response.json();
                history.push("/participant/dashboard");
            }

            setOptedOutId(json.id);
        },
        [participantId, programmeId],
    );

    return (
        <SurveyDialogContext.Provider
            value={{
                view,
                programmeId,
                participantId,
                preSurveyId,
                openDialog,
                changeView,
                configureProgrammeId,
                configureParticipantId,
                configurePreSurveyId,
                optOut,
                shouldOpenDialog,
                completeSurvey,
                optedOutId: optedOutId,
                hasOpenedSurvey,
                setHasOpenedSurvey,
            }}
        >
            {children}
        </SurveyDialogContext.Provider>
    );
};

SurveyDialogProvider.propTypes = {
    children: node,
};

export const useSurveyDialog = (): SurveyDialogContextI => React.useContext(SurveyDialogContext);
