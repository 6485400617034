import React, { useEffect, useState } from "react";
import { useStoryCollection } from "../../contexts/collection.context";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { Formik } from "formik";
import { CollectionType } from "../../entities/CollectionType";
import * as yup from "yup";
import { Box, FormControl, FormHelperText, Grid, InputLabel, MenuItem, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { ToggleInput } from "../../../../../core/components/ToggleInput";
import { StoryCollectionType } from "../../../../../models/modelTypes";
import { FormActions } from "../../../../../core/components/FormActions";
import { PrimaryButton } from "../../../../layout/Button/Button";
import { useCurriculum } from "../../../../../hooks/useCurriculum";
import { Stop } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { CardColour } from "common/build/prisma/client";

export const CollectionDetails: React.FC = (): JSX.Element => {
    const { details, submitStep, changeStep, addNewCollection, updateCollection, collection } = useStoryCollection();
    const { listAll, curriculums } = useCurriculum();
    const { name, description, ageRange, type, specialTraining, curriculumId, colour, guideURL, guideURLROI } = details;

    const [error, setError] = useState();

    useEffect(() => {
        listAll();
    }, []);

    const onSubmit = (values: Partial<StoryCollectionType> & { numWeeks: number }) => {
        submitStep(values);
        changeStep(1);
        if (collection && collection.id) {
            updateCollection(collection.id, values)
                .then(() => {
                    setError(undefined);
                    changeStep(1);
                })
                .catch((error) => setError(error));
        } else {
            addNewCollection(values)
                .then(() => {
                    setError(undefined);
                    changeStep(1);
                })
                .catch((error) => setError(error));
        }
    };

    const ageRanges = [
        ...(() => {
            const ageRanges: string[] = [];
            for (let i = 4; i < 17; i++) {
                ageRanges.push(`${i}-${i + 1}`);
            }
            return ageRanges;
        })(),
        "18+",
    ];

    return (
        <form>
            <WizardHeading overline="Create a Story Collection" heading="Collection Details" />
            <Formik
                initialValues={{
                    name: name ?? "",
                    description: description ?? "",
                    ageRange: ageRange ?? "",
                    colour: colour ?? ("Silver" as CardColour),
                    type: type ?? CollectionType.Group,
                    specialTraining: specialTraining ?? false,
                    curriculumId: curriculumId ?? 0,
                    numWeeks: 1,
                    guideURL: guideURL ?? "",
                    guideURLROI: guideURLROI ?? "",
                }}
                onSubmit={onSubmit}
                enableReinitialize={true}
                validationSchema={yup.object({
                    name: yup.string().required("Story collection name is required"),
                    description: yup.string().required("Story collection description is required"),
                    type: yup.string().required("Collection type is required"),
                    ageRange: yup.string().required("Age range is required"),
                    specialTraining: yup.string().required("If this collection requires special training is required"),
                    curriculumId: yup.number().integer().moreThan(0).required("Curriculum is required"),
                    guideURL: yup.string().url("Invalid URL. Please enter a valid URL."),
                    guideURLROI: yup.string().url("Invalid URL. Please enter a valid URL."),
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    required
                                    name="name"
                                    variant="outlined"
                                    label="Collection Name"
                                    value={values.name}
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.name && touched.name && errors.name}
                                    error={!!errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    name="description"
                                    variant="outlined"
                                    label="Collection Description"
                                    value={values.description}
                                    multiline
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.description && touched.description && errors.description}
                                    error={!!errors.description}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    variant="outlined"
                                    error={!!errors.ageRange}
                                    fullWidth
                                    style={{ height: "100%" }}
                                >
                                    <InputLabel id="age-label" required>
                                        Age Range of Collection
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        required
                                        label="Age Range of Collection"
                                        labelId="age-label"
                                        name="ageRange"
                                        id="ageRange"
                                        value={values.ageRange}
                                        onChange={(e) => {
                                            setFieldValue("ageRange", e.target.value);
                                        }}
                                    >
                                        <MenuItem key={0} value={""} disabled>
                                            Please select
                                        </MenuItem>
                                        {ageRanges.map((ageRange) => (
                                            <MenuItem key={ageRange} value={ageRange}>
                                                {ageRange}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                        {errors.ageRange && touched.ageRange && errors.ageRange}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" error={!!errors.colour} fullWidth>
                                            <InputLabel id="colour-label" required>
                                                Card Colour
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Card Colour"
                                                labelId="colour-label"
                                                name="colour"
                                                id="colour"
                                                value={values.colour}
                                                onChange={(e) => {
                                                    setFieldValue("colour", e.target.value);
                                                }}
                                            >
                                                <MenuItem key={0} value={""} disabled>
                                                    Please select
                                                </MenuItem>
                                                <MenuItem key={"Red"} value={"Red"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#CA2D23" }} />
                                                        Red
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Orange"} value={"Orange"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#DD752A" }} />
                                                        Orange
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Yellow"} value={"Yellow"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#D3B619" }} />
                                                        Yellow
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Green"} value={"Green"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#78AF1F" }} />
                                                        Green
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Cyan"} value={"Cyan"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#27B5BE" }} />
                                                        Cyan
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Blue"} value={"Blue"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#6361B2" }} />
                                                        Blue
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Magneta"} value={"Magneta"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#AA3BA6" }} />
                                                        Magneta
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Crimson"} value={"Crimson"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#D33057" }} />
                                                        Crimson
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Silver"} value={"Silver"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#818390" }} />
                                                        Silver
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Bronze"} value={"Bronze"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#A77A50" }} />
                                                        Bronze
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"BrightYellow"} value={"BrightYellow"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#FFF700" }} />
                                                        Bright Yellow
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Aqua"} value={"Aqua"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#0DFFFF" }} />
                                                        Aqua
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"Purple"} value={"Purple"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#AE0CFF" }} />
                                                        Purple
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"BrightOrange"} value={"BrightOrange"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#FF7A15" }} />
                                                        Bright Orange
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"BrightRed"} value={"BrightRed"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#FF0000" }} />
                                                        Bright Red
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"LightGreen"} value={"LightGreen"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#007919" }} />
                                                        Light Green
                                                    </div>
                                                </MenuItem>
                                                <MenuItem key={"NightBlue"} value={"NightBlue"}>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <Stop fontSize="large" style={{ fill: "#033F77" }} />
                                                        Night Blue
                                                    </div>
                                                </MenuItem>
                                            </Select>
                                            <FormHelperText>
                                                {errors.colour && touched.colour && errors.colour}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <FormControl variant="outlined" error={!!errors.type} fullWidth>
                                            <InputLabel id="type-label" required>
                                                Collection Type
                                            </InputLabel>
                                            <Select
                                                labelId="type-label"
                                                name="type"
                                                id="type"
                                                value={values.type}
                                                label="Collection Type"
                                                onChange={(e) => {
                                                    setFieldValue("type", e.target.value);
                                                }}
                                                fullWidth
                                            >
                                                {Object.entries(CollectionType).map(([value, label]) => {
                                                    return (
                                                        <MenuItem key={value} value={value}>
                                                            {label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <FormHelperText>
                                                {errors.type && touched.type && errors.type}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" error={!!errors.curriculumId} fullWidth>
                                    <InputLabel id="curriculum-label" required>
                                        Choose a curriculum
                                    </InputLabel>
                                    <Select
                                        required
                                        fullWidth
                                        label="Choose a curriculum"
                                        name="curriculum"
                                        id="curriculum"
                                        onChange={(e) => {
                                            setFieldValue("curriculumId", e.target.value);
                                        }}
                                    >
                                        {curriculums?.map((curriculum) => (
                                            <MenuItem key={curriculum.id} value={curriculum.id}>
                                                {curriculum.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="guideURL"
                                    variant="outlined"
                                    label="Teacher Guide Google Drive URL - NI"
                                    value={values.guideURL}
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.guideURL && touched.guideURL && errors.guideURL}
                                    error={!!errors.guideURL}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="guideURLROI"
                                    variant="outlined"
                                    label="Teacher Guide Google Drive URL - ROI"
                                    value={values.guideURLROI}
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.guideURLROI && touched.guideURLROI && errors.guideURLROI}
                                    error={!!errors.guideURLROI}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="numWeeks-label" required>
                                        Num. of Weeks
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Number of Weeks"
                                        labelId="numWeeks-label"
                                        name="numWeeks"
                                        id="numWeeks"
                                        value={values.numWeeks}
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={0} value={""} disabled>
                                            Please select
                                        </MenuItem>
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <ToggleInput
                                id="specialTraining"
                                value={values.specialTraining ?? false}
                                setFieldValue={setFieldValue}
                                label="Does this collection require specialised training?"
                                leftOption="Yes"
                                rightOption="No"
                            />

                            <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Input fields marked with an asterisk (*) are required
                            </Grid>

                            <Grid item xs={12}>
                                {error && (
                                    <Box mb={2}>
                                        <Alert severity="error">{error}</Alert>
                                    </Box>
                                )}
                                <FormActions
                                    right={
                                        <>
                                            <PrimaryButton
                                                size="large"
                                                disabled={isSubmitting}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    submitForm();
                                                }}
                                            >
                                                Continue
                                            </PrimaryButton>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </form>
    );
};
