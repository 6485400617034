import React from "react";
import { ListProps, List } from "@material-ui/core";

type VListProps = ListProps & {
    children?: React.ReactNode;
};

export const VList: React.FC<VListProps> = (props: VListProps) => {
    const { children, ...rest } = props;

    return <List {...rest}>{children}</List>;
};
