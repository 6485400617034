import { Box, Container } from "@material-ui/core";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { StepperWizard } from "../../../core/components/wizard/StepperWizard";
import WizardStep from "../../../core/components/wizard/WizardStep";
import { useCollection } from "../../../hooks/useCollection";
import { useMobile } from "../../../hooks/useMobile";
import { useProgramme } from "../../programme/contexts/programme.context";
import PathwayStep from "../../programme/wizard/steps/Pathway";
import { ClassProgrammeDetails } from "../session/ClassProgrammeDetails";

export const CreateClassProgramme = (): JSX.Element => {
    const { id, paramId } = useParams<{ id?: string; paramId?: string }>();
    const { activeStep, completedSteps, changeStep, clearProvider, submitStep, details, submitDetails } =
        useProgramme();
    const { collection, getCollection } = useCollection();
    const { isMobile } = useMobile();

    useEffect(() => {
        if (paramId && parseInt(paramId)) {
            getCollection(parseInt(paramId));
        }
    }, [paramId]);

    useEffect(() => {
        if (id && parseInt(id)) {
            submitDetails({ ...details, classroomId: parseInt(id) });
        }
    }, [id]);

    useEffect(() => {
        if (collection) {
            submitStep({ storyCollectionId: collection.id, ageRange: collection.ageRange });
            if (activeStep === 0) {
                changeStep(1);
            }
        }
    }, [collection]);

    useEffect(() => {
        if (completedSteps[2]) {
            clearProvider();
        }
    }, []);

    return (
        <Box p={4}>
            <Container maxWidth={"md"}>
                {isMobile && <div style={{ paddingTop: "64px" }} />}
                <StepperWizard activeStep={activeStep} goToStep={changeStep} orientation={"horizontal"}>
                    <WizardStep identifier={0} heading={isMobile ? " " : "Collection"} completed={completedSteps[0]}>
                        <PathwayStep />
                    </WizardStep>
                    <WizardStep
                        identifier={1}
                        heading={isMobile ? " " : "Programme Details"}
                        completed={completedSteps[1]}
                    >
                        <ClassProgrammeDetails />
                    </WizardStep>
                </StepperWizard>
            </Container>
        </Box>
    );
};
