import React from "react";
import { Grid, makeStyles, createStyles, Paper, Typography } from "@material-ui/core";
import { useClassroom } from "../contexts/classroom.context";
import { verbalTheme } from "../../layout/themes/verbal.theme";

type StudentCheckInGridProps = {
    moodType: string;
    sessionId?: number;
    handleSelectStudent: () => void;
};

export const StudentCheckInGrid: React.FC<StudentCheckInGridProps> = (props: StudentCheckInGridProps): JSX.Element => {
    const { handleSelectStudent, moodType, sessionId } = props;

    const { classroom, setCurrentParticipant, getMoodToday } = useClassroom();

    const useStyles = makeStyles(
        createStyles({
            root: {
                flexGrow: 1,
            },
            paper: {
                display: "flex",
                minHeight: 120,
                minWidth: 120,
                padding: verbalTheme.spacing(1),
                boxShadow: "0 3px 5px 2px #1FAF8C",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                    backgroundColor: "#E9F7DF",
                },
            },
            paperTaken: {
                display: "flex",
                minHeight: 120,
                minWidth: 120,
                padding: verbalTheme.spacing(1),
                boxShadow: "0 3px 5px 2px #121738",
                justifyContent: "center",
                alignItems: "center",
                cursor: "not-allowed",
                opacity: 0.3,
                backgroundColor: "#F0F2F8",
            },
        }),
    );

    const classes = useStyles();

    return classroom?.Students && classroom.Students.length > 0 ? (
        <div className={classes.root}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                    <Typography variant="h2" align="center">
                        Take my class daily check in
                    </Typography>
                </Grid>
                {classroom.Students.map((s) => {
                    const isTaken =
                        sessionId && moodType === "Session"
                            ? getMoodToday(s, moodType, sessionId)?.mood
                                ? true
                                : false
                            : getMoodToday(s, moodType, null)?.mood
                            ? true
                            : false;
                    return (
                        <Grid key={s.id} item>
                            <Paper
                                elevation={3}
                                className={isTaken ? classes.paperTaken : classes.paper}
                                onClick={
                                    isTaken
                                        ? undefined
                                        : () => {
                                              setCurrentParticipant(s);
                                              handleSelectStudent();
                                          }
                                }
                            >
                                <Typography variant="h5" color={isTaken ? "secondary" : "primary"}>
                                    {s.Participant?.name}
                                </Typography>
                            </Paper>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    ) : (
        <></>
    );
};
