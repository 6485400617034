import { Box, Grid, Typography } from "@material-ui/core";
import React, { ReactNode } from "react";
import { VModule } from "../../../../core/components/VModule";

type QuestionInfoModuleProps = {
    title: string;
    content?: string;
    open?: boolean;
    children?: ReactNode;
};

export const QInfoModule = (props: QuestionInfoModuleProps): JSX.Element => {
    const { open, title, content, children } = props;

    return (
        <Grid item xs={12} style={open ? { display: "block" } : { display: "none" }}>
            <VModule>
                <Typography color="primary" variant="caption" paragraph>
                    <b>{title}</b>
                </Typography>
                <Typography style={{ whiteSpace: "pre-line" }}>{content}</Typography>
                {children ? <Box mt={2}>{children}</Box> : null}
            </VModule>
        </Grid>
    );
};
