import { useMemo, useCallback } from "react";
import { useHistory } from "react-router";

interface withUrlParamsFunctionsI {
    setParam(name: string, value: string): void;
}

/**
 * Custom hook to manage URL query string
 */
export const useQueryString = <UrlParams extends { [K in keyof UrlParams]?: string }>(): Partial<UrlParams> &
    withUrlParamsFunctionsI => {
    const params = useMemo(
        () => Object.fromEntries(new URLSearchParams(window.location.search).entries()) as Partial<UrlParams>,
        [window.location.search],
    );
    const { push } = useHistory();
    return {
        ...params,
        setParam: useCallback(
            (name: string, value: string): void => {
                const urlParams = new URLSearchParams(window.location.search);
                if (value) {
                    urlParams.set(name, value);
                } else {
                    urlParams.delete(name);
                }
                push({ search: "?" + urlParams.toString() });
            },
            [window.location.search],
        ),
    };
};

type QueryStringStateResult<T> = [value: T, setValue: (newValue: T) => void];
type QueryStringStateHook<T> = (name: string, defaultValue?: T) => QueryStringStateResult<T>;

export const useQueryStringState: QueryStringStateHook<string> = (name, defaultValue = "") => {
    const { [name]: value = defaultValue, setParam } = useQueryString<Record<string, string>>();
    return [
        value,
        useCallback((value: string) => setParam(name, value !== defaultValue ? value : ""), [setParam, defaultValue]),
    ];
};

const useTypedQuestStringState: <T>(
    state: QueryStringStateResult<string>,
    getter: (value: string) => T,
    setter: (value: T) => string,
) => QueryStringStateResult<T> = ([value, stateSetter], getter, setter) => [
    getter(value),
    useCallback((value) => stateSetter(setter(value)), [setter, stateSetter]),
];

const numberToString = (number: number) => number.toString();

export const useIntQueryStringState: QueryStringStateHook<number> = (name: string, defaultValue?: number) =>
    useTypedQuestStringState<number>(useQueryStringState(name, defaultValue?.toString()), parseInt, numberToString);
