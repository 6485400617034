import React, { ReactChild, useEffect } from "react";
import PathwayModal from "./PathwayModal";
import { Box, Button, Card, CardContent, Grid, Typography, Link, Tooltip } from "@material-ui/core";
import { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { StoryCollectionType } from "../../../../models/modelTypes";
import { ThemePill } from "../../../session/components/ThemePill";
import { Alert } from "@material-ui/lab";
import { SpecialTrainingDialog } from "./SpecialTrainingDialog";
import { useAuth } from "../../../../contexts/auth.context";
import clsx from "clsx";

type PathwayCardProps = {
    storyCollection: StoryCollectionType;
    save?: (pathwayId: number, ageRange: string) => void;
};

const useStyles = makeStyles(
    createStyles({
        CPathwayCard: {
            padding: "24px",
        },
        CardContent: {
            padding: 0,
            "&:last-child": {
                paddingBottom: 0,
            },
        },
        CardActions: {
            padding: 0,
            display: "flex",
            //justifyContent: "space-between",
            "&:last-child": {
                paddingBottom: 0,
            },
        },
    }),
);

export const PathwayCard: React.FC<PathwayCardProps> = (props: PathwayCardProps): JSX.Element => {
    const { user } = useAuth();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [specialTrainingDialog, setSpecialTrainingDialog] = useState<boolean>(false);
    const classes = useStyles();
    const [tooltip, setTooltip] = useState<string | ReactChild>(
        <Typography>This collection is currently in development</Typography>,
    );

    useEffect(() => {
        if (props.storyCollection.Stories?.length && props.storyCollection.Stories.length > 0) {
            setTooltip("");
        }
    }, [props]);

    return (
        <Grid key={props.storyCollection.id} item xs={12} className={clsx("storyCollectionOption")}>
            <Card className={classes.CPathwayCard} variant="outlined">
                <CardContent className={classes.CardContent}>
                    <Typography variant="h4">{props.storyCollection.name}</Typography>
                    <Typography variant="h6" gutterBottom>
                        {`${
                            props.storyCollection
                                ? `${
                                      props.storyCollection.Curriculum
                                          ? props.storyCollection.Curriculum?.name +
                                            " / " +
                                            `${props.storyCollection.ageRange} Years`
                                          : ""
                                  } ${props.storyCollection.type ? " / " + props.storyCollection.type : ""}`
                                : ""
                        }`}
                    </Typography>

                    <Box mt={1}>
                        <Grid container spacing={1}>
                            {props.storyCollection.Weeks?.map((week, index) => (
                                <Box component={"span"} mb={2} key={index} mr={1}>
                                    {week?.Theme?.name && week.Theme.description && (
                                        <ThemePill label={week.Theme?.name} content={week.Theme?.description} />
                                    )}
                                </Box>
                            ))}
                        </Grid>
                    </Box>
                    <Box>
                        <Typography variant={"body2"} paragraph>
                            {props.storyCollection.description}
                        </Typography>
                    </Box>

                    {props.storyCollection.specialTraining && (
                        <Box mt={1} mb={2}>
                            <Alert severity="info">
                                <Typography>
                                    <b>Requires specialist training</b> - In order to run sessions using this story
                                    collection your selected facilitators must either have or complete specialist
                                    training. This only takes 1 hour to do and can be done on the Verbal system.{" "}
                                    <Link
                                        component="button"
                                        color="secondary"
                                        onClick={() => setSpecialTrainingDialog(true)}
                                    >
                                        <u>Learn more</u>
                                    </Link>
                                </Typography>
                            </Alert>
                        </Box>
                    )}
                </CardContent>
                <div className={classes.CardActions}>
                    <div>
                        {props.save && typeof props.save === "function" && (
                            <Tooltip title={tooltip}>
                                <span>
                                    <Button
                                        variant="contained"
                                        color={"primary"}
                                        onClick={() =>
                                            props.save?.(props.storyCollection.id, props.storyCollection.ageRange)
                                        }
                                        disabled={
                                            props.storyCollection.Stories?.length &&
                                            props.storyCollection.Stories.length > 0
                                                ? false
                                                : true
                                        }
                                        className={clsx("selectCollectionButton")}
                                    >
                                        Select this collection
                                    </Button>
                                </span>
                            </Tooltip>
                        )}
                    </div>
                    <div style={{ paddingLeft: "1rem" }}>
                        {user?.Organisation?.country === "Republic of Ireland" && props.storyCollection.guideURLROI ? (
                            <Button
                                fullWidth
                                color="secondary"
                                variant="contained"
                                href={props.storyCollection.guideURLROI}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={clsx("guideNotesButton")}
                            >
                                Guide Notes
                            </Button>
                        ) : user?.Organisation?.country !== "Republic of Ireland" && props.storyCollection.guideURL ? (
                            <Button
                                fullWidth
                                color="secondary"
                                variant="contained"
                                href={props.storyCollection.guideURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={clsx("guideNotesButton")}
                            >
                                Guide Notes
                            </Button>
                        ) : null}
                    </div>
                </div>
            </Card>
            {props.save && typeof props.save === "function" && (
                <PathwayModal
                    storyCollection={props.storyCollection}
                    next={props.save}
                    open={openModal}
                    setOpen={setOpenModal}
                />
            )}
            <SpecialTrainingDialog open={specialTrainingDialog} setOpen={setSpecialTrainingDialog} />
        </Grid>
    );
};
