import React, { FC } from "react";
import { PieChartWidget } from "./PieChartWidget";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";

export const GenderPieWidget: FC = () => {
    const { item } = useFetch<ActiveProgramStats>();

    return (
        <PieChartWidget
            data={
                item?.genderBreakdown && Object.keys(item?.genderBreakdown).length > 0
                    ? Object.keys(item?.genderBreakdown).map((key) => ({ x: key, y: item?.genderBreakdown[key] }))
                    : undefined
            }
            spinWhileFetching
            header="Gender Demographic"
            subHeader="Active programmes by gender"
        />
    );
};
