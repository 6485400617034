import React, { useEffect, useState } from "react";
import { EnterEmail } from "./signup-signin/EnterEmail";
import { EnterDetails } from "./signup-signin/EnterDetails";
import { Confirmation } from "./signup-signin/Confirmation";
import { HomeScreenView, useHome } from "./hooks/home.context";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { Typography } from "@material-ui/core";
import { useParticipantAuth } from "../../contexts/participant-auth.context";

type HomeScreenProps = {
    isSignIn?: boolean;
};

export const HomeScreen: React.FC<HomeScreenProps> = ({ isSignIn = false }) => {
    const { view, validateJwt } = useHome();

    const [loading, setLoading] = useState<boolean>(true);
    const [jwtInvalid, setJwtInvalid] = useState<boolean>(false);

    const { jwt } = useParams<{ jwt?: string }>();

    const history = useHistory();

    const { login, loading: authLoading, isAuthenticated, performedLogin } = useParticipantAuth();

    useEffect(() => {
        if (!authLoading && isAuthenticated) {
            history.push(`/participant/dashboard${performedLogin ? "?postLogin=1" : ""}`);
        }
    }, [authLoading, isAuthenticated, performedLogin]);

    useEffect(() => {
        if (jwt) {
            if (!isSignIn) {
                validateJwt(jwt)
                    .then(() => {
                        setJwtInvalid(false);
                    })
                    .catch(() => {
                        setJwtInvalid(true);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            } else {
                login(jwt);
            }
        } else {
            setLoading(false);
        }
    }, [jwt]);

    if (loading) {
        return <Spinner />;
    }

    if (jwtInvalid) {
        return (
            <Typography variant="h5" component="h1" align="center">
                This invitation is no longer valid. Please contact your programme organiser for assistance.
            </Typography>
        );
    }

    switch (view) {
        case HomeScreenView.Email:
            return <EnterEmail isSignIn={isSignIn} />;
        case HomeScreenView.Details:
            return <EnterDetails />;
        case HomeScreenView.Confirmation:
            return <Confirmation />;
    }
};
export default HomeScreen;
