import { FacilitatorToProgrammeType, SessionType } from "../models/modelTypes";

export const sortSessions = ({ sessionNumber: a }: SessionType, { sessionNumber: b }: SessionType): number => a - b;

// sort facilitators on firstname, then last name, ascending
export const sortFacilitators = (
    { Facilitator: a }: FacilitatorToProgrammeType,
    { Facilitator: b }: FacilitatorToProgrammeType,
): number => {
    if (a.User.firstName && b.User.firstName) {
        if (a.User.firstName.toLowerCase() < b.User.firstName.toLowerCase()) {
            return -1;
        }
        if (a.User.firstName.toLowerCase() > b.User.firstName.toLowerCase()) {
            return 1;
        }
    }
    if (a.User.lastName && b.User.lastName) {
        if (a.User.lastName.toLowerCase() < b.User.lastName.toLowerCase()) {
            return -1;
        }
        if (a.User.lastName.toLowerCase() > b.User.lastName.toLowerCase()) {
            return 1;
        }
    }
    return 0;
};
