"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.countryList = void 0;
exports.countryList = [
    "Northern Ireland",
    "Republic of Ireland",
    "United Kingdom",
    "Anguilla",
    "Antigua and Barbuda",
    "Australia",
    "Bahamas",
    "Barbados",
    "Belize",
    "Bermuda",
    "Botswana",
    "British Virgin Islands",
    "Cameroon",
    "Canada (except Quebec)",
    "Cayman Islands",
    "Dominica",
    "England",
    "Fiji",
    "Gambia",
    "Ghana",
    "Gibraltar",
    "Grenada",
    "Guyana",
    "Jamaica",
    "Kenya",
    "Lesotho",
    "Liberia",
    "Malawi",
    "Malta",
    "Mauritius",
    "Montserrat",
    "Namibia",
    "New Zealand",
    "Nigeria",
    "Papua New Guinea",
    "St. Kitts and Nevis",
    "St. Lucia",
    "St. Vincent and the Grenadines",
    "Scotland",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Solomon Islands",
    "South Africa",
    "Swaziland",
    "Tanzania",
    "Tonga",
    "Trinidad and Tobago",
    "Turks and Caicos Islands",
    "Uganda",
    "Vanuatu",
    "Wales",
    "Zambia",
    "Zimbabwe",
];
