import { getData, patchData, postData } from "./rest";
import { FacilitatorStoryPartType, MappedStoryQuestionType, MappedStoryType, StoryType } from "../../models/modelTypes";
import { objectToQueryString } from "../../utils/api";
import { ListParameters } from "common/build/api-parameters/common";

export const add = async (
    story: Partial<StoryType> & {
        themeIds?: { id: number }[];
        parts: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
    },
): Promise<Response> => await postData("/story", story);

export const all = async (): Promise<StoryType[]> => await getData("/story/all");

export const list = async (
    args: Record<string, unknown> & ListParameters,
): Promise<{ items: StoryType[]; total: number }> => await getData(`/story${objectToQueryString(args)}`);

export const update = async (
    id: number,
    newValues: Partial<StoryType> & {
        themeIds?: { id: number }[];
        parts: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
    },
): Promise<Response> => await patchData(`/story/${id}`, newValues);

export const get = async (id: number, args: Record<string, unknown>): Promise<StoryType> =>
    await getData(`/story/getstory/${id}${objectToQueryString(args)}`);

export const getMapped = async (id: number, args: Record<string, unknown>): Promise<MappedStoryType> =>
    await getData(`/story/mapped/${id}${objectToQueryString(args)}`);

export const getFacilitatorMapped = async (mappedStoryId: number): Promise<FacilitatorStoryPartType[]> =>
    await getData(`/story/mapped/${mappedStoryId}/facilitator`);

export const addFacilitatorMapped = async (
    facilitatorStoryPart: Partial<FacilitatorStoryPartType>,
): Promise<Response> => await postData(`/story/mapped/facilitator`, facilitatorStoryPart);

export const addMappedStoryPartQuestion = async (
    mappedStoryId: number,
    partNum: number,
    collectionId: number,
    question: Partial<MappedStoryQuestionType>,
): Promise<Response> =>
    await postData(`/story/mapped/${mappedStoryId}/part/${partNum}/question/${collectionId}`, { question: question });

export const removeMappedStoryPartQuestion = async (
    mappedStoryId: number,
    partNum: number,
    order: number,
): Promise<Response> => await patchData(`/story/mapped/${mappedStoryId}/part/${partNum}/question/${order}`);

export const publishMappedStory = async (mappedStoryId: number): Promise<Response> =>
    await patchData(`/story/mapped/${mappedStoryId}`);

export const reviewStory = async (storyId: number): Promise<Response> => await patchData(`/story/${storyId}/review`);

export const publishStory = async (storyId: number): Promise<Response> => await patchData(`/story/${storyId}/publish`);
