import { Avatar, Box, Button, Card, Link, ThemeProvider, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React from "react";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { Survey } from "common/build/prisma/client";
import { useProgrammeParticipant } from "../../participant/ProgrammeParticipant.context";
import { SurveyDialogView, useSurveyDialog } from "../../public/hooks/surveyDialog.context";
import { useEffect } from "react";
import { AssignmentOutlined } from "@material-ui/icons";

export enum SurveyType {
    Pre,
    Post,
}

interface SurveyReminderCardProps {
    survey: Survey;
}

const theme = {
    ...verbalTheme,
    typography: { ...verbalTheme.typography, body1: { ...verbalTheme.typography.body1, fontSize: "14px" } },
};

export const SurveyReminderCard: React.FC<SurveyReminderCardProps> = (props: SurveyReminderCardProps) => {
    const { survey } = props;
    const { surveyType, loading, participantToProgramme } = useProgrammeParticipant();
    const {
        changeView,
        configureParticipantId,
        configurePreSurveyId,
        configureProgrammeId,
        shouldOpenDialog,
        optedOutId,
        hasOpenedSurvey,
        setHasOpenedSurvey,
    } = useSurveyDialog();
    const useStyles = makeStyles(() =>
        createStyles({
            cardLayout: {
                display: "flex",
                flexDirection: "column",
                padding: theme.spacing(3),
                [verbalTheme.breakpoints.up("md")]: {
                    flexDirection: "row",
                    alignItems: "center",
                },
            },
            cardIcon: {
                display: "flex",
                justifyContent: "center",
                margin: theme.spacing(0, 0, 2, 0),
                [verbalTheme.breakpoints.up("md")]: {
                    margin: theme.spacing(0, 2, 0, 0),
                },
            },
            cardActions: {
                flexDirection: "column",
                marginTop: theme.spacing(2),
                [verbalTheme.breakpoints.up("md")]: {
                    marginTop: theme.spacing(0),
                    marginLeft: "auto",
                },
            },
            icon: {
                width: theme.spacing(7),
                height: theme.spacing(7),
                color: "#fff",
                backgroundColor: surveyType === SurveyType.Post ? "#4476C5" : "#1FAF8C",
            },
            cardContent: {
                textAlign: "center",
                [verbalTheme.breakpoints.up("md")]: {
                    textAlign: "left",
                },
            },
        }),
    );
    const classes = useStyles();
    const surveyURL = `https://www.surveymonkey.co.uk/r/${
        surveyType === SurveyType.Pre ? survey.preSurveyId : survey.postSurveyId
    }?partId=${participantToProgramme.participantId}&progId=${participantToProgramme.programmeId}`;
    const [optedOut, setOptedOut] = React.useState<boolean>(false);

    useEffect(() => {
        if (participantToProgramme.id && optedOutId === participantToProgramme.id) {
            setOptedOut(true);
        }
    }, [optedOutId]);

    return (
        <ThemeProvider theme={theme}>
            {!loading && !hasOpenedSurvey && surveyType !== undefined && !optedOut && (
                <Box pt={3}>
                    <Card>
                        <div className={classes.cardLayout}>
                            <div className={classes.cardIcon}>
                                <Avatar className={classes.icon}>
                                    <AssignmentOutlined fontSize="large" />
                                </Avatar>
                            </div>

                            <div className={classes.cardContent}>
                                <Typography variant="h6" component="h6">
                                    {surveyType === SurveyType.Pre ? "Pre" : "Post"} Programme Survey
                                </Typography>
                                <Typography variant="body1" color="textSecondary" component="p">
                                    Please take some time to complete the{" "}
                                    {surveyType === SurveyType.Pre ? "pre " : "post "}
                                    programme survey.{" "}
                                    <Link
                                        component="button"
                                        variant={"body1"}
                                        onClick={() => {
                                            configureParticipantId(participantToProgramme.participantId);
                                            configurePreSurveyId(survey.preSurveyId);
                                            configureProgrammeId(participantToProgramme.programmeId);
                                            changeView(SurveyDialogView.OptOut);
                                            shouldOpenDialog(true);
                                        }}
                                    >
                                        Opt Out
                                    </Link>
                                </Typography>
                            </div>

                            <div className={classes.cardActions}>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    size={"large"}
                                    color={surveyType === SurveyType.Post ? "secondary" : "primary"}
                                    target="_blank"
                                    onClick={() => {
                                        setHasOpenedSurvey(true);
                                    }}
                                    href={surveyURL}
                                >
                                    Take Survey
                                </Button>
                            </div>
                        </div>
                    </Card>
                </Box>
            )}
        </ThemeProvider>
    );
};
