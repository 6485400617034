import { Box, Button, Typography } from "@material-ui/core";
import React from "react";
import MobileSplash from "../../img/mobile-splash-screen.png";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth.context";

export const ViewOnMobile = (): JSX.Element => {
    const history = useHistory();
    const { logout } = useAuth();

    return (
        <>
            <Box
                maxHeight={"100vh"}
                style={{ paddingTop: "36px" }}
                m={4}
                mt={0}
                mb={0}
                alignSelf="center"
                overflow={"hidden"}
            >
                <Box mb={2}>
                    <img src={MobileSplash} />
                </Box>
                <Box mb={2}>
                    <Typography align="center" variant={"h6"}>
                        Hello, We noticed that you are using your phone to join us.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography align="center">
                        We would recommend you use a tablet, laptop or desktop to enjoy the full Verbal Wellbeing
                        experience.
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography color="primary" align="center">
                        <b>
                            Don&apos;t worry you can continue to use your phone to get a better idea of the app just
                            some elements may not be as smooth.
                        </b>
                    </Typography>
                </Box>
                <Box display="flex" justifyContent={"center"}>
                    <Box m={2} mb={0}>
                        <Button
                            size="large"
                            variant={"contained"}
                            color="secondary"
                            onClick={() => {
                                logout();
                                history.goBack();
                            }}
                        >
                            Go Back
                        </Button>
                    </Box>
                    <Box m={2} mb={0}>
                        <Button
                            size="large"
                            variant={"contained"}
                            color="primary"
                            onClick={() => history.push("/home")}
                        >
                            Continue
                        </Button>
                    </Box>
                </Box>
            </Box>
        </>
    );
};
