import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { Grid, FormControl, FormHelperText, MenuItem, Box, InputLabel } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { useProgramme } from "../../contexts/programme.context";
import { Formik } from "formik";
import * as yup from "yup";
import { ProgrammeFrequency } from "../../entities/ProgrammeDetails";
import { Organisation } from "common/build/prisma/client";
import { Alert } from "@material-ui/lab";
import { ProgrammeType } from "../../../../models/modelTypes";
import dayjs from "dayjs";
import { PrimaryButton } from "../../../layout/Button/Button";
import { FormActions } from "../../../../core/components/FormActions";
import { WizardHeading } from "../../../../core/components/WizardHeading";
import { ToggleInput } from "../../../../core/components/ToggleInput";
import { VideoConferenceSelect } from "../../../session/components/VideoConferenceSelect";

type ProgrammeDetailsProps = {
    organisation: Organisation | undefined;
};

export type AddProgrammeType = ProgrammeType & {
    date: string;
    time: string;
};

const ProgrammeDetails: React.FC<ProgrammeDetailsProps> = (props: ProgrammeDetailsProps): JSX.Element => {
    const {
        changeStep,
        details,
        submitStep,
        addNewProgramme,
        updateProgramme,
        programme,
        addStoryCollectionToProgramme,
        generateProgrammeSessions,
    } = useProgramme();

    const { name, isGroup, isOnline, startDate, frequency, providerType, providerURL } = details;

    const [error, setError] = useState();

    const onSubmit = (values: Partial<AddProgrammeType>) => {
        const { date, time, ...programmeDetails } = values;
        const newDate = dayjs(`${date} ${time}`, "YYYY-MM-DD HH:mm").toDate();
        const stepData = {
            ...programmeDetails,
            startDate: newDate,
            organisationId: props.organisation?.id,
            storyCollectionId: details.storyCollectionId,
            ageRange: details?.ageRange,
        };
        submitStep(stepData);
        if (programme && programme.id) {
            updateProgramme(programme.id, stepData)
                .then(() => {
                    setError(undefined);
                    if (details?.storyCollectionId) {
                        addStoryCollectionToProgramme(details.storyCollectionId, programme.id)
                            .then(() => {
                                // moved in here as it was generating sessions before updated programme was
                                // returned (when collection is changed) which caused old collection stories to be assigned to sessions
                                generateProgrammeSessions(programme.id)
                                    .then(() => {
                                        setError(undefined);
                                        changeStep(2);
                                    })
                                    .catch((error) => {
                                        setError(error);
                                    });
                            })
                            .catch((error) => {
                                setError(error);
                            });
                    } else {
                        changeStep(2);
                    }
                })
                .catch((error) => {
                    setError(error);
                });
        } else {
            addNewProgramme(stepData)
                .then(() => {
                    setError(undefined);
                    changeStep(2);
                })
                .catch((error) => {
                    setError(error);
                });
        }
    };

    const ageRanges = [
        ...(() => {
            const ageRanges: string[] = [];
            for (let i = 4; i < 17; i++) {
                ageRanges.push(`${i}-${i + 1}`);
            }
            return ageRanges;
        })(),
        "18+",
    ];

    return (
        <form>
            <WizardHeading overline={"Schedule a programme"} heading={"Programme details"} />
            <Formik
                initialValues={{
                    name: name ?? "",
                    isGroup: isGroup ?? true,
                    ageRange: details?.ageRange ?? "",
                    isOnline: isOnline ?? false,
                    date: dayjs(startDate).format("YYYY-MM-DD") ?? dayjs(new Date()).format("YYYY-MM-DD"),
                    time: dayjs(startDate).format("HH:mm") ?? "08:30",
                    frequency: frequency ?? ProgrammeFrequency.Weekly,
                    providerType: providerType ?? "BuiltIn",
                    providerURL: providerURL ?? "",
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    name: yup.string().required("Programme name is required"),
                    isGroup: yup.boolean().required("A group or 1-1 is required"),
                    //ageRange: yup.string().required("Age Range is required"),
                    isOnline: yup.boolean().required("Online or in person is required"),
                    date: yup.string().required("A Start Date is required"),
                    time: yup.string().required("A Start Time is required"),
                    frequency: yup.string().required("Frequency is required"),
                    providerURL: isOnline
                        ? yup.string().when("providerType", {
                              is: "BuiltIn",
                              then: yup.string().notRequired(),
                              otherwise: yup
                                  .string()
                                  .matches(
                                      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                      "Video URL must be a valid URL",
                                  )
                                  .required(),
                          })
                        : yup.string().notRequired(),
                })}
                validateOnChange={false}
                enableReinitialize
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    required
                                    name="name"
                                    variant="outlined"
                                    label="Programme Name"
                                    value={values.name}
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.name && touched.name && errors.name}
                                    error={!!errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" error={!!errors.ageRange} fullWidth>
                                    <InputLabel id="age-label" required>
                                        Age Group of Participants
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        disabled={details.ageRange ? true : false}
                                        label="Age Group of Participants"
                                        labelId="age-label"
                                        name="ageRange"
                                        id="ageRange"
                                        value={values.ageRange}
                                    >
                                        <MenuItem key={0} value={""} disabled>
                                            Please select
                                        </MenuItem>
                                        {ageRanges.map((ageRange) => (
                                            <MenuItem key={ageRange} value={ageRange}>
                                                {ageRange}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                        {errors.ageRange && touched.ageRange && errors.ageRange}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6}>
                                        <TextField
                                            name="date"
                                            id="date"
                                            label="Start Date"
                                            type="date"
                                            variant="outlined"
                                            value={values.date}
                                            onChange={handleChange}
                                            helperText={errors.date && touched.date && errors.date}
                                            error={!!errors.date}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            name="time"
                                            id="time"
                                            label="Start Time"
                                            type="time"
                                            variant="outlined"
                                            inputProps={{
                                                step: 300,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={values.time}
                                            onChange={handleChange}
                                            helperText={errors.time && touched.time && errors.time}
                                            error={!!errors.time}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" error={!!errors.frequency} fullWidth>
                                            <InputLabel id="frequency-label" required>
                                                Frequency
                                            </InputLabel>
                                            <Select
                                                labelId="frequency-label"
                                                name="frequency"
                                                id="frequency"
                                                value={values.frequency}
                                                label="Frequency"
                                                onChange={(e) => setFieldValue("frequency", e.target.value)}
                                                fullWidth
                                            >
                                                {Object.entries(ProgrammeFrequency).map(([value, label]) => {
                                                    return (
                                                        <MenuItem key={value} value={value}>
                                                            {label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <FormHelperText>
                                                {errors.frequency && touched.frequency && errors.frequency}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <ToggleInput
                                id={`isOnline`}
                                setFieldValue={setFieldValue}
                                label={`How / Where will the sessions be held?`}
                                leftOption={`Online`}
                                rightOption={`In-Person`}
                                value={values.isOnline ?? false}
                            />
                            <VideoConferenceSelect
                                setFieldValue={setFieldValue}
                                values={values as Partial<ProgrammeType>}
                                errors={errors as Partial<ProgrammeType>}
                            />

                            <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Input fields marked with an asterisk (*) are required
                            </Grid>

                            <Grid item xs={12}>
                                {error && (
                                    <Box mb={2}>
                                        <Alert severity="error">{error}</Alert>
                                    </Box>
                                )}
                                <FormActions
                                    right={
                                        <>
                                            <PrimaryButton
                                                size="large"
                                                disabled={isSubmitting}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    submitForm();
                                                }}
                                            >
                                                Continue
                                            </PrimaryButton>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </form>
    );
};

export default ProgrammeDetails;
