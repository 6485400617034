import { Box, Typography, Paper, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { getCurriculumsAZ } from "../../client/api/home";
import { CurriculumType } from "../../models/modelTypes";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { VList } from "../../core/components/VList";
import { VListItem, VListItemType } from "../../core/components/VListItem";
import { useHistory } from "react-router-dom";

export const CollectionsAZ = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [allCurriculums, setAllCurriculums] = useState<CurriculumType[]>([]);

    const history = useHistory();

    useEffect(() => {
        setLoading(true);

        getCurriculumsAZ()
            .then((value) => setAllCurriculums(value))
            .finally(() => setLoading(false));
    }, []);

    return (
        <Box pt={4} px={2}>
            <Box display="flex" justifyContent="space-between">
                <>
                    <Typography variant="h6">Curriculums A-Z</Typography>
                </>
            </Box>
            <VList>
                <Grid container spacing={2}>
                    {allCurriculums.length > 0 && !loading
                        ? allCurriculums.map((item) => (
                              <Grid item xs={12} sm={6} md={12} key={item.id}>
                                  <Paper elevation={2}>
                                      <VListItem
                                          type={VListItemType.Primary}
                                          primaryText={item.name}
                                          onClick={() => history.push(`/explore/curriculum/${item.id}`)}
                                          textProps={{ color: "primary" }}
                                      >
                                          <ArrowForwardIosIcon color="primary" />
                                      </VListItem>
                                  </Paper>
                              </Grid>
                          ))
                        : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20].map((item) => (
                              <Grid item xs={12} sm={6} md={12} key={item}>
                                  <Paper
                                      style={{ marginBottom: "10", paddingLeft: 10, paddingRight: 10 }}
                                      elevation={2}
                                  >
                                      <Skeleton height="50px" />
                                  </Paper>
                              </Grid>
                          ))}
                </Grid>
            </VList>
        </Box>
    );
};
