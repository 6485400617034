import React, { useEffect, useState } from "react";
import { AppBar, Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../themes/verbal.theme";
import Search from "../../../core/components/Search";
import { PrimaryButton } from "../Button/Button";
import { list } from "../../../client/api/organisation";
import { useAuth } from "../../../contexts/auth.context";
import { Organisation, Permission } from "common/build/prisma/client";

const useStyles = makeStyles(
    createStyles({
        header: {
            alignItems: "center",
            marginBottom: verbalTheme.spacing(3),
            [verbalTheme.breakpoints.up("md")]: {
                display: "flex",
            },
        },
        headerTitle: {
            display: "flex",
            alignItems: "center",
            flexGrow: 0,
            marginBottom: verbalTheme.spacing(2),
            marginRight: verbalTheme.spacing(4),
            [verbalTheme.breakpoints.up("md")]: {
                marginBottom: 0,
            },
            border: 0,
        },
        headerOptions: {
            flexBasis: "100%",
        },
        marginRight: {
            marginRight: verbalTheme.spacing(1),
        },
        filterContainer: {
            width: "45%",
            marginRight: verbalTheme.spacing(20),
        },
        extraFiltersContainer: {
            width: "30%",
        },
        dateRow: {
            flexGrow: 1,
            width: "100%",
        },
        dateItem: {
            width: "50%",
        },
    }),
);

type HeaderProps = {
    title: string;
    total?: string;
    onAdd?: () => void;
    onSearch?: (searchTerm: string) => void;
    orgFilterPermission?: Permission;
    onFilter?: (filter: Record<string, unknown>) => void;
    onOrder?: (order: string, column: string) => void;
    onLocationFilter?: (location: string) => void;
    onActivityFilter?: (lastActive: string) => void;
    onStartDate?: (date: string) => void;
    onEndDate?: (date: string) => void;
    programmeFilter?: boolean;
    userFilter?: boolean;
    canOrder?: boolean;
    canFilterDay?: boolean;
    canFilterWeek?: boolean;
    canFilterLocation?: boolean;
    canFilterActivity?: boolean;
    columnTitles: string[];
    userStatus?: string;
    onUserStatusChange?: (status: string) => void;
};

export const Header = (props: HeaderProps): JSX.Element => {
    const {
        title,
        total,
        onAdd,
        onSearch,
        onFilter,
        onOrder,
        onLocationFilter,
        onActivityFilter,
        onStartDate,
        onEndDate,
        orgFilterPermission,
        programmeFilter,
        userFilter,
        canOrder,
        canFilterLocation,
        canFilterActivity,
        columnTitles,
        onUserStatusChange,
    } = props;
    const classes = useStyles();
    const { user, hasPermission } = useAuth();
    const canFilterOrg = user && orgFilterPermission && hasPermission(orgFilterPermission);
    const [organisationId, setOrganisationId] = useState<number>(user?.organisationId as number);
    const [organisations, setOrganisations] = useState<Organisation[]>();

    const [programmeFilterName, setProgrammeFilterName] = useState<string>("Active");
    const [userFilterName, setUserFilterName] = useState<string>("Active");
    const [orderKey, setOrderKey] = useState<string>("Ascending");
    const [selectedColumn, setSelectedColumn] = useState<string>("");
    const [locationFilter, setLocationFilter] = useState<string>("All");
    const [activityFilter, setActivityFilter] = useState<string>("All time");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    useEffect(() => {
        canFilterOrg && onFilter && onFilter({ organisationId });
    }, [onFilter, organisationId, canFilterOrg]);

    useEffect(() => {
        canFilterOrg && list({ take: 1000 }).then(({ items }) => setOrganisations(items));
    }, [canFilterOrg]);

    useEffect(() => {
        programmeFilter && onFilter && onFilter({ filterProgrammeState: programmeFilterName });
    }, [programmeFilter, programmeFilterName, onFilter]);

    useEffect(() => {
        userFilter && onFilter && onFilter({ filterUserState: userFilterName });
    }, [userFilter, onFilter, userFilterName]);

    useEffect(() => {
        canOrder && onOrder && onOrder(orderKey, selectedColumn);
    }, [canOrder, onOrder, orderKey, selectedColumn]);

    useEffect(() => {
        canFilterLocation && onLocationFilter && onLocationFilter(locationFilter);
    }, [canFilterLocation, onLocationFilter, locationFilter]);

    useEffect(() => {
        canFilterActivity && onActivityFilter && onActivityFilter(activityFilter);
    }, [canFilterActivity, onActivityFilter, activityFilter]);

    useEffect(() => {
        startDate && onStartDate && onStartDate(startDate);
    }, [startDate, onStartDate, startDate]);

    useEffect(() => {
        endDate && onEndDate && onEndDate(endDate);
    }, [endDate, onEndDate, endDate]);

    const handleStartDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(event.target.value);
    };

    const handleEndDate = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(event.target.value);
    };

    const clearDates = () => {
        setStartDate("");
        setEndDate("");
        if (onStartDate) onStartDate("");
        if (onEndDate) onEndDate("");
    };

    return (
        <>
            <AppBar position={"static"} color={"transparent"} elevation={0}>
                <div className={classes.header}>
                    <div className={classes.headerTitle}>
                        <Box mr={2} className={title.toLowerCase() + "ListTitle"}>
                            <Typography variant="h3" component={"h1"} noWrap>
                                {title}{" "}
                                {total && <span className={title.toLowerCase() + "ListTitleCount"}>({total})</span>}
                            </Typography>
                        </Box>

                        {onAdd && (
                            <div>
                                <PrimaryButton
                                    aria-label="Add New"
                                    onClick={onAdd}
                                    className={title.toLowerCase() + "ListAddButton"}
                                >
                                    Add New
                                </PrimaryButton>
                            </div>
                        )}
                    </div>

                    <div className={classes.headerOptions}>
                        <Box display="flex" flex="1">
                            <Grid container spacing={1} justifyContent="flex-start" className={classes.filterContainer}>
                                {columnTitles.length > 0 && canOrder && (
                                    <Grid item md={6} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="columnTitles">Column Titles</InputLabel>
                                            <Select
                                                labelId="columnTitles"
                                                label="Column Titles"
                                                fullWidth
                                                value={selectedColumn}
                                                onChange={(event) => setSelectedColumn(event.target.value as string)}
                                            >
                                                {columnTitles.map((columnTitle) => (
                                                    <MenuItem key={columnTitle} value={columnTitle}>
                                                        {columnTitle}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {canOrder && hasPermission("UserUpdate") && (
                                    <Grid item md={6} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="order">Order</InputLabel>
                                            <Select
                                                labelId="order"
                                                label="Order"
                                                fullWidth
                                                value={orderKey}
                                                onChange={(event) => setOrderKey(event.target.value as string)}
                                            >
                                                <MenuItem value={"Ascending"} selected={orderKey === "Ascending"}>
                                                    Ascending
                                                </MenuItem>
                                                <MenuItem value={"Descending"} selected={orderKey === "Descending"}>
                                                    Descending
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {canFilterLocation && hasPermission("UserUpdate") && (
                                    <Grid item md={6} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="location">Location</InputLabel>
                                            <Select
                                                labelId="location"
                                                label="Location"
                                                fullWidth
                                                value={locationFilter}
                                                onChange={(event) => setLocationFilter(event.target.value as string)}
                                            >
                                                <MenuItem value={"All"} selected={locationFilter === "All"}>
                                                    All
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Northern Ireland"}
                                                    selected={locationFilter === "Northern Ireland"}
                                                >
                                                    Northern Ireland
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Republic of Ireland"}
                                                    selected={locationFilter === "Republic of Ireland"}
                                                >
                                                    Republic of Ireland
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Great Britain"}
                                                    selected={locationFilter === "Great Britain"}
                                                >
                                                    Great Britain
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {programmeFilter && hasPermission("ProgrammeCreate") && (
                                    <Grid item md={6} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="status">Status</InputLabel>
                                            <Select
                                                labelId="status"
                                                label="Status"
                                                fullWidth
                                                value={programmeFilterName}
                                                onChange={(event) =>
                                                    setProgrammeFilterName(event.target.value as string)
                                                }
                                            >
                                                <MenuItem value={"All"} selected={programmeFilterName === "All"}>
                                                    All
                                                </MenuItem>
                                                <MenuItem value={"Active"} selected={programmeFilterName === "Active"}>
                                                    Active
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Complete"}
                                                    selected={programmeFilterName === "Complete"}
                                                >
                                                    Complete
                                                </MenuItem>
                                                <MenuItem
                                                    value={"Archived"}
                                                    selected={programmeFilterName === "Archived"}
                                                >
                                                    Archived
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {canFilterOrg && (
                                    <Grid item xs={12} md={6}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="org">Organisation</InputLabel>
                                            <Select
                                                labelId="org"
                                                label="Organisation"
                                                fullWidth
                                                value={organisationId}
                                                onChange={(event) =>
                                                    setOrganisationId(parseInt(event.target.value as string))
                                                }
                                            >
                                                {organisations &&
                                                    organisations.map(({ id, name }) => (
                                                        <MenuItem key={id} value={id} selected={organisationId === id}>
                                                            {name}
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}

                                {selectedColumn !== "Date Registered" && selectedColumn !== "Last Logon" ? (
                                    <Grid item md={6} xs={12}>
                                        {onSearch && <Search onSearch={onSearch} />}
                                    </Grid>
                                ) : (
                                    <Grid item md={12} xs={12} className={classes.dateRow}>
                                        <Box className={classes.dateItem}>
                                            <label htmlFor="start-date">Select start date:</label>
                                            <input
                                                type="date"
                                                id="start-date"
                                                value={startDate}
                                                onChange={handleStartDate}
                                            />
                                        </Box>
                                        <Box className={classes.dateItem}>
                                            <label htmlFor="end-date">Select end date:</label>
                                            <input type="date" id="end-date" value={endDate} onChange={handleEndDate} />
                                        </Box>
                                        <Box className={classes.dateItem}>
                                            <PrimaryButton onClick={clearDates}>Clear Dates</PrimaryButton>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid
                                container
                                spacing={1}
                                justifyContent="flex-end"
                                className={classes.extraFiltersContainer}
                            >
                                {userFilter && hasPermission("UserUpdate") && (
                                    <Grid item md={6} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="status">Status</InputLabel>
                                            <Select
                                                labelId="status"
                                                label="Status"
                                                fullWidth
                                                value={userFilterName}
                                                onChange={(event) => {
                                                    const newStatus = event.target.value as string;
                                                    setUserFilterName(newStatus); // maintaing local state to work with old code
                                                    if (onUserStatusChange) {
                                                        onUserStatusChange(newStatus); // to inform List (to then inform PagedList)
                                                    }
                                                }}
                                            >
                                                <MenuItem value={"Active"} selected={userFilterName === "Active"}>
                                                    Active
                                                </MenuItem>
                                                <MenuItem value={"Archived"} selected={userFilterName === "Archived"}>
                                                    Archived
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                                {canFilterActivity && hasPermission("UserUpdate") && (
                                    <Grid item md={6} xs={12}>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="activityTitles">Last active</InputLabel>
                                            <Select
                                                labelId="activity"
                                                label="Activity"
                                                fullWidth
                                                value={activityFilter}
                                                onChange={(event) => setActivityFilter(event.target.value as string)}
                                            >
                                                <MenuItem value={"All time"} selected={activityFilter === "All time"}>
                                                    All time
                                                </MenuItem>
                                                <MenuItem value={"Today"} selected={activityFilter === "Today"}>
                                                    Today
                                                </MenuItem>
                                                <MenuItem value={"This week"} selected={activityFilter === "This week"}>
                                                    This week
                                                </MenuItem>
                                                <MenuItem
                                                    value={"This month"}
                                                    selected={activityFilter === "This month"}
                                                >
                                                    This month
                                                </MenuItem>
                                                <MenuItem value={"This year"} selected={activityFilter === "This year"}>
                                                    This year
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                )}
                            </Grid>
                        </Box>
                    </div>
                </div>
            </AppBar>
        </>
    );
};
