import { useCallback, useState } from "react";
import { get, list } from "../client/api/topic";
import { TopicType } from "../models/modelTypes";

interface TopicI {
    topics: TopicType[];
    topic?: TopicType;
    loading: boolean;

    getAllTopics: (args?: Record<string, unknown>) => Promise<void>;
    getTopic: (id: number) => Promise<void>;
}

export const useTopic = (): TopicI => {
    const [topics, setTopics] = useState<TopicType[]>([]);
    const [topic, setTopic] = useState<TopicType>();
    const [loading, setLoading] = useState<boolean>(false);

    const getAllTopics = useCallback((args?: Record<string, unknown>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            list(args ?? { includeCollections: true })
                .then(({ items }) => {
                    setTopics(items);
                    resolve();
                })
                .catch((error) => {
                    setTopics([]);
                    reject(error.message);
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getTopic = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            get(id, { includeCollections: true })
                .then((value) => {
                    setTopic(value);
                    resolve();
                })
                .catch((error) => {
                    setTopic(undefined);
                    reject(error.message);
                })
                .finally(() => setLoading(false));
        });
    }, []);

    return {
        topics,
        topic,
        loading,
        getAllTopics,
        getTopic,
    };
};
