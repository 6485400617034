import React from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { NavigateNext } from "@material-ui/icons";

type BreadcrumbProps = {
    crumbs?: { name: string; url: string }[];
    current: string;
};

export const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
    const { crumbs, current } = props;
    const { pathname } = useLocation();

    const parts = pathname.split("/").filter((part) => part && isNaN(parseInt(part)));

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                fontSize: "14px",
            },
        }),
    );

    const classes = useStyles();

    return (
        <Breadcrumbs separator={<NavigateNext fontSize={"small"} />} aria-label="breadcrumb" className={classes.root}>
            {crumbs
                ? crumbs.map((crumb) => {
                      const { name, url } = crumb;
                      return (
                          <Link component={RouterLink} key={name} color="primary" to={url}>
                              {`${name[0].toUpperCase() + name.substring(1).toLowerCase()}`}
                          </Link>
                      );
                  })
                : parts.map((part, index) => {
                      if (index < parts.length - 1) {
                          return (
                              <Link component={RouterLink} key={part} color="primary" to={`/${part}`}>
                                  {`${part[0].toUpperCase() + part.substring(1).toLowerCase()}`}
                              </Link>
                          );
                      }
                  })}
            <Typography color="textPrimary" variant={"body2"}>
                {current}
            </Typography>
            ;
        </Breadcrumbs>
    );
};
export default Breadcrumb;
