import { Formik } from "formik";
import * as yup from "yup";
import React, { useMemo, useRef, useState } from "react";
import { WizardHeading } from "../../../core/components/WizardHeading";
import { ClassroomType } from "../../../models/modelTypes";
import {
    Box,
    Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { useAuth } from "../../../contexts/auth.context";
import { TeacherSelect } from "../teachers/TeacherSelect";
import { PrimaryButton, SecondaryButton } from "../../layout/Button/Button";
import { Alert } from "@material-ui/lab";
import { FormActions } from "../../../core/components/FormActions";
import { useHistory } from "react-router-dom";
import { useClassroom } from "../contexts/classroom.context";
import { update } from "../../../client/api/classroom";
import { useMobile } from "../../../hooks/useMobile";
import { useSetTourState } from "../../tour/Store";

export const CreateClassroom = (): JSX.Element => {
    const { user } = useAuth();
    const { isTablet, isMobile } = useMobile();
    const history = useHistory();
    const { addClassroom, classroom, clearProvider } = useClassroom();
    const setTourState = useSetTourState();

    const [error, setError] = useState();

    const refBtnTab = useRef<HTMLButtonElement | null>(null);

    useMemo(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("classroomCreateBackBtn", refBtnTab) }));
    }, [refBtnTab]);

    const onSubmit = (values: Partial<ClassroomType> & { isNotPrimary: boolean }) => {
        const { name, description, ageRange, primaryUserId } = values;

        // TODO - if classroom, edit. Otherwise, add
        if (classroom) {
            update(classroom.id, {
                name,
                description,
                ageRange,
                primaryUserId,
                organisationId: user?.organisationId,
            })
                .then(() => {
                    setError(undefined);
                    clearProvider();
                    history.goBack();
                })
                .catch((error) => setError(error));
        } else {
            addClassroom({
                name,
                description,
                ageRange,
                primaryUserId,
                organisationId: user?.organisationId,
            })
                .then(() => {
                    setError(undefined);
                    clearProvider();
                    history.goBack();
                })
                .catch((error) => setError(error));
        }
    };

    const ageRanges = [
        ...(() => {
            const ageRanges: string[] = [];
            for (let i = 4; i < 17; i++) {
                ageRanges.push(`${i}-${i + 1}`);
            }
            return ageRanges;
        })(),
        "18+",
    ];

    return (
        <>
            {!isTablet && isMobile && <div style={{ paddingTop: "64px" }} />}
            <Box p={4}>
                <Container maxWidth={"md"}>
                    <WizardHeading
                        overline={classroom ? "Edit Classroom" : "Create a classroom"}
                        heading="Building your profile"
                    />
                    <Formik
                        initialValues={{
                            name: classroom?.name ?? "",
                            description: classroom?.description ?? "",
                            ageRange: classroom?.ageRange ?? "",
                            primaryUserId: user?.id,
                            isNotPrimary: false,
                        }}
                        onSubmit={onSubmit}
                        validationSchema={yup.object({
                            name: yup.string().required("Classroom name is required"),
                            ageRange: yup.string().required("Age range is required"),
                        })}
                        validateOnChange={false}
                    >
                        {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                            return (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            autoFocus
                                            required
                                            className="classroom-name"
                                            value={values.name}
                                            id="name"
                                            onChange={handleChange}
                                            label="Classroom name"
                                            fullWidth
                                            variant="outlined"
                                            helperText={errors.name && touched.name && errors.name}
                                            error={!!errors.name}
                                        />
                                    </Grid>

                                    <Grid item xs={12} className="description">
                                        <TextField
                                            value={values.description}
                                            id="description"
                                            onChange={handleChange}
                                            label="Classroom description"
                                            fullWidth
                                            multiline
                                            variant="outlined"
                                            helperText={errors.description && touched.description && errors.description}
                                            error={!!errors.description}
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl variant="outlined" error={!!errors.ageRange} fullWidth>
                                            <InputLabel id="age-label" required>
                                                Age Group of Class
                                            </InputLabel>
                                            <Select
                                                fullWidth
                                                label="Age Group of Class"
                                                labelId="age-label"
                                                name="ageRange"
                                                id="ageRange"
                                                onChange={handleChange}
                                                value={values.ageRange}
                                            >
                                                <MenuItem key={0} value={""} disabled>
                                                    Please select
                                                </MenuItem>
                                                {ageRanges.map((ageRange) => (
                                                    <MenuItem key={ageRange} value={ageRange}>
                                                        {ageRange}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <FormHelperText>
                                                {errors.ageRange && touched.ageRange && errors.ageRange}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} className="mainTeacher">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    value={values.isNotPrimary}
                                                    onChange={handleChange}
                                                    name="isNotPrimary"
                                                    color="primary"
                                                />
                                            }
                                            label="Click here if you are not the main teacher"
                                        />
                                    </Grid>

                                    <TeacherSelect
                                        currentUserId={user?.id}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                    />

                                    <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                        Input fields marked with an asterisk (*) are required
                                    </Grid>

                                    <Grid item xs={12}>
                                        {error && (
                                            <Box mb={2}>
                                                <Alert severity="error">{error}</Alert>
                                            </Box>
                                        )}
                                        <FormActions
                                            right={
                                                <>
                                                    <PrimaryButton
                                                        className="cont-btn"
                                                        size="large"
                                                        disabled={isSubmitting}
                                                        onClick={submitForm}
                                                    >
                                                        Continue
                                                    </PrimaryButton>
                                                </>
                                            }
                                            left={
                                                <SecondaryButton
                                                    ref={refBtnTab}
                                                    className="back-btn"
                                                    size="large"
                                                    onClick={() => history.goBack()}
                                                >
                                                    Back
                                                </SecondaryButton>
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            );
                        }}
                    </Formik>
                </Container>
            </Box>
        </>
    );
};
