import React from "react";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useReadingLayoutStyle } from "./ReadingLayout.style";
import { verbalTheme } from "../../themes/verbal.theme";
import { ReadingHeader } from "../../../../core/components/ReadingHeader";

export const ReadingLayout: React.FC = ({ children }): React.ReactElement => {
    const classes = useReadingLayoutStyle();
    return (
        <ThemeProvider theme={verbalTheme}>
            <CssBaseline />
            <ReadingHeader />
            <div className={classes.root}>
                <main className={classes.main}>{children}</main>
            </div>
        </ThemeProvider>
    );
};
