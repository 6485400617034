import { useCallback, useState } from "react";
import { get, list } from "../client/api/theme";
import { ThemeType } from "../models/modelTypes";

interface ThemesI {
    themes: ThemeType[];
    theme?: ThemeType;
    loading: boolean;

    getAllThemes: (args?: Record<string, unknown>) => Promise<void>;
    getTheme: (id: number) => Promise<void>;
}

export const useThemes = (): ThemesI => {
    const [themes, setThemes] = useState<ThemeType[]>([]);
    const [theme, setTheme] = useState<ThemeType>();
    const [loading, setLoading] = useState<boolean>(false);

    const getAllThemes = useCallback((args?: Record<string, unknown>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            list(args ?? { take: "10000" })
                .then(({ items }) => {
                    setThemes(items);
                    resolve();
                })
                .catch((error) => {
                    setThemes([]);
                    reject(error.message);
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const getTheme = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            get(id, { includeCollections: true, includeCurriculums: true })
                .then((value) => {
                    setTheme(value);
                    resolve();
                })
                .catch((error) => {
                    setTheme(undefined);
                    reject(error.message);
                })
                .finally(() => setLoading(false));
        });
    }, []);

    return {
        themes,
        theme,
        loading,
        getAllThemes,
        getTheme,
    };
};
