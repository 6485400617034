import React from "react";
import Button from "@material-ui/core/Button";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { ThemeProvider } from "@material-ui/core";
import { useSurveyDialog } from "../../public/hooks/surveyDialog.context";
import { VDialog } from "../../../core/components/VDialog";
import OptOutImg from "../../../img/opt-out.svg";

export const SurveyDialogOptedOut = (): JSX.Element => {
    const { openDialog, shouldOpenDialog } = useSurveyDialog();

    return (
        <ThemeProvider theme={verbalTheme}>
            <VDialog
                icon={<img src={OptOutImg} />}
                open={openDialog}
                header={"Opted out"}
                content={"You have now been opted out of recieving surveys for the remainder of this programme"}
                actions={
                    <>
                        <Button
                            onClick={() => shouldOpenDialog(false)}
                            fullWidth
                            variant="contained"
                            size={"large"}
                            color="primary"
                        >
                            Return to dashboard
                        </Button>
                    </>
                }
            />
        </ThemeProvider>
    );
};
export default SurveyDialogOptedOut;
