import { Prisma, Programme, Session } from "common/build/prisma/client";
import { deleteData, getData, patchData, postData } from "./rest";
import { objectToQueryString } from "../../utils/api";
import { SessionFetchParameters } from "common/build/api-parameters/session";

export const add = async (session: Session): Promise<Response> => await postData("/session", session);

export const list = async (programmeId: number): Promise<Session[]> => await getData(`/session/${programmeId}`);

export const update = async (
    id: number,
    editedSession: Partial<Prisma.SessionUpdateInput | Prisma.SessionUncheckedUpdateInput>,
): Promise<Response> => await patchData(`/session/${id}`, editedSession);

export const get = async (id: number, args: SessionFetchParameters): Promise<Session> => {
    return await getData(`/session/${id}${objectToQueryString(args)}`);
};

export const listProgrammes = async (): Promise<Programme[]> => await getData("/session/programmes/all");

export const deleteProgramme = async (programmeId: number): Promise<Response> =>
    await deleteData(`/session/programme/${programmeId}`);

export const admitSessionParticipants = async (
    id: number,
    editedSession: Partial<Prisma.SessionUpdateInput | Prisma.SessionUncheckedUpdateInput>,
): Promise<Response> => await patchData(`/session/${id}/admit-participants`, editedSession);

export const startSessionSurvey = async (
    id: number,
    editedSession: Partial<Prisma.SessionUpdateInput | Prisma.SessionUncheckedUpdateInput>,
): Promise<Response> => await patchData(`/session/${id}/start-survey`, editedSession);

export const updateSessionState = async (
    id: number,
    editedSession: Partial<Prisma.SessionUpdateInput | Prisma.SessionUncheckedUpdateInput>,
): Promise<Response> => await patchData(`/session/${id}/state`, editedSession);

export const updateSessionStoryPart = async (
    id: number,
    editedSession: Partial<Prisma.SessionUpdateInput | Prisma.SessionUncheckedUpdateInput>,
): Promise<Response> => await patchData(`/session/${id}/part`, editedSession);

export const checkIfPrepared = async (id: number): Promise<boolean> => await getData(`/session/${id}/prepared`);
