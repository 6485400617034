import React from "react";
import { CreateOrganisationFlowStages, useOnboarding } from "./onboarding/contexts/onboarding.context";
import { OnboardingWizard } from "./onboarding/wizard/OnboardingWizard";
import OrgansationSignUp from "./onboarding/OrganisationSignUp";

export const CreateOrganisation: React.FC = () => {
    const { stage } = useOnboarding();
    if (stage === CreateOrganisationFlowStages.credentials) {
        return <OrgansationSignUp />;
    }

    return <OnboardingWizard />;
};
