import { createMuiTheme } from "@material-ui/core";
// We create a MUI Theme object, then we override it using variables from the mui theme in the override.
// This overridden theme object is exported for use in `ThemeProvider` and custom styles.

declare module "@material-ui/core/styles/createPalette" {
    interface PaletteOptions {
        secondaryShade?: PaletteColorOptions;
        themePill?: PaletteColorOptions;
    }
    interface Palette {
        secondaryShade: PaletteColor;
        themePill: PaletteColor;
    }
}

const muiTheme = createMuiTheme({
    //Main theming options(Colors and Type etc.)
    palette: {
        type: "dark",
        primary: {
            main: "#1FAF8C",
        },
        secondary: {
            main: "#121738",
            dark: "#0B102D",
            light: "#1A1E37",
        },
        error: {
            main: "#C34D48",
            dark: "#50181F",
        },
        success: {
            main: "#4C9D81",
            dark: "#08281D",
        },
        warning: {
            main: "#C18740",
            dark: "#2E2316",
        },
        info: {
            main: "#4476C5",
            dark: "#122542",
        },
        text: {
            primary: "#ffffff",
            disabled: "rgba(255, 255, 255, 0.38)",
        },
        secondaryShade: {
            main: "#F0F2F8",
        },
        themePill: {
            main: "#1F7BAF",
            dark: "#186088",
        },
        background: {
            default: "#121738",
            paper: "#0f122d",
            // paper: "rgba(255,255,255,0.1)",
        },
    },
    typography: {
        fontFamily: "'Montserrat',sans-serif",
        h1: {
            fontWeight: 700,
            fontSize: 72,
            letterSpacing: 0.5,
        },
        h2: {
            fontSize: 48,
            fontWeight: 700,
        },
        h3: {
            fontWeight: 700,
            fontSize: 32,
        },
        h4: {
            fontWeight: 700,
            fontSize: 26,
        },
        h5: {
            fontWeight: 700,
            fontSize: 20,
            letterSpacing: 0.5,
        },
        h6: {
            fontWeight: 700,
            fontSize: 16,
        },
        subtitle1: {
            fontWeight: 700,
        },
        body2: {
            fontSize: 14,
        },
        overline: {
            fontSize: 14,
            textTransform: "uppercase",
        },
    },
    shape: {
        borderRadius: 8,
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

export const publicTheme = {
    ...muiTheme,
    //Sets Props at a global level
    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The default props to change
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
        MuiTab: {
            disableRipple: true,
        },
    },
    //Override Styling on components at a global level
    overrides: {
        MuiContainer: {
            maxWidthMd: {
                maxWidth: "1100px !important",
            },
        },

        MuiTableCell: {
            head: {
                fontWeight: 700,
            },
        },

        MuiDialogTitle: {
            root: {
                margin: 0,
                padding: muiTheme.spacing(3),
                paddingBottom: muiTheme.spacing(1),
            },
        },

        MuiDialogContent: {
            root: {
                padding: muiTheme.spacing(3),
                paddingTop: muiTheme.spacing(2),
                paddingBottom: muiTheme.spacing(2),
            },
        },

        MuiDialogActions: {
            root: {
                padding: muiTheme.spacing(3),
                paddingTop: muiTheme.spacing(1),
            },
        },

        MuiInputBase: {
            root: {
                height: "52px",
            },
        },
        MuiInputLabel: {
            root: {
                color: muiTheme.palette.common.white,
                top: "-1px",
                "&.Mui-error": {
                    color: "#fff!important",
                    borderColor: muiTheme.palette.common.white,
                    borderWidth: 2,
                },
            },
            formControl: {
                top: "-1px",
            },
            asterisk: {
                "&$error": {
                    color: muiTheme.palette.common.white,
                },
            },
        },

        MuiFormLabel: {
            root: {
                "&.Mui-error": {
                    color: muiTheme.palette.error.light,
                },
            },
        },

        MuiFormHelperText: {
            root: {
                "&.Mui-error": {
                    color: muiTheme.palette.common.white,
                },
            },
        },

        MuiOutlinedInput: {
            root: {
                "&$focused $notchedOutline": {
                    borderColor: muiTheme.palette.common.white,
                    borderWidth: 1,
                },
                "&.Mui-error $notchedOutline": {
                    borderColor: muiTheme.palette.common.white,
                    borderWidth: 2,
                },
            },
        },

        MuiFormControl: {
            root: {
                marginTop: muiTheme.spacing(2),
            },
        },

        MuiAlert: {
            root: {
                fontWeight: "700",
            },
            standardSuccess: {
                backgroundColor: muiTheme.palette.success.dark,
            },
            standardError: {
                backgroundColor: muiTheme.palette.error.dark,
            },
            standardInfo: {
                backgroundColor: muiTheme.palette.info.dark,
            },
            standardWarning: {
                backgroundColor: muiTheme.palette.warning.dark,
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: muiTheme.palette.secondary.main,
            },
        },
        MuiPaper: {
            root: {
                border: "0",
                boxShadow: "0px 0px 22px rgba(0,0,0,0.08)",
            },
            elevation1: {
                boxShadow: "0px 0px 22px rgba(0,0,0,0.08)",
            },
        },

        //BUTTON STYLES

        MuiButton: {
            contained: {
                backgroundColor: "#A0A2AF",
                color: "white",
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
                "&:hover": {
                    boxShadow: "none",
                    backgroundColor: muiTheme.palette.secondary.light,
                },
            },
            containedPrimary: {
                backgroundColor: muiTheme.palette.primary.main,
                color: "white",
                "&:hover": {
                    boxShadow: "none",
                    backgroundColor: muiTheme.palette.primary.dark,
                },
            },
            containedSecondary: {
                backgroundColor: muiTheme.palette.secondary.main,
                color: "white",
                "&:hover": {
                    boxShadow: "none",
                    backgroundColor: muiTheme.palette.secondary.dark,
                },
            },
            containedSizeLarge: {
                height: 48,
                fontSize: 16,
            },
            outlinedSizeLarge: {
                height: 48,
                fontSize: 16,
            },
            label: {
                textTransform: "none",
                //color: "white",
                fontWeight: 700,
            },
        },
        MuiTabs: {
            root: {
                borderBottom: "1px solid #D0D1D7",
                borderTop: "1px solid #D0D1D7",
            },
            indicator: {
                backgroundColor: muiTheme.palette.primary.main,
                height: 3,
            },
        },
        MuiTab: {
            root: {
                fontWeight: "700",
                textTransform: "none",
                padding: muiTheme.spacing(2, 4, 2, 4),
                // [muiTheme.breakpoints.up("md")]: {
                //     padding: 0,
                //     minWidth: 0,
                // },
            },
        },
        MuiIconButton: {
            root: {
                padding: muiTheme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#404854",
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: muiTheme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                marginRight: 0,
                "& svg": {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            colorDefault: {
                backgroundColor: muiTheme.palette.primary.main,
            },
            root: {
                fontWeight: 700,
                fontSize: "14px",
            },
        },
        MuiStepConnector: {
            line: {
                display: "none",
            },
        },
        MuiBottomNavigationAction: {
            root: {
                color: muiTheme.palette.common.white,
                padding: muiTheme.spacing(2),
                "&.Mui-selected": {
                    paddingTop: muiTheme.spacing(2),
                },
            },
            label: {
                fontWeight: 700,
                marginTop: muiTheme.spacing(0.5),
                fontSize: "12px",
                "&.Mui-selected": {
                    fontSize: "12px",
                },
            },
        },
        MuiBottomNavigation: {
            root: {
                height: "82px",
            },
        },

        MuiAccordion: {
            root: {
                boxShadow: "none",
                marginBottom: muiTheme.spacing(3),
                "&.Mui-expanded": {
                    //margin: muiTheme.spacing(0, 0),
                },
            },
        },
        MuiAccordionSummary: {
            root: {
                padding: muiTheme.spacing(0, 3, 0, 3),
                minHeight: muiTheme.spacing(7),
            },
        },
        MuiAccordionDetails: {
            root: {
                padding: muiTheme.spacing(3),
                paddingTop: 0,
            },
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom: "1em",
            },
        },
    },
};
