import { IconButton } from "@material-ui/core";
import { useCrud } from "../../../core/components/crud/contexts/crud.context";
import React from "react";
import { useList } from "./contexts/list.context";
import dayjs from "dayjs";
import { Delete } from "@material-ui/icons";

export function email(email: string): JSX.Element {
    return (
        <a href={`mailto:${email}`} onClick={(e) => e.stopPropagation()}>
            {email}
        </a>
    );
}

/**
 * @deprecated decorators can now accept strings
 * @param value
 */
export const simple = (value: string): string => value;

export const date = (value: string): string => (value && dayjs(value as unknown as Date).format("D MMM YYYY")) || "N/A";

export function deleteRow<T extends Record<string, unknown>>(id: number): JSX.Element {
    const { deleteItem } = useCrud<T>();
    return (
        <IconButton aria-label="delete" onClick={() => deleteItem(id)}>
            <Delete />
        </IconButton>
    );
}

export function disconnectRow<T extends Record<string, unknown>>(
    data: T & { [key: string]: string | number | null | Date | unknown[] },
    relationEntity: string,
): JSX.Element {
    const { update } = useCrud<T>();
    const { fetchList } = useList<T>();

    const entity = data[relationEntity] as T & { id: number }[];
    const relationId = entity[0].id;
    const id = data.id;

    const disconnectParticipant =
        relationEntity === "ParticipantToClassroom"
            ? ({
                  ParticipantToClassroom: {
                      deleteMany: [{ id: relationId }],
                  },
              } as unknown)
            : ({
                  ParticipantToProgramme: {
                      deleteMany: [{ id: relationId }],
                  },
              } as unknown);

    return (
        <IconButton
            aria-label="delete"
            onClick={() =>
                update(id as number, disconnectParticipant as T).then(() => {
                    fetchList();
                })
            }
        >
            <Delete />
        </IconButton>
    );
}
