import React, { ComponentProps } from "react";
import {
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    makeStyles,
    useTheme,
    TypographyProps,
} from "@material-ui/core";

export enum VListItemType {
    Primary = "Primary",
    Secondary = "Secondary",
    White = "White",
}

type ListItemPropType = Omit<ComponentProps<typeof ListItem>, "button"> & { button?: true };

type VListItemProps = ListItemPropType & {
    type?: VListItemType;
    children?: React.ReactNode;
    avatar?: React.ReactNode;
    primaryText?: string;
    secondaryText?: string;
    textProps?: TypographyProps;
};

export const VListItem: React.FC<VListItemProps> = (props: VListItemProps) => {
    const { avatar, primaryText, secondaryText, type, children, textProps, ...rest } = props;
    const theme = useTheme();

    const getListAvatarBackground = () => {
        if (type === VListItemType.Primary) {
            return theme.palette.primary.main;
        } else if (type === VListItemType.Secondary) {
            return theme.palette.secondary.dark;
        } else if (type === VListItemType.White) {
            return theme.palette.common.white;
        }
        return theme.palette.common.white;
    };

    const useStyles = makeStyles(() => ({
        defaultColour: {
            backgroundColor: getListAvatarBackground(),
        },
    }));

    const classes = useStyles();
    return (
        <ListItem button {...rest}>
            {avatar && (
                <ListItemAvatar>
                    <Avatar className={classes.defaultColour}>{avatar}</Avatar>
                </ListItemAvatar>
            )}
            <ListItemText primaryTypographyProps={textProps} primary={primaryText} secondary={secondaryText} />
            {children}
        </ListItem>
    );
};
