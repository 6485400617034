import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FetchProvider, useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { useQueryStringState } from "../../../hooks/useQueryString";
import { ClassroomType, MoodType, ProgrammeType } from "../../../models/modelTypes";
import { Error } from "../../../core/components/Error";
import { Box, createStyles, Grid, makeStyles, Paper, Tab, Tabs, Typography, useMediaQuery } from "@material-ui/core";
import { useAuth } from "../../../contexts/auth.context";
import { useSetTourState } from "../../tour/Store";
import { useMobile } from "../../../hooks/useMobile";
import { TabPanel } from "../../programme/screens/ViewProgramme";
import { ClassActiveProgWidget } from "../components/widgets/ClassActiveProgWidget";
import { ClassCountWidget } from "../components/widgets/ClassCountWidget";
import { ClassroomStudentScreen } from "../students/ClassroomStudentScreen";
import Crud from "../../../core/components/crud/Crud";
import { ProgrammeListParameters } from "common/src/api-parameters/programmes";
import { addProgramme, get, list } from "../../../client/api/programme";
import { ClassroomTeacherScreen } from "../teachers/ClassroomTeacherScreen";
import { useClassroom } from "../contexts/classroom.context";
import { ProgrammeProvider } from "../../programme/contexts/programme.context";
import { CreateClassProgramme } from "./CreateClassProgramme";
import { ViewClassProgramme } from "./ViewClassProgramme";
import { Session } from "common/build/prisma/client";
import { sortSessions } from "../../../utils/sorts";
import dayjs from "dayjs";
import { ClassroomStats } from "common/build/api-parameters/users";
import { getClassroomStats } from "../../../client/api/users";
import { VictoryAxis, VictoryChart, VictoryContainer, VictoryLabel, VictoryLine, VictoryTheme } from "victory";
import { ClassList } from "../components/ClassList";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import barchartImg from "../../../img/barchart.svg";
import { SimpleBlankSlate } from "../../../core/components/SimpleBlankSlate";
import { AverageWellbeingBar } from "../components/AverageWellbeingBar";
import { NegativeDrivers } from "../components/NegativeDrivers";
import { ClassWellbeingWidget } from "../components/widgets/ClassWellbeingWidget";
import { ClassWellbeingBreakdownWidget } from "../components/widgets/ClassWellbeingBreakdown";
import clsx from "clsx";

const classProgrammesCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Programme Name",
    },
    pathwayId: {
        label: "Story Collection",
        inForm: false,
        decorator: (value: string, property?: string, programme?: ProgrammeType): string =>
            programme?.StoryCollection ? programme?.StoryCollection.name : "None",
    },
    nextSession: {
        label: "Next Session",
        inForm: false,
        decorator: (value: string, property?: string, programme?: ProgrammeType): string => {
            const deleted = programme?.deleted;
            const sessions: Session[] = programme?.Sessions ?? [];
            const activeSessions = [...sessions].filter((session) => session.state !== "Complete").sort(sortSessions);
            return deleted || activeSessions.length < 1
                ? "None Upcoming"
                : dayjs(activeSessions[0].date).format("D MMM YYYY [at] HH:mm");
        },
    },
};

const tabs = ["overview", "students", "staff", "data"];

export const ViewClassroom = (): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const { item, fetching, fetchItem } = useFetch<ClassroomType>();
    const { user, hasPermission } = useAuth();
    const setTourState = useSetTourState();
    const { isMobile, isTablet } = useMobile();
    const { setClassroom, classroom } = useClassroom();

    const [error, setError] = useState<boolean>(false);

    const [tabValue, setTabValue] = useQueryStringState("tab", tabs[0]);
    const [tabIndex, setTabIndex] = useState(Math.max(tabs.indexOf(tabValue), 0));

    useEffect(() => {
        setTabIndex(Math.max(tabs.indexOf(tabValue), 0));
    }, [tabValue]);

    const refOverviewTab = useRef<HTMLDivElement | null>(null);
    const refStudentsTab = useRef<HTMLDivElement | null>(null);
    const refStaffTab = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("classroomOverviewTab", refOverviewTab) }));
    }, [refOverviewTab]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("classroomStudentsTab", refStudentsTab) }));
    }, [refStudentsTab]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("classroomStaffTab", refStaffTab) }));
    }, [refStaffTab]);

    const numTeachers = (classroom?.Teachers?.length || 0) + 1;
    const isSmall = useMediaQuery(verbalTheme.breakpoints.down("md"));
    const isLarge = useMediaQuery(verbalTheme.breakpoints.up("lg"));

    // filter and sort student moods. Filter out moods in which the student is not present. Sort by date
    const studentMoods =
        classroom?.Students?.flatMap((v) => v.Moods ?? [])
            .filter((m) => m?.isPresent)
            .sort((a, b) => {
                if (a?.date && b?.date) {
                    return new Date(a.date).getTime() - new Date(b.date).getTime();
                } else {
                    return -1;
                }
            }) ?? [];

    const negativeMoods = studentMoods?.filter((m) => ["Angry", "Sad", "Worried"].includes(m?.mood ?? ""));
    const positiveMoods = studentMoods?.filter((m) => ["Happy"].includes(m?.mood ?? ""));
    const neutralMoods = studentMoods?.filter((m) => ["Calm"].includes(m?.mood ?? ""));

    const todayDate = new Date().toISOString().split("T")[0];
    const todaysCheckinMoods = studentMoods?.filter((m) => {
        return m?.date?.toLocaleString().split("T")[0] === todayDate && m.type === "Registration";
    });
    const todaysSessionMoods = studentMoods?.filter((m) => {
        return m?.date?.toLocaleString().split("T")[0] === todayDate && m.type === "Session";
    });

    // Group moods by date to get an average positivity score for each day.
    const grouped = studentMoods.reduce((groups: Record<string, MoodType[]>, mood) => {
        if (mood && mood.date) {
            const date = mood?.date?.toLocaleString().split("T")[0];
            if (!groups[date]) {
                groups[date] = [];
            }
            groups[date].push(mood);
        }
        return groups;
    }, {});

    const groupedMoods = Object.keys(grouped).map((date) => {
        return {
            date,
            moods: grouped[date],
        };
    });

    useEffect(() => {
        if (parseInt(id) !== item?.id) {
            fetchItem(parseInt(id))
                .then(() => {
                    setError(false);
                })
                .catch(() => setError(true));
        }
    }, [id, item?.id]);

    useEffect(() => {
        if (item) {
            setClassroom(item);
        }
    }, [item]);

    if (error) {
        return <Error description={`Unable to load Classroom}`} />;
    }

    const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        if (tabValue != tabs[newValue]) {
            setTabValue(tabs[newValue]);
        }
    };

    const useStyles = makeStyles(() =>
        createStyles({
            headerColor: {
                backgroundColor: "#F9F9FB",
            },
        }),
    );

    const classes = useStyles();

    const getPositiveGraphLineData = (noOfDays: number, type: string, sessionId: number | null) => {
        return groupedMoods.slice(0 - noOfDays).map((v) => {
            const thisTypeMoods = v.moods.filter(
                (mood) => (sessionId === null || mood.sessionId === sessionId) && mood.type === type,
            );

            return {
                x: dayjs(v.date).format("ddd D MMM"),
                y:
                    thisTypeMoods?.length ?? 0 > 0
                        ? (100 / thisTypeMoods?.length ?? 1) *
                          (thisTypeMoods?.filter((m) => ["Happy", "Calm"].includes(m.mood)).length ?? 0)
                        : null,
            };
        });
    };

    return (
        <>
            {!isTablet && isMobile && <div style={{ marginTop: 56 }} />}
            <Box className={classes.headerColor}>
                <Box p={4}>
                    <Box mb={2}>
                        <Typography variant="h3">Hello {user?.firstName}, welcome to your classroom</Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography variant="h2" component="h1" color="textPrimary" align="left">
                            {`${classroom && classroom?.name ? `${classroom?.name}` : `Classroom`}`}
                        </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems="center">
                        <Typography variant="body2" color="textPrimary" align="left">
                            {`${
                                classroom
                                    ? `${classroom.ageRange} Years / ${numTeachers} Staff / ${classroom.Students?.length} Students`
                                    : ""
                            }`}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            {!fetching && classroom ? (
                <>
                    <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="primary"
                        className={classes.headerColor}
                    >
                        <Tab label="Overview" id="overview-tab" ref={refOverviewTab} />

                        <Tab label="Add Students" id="student-tab" ref={refStudentsTab} />

                        <Tab label="Add Staff" id="staff-tab" ref={refStaffTab} />
                    </Tabs>

                    <TabPanel index={0} value={tabIndex}>
                        <FetchProvider<ClassroomStats>
                            noun="stats"
                            getItem={() => getClassroomStats(classroom.id)}
                            fetchOnload
                        >
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} lg className={clsx("classAciveProgWidget")}>
                                    <ClassActiveProgWidget />
                                </Grid>

                                <Grid item xs={12} sm={6} lg className={clsx("classCountWidget")}>
                                    <ClassCountWidget />
                                </Grid>

                                <Grid item xs={12} sm={12} lg className={clsx("classWellbeingWidget")}>
                                    <ClassWellbeingWidget
                                        empty={studentMoods.length < 1}
                                        percent={
                                            studentMoods.length > 0
                                                ? (100 / studentMoods.length) *
                                                  (positiveMoods.length + neutralMoods.length)
                                                : 100
                                        }
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} lg className={clsx("classMoodsTodayWidget")}>
                                    <ClassWellbeingBreakdownWidget
                                        empty={todaysCheckinMoods.length < 1}
                                        moods={todaysCheckinMoods}
                                        title={"Today’s Check-in Results"}
                                    ></ClassWellbeingBreakdownWidget>
                                </Grid>

                                <Grid item xs={12} sm={6} lg className={clsx("classMoodsAfterSessionWidget")}>
                                    <ClassWellbeingBreakdownWidget
                                        empty={todaysSessionMoods.length < 1}
                                        moods={todaysSessionMoods}
                                        title={"After Today’s Verbal Wellbeing Session"}
                                    ></ClassWellbeingBreakdownWidget>
                                </Grid>
                            </Grid>{" "}
                        </FetchProvider>
                        <Box marginBottom={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={8}>
                                    <Paper
                                        style={{
                                            backgroundColor: verbalTheme.palette.secondaryShade.main,
                                            borderRadius: 5,
                                            marginTop: verbalTheme.spacing(2),
                                            paddingTop: verbalTheme.spacing(2),
                                            height: "100%",
                                        }}
                                        variant="outlined"
                                    >
                                        {hasPermission("ClassroomReportView") ? (
                                            groupedMoods && groupedMoods.length > 0 ? (
                                                <>
                                                    <Typography align="center" variant={"h4"}>
                                                        Your Class Wellbeing
                                                    </Typography>
                                                    <AverageWellbeingBar
                                                        allMoods={studentMoods}
                                                        positiveMoods={positiveMoods}
                                                        negativeMoods={negativeMoods}
                                                        neutralMoods={neutralMoods}
                                                    />

                                                    {groupedMoods.length > 1 ? (
                                                        <VictoryChart
                                                            containerComponent={<VictoryContainer />}
                                                            padding={{
                                                                top: 0,
                                                                bottom: isSmall ? 60 : isLarge ? 50 : 0,
                                                                right: 10,
                                                                left: 35,
                                                            }}
                                                            domainPadding={{ x: 10 }}
                                                            theme={VictoryTheme.material}
                                                            maxDomain={{ y: 101 }}
                                                            height={isSmall ? 200 : 150}
                                                        >
                                                            <VictoryAxis
                                                                label={"Date of Check-in"}
                                                                axisLabelComponent={<VictoryLabel dy={15} />}
                                                                style={{
                                                                    axisLabel: {
                                                                        fontSize: 8,
                                                                        fill: verbalTheme.palette.primary.main,
                                                                    },
                                                                    tickLabels: {
                                                                        fontSize: 6,
                                                                    },
                                                                }}
                                                            />
                                                            <VictoryAxis
                                                                axisLabelComponent={<VictoryLabel dy={-17} dx={-5} />}
                                                                label={"% of class reporting positive wellbeing"}
                                                                dependentAxis
                                                                orientation="left"
                                                                domain={{ y: [0, 100] }}
                                                                style={{
                                                                    axisLabel: {
                                                                        fontSize: 8,
                                                                        fill: verbalTheme.palette.primary.main,
                                                                    },
                                                                    tickLabels: {
                                                                        fontSize: 6,
                                                                    },
                                                                }}
                                                            />
                                                            <VictoryLine
                                                                labels={({ datum }) => Math.round(datum.y)}
                                                                labelComponent={
                                                                    <VictoryLabel
                                                                        style={{ fontSize: 6 }}
                                                                        dy={-5}
                                                                        renderInPortal
                                                                    />
                                                                }
                                                                data={getPositiveGraphLineData(7, "Registration", null)}
                                                            />
                                                        </VictoryChart>
                                                    ) : (
                                                        <SimpleBlankSlate
                                                            image={<img src={barchartImg} />}
                                                            header={"Class Wellbeing Chart"}
                                                            content={`When the class has started to mark their mood over a number of days, this will show the average class mood over time`}
                                                            extra={
                                                                <>
                                                                    <Box mb={2}>
                                                                        <Typography variant={"body2"} color={"primary"}>
                                                                            (Available after the second student
                                                                            Check-In)
                                                                        </Typography>
                                                                    </Box>
                                                                </>
                                                            }
                                                        />
                                                    )}
                                                </>
                                            ) : (
                                                <SimpleBlankSlate
                                                    image={<img src={barchartImg} />}
                                                    header={"Average class Wellbeing"}
                                                    content={`When Check-Ins have been completed, this will show a snapshot of the class overall wellbeing`}
                                                    extra={
                                                        <>
                                                            <Box mb={2}>
                                                                <Typography variant={"body2"} color={"primary"}>
                                                                    (Available after the first student Check-In)
                                                                </Typography>
                                                            </Box>
                                                        </>
                                                    }
                                                />
                                            )
                                        ) : (
                                            <SimpleBlankSlate
                                                image={<img src={barchartImg} />}
                                                header={"Average class Wellbeing"}
                                                content={`You do not have permission to view the class wellbeing chart`}
                                                extra={
                                                    <>
                                                        <Box mb={2}>
                                                            <Typography variant={"body2"} color={"primary"}>
                                                                (Available for Staff Members and School Administrators
                                                                only)
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                }
                                            />
                                        )}
                                        {/* {groupedMoods && groupedMoods.length > 0 ? (
                                            <>
                                                <Typography align="center" variant={"h4"}>
                                                    Your Class Wellbeing
                                                </Typography>
                                                <AverageWellbeingBar
                                                    allMoods={studentMoods}
                                                    positiveMoods={positiveMoods}
                                                    negativeMoods={negativeMoods}
                                                    neutralMoods={neutralMoods}
                                                />

                                                {groupedMoods.length > 1 ? (
                                                    <VictoryChart
                                                        containerComponent={<VictoryContainer />}
                                                        padding={{
                                                            top: 0,
                                                            bottom: isSmall ? 60 : isLarge ? 50 : 0,
                                                            right: 10,
                                                            left: 35,
                                                        }}
                                                        domainPadding={{ x: 10 }}
                                                        theme={VictoryTheme.material}
                                                        maxDomain={{ y: 101 }}
                                                        height={isSmall ? 200 : 150}
                                                    >
                                                        <VictoryAxis
                                                            label={"Date of Check-in"}
                                                            axisLabelComponent={<VictoryLabel dy={15} />}
                                                            style={{
                                                                axisLabel: {
                                                                    fontSize: 8,
                                                                    fill: verbalTheme.palette.primary.main,
                                                                },
                                                                tickLabels: {
                                                                    fontSize: 6,
                                                                },
                                                            }}
                                                        />
                                                        <VictoryAxis
                                                            axisLabelComponent={<VictoryLabel dy={-17} dx={-5} />}
                                                            label={"% of class reporting positive wellbeing"}
                                                            dependentAxis
                                                            orientation="left"
                                                            domain={{ y: [0, 100] }}
                                                            style={{
                                                                axisLabel: {
                                                                    fontSize: 8,
                                                                    fill: verbalTheme.palette.primary.main,
                                                                },
                                                                tickLabels: {
                                                                    fontSize: 6,
                                                                },
                                                            }}
                                                        />
                                                        <VictoryLine
                                                            labels={({ datum }) => Math.round(datum.y)}
                                                            labelComponent={
                                                                <VictoryLabel
                                                                    style={{ fontSize: 6 }}
                                                                    dy={-5}
                                                                    renderInPortal
                                                                />
                                                            }
                                                            data={groupedMoods.slice(-7).map((v) => {
                                                                return {
                                                                    x: dayjs(v.date).format("ddd D MMM"),
                                                                    y:
                                                                        (100 / v.moods.length ?? 1) *
                                                                        v.moods.filter((m) =>
                                                                            ["Happy", "Calm"].includes(m.mood),
                                                                        ).length,
                                                                };
                                                            })}
                                                        />
                                                    </VictoryChart>
                                                ) : (
                                                    <SimpleBlankSlate
                                                        image={<img src={barchartImg} />}
                                                        header={"Class Wellbeing Chart"}
                                                        content={`When the class has started to mark their mood over a number of days, this will show the average class mood over time`}
                                                        extra={
                                                            <>
                                                                <Box mb={2}>
                                                                    <Typography variant={"body2"} color={"primary"}>
                                                                        (Available after the second student Check-In)
                                                                    </Typography>
                                                                </Box>
                                                            </>
                                                        }
                                                    />
                                                )}
                                            </>
                                        ) : (
                                            <SimpleBlankSlate
                                                image={<img src={barchartImg} />}
                                                header={"Average class Wellbeing"}
                                                content={`When Check-Ins have been completed, this will show a snapshot of the class overall wellbeing`}
                                                extra={
                                                    <>
                                                        <Box mb={2}>
                                                            <Typography variant={"body2"} color={"primary"}>
                                                                (Available after the first student Check-In)
                                                            </Typography>
                                                        </Box>
                                                    </>
                                                }
                                            />
                                        )} */}
                                    </Paper>
                                </Grid>
                                <Grid item container spacing={0} xs={12} md={4} direction={"column"}>
                                    <Grid
                                        item
                                        style={{ paddingBottom: verbalTheme.spacing(2) }}
                                        className={clsx("classStudentWellbeingOverview")}
                                    >
                                        <Paper
                                            style={{
                                                backgroundColor: verbalTheme.palette.secondaryShade.main,
                                                borderRadius: 5,
                                                marginTop: verbalTheme.spacing(2),
                                                paddingTop: verbalTheme.spacing(2),
                                                height: "100%",
                                            }}
                                            variant="outlined"
                                        >
                                            {classroom?.Students && classroom.Students.length > 0 ? (
                                                <>
                                                    <Typography align="center" variant="h4">
                                                        Student Wellbeing
                                                    </Typography>
                                                    {hasPermission("ClassroomReportView") ? (
                                                        <Typography variant={"body2"} align="center" color={"primary"}>
                                                            (Click a student name to view their profile)
                                                        </Typography>
                                                    ) : null}
                                                </>
                                            ) : null}

                                            <ClassList expandable={true} />
                                        </Paper>
                                    </Grid>

                                    <Grid item className={clsx("classNegativeDriversOverview")}>
                                        <NegativeDrivers allNegativeMoods={negativeMoods} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mx={-3}>
                            <ProgrammeProvider>
                                <Crud<ProgrammeType, ProgrammeListParameters>
                                    model={classProgrammesCRUDModel}
                                    noun="Programme"
                                    getData={list}
                                    getItem={get}
                                    labelProperty="name"
                                    addComponent={CreateClassProgramme}
                                    viewComponent={ViewClassProgramme}
                                    viewOnClick
                                    addItem={addProgramme}
                                    listParams={{ includeStoryCollection: true, classroomId: classroom.id }}
                                    itemParams={{ includeStoryCollection: true }}
                                    searchable
                                    programmeFilter={true}
                                />
                            </ProgrammeProvider>
                        </Box>
                    </TabPanel>

                    <TabPanel index={1} value={tabIndex}>
                        <ClassroomStudentScreen classroomId={classroom.id} />
                    </TabPanel>

                    <TabPanel index={2} value={tabIndex}>
                        <ClassroomTeacherScreen />
                    </TabPanel>
                </>
            ) : null}
        </>
    );
};
