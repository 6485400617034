import React, { useState } from "react";
import { Box, Button, Grid, Typography, Container } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import SurveyRating from "../../core/components/SurveyRating";
import { CustomIconContainer } from "../../core/components/survey/Icons";
import SessionCompleteImg from "../../img/session-complete.svg";
import { useParticipantAuth } from "../../contexts/participant-auth.context";
import { completeReadAtHomeSurvey } from "../../client/api/public/survey";
import { useStoryStyles } from "../../hooks/styles/StoryStyles";
import { Alert } from "@material-ui/lab";

export const ReadAtHomeSurvey: React.FC = (): JSX.Element => {
    const [storyFeelingMeasure, setStoryFeelingMeasure] = useState<number>();
    const [storyQualityMeasure, setStoryQualityMeasure] = useState<number>();
    const [conversationMeasure, setConversationMeasure] = useState<number>();
    const [surveyComplete, setSurveyComplete] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(true);

    const history = useHistory();

    const { user } = useParticipantAuth();

    const { id } = useParams<{ id?: string }>();

    if (!id && !user) {
        return (
            <Grid item xs={12}>
                <Typography color="error">{`Unable to load survey`}</Typography>
            </Grid>
        );
    }

    const saveSurvey = () => {
        if (id && user && user.id) {
            if (storyFeelingMeasure && storyQualityMeasure && conversationMeasure) {
                completeReadAtHomeSurvey({
                    mappedStoryId: parseInt(id),
                    publicUserId: user.id,
                    storyFeelingMeasure,
                    storyQualityMeasure,
                    conversationMeasure,
                })
                    .then(() => {
                        setError(false);
                        setValidationError(false);
                        setSurveyComplete(true);
                    })
                    .catch(() => {
                        setError(true);
                        setSurveyComplete(true);
                    });
            } else {
                setValidationError(true);
            }
        } else {
            setError(true);
            setSurveyComplete(true);
        }
    };

    const closeStory = () => {
        history.push("/participant/dashboard");
    };

    const storyClasses = useStoryStyles();

    return (
        <Container className={storyClasses.surveyContainer}>
            <Box mb={3}>
                {!surveyComplete ? (
                    <>
                        <Box mb={3}>
                            <Typography variant="h2" color="textPrimary" align="left">
                                Survey
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Typography variant="body1" color="textPrimary" align="left">
                                Please take some time to answer these quick questions.
                            </Typography>
                        </Box>
                        <Box mb={3}>
                            <Box mb={4}>
                                <Box mb={2}>
                                    <Typography variant="h6" color="textPrimary" align="left">
                                        1. How did you like the story?
                                    </Typography>
                                </Box>
                                <SurveyRating
                                    name="story-feeling"
                                    value={storyFeelingMeasure}
                                    max={5}
                                    IconContainerComponent={(props) => (
                                        <CustomIconContainer name={"number"} {...props} />
                                    )}
                                    onChange={(_, value) => {
                                        setStoryFeelingMeasure(value ?? 1);
                                    }}
                                    minLabel={"Didn't like it"}
                                    maxLabel={"Loved it!"}
                                />
                            </Box>
                            <Box mb={4}>
                                <Box mb={2}>
                                    <Typography variant="h6" color="textPrimary" align="left">
                                        2. How do you feel after the story?
                                    </Typography>
                                </Box>
                                <SurveyRating
                                    name="story-quality"
                                    value={storyQualityMeasure}
                                    max={5}
                                    IconContainerComponent={(props) => (
                                        <CustomIconContainer name={"smiley"} {...props} />
                                    )}
                                    onChange={(_, value) => {
                                        setStoryQualityMeasure(value ?? 1);
                                    }}
                                />
                            </Box>
                            <Box mb={4}>
                                <Box mb={2}>
                                    <Typography variant="h6" color="textPrimary" align="left">
                                        3. How well did the conversation go between you and your child?
                                    </Typography>
                                </Box>
                                <SurveyRating
                                    name="conversation-measure"
                                    value={conversationMeasure}
                                    max={5}
                                    IconContainerComponent={(props) => (
                                        <CustomIconContainer name={"number"} {...props} />
                                    )}
                                    onChange={(_, value) => {
                                        setConversationMeasure(value ?? 1);
                                    }}
                                />
                            </Box>
                        </Box>
                        {validationError && (
                            <Box mb={2}>
                                <Alert severity="error">Please answer all questions before submitting the survey</Alert>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="flex-end">
                            <Button variant="contained" size="large" color="primary" onClick={saveSurvey}>
                                Save Answers
                            </Button>
                        </Box>
                    </>
                ) : (
                    <>
                        {!error ? (
                            <>
                                <Box display={"grid"} justifyContent={"center"} mb={3}>
                                    <Box width={120} textAlign={"center"}>
                                        <img src={SessionCompleteImg} />
                                    </Box>
                                </Box>
                                <Box mb={3} textAlign={"center"}>
                                    <Typography variant="h2" color="textPrimary">
                                        Survey Complete
                                    </Typography>
                                </Box>
                                <Box mb={3} textAlign={"center"}>
                                    <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                                        Thanks so much for taking part in our survey.
                                    </Typography>
                                </Box>
                            </>
                        ) : (
                            <Box mb={2}>
                                <Alert severity="error">Error saving survey</Alert>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="center">
                            <Button variant="contained" size="large" color="primary" onClick={closeStory}>
                                Exit Story
                            </Button>
                        </Box>
                    </>
                )}
            </Box>
        </Container>
    );
};
