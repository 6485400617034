import { createStyles, makeStyles, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";
import { publicTheme } from "../../components/layout/themes/public.theme";
import { Home, LibraryBooks } from "@material-ui/icons";

type SideNavProps = {
    children?: React.ReactNode;
    navIndex: number;
    changeNavIndex: (newIndex: number) => void;
};

const useStyles = makeStyles(
    createStyles({
        root: {
            borderRadius: "8px",
        },
        selected: {
            color: publicTheme.palette.primary.main,
            borderRadius: "8px",
        },
    }),
);

export const SideNav = (props: SideNavProps): JSX.Element => {
    const classes = useStyles();

    return (
        <>
            <List component="nav" className={classes.root}>
                <ListItem
                    button
                    className={props.navIndex === 0 ? classes.selected : classes.root}
                    onClick={() => props.changeNavIndex(0)}
                >
                    <ListItemIcon>
                        <Home fontSize={"large"} />
                    </ListItemIcon>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem
                    button
                    className={props.navIndex === 1 ? classes.selected : classes.root}
                    onClick={() => props.changeNavIndex(1)}
                >
                    <ListItemIcon>
                        <LibraryBooks />
                    </ListItemIcon>
                    <ListItemText primary="Collections" />
                </ListItem>
                {props.children}
            </List>
        </>
    );
};

export default SideNav;
