import { Orientation } from "@material-ui/core";
import React, { ComponentProps } from "react";

import WizardStep from "./WizardStep";

export type WizardChild = React.ReactElement<ComponentProps<typeof WizardStep>>;

type WizardProps = {
    activeStep: number;
    goToStep: (targetStep: number) => void;
    hideStepper?: boolean;
    orientation?: Orientation;

    children: WizardChild[] | WizardChild;
};

export const Wizard: React.FC<WizardProps> = (props: WizardProps) => (
    <>
        {React.Children.map(props.children, (step) => {
            if (step?.props.identifier === props.activeStep) {
                return step;
            }
        })}
    </>
);

export default Wizard;
