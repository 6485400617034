import { Organisation, Tier } from "common/build/prisma/client";
import { getData } from "./rest";
import { ListParameters } from "../../../../common/build/api-parameters/common";
import { objectToQueryString } from "../../utils/api";

export const get = async (userId: number): Promise<Organisation & { Tier: Tier }> =>
    await getData(`/organisation/by-user/${userId}`);

export const list = async (args: ListParameters): Promise<{ items: Organisation[]; total: number }> =>
    await getData(`/organisation${objectToQueryString(args)}`);
