import React from "react";
import { useProgramme } from "../../contexts/programme.context";
import { useHistory } from "react-router-dom";
import { WizardHeading } from "../../../../core/components/WizardHeading";
import ManageProgrammeFacilitators from "./ManageProgrammeFacilitators";

export const ProgrammeFacilitators: React.FC = (): JSX.Element => {
    const { submitStep, programme } = useProgramme();
    const history = useHistory();

    const onSubmit = () => {
        if (programme) {
            submitStep(programme);
            history.push(`/programmes/view/${programme.id}`);
        }
    };

    return (
        <>
            {programme && (
                <>
                    <WizardHeading overline={"Schedule a programme"} heading={"Assign Facilitators"} />
                    <ManageProgrammeFacilitators programme={programme} onSubmit={onSubmit} programmeCreate={true} />
                </>
            )}
        </>
    );
};
