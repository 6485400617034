import { TextField, FormControl, InputLabel, MenuItem, Select, FormHelperText, Grid } from "@material-ui/core";
import React from "react";
import { useOnboarding } from "../../contexts/onboarding.context";
import { Formik } from "formik";
import * as yup from "yup";
import { Organisation, User } from "common/build/prisma/client";
import { OrganisationType } from "../../entities/OnboardingOrganisationDetails";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { PrimaryButton } from "../../../../layout/Button/Button";
import { FormActions } from "../../../../../core/components/FormActions";
import { phoneNumberValidation } from "../../../../../utils/phoneNumberValidation";

export const Details: React.FC = () => {
    const { changeStep, details, submitStep } = useOnboarding();

    const { firstName, lastName, name, phoneNo, email } = details;

    const onSubmit = (values: Partial<Organisation & User>) => {
        submitStep(values);
        changeStep(1);
    };

    const initialValues: Partial<Organisation & User> = {
        firstName: firstName ?? "",
        lastName: lastName ?? "",
        name: name ?? "",
        phoneNo: phoneNo ?? "",
        email: email ?? "",
        orgType: "School",
    };

    return (
        <form>
            <WizardHeading heading={"Let's get you set up"} />
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    firstName: yup.string().required("First name is required"),
                    lastName: yup.string().required("Last name is required"),
                    name: yup.string().required("Organisation name is required"),
                    email: yup.string().email("Please enter a valid email").required("Email is required").max(100),
                    phoneNo: phoneNumberValidation,
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        autoFocus
                                        fullWidth={true}
                                        id="firstName"
                                        label="First Name"
                                        variant="outlined"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        helperText={errors.firstName && touched.firstName && errors.firstName}
                                        error={errors.firstName ? true : false}
                                        required
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth={true}
                                        id="lastName"
                                        label="Surname"
                                        variant="outlined"
                                        value={values.lastName}
                                        onChange={handleChange}
                                        helperText={errors.lastName && touched.lastName && errors.lastName}
                                        error={errors.lastName ? true : false}
                                        required
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth={true}
                                        id="name"
                                        label="Organisation Name"
                                        variant="outlined"
                                        value={values.name}
                                        disabled
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl
                                        variant="outlined"
                                        error={errors.orgType ? true : false}
                                        fullWidth={true}
                                    >
                                        <InputLabel required id="type-label">
                                            Organisation Type
                                        </InputLabel>
                                        <Select
                                            disabled
                                            label="Organisation Type"
                                            fullWidth={true}
                                            labelId="type-label"
                                            id="orgType"
                                            value={values.orgType ?? "School"}
                                            onChange={(e) => setFieldValue("orgType", e.target.value)}
                                        >
                                            <MenuItem value={""}>Please select</MenuItem>
                                            {Object.entries(OrganisationType).map(([value, label]) => {
                                                return (
                                                    <MenuItem key={value} value={value}>
                                                        {label}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                        <FormHelperText>
                                            {errors.orgType && touched.orgType && errors.orgType}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth={true}
                                        id="phoneNo"
                                        label="Phone Number"
                                        variant="outlined"
                                        value={values.phoneNo}
                                        onChange={handleChange}
                                        helperText={errors.phoneNo && touched.phoneNo && errors.phoneNo}
                                        error={errors.phoneNo ? true : false}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth={true}
                                        id="email"
                                        label="Email Address"
                                        variant="outlined"
                                        value={values.email}
                                        disabled
                                        inputProps={{
                                            maxLength: 100,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    Input fields marked with an asterisk (*) are required
                                </Grid>

                                <Grid item xs={12}>
                                    <FormActions
                                        right={
                                            <>
                                                <PrimaryButton
                                                    size="large"
                                                    disabled={isSubmitting}
                                                    onClick={(event) => {
                                                        event.preventDefault();
                                                        submitForm();
                                                    }}
                                                >
                                                    Continue
                                                </PrimaryButton>
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    );
                }}
            </Formik>
        </form>
    );
};
