import { AppBar, Avatar, IconButton, Menu, MenuItem, Toolbar } from "@material-ui/core";
import React, { useState } from "react";
import { publicTheme } from "../../components/layout/themes/public.theme";
import { useParticipantAuth } from "../../contexts/participant-auth.context";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import VerbalLogo from "../../img/VerbalLogo";
import { Link } from "react-router-dom";
import { useAvatarStyles } from "../../hooks/styles/UseAvatarStyles";

const useStyles = makeStyles(
    createStyles({
        header: {
            backgroundColor: publicTheme.palette.secondary.main,
            padding: publicTheme.spacing(2),
            minHeight: "80px",
            borderBottom: "1px solid rgba(0,0,0,0.1)",
        },
        logo: {
            paddingLeft: publicTheme.spacing(1),
        },
        toolbar: {
            padding: 0,
            display: "flex",
            justifyContent: "space-between",
        },
        title: {
            color: publicTheme.palette.common.white,
        },
        avatar: {
            backgroundColor: "red",
        },
    }),
);

type PublicHeaderProps = {
    changePage: (newIndex: number) => void;
};

export const PublicHeader = (props: PublicHeaderProps): JSX.Element => {
    const classes = useStyles();
    const avatarClasses = useAvatarStyles();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const { user, logout } = useParticipantAuth();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <AppBar position={"sticky"} className={classes.header} elevation={0}>
            <Toolbar className={classes.toolbar}>
                <Link onClick={() => props.changePage(0)} to="#" className={classes.logo}>
                    <VerbalLogo color={"white"} width={"120"} />
                </Link>
                <IconButton onClick={handleMenu}>
                    <Avatar className={avatarClasses.backgroundWhite}>
                        {user?.firstName?.charAt(0)}
                        {user?.lastName?.charAt(0)}
                    </Avatar>
                </IconButton>
            </Toolbar>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
        </AppBar>
    );
};

export default PublicHeader;
