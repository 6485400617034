import React, { FC } from "react";
import {
    VictoryLine,
    VictoryAxis,
    VictoryChart,
    VictoryVoronoiContainer,
    VictoryTooltip,
    VictoryScatter,
    VictoryGroup,
} from "victory";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { HeadlineFigureWidget } from "./HeadlineFigureWidget";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";

export const AttendanceHeadlineWidget: FC<{ isFacilitator: boolean }> = ({ isFacilitator }) => {
    const { item } = useFetch<ActiveProgramStats>();
    const thisWeekDifference = item?.twelveWeekAttendanceDifference[0].difference || 0;
    const lastWeekDifference = item?.twelveWeekAttendanceDifference[1].difference || 0;
    const difference = thisWeekDifference - lastWeekDifference;
    const isDownFromLastWeek = difference < 0 ? true : false;
    const isSmall = useMediaQuery(verbalTheme.breakpoints.down("md"));

    return (
        <HeadlineFigureWidget
            header={isFacilitator ? "Attendence at your sessions this week" : "This weeks attendance"}
        >
            <Box>
                <SpinWhileFetching>
                    <Typography variant="h2">{thisWeekDifference}%</Typography>
                    {difference !== 0 ? (
                        <Box
                            color={isDownFromLastWeek ? "error.main" : "success.main"}
                            display="flex"
                            justifyContent="center"
                        >
                            {isDownFromLastWeek ? <ArrowDownward fontSize="small" /> : <ArrowUpward fontSize="small" />}
                            <Typography variant="body2">
                                {isDownFromLastWeek ? difference * -1 : difference}% on last week
                            </Typography>
                        </Box>
                    ) : (
                        <Typography variant="body2">No change in the last week</Typography>
                    )}
                </SpinWhileFetching>
            </Box>
            <Box>
                {item?.programmes && (
                    <VictoryChart
                        containerComponent={<VictoryVoronoiContainer mouseFollowTooltips voronoiDimension="x" />}
                        width={isSmall ? 300 : 300}
                        height={isSmall ? 50 : 50}
                        domainPadding={{ x: 10 }}
                        padding={10}
                    >
                        <VictoryGroup
                            data={item?.twelveWeekAttendanceDifference.map((x) => ({
                                x: 12 - x.weeksAgo,
                                y: x.difference,
                            }))}
                            labels={({ datum }) => {
                                const weeks = 12 - datum.x;
                                const padding = "    ";
                                let label = `${weeks} weeks ago:`;
                                if (weeks === 0) {
                                    label = `This week:`;
                                } else if (weeks === 1) {
                                    label = `Last week`;
                                }
                                return `${padding} ${label} \n ${datum.y}%`;
                            }}
                            labelComponent={
                                <VictoryTooltip
                                    cornerRadius={() => 4}
                                    flyoutPadding={4}
                                    flyoutStyle={{
                                        fill: "white",
                                        stroke: "#e3e3e3",
                                        fontSize: 4,
                                    }}
                                    style={{ fontSize: isSmall ? "10" : "10" }}
                                    center={{ x: 100, y: 100 }}
                                    constrainToVisibleArea
                                />
                            }
                            color="#75C5FA"
                        >
                            <VictoryLine
                                sortKey={"x"}
                                sortOrder={"descending"}
                                style={{
                                    data: {
                                        strokeWidth: 2,
                                    },
                                }}
                            />
                            <VictoryScatter
                                sortKey={"x"}
                                sortOrder={"descending"}
                                style={{
                                    data: {
                                        fill: () => "#FFFFFF",
                                        stroke: () => "#75C5FA",
                                        strokeWidth: 2,
                                    },
                                }}
                            />
                        </VictoryGroup>
                        <VictoryAxis
                            style={{
                                axis: { stroke: "transparent" },
                                ticks: { stroke: "transparent" },
                                tickLabels: { fill: "transparent" },
                            }}
                        />
                    </VictoryChart>
                )}
            </Box>
        </HeadlineFigureWidget>
    );
};
