import { node } from "prop-types";
import React, { ReactNode, useState } from "react";
import { v4 as uuidv4 } from "uuid";

type AlertSeverity = "success" | "error" | "warning" | "info";

export type Alert = {
    id: string;
    msg: string;
    severity: AlertSeverity;
    timeout?: number;
};

interface AlertContextI {
    alert?: Alert;

    setAlert: (msg: string, severity: AlertSeverity, timeout?: number) => void;
}

export const AlertContext = React.createContext({} as AlertContextI);

const useAlertContext = () => {
    const [alert, setNewAlert] = useState<Alert>();

    const setAlert = (msg: string, severity: AlertSeverity, timeout = 3000) => {
        const newAlert = {
            id: uuidv4(),
            msg,
            severity,
            timeout,
        };

        setNewAlert(newAlert);

        setTimeout(() => setNewAlert(undefined), timeout);
    };
    return {
        alert,
        setAlert,
    };
};

export const AlertProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const value = useAlertContext();
    return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};

AlertProvider.propTypes = {
    children: node,
};

export const useAlert = (): AlertContextI => React.useContext(AlertContext);
