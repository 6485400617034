import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import { Box, Paper, PaperProps } from "@material-ui/core";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";
import { publicTheme } from "../../components/layout/themes/public.theme";

export enum VModuleType {
    Shade1 = "Shade1",
    Shade2 = "Shade2",
    Shade3 = "Shade3",
}

export type VModuleProps = PaperProps & {
    type?: VModuleType;
    children: React.ReactNode;
    padded?: boolean;
    className?: string;
    shadow?: boolean;
};

const LightTheme: React.FC = ({ children }) => <ThemeProvider theme={verbalTheme}>{children}</ThemeProvider>;
const DarkTheme: React.FC = ({ children }) => <ThemeProvider theme={publicTheme}>{children}</ThemeProvider>;
const DarkishTheme: React.FC = ({ children }) => (
    <ThemeProvider
        theme={{
            ...publicTheme,
            palette: {
                ...publicTheme.palette,
                background: { ...publicTheme.palette.background, paper: publicTheme.palette.secondary.light },
            },
        }}
    >
        {children}
    </ThemeProvider>
);
const LightishTheme: React.FC = ({ children }) => (
    <ThemeProvider
        theme={{
            ...verbalTheme,
            palette: {
                ...verbalTheme.palette,
                background: { ...verbalTheme.palette.background, paper: verbalTheme.palette.secondaryShade.main },
            },
        }}
    >
        {children}
    </ThemeProvider>
);

export const VModule: React.FC<VModuleProps> = (props: VModuleProps) => {
    const { type, children, padded = true, shadow = false, ...rest } = props;

    let ThemeComponent;
    switch (type) {
        // Light
        case VModuleType.Shade1:
            ThemeComponent = LightishTheme;
            break;
        // darkish
        case VModuleType.Shade2:
            ThemeComponent = DarkishTheme;
            break;
        /// v dark
        case VModuleType.Shade3:
            ThemeComponent = DarkTheme;
            break;
        default:
            ThemeComponent = LightTheme;
            break;
    }

    return (
        <ThemeComponent>
            <Paper elevation={shadow ? 1 : 0} {...rest}>
                <Box p={padded ? 3 : 0}>{children}</Box>
            </Paper>
        </ThemeComponent>
    );
};
