import * as React from "react";
import { Typography, Dialog, IconButton, Button, createStyles, makeStyles, Box } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import VerbalLogo from "../../../img/VerbalLogo";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { useHistory, useLocation } from "react-router-dom";

const SubscriptionSuccess: React.FC = () => {
    const [modalOpen, modalOpenSet] = React.useState(true);
    const { addSchoolOrganisation } = useOrganisation();
    const history = useHistory();
    const { search } = useLocation();
    const subscriptionId = new URLSearchParams(search).get("subscription_id");

    React.useEffect(() => {
        const completeOnboarding = async () => {
            await addSchoolOrganisation({
                subscriptionId: subscriptionId,
            });
            modalOpenSet(true);
        };

        completeOnboarding();
    }, []);

    const closeModal = () => {
        modalOpenSet(false);
        history.push("/home");
    };

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                background: verbalTheme.palette.success.main,
                color: verbalTheme.palette.common.white,
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            {modalOpen && (
                <Dialog onClose={closeModal} open={modalOpen}>
                    <Box mb={4} padding={2}>
                        <VerbalLogo color={"white"} width={"175px"} />
                    </Box>
                    <MuiDialogTitle>
                        <Typography variant="h4" component="h4">
                            Thank you for your subscription.
                        </Typography>
                        <IconButton className={classes.closeButton} aria-label="close" onClick={closeModal}>
                            <CloseIcon />
                        </IconButton>
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <Button variant="contained" type="submit" size="large" color="primary" onClick={closeModal}>
                            Login
                        </Button>
                    </MuiDialogContent>
                </Dialog>
            )}
        </>
    );
};

export default SubscriptionSuccess;
