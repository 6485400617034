import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Hidden,
    IconButton,
    MobileStepper,
    TextField,
    Typography,
} from "@material-ui/core";
import { RoleName, User } from "common/build/prisma/client";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { NewUserCard } from "./components/NewUserCard";
import * as yup from "yup";
import { RoleType, UserType } from "../../models/modelTypes";
import { RoleType as RoleTypeName } from "./entities/RoleType";
import { add } from "../../client/api/users";
import { list } from "../../client/api/role";
import { useAlert } from "../../contexts/alert.context";
import { useAuth } from "../../contexts/auth.context";
import SwipeableViews from "react-swipeable-views";
import { useMobile } from "../../hooks/useMobile";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { useSetTourState } from "../tour/Store";

export const AddUserScreen = (): JSX.Element => {
    const { setAlert } = useAlert();
    const { user, hasPermission } = useAuth();
    const { isTablet, isMobile } = useMobile();

    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [roles, setRoles] = useState<RoleType[]>([]);
    const [roleName, setRoleName] = useState<RoleName>();
    const [displayName, setDisplayName] = useState<string>("");
    const [swipeStep, setSwipeStep] = useState<number>(0);

    const setTourState = useSetTourState();

    const refSaveBtn = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("saveUserBackBtn", refSaveBtn) }));
    }, [refSaveBtn]);

    useEffect(() => {
        list().then((values) => {
            if (values.items) {
                setRoles(values.items);
            }
        });
    }, []);

    const onSaveClicked = (roleName: RoleName) => {
        setRoleName(roleName);
        setDisplayName(
            user?.isSchool && roleName === "ProgrammeCoordinator"
                ? "Teaching Staff"
                : (Object.values(RoleTypeName)[Object.keys(RoleTypeName).indexOf(roleName)] as string),
        );
        setShowAdd(true);
    };

    const nameInputRef = useRef<HTMLInputElement>();

    const onSubmit = (values: Partial<UserType>, formikHelpers: FormikHelpers<Partial<UserType>>) => {
        const { setSubmitting } = formikHelpers;
        const newUser = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            roleId: roles.filter((r) => r.name === roleName)?.[0]?.id,
        } as User;
        add(newUser)
            .then((response) => {
                if (response.ok) {
                    setAlert(
                        `User, ${values.firstName} ${values.lastName}, successfully created. They will receive an email to set up their account`,
                        "success",
                        4000,
                    );
                } else {
                    setSubmitting(false);
                    setAlert("Email address already in use.", "error");
                }
            })
            .finally(() => {
                formikHelpers.resetForm();
                //nameInputRef.current?.focus();
            })
            .catch(() => setAlert("An error occurred. User was not created. Please try again", "error"));
    };

    return (
        <Box display={"flex"} height={1} p={3} flexDirection={"column"} maxWidth={"100vw"}>
            {!isTablet && isMobile && <div style={{ paddingTop: "64px" }} />}
            <Box mb={3}>
                <Typography variant="h3">Select the user type and fill in the users&apos; details below</Typography>
            </Box>
            <Box mb={3}>
                <Hidden only={["xs"]}>
                    <Grid container spacing={2} data-tour="add-users-all">
                        {roles && roles.length && roles.length > 0
                            ? roles
                                  .filter((role) => !role.name.includes("Story"))
                                  .sort((a, b) => {
                                      const order = { Facilitator: 1, Admin: 3, ProgrammeCoordinator: 2 };
                                      return order[a.name as keyof typeof order] - order[b.name as keyof typeof order];
                                  })
                                  .map((role, index) => {
                                      const userHasPermission = role.RolePermissions?.filter((perm) =>
                                          hasPermission(perm.permission),
                                      );
                                      if (role.name === "ProgrammeCoordinator" && role.name === user?.Role.name) {
                                          return null;
                                      }
                                      if (role.name && userHasPermission?.length > 0) {
                                          return (
                                              <Grid
                                                  data-tour={index === 1 ? "add-users-permissions" : undefined}
                                                  item
                                                  key={`xs_role_${role.name}`}
                                                  xs={12}
                                                  sm={6}
                                                  md={4}
                                              >
                                                  <NewUserCard
                                                      index={index}
                                                      onAdd={onSaveClicked}
                                                      roleName={role.name}
                                                      isSelected={(roleName as string) === role.name}
                                                  />
                                              </Grid>
                                          );
                                      }
                                  })
                            : null}
                    </Grid>
                </Hidden>
                <Hidden only={["lg", "xl", "md", "sm"]}>
                    <SwipeableViews
                        index={swipeStep}
                        onChangeIndex={(step: number) => setSwipeStep(step)}
                        //enableMouseEvents
                        style={{ paddingRight: verbalTheme.spacing(2) }}
                    >
                        {roles && roles.length && roles.length > 0
                            ? roles
                                  .filter((role) => !role.name.includes("Story"))
                                  .sort((a, b) => {
                                      const order = { Facilitator: 1, Admin: 3, ProgrammeCoordinator: 2 };
                                      return order[a.name as keyof typeof order] - order[b.name as keyof typeof order];
                                  })
                                  .map((role) => {
                                      const userHasPermission = role.RolePermissions?.filter((perm) =>
                                          hasPermission(perm.permission),
                                      );
                                      if (role.name === "ProgrammeCoordinator" && role.name === user?.Role.name) {
                                          return null;
                                      }
                                      if (role.name && userHasPermission?.length > 0) {
                                          return (
                                              <NewUserCard
                                                  key={`card_role_${role.name}`}
                                                  onAdd={onSaveClicked}
                                                  roleName={role.name}
                                                  isSelected={(roleName as string) === role.name}
                                              />
                                          );
                                      }
                                  })
                            : null}
                    </SwipeableViews>
                    <MobileStepper
                        steps={roles.filter((role) => !role.name.includes("Story")).length}
                        position="static"
                        activeStep={swipeStep}
                        nextButton={
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={() => setSwipeStep((prev) => prev + 1)}
                                disabled={swipeStep === roles.filter((role) => !role.name.includes("Story")).length - 1}
                            >
                                <KeyboardArrowRight fontSize="large" />
                            </IconButton>
                        }
                        backButton={
                            <IconButton
                                color="primary"
                                size="small"
                                onClick={() => setSwipeStep((prev) => prev - 1)}
                                disabled={swipeStep === 0}
                            >
                                <KeyboardArrowLeft fontSize="large" />
                            </IconButton>
                        }
                    />
                </Hidden>
            </Box>
            <Dialog open={showAdd}>
                <DialogTitle>{`Add new ${displayName} account`}</DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{ email: "", firstName: "", lastName: "" }}
                        validationSchema={yup.object({
                            firstName: yup.string().required("First Name is required"),
                            lastName: yup.string().required("Surname is required"),
                            email: yup
                                .string()
                                .email("A valid email is required")
                                .required("Email is required")
                                .max(100),
                        })}
                        validateOnChange={false}
                        onSubmit={onSubmit}
                    >
                        {({ handleSubmit, resetForm, handleChange, isSubmitting, errors, touched, values }) => {
                            return (
                                <Form onSubmit={handleSubmit}>
                                    <Grid container spacing={2} alignItems="flex-start">
                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                data-tour="add-users-name"
                                                required
                                                name="firstName"
                                                variant="outlined"
                                                label="First Name"
                                                value={values.firstName}
                                                fullWidth
                                                onChange={handleChange}
                                                helperText={errors.firstName && touched.firstName && errors.firstName}
                                                error={!!errors.firstName}
                                                inputRef={nameInputRef}
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                data-tour="add-users-surname"
                                                required
                                                name="lastName"
                                                variant="outlined"
                                                label="Surname"
                                                value={values.lastName}
                                                fullWidth
                                                onChange={handleChange}
                                                helperText={errors.lastName && touched.lastName && errors.lastName}
                                                error={!!errors.lastName}
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                required
                                                data-tour="add-users-email"
                                                name="email"
                                                variant="outlined"
                                                label="Email"
                                                value={values.email}
                                                fullWidth
                                                onChange={handleChange}
                                                helperText={errors.email && touched.email && errors.email}
                                                error={!!errors.email}
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Grid>

                                        <Grid item sm={6} xs={12}>
                                            <TextField
                                                data-tour="add-users-role"
                                                name="role"
                                                variant="outlined"
                                                label="Role"
                                                value={displayName}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>

                                        <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                            Input fields marked with an asterisk (*) are required
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-between">
                                                <Grid item>
                                                    <Button
                                                        ref={refSaveBtn}
                                                        data-tour="add-users-back-btn"
                                                        variant="contained"
                                                        size="large"
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            setShowAdd(false);
                                                            setRoleName(undefined);
                                                            resetForm();
                                                        }}
                                                    >
                                                        Back
                                                    </Button>
                                                </Grid>
                                                <Grid item>
                                                    <Button
                                                        data-tour="add-users-add-btn"
                                                        type="submit"
                                                        variant="contained"
                                                        size="large"
                                                        color="primary"
                                                        disabled={isSubmitting}
                                                    >
                                                        {!isSubmitting ? "Add" : <CircularProgress size={30} />}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContent>
            </Dialog>
        </Box>
    );
};
