import * as yup from "yup";
import { disconnectRow, email } from "../../../core/components/crud/decorators";
import { phoneNumberValidation } from "../../../utils/phoneNumberValidation";
import { ClassroomType, ParticipantType } from "../../../models/modelTypes";
import { Participant } from "common/build/prisma/client";
import { Model } from "../../../core/components/crud/Crud";

export const createClassroomStudentsCRUDModel: (c?: ClassroomType) => Model<Participant> = () => {
    return {
        name: {
            label: "Name",
            inTable: true,
            validation: yup.string().required("Name is Required").max(100),
        },
        email: {
            label: "Email",
            validation: yup.string().email("A valid email is required").optional().max(100),
            decorator: (value: unknown): JSX.Element => email(value as string),
        },
        phoneNumber: {
            label: "Phone Number",
            validation: phoneNumberValidation.optional(),
        },
        id: {
            label: " ",
            inTable: true,
            inForm: false,
            decorator: (value: number, property: string, participant: ParticipantType): JSX.Element =>
                disconnectRow<ParticipantType>(participant, "ParticipantToClassroom"),
        },
    };
};
