import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";

export const useUtilityStyles = makeStyles(
    createStyles({
        floatRight: {
            float: "right",
        },
        floatLeft: {
            float: "left",
        },
        clearFloats: {
            overflow: "auto",
        },
        textRight: {
            textAlign: "right",
        },
        textLeft: {
            textAlign: "left",
        },
        bold: {
            fontWeight: 700,
        },
        height100: {
            height: "100%",
        },
        hideOnMobile: {
            [verbalTheme.breakpoints.down("md")]: {
                display: "none",
            },
        },
        hideOnDesktop: {
            [verbalTheme.breakpoints.up("md")]: {
                display: "none",
            },
        },
    }),
);
