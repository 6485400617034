import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { Box, Container, Typography, useMediaQuery } from "@material-ui/core";
import { publicTheme } from "../layout/themes/public.theme";
import { Navigation } from "./Navigation";
import { ListProvider } from "../../core/components/crud/contexts/list.context";
import { list } from "../../client/api/public/participant";
import { listCollections } from "../../client/api/public/collection";
import { ParticipantHome } from "../../components/participant/ParticipantHome";
import PublicHeader from "../../core/components/PublicHeader";
import { useParticipantAuth } from "../../contexts/participant-auth.context";
import Grid from "@material-ui/core/Grid";
import SideNav from "../../core/components/SideNav";
import ParticipantCollections from "./ParticipantCollections";
import SurveyDialog from "./surveyDialog/SurveyDialog";
import { SurveyDialogProvider } from "../public/hooks/surveyDialog.context";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { CollectionListParameters } from "../../../../common/src/api-parameters/collections";

const useStyles = makeStyles(
    createStyles({
        root: {
            backgroundColor: publicTheme.palette.secondary.main,
            color: "white",
        },
        content: {
            paddingTop: publicTheme.spacing(0),
            [publicTheme.breakpoints.up("md")]: {
                paddingTop: publicTheme.spacing(4),
            },
        },
        sidebarNav: {
            display: "none",
            [publicTheme.breakpoints.up("md")]: {
                display: "block",
            },
        },
        bottomNavBar: {
            [publicTheme.breakpoints.up("md")]: {
                display: "none",
            },
        },
        mobileContainer: {
            padding: publicTheme.spacing(0),
            paddingBottom: publicTheme.spacing(10),
            [publicTheme.breakpoints.up("md")]: {
                padding: publicTheme.spacing(3),
                paddingBottom: publicTheme.spacing(1),
            },
        },
    }),
);
const collectionParams = { includeMappedStory: true } as CollectionListParameters;
export const ParticipantDashboard = (): JSX.Element => {
    const classes = useStyles();
    const [navIndex, setNavIndex] = useState<number>(0);
    const { user } = useParticipantAuth();

    if (!user?.id) {
        return <Typography color="error">{`Unable to load home`}</Typography>;
    }
    const isSmall = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    return (
        <div className={classes.root}>
            <PublicHeader changePage={setNavIndex} />
            <Container maxWidth={"lg"} className={classes.mobileContainer}>
                <Box className={classes.content}>
                    <Grid container spacing={isSmall ? 0 : 2}>
                        <Grid item xs={3} className={classes.sidebarNav}>
                            <SideNav navIndex={navIndex} changeNavIndex={setNavIndex} />
                        </Grid>

                        <Grid item xs={12} md={9}>
                            <ListProvider plural="participants" getData={list}>
                                <SurveyDialogProvider>
                                    <SurveyDialog />
                                    {navIndex === 0 && <ParticipantHome />}
                                </SurveyDialogProvider>
                            </ListProvider>
                            <Box pl={3} pr={3}>
                                <ListProvider
                                    plural="collections"
                                    includes={collectionParams}
                                    getData={listCollections}
                                >
                                    {navIndex === 1 && <ParticipantCollections />}
                                </ListProvider>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <div className={classes.bottomNavBar}>
                <Navigation navIndex={navIndex} changeNavIndex={setNavIndex} />
            </div>
        </div>
    );
};
export default ParticipantDashboard;
