import {
    Grid,
    Box,
    Typography,
    DialogContentText,
    Divider,
    IconButton,
    Card,
    CardContent,
    Button,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { format } from "date-fns";
import React, { useState } from "react";
import ConfirmDialog from "../../../core/components/ConfirmDialog";
import { SessionType } from "../../../models/modelTypes";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { sortSessions } from "../../../utils/sorts";
import { useAuth } from "../../../contexts/auth.context";
import { Check, Create } from "@material-ui/icons";

interface AllSessionsGridProps {
    sessions: SessionType[];
    excludeNextSession?: boolean;
}

export const AllSessionsGrid: React.FC<AllSessionsGridProps> = (props: AllSessionsGridProps) => {
    const { hasPermission, user } = useAuth();
    const [showPermissions, setshowPermissions] = useState<boolean>(false);
    const [selectedSession, setselectedSession] = useState<SessionType>();
    const { sessions, excludeNextSession } = props;
    const orderedSessions = [...sessions].sort(sortSessions);
    const history = useHistory();

    if (excludeNextSession) {
        const idx = orderedSessions.findIndex((p) => p.state === "InProgress" || p.state === "NotStarted");
        delete orderedSessions[idx];
    }

    const useStyles = makeStyles(() =>
        createStyles({
            sessionGridItem: {
                flex: "1 0 50%",
                [verbalTheme.breakpoints.up("md")]: {
                    maxWidth: "250px",
                    flex: "1 0 16%",
                },
            },
            iconButton: {
                padding: "0px",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            {showPermissions && (
                <ConfirmDialog
                    open={showPermissions}
                    title={`Deliver Session?`}
                    onConfirm={() => history.push(`/start-session/${selectedSession?.id}`)}
                    onCancel={() => {
                        setshowPermissions(false);
                        setselectedSession(undefined);
                    }}
                >
                    <DialogContentText>{`Delviering a session will attempt to access your camera and microphone. 
                        Please accept when your web browser asks permission to use your webcam and microphone.`}</DialogContentText>
                </ConfirmDialog>
            )}
            <Box mb={2}>
                <Typography variant="h5" color="textSecondary" gutterBottom>
                    All Sessions
                </Typography>
            </Box>
            <Grid container spacing={3}>
                {/** Ajusted height of cards */}
                {orderedSessions.map((session) => {
                    const url =
                        user?.Organisation?.country === "Republic of Ireland"
                            ? session.MappedStory?.Story?.resourcesROI
                            : session.MappedStory?.Story?.resources;
                    return (
                        <Grid item xs className={classes.sessionGridItem} key={session.id}>
                            <Card style={{ height: "100%" }}>
                                <CardContent style={{ height: "100%" }}>
                                    <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        flexDirection="column"
                                        minHeight="100%"
                                    >
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="h6" color={"textSecondary"} gutterBottom>
                                                Session {session.sessionNumber}
                                            </Typography>
                                            {session.state === "Complete" && <Check color="primary" fontSize="small" />}
                                            {session.state !== "Complete" && hasPermission("SessionReschedule") && (
                                                <IconButton
                                                    className={classes.iconButton}
                                                    onClick={() => history.push(`/sessions/edit/${session.id}`)}
                                                >
                                                    <Create color="action" fontSize="small" />
                                                </IconButton>
                                            )}
                                        </Box>

                                        <Box mb={2}>
                                            <Typography variant="h6">
                                                {session.MappedStory?.Story?.storyName}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                By {session.MappedStory?.Story?.author}
                                            </Typography>
                                        </Box>

                                        <Box mb={2}>
                                            <Divider light />
                                        </Box>

                                        <Box mb={2}>
                                            <Typography variant="body1">
                                                {format(Date.parse(session.date.toLocaleString()), "EE")}
                                            </Typography>
                                            <Typography variant="h4">
                                                {format(Date.parse(session.date.toLocaleString()), "d MMM")}
                                            </Typography>
                                            <Typography variant="body2">
                                                {format(Date.parse(session.date.toLocaleString()), "YYY")} at{" "}
                                                {format(Date.parse(session.date.toLocaleString()), "HH:mm")}
                                            </Typography>
                                        </Box>

                                        {session.state === "Complete" && url ? (
                                            <Box mb={1} textAlign="center">
                                                <Button
                                                    fullWidth
                                                    color="secondary"
                                                    variant="outlined"
                                                    href={url}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    Learning Resources
                                                </Button>
                                            </Box>
                                        ) : null}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}{" "}
            </Grid>
        </>
    );
};
