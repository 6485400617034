import { Box, Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useOnboarding } from "../../contexts/onboarding.context";
import { PrimaryButton, SecondaryButton } from "../../../../layout/Button/Button";
import { FormActions } from "../../../../../core/components/FormActions";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { OrganisationType } from "../../entities/OnboardingOrganisationDetails";
import { useOrganisation } from "../../../../../hooks/useOrganisation";
import { useMobile } from "../../../../../hooks/useMobile";
import { useAuth } from "../../../../../contexts/auth.context";
import { useHistory } from "react-router-dom";
import { Alert } from "@material-ui/lab";

export const Confirmation: React.FC = () => {
    const { details, changeStep, confirmStep } = useOnboarding();
    const { addNewOrganisation } = useOrganisation();

    const { isAuthenticated } = useAuth();
    const { isTablet, isMobile } = useMobile();

    useEffect(() => {
        if (isAuthenticated) {
            if (!isTablet && isMobile) {
                history.push("/detect-mobile");
            } else {
                history.push("/home");
            }
        }
    }, [isAuthenticated]);

    const [error, setError] = useState<string>();

    const history = useHistory();

    const { firstName, lastName, addressLine1, addressLine2, city, postcode, country, name, phoneNo, email } = details;

    const handleConfirm = async () => {
        if (details && details.orgType === OrganisationType.School) {
            confirmStep();
            changeStep(3);
        } else {
            try {
                await addNewOrganisation(details);
                setError(undefined);
            } catch (error) {
                setError(error as string);
            }
        }
    };

    return (
        <>
            <WizardHeading overline={"Let's get you set up"} heading={"Confirm details"} />
            <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={6}>
                    <Box mb="1rem">
                        <Typography variant="subtitle1">Name</Typography>
                        <Typography variant="body1">{`${firstName} ${lastName}`}</Typography>
                    </Box>
                    <Box mb="1rem">
                        <Typography variant="subtitle1">Organisation</Typography>
                        <Typography variant="body1">{name}</Typography>
                    </Box>
                    {phoneNo && (
                        <Box mb="1rem">
                            <Typography variant="subtitle1">Phone Number</Typography>
                            <Typography variant="body1">{phoneNo}</Typography>
                        </Box>
                    )}
                    <Box mb="1rem">
                        <Typography variant="subtitle1">Email</Typography>
                        <Typography variant="body1">{email}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1">Address</Typography>
                    <Typography variant="body1">{`${addressLine1},`}</Typography>
                    <Typography variant="body1">{`${addressLine2 !== "" ? addressLine2 + "," : ""}`}</Typography>
                    <Typography variant="body1">{`${city},`}</Typography>
                    <Typography variant="body1">{`${postcode !== "" ? postcode + "," : ""}`}</Typography>
                    <Typography variant="body1">{`${country}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormActions
                        left={
                            <>
                                <SecondaryButton size="large" onClick={() => changeStep(1)}>
                                    Back
                                </SecondaryButton>
                            </>
                        }
                        right={
                            <>
                                <PrimaryButton size="large" onClick={() => handleConfirm()}>
                                    {"Confirm & Continue"}
                                </PrimaryButton>
                            </>
                        }
                    />
                </Grid>
            </Grid>
            <Box mt={4}>{error && <Alert severity="error">{error}</Alert>}</Box>
        </>
    );
};
