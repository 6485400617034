import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { Box, createStyles, makeStyles } from "@material-ui/core";
import { StoryCollectionType } from "../../../../models/modelTypes";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { ThemePill } from "../../../session/components/ThemePill";
import { Close } from "@material-ui/icons";

export interface DialogTitleProps {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}

const useStyles = makeStyles(
    createStyles({
        closeButton: {
            position: "absolute",
            right: verbalTheme.spacing(1.5),
            top: verbalTheme.spacing(1.5),
            color: verbalTheme.palette.grey[500],
        },
    }),
);

export const DialogTitle = (props: DialogTitleProps): JSX.Element => {
    const { children, onClose, ...other } = props;
    const classes = useStyles();
    return (
        <MuiDialogTitle disableTypography {...other}>
            <Typography variant="h4" component="h3">
                {children}
            </Typography>
            {onClose ? (
                <IconButton className={classes.closeButton} aria-label="close" onClick={onClose}>
                    <Close />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

type PathwayModalProps = {
    next: (newStep: number, ageRange: string) => void;
    storyCollection: StoryCollectionType;
    open: boolean;
    setOpen: (open: boolean) => void;
};

const PathwayModal: React.FC<PathwayModalProps> = (props: PathwayModalProps): JSX.Element => {
    return (
        <div>
            <Dialog onClose={() => props.setOpen(false)} aria-labelledby="customized-dialog-title" open={props.open}>
                <DialogTitle id="customized-dialog-title" onClose={() => props.setOpen(false)}>
                    {props.storyCollection.name}
                </DialogTitle>
                <MuiDialogContent>
                    <Box mb={2}>
                        {props.storyCollection.Theme?.map((theme, index) => {
                            return (
                                <Box component={"span"} mb={2} key={index} mr={1}>
                                    <ThemePill key={index} label={theme.name} content={theme.description || ""} />
                                </Box>
                            );
                        })}
                    </Box>
                    <Typography variant="body2" color="textPrimary">
                        {props.storyCollection.description}
                    </Typography>
                </MuiDialogContent>
                <MuiDialogActions>
                    <Button variant="contained" onClick={() => props.setOpen(false)}>
                        Close
                    </Button>
                    <Button
                        autoFocus
                        onClick={() => props.next(props.storyCollection.id, props.storyCollection.ageRange)}
                        variant="contained"
                        color="primary"
                    >
                        Select this collection
                    </Button>
                </MuiDialogActions>
            </Dialog>
        </div>
    );
};

export default PathwayModal;
