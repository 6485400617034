import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ErrorBoundary from "../src/components/error/ErrorBoundary";
import { ThemeProvider } from "@material-ui/core";
import { verbalTheme } from "../src/components/layout/themes/verbal.theme";
import CssBaseline from "@material-ui/core/CssBaseline";

BigInt.prototype.toJSON = function () {
    return this.toString();
};

ReactDOM.render(
    <ThemeProvider theme={verbalTheme}>
        <CssBaseline />
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </ThemeProvider>,
    document.getElementById("root"),
);
