import { useCallback, useState } from "react";
import { get } from "../client/api/collection";
import { getAll } from "../client/api/public/collection";
import { StoryCollectionType } from "../models/modelTypes";

interface CollectionI {
    collections: StoryCollectionType[];
    collection?: StoryCollectionType;

    loading: boolean;

    getAllCollections: () => Promise<void>;
    getCollection: (id: number) => Promise<void>;
}

/**
 * Custom hook to get and list collections.
 */
export const useCollection = (): CollectionI => {
    const [collections, setCollections] = useState<StoryCollectionType[]>([]);
    const [collection, setCollection] = useState<StoryCollectionType>();

    const [loading, setLoading] = useState<boolean>(false);

    const getAllCollections = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            getAll()
                .then(async (collections: StoryCollectionType[]) => {
                    setCollections(collections);
                    resolve();
                })
                .catch((error) => {
                    setCollections([]);
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const getCollection = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            get(id, { includeMappedStory: true, includeThemes: true, includeCurriculum: true, includeTopics: true })
                .then(async (collection: StoryCollectionType) => {
                    setCollection(collection);
                    resolve();
                })
                .catch((error) => {
                    setCollection(undefined);
                    reject(error.message);
                })
                .finally(() => setLoading(false));
        });
    }, []);

    return {
        collections: collections,
        collection,
        loading,
        getAllCollections,
        getCollection,
    };
};
