import { createStyles, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { SecondaryButton } from "../../../../layout/Button/Button";
import { verbalTheme } from "../../../../layout/themes/verbal.theme";
import { HeadlineFigureWidget } from "../HeadlineFigureWidget";

export const ClassroomWidget: FC<{ minHeight?: string }> = ({ minHeight }): JSX.Element => {
    const history = useHistory();

    const useStyles = makeStyles(
        createStyles({
            imgPlacment: {
                height: "75%",
                width: "75%",
                margin: "auto",
            },
        }),
    );
    const classes = useStyles();

    return (
        <HeadlineFigureWidget minHeight={minHeight}>
            <div className={classes.imgPlacment}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="75%"
                    width="75%"
                    viewBox="0 0 42 42"
                    fill={verbalTheme.palette.primary.main}
                >
                    <path d="m23.95 42-14.5-7.95v-12L2 18 23.95 6 46 18v15.85h-3v-14.1l-4.55 2.3v12Zm0-15.4L39.7 18 23.95 9.55 8.3 18Zm0 12 11.5-6.35v-8.4L23.95 30l-11.5-6.25v8.5Zm.05-12Zm-.05 3.7Zm0 0Z" />
                </svg>
            </div>
            <SecondaryButton onClick={() => history.push("/classrooms")} size="small" variant="contained" fullWidth>
                Classrooms
            </SecondaryButton>
        </HeadlineFigureWidget>
    );
};
