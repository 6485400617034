import React from "react";
import { Box } from "@material-ui/core";

type FormActionsProps = {
    left?: React.ReactElement;
    right?: React.ReactElement;
};

export const FormActions = (props: FormActionsProps): JSX.Element => {
    const { left, right } = props;

    return (
        <>
            <Box display={"grid"} gridTemplateColumns={"1fr 1fr"}>
                <Box justifySelf={"start"}>{left}</Box>
                <Box justifySelf={"end"}>{right}</Box>
            </Box>
        </>
    );
};
