import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import { Box, Button, ClickAwayListener, Paper, Typography } from "@material-ui/core";

interface SimplePopperProps {
    label: string;
    content: string;
}

const useStyles = makeStyles((verbalTheme) => ({
    paper: {
        border: "1px solid rgba(0,0,0,0.1)",
    },
    typography: {
        padding: verbalTheme.spacing(2),
    },
    popper: {
        maxWidth: "50%",
    },
}));

export const SimplePopper: React.FC<SimplePopperProps> = (props: SimplePopperProps) => {
    const { label, content } = props;
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
    const clickAwayHandler = () => setIsOpen(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Box mr={1}>
                <Button variant="contained" color="secondary" size="small" type="button" onClick={handleClick}>
                    {label}
                </Button>
            </Box>
            {isOpen && (
                <ClickAwayListener onClickAway={clickAwayHandler}>
                    <Popper open={isOpen} anchorEl={anchorEl} className={classes.popper}>
                        <Paper className={classes.paper}>
                            <Typography variant="body2" className={classes.typography}>
                                {content}
                            </Typography>
                        </Paper>
                    </Popper>
                </ClickAwayListener>
            )}
        </>
    );
};
