import React, { ComponentProps } from "react";
import Wizard from "./Wizard";
import { VerticalWizard } from "./VerticalWizard";
import { HorizontalWizard } from "./HorizontalWizard";

type StepperWizardProps = ComponentProps<typeof Wizard>;

export const StepperWizard: React.FC<StepperWizardProps> = (props: StepperWizardProps) => {
    const { orientation } = props;

    if (orientation === "horizontal") {
        return <HorizontalWizard {...props} />;
    } else {
        return <VerticalWizard {...props} />;
    }
};
