import React, { FC } from "react";
import { Avatar, Box, Drawer, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { date } from "../../../../utils/date";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { VTextLink } from "../../../common/VTextLink";
import { CalendarToday } from "@material-ui/icons";
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        icon: {
            width: verbalTheme.spacing(10),
            height: verbalTheme.spacing(10),
            alignSelf: "center",
            color: "#fff",
            backgroundColor: "#1FAF8C",
        },
        toolbar: theme.mixins.toolbar,
    }),
);

export const UpcomingSessionsDrawer: FC<{ isFacilitator?: boolean }> = ({ isFacilitator }) => {
    const classes = useStyles();
    const { item } = useFetch<ActiveProgramStats>();

    const hasNoActiveSessions = item?.sessions.length === 0;

    return (
        <Drawer
            className={clsx(classes.drawer, "upcomingSessionsContainer")}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor="right"
        >
            {hasNoActiveSessions ? (
                <Box p={2} textAlign="center">
                    <Box justifyContent="center" display="flex" mt={5} mb={2}>
                        <Avatar className={classes.icon}>
                            <CalendarToday fontSize="large" />
                        </Avatar>
                    </Box>
                    <Box mb={2}>
                        <Typography variant={"h5"} gutterBottom>
                            No Upcoming Sessions
                        </Typography>
                    </Box>
                    <Typography>
                        There are currently 0 upcoming sessions scheduled,{" "}
                        {isFacilitator ? (
                            "schedule a programme "
                        ) : (
                            <VTextLink to="/programmes/add">schedule a programme </VTextLink>
                        )}
                        to schedule new sessions.
                    </Typography>
                </Box>
            ) : (
                <>
                    <Box p={2} pb={0}>
                        <Typography variant="h5">Upcoming Sessions</Typography>
                    </Box>
                    <List>
                        {item?.sessions.slice(0, 6).map((session) => (
                            <ListItem key={session.id} alignItems="flex-start" divider>
                                <ListItemText
                                    primary={
                                        <>
                                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                                {date(session.date)}
                                            </Typography>
                                            <Typography variant="h6">{session.MappedStory.Story.storyName}</Typography>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <VTextLink
                                                underline={"always"}
                                                to={`/programmes/view/${session.programmeId}`}
                                            >
                                                {session.Programme?.name}
                                            </VTextLink>
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </>
            )}
        </Drawer>
    );
};
