import React, { useEffect } from "react";
import { Box, Container } from "@material-ui/core";
import { StepperWizard } from "../../../../core/components/wizard/StepperWizard";
import WizardStep from "../../../../core/components/wizard/WizardStep";
import { useStoryCollection } from "../contexts/collection.context";
import { CollectionDetails } from "./steps/CollectionDetails";
import { CollectionStories } from "./steps/CollectionStories";
import { ListProvider } from "../../../../core/components/crud/contexts/list.context";
import { list } from "../../../../client/api/story";

export const CreateCollectionWizard: React.FC = (): JSX.Element => {
    const { activeStep, completedSteps, changeStep, clearProvider } = useStoryCollection();

    useEffect(() => {
        if (completedSteps[2]) {
            clearProvider();
        }
    }, []);

    return (
        <Box p={4}>
            <Container maxWidth={"md"}>
                <StepperWizard activeStep={activeStep} goToStep={changeStep} orientation="horizontal">
                    <WizardStep identifier={0} heading="Collection Details" completed={completedSteps[0]}>
                        <CollectionDetails />
                    </WizardStep>
                    <WizardStep identifier={1} heading="Collection Stories" completed={completedSteps[1]}>
                        <ListProvider plural="Stories" getData={list}>
                            <CollectionStories />
                        </ListProvider>
                    </WizardStep>
                </StepperWizard>
            </Container>
        </Box>
    );
};
