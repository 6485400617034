import { createStyles, makeStyles } from "@material-ui/core";
import { publicTheme } from "../themes/public.theme";
const styles = createStyles({
    root: {
        display: "flex",
        minHeight: "100vh",
        backgroundColor: publicTheme.palette.secondary.main,
        color: "white",
    },
    main: {
        display: "flex",
        flex: "1",
        flexDirection: "column",
    },
});

export const usePublicLayoutStyle = makeStyles(styles);
