import React from "react";
import { WizardStep } from "../../../../core/components/wizard/WizardStep";
import { Details } from "../wizard/steps/Details";
import { Address } from "../wizard/steps/Address";
import { useOnboarding } from "../contexts/onboarding.context";
import { Container } from "@material-ui/core";
import { StepperWizard } from "../../../../core/components/wizard/StepperWizard";
import { useMobile } from "../../../../hooks/useMobile";
import { Confirmation } from "./steps/Confirmation";
import { Subscription } from "./steps/Subscription";
import WrappedPaymentMethod from "./steps/PaymentMethod";
import { OrganisationType } from "../entities/OnboardingOrganisationDetails";

export const OnboardingWizard: React.FC = () => {
    const { activeStep, completedSteps, changeStep, details } = useOnboarding();

    const { isMobile } = useMobile();

    return (
        <Container maxWidth={"md"}>
            <StepperWizard activeStep={activeStep} goToStep={changeStep} orientation={"horizontal"}>
                <WizardStep identifier={0} heading={isMobile ? " " : "Your Details"} completed={completedSteps[0]}>
                    <Details />
                </WizardStep>
                <WizardStep identifier={1} heading={isMobile ? " " : "Address"} completed={completedSteps[1]}>
                    <Address />
                </WizardStep>
                <WizardStep identifier={2} heading={isMobile ? " " : "Confirmation"} completed={completedSteps[2]}>
                    <Confirmation />
                </WizardStep>
                {details && details.orgType === OrganisationType.School ? (
                    <WizardStep identifier={3} heading={isMobile ? " " : "Subscription"} completed={completedSteps[3]}>
                        <Subscription />
                    </WizardStep>
                ) : (
                    <></>
                )}
                {details && details.orgType === OrganisationType.School ? (
                    <WizardStep
                        identifier={4}
                        heading={isMobile ? " " : "Payment Method"}
                        completed={completedSteps[4]}
                    >
                        <WrappedPaymentMethod />
                    </WizardStep>
                ) : (
                    <></>
                )}
            </StepperWizard>
        </Container>
    );
};
