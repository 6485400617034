import React from "react";
import Rating from "@material-ui/lab/Rating";
import { RatingProps } from "@material-ui/lab/Rating";
import { Box, fade, withStyles } from "@material-ui/core";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useUtilityStyles } from "../../hooks/styles/UtilityStyles";
import clsx from "clsx";

type SurveyRatingProps = RatingProps & {
    minLabel?: string;
    maxLabel?: string;
};

const StyledRating = withStyles({
    root: {
        width: "100%",
        "& svg": {
            width: "100%",
            height: "100%",
        },
    },
    label: {
        flex: 1,
        marginLeft: verbalTheme.spacing(1),
        "&:first-of-type": {
            marginLeft: "0",
        },
    },
    icon: {
        backgroundColor: verbalTheme.palette.common.white,
        boxShadow: "0px 0px 10px rgb(0 0 0 / 10%)",
        borderRadius: verbalTheme.shape.borderRadius,
        padding: verbalTheme.spacing(1),
    },
    iconFilled: {
        color: verbalTheme.palette.primary.main,
        backgroundColor: fade(verbalTheme.palette.primary.main, 0.3),
    },
    iconHover: {
        color: verbalTheme.palette.primary.main,
        backgroundColor: fade(verbalTheme.palette.primary.main, 0.3),
    },
})(Rating);

export const SurveyRating = (props: SurveyRatingProps): JSX.Element => {
    const { minLabel, maxLabel } = props;
    const useStyles = makeStyles(
        createStyles({
            surveyLabel: {
                fontSize: "0.75rem",
                maxWidth: "100px",
            },
            surveyAnswersContainer: {
                maxWidth: "100%",
            },
        }),
    );
    const classes = useStyles();
    const utilityClasses = useUtilityStyles();

    return (
        <>
            <div className={classes.surveyAnswersContainer}>
                <StyledRating {...props} />
                {minLabel && maxLabel && (
                    <Box mt={1} display="flex" justifyContent="space-between" alignItems="center">
                        <span className={clsx(classes.surveyLabel)}>{minLabel}</span>
                        <span className={clsx(classes.surveyLabel, utilityClasses.textRight)}>{maxLabel}</span>
                    </Box>
                )}
            </div>
        </>
    );
};

export default SurveyRating;
