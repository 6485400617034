import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";
import { countryList } from "common/build/countries/countryList";

type SelectCountryProps = {
    id?: string;
    value: string;
    onChange: (event: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    label?: string;
};

export const SelectCountry: React.FC<SelectCountryProps> = (props: SelectCountryProps) => {
    const { id = "country", value, onChange, label = "Country" } = props;

    return (
        <>
            <InputLabel id="country-label">{label}</InputLabel>
            <Select labelId={"country-label"} id={id} value={value} onChange={(e) => onChange(e)} label={label}>
                {countryList.map((country) => (
                    <MenuItem key={country} value={country}>
                        {country}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};
export default SelectCountry;
