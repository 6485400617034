import React, { useState } from "react";
import { MappedStoryType, ParticipantToProgrammeType, ParticipantType } from "../../models/modelTypes";
import { VAccordion, VAccordionItem } from "../../core/components/VAccordion";
import { useList } from "../../core/components/crud/contexts/list.context";
import { ParticipantSessionCard } from "../../components/session/components/ParticipantSessionCard";
import { SurveyReminderCard } from "../../components/session/components/SurveyReminderCard";
import { ParticipantNextSessionDetails } from "../session/components/ParticipantNextSessionDetails";
import { Box, Button, Grid } from "@material-ui/core";
import StoryOverview from "./StoryOverview";
import { ProgrammeParticipantProvider } from "./ProgrammeParticipant.context";
import { FetchProvider } from "../../core/components/crud/contexts/fetch.context";
import { get } from "../../client/api/public/session";
import { NoUpcoming } from "./NoUpcoming";
import { sortSessions } from "../../utils/sorts";
import { NavigateBefore } from "@material-ui/icons";

enum ParticipantHomeView {
    Overview = "Overview",
    NextSessionDetails = "NextSessionDetails",
    OnlineSession = "OnlineSession",
    ReadStory = "ReadStory",
    RelatedOverview = "RelatedOverview",
}

const p2pComparator = (participantToProgramme: ParticipantToProgrammeType): number => {
    const sortedSessions = [...(participantToProgramme?.Programme?.Sessions || [])].sort(sortSessions);
    // handle bad data
    if (sortedSessions.length === 0) {
        return 0;
    }
    const incompleteSession = sortedSessions.filter(({ state }) => state !== "Complete");
    // if there are incomplete sessionDates, use the first one as this programme's comparator
    return incompleteSession.length > 0
        ? new Date(incompleteSession[0].date).getTime()
        : // for fully completed programmes, flip the last session date (reverse order) and pad to move to the end
          new Date(sortedSessions[sortedSessions.length - 1].date).getTime() * -1 + Date.now() * 1000;
};
const p2pSort = (p1: ParticipantToProgrammeType, p2: ParticipantToProgrammeType) =>
    p2pComparator(p1) - p2pComparator(p2);

export const ParticipantHome: React.FC = (): JSX.Element => {
    const { list } = useList<ParticipantType>();
    const [view, setView] = useState<ParticipantHomeView>(ParticipantHomeView.Overview);
    const [nextSessionId, setNextSessionId] = useState<number>();
    const [currentParticipant, setCurrentParticipant] = useState<ParticipantType | undefined>();
    const [relatedStory, setRelatedStory] = useState<MappedStoryType>();

    const participantAccordian: React.ReactElement[] = [];

    list.map((participant) => {
        [...(participant?.ParticipantToProgramme || [])].sort(p2pSort).map((object) => {
            return participantAccordian.push(
                <VAccordionItem
                    expanded={list.length === 1 ? true : undefined}
                    title={participant.name}
                    key={(participant.name + object.Programme.id).toString()}
                    showHeader={
                        // We're only interested in showing the header if there's multiple participants with different names and sessions.
                        [
                            ...new Set(
                                list
                                    .filter((item) => item?.ParticipantToProgramme?.length ?? 0 > 0)
                                    .map((item) => item.name),
                            ),
                        ].length > 1
                    }
                >
                    <Grid container spacing={2}>
                        <ProgrammeParticipantProvider participantToProgramme={object}>
                            {!object.Programme.Sessions && <NoUpcoming nounPlural="Sessions" />}
                            <Grid item xs={12}>
                                {object.Programme.Survey && <SurveyReminderCard survey={object.Programme.Survey} />}
                            </Grid>
                            {object.Programme.Sessions && (
                                <>
                                    <ParticipantSessionCard
                                        buttonVariant={"contained"}
                                        showRelatedStories={false}
                                        sessions={object.Programme.Sessions}
                                        onViewNextSessionDetails={(session) => {
                                            setNextSessionId(session.id);
                                            setCurrentParticipant(participant);
                                            setView(ParticipantHomeView.NextSessionDetails);
                                        }}
                                    />
                                    <ParticipantSessionCard
                                        buttonVariant={"outlined"}
                                        previous={true}
                                        sessions={object.Programme.Sessions}
                                        onClickRelatedStory={(story?: MappedStoryType) => {
                                            setRelatedStory(story);
                                            setView(ParticipantHomeView.RelatedOverview);
                                        }}
                                    />
                                </>
                            )}
                        </ProgrammeParticipantProvider>
                    </Grid>
                </VAccordionItem>,
            );
        });
    });
    if (participantAccordian.length === 0) {
        participantAccordian.push(<NoUpcoming nounPlural="Programmes" />);
    }

    switch (view) {
        case ParticipantHomeView.Overview:
            return (
                <>
                    <VAccordion>{participantAccordian}</VAccordion>
                </>
            );
        case ParticipantHomeView.NextSessionDetails:
            return (
                <>
                    <Box pl={3}>
                        <Button
                            startIcon={<NavigateBefore />}
                            variant="text"
                            onClick={() => setView(ParticipantHomeView.Overview)}
                        >
                            Dashboard
                        </Button>
                    </Box>
                    {nextSessionId && currentParticipant && (
                        <FetchProvider noun={"session"} getItem={get}>
                            <ParticipantNextSessionDetails participant={currentParticipant} sessionId={nextSessionId} />
                        </FetchProvider>
                    )}
                </>
            );
        case ParticipantHomeView.RelatedOverview:
            return (
                <>
                    <Box pl={3}>
                        <Button
                            startIcon={<NavigateBefore />}
                            variant="text"
                            onClick={() => setView(ParticipantHomeView.Overview)}
                        >
                            Dashboard
                        </Button>
                    </Box>
                    <StoryOverview story={relatedStory} />
                </>
            );
        default:
            return <VAccordion>{participantAccordian}</VAccordion>;
    }
};
