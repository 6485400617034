import { Box, Typography } from "@material-ui/core";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import React from "react";
import { useAuth } from "../../../../../contexts/auth.context";
import { useFetch } from "../../../../../core/components/crud/contexts/fetch.context";
import { SpinWhileFetching } from "../../../../common/SpinWhileFetching";
import { VTextLink } from "../../../../common/VTextLink";
import { SecondaryButton } from "../../../../layout/Button/Button";
import { HeadlineFigureWidget } from "../HeadlineFigureWidget";

export const StoryHeadlineWidget = (): JSX.Element => {
    const { item } = useFetch<ActiveProgramStats>();
    const { hasPermission } = useAuth();
    return (
        <HeadlineFigureWidget header={"Total Stories"}>
            <Box>
                <SpinWhileFetching>
                    <Typography variant="h2">{item?.stories}</Typography>
                </SpinWhileFetching>
            </Box>
            <Box>
                {hasPermission("StoryCreate") && (
                    <VTextLink to="/stories/add">
                        <SecondaryButton fullWidth>Add Story</SecondaryButton>
                    </VTextLink>
                )}
            </Box>
        </HeadlineFigureWidget>
    );
};
