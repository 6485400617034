import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@material-ui/core";
import { ThemePill } from "../../session/components/ThemePill";
import { MappedStoryType, SessionType } from "../../../models/modelTypes";

type MappedStoryDetailsProps = {
    story?: MappedStoryType;
    session?: SessionType;
};

export const MappedStoryDetails: React.FC<MappedStoryDetailsProps> = (props: MappedStoryDetailsProps): JSX.Element => {
    const { story, session } = props;
    const [mappedStory, setMappedStory] = useState<MappedStoryType>();

    // dynamically set story - might be a better way to do this
    useEffect(() => {
        if (session) {
            setMappedStory(session.MappedStory);
        } else {
            setMappedStory(story);
        }
    }, []);

    const theme = useTheme();

    return (
        <>
            <Box color={theme.palette.common.white}>
                <Typography variant="h4" gutterBottom>
                    {mappedStory?.Story?.storyName}
                </Typography>
                <Typography variant="body2" color={"textSecondary"} gutterBottom>
                    By {mappedStory?.Story?.author}
                </Typography>
                {mappedStory?.Story?.synopsis && (
                    <Box mb={2}>
                        <Typography variant="body2" gutterBottom>
                            {mappedStory.Story.synopsis}
                        </Typography>
                    </Box>
                )}
            </Box>
            {mappedStory?.Story?.Themes && (
                <Box mb={2}>
                    {mappedStory?.Story?.Themes.map((theme, index) => (
                        <Box component={"span"} mb={2} key={index} mr={1}>
                            <ThemePill key={index} label={theme.name} content={theme.description || ""} />
                        </Box>
                    ))}
                </Box>
            )}
        </>
    );
};
