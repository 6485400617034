import { Tooltip, TooltipProps, withStyles } from "@material-ui/core";
import React, { FC } from "react";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";

const TooltipStyled = withStyles(() => ({
    arrow: {
        color: verbalTheme.palette.primary.main,
    },
    tooltip: {
        border: "2px solid #1FAF8C",
        backgroundColor: verbalTheme.palette.background.paper,
        color: verbalTheme.palette.text.primary,
        boxShadows: verbalTheme.shadows[1],
        fontSize: 16,
    },
}))(Tooltip);

export const VTooltip: FC<TooltipProps> = (props) => {
    const { children, ...rest } = props;
    return (
        <TooltipStyled arrow {...rest}>
            {children}
        </TooltipStyled>
    );
};
