import { ParticipantListParameters } from "common/build/api-parameters/participants";
import React, { useMemo } from "react";
import { add, deleteParticipant, list, update } from "../../../client/api/participants";
import { CrudProvider } from "../../../core/components/crud/contexts/crud.context";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { ListProvider } from "../../../core/components/crud/contexts/list.context";
import { ClassroomType } from "../../../models/modelTypes";
import { ClassroomStudentAdd } from "./ClassroomStudentAdd";
import { ClassroomStudentList } from "./ClassroomStudentList";
import { createClassroomStudentsCRUDModel } from "./model";

export const ClassroomStudentScreen = (props: { classroomId: number }): JSX.Element => {
    const { classroomId } = props;

    const { item } = useFetch<ClassroomType>();

    const model = useMemo(() => createClassroomStudentsCRUDModel(item), [item]);
    const listParams: ParticipantListParameters = { classroomId, take: 500 };

    return (
        <>
            <CrudProvider
                model={model}
                noun="Participant"
                addItem={add}
                updateItem={update}
                deleteData={deleteParticipant}
            >
                <ListProvider plural="Participants" includes={listParams} getData={list} pagination={false}>
                    <ClassroomStudentList />
                    <ClassroomStudentAdd classroomId={classroomId} />
                </ListProvider>
            </CrudProvider>
        </>
    );
};
