import React, { useEffect, useState } from "react";
import { Checkbox, FormControl, FormHelperText, Grid, InputLabel, MenuItem, TextField } from "@material-ui/core";
import { StoryType, ThemeType } from "../../../../../models/modelTypes";
import { Formik } from "formik";
import * as yup from "yup";
import { PrimaryButton, SecondaryButton } from "../../../../layout/Button/Button";
import { FormActions } from "../../../../../core/components/FormActions";
import { useHistory } from "react-router";
import Select from "@material-ui/core/Select";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { useStoryContext } from "../../story.context";
import { Stop } from "@material-ui/icons";
import { useThemes } from "../../../../../hooks/useTheme";
import { VPillPositive } from "../../../../../core/components/VPill";

export const AddStory = (): JSX.Element => {
    const { details, submitStep, changeStep, story } = useStoryContext();
    const { storyName, author, ageRange, synopsis, type, overview, numParts, colour, resources, resourcesROI } =
        details;
    const history = useHistory();
    const [isExtract, setIsExtract] = useState<boolean>(false);

    // themes
    const { themes, getAllThemes } = useThemes();
    const [selectedThemes, setSelectedThemes] = useState<ThemeType[]>([]);

    // get all themes for select list
    useEffect(() => {
        getAllThemes();
    }, []);

    // set existing themes
    useEffect(() => {
        if (story && story.Themes && story.Themes.length > 0) setSelectedThemes(story.Themes);
    }, [story]);

    useEffect(() => {
        if (details && details.type === "Extract") {
            setIsExtract(true);
        }
    }, [details]);

    const onSubmit = (values: Partial<StoryType> & { numParts: number }) => {
        const themeIds = selectedThemes.map((theme) => {
            return { id: theme.id };
        });
        submitStep({ ...details, ...values, themeIds: themeIds });
        changeStep(1);
    };

    const handleThemeSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedThemes(themes.filter((theme) => (event.target.value as string[]).includes(theme.name)));
    };

    const ageRanges = [
        ...(() => {
            const ageRanges: string[] = [];
            for (let i = 4; i < 17; i++) {
                ageRanges.push(`${i}-${i + 1}`);
            }
            return ageRanges;
        })(),
        "18+",
    ];

    return (
        <form>
            <WizardHeading heading="Story Details" overline={`${story ? "Edit" : "Create"} a Story`} />
            <Formik
                initialValues={{
                    storyName: storyName ?? "",
                    author: author ?? "",
                    synopsis: synopsis ?? "",
                    overview: overview ?? "",
                    ageRange: ageRange ?? "",
                    type: type ?? undefined,
                    numParts: numParts ?? 0,
                    colour: colour ?? "Red",
                    resources: resources ?? "",
                    resourcesROI: resourcesROI ?? "",
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    storyName: yup.string().required("Story name is required"),
                    author: yup.string().required("Author is required"),
                    synopsis: yup.string().required("Synopsis is required"),
                    ageRange: yup.string().required("Age range is required"),
                    type: yup.string().required("Type of story is required"),
                    numParts: yup.number().moreThan(0).required("Number of parts is required"),
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    autoFocus
                                    required
                                    name="storyName"
                                    variant="outlined"
                                    label="Story Name"
                                    value={values.storyName}
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.storyName && touched.storyName && errors.storyName}
                                    error={!!errors.storyName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    name="author"
                                    variant="outlined"
                                    label="Author"
                                    value={values.author}
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.author && touched.author && errors.author}
                                    error={!!errors.author}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" error={!!errors.ageRange} fullWidth>
                                    <InputLabel id="age-label" required>
                                        Age Range of Story
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Age Range of Story"
                                        labelId="age-label"
                                        name="ageRange"
                                        id="ageRange"
                                        value={values.ageRange}
                                        onChange={(e) => setFieldValue("ageRange", e.target.value)}
                                    >
                                        <MenuItem key={0} value={""} disabled>
                                            Please select
                                        </MenuItem>
                                        {ageRanges.map((ageRange) => (
                                            <MenuItem key={ageRange} value={ageRange}>
                                                {ageRange}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>
                                        {errors.ageRange && touched.ageRange && errors.ageRange}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl variant="outlined" error={!!errors.type} fullWidth>
                                    <InputLabel id="type-label" required>
                                        Story Type
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Story Type"
                                        labelId="type-label"
                                        name="type"
                                        id="type"
                                        value={values.type}
                                        onChange={(e) => {
                                            setFieldValue("type", e.target.value);
                                            if (e.target.value === "Extract") {
                                                setIsExtract(true);
                                            } else {
                                                setIsExtract(false);
                                            }
                                        }}
                                    >
                                        <MenuItem key={0} value={""} disabled>
                                            Please select
                                        </MenuItem>
                                        <MenuItem key={"ShortStory"} value={"ShortStory"}>
                                            Short Story
                                        </MenuItem>
                                        <MenuItem key={"Extract"} value={"Extract"}>
                                            Extract
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.type && touched.type && errors.type}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" error={!!errors.colour} fullWidth>
                                    <InputLabel id="colour-label" required>
                                        Card Colour
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Card Colour"
                                        labelId="colour-label"
                                        name="colour"
                                        id="colour"
                                        value={values.colour}
                                        onChange={(e) => {
                                            setFieldValue("colour", e.target.value);
                                        }}
                                    >
                                        <MenuItem key={0} value={""} disabled>
                                            Please select
                                        </MenuItem>
                                        <MenuItem key={"Red"} value={"Red"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#CA2D23" }} />
                                                Red
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Orange"} value={"Orange"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#DD752A" }} />
                                                Orange
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Yellow"} value={"Yellow"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#D3B619" }} />
                                                Yellow
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Green"} value={"Green"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#78AF1F" }} />
                                                Green
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Cyan"} value={"Cyan"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#27B5BE" }} />
                                                Cyan
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Blue"} value={"Blue"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#6361B2" }} />
                                                Blue
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Magneta"} value={"Magneta"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#AA3BA6" }} />
                                                Magneta
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Crimson"} value={"Crimson"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#D33057" }} />
                                                Crimson
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Silver"} value={"Silver"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#818390" }} />
                                                Silver
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Bronze"} value={"Bronze"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#A77A50" }} />
                                                Bronze
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"BrightYellow"} value={"BrightYellow"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#FFF700" }} />
                                                Bright Yellow
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Aqua"} value={"Aqua"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#0DFFFF" }} />
                                                Aqua
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"Purple"} value={"Purple"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#AE0CFF" }} />
                                                Purple
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"BrightOrange"} value={"BrightOrange"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#FF7A15" }} />
                                                Bright Orange
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"BrightRed"} value={"BrightRed"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#FF0000" }} />
                                                Bright Red
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"LightGreen"} value={"LightGreen"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#007919" }} />
                                                Light Green
                                            </div>
                                        </MenuItem>
                                        <MenuItem key={"NightBlue"} value={"NightBlue"}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <Stop fontSize="large" style={{ fill: "#033F77" }} />
                                                Night Blue
                                            </div>
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>{errors.colour && touched.colour && errors.colour}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" fullWidth>
                                    <InputLabel id="theme-label" required>
                                        Story Themes
                                    </InputLabel>
                                    <Select
                                        multiple
                                        multiline
                                        fullWidth
                                        label="Story Themes"
                                        labelId="theme-label"
                                        value={selectedThemes.map((theme) => theme.name)}
                                        renderValue={(selected) => {
                                            return (selected as string[]).map((value) => (
                                                <VPillPositive key={value} style={{ margin: "2px" }} label={value} />
                                            ));
                                        }}
                                        onChange={handleThemeSelectChange}
                                    >
                                        {themes.map((theme) => (
                                            <MenuItem key={theme.id} value={theme.name}>
                                                <Checkbox
                                                    checked={selectedThemes.findIndex((t) => t.id === theme.id) > -1}
                                                />
                                                {theme.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    name="synopsis"
                                    variant="outlined"
                                    label="Synopsis"
                                    value={values.synopsis}
                                    fullWidth
                                    multiline
                                    onChange={handleChange}
                                    helperText={errors.synopsis && touched.synopsis && errors.synopsis}
                                    error={!!errors.synopsis}
                                />
                            </Grid>
                            {isExtract ? (
                                <Grid item xs={12}>
                                    <TextField
                                        required={isExtract}
                                        name="overview"
                                        variant="outlined"
                                        label="Contextual Overview"
                                        value={values.overview}
                                        fullWidth
                                        multiline
                                        onChange={handleChange}
                                        helperText={errors.overview && touched.overview && errors.overview}
                                        error={!!errors.overview}
                                    />
                                </Grid>
                            ) : null}
                            <Grid item xs={12}>
                                <TextField
                                    name="resources"
                                    variant="outlined"
                                    label="Link to Additional Resources - NI"
                                    value={values.resources}
                                    fullWidth
                                    multiline
                                    onChange={handleChange}
                                    helperText={errors.resources && touched.resources && errors.resources}
                                    error={!!errors.resources}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="resourcesROI"
                                    variant="outlined"
                                    label="Link to Additional Resources - ROI"
                                    value={values.resourcesROI}
                                    fullWidth
                                    multiline
                                    onChange={handleChange}
                                    helperText={errors.resourcesROI && touched.resourcesROI && errors.resourcesROI}
                                    error={!!errors.resourcesROI}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl variant="outlined" error={!!errors.numParts} fullWidth>
                                    <InputLabel id="numParts-label" required>
                                        Num. of Story Parts / Sections
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        label="Num. of Story Parts / Sections"
                                        labelId="numParts-label"
                                        name="numParts"
                                        id="numParts"
                                        value={values.numParts}
                                        onChange={(e) => {
                                            setFieldValue("numParts", e.target.value);
                                        }}
                                    >
                                        <MenuItem key={0} value={""} disabled>
                                            Please select
                                        </MenuItem>
                                        <MenuItem key={1} value={1}>
                                            1
                                        </MenuItem>
                                        <MenuItem key={2} value={2}>
                                            2
                                        </MenuItem>
                                        <MenuItem key={3} value={3}>
                                            3
                                        </MenuItem>
                                        <MenuItem key={4} value={4}>
                                            4
                                        </MenuItem>
                                        <MenuItem key={5} value={5}>
                                            5
                                        </MenuItem>
                                    </Select>
                                    <FormHelperText>
                                        {errors.numParts && touched.numParts && errors.numParts}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Input fields marked with an asterisk (*) are required
                            </Grid>

                            <Grid item xs={12}>
                                <FormActions
                                    left={
                                        <SecondaryButton size="large" onClick={() => history.push("/stories")}>
                                            Cancel
                                        </SecondaryButton>
                                    }
                                    right={
                                        <PrimaryButton
                                            size="large"
                                            disabled={isSubmitting}
                                            onClick={(event) => {
                                                event.preventDefault();
                                                submitForm();
                                            }}
                                        >
                                            Continue
                                        </PrimaryButton>
                                    }
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </form>
    );
};
