import { Card, Typography } from "@material-ui/core";
import React, { FC } from "react";
import {
    VictoryAxis,
    VictoryBar,
    VictoryChart,
    VictoryContainer,
    VictoryTheme,
    VictoryTooltip,
    VictoryGroup,
} from "victory";
import { MoodType } from "../../../models/modelTypes";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import dayjs from "dayjs";

type StudentWellbeingChartProps = {
    moods: MoodType[];
};

export const StudentWellbeingChart: FC<StudentWellbeingChartProps> = (
    props: StudentWellbeingChartProps,
): JSX.Element => {
    const { moods } = props;

    return (
        <>
            <Card variant="outlined">
                <Typography variant="h4" style={{ paddingTop: verbalTheme.spacing(2) }} align="center">
                    Mood History (Last 7){" "}
                    <span
                        style={{
                            backgroundColor: verbalTheme.palette.primary.main,
                            color: verbalTheme.palette.common.white,
                            borderRadius: 5,
                            padding: 5,
                        }}
                    >
                        Positive
                    </span>{" "}
                    <span
                        style={{
                            backgroundColor: verbalTheme.palette.error.main,
                            color: verbalTheme.palette.common.white,
                            borderRadius: 5,
                            padding: 5,
                        }}
                    >
                        Negative
                    </span>{" "}
                    <span
                        style={{
                            backgroundColor: "#455964",
                            color: verbalTheme.palette.common.white,
                            borderRadius: 5,
                            padding: 5,
                        }}
                    >
                        Neutral
                    </span>
                </Typography>

                <VictoryChart
                    containerComponent={<VictoryContainer responsive={true} />}
                    height={100}
                    padding={{ top: 10, bottom: 25, right: 10, left: 35 }}
                    domainPadding={{ x: 20 }}
                    theme={VictoryTheme.material}
                >
                    <VictoryAxis
                        dependentAxis
                        orientation="left"
                        tickValues={[{ type: "Negative" }, { type: "Neutral" }, { type: "Positive" }].map(
                            (val) => val.type,
                        )}
                        tickFormat={(y) => `${y}`}
                        style={{
                            tickLabels: {
                                fontSize: 5,
                            },
                        }}
                    />
                    <VictoryAxis
                        style={{
                            tickLabels: {
                                fontSize: 5,
                            },
                        }}
                    />
                    <VictoryGroup offset={20}>
                        <VictoryBar
                            categories={{ y: ["Negative", "Neutral", "Positive"] }}
                            labels={({ datum }) => datum.reason ?? datum.mood}
                            labelComponent={
                                <VictoryTooltip
                                    style={{ fontSize: verbalTheme.spacing(0.5) }}
                                    constrainToVisibleArea
                                    text={({ datum }) =>
                                        `Check-in: ${
                                            datum?.y === "Negative" ? `${datum.mood} - ${datum.reason}` : datum.mood
                                        }`
                                    }
                                />
                            }
                            data={moods?.slice(-7).map((m) => {
                                return {
                                    x: dayjs(m.date.toLocaleString().split("T")[0]).format("ddd D MMM"),
                                    y:
                                        m.type === "Registration"
                                            ? ["Calm"].includes(m.mood)
                                                ? "Neutral"
                                                : ["Sad", "Angry", "Worried"].includes(m.mood)
                                                ? "Negative"
                                                : "Positive"
                                            : null,
                                    reason: m.reason,
                                    mood: m.mood,
                                };
                            })}
                            style={{
                                data: {
                                    fill: ({ datum }) =>
                                        datum.y === "Negative"
                                            ? "#E84848"
                                            : datum.y === "Positive"
                                            ? "#1FAF8C"
                                            : "#455964",
                                },
                            }}
                        />
                        <VictoryBar
                            categories={{ y: ["Negative", "Neutral", "Positive"] }}
                            labels={({ datum }) => datum.reason ?? datum.mood}
                            labelComponent={
                                <VictoryTooltip
                                    style={{ fontSize: verbalTheme.spacing(0.5) }}
                                    constrainToVisibleArea
                                    text={({ datum }) =>
                                        `Session: ${
                                            datum?.y === "Negative" ? `${datum.mood} - ${datum.reason}` : datum.mood
                                        }`
                                    }
                                />
                            }
                            data={moods?.slice(-7).map((m) => {
                                return {
                                    x: dayjs(m.date.toLocaleString().split("T")[0]).format("ddd D MMM"),
                                    y:
                                        m.type === "Session"
                                            ? ["Calm"].includes(m.mood)
                                                ? "Neutral"
                                                : ["Sad", "Angry", "Worried"].includes(m.mood)
                                                ? "Negative"
                                                : "Positive"
                                            : null,
                                    reason: m.reason,
                                    mood: m.mood,
                                };
                            })}
                            style={{
                                data: {
                                    fill: ({ datum }) =>
                                        datum.y === "Negative"
                                            ? "#E84848"
                                            : datum.y === "Positive"
                                            ? "#1FAF8C"
                                            : "#455964",
                                },
                            }}
                        />
                    </VictoryGroup>
                </VictoryChart>
            </Card>
        </>
    );
};
