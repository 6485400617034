import React, { useState } from "react";
import { VPill, VPillProps } from "../../../core/components/VPill";
import { makeStyles } from "@material-ui/core/styles";
import { ClickAwayListener, Paper, Typography, Popper } from "@material-ui/core";
import { publicTheme } from "../../layout/themes/public.theme";

type ThemePillProps = VPillProps & {
    content: string | null;
};

const useStyles = makeStyles((verbalTheme) => ({
    paper: {
        border: "1px solid rgba(0,0,0,0.1)",
        backgroundColor: "white",
        color: verbalTheme.palette.text.primary,
    },
    typography: {
        padding: verbalTheme.spacing(2),
        color: verbalTheme.palette.text.primary,
    },
    pill: {
        backgroundColor: publicTheme.palette.themePill.main,
        color: verbalTheme.palette.common.white,
    },
}));

export const ThemePill: React.FC<ThemePillProps> = (props: ThemePillProps) => {
    const classes = useStyles();
    const { label, content } = props;
    const [isOpen, setIsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const clickAwayHandler = () => setIsOpen(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsOpen(!isOpen);
    };

    return (
        <>
            {content ? (
                <>
                    <VPill label={label} clickable={true} onClick={handleClick} className={classes.pill} />
                    {isOpen && (
                        <ClickAwayListener onClickAway={clickAwayHandler}>
                            <Popper style={{ zIndex: 1 }} open={isOpen} anchorEl={anchorEl}>
                                <Paper className={"c-popper"}>
                                    <Typography variant="body2">{content}</Typography>
                                </Paper>
                            </Popper>
                        </ClickAwayListener>
                    )}
                </>
            ) : (
                <VPill label={label} />
            )}
        </>
    );
};
