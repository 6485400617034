import React, { FC, useEffect, useRef, useState } from "react";
import { list as listParticipants } from "../../../client/api/participants";
import { addParticipant } from "../../../client/api/programme";
import { Participant } from "common/build/prisma/client";
import { useList } from "../../../core/components/crud/contexts/list.context";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    createStyles,
    Grid,
    makeStyles,
    Popper,
    PopperProps,
    TextField,
    Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { createProgrammeParticipantCRUDModel } from "./model";
import { getValidationSchema } from "../../../core/components/crud/Add";
import { AsyncInput } from "../../../core/components/AsyncInput";
import { useAuth } from "../../../contexts/auth.context";
import { ParticipantType } from "../../../models/modelTypes";
import { verbalTheme } from "../../layout/themes/verbal.theme";

type ProgrammeParticipantAddProps = {
    programmeId: number;
    wideName?: boolean;
};
export const ProgrammeParticipantAdd: FC<ProgrammeParticipantAddProps> = (props) => {
    const { programmeId, wideName = false } = props;
    const { fetchList, list, toggleCheck } = useList<Participant>();
    const [error, setError] = useState<string | undefined>();
    const [participantIds, setParticipantIds] = useState<number[]>([]);
    const [loading, setLoading] = useState<boolean>();
    const { user } = useAuth();

    useEffect(() => {
        const addedParticipantIds: number[] = [];
        for (const item of list) {
            addedParticipantIds.push(typeof item.id === "string" ? parseInt(item.id) : item.id);
        }
        setParticipantIds(addedParticipantIds);
    }, [list]);

    const nameInputRef = useRef<HTMLInputElement>();

    const onSave = (values: Partial<Participant>, { resetForm }: { resetForm: () => void }) => {
        setLoading(true);

        addParticipant(programmeId, {
            id: values.id ?? 0,
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            organisationId: user?.organisationId,
        })
            .then(async (res) => {
                if (res.ok) {
                    const participantResponse: ParticipantType = await res.json();
                    toggleCheck(participantResponse.id);
                    setError(undefined);
                    resetForm();
                    //nameInputRef.current?.focus();
                    fetchList();
                } else {
                    setError("Error adding participant");
                }
            })
            .catch((err: Error) => {
                setError(err.message);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const initialValues: Partial<ParticipantType> = {
        name: "",
        phoneNumber: "",
        email: "",
    };

    const useStyles = makeStyles(
        createStyles({
            gridName: {
                [verbalTheme.breakpoints.down(620)]: {
                    flexBasis: "100%",
                },
                [verbalTheme.breakpoints.down(1114)]: {
                    flexBasis: "100%",
                },
            },
            gridEmail: {
                [verbalTheme.breakpoints.down(620)]: {
                    flexBasis: "100%",
                },
            },
            gridPhone: {
                [verbalTheme.breakpoints.down(620)]: {
                    flexBasis: "100%",
                },
            },
            wideGridName: {
                flexBasis: "100%",
            },
        }),
    );

    const customPopper = function (props: PopperProps) {
        return (
            <Popper
                {...props}
                style={{
                    width: "fit-content",
                }}
                placement="bottom-start"
            />
        );
    };

    const classes = useStyles();

    return (
        <Card>
            <CardContent>
                <Box mb={2}>
                    <Typography variant="h4" component="h3" color="textPrimary" align="left" gutterBottom>
                        Add Participant
                    </Typography>
                </Box>
                <Formik
                    initialValues={initialValues}
                    onSubmit={onSave}
                    validationSchema={yup.object(getValidationSchema(createProgrammeParticipantCRUDModel()))}
                    validateOnChange={false}
                >
                    {({
                        handleSubmit,
                        resetForm,
                        handleChange,
                        isSubmitting,
                        errors,
                        setFieldValue,
                        values,
                        touched,
                    }) => {
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md
                                        className={`${classes.gridName} ${wideName ? classes.wideGridName : ""}`}
                                    >
                                        <AsyncInput<Participant>
                                            inputRef={nameInputRef}
                                            freeSolo
                                            onSelected={(participant) => {
                                                if (participant) {
                                                    setFieldValue("id", participant.id);
                                                    setFieldValue("name", participant.name);
                                                    setFieldValue("email", participant.email);
                                                    setFieldValue("phoneNumber", participant.phoneNumber);
                                                } else {
                                                    resetForm();
                                                }
                                            }}
                                            label="Name*"
                                            valueProperty="id"
                                            textProperty="name"
                                            onSearchChange={(name) => {
                                                if (values.id) {
                                                    resetForm();
                                                }
                                                setFieldValue("name", name);
                                                if (!name) {
                                                    return Promise.resolve({ items: [], total: 0 });
                                                }
                                                return listParticipants({
                                                    nameSearch: {
                                                        name,
                                                        excludeIds: participantIds,
                                                    },
                                                    take: 6,
                                                });
                                            }}
                                            renderOption={(option) => (
                                                <>
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle1" component="h2">
                                                                {(option.additional as Participant).name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} md="auto">
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {(option.additional as Participant).email}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md="auto">
                                                                    <Typography variant="body2" color="textSecondary">
                                                                        {(option.additional as Participant).phoneNumber}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {(option.additional as Participant).lastInvolvement && (
                                                            <Grid item xs={12}>
                                                                <Typography variant="body2" color="textSecondary">
                                                                    <strong>Last Involvement:</strong>{" "}
                                                                    {(option.additional as Participant).lastInvolvement}
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                            PopperComponent={customPopper}
                                            helperText={errors.name && touched.name ? errors.name : undefined}
                                            error={errors.name ? true : false}
                                        />
                                    </Grid>
                                    <Grid item xs md className={classes.gridEmail}>
                                        <TextField
                                            fullWidth={true}
                                            id="email"
                                            label="Email"
                                            variant="outlined"
                                            autoComplete="off"
                                            disabled={!!values.id}
                                            value={values.email}
                                            onChange={handleChange}
                                            helperText={errors.email && touched.email && errors.email}
                                            error={errors.email ? true : false}
                                            inputProps={{
                                                maxLength: 100,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs md className={classes.gridPhone}>
                                        <TextField
                                            fullWidth={true}
                                            id="phoneNumber"
                                            label="Phone Number"
                                            variant="outlined"
                                            autoComplete="off"
                                            disabled={!!values.id}
                                            value={values.phoneNumber}
                                            onChange={handleChange}
                                            helperText={errors.phoneNumber && touched.phoneNumber && errors.phoneNumber}
                                            error={errors.phoneNumber ? true : false}
                                            inputProps={{
                                                maxLength: 50,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            disabled={isSubmitting || !values?.name}
                                        >
                                            {!loading ? "Add" : <CircularProgress />}
                                        </Button>
                                    </Grid>
                                </Grid>
                                {error && (
                                    <Grid item xs={12}>
                                        <Typography color="error">{error}</Typography>
                                    </Grid>
                                )}

                                <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)", paddingTop: "8px" }}>
                                    Input fields marked with an asterisk (*) are required
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </CardContent>
        </Card>
    );
};
