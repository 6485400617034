import { StoryCollectionType } from "../../../../models/modelTypes";

export const collectionsCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Story Collection",
    },
    description: {
        inTable: false,
    },
    ageRange: {
        label: "Age Group",
        decorator: (value: unknown, property?: string, collection?: StoryCollectionType): string =>
            collection?.ageRange
                ? collection?.ageRange
                : collection?.Curriculum?.ageRange
                ? collection?.Curriculum?.ageRange
                : "",
    },
    curriculumId: {
        label: "Curriculum",
        inForm: false,
        decorator: (value: unknown, property?: string, collection?: StoryCollectionType): string =>
            collection?.Curriculum ? collection.Curriculum.name : "None",
    },
    Stories: {
        label: "Stories",
        inForm: false,
        decorator: (value: unknown, property?: string, collection?: StoryCollectionType): string =>
            collection?.Stories?.length && collection?.Stories?.length > 0 ? String(collection.Stories.length) : "0",
    },
    type: {
        label: "Type",
    },
    approved: {
        label: "Approved for use",
        decorator: (value: unknown, property?: string, collection?: StoryCollectionType): string =>
            collection?.approved ? "Yes" : "No",
    },
};
