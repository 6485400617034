import React from "react";
import { Crud } from "../../core/components/crud/Crud";
import { get, list, add, update, deleteUser } from "../../client/api/users";
import { User } from "common/build/prisma/client";
import { usersCRUDModel } from "./model";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/auth.context";
import { UserType } from "../../models/modelTypes";
import { UserListParameters } from "../../../../common/build/api-parameters/users";
import { AddUser } from "./AddUser";

const params = { includeRole: true };

export const UsersScreen = (): JSX.Element => {
    const { goBack } = useHistory();
    const { user: loggedInUser, hasPermission } = useAuth();

    return (
        <>
            <Crud<UserType, UserListParameters>
                model={usersCRUDModel}
                noun="User"
                getData={list}
                getItem={get}
                addItem={loggedInUser?.isVerbal ? add : undefined}
                updateItem={hasPermission("UserUpdate") ? update : undefined}
                labelProperty={"name"}
                listParams={params}
                addComponent={AddUser}
                userFilter={true}
                searchable
                actions={[
                    {
                        label: "Archive User",
                        onClick: (id: number) => {
                            deleteUser(id).then(() => {
                                goBack();
                            });
                        },
                        visible: (user: User) => !user.deleted && user.id !== loggedInUser?.id,
                    },
                    {
                        label: "Unarchive User",
                        onClick: (id: number) => {
                            update(id, { deleted: false }).then(() => {
                                goBack();
                            });
                        },
                        visible: (user: User) => user.deleted,
                    },
                ]}
            />
        </>
    );
};
export default UsersScreen;
