import React, { FC } from "react";
import { Box, Card, CardContent, Grid, Typography, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import barchartImg from "../../../../img/bars.svg";
import { SimpleBlankSlate } from "../../../../core/components/SimpleBlankSlate";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import {
    VictoryLegend,
    VictoryStack,
    VictoryAxis,
    VictoryChart,
    VictoryTheme,
    VictoryContainer,
    VictoryLabel,
} from "victory";
import { verbalTheme } from "../../../layout/themes/verbal.theme";

const useStyles = makeStyles(
    createStyles({
        widget: {
            height: "100%",
            minHeight: "235px",
            maxHeight: "50vh",
            overflowY: "auto",
        },
        widgetInner: {
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        centered: {
            textAlign: "center",
        },
    }),
);

export const StackedBarChartWidget: FC<{
    programmeCount: number;
    header: string;
    subHeader?: string | React.ReactElement;
    spinWhileFetching?: boolean;
    hasNoData?: boolean;
    noDataMessage?: React.ReactElement;
    barCharts?: JSX.Element[];
    legend?: Array<{
        name?: string;
        labels?: {
            fill?: string;
        };
        symbol?: {
            fill?: string;
            type?: string;
        };
    }>;
    xAxisLabelComponent?: React.ReactElement;
    xAxisEvents?: unknown[];
}> = ({
    header,
    spinWhileFetching = false,
    legend,
    barCharts,
    hasNoData = false,
    noDataMessage,
    xAxisLabelComponent,
    xAxisEvents,
    subHeader,
    programmeCount,
}) => {
    const classes = useStyles();
    const { fetching } = useFetch();
    const Loader = spinWhileFetching ? SpinWhileFetching : React.Fragment;
    const isSmall = useMediaQuery(verbalTheme.breakpoints.down("lg"));
    return (
        <Grid item xs={12} md={12}>
            <Card className={classes.widget} variant="outlined">
                <CardContent className={clsx(classes.widgetInner)}>
                    {!fetching && hasNoData ? (
                        <SimpleBlankSlate
                            image={<img src={barchartImg} />}
                            header={header}
                            extra={<Box mb={2}>{noDataMessage}</Box>}
                        />
                    ) : (
                        <>
                            <Box>
                                <Typography variant="h4">{header}</Typography>
                                <Box style={{ padding: "10px" }}>{subHeader}</Box>
                            </Box>

                            <Box className={classes.centered}>
                                <Loader>
                                    <Box>
                                        <VictoryChart
                                            theme={VictoryTheme.material}
                                            containerComponent={<VictoryContainer responsive={true} />}
                                            width={isSmall ? 650 : 650}
                                            height={40 * programmeCount}
                                            domainPadding={{ x: 10 }}
                                            padding={{ top: 20, bottom: 25, left: 175, right: 10 }}
                                        >
                                            <VictoryLegend
                                                theme={VictoryTheme.material}
                                                style={{
                                                    labels: { fontSize: 8 },
                                                }}
                                                orientation="horizontal"
                                                data={legend}
                                            />
                                            <VictoryAxis
                                                theme={VictoryTheme.material}
                                                tickLabelComponent={
                                                    xAxisLabelComponent ?? (
                                                        <VictoryLabel style={{ cursor: "pointer", fontSize: 5 }} />
                                                    )
                                                }
                                                events={(xAxisEvents as never) ?? []}
                                                style={{
                                                    axisLabel: { fontSize: 8 },
                                                    tickLabels: { fontSize: 8 },
                                                }}
                                                horizontal
                                            />
                                            <VictoryAxis
                                                theme={VictoryTheme.material}
                                                style={{
                                                    axisLabel: { fontSize: 8 },
                                                    tickLabels: { fontSize: 8 },
                                                }}
                                                tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                                                tickFormat={(t) => `${t}%`}
                                                dependentAxis
                                            />
                                            <VictoryStack
                                                theme={VictoryTheme.material}
                                                style={{
                                                    labels: { fontSize: 8 },
                                                }}
                                                horizontal
                                                colorScale={["#1EAE8C", "#131739"]}
                                            >
                                                {barCharts}
                                            </VictoryStack>
                                        </VictoryChart>
                                    </Box>
                                </Loader>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
};
