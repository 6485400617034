import { AsyncSearch } from "../../../core/components/AsyncSearch";
import { list as listRoles } from "../../../client/api/role";
import { RoleName } from "common/build/prisma/client";
import { UserType, RoleType as RoleWithPermissions } from "../../../models/modelTypes";
import { RoleType } from "../../users/entities/RoleType";
import { Model } from "../../../core/components/crud/Crud";
import dayjs from "dayjs";

export const organisationUsersModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Name",
        decorator: (value: string, property: string, user: UserType): string => `${user.firstName} ${user.lastName}`,
    },
    email: {
        label: "Email",
    },
    roleId: {
        label: "Role",
        InputComponent: AsyncSearch,
        inputProps: {
            label: "Role",
            property: "roleId",
            onSearchChange: async (): Promise<{ items: RoleWithPermissions[]; total: number }> => {
                const { items, total } = await listRoles();
                return new Promise<{ items: RoleWithPermissions[]; total: number }>((resolve) =>
                    resolve({
                        items: items.map(
                            (item) =>
                                ({ ...item, name: RoleType[item.name] as unknown as RoleName }) as RoleWithPermissions,
                        ),
                        total,
                    }),
                );
            },
        },
        decorator: (value: string, property: string, user: UserType): string => RoleType[user.Role.name],
    },
    visitCount: {
        label: "No of Visits",
    },
    registrationDate: {
        label: "Date Registered",
        decorator: (value: string, property?: string, user?: UserType): string => {
            return dayjs(user?.registrationDate).format("D MMM YYYY [at] HH:mm");
        },
    },
    lastVisited: {
        label: "Last Logon",
        decorator: (value: string, property?: string, user?: UserType): string => {
            return dayjs(user?.lastVisited).format("D MMM YYYY [at] HH:mm");
        },
    },
} as Model<UserType>;
