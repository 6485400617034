import React from "react";
import { IconContainerProps } from "@material-ui/lab/Rating";
import {
    SentimentVerySatisfied,
    SentimentDissatisfied,
    SentimentSatisfied,
    SentimentSatisfiedAlt,
    SentimentVeryDissatisfied,
    Looks5,
    Looks4,
    Looks3,
    LooksTwo,
    LooksOne,
} from "@material-ui/icons";

const smileyIcons: { [index: string]: { icon: React.ReactElement } } = {
    1: {
        icon: <SentimentVeryDissatisfied />,
    },
    2: {
        icon: <SentimentDissatisfied />,
    },
    3: {
        icon: <SentimentSatisfied />,
    },
    4: {
        icon: <SentimentSatisfiedAlt />,
    },
    5: {
        icon: <SentimentVerySatisfied />,
    },
};

const numberIcons: { [index: string]: { icon: React.ReactElement } } = {
    1: {
        icon: <LooksOne />,
    },
    2: {
        icon: <LooksTwo />,
    },
    3: {
        icon: <Looks3 />,
    },
    4: {
        icon: <Looks4 />,
    },
    5: {
        icon: <Looks5 />,
    },
};

export type CustomIconContainerProps = IconContainerProps & {
    name: string;
};

export function CustomIconContainer(props: CustomIconContainerProps): JSX.Element {
    const { name, value, ...other } = props;
    return <span {...other}>{name === "smiley" ? smileyIcons[value].icon : numberIcons[value].icon}</span>;
}
