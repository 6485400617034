import { Box, Button, Typography, Link, Avatar } from "@material-ui/core";
import React from "react";
import { createStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import { VModule } from "../../../../core/components/VModule";
import { ConferenceProviderParticipantText } from "common/build/programmes/conferenceProviderParticipantText";
import { SmsOutlined } from "@material-ui/icons";

interface ExternalVideoConferenceProps {
    providerURL: string;
    isParticipant: boolean;
    isSessionStarted: boolean;
    provider: string;
}

export const ExternalVideoConference: React.FC<ExternalVideoConferenceProps> = (
    props: ExternalVideoConferenceProps,
): JSX.Element => {
    const { providerURL, isParticipant, isSessionStarted, provider } = props;
    const theme = useTheme();
    const useStyles = makeStyles(() =>
        createStyles({
            avatar: {
                backgroundColor: theme.palette.primary.main,
                width: theme.spacing(10),
                height: theme.spacing(10),
            },
        }),
    );

    const classes = useStyles();

    let absoluteURL;
    if (providerURL) {
        const absoluteURLRegex = new RegExp("^(http|https)://", "i");
        absoluteURL = absoluteURLRegex.test(providerURL) ? providerURL : `https://${providerURL}`;
    }

    return (
        <Box
            pl={4}
            pr={4}
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            justifyContent={"center"}
            textAlign={"center"}
        >
            <Box display={"flex"} justifyContent={"center"} mb={4}>
                <Avatar className={classes.avatar}>{<SmsOutlined fontSize={"large"} />}</Avatar>
            </Box>
            <Box mb={2}>
                <Typography variant={"h3"}>Your Meeting</Typography>
            </Box>
            {isParticipant
                ? getParticipantContent(isSessionStarted, provider, absoluteURL)
                : getFacilitatorContent(isSessionStarted, absoluteURL)}
        </Box>
    );
};
function getParticipantContent(isSessionStarted: boolean, provider: string, absoluteURL?: string): React.ReactElement {
    return (
        <>
            <Box mb={4}>
                <Typography>{ConferenceProviderParticipantText(provider)}</Typography>
            </Box>
            <Box mt={4}>
                <Typography>You can use the link below to access the meeting</Typography>
                {absoluteURL && (
                    <Link style={{ wordBreak: "break-all" }} target="_blank" href={absoluteURL}>
                        {absoluteURL}
                    </Link>
                )}
            </Box>
        </>
    );
}

function getFacilitatorContent(isSessionStarted: boolean, absoluteURL?: string): React.ReactElement {
    return (
        <>
            <Box mb={4}>
                {isSessionStarted ? (
                    <Typography>
                        Your meeting is currently in progress using your selected third party conference provider
                    </Typography>
                ) : (
                    <Typography>
                        As you have opted to use a third party to run your session, all video will happen on your
                        selected service.
                    </Typography>
                )}
            </Box>
            {!isSessionStarted && absoluteURL && (
                <Button target="_blank" href={absoluteURL} variant="contained" size="large" color="primary">
                    Open Meeting
                </Button>
            )}
            <Box mt={4}>
                <VModule shadow={true}>
                    <Box mb={2}>
                        <Typography>Just in case you need to share it with any participants in your group</Typography>
                    </Box>
                    {absoluteURL && (
                        <Link style={{ wordBreak: "break-all" }} target="_blank" href={absoluteURL}>
                            {absoluteURL}
                        </Link>
                    )}
                </VModule>
            </Box>
        </>
    );
}
