import { Role, RolePermission, User, UserRating } from "common/build/prisma/client";
import { getData, postData } from "./rest";

export const register = async (userDetails: User): Promise<Response> => await postData("/users", userDetails);

export const loginUser = async (email: string, password: string): Promise<Response> =>
    await postData("/auth", { email, password });

export const get = async (): Promise<User & { Role: Role & { RolePermissions: RolePermission[] } }> =>
    await getData("/auth");
export type SetPasswordParams = { token: string; password: string };
export const setPassword = async (params: SetPasswordParams): Promise<Response> =>
    await postData("/set-password/", params);
export const addUserRating = async (params?: Partial<UserRating>): Promise<Response> =>
    await postData("/add-user-rating/", params);
export type InitResetParams = { email: string };
export const initReset = async (params: InitResetParams): Promise<Response> => await postData("/init-reset/", params);
