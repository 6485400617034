import React, { useEffect, useState } from "react";
import { ViewProps } from "../../../../core/components/crud/View";
import { StoryType } from "../../../../models/modelTypes";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { useCrud } from "../../../../core/components/crud/contexts/crud.context";
import { useHistory, useParams } from "react-router-dom";
import Error from "../../../../core/components/Error";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Container,
    createStyles,
    Grid,
    Link,
    makeStyles,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import Breadcrumb from "../../../../core/components/Breadcrumb";
import Spinner from "../../../layout/Spinner";
import { ExpandMore } from "@material-ui/icons";
import { PrimaryButton, SecondaryButton } from "../../../layout/Button/Button";
import { useAuth } from "../../../../contexts/auth.context";
import { VPillPositive } from "../../../../core/components/VPill";
import { useStoryContext } from "../story.context";
import { TabPanel } from "../../../programme/screens/ViewProgramme";
import { date } from "../../../../utils/date";

export const ViewStory: React.FC<ViewProps> = (props: ViewProps): JSX.Element => {
    const { labelProperty } = props;
    const { hasPermission } = useAuth();
    const { item, fetching, fetchItem } = useFetch<StoryType>();
    const { noun } = useCrud<StoryType>();
    const { setCurrentDetails, setCurrentStory, markStoryReviewed, markStoryPublished, loading } = useStoryContext();

    const [error, setError] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState<number>(0);

    const { id } = useParams<{ id: string }>();
    const history = useHistory();

    useEffect(() => {
        if (parseInt(id)) {
            fetchItem(parseInt(id))
                .then(() => {
                    setError(false);
                })
                .catch(() => setError(true));
        }
    }, [id, loading]);

    if (error) {
        return <Error description={`Unable to load ${noun}`} />;
    }

    const handleEdit = () => {
        setCurrentStory(item);
        const storyDetails = {
            ...item,
            numParts: item?.StoryParts?.length ?? 0,
            parts:
                item?.StoryParts?.length && item.StoryParts.length > 0
                    ? item.StoryParts.map((part) => {
                          return {
                              part: part.part,
                              content: part.content,
                              figurativeNotes: part.figurativeNotes,
                              imageUrl: part.imageUrl,
                          };
                      })
                    : [],
            themeIds:
                item?.Themes?.length && item.Themes.length > 0
                    ? item.Themes.map((theme) => {
                          return { id: theme.id };
                      })
                    : [],
        };

        setCurrentDetails(storyDetails);
        history.push("/stories/add");
    };

    const useStyles = makeStyles(() =>
        createStyles({
            accordion: {
                padding: "0 16px 0 16px",
                marginBottom: "16px!important",
                backgroundColor: "rgba(0,0,0,0.05)",
                width: "100%",
            },
            accordionHeader: {
                minHeight: "64px",
            },
            header: {
                backgroundColor: "#F9F9FB",
            },
            textBreak: {
                whiteSpace: "pre-line",
            },
        }),
    );
    const classes = useStyles();

    return (
        <>
            <Box p={3} className={classes.header}>
                <Box mb={2}>
                    <Breadcrumb
                        current={labelProperty && item && item[labelProperty] ? `${item[labelProperty]}` : `${noun}`}
                    />
                </Box>
            </Box>

            {!fetching && item ? (
                <>
                    <Box p={3}>
                        <Container maxWidth={"md"}>
                            <Tabs
                                value={tabValue}
                                onChange={(_event: React.ChangeEvent<unknown>, newValue: number) =>
                                    setTabValue(newValue)
                                }
                            >
                                <Tab label="Details" id="details-tab" />
                                <Tab label="Meta" id="meta-tab" />
                            </Tabs>
                            <TabPanel index={0} value={tabValue}>
                                <Grid container spacing={3}>
                                    <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant={"h4"}>Story Name</Typography>
                                            <Typography>{item.storyName}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Grid item container spacing={1}>
                                                <Grid item>
                                                    {hasPermission("StoryUpdate") && (
                                                        <SecondaryButton onClick={handleEdit}>Edit</SecondaryButton>
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    {hasPermission("StoryReview") && !item.isReviewed && (
                                                        <PrimaryButton onClick={() => markStoryReviewed(item.id)}>
                                                            Approve
                                                        </PrimaryButton>
                                                    )}
                                                </Grid>
                                                <Grid item>
                                                    {hasPermission("StoryPublish") &&
                                                        item.isReviewed &&
                                                        !item.isPublished && (
                                                            <PrimaryButton onClick={() => markStoryPublished(item.id)}>
                                                                Publish
                                                            </PrimaryButton>
                                                        )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant={"h4"}>Author</Typography>
                                        <Typography>{item.author}</Typography>
                                    </Grid>
                                    {item.ageRange && (
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant={"h4"}>Age Range</Typography>
                                            <Typography>{item.ageRange}</Typography>
                                        </Grid>
                                    )}
                                    {item.type && (
                                        <Grid item xs={12} sm={6}>
                                            <Typography variant={"h4"}>Story Type</Typography>
                                            <Typography>
                                                {(item.type as string).replace(/([A-Z])/g, " $1").trim()}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {item.synopsis && (
                                        <Grid item xs={12}>
                                            <Typography variant={"h4"}>Story Synopsis</Typography>
                                            <Typography className={classes.textBreak}>{item.synopsis}</Typography>
                                        </Grid>
                                    )}
                                    {item.overview && (
                                        <Grid item xs={12}>
                                            <Typography variant={"h4"}>Contextual Overview</Typography>
                                            <Typography className={classes.textBreak}>{item.overview}</Typography>
                                        </Grid>
                                    )}
                                    {item.resources && (
                                        <Grid item xs={12}>
                                            <Typography variant={"h4"}>Additional Resources link - NI</Typography>
                                            <Typography
                                                className={classes.textBreak}
                                                component={Link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={item.resources}
                                            >
                                                {item.resources}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {item.resourcesROI && (
                                        <Grid item xs={12}>
                                            <Typography variant={"h4"}>Additional Resources link ROI</Typography>
                                            <Typography
                                                className={classes.textBreak}
                                                component={Link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={item.resourcesROI}
                                            >
                                                {item.resourcesROI}
                                            </Typography>
                                        </Grid>
                                    )}
                                    {item.Themes?.length && item.Themes.length > 0 ? (
                                        <Grid container item xs={12} spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant={"h4"}>Story Themes</Typography>
                                            </Grid>
                                            {item.Themes.map((theme) => (
                                                <Grid item key={theme.id}>
                                                    <VPillPositive label={theme.name} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    ) : null}
                                    {item.StoryParts?.length &&
                                        item.StoryParts.length > 0 &&
                                        item.StoryParts.map((part) => (
                                            <Accordion
                                                className={classes.accordion}
                                                key={part.id}
                                                elevation={0}
                                                defaultExpanded={true}
                                                style={{ padding: "16px" }}
                                            >
                                                <AccordionSummary
                                                    style={{ padding: "0px" }}
                                                    expandIcon={<ExpandMore />}
                                                    className={classes.accordionHeader}
                                                >
                                                    <Typography variant="h4">Story Part {part.part + 1}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ height: "100%", padding: "0px" }}>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">Content</Typography>
                                                            <Typography className={classes.textBreak}>
                                                                {part.content}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">Figurative Notes</Typography>
                                                            <Typography className={classes.textBreak}>
                                                                {part.figurativeNotes}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6">Image URL</Typography>
                                                            <Typography className={classes.textBreak}>
                                                                {part.imageUrl ? part.imageUrl : "None"}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                </Grid>
                            </TabPanel>
                            <TabPanel index={1} value={tabValue}>
                                <Grid container spacing={3}>
                                    {item?.CreateUser?.firstName ? (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6">Created By</Typography>
                                            <Typography>{`${item?.CreateUser?.firstName} ${
                                                item?.CreateUser?.lastName ?? ""
                                            } ${
                                                item?.CreateUser?.email ? `/ ${item.CreateUser.email}` : ""
                                            }`}</Typography>
                                        </Grid>
                                    ) : null}

                                    {item?.createdDate ? (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6">Created on</Typography>
                                            <Typography>{date(item?.createdDate)}</Typography>
                                        </Grid>
                                    ) : null}

                                    {item?.ReviewUser?.firstName ? (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6">Reviewed By</Typography>
                                            <Typography>{`${item?.ReviewUser?.firstName} ${
                                                item?.ReviewUser?.lastName ?? ""
                                            } ${
                                                item?.ReviewUser?.email ? `/ ${item.ReviewUser.email}` : ""
                                            }`}</Typography>
                                        </Grid>
                                    ) : null}

                                    {item?.reviewedDate ? (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6">Reviewed on</Typography>
                                            <Typography>{date(item?.reviewedDate)}</Typography>
                                        </Grid>
                                    ) : null}

                                    {item?.PublishUser?.firstName ? (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6">Published By</Typography>
                                            <Typography>{`${item?.PublishUser?.firstName} ${
                                                item?.PublishUser?.lastName ?? ""
                                            } ${
                                                item?.PublishUser?.email ? `/ ${item.PublishUser.email}` : ""
                                            }`}</Typography>
                                        </Grid>
                                    ) : null}

                                    {item?.publishedDate ? (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6">Published on</Typography>
                                            <Typography>{date(item?.publishedDate)}</Typography>
                                        </Grid>
                                    ) : null}
                                </Grid>
                            </TabPanel>
                        </Container>
                    </Box>
                </>
            ) : (
                <Spinner />
            )}
        </>
    );
};
