import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
    Backdrop,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fade,
    Grid,
    Modal,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import { ContentEditor } from "../../common/ContentEditor";
import { useStory } from "../../../hooks/useStory";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { FacilitatorStoryPartType, SessionType, WeekType } from "../../../models/modelTypes";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { Breadcrumb } from "../../../core/components/Breadcrumb";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import Spinner from "../../layout/Spinner";
import { Error } from "../../../core/components/Error";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { publicTheme } from "../../layout/themes/public.theme";
import { TabPanel } from "../../programme/screens/ViewProgramme";
import { useMobile } from "../../../hooks/useMobile";
import { PrepareQuestionModule } from "./questions/PrepareQuestionModule";
import { Alert, Skeleton } from "@material-ui/lab";
import { VTooltip } from "../../../core/components/VTooltip";
import { ExpandMore } from "@material-ui/icons";
import { PrimaryButton } from "../../layout/Button/Button";
import { StorySynopsis } from "../components/StorySynopsis";
import { FileViewer } from "../../common/FileViewer";
import { useAuth } from "../../../contexts/auth.context";
import { useSetTourState, useTrackedTourState } from "../../tour/Store";

function extractGDFolderId(url: string) {
    // ?<= ensures it's a google drive url, then takes characters from after /folders until the ?
    const regex = new RegExp("(?<=https://drive.google.com/drive/folders/)[^\\?]*(?!\\?)?");
    const match = regex.exec(url);
    if (match) {
        return match[0];
    } else {
        return undefined;
    }
}

type PrepareStoryProps = {
    classroomId?: number;
};

export const PrepareStory: React.FC<PrepareStoryProps> = (props: PrepareStoryProps): JSX.Element => {
    const { classroomId } = props;
    const { item, fetchItem } = useFetch<SessionType>();
    const { isMobile, isTablet } = useMobile();
    const { user } = useAuth();
    const { facilitatorStoryParts, getFacilitatorStoryParts, addFacilitatorStoryPart } = useStory();
    const setTourState = useSetTourState();
    const tourState = useTrackedTourState();

    const [content, setContent] = useState<Map<number, string>>(new Map());
    const [saving, setSaving] = useState<boolean>(false);
    const [isEdited, setIsEdited] = useState<boolean>(false);
    const [confirmCancel, setConfirmCancel] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingImagePreviews, setLoadingImagePreviews] = useState<boolean[]>();
    const [error, setError] = useState<boolean>(false);
    const [isEditorLoaded, setIsEditorLoaded] = useState<boolean>(false);
    const [isQuestionsOpen, setIsQuestionsOpen] = useState<Map<number, boolean>>(new Map());
    const [week, setWeek] = useState<WeekType>();
    const [driveFolderId, setDriveFolderId] = useState<string>();

    const [accordionOpen, setAccordionOpen] = useState<Map<number, boolean>>(new Map());
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [storyTooltipOpen, setStoryTooltipOpen] = useState<boolean>(false);

    // Tabs
    const [tabValue, setTabValue] = useState<number>(0);
    const partRefs = useRef<HTMLDivElement[]>([]);

    const refStoryTab = useRef<HTMLDivElement | null>(null);
    const refSynopsisTab = useRef<HTMLDivElement | null>(null);
    const refResourcesTab = useRef<HTMLDivElement | null>(null);
    const refShowQuestions = useRef<HTMLButtonElement | null>(null);
    const refHideQuestions = useRef<HTMLButtonElement | null>(null);

    const { id } = useParams<{ id?: string }>();
    const history = useHistory();

    const [openModalImagePreview, setOpenModalImagePreview] = useState(false);
    const [modalImage, setModalImage] = useState("false");

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("prepareStoryTab", refStoryTab) }));
    }, [refStoryTab]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("prepareSynopsisTab", refSynopsisTab) }));
    }, [refSynopsisTab]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("prepareResourcesTab", refResourcesTab) }));
    }, [refResourcesTab]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("prepareShowQuestions", refShowQuestions) }));
    }, [refShowQuestions]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("prepareHideQuestions", refHideQuestions) }));
    }, [refHideQuestions]);

    useEffect(() => {
        if (id) {
            setLoading(true);
            fetchItem(parseInt(id))
                .then(() => {
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError(true);
        }
    }, [id]);

    useEffect(() => {
        if (item && item.MappedStory && item.MappedStory.storyId) {
            getFacilitatorStoryParts(item.MappedStory.storyId);
        }

        if (
            item &&
            item.MappedStory &&
            item.MappedStory.StoryCollection &&
            item.MappedStory.StoryCollection.Weeks &&
            item.MappedStory.StoryCollection.Weeks.length
        ) {
            const currentWeek = item.MappedStory.StoryCollection.Weeks.filter(
                (week) => week.order === item.MappedStory?.order,
            )[0];
            if (currentWeek) setWeek(currentWeek);
        }
    }, [item]);

    useEffect(() => {
        if (user?.Organisation?.country === "Republic of Ireland") {
            if (item?.MappedStory?.Story?.resourcesROI) {
                setDriveFolderId(extractGDFolderId(item?.MappedStory?.Story?.resourcesROI));
            }
        } else {
            if (item?.MappedStory?.Story?.resources) {
                setDriveFolderId(extractGDFolderId(item?.MappedStory?.Story?.resources));
            }
        }
    }, [item?.MappedStory?.Story, user?.Organisation?.country]);

    // Needed as TinyMCE editor isn't activating if only one part in story
    // This will manually set it to loaded if the story has one part
    // To stop Spinner from showing continuously
    useEffect(() => {
        if (facilitatorStoryParts && facilitatorStoryParts.length === 1) {
            setIsEditorLoaded(true);
        }

        if (facilitatorStoryParts && facilitatorStoryParts.length > 0) {
            for (const part of facilitatorStoryParts) {
                setIsQuestionsOpen(new Map(isQuestionsOpen.set(part.id, true)));
            }
        }

        const arrayLength = facilitatorStoryParts ? facilitatorStoryParts.length : 0;
        const loadingImagePreviewArray: boolean[] = [...Array<boolean>(arrayLength)].fill(true);
        setLoadingImagePreviews(loadingImagePreviewArray);
    }, [facilitatorStoryParts]);

    // Open tooltip temporarily when editor is loaded to inform user they can use the text editing tools.
    // Closes tooltip after 5 seconds
    useEffect(() => {
        if (isEditorLoaded && !tourState.openTour) {
            setTooltipOpen(true);

            const timeout = setTimeout(() => {
                setTooltipOpen(false);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [isEditorLoaded, tourState.openTour]);

    useEffect(() => {
        if (item && !loading && !tourState.openTour) {
            setStoryTooltipOpen(true);

            const timeout = setTimeout(() => {
                setStoryTooltipOpen(false);
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [item, loading, tourState.openTour]);

    useEffect(() => {
        if (tabValue !== 0) {
            setStoryTooltipOpen(false);
        }
    }, [tabValue]);

    const handleEditorChange = (id: number, newContent: string) => {
        setContent(new Map(content?.set(id, newContent)));
    };

    const handleSaveAnnotation = () => {
        if (content) {
            setSaving(true);
            content.forEach((value: string, key: number) => {
                const facilitatorStory = {
                    html: value,
                    mappedStoryPartId: key,
                } as Partial<FacilitatorStoryPartType>;
                addFacilitatorStoryPart(facilitatorStory);
            });
            setSaving(false);
            if (classroomId) {
                history.push(`/classrooms/view/${classroomId}/view/${item?.programmeId}`);
            } else {
                history.push(`/programmes/view/${item?.programmeId}`);
            }
        }
    };

    const handleCancel = () => {
        if (isEdited) {
            setConfirmCancel(true);
        } else {
            if (classroomId) {
                history.push(`/classrooms/view/${classroomId}/view/${item?.programmeId}`);
            } else {
                history.push(`/programmes/view/${item?.programmeId}`);
            }
        }
    };

    const handleLoadingImagePreviews = (index: number, value: boolean) => {
        if (loadingImagePreviews) {
            const newLoadingImagePreview = [...loadingImagePreviews];
            newLoadingImagePreview[index] = value;
            setLoadingImagePreviews(newLoadingImagePreview);
        }
    };

    const handleModalImagePreviewClose = () => {
        setOpenModalImagePreview(false);
    };

    const handleModalImage = (value: string) => {
        setModalImage(value);
        setOpenModalImagePreview(true);
    };

    const useStyles = makeStyles(
        createStyles({
            accordion: {
                padding: "0 16px 0 16px",
                marginBottom: "16px!important",
                backgroundColor: "rgba(0,0,0,0.05)",
            },
            accordionHeader: {
                minHeight: "64px",
            },
            gridRowItem: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            prepareLayout: {
                display: "grid",
                gridTemplateColumns: "auto",
            },
            pill: {
                backgroundColor: publicTheme.palette.themePill.main,
                color: verbalTheme.palette.common.white,
            },
            content: {
                padding: 0,
                margin: 0,
            },
            resourceLink: {
                color: verbalTheme.palette.primary.main,
                textDecoration: "none",
            },
            modal: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                "&:hover": {
                    backgroundcolor: "red",
                },
            },
            fadeImg: {
                outline: "none",
            },
        }),
    );

    const classes = useStyles();

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error description={`Unable to load session`} />;
    }

    return (
        <div className={classes.prepareLayout}>
            {!isTablet && isMobile && <div style={{ marginTop: 56 }} />}
            <Box pt={!isTablet && isMobile ? 5 : 4} p={isMobile ? 0 : 4}>
                <Container maxWidth={"xl"} style={isMobile ? { paddingLeft: 0, paddingRight: 0 } : undefined}>
                    <Box data-tour="prep-session-bc" mb={2} pl={isTablet || isMobile ? 2 : 0}>
                        <Breadcrumb
                            crumbs={[
                                {
                                    name: "Programme",
                                    url: `${
                                        classroomId
                                            ? `/classrooms/view/${classroomId}/view/${item?.programmeId}`
                                            : `/programmes/view/${item?.programmeId}`
                                    }`,
                                },
                            ]}
                            current={"Annotate Story"}
                        />
                    </Box>

                    <Box mb={2} px={isMobile ? 2 : 0}>
                        <Typography data-tour="prep-story-name" variant="h1">
                            {item?.MappedStory?.Story?.storyName}
                        </Typography>
                    </Box>

                    <Tabs
                        textColor="primary"
                        indicatorColor="secondary"
                        value={tabValue}
                        onChange={(_event: React.ChangeEvent<unknown>, newValue: number) => setTabValue(newValue)}
                    >
                        <Tab ref={refSynopsisTab} data-tour="prep-synopsis-tab" label="Synopsis" id="synopsis-tab" />
                        <VTooltip
                            title={"You can click on this tab to view the story and questions"}
                            placement="bottom-start"
                            open={!tourState.openTour && storyTooltipOpen}
                        >
                            <Tab ref={refStoryTab} data-tour="prep-story-tab" label="Story" id="story-tab" />
                        </VTooltip>
                        <Tab
                            ref={refResourcesTab}
                            data-tour="prep-learn-tab"
                            label="Learning Resources"
                            id="bonus-tab"
                        />
                    </Tabs>

                    <TabPanel padding={0} index={0} value={tabValue}>
                        <StorySynopsis story={item?.MappedStory} week={week} />
                    </TabPanel>

                    <TabPanel padding={0} index={1} value={tabValue}>
                        {!isEditorLoaded && (
                            <Box display="flex" justifyContent={"center"}>
                                <Spinner />
                            </Box>
                        )}
                        {facilitatorStoryParts?.map((part, index) => {
                            if (part.html) {
                                return (
                                    <div
                                        ref={(el) => {
                                            if (el !== null && !partRefs.current.includes(el)) {
                                                partRefs.current.push(el);
                                            }
                                        }}
                                        key={part.id}
                                        style={
                                            isEditorLoaded
                                                ? { visibility: "visible", marginTop: 5 }
                                                : { visibility: "hidden" }
                                        }
                                    >
                                        <Accordion
                                            className={classes.accordion}
                                            expanded={accordionOpen.get(part.id) ?? true}
                                            onChange={(
                                                _event: ChangeEvent<Record<string, unknown>>,
                                                isExpanded: boolean,
                                            ) => setAccordionOpen(new Map(accordionOpen.set(part.id, isExpanded)))}
                                            elevation={0}
                                        >
                                            <AccordionSummary
                                                style={{ padding: "0px" }}
                                                className={classes.accordionHeader}
                                                expandIcon={
                                                    <ExpandMore
                                                        data-tour={index === 0 ? "prep-part-accord" : undefined}
                                                    />
                                                }
                                            >
                                                <Typography data-tour={index === 0 ? "prep-story-part-num" : undefined}>
                                                    <strong>Part {index + 1}</strong> of {facilitatorStoryParts?.length}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{
                                                    height: "100%",
                                                    padding: "0px",
                                                    paddingBottom: verbalTheme.spacing(2),
                                                }}
                                            >
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Alert
                                                            data-tour={index === 0 ? "prep-story-alert" : undefined}
                                                            severity="warning"
                                                            variant="filled"
                                                        >
                                                            Please read through <strong>part {index + 1}</strong> before
                                                            reviewing the <strong>part {index + 1} questions</strong>
                                                        </Alert>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={isQuestionsOpen.get(part.id) ? 6 : 10}
                                                    >
                                                        <VTooltip
                                                            title={
                                                                index === 0
                                                                    ? "You can use the text tools to highlight, bold, underline and/or italicise the text of the story to help you prepare"
                                                                    : ""
                                                            }
                                                            placement="top-start"
                                                            open={!tourState.openTour && tooltipOpen}
                                                        >
                                                            <span
                                                                data-tour={
                                                                    index === 0 ? "prep-story-content" : undefined
                                                                }
                                                            >
                                                                <ContentEditor
                                                                    setIsEditorLoaded={setIsEditorLoaded}
                                                                    setIsEdited={setIsEdited}
                                                                    content={content}
                                                                    setContent={handleEditorChange}
                                                                    initialContent={part.html}
                                                                    mappedStoryPartId={part.mappedStoryPartId}
                                                                    id={`prepare-part-${index + 1}`}
                                                                />
                                                            </span>
                                                        </VTooltip>

                                                        {!isMobile ? (
                                                            <Typography color="error">
                                                                <strong>End of part {index + 1}</strong> go to show part{" "}
                                                                {index + 1} questions {">>>"}
                                                            </Typography>
                                                        ) : null}
                                                    </Grid>
                                                    <Grid item sm={12} md={isQuestionsOpen.get(part.id) ? 6 : 2}>
                                                        {isQuestionsOpen.get(part.id) || isMobile ? (
                                                            <>
                                                                <PrepareQuestionModule
                                                                    part={part.MappedStoryPart}
                                                                    mappedStoryId={item?.MappedStory?.id}
                                                                />
                                                                {!isMobile ? (
                                                                    <Box mt={3}>
                                                                        <VTooltip
                                                                            title={`Hide Part ${index + 1} Questions`}
                                                                        >
                                                                            <PrimaryButton
                                                                                ref={
                                                                                    index === 0
                                                                                        ? refHideQuestions
                                                                                        : undefined
                                                                                }
                                                                                onClick={() =>
                                                                                    setIsQuestionsOpen(
                                                                                        new Map(
                                                                                            isQuestionsOpen.set(
                                                                                                part.id,
                                                                                                false,
                                                                                            ),
                                                                                        ),
                                                                                    )
                                                                                }
                                                                            >
                                                                                Hide part {index + 1} questions
                                                                            </PrimaryButton>
                                                                        </VTooltip>
                                                                    </Box>
                                                                ) : null}
                                                            </>
                                                        ) : (
                                                            <Box
                                                                display="flex"
                                                                flexDirection={"column"}
                                                                justifyContent={"space-between"}
                                                                height={"100%"}
                                                            >
                                                                <Box width={"100%"} mt={1}>
                                                                    <VTooltip
                                                                        title={`Show Part ${index + 1} Questions`}
                                                                    >
                                                                        <Button
                                                                            color="primary"
                                                                            variant="contained"
                                                                            ref={
                                                                                index === 0
                                                                                    ? refShowQuestions
                                                                                    : undefined
                                                                            }
                                                                            data-tour={
                                                                                index === 0
                                                                                    ? "prep-question-show"
                                                                                    : undefined
                                                                            }
                                                                            onClick={() =>
                                                                                setIsQuestionsOpen(
                                                                                    new Map(
                                                                                        isQuestionsOpen.set(
                                                                                            part.id,
                                                                                            true,
                                                                                        ),
                                                                                    ),
                                                                                )
                                                                            }
                                                                        >
                                                                            Show part {index + 1} questions
                                                                        </Button>
                                                                    </VTooltip>
                                                                </Box>
                                                                {part.MappedStoryPart?.imageUrl && (
                                                                    <Box width={"100%"} mt={5} sx={{ flexGrow: 1 }}>
                                                                        <div
                                                                            style={{
                                                                                display: loadingImagePreviews?.[index]
                                                                                    ? "block"
                                                                                    : "none",
                                                                            }}
                                                                        >
                                                                            <Skeleton
                                                                                variant="rect"
                                                                                animation="wave"
                                                                                width={"100%"}
                                                                                height={168}
                                                                            />
                                                                        </div>
                                                                        <div
                                                                            style={{
                                                                                display: loadingImagePreviews?.[index]
                                                                                    ? "none"
                                                                                    : "block",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={part.MappedStoryPart?.imageUrl}
                                                                                width={"100%"}
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() =>
                                                                                    handleModalImage(
                                                                                        part.MappedStoryPart
                                                                                            ?.imageUrl as string,
                                                                                    )
                                                                                }
                                                                                onLoad={() => {
                                                                                    handleLoadingImagePreviews(
                                                                                        index,
                                                                                        false,
                                                                                    );
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        <Typography align="center">
                                                                            Image Preview
                                                                        </Typography>
                                                                    </Box>
                                                                )}
                                                                <Box width={"100%"} mt={1}>
                                                                    <VTooltip
                                                                        title={`Show Part ${index + 1} Questions`}
                                                                    >
                                                                        <PrimaryButton
                                                                            // ref={
                                                                            //     index === 0
                                                                            //         ? refShowQuestions
                                                                            //         : undefined
                                                                            // }
                                                                            onClick={() => {
                                                                                setIsQuestionsOpen(
                                                                                    new Map(
                                                                                        isQuestionsOpen.set(
                                                                                            part.id,
                                                                                            true,
                                                                                        ),
                                                                                    ),
                                                                                );
                                                                                partRefs.current[index] &&
                                                                                    partRefs.current[
                                                                                        index
                                                                                    ].scrollIntoView({
                                                                                        behavior: "smooth",
                                                                                    });
                                                                            }}
                                                                        >
                                                                            Show part {index + 1} questions
                                                                        </PrimaryButton>
                                                                    </VTooltip>
                                                                </Box>
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                );
                            }
                        })}
                        <Modal
                            className={classes.modal}
                            open={openModalImagePreview}
                            onClose={handleModalImagePreviewClose}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={openModalImagePreview} timeout={500}>
                                <img
                                    src={modalImage}
                                    key={modalImage}
                                    style={{
                                        maxHeight: "90%",
                                        maxWidth: "90%",
                                    }}
                                    className={classes.fadeImg}
                                />
                            </Fade>
                        </Modal>
                        {isEditorLoaded && (
                            <Box mb={2} data-tour="prepare-story-save">
                                <Grid container justifyContent={"space-between"}>
                                    <Grid item>
                                        <Button variant="contained" size="large" onClick={handleCancel}>
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="secondary"
                                            disabled={saving}
                                            onClick={handleSaveAnnotation}
                                        >
                                            Save Annotations
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </TabPanel>
                    <TabPanel padding={0} index={2} value={tabValue}>
                        <FileViewer
                            url={
                                driveFolderId
                                    ? `https://drive.google.com/embeddedfolderview?id=${driveFolderId}#list`
                                    : undefined
                            }
                            title={item?.MappedStory?.Story?.storyName}
                            noneMessage="No bonus content with this story"
                        />
                    </TabPanel>
                </Container>

                <Dialog open={confirmCancel} onClose={() => setConfirmCancel(false)}>
                    <DialogTitle>Cancel Story Annotations?</DialogTitle>
                    <DialogContent>
                        <Typography>
                            You have annotated the story. Are you sure you want to cancel and discard your annotations?
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            size="large"
                            color="secondary"
                            onClick={() => setConfirmCancel(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={() => history.push(`/programmes/view/${item?.programmeId}`)}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </div>
    );
};
