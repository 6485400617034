import { Box, Button, CircularProgress, Container, Grid, TextField, Typography } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../core/components/Breadcrumb";
import { useCrud } from "../../../core/components/crud/contexts/crud.context";
import { getValidationSchema } from "../../../core/components/crud/Add";
import * as yup from "yup";

export const AddTheme = <T extends Record<string, unknown>>(): JSX.Element => {
    const { add, model } = useCrud<T>();
    const { goBack } = useHistory();

    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>();

    const onSave = (values: T, formikHelpers: FormikHelpers<T & { [key: string]: string | number }>) => {
        const { setSubmitting } = formikHelpers;
        setLoading(true);
        add(values)
            .then(() => {
                setError(undefined);
                goBack();
            })
            .catch((err: string) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                setSubmitting(false);
            });
    };

    return (
        <Box p={4}>
            <Container maxWidth={"md"}>
                <Box mb={"3rem"}>
                    <Box mb={3}>
                        <Breadcrumb current={`Add new theme`} />
                    </Box>
                    <Typography variant="h2" color="textPrimary" align="left">
                        {`Add new Theme`}
                    </Typography>
                </Box>
                <Formik
                    initialValues={{} as T & { [key: string]: string | number }}
                    onSubmit={onSave}
                    validationSchema={yup.object(getValidationSchema<T>(model))}
                    validateOnChange={false}
                >
                    {({ submitForm, handleChange, isSubmitting, errors }) => {
                        return (
                            <>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {Object.entries(model)
                                        .filter(([, { inForm = true }]) => inForm)
                                        .map(([property, { label = `${property[0].toUpperCase() + property
                                                        .substring(1)
                                                        .toLowerCase()}`, InputComponent = TextField, inputProps = {} }], index) => {
                                            return (
                                                <Grid key={index} item xs={12}>
                                                    <InputComponent
                                                        fullWidth={true}
                                                        multiline
                                                        id={property}
                                                        label={label}
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        helperText={errors[property] && errors[property]}
                                                        error={errors[property] ? true : false}
                                                        {...(index === 0 && InputComponent === TextField
                                                            ? { autoFocus: true }
                                                            : undefined)}
                                                        {...inputProps}
                                                    />
                                                </Grid>
                                            );
                                        })}

                                    <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                        Input fields marked with an asterisk (*) are required
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        goBack();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={submitForm}
                                                >
                                                    {!loading ? "Save" : <CircularProgress size={30} />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {error && (
                                        <Grid item xs={12}>
                                            <Typography color="error">{error}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        );
                    }}
                </Formik>
            </Container>
        </Box>
    );
};
