import { Container } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect, ReactElement } from "react";
import { useHistory, useParams } from "react-router-dom";
import { VSwitch } from "../../../core/components/VSwitch";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { SessionType } from "../../../models/modelTypes";
import { JitsiVideoConference } from "../sessionMode/meeting/JitsiVideoConference";
import Spinner from "../../layout/Spinner";
import { useParticipantAuth } from "../../../contexts/participant-auth.context";
import { ReadStory } from "../sessionMode/wizard/steps/ReadStory";
import { API_URL } from "../../../client/api/rest";
import { useSessionMode } from "../sessionMode/contexts/sessionMode.context";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { useStoryStyles } from "../../../hooks/styles/StoryStyles";
import { Survey, SurveyType } from "../sessionMode/wizard/steps/Survey";
import { SessionFetchParameters } from "../../../../../common/src/api-parameters/session";
import { Error } from "../../../core/components/Error";
import { ExternalVideoConference } from "../sessionMode/meeting/ExternalVideoConference";
import { ConferenceProviderType } from "common/build/programmes/conferenceProviderType";

enum OnlineSessionView {
    Video = "Video",
    Story = "Story",
}

interface ParticipantSessionProps {
    isOnline?: boolean;
}

export const ParticipantOnlineSession: React.FC<ParticipantSessionProps> = (props: ParticipantSessionProps) => {
    const { isOnline = false } = props;
    const { item, fetchItem } = useFetch<SessionType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [view, setView] = useState<OnlineSessionView>(isOnline ? OnlineSessionView.Video : OnlineSessionView.Story);
    const [conference, setConference] = useState<ReactElement>();
    const [showSurvey, setShowSurvey] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [surveyDone, setSurveyDone] = useState<boolean>(false);

    const { participantId, sessionId } = useParams<{ participantId?: string; sessionId?: string }>();

    const { changePart } = useSessionMode();

    const history = useHistory();

    const useStyles = makeStyles(
        createStyles({
            sessionModeLayout: {
                height: "calc(100vh - 153px)",
                display: isOnline ? "flex" : "block",
                [verbalTheme.breakpoints.up("md")]: {
                    display: "grid",
                    gridTemplateColumns: isOnline ? (surveyDone ? "auto 75vw" : "auto 400px") : "auto",
                    height: "calc(100vh - 81px)",
                },
            },
            storyPanel: {
                paddingBottom: verbalTheme.spacing(4),
                overflow: "auto",
                width: "100%",
                display: view === OnlineSessionView.Video ? "none" : "block",
                [verbalTheme.breakpoints.up("md")]: {
                    display: "block",
                },
            },
            videoPanel: {
                display: view === OnlineSessionView.Story ? "none" : "flex",
                overflow: "hidden",
                [verbalTheme.breakpoints.up("md")]: {
                    display: "block",
                },
                position: "relative",
                backgroundColor: item?.providerType === "BuiltIn" ? "black" : "#F9F9FB",
                color: item?.providerType === "BuiltIn" ? "white" : "inherit",
                width: "100%",
            },
            viewSwitch: {
                padding: verbalTheme.spacing(3),
                paddingTop: verbalTheme.spacing(0),
                minHeight: "72px",
                [verbalTheme.breakpoints.up("md")]: {
                    display: "none",
                },
            },
        }),
    );

    const classes = useStyles();

    const { user } = useParticipantAuth();

    useEffect(() => {
        if (sessionId && parseInt(sessionId) > 0) {
            setLoading(true);
            fetchItem(parseInt(sessionId), { includeStory: true } as SessionFetchParameters)
                .then(() => {
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError(true);
        }
    }, [sessionId]);

    useEffect(() => {
        if (item) {
            changePart(item.currentPart);
            setShowSurvey(item.isSurveyInProgress);

            const events = new EventSource(`${API_URL}/session/${item.id}/updates`, {
                withCredentials: true,
            });

            events.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (parseInt(data.part) >= 0) {
                    changePart(data.part);
                }

                if (data.isSurveyInProgress) {
                    setShowSurvey(true);
                }

                if (data.state === "Complete") {
                    history.push(`/participant/dashboard`);
                }
            };

            return () => {
                events.close();
            };
        }
    }, [item]);

    const storyClasses = useStoryStyles();
    const participantVideoConfig = {
        DEFAULT_REMOTE_DISPLAY_NAME: "Verbal Guest",
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISPLAY_WELCOME_PAGE_CONTENT: false,
        MOBILE_APP_PROMO: false,
        SHOW_JITSI_WATERMARK: false,
        SHOW_WATERMARK_FOR_GUESTS: false,
        HIDE_DEEP_LINKING_LOGO: true,
        HIDE_INVITE_MORE_HEADER: true,
        RECENT_LIST_ENABLED: false,
        TILE_VIEW_MAX_COLUMNS: 2,
        TOOLBAR_BUTTONS: [
            "microphone",
            "camera",
            "closedcaptions",
            "fullscreen",
            "fodeviceselection",
            "profile",
            "chat",
            "etherpad",
            "settings",
            "raisehand",
            "videoquality",
            "shortcuts",
            "download",
            "mute-everyone",
        ],
    };

    useEffect(() => {
        if (isOnline && item && item.guid) {
            setConference(
                item.providerType === "BuiltIn" || !item.providerURL ? (
                    <JitsiVideoConference
                        user={user}
                        roomName={item?.guid ?? sessionId}
                        configuration={participantVideoConfig}
                    />
                ) : (
                    <ExternalVideoConference
                        provider={ConferenceProviderType[item.providerType]}
                        isSessionStarted={item.state !== "NotStarted" && item.isUsersAdmitted}
                        isParticipant={true}
                        providerURL={item.providerURL}
                    ></ExternalVideoConference>
                ),
            );
        }
    }, [item]);

    if (loading) {
        return <Spinner />;
    }

    if (error || !participantId) {
        return <Error description={`Unable to load session`} />;
    }

    return (
        <>
            {isOnline && (
                <div className={classes.viewSwitch}>
                    <VSwitch
                        id="isOnline"
                        value={view}
                        exclusive
                        onChange={(_: React.MouseEvent<HTMLElement>, value: string) =>
                            setView(
                                value === OnlineSessionView.Video ? OnlineSessionView.Video : OnlineSessionView.Story,
                            )
                        }
                        options={[
                            { name: "Video", value: OnlineSessionView.Video },
                            { name: showSurvey ? "Survey" : "Story", value: OnlineSessionView.Story },
                        ]}
                        segmented
                    />
                </div>
            )}
            <div className={classes.sessionModeLayout}>
                <div className={classes.storyPanel}>
                    <Container className={storyClasses.storyContainer}>
                        {!showSurvey ? (
                            <ReadStory session={item} />
                        ) : (
                            <Survey
                                type={SurveyType.Participant}
                                session={item}
                                participantId={parseInt(participantId)}
                                setSurveyDone={setSurveyDone}
                            />
                        )}
                    </Container>
                </div>
                {isOnline && <div className={classes.videoPanel}>{conference}</div>}
            </div>
        </>
    );
};
