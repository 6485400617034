import { node } from "prop-types";
import React, { ReactNode, useCallback, useEffect, useState } from "react";
import { Organisation, User } from "common/build/prisma/client";
import { useIntQueryStringState, useQueryStringState } from "../../../../hooks/useQueryString";
import { Product } from "../entities/Products";
import { getProducts } from "../../../../client/api/public/products";

export enum CreateOrganisationFlowStages {
    credentials = "credentials",
    details = "details",
}

interface OnboardingContextI {
    activeStep: number;
    stage: CreateOrganisationFlowStages;
    completedSteps: { [k: number]: boolean };
    details: Partial<Organisation & User>;
    plans: Product[];
    plan: Product | undefined;

    changeStep: (newStep: number) => void;
    planStep: (details: Product) => void;
    submitStep: (details: Partial<Organisation & User>) => void;
    signUp: (details: Partial<Organisation & User>) => void;
    confirmStep: () => void;
}

const OnboardingContext = React.createContext({} as OnboardingContextI);

export const OnboardingProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [stage, setStage] = useQueryStringState("stage", CreateOrganisationFlowStages.credentials);
    const [activeStep, setActiveStep] = useIntQueryStringState("step", 0);
    const [completedSteps, setCompletedSteps] = React.useState<{ [k: number]: boolean }>({});
    const [details, setDetails] = useState<Partial<Organisation & User>>({
        firstName: "",
        lastName: "",
        name: "",
        orgType: null,
        email: "",
        password: "",
        phoneNo: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        country: "",
        postcode: "",
    });
    const [plans, setPlans] = useState<Product[]>([]);
    const [plan, setPlan] = useState<Product>();

    const changeStep = useCallback((newStep: number) => {
        setActiveStep(newStep);
    }, []);

    const submitStep = useCallback(
        async (details: Partial<Organisation & User>) => {
            setDetails((prevDetails) => ({ ...prevDetails, ...details }));
            setCompletedSteps((prevCompletedSteps) => ({ ...prevCompletedSteps, [activeStep]: true }));
        },
        [activeStep],
    );

    const confirmStep = useCallback(async () => {
        setCompletedSteps((prevCompletedSteps) => ({ ...prevCompletedSteps, [activeStep]: true }));
        const products = await getProducts();
        setPlans(products);
    }, [activeStep]);

    const planStep = useCallback(
        async (details: Product) => {
            setPlan(details);
            setCompletedSteps((prevCompletedSteps) => ({ ...prevCompletedSteps, [activeStep]: true }));
        },
        [activeStep],
    );

    const signUp = useCallback(
        (details: Partial<Organisation & User>) => {
            setDetails((prevDetails) => ({ ...prevDetails, ...details }));
            setStage(CreateOrganisationFlowStages.details);
        },
        [setStage],
    );

    useEffect(() => {
        if (!details.email || !details.password) {
            setStage(CreateOrganisationFlowStages.credentials);
        }
    }, [details.email, details.password, setStage]);

    useEffect(() => {
        if (stage === CreateOrganisationFlowStages.credentials) {
            setActiveStep(0);
        }
    }, [stage, setActiveStep]);

    return (
        <OnboardingContext.Provider
            value={{
                activeStep,
                completedSteps,
                details,
                changeStep,
                submitStep,
                confirmStep,
                signUp,
                planStep,
                stage: stage as CreateOrganisationFlowStages,
                plans,
                plan,
            }}
        >
            {children}
        </OnboardingContext.Provider>
    );
};

OnboardingProvider.propTypes = {
    children: node,
};

export const useOnboarding = (): OnboardingContextI => React.useContext<OnboardingContextI>(OnboardingContext);
