import { Box, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../core/components/Breadcrumb";
import { useCollection } from "../../hooks/useCollection";
import { useMobile } from "../../hooks/useMobile";
import { useTopic } from "../../hooks/useTopic";
import Spinner from "../layout/Spinner";
import { getColourClass } from "./BrowseHome";
import { useHomeStyles } from "./Home.style";
import { HomeViewItem } from "./HomeViewItem";

export const ViewCollectionTopic = (): JSX.Element => {
    const { id, topicId } = useParams<{ id: string; topicId: string }>();
    const { isMobile } = useMobile();
    const { topic, getTopic } = useTopic();
    const { collection, getCollection } = useCollection();
    const history = useHistory();

    useEffect(() => {
        if (parseInt(topicId)) {
            getTopic(parseInt(topicId));
        }
    }, [topicId]);

    useEffect(() => {
        if (parseInt(id)) {
            getCollection(parseInt(id));
        }
    }, [id]);

    const classes = useHomeStyles();

    return (
        <Box display={"flex"}>
            {topic && collection ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {isMobile && <div className={classes.toolbar} />}
                        <Box pl={4} pt={isMobile ? 4 : 2}>
                            <Breadcrumb
                                crumbs={[
                                    { name: "Home", url: "/home" },
                                    {
                                        name: collection.name,
                                        url: `/home/collection/${id}`,
                                    },
                                ]}
                                current={topic.name}
                            />
                        </Box>
                        <HomeViewItem title={topic.name} description={topic.description} colour={topic.colour} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box bgcolor="secondaryShade.main" width={1} px={2}>
                            <Typography>Story Collections</Typography>
                            <ImageList gap={10} cols={isMobile ? 2 : 4}>
                                {topic.StoryCollection &&
                                    topic.StoryCollection.length > 0 &&
                                    topic.StoryCollection.map((item) => (
                                        <ImageListItem key={item.id}>
                                            <Box
                                                minHeight="100%"
                                                minWidth="100%"
                                                bgcolor={`${getColourClass({ colour: item.colour })}`}
                                                onClick={() => history.push(`/home/collection/${item.id}`)}
                                                style={{ cursor: "pointer" }}
                                            />
                                            <ImageListItemBar
                                                className={classes.titleBarBottom}
                                                title={
                                                    <Box>
                                                        <Typography style={{ color: "white", whiteSpace: "pre-line" }}>
                                                            {item.name}
                                                        </Typography>
                                                    </Box>
                                                }
                                                position="bottom"
                                            />
                                        </ImageListItem>
                                    ))}
                            </ImageList>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <Spinner />
            )}
        </Box>
    );
};
