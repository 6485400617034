import { Box, LinearProgress, Paper, Typography } from "@material-ui/core";
import React from "react";
import { VList } from "../../../core/components/VList";
import { VListItem } from "../../../core/components/VListItem";
import { MoodType } from "../../../models/modelTypes";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import barchartImg from "../../../img/barchart.svg";
import { SimpleBlankSlate } from "../../../core/components/SimpleBlankSlate";
import { useAuth } from "../../../contexts/auth.context";

type NegativeDriversProps = {
    allNegativeMoods: MoodType[];
    individual?: boolean;
    name?: string;
};

export const NegativeDrivers = (props: NegativeDriversProps): JSX.Element => {
    const { allNegativeMoods, individual = false, name } = props;
    const { hasPermission } = useAuth();

    const groupedNegative = allNegativeMoods.reduce((groups: Record<string, MoodType[]>, mood) => {
        if (mood && mood.reason) {
            if (!groups[mood.reason]) {
                groups[mood.reason] = [];
            }
            groups[mood.reason].push(mood);
        }
        return groups;
    }, {});

    const groupedNegativeMoods = Object.keys(groupedNegative)
        .map((reason) => {
            return {
                reason,
                moods: groupedNegative[reason],
            };
        })
        .sort((a, b) => b.moods.length - a.moods.length);

    return (
        <Paper
            style={{
                backgroundColor: verbalTheme.palette.secondaryShade.main,
                borderRadius: 5,
                marginTop: verbalTheme.spacing(2),
                paddingTop: verbalTheme.spacing(2),
                height: "100%",
            }}
            variant="outlined"
        >
            {hasPermission("ClassroomReportView") ? (
                allNegativeMoods && allNegativeMoods.length > 0 ? (
                    <>
                        <Typography variant="h4" align="center">
                            {individual ? "Key Negative Mood Drivers" : "Overall Negative Mood Drivers"}
                        </Typography>
                        <VList>
                            {groupedNegativeMoods.map((m) => (
                                <>
                                    <VListItem
                                        style={{ paddingBottom: 0 }}
                                        key={m.reason}
                                        primaryText={m.reason}
                                        button={undefined}
                                    >
                                        {Math.round((100 / allNegativeMoods.length) * m.moods.length)}%
                                    </VListItem>
                                    <LinearProgress
                                        style={{
                                            marginLeft: verbalTheme.spacing(2),
                                            marginRight: verbalTheme.spacing(2),
                                            marginTop: 0,
                                            marginBottom: 0,
                                        }}
                                        variant="determinate"
                                        value={Math.round((100 / allNegativeMoods.length) * m.moods.length)}
                                    />
                                </>
                            ))}
                        </VList>
                    </>
                ) : (
                    <>
                        <SimpleBlankSlate
                            image={<img src={barchartImg} />}
                            header={individual ? "Overall Negative Mood Drivers" : "Key Negative Mood Drivers"}
                            content={
                                individual
                                    ? `This will show the key reasons ${name} has marked a negative mood`
                                    : `This will show the key reasons the class has marked a negative mood`
                            }
                            extra={
                                individual ? (
                                    <Box>
                                        <Typography color="primary">
                                            (Available when negative moods have been marked)
                                        </Typography>
                                    </Box>
                                ) : undefined
                            }
                        />
                    </>
                )
            ) : (
                <>
                    <SimpleBlankSlate
                        image={<img src={barchartImg} />}
                        header={"Negative Mood Drivers"}
                        content={"You do not have permission to view the negative mood drivers"}
                        extra={
                            <Box>
                                <Typography color="primary">
                                    (Available for Staff Members and School Administrators only)
                                </Typography>
                            </Box>
                        }
                    />
                </>
            )}
            {}
        </Paper>
    );
};
