import { getData } from "../rest";
import { StoryCollectionType } from "../../../models/modelTypes";
import { objectToQueryString } from "../../../utils/api";
import { CollectionListParameters } from "../../../../../common/src/api-parameters/collections";

export const listCollections = async (
    args: CollectionListParameters,
): Promise<{ items: StoryCollectionType[]; total: number }> =>
    await getData(`/public/collection${objectToQueryString(args)}`);

export const getAll = async (): Promise<StoryCollectionType[]> =>
    await getData(`/public/collection/${objectToQueryString({ take: 1000 } as CollectionListParameters)}`);
