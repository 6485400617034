import React, { useEffect, useState } from "react";
import { useProgramme } from "../../contexts/programme.context";
import { useFacilitator } from "../../../../hooks/useFacilitator";
import { User } from "common/build/prisma/client";
import { useAuth } from "../../../../contexts/auth.context";
import { Formik } from "formik";
import {
    Box,
    Button,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    Link,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import * as yup from "yup";
import ProgrammeFacilitatorModal from "./ProgrammeFacilitatorModal";
import { Alert } from "@material-ui/lab";
import { PrimaryButton, SecondaryButton } from "../../../layout/Button/Button";
import { FormActions } from "../../../../core/components/FormActions";
import { ProgrammeType } from "../../../../models/modelTypes";
import { sortFacilitators } from "../../../../utils/sorts";
import { Delete } from "@material-ui/icons";

type ManageProgrammeFacilitatorsType = {
    programme: ProgrammeType;
    onSubmit: () => Promise<void> | void;
    programmeCreate: boolean;
};

const ManageProgrammeFacilitators = (props: ManageProgrammeFacilitatorsType): JSX.Element => {
    const { onSubmit, programmeCreate, programme } = props;
    const { hasPermission } = useAuth();
    const { changeStep } = useProgramme();
    const { addFacilitatorToProgramme, removeFacilitatorFromProgramme, facilitatorProgramme, setFacilitatorProgramme } =
        useFacilitator();
    const { facilitators, getAllFacilitators } = useFacilitator();

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        getAllFacilitators();
    }, []);

    useEffect(() => {
        if (programme) {
            setFacilitatorProgramme(programme);
        }
    }, [programme]);

    const addFacilitator = (programmeId: number, user: Partial<User>) => {
        addFacilitatorToProgramme(programmeId, user, false)
            .then(() => {
                setError(undefined);
            })
            .catch((error) => {
                setError(error);
            });
    };

    const removeFacilitator = (programmeId: number, facilitatorId: number) => {
        removeFacilitatorFromProgramme(programmeId, facilitatorId)
            .then(() => {
                setError(undefined);
            })
            .catch((error) => setError(error));
    };

    const useStyles = makeStyles(
        createStyles({
            table: {
                boxShadow: "none",
                marginBottom: "32px",
            },
            facilitatoMenuItem: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "right",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Formik
                initialValues={{
                    facilitatorId: "",
                }}
                onSubmit={onSubmit}
                validationSchema={
                    facilitatorProgramme?.Facilitators === undefined &&
                    yup.object({
                        facilitatorId: yup.string().required("Facilitator is required"),
                    })
                }
                validateOnChange={false}
            >
                {({ submitForm, errors, values, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <>
                                    {hasPermission("ProgrammeCreate") && (
                                        <FormControl variant="outlined" error={!!errors.facilitatorId} fullWidth>
                                            <Box mb={2}>
                                                <Grid container wrap="nowrap" spacing={1}>
                                                    <Grid item xs>
                                                        <InputLabel id="age-label" required>
                                                            Choose a facilitator
                                                        </InputLabel>
                                                        <Select
                                                            inputProps={{
                                                                className: classes.facilitatoMenuItem,
                                                            }}
                                                            className={classes.facilitatoMenuItem}
                                                            required
                                                            fullWidth
                                                            label="Choose a facilitator"
                                                            name="facilitator"
                                                            id="facilitator"
                                                            onChange={(e) =>
                                                                setFieldValue("facilitatorId", e.target.value)
                                                            }
                                                        >
                                                            {facilitators
                                                                ?.filter(
                                                                    (f) =>
                                                                        !facilitatorProgramme?.Facilitators?.find(
                                                                            (e) => e.facilitatorId === f.id,
                                                                        ),
                                                                )
                                                                .map((facilitator) => (
                                                                    <MenuItem
                                                                        className={classes.facilitatoMenuItem}
                                                                        key={facilitator.id}
                                                                        value={facilitator.User.email}
                                                                    >
                                                                        {facilitator.User.firstName}{" "}
                                                                        {facilitator.User.lastName}
                                                                        {
                                                                            <>
                                                                                <Chip label={facilitator.User.email} />
                                                                            </>
                                                                        }
                                                                    </MenuItem>
                                                                ))}
                                                        </Select>
                                                    </Grid>
                                                    <Grid item>
                                                        {facilitatorProgramme && (
                                                            <Button
                                                                variant="contained"
                                                                color="secondary"
                                                                size="large"
                                                                disabled={isSubmitting}
                                                                onClick={() =>
                                                                    addFacilitator(facilitatorProgramme.id, {
                                                                        email: values.facilitatorId,
                                                                    })
                                                                }
                                                            >
                                                                Add
                                                            </Button>
                                                        )}
                                                    </Grid>
                                                </Grid>

                                                <Grid
                                                    item
                                                    xs={12}
                                                    style={{ color: "rgba(0, 0, 0, 0.54)", paddingTop: "8px" }}
                                                >
                                                    Input fields marked with an asterisk (*) are required
                                                </Grid>
                                            </Box>

                                            <FormHelperText>
                                                {errors.facilitatorId && touched.facilitatorId}
                                            </FormHelperText>
                                        </FormControl>
                                    )}
                                    {facilitatorProgramme &&
                                    facilitatorProgramme.Facilitators?.length &&
                                    facilitatorProgramme.Facilitators?.length > 0 ? (
                                        <TableContainer component={Paper} className={classes.table}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Name</TableCell>
                                                        {hasPermission("ProgrammeCreate") && (
                                                            <>
                                                                <TableCell align="right">Email</TableCell>
                                                                <TableCell align="right">Remove</TableCell>
                                                            </>
                                                        )}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {facilitatorProgramme.Facilitators.sort(sortFacilitators).map(
                                                        (facilitator) => (
                                                            <TableRow key={facilitator.Facilitator.User.id}>
                                                                <TableCell>
                                                                    {facilitator.Facilitator.User.firstName}{" "}
                                                                    {facilitator.Facilitator.User.lastName}
                                                                </TableCell>
                                                                {hasPermission("ProgrammeCreate") && (
                                                                    <>
                                                                        <TableCell align="right">
                                                                            {facilitator.Facilitator.User.email}
                                                                        </TableCell>
                                                                        <TableCell align="right">
                                                                            <IconButton
                                                                                color="primary"
                                                                                onClick={() =>
                                                                                    removeFacilitator(
                                                                                        facilitatorProgramme.id,
                                                                                        facilitator.Facilitator.id,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <Delete />
                                                                            </IconButton>
                                                                        </TableCell>
                                                                    </>
                                                                )}
                                                            </TableRow>
                                                        ),
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    ) : null}
                                    {hasPermission("ProgrammeCreate") && facilitatorProgramme && (
                                        <Grid item xs>
                                            <Typography variant="body1">
                                                Or{" "}
                                                <Link
                                                    variant={"body1"}
                                                    component="button"
                                                    onClick={() => setOpenModal(true)}
                                                >
                                                    add a new facilitator
                                                </Link>
                                            </Typography>
                                            <ProgrammeFacilitatorModal
                                                programmeId={facilitatorProgramme.id}
                                                open={openModal}
                                                setOpen={setOpenModal}
                                                setProgramme={setFacilitatorProgramme}
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs>
                                        {error && (
                                            <Box mb={2}>
                                                <Alert severity="error">{error}</Alert>
                                            </Box>
                                        )}

                                        {programmeCreate && (
                                            <FormActions
                                                left={
                                                    <>
                                                        <SecondaryButton size="large" onClick={() => changeStep(1)}>
                                                            Back
                                                        </SecondaryButton>
                                                    </>
                                                }
                                                right={
                                                    <>
                                                        <PrimaryButton
                                                            size="large"
                                                            disabled={!facilitatorProgramme?.Facilitators}
                                                            onClick={submitForm}
                                                        >
                                                            Continue
                                                        </PrimaryButton>
                                                    </>
                                                }
                                            />
                                        )}
                                    </Grid>
                                </>
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </>
    );
};

export default ManageProgrammeFacilitators;
