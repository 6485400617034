import React from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup, { ToggleButtonGroupProps } from "@material-ui/lab/ToggleButtonGroup";
import { makeStyles, createStyles } from "@material-ui/core";

type SwitchOption = {
    name: string;
    value: string;
};

type VSwitchProps = ToggleButtonGroupProps & {
    options: SwitchOption[];
    value: string;
    onChange: (event: React.MouseEvent<HTMLElement>, newValue: string) => void;
    segmented?: boolean;
};

export const VSwitch: React.FC<VSwitchProps> = (props: VSwitchProps): JSX.Element => {
    const { options, value, onChange, segmented = false, ...rest } = props;

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                display: segmented ? "flex" : "inline",
            },
            buttonRoot: {
                ...(segmented && { flex: 1 }),
            },
        }),
    );

    const classes = useStyles();

    return (
        <ToggleButtonGroup value={value} onChange={onChange} className={classes.root} {...rest}>
            {options.map(({ name, value }, i) => (
                <ToggleButton key={i} value={value} className={classes.buttonRoot}>
                    {name}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};
