"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConferenceProviderType = void 0;
exports.ConferenceProviderType = {
    BuiltIn: "Built-In",
    GoogleMeet: "Google Meet",
    MicrosoftTeams: "Microsoft Teams",
    Zoom: "Zoom",
    Other: "Other",
};
