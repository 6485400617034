import * as yup from "yup";
import React from "react";
import { email, disconnectRow } from "../../../core/components/crud/decorators";
import { ParticipantType, ProgrammeType, SessionType } from "../../../models/modelTypes";
import { Tooltip } from "@material-ui/core";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { phoneNumberValidation } from "../../../utils/phoneNumberValidation";
import { Model } from "../../../core/components/crud/Crud";
import { Participant } from "common/build/prisma/client";
import { Clear, Done, HourglassEmptyRounded } from "@material-ui/icons";

export function surveyStatus(
    property: string,
    participant: ParticipantType,
    programme?: ProgrammeType,
    session?: SessionType,
): JSX.Element {
    if (participant?.ParticipantToProgramme) {
        const hasComplete =
            property === "hasCompletedPreSurvey"
                ? participant.ParticipantToProgramme[0].hasCompletedPreSurvey
                : participant.ParticipantToProgramme[0].hasCompletedPostSurvey;

        const optedOut = participant.ParticipantToProgramme[0].hasOptedOutOfSurveys;

        let length: number | undefined = 0;
        if (programme) {
            length = programme.Sessions?.filter((session) => session.state === "Complete" && session.sessionNumber > 1)
                ?.length;
        } else if (session) {
            // this is used in register. If current session is 3 and we sessionNumber > 2, then on the register screen it displays Not complete
            // But participant has until session 3 is marked as complete to complete the survey
            length = session.sessionNumber > 2 ? 1 : 0;
        }

        const involuntarilyOptedOut =
            !participant.ParticipantToProgramme[0].hasCompletedPreSurvey && length && length > 0;

        if (hasComplete || optedOut || involuntarilyOptedOut) {
            return optedOut || involuntarilyOptedOut ? (
                <Tooltip title={optedOut ? "Opted Out" : "Not Complete"} aria-label="opted-out">
                    <Clear style={{ color: verbalTheme.palette.error.main, display: "block" }} />
                </Tooltip>
            ) : (
                <Tooltip title="Complete" aria-label="complete">
                    <Done style={{ color: verbalTheme.palette.success.main, display: "block" }} />
                </Tooltip>
            );
        }
    }

    return (
        <Tooltip title="Awaiting Completion" aria-label="awaiting">
            <HourglassEmptyRounded style={{ color: verbalTheme.palette.secondary.main, display: "block" }} />
        </Tooltip>
    );
}

export const createProgrammeParticipantCRUDModel: (p?: ProgrammeType) => Model<Participant> = (programme) => {
    return {
        name: {
            label: "Name",
            inTable: true,
            validation: yup.string().required("Name is required").max(100),
        },
        email: {
            label: "Email",
            validation: yup.string().email("A valid email is required").optional().max(100),
            decorator: (value: unknown): JSX.Element => email(value as string),
        },
        phoneNumber: {
            label: "Phone Number",
            validation: phoneNumberValidation.optional(),
        },
        hasCompletedPreSurvey: {
            inTable: true,
            inForm: false,
            label: "Pre Survey",
            decorator: (value: boolean, property: string, participant: ParticipantType): JSX.Element =>
                surveyStatus(property, participant, programme),
        },
        hasCompletedPostSurvey: {
            inTable: true,
            inForm: false,
            label: "Post Survey",
            decorator: (value: boolean, property: string, participant: ParticipantType): JSX.Element =>
                surveyStatus(property, participant, programme),
        },
        id: {
            label: " ",
            inTable: true,
            inForm: false,
            decorator: (value: number, property: string, participant: ParticipantType): JSX.Element =>
                disconnectRow<ParticipantType>(participant, "ParticipantToProgramme"),
        },
    };
};
