import React, { useEffect, useState } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {
    Dialog,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { PrimaryButton, SecondaryButton } from "../../../layout/Button/Button";
import * as yup from "yup";
import { FormActions } from "../../../../core/components/FormActions";
import { Formik, FormikHelpers } from "formik";
import { MappedStoryQuestionType, StoryCollectionType } from "../../../../models/modelTypes";
import { RelatedQuestion } from "common/build/prisma/client";
import { ConditionQuestion } from "./ConditionQuestion";
import { VPill } from "../../../../core/components/VPill";
import { useAuth } from "../../../../contexts/auth.context";

type DiscussionDialogProps = {
    open: boolean;
    questionCount: number;
    existingQuestion?: MappedStoryQuestionType;
    collection?: StoryCollectionType;
    setOpen: (open: boolean) => void;
    getQuestion: (question: Partial<MappedStoryQuestionType>) => void;
    cancel: () => void;
};

export const DiscussionDialog: React.FC<DiscussionDialogProps> = (props: DiscussionDialogProps): JSX.Element => {
    const { open, setOpen, getQuestion, cancel, existingQuestion, questionCount, collection } = props;
    const { hasPermission } = useAuth();

    const [question, setQuestion] = useState<Partial<MappedStoryQuestionType>>();
    const [addFirstConditionQuestion, setAddFirstConditionQuestion] = useState<boolean>(false);
    const [addSecondConditionQuestion, setAddSecondConditionQuestion] = useState<boolean>(false);

    const isPublicCollection = collection?.type === "Public";

    useEffect(() => {
        if (existingQuestion) {
            setQuestion(existingQuestion);
            if (existingQuestion.RelatedQuestion) {
                if (existingQuestion.RelatedQuestion[0]) {
                    setAddFirstConditionQuestion(true);
                }
                if (existingQuestion.RelatedQuestion[1]) {
                    setAddSecondConditionQuestion(true);
                }
            }
        } else {
            setQuestion(undefined);
        }
    }, [existingQuestion, open]);

    const submitQuestion = (
        values: Partial<
            MappedStoryQuestionType & {
                condition1: boolean;
                conQuestion1: string;
                conExplanation1: string;
                condition2: boolean;
                conQuestion2: string;
                conExplanation2: string;
            }
        >,
        formikHelpers: FormikHelpers<Partial<MappedStoryQuestionType>>,
    ) => {
        let q: Partial<MappedStoryQuestionType> = {
            order: values.order,
            question: values.question,
            explaination: values.explaination,
            hint1: values.hint1,
            hint2: values.hint2,
            keywordDesc: values.keywordDesc,
            keywords: values.keywords,
            prompt: values.prompt,
            prepGuide: values.prepGuide,
            category: values.category,
        };

        const relatedQuestions = [];

        if (values.conQuestion1) {
            const relatedOne: Partial<RelatedQuestion> = {
                condition: values.condition1 === true ? "Yes" : "No",
                question: values.conQuestion1,
                explanation: values.conExplanation1 ?? null,
            };

            relatedQuestions.push(relatedOne);
        }

        if (values.conQuestion2) {
            const relatedTwo: Partial<RelatedQuestion> = {
                condition: values.condition2 === true ? "Yes" : "No",
                question: values.conQuestion2,
                explanation: values.conExplanation2 ?? null,
            };

            relatedQuestions.push(relatedTwo);
        }

        q = {
            ...q,
            RelatedQuestion: relatedQuestions,
        };

        getQuestion(q);
        setOpen(false);
        setAddFirstConditionQuestion(false);
        setAddSecondConditionQuestion(false);
        formikHelpers.resetForm();
    };

    const handleCancel = () => {
        setOpen(false);
        setAddFirstConditionQuestion(false);
        setAddSecondConditionQuestion(false);
        cancel();
    };

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
            keywordChip: {
                borderRadius: 0,
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Dialog open={open} fullScreen>
                <MuiDialogTitle>
                    <Typography variant="h4" component="h3">
                        Add Question/s
                    </Typography>
                    <IconButton className={classes.closeButton} aria-label="close" onClick={handleCancel}>
                        <Close />
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent>
                    <Formik
                        initialValues={{
                            question: question?.question ?? "",
                            explaination: question?.explaination ?? "",
                            hint1: question?.hint1 ?? "",
                            hint2: question?.hint2 ?? "",
                            prepGuide: question?.prepGuide ?? "",
                            keywordDesc: question?.keywordDesc ?? "",
                            keywords: question?.keywords ?? "",
                            category: question?.category ?? "Open",
                            prompt: question?.prompt ?? "",
                            order: question ? question.order : questionCount === 0 ? 1 : questionCount + 1,
                            condition1: question?.RelatedQuestion?.[0]?.condition === "Yes" ? true : false,
                            condition2: question?.RelatedQuestion?.[1]?.condition === "Yes" ? true : false,
                            conQuestion1: question?.RelatedQuestion?.[0]?.question ?? "",
                            conQuestion2: question?.RelatedQuestion?.[1]?.question ?? "",
                            conExplanation1: question?.RelatedQuestion?.[0]?.explanation ?? "",
                            conExplanation2: question?.RelatedQuestion?.[1]?.explanation ?? "",
                        }}
                        onSubmit={submitQuestion}
                        validationSchema={yup.object({
                            question: yup.string().required("Question is required"),
                            explaination: yup.string().required("Why ask this is required"),
                            prepGuide: yup.string().required("Guide Note is required"),
                            keywordDesc: yup.string().required("Discussion purpose is required"),
                            keywords: yup.string().required("Keywords are required"),
                            prompt: yup.string().required("Help prompt is required"),
                        })}
                        validateOnChange={false}
                    >
                        {({ submitForm, errors, values, handleChange, setFieldValue, isSubmitting }) => {
                            return (
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        submitForm();
                                    }}
                                >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                autoFocus
                                                required
                                                disabled={!hasPermission("StoryAddQuestion")}
                                                multiline
                                                name="question"
                                                id="question"
                                                label="Question"
                                                variant="outlined"
                                                value={values.question}
                                                fullWidth
                                                onChange={handleChange}
                                                helperText="Enter the discussion question"
                                                error={!!errors.question}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                name="explaination"
                                                multiline
                                                required
                                                disabled={!hasPermission("StoryAddQuestion")}
                                                variant="outlined"
                                                label="Why ask this?"
                                                value={values.explaination}
                                                fullWidth
                                                onChange={handleChange}
                                                helperText="Explain why this question is being asked"
                                                error={!!errors.explaination}
                                            />
                                        </Grid>
                                        {!isPublicCollection && (
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required={!isPublicCollection}
                                                        fullWidth
                                                        name="keywordDesc"
                                                        multiline
                                                        disabled={!hasPermission("StoryAddQuestion")}
                                                        variant="outlined"
                                                        label="Discussion Purpose / Keywords"
                                                        value={values.keywordDesc}
                                                        onChange={handleChange}
                                                        helperText="Explain the purpose of the discussion such as the keywords to listen out for"
                                                        error={!!errors.keywordDesc}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Grid item xs={12} container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required={!isPublicCollection}
                                                                fullWidth
                                                                name="keywords"
                                                                multiline
                                                                disabled={!hasPermission("StoryAddQuestion")}
                                                                variant="outlined"
                                                                label="Enter Keywords"
                                                                value={values.keywords}
                                                                onChange={handleChange}
                                                                helperText="List the keywords for the facilitator to listen out for"
                                                            />
                                                        </Grid>
                                                        {values.keywords?.length && values.keywords.length > 0
                                                            ? values.keywords?.split(" ").map((kw, index) => (
                                                                  <Grid key={kw} item>
                                                                      <VPill
                                                                          style={
                                                                              index % 2 === 0
                                                                                  ? { backgroundColor: "#28B5BE" }
                                                                                  : { backgroundColor: "#D3B61A" }
                                                                          }
                                                                          className={classes.keywordChip}
                                                                          label={kw}
                                                                      />
                                                                  </Grid>
                                                              ))
                                                            : null}
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required={!isPublicCollection}
                                                        fullWidth
                                                        name="prompt"
                                                        disabled={!hasPermission("StoryAddQuestion")}
                                                        multiline
                                                        variant="outlined"
                                                        label="Help Prompt"
                                                        value={values.prompt}
                                                        onChange={handleChange}
                                                        helperText="Give the facilitator a help prompt in case the group aren't engaging with the discussion"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="prepGuide"
                                                        variant="outlined"
                                                        multiline
                                                        disabled={!hasPermission("StoryAddQuestion")}
                                                        required={!isPublicCollection}
                                                        label="Delivery Guide Note"
                                                        value={values.prepGuide}
                                                        fullWidth
                                                        onChange={handleChange}
                                                        helperText="Guide facilitators through the discussion with participants when delivering"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl variant="outlined" error={!!errors.category} fullWidth>
                                                        <InputLabel id="category-label" required>
                                                            Category
                                                        </InputLabel>
                                                        <Select
                                                            fullWidth
                                                            label="Category"
                                                            labelId="category-label"
                                                            name="category"
                                                            id="category"
                                                            value={values.category}
                                                            onChange={(e) => setFieldValue("category", e.target.value)}
                                                        >
                                                            <MenuItem key={"Open"} value={"Open"}>
                                                                Open
                                                            </MenuItem>
                                                            <MenuItem key={"Closed"} value={"Closed"}>
                                                                Closed
                                                            </MenuItem>
                                                        </Select>
                                                        <FormHelperText>
                                                            Select whether his question is open or closed. Closed
                                                            questions expect a Yes/No answer from the group
                                                        </FormHelperText>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        )}

                                        {isPublicCollection && (
                                            <>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="hint1"
                                                        variant="outlined"
                                                        disabled={!hasPermission("StoryAddQuestion")}
                                                        label="Hint 1"
                                                        value={values.hint1}
                                                        fullWidth
                                                        onChange={handleChange}
                                                        helperText="Give a hint to parents to help them generate discussion with their child"
                                                        error={!!errors.hint1}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        name="hint2"
                                                        variant="outlined"
                                                        label="Hint 2"
                                                        disabled={!hasPermission("StoryAddQuestion")}
                                                        value={values.hint2}
                                                        fullWidth
                                                        onChange={handleChange}
                                                        helperText="Give another hint to parents to help them generate discussion with their child"
                                                        error={!!errors.hint2}
                                                    />
                                                </Grid>
                                            </>
                                        )}

                                        {values.category === "Closed" && addFirstConditionQuestion && (
                                            <ConditionQuestion
                                                beenApproved={false}
                                                removeCondition={
                                                    !addSecondConditionQuestion
                                                        ? () => setAddFirstConditionQuestion(false)
                                                        : undefined
                                                }
                                                condition={values.condition1 ?? true}
                                                count={1}
                                                question={values.conQuestion1 ?? ""}
                                                explanation={values.conExplanation1}
                                            />
                                        )}
                                        {values.category === "Closed" && addSecondConditionQuestion && (
                                            <ConditionQuestion
                                                beenApproved={false}
                                                removeCondition={() => setAddSecondConditionQuestion(false)}
                                                condition={!values.condition1}
                                                count={2}
                                                question={values.conQuestion2 ?? ""}
                                                explanation={values.conExplanation2}
                                            />
                                        )}
                                        <Grid item>
                                            {values.category === "Closed" &&
                                                (!addFirstConditionQuestion || !addSecondConditionQuestion) &&
                                                hasPermission("StoryAddQuestion") && (
                                                    <SecondaryButton
                                                        onClick={() => {
                                                            if (!addFirstConditionQuestion) {
                                                                setAddFirstConditionQuestion(true);
                                                            } else {
                                                                setAddSecondConditionQuestion(true);
                                                            }
                                                        }}
                                                    >
                                                        Add Conditional Question
                                                    </SecondaryButton>
                                                )}
                                        </Grid>

                                        <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                            Input fields marked with an asterisk (*) are required
                                        </Grid>

                                        <Grid item xs={12}>
                                            <FormActions
                                                right={
                                                    hasPermission("StoryAddQuestion") ? (
                                                        <PrimaryButton
                                                            size="large"
                                                            type="submit"
                                                            variant="contained"
                                                            color={"primary"}
                                                            disabled={isSubmitting}
                                                        >
                                                            Save
                                                        </PrimaryButton>
                                                    ) : (
                                                        <></>
                                                    )
                                                }
                                                left={
                                                    <SecondaryButton size="large" onClick={handleCancel}>
                                                        {"Cancel"}
                                                    </SecondaryButton>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            );
                        }}
                    </Formik>
                </MuiDialogContent>
            </Dialog>
        </>
    );
};
