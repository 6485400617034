import {
    Box,
    Card,
    Collapse,
    createStyles,
    Dialog,
    DialogProps,
    Grid,
    IconButton,
    ListItemIcon,
    ListItemText,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { ArrowBack, ArrowForward, Close, ExpandLess, ExpandMore } from "@material-ui/icons";
import { SimpleBlankSlate } from "../../../core/components/SimpleBlankSlate";
import barchartImg from "../../../img/bars.svg";
import barchartImgDark from "../../../img/barchart.svg";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { VList } from "../../../core/components/VList";
import { VListItem } from "../../../core/components/VListItem";
import { format } from "date-fns";
import { VModule } from "../../../core/components/VModule";
import { StudentWellbeingChart } from "./StudentWellbeingChart";
import { AverageWellbeingBar } from "../components/AverageWellbeingBar";
import { NegativeDrivers } from "../components/NegativeDrivers";
import { MoodType } from "../../../models/modelTypes";
import { useClassroom } from "../contexts/classroom.context";

type StudentProfileProps = DialogProps & {
    setOpenProfile: (open: boolean) => void;
    handleNextProfile: () => void;
    handlePreviousProfile: () => void;
};

export const StudentProfile = (props: StudentProfileProps): JSX.Element => {
    const { open, setOpenProfile, handleNextProfile, handlePreviousProfile } = props;
    const { currentParticipant } = useClassroom();

    const [positiveOpen, setPositiveOpen] = useState<boolean>(false);
    const [negativeOpen, setNegativeOpen] = useState<boolean>(false);
    const [neutralOpen, setNeutralOpen] = useState<boolean>(false);

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
        }),
    );

    // separate categories of moods
    const moods: Array<MoodType> = currentParticipant?.Moods?.filter((m) => m.isPresent) ?? [];
    const positiveMoods = moods?.filter((m) => ["Happy"].includes(m.mood)) ?? [];
    const negativeMoods = moods?.filter((m) => ["Sad", "Angry", "Worried"].includes(m.mood)) ?? [];
    const neutralMoods = moods?.filter((m) => ["Calm"].includes(m.mood)) ?? [];
    const triggerMoods =
        moods?.filter((m) => m.trigger)?.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) ?? [];

    const classes = useStyles();

    return (
        <>
            <Dialog open={open} fullScreen onClose={() => setOpenProfile(false)}>
                <MuiDialogTitle>
                    <Grid container spacing={3} justifyContent="center">
                        <Grid item>
                            <IconButton onClick={handlePreviousProfile}>
                                <ArrowBack color="primary" fontSize="large" />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Box display="flex" justifyContent={"center"} flexDirection="column">
                                <Typography variant="h2" align="center">
                                    How is {currentParticipant?.Participant?.name} doing?
                                </Typography>
                                <Typography variant="caption" align="center">
                                    {currentParticipant?.Participant?.email}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleNextProfile}>
                                <ArrowForward color="primary" fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>

                    <IconButton
                        className={classes.closeButton}
                        aria-label="close-profile"
                        onClick={() => setOpenProfile(false)}
                    >
                        <Close />
                    </IconButton>
                </MuiDialogTitle>
                <MuiDialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AverageWellbeingBar
                                allMoods={moods}
                                positiveMoods={positiveMoods}
                                negativeMoods={negativeMoods}
                                neutralMoods={neutralMoods}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Paper
                                style={{
                                    backgroundColor: verbalTheme.palette.secondaryShade.main,
                                    borderRadius: 5,
                                    marginTop: verbalTheme.spacing(2),
                                    paddingTop: verbalTheme.spacing(2),
                                    height: "100%",
                                }}
                                variant="outlined"
                            >
                                {moods && moods.length > 0 ? (
                                    <>
                                        <Typography variant="h4" align="center">
                                            All Moods
                                        </Typography>
                                        <VList style={{ display: "table" }}>
                                            <VListItem onClick={() => setPositiveOpen(!positiveOpen)}>
                                                <ListItemIcon>
                                                    <span style={{ fontSize: "32px" }}>&#128513;</span>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    Positive ({positiveMoods?.length ?? 0} total)
                                                </ListItemText>
                                                {positiveMoods.length > 0 ? (
                                                    positiveOpen ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )
                                                ) : null}
                                            </VListItem>
                                            <Collapse in={positiveOpen}>
                                                {positiveMoods
                                                    ?.map((m) => m.mood)
                                                    .filter((m, i, a) => a.indexOf(m) === i)
                                                    .map((m) => ({
                                                        mood: m,
                                                        count: positiveMoods.filter((v) => v.mood === m).length,
                                                    }))
                                                    .map((m) => {
                                                        return (
                                                            <VListItem key={m.mood} button={undefined}>
                                                                <ListItemText inset>
                                                                    {m.mood} ({m.count})
                                                                </ListItemText>
                                                            </VListItem>
                                                        );
                                                    })}
                                            </Collapse>
                                        </VList>

                                        <VList style={{ display: "table" }}>
                                            <VListItem onClick={() => setNegativeOpen(!negativeOpen)}>
                                                <ListItemIcon>
                                                    <span style={{ fontSize: "32px" }}>&#128532;</span>
                                                </ListItemIcon>
                                                <ListItemText>
                                                    Negative ({negativeMoods?.length ?? 0} total)
                                                </ListItemText>
                                                {negativeMoods.length > 0 ? (
                                                    negativeOpen ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )
                                                ) : null}
                                            </VListItem>
                                            <Collapse in={negativeOpen}>
                                                {negativeMoods
                                                    ?.map((m) => m.mood)
                                                    .filter((m, i, a) => a.indexOf(m) === i)
                                                    .map((m) => ({
                                                        mood: m,
                                                        count: negativeMoods.filter((v) => v.mood === m).length,
                                                    }))
                                                    .map((m) => {
                                                        return (
                                                            <VListItem key={m.mood} button={undefined}>
                                                                <ListItemText inset>
                                                                    {m.mood} ({m.count})
                                                                </ListItemText>
                                                            </VListItem>
                                                        );
                                                    })}
                                            </Collapse>
                                        </VList>

                                        <VList style={{ display: "table" }}>
                                            <VListItem onClick={() => setNeutralOpen(!neutralOpen)}>
                                                <ListItemIcon>
                                                    <span style={{ fontSize: "32px" }}>&#128528;</span>
                                                </ListItemIcon>
                                                <ListItemText>Neutral ({neutralMoods?.length ?? 0} total)</ListItemText>
                                                {neutralMoods.length > 0 ? (
                                                    neutralOpen ? (
                                                        <ExpandLess />
                                                    ) : (
                                                        <ExpandMore />
                                                    )
                                                ) : null}
                                            </VListItem>
                                            <Collapse in={neutralOpen}>
                                                {neutralMoods
                                                    ?.map((m) => m.mood)
                                                    .filter((m, i, a) => a.indexOf(m) === i)
                                                    .map((m) => ({
                                                        mood: m,
                                                        count: neutralMoods.filter((v) => v.mood === m).length,
                                                    }))
                                                    .map((m) => {
                                                        return (
                                                            <VListItem key={m.mood} button={undefined}>
                                                                <ListItemText inset>
                                                                    {m.mood} ({m.count})
                                                                </ListItemText>
                                                            </VListItem>
                                                        );
                                                    })}
                                            </Collapse>
                                        </VList>
                                    </>
                                ) : (
                                    <SimpleBlankSlate
                                        image={<img src={barchartImgDark} />}
                                        header={"Student Moods"}
                                        content={`When ${currentParticipant?.Participant?.name} has marked their mood, this will show their moods grouped into Positive, Negative and Neutral`}
                                        extra={
                                            <>
                                                <Box mb={2}>
                                                    <Typography variant={"body2"} color={"primary"}>
                                                        (Available when {currentParticipant?.Participant?.name}s mood
                                                        has been taken at least once)
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <NegativeDrivers
                                allNegativeMoods={negativeMoods}
                                individual={true}
                                name={currentParticipant?.Participant?.name}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} style={{ marginTop: verbalTheme.spacing(2) }}>
                            <VModule variant="outlined">
                                {triggerMoods && triggerMoods?.length > 0 ? (
                                    <>
                                        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"}>
                                            <Typography variant="h4" align="center">
                                                {currentParticipant?.Participant?.name}&apos;s Triggers
                                            </Typography>
                                            <Typography variant="caption" align="center" color="primary">
                                                This table allows you to see any concerning moods to facilitate early
                                                intervention
                                            </Typography>
                                        </Box>
                                        <Table>
                                            <TableHead>
                                                <TableCell align="center">Mood</TableCell>
                                                <TableCell align="center">Reason</TableCell>
                                                <TableCell align="center">Date</TableCell>
                                            </TableHead>
                                            <TableBody>
                                                {triggerMoods?.map((m) => (
                                                    <TableRow key={m.id}>
                                                        <TableCell align="center">{m.mood}</TableCell>
                                                        <TableCell align="center">{m.reason}</TableCell>
                                                        <TableCell align="center">
                                                            {format(
                                                                Date.parse(m.date.toLocaleString()),
                                                                "EE d MMM YYY",
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </>
                                ) : (
                                    <SimpleBlankSlate
                                        image={<img src={barchartImg} />}
                                        header={"Student Triggers"}
                                        content={`When ${currentParticipant?.Participant?.name} has started to mark their mood, this table will show the moods and mood reasons that have caused a trigger. This allows you to see any concerning moods and reason early to facilitate early intervention`}
                                        extra={
                                            <>
                                                <Box mb={2}>
                                                    <Typography variant={"body2"} color={"primary"}>
                                                        (Available when {currentParticipant?.Participant?.name}s mood
                                                        and reason has caused a trigger)
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                )}
                            </VModule>
                        </Grid>
                        <Grid item xs={12}>
                            {moods && moods?.length && moods?.length > 0 ? (
                                <StudentWellbeingChart moods={moods} />
                            ) : (
                                <Card variant="outlined">
                                    <SimpleBlankSlate
                                        image={<img src={barchartImg} />}
                                        header={"Student Wellbeing"}
                                        content={`When ${currentParticipant?.Participant?.name} has started to mark their mood, this wellbeing chart will show their positive moods vs their negative and neutral moods`}
                                        extra={
                                            <>
                                                <Box mb={2}>
                                                    <Typography variant={"body2"} color={"primary"}>
                                                        (Available when {currentParticipant?.Participant?.name}s mood
                                                        begins to be tracked)
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                </Card>
                            )}
                        </Grid>
                    </Grid>
                </MuiDialogContent>
            </Dialog>
        </>
    );
};
