import * as yup from "yup";

export const themeCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Name",
        inputProps: {
            required: true,
        },
        validation: yup.string().required("Name is required"),
    },
    description: {
        label: "Description",
    },
};
