import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";

interface ToggleProps {
    id: string;
    value: boolean;
    setFieldValue: (field: string, value: boolean) => void;
    label: string;
    leftOption: string;
    rightOption: string;
    disabled?: boolean;
}

export const ToggleInput = (props: ToggleProps): JSX.Element => {
    const { id, value, setFieldValue, label, leftOption, rightOption, disabled = false } = props;

    return (
        <Grid item xs={12}>
            <Box mb="1rem">
                <Typography variant="body1" color="textPrimary" align="left">
                    {label}
                </Typography>
            </Box>
            <ToggleButtonGroup
                id={id}
                size="large"
                value={value}
                exclusive
                onChange={(_, value: boolean) => {
                    if (value !== null) {
                        setFieldValue(id, value);
                    }
                }}
            >
                <ToggleButton value={true} selected={value} disabled={disabled}>
                    {leftOption}
                </ToggleButton>
                <ToggleButton value={false} selected={!value} disabled={disabled}>
                    {rightOption}
                </ToggleButton>
            </ToggleButtonGroup>
        </Grid>
    );
};
