import { AppBar, Toolbar, Button, Box } from "@material-ui/core";
import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";
import { Link, useHistory } from "react-router-dom";
import VerbalLogo from "../../img/VerbalLogo";
import { ConfirmDialog } from "./ConfirmDialog";
import { useAuth } from "../../contexts/auth.context";

const useStyles = makeStyles(
    createStyles({
        header: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: verbalTheme.palette.background.default,
            padding: verbalTheme.spacing(2),
            minHeight: "80px",
            [verbalTheme.breakpoints.up("md")]: {
                borderBottom: "1px solid rgba(0,0,0,0.1)",
            },
        },
        logo: {
            width: "150px",
            paddingLeft: verbalTheme.spacing(1),
            paddingTop: "2px",
        },
        toolbar: {
            padding: 0,
            "& > div": {
                display: "flex",
            },
            "& .c-toolbar-item": {
                padding: verbalTheme.spacing(2),
                borderRight: "1px solid rgba(255,255,255,0.1)",
            },
        },
    }),
);

export const ReadingHeader: React.FC = () => {
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const { isAuthenticated } = useAuth();
    const history = useHistory();

    const classes = useStyles();

    return (
        <>
            <AppBar position={"fixed"} className={classes.header} elevation={0}>
                <Link onClick={() => history.push("/participant/dashboard")} to="#" className={classes.logo}>
                    <Box className={classes.logo}>
                        <VerbalLogo />
                    </Box>
                </Link>
                <Toolbar className={classes.toolbar}>
                    <Button variant="contained" color="inherit" size="small" onClick={() => setShowConfirm(true)}>
                        {isAuthenticated ? "Leave Session" : "Exit Story"}
                    </Button>
                </Toolbar>
            </AppBar>
            {showConfirm && (
                <ConfirmDialog
                    open={showConfirm}
                    title={`Are you sure you want to leave?`}
                    onConfirm={() => history.push(`/participant/dashboard`)}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                ></ConfirmDialog>
            )}
        </>
    );
};
