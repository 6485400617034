import React from "react";
import { createStyles, Dialog, makeStyles, Typography, IconButton, useMediaQuery } from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { Close } from "@material-ui/icons";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { ClassList } from "../components/ClassList";
import { useClassroom } from "../contexts/classroom.context";

type ClassListDialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const ClassListDialog = (props: ClassListDialogProps): JSX.Element => {
    const { open, setOpen } = props;
    const { classroom } = useClassroom();

    const fullscreen = useMediaQuery(verbalTheme.breakpoints.down("sm"));
    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },

            root: {
                width: "100%",
                maxWidth: 360,
                backgroundColor: verbalTheme.palette.grey[500],
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md" fullScreen={fullscreen}>
                    <MuiDialogTitle>
                        {classroom?.Students && classroom.Students.length > 0 ? (
                            <Typography variant="h4" align="center">
                                Class List
                            </Typography>
                        ) : null}

                        <IconButton
                            className={classes.closeButton}
                            aria-label="close-profile"
                            onClick={() => setOpen(false)}
                        >
                            <Close />
                        </IconButton>
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <ClassList />
                    </MuiDialogContent>
                </Dialog>
            </div>
        </>
    );
};
