import React, { useState } from "react";
import { Box, Typography, Button } from "@material-ui/core";
import { HeadlineFigureWidget } from "../../../dashboard/Widgets/Headlines/HeadlineFigureWidget";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import { useClassroom } from "../../contexts/classroom.context";
import { ClassListDialog } from "../../students/ClassListDialog";

export const ClassCountWidget = (): JSX.Element => {
    const { classroom } = useClassroom();
    const [open, setOpen] = useState<boolean>(false);
    return (
        <>
            <HeadlineFigureWidget header={"Students in this class"}>
                <Box>
                    <SpinWhileFetching>
                        <Typography variant="h2">{classroom?.Students?.length}</Typography>
                    </SpinWhileFetching>
                </Box>
                <Button size={"small"} variant="contained" color="primary" onClick={() => setOpen(true)}>
                    View Class List
                </Button>
            </HeadlineFigureWidget>

            <ClassListDialog open={open} setOpen={setOpen} />
        </>
    );
};
