import { AppBar, Box, Toolbar } from "@material-ui/core";
import React from "react";
import { ToolbarMode, useBaseLayout } from "../../components/layout/BaseLayout.context";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";
import VerbalLogo from "../../img/VerbalLogo";

export const Header: React.FC = () => {
    const { toolbar, mode } = useBaseLayout();

    const useStyles = makeStyles(
        createStyles({
            root: {
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor:
                    mode === ToolbarMode.Dark ? verbalTheme.palette.secondary.main : verbalTheme.palette.common.white,
                color: "white",
            },
            toolbar: {
                padding: 0,
                "& > div": {
                    display: "flex",
                },
                "& .c-toolbar-item": {
                    padding: verbalTheme.spacing(2),
                    borderRight: "1px solid rgba(255,255,255,0.1)",
                },
            },
            logo: {
                width: "172px",
                paddingBottom: "14px",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <AppBar className={classes.root} elevation={0} position="relative">
                <Box p={2} className={classes.logo}>
                    <VerbalLogo color={mode === ToolbarMode.Dark ? "white" : undefined} />
                </Box>
                <Toolbar className={classes.toolbar}>{toolbar}</Toolbar>
            </AppBar>
        </>
    );
};
