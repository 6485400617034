import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import Drawer, { DrawerProps } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Omit } from "@material-ui/types";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Permission } from "common/build/prisma/client";
import { useAuth } from "../../contexts/auth.context";
//import { useSetTourState, useTrackedTourState } from "../tour/Store";
import VerbalLogo from "../../img/VerbalLogo";
import { verbalTheme } from "./themes/verbal.theme";
import Tooltip from "@material-ui/core/Tooltip";
import LogoV from "../../img/logo-v.png";
import { Divider, MenuItem, Paper, Popper } from "@material-ui/core";
import {
    Book,
    BookmarkBorder,
    ChevronLeft,
    ChevronRight,
    CollectionsBookmark,
    //Dashboard,
    ExitToApp,
    ImportContacts,
    Domain,
    PieChart,
    PeopleOutlined,
    Settings,
    Style,
    ViewModule,
    HomeOutlined,
    HelpOutline,
    ContactSupportOutlined,
    WebOutlined,
    QuestionAnswerOutlined,
    Close,
    OndemandVideoOutlined,
    SchoolOutlined,
    Explore,
    LibraryBooks,
    Build,
    DirectionsWalk,
} from "@material-ui/icons";
import MapIcon from "@material-ui/icons/Map";
import { useOrganisation } from "../../hooks/useOrganisation";
import largeV from "../../img/large-v-nav.svg";
import { useMobile } from "../../hooks/useMobile";
import { TutorialVideosDialog } from "../support/TutorialVideosDialog";
import { getCustomerPortalUrl } from "../../client/api/public/stripe";
import { SubscriptionContactDialog } from "../organisation/SubscriptionContactDialog";
import { SubscriptionExpiredDialog } from "../organisation/SubscriptionExpiredDialog";
import { useSetTourState, useTrackedTourState } from "../tour/Store";

export type categoryProps = {
    id: string;
    icon: React.ReactElement;
    path: string;
    permission: Permission;
};

const drawerWidth = 220;

const styles = (theme: Theme) =>
    createStyles({
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: "nowrap",
            zIndex: 0,
        },
        drawerPaper: {
            justifyContent: "space-between",
            backgroundColor: verbalTheme.palette.secondary.main,
            backgroundImage: `url(${largeV})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
        },
        drawerOpen: {
            width: drawerWidth,
            zIndex: 0,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            overflow: "hidden",
        },
        drawerClose: {
            zIndex: 0,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: "hidden",
            width: theme.spacing(6) + 1,
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(8) + 5,
            },
        },

        subDrawerOpen: {
            width: drawerWidth,
            zIndex: 0,
            overflow: "hidden",
        },
        subDrawerHeader: {
            display: "flex",
            padding: theme.spacing(0, 1),
        },

        item: {
            paddingTop: verbalTheme.spacing(2),
            paddingBottom: verbalTheme.spacing(2),
            paddingLeft: verbalTheme.spacing(3),
            color: "rgba(255, 255, 255, 0.7)",
            "&:hover,&:focus": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
        },

        itemLogo: {
            paddingTop: "24px",
            paddingBottom: "24px",
            paddingRight: "32px",
        },

        itemActiveItem: {
            color: verbalTheme.palette.primary.main,
            backgroundColor: "rgba(255, 255, 255, 0.08)",
        },

        itemPrimary: {
            fontSize: "inherit",
            fontWeight: 700,
        },
        itemIcon: {
            minWidth: "auto",
            marginRight: theme.spacing(3),
        },
        bottom: {
            position: "absolute",
            bottom: 0,
        },

        menuButton: {
            color: "#ffffff",
        },

        hide: {
            display: "none",
        },
        logoSmall: {},
        logoOpen: {
            "& .logoFull": {
                display: "block",
            },
            "& .logoSmall": {
                display: "none",
                width: "36px",
            },
        },
        logoClose: {
            paddingRight: "16px",
            "& .logoFull": {
                display: "none",
            },
            "& .logoSmall": {
                display: "block",
                width: "36px",
            },
        },
        toolbar: theme.mixins.toolbar,
    });

export const activeRoute = (routeName?: string): boolean => {
    const location = useLocation();
    return location.pathname.indexOf(routeName ? routeName.toLowerCase() : "") > -1 ? true : false;
};

export interface NavigatorProps extends Omit<DrawerProps, "classes">, WithStyles<typeof styles> {}

function Navigator(props: NavigatorProps) {
    const { logout, hasPermission, user } = useAuth();
    const setTourState = useSetTourState();
    const tourState = useTrackedTourState();
    const { classes, ...other } = props;

    const { organisation, getOrganisation } = useOrganisation();
    const { isMobile } = useMobile();

    useEffect(() => {
        if (user) {
            getOrganisation(user.id);
        }
    }, [user]);

    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [subMenuOpen, setSubMenuOpen] = useState<Map<string, boolean>>(
        new Map<string, boolean>([
            ["tours", false],
            ["help", false],
            ["story", false],
            ["stats", false],
        ]),
    );
    const [videosOpen, setVideosOpen] = useState<boolean>(false);

    // Subscription Dialog
    const [subDialogOpen, setSubDialogOpen] = useState<boolean>(false);
    const [subExpiredDialogOpen, setSubExpiredDialogOpen] = useState<boolean>(false);

    const handleDrawerOpen = () => {
        setSubMenuOpen(
            new Map(subMenuOpen.set("tours", false).set("help", false).set("story", false).set("stats", false)),
        );
        setAnchorEl(null);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setSubMenuOpen(
            new Map(subMenuOpen.set("tours", false).set("help", false).set("story", false).set("stats", false)),
        );
        setAnchorEl(null);
        setOpen(false);
    };

    useEffect(() => {
        if (isMobile) {
            setOpen(true);
        }
    }, [isMobile]);

    const useStyles = makeStyles(
        createStyles({
            popper: {
                minHeight: "100% !important",
                transform: `translate3d(${!open ? "219px" : "68px"}, 0px, 0px) !important`,
                zIndex: 1,
                position: "fixed",
            },
            menuPaper: {
                width: "240px",
                minHeight: "100vh",
                paddingBottom: 0,
                marginBottom: 0,
                borderRadius: 0,
                backgroundColor: verbalTheme.palette.secondary.main,
                backgroundImage: `url(${largeV})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: `${!open ? "166% bottom" : "128% bottom"}`,
            },
            subMenuHeader: {
                paddingTop: !open ? verbalTheme.spacing(4) : verbalTheme.spacing(3),
                paddingBottom: !open ? verbalTheme.spacing(4) : verbalTheme.spacing(3),
                paddingLeft: verbalTheme.spacing(3),
                color: "rgba(255, 255, 255, 0.7)",
                display: "flex",
                justifyContent: "flex-end",
            },
        }),
    );

    const localClasses = useStyles();

    const handleMyAccount = async () => {
        const isAdmin = user?.Role.name === "Admin";
        if (
            isAdmin &&
            organisation &&
            (organisation.subscriptionStatus === "active" || organisation?.subscriptionStatus === "trialing") &&
            organisation.stripeCustomerId
        ) {
            await getCustomerPortalUrl(organisation.stripeCustomerId).then(({ url }) => window.open(url, "_blank"));
        } else {
            setSubDialogOpen(true);
        }
    };

    const categories = [
        {
            id: "Menu",
            children: [
                { id: "Home", icon: <HomeOutlined />, path: "/home", permission: "DashboardView" },
                { id: "Explore", icon: <Explore />, path: "/explore", permission: "DashboardView" },
                //{ id: "Dashboard", icon: <Dashboard />, path: "/dashboard", permission: "DashboardView" },
                user?.isSchool
                    ? { id: "Classrooms", icon: <SchoolOutlined />, path: "/classrooms", permission: "ClassroomView" }
                    : {
                          id: "Programmes",
                          icon: <BookmarkBorder />,
                          path: "/programmes",
                          permission: "ProgrammeViewAll",
                      },
                {
                    id: "Add User",
                    icon: <PeopleOutlined />,
                    path: "/add-users",
                    permission: "SettingsView",
                },
            ] as categoryProps[],
        },
        {
            id: "Account",
            children: [
                { id: "User Accounts", icon: <Settings />, path: "/users", permission: "SettingsView" },
            ] as categoryProps[],
        },
    ];

    const storyCategories = [
        {
            id: "StoryCuration",
            children: [
                { id: "Stories", icon: <Book />, path: "/stories", permission: "StoryViewAll" },
                {
                    id: "Collections",
                    icon: <CollectionsBookmark />,
                    path: "/collections",
                    permission: "StoryCollectionView",
                },
                { id: "Curriculums", icon: <ViewModule />, path: "/curriculums", permission: "StoryCollectionCreate" },
                { id: "Themes", icon: <Style />, path: "/themes", permission: "StoryCollectionCreate" },
            ],
        },
    ];

    const statsCategories = [
        {
            id: "Stats",
            children: [
                { id: "Organisations", icon: <Domain />, path: "/stats/organisations", permission: "StatsViewAll" },
                {
                    id: "Story Collections",
                    icon: <CollectionsBookmark />,
                    path: "/stats/storycollections",
                    permission: "StatsViewAll",
                },
            ],
        },
    ];

    return (
        <>
            {/** Main Drawer */}
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: !open,
                    [classes.drawerClose]: open,
                })}
                classes={{
                    paper: clsx(classes.drawerPaper, {
                        [classes.drawerOpen]: !open,
                        [classes.drawerClose]: open,
                    }),
                }}
                {...other}
            >
                <div id="main-nav-top">
                    {organisation && organisation.Tier?.tier === "Demo" && <div className={classes.toolbar} />}
                    <List disablePadding component={"nav"}>
                        <ListItem
                            className={clsx(classes.itemLogo, {
                                [classes.logoOpen]: !open,
                                [classes.logoClose]: open,
                            })}
                            onClick={() => history.push("/home")}
                        >
                            <img className={"logoSmall"} src={LogoV} />
                            <div className={"logoFull"}>
                                <VerbalLogo color={"white"} />
                            </div>
                        </ListItem>
                        {categories.map(({ id, children }) => (
                            <React.Fragment key={id}>
                                {children.map(
                                    ({ id: childId, icon, path, permission }) =>
                                        hasPermission(permission) && (
                                            <ListItem
                                                component={Link}
                                                to={path}
                                                key={childId}
                                                button
                                                className={clsx(
                                                    classes.item,
                                                    activeRoute(path) &&
                                                        !subMenuOpen.get("story") &&
                                                        !subMenuOpen.get("help") &&
                                                        !subMenuOpen.get("stats") &&
                                                        !subMenuOpen.get("tours") &&
                                                        classes.itemActiveItem,
                                                    {
                                                        ["nav" + childId.split(" ").join("") + "Button"]: true,
                                                    },
                                                )}
                                                onClick={() =>
                                                    setSubMenuOpen(
                                                        new Map(
                                                            subMenuOpen
                                                                .set("tours", false)
                                                                .set("help", false)
                                                                .set("story", false)
                                                                .set("stats", false),
                                                        ),
                                                    )
                                                }
                                            >
                                                <Tooltip title={childId}>
                                                    <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                                </Tooltip>
                                                <ListItemText
                                                    classes={{
                                                        primary: classes.itemPrimary,
                                                    }}
                                                >
                                                    {childId}
                                                </ListItemText>
                                            </ListItem>
                                        ),
                                )}
                            </React.Fragment>
                        ))}
                        {user && user.isSchool && !user.isVerbal && (
                            <>
                                <ListItem
                                    className={clsx(classes.item)}
                                    button
                                    onClick={() => {
                                        handleMyAccount();
                                    }}
                                >
                                    <Tooltip title="My Account">
                                        <ListItemIcon className={classes.itemIcon}>
                                            <LibraryBooks />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText classes={{ primary: classes.itemPrimary }}>My Account</ListItemText>
                                </ListItem>
                            </>
                        )}
                        {user && user.isVerbal && hasPermission("StoryViewAll") && (
                            <>
                                <ListItem
                                    button
                                    className={clsx(
                                        classes.item,
                                        (activeRoute("/stories") ||
                                            activeRoute("/collections") ||
                                            activeRoute("/curriculums") ||
                                            activeRoute("/themes") ||
                                            subMenuOpen.get("story")) &&
                                            classes.itemActiveItem,
                                        "navStoryCurationButton",
                                    )}
                                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                        setAnchorEl(event.currentTarget);
                                        setSubMenuOpen(
                                            new Map(
                                                subMenuOpen
                                                    .set("story", !subMenuOpen.get("story"))
                                                    .set("tours", false)
                                                    .set("help", false)
                                                    .set("stats", false),
                                            ),
                                        );
                                    }}
                                >
                                    <Tooltip title={"Story Curation"}>
                                        <ListItemIcon className={classes.itemIcon}>
                                            <ImportContacts />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText classes={{ primary: classes.itemPrimary }}>
                                        Story Curation
                                    </ListItemText>
                                </ListItem>
                            </>
                        )}
                        {user && user.isVerbal && hasPermission("StatsViewAll") && (
                            <>
                                <ListItem
                                    button
                                    className={clsx(
                                        classes.item,
                                        (activeRoute("/stats") || subMenuOpen.get("stats")) && classes.itemActiveItem,
                                    )}
                                    onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                                        setAnchorEl(event.currentTarget);
                                        setSubMenuOpen(
                                            new Map(
                                                subMenuOpen
                                                    .set("stats", !subMenuOpen.get("stats"))
                                                    .set("tours", false)
                                                    .set("help", false)
                                                    .set("story", false),
                                            ),
                                        );
                                    }}
                                >
                                    <Tooltip title={"Stats"}>
                                        <ListItemIcon className={classes.itemIcon}>
                                            <PieChart />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText classes={{ primary: classes.itemPrimary }}>Stats</ListItemText>
                                </ListItem>
                            </>
                        )}
                    </List>
                </div>

                <List disablePadding component={"nav"} id="main-nav-bottom">
                    {tourState.currentTour && (
                        <ListItem
                            className={clsx(classes.item, "navTakeTourButton")}
                            button
                            onClick={() => {
                                setTourState((prev) => ({ ...prev, openTour: !prev.openTour }));
                            }}
                        >
                            <Tooltip title="Tour">
                                <ListItemIcon className={classes.itemIcon}>
                                    <DirectionsWalk />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText classes={{ primary: classes.itemPrimary }}>Take Tour</ListItemText>
                        </ListItem>
                    )}

                    <ListItem
                        className={clsx(classes.item, subMenuOpen.get("tours") && classes.itemActiveItem)}
                        button
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            setAnchorEl(event.currentTarget);
                            setSubMenuOpen(
                                new Map(
                                    subMenuOpen
                                        .set("tours", !subMenuOpen.get("tours"))
                                        .set("story", false)
                                        .set("stats", false)
                                        .set("help", false),
                                ),
                            );
                        }}
                    >
                        <Tooltip title="Tour Guide">
                            <ListItemIcon className={classes.itemIcon}>
                                <MapIcon />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText classes={{ primary: classes.itemPrimary }}>Tour Guide</ListItemText>
                    </ListItem>

                    <ListItem
                        className={clsx(classes.item, subMenuOpen.get("help") && classes.itemActiveItem)}
                        button
                        onClick={(event: React.MouseEvent<HTMLDivElement>) => {
                            setAnchorEl(event.currentTarget);
                            setSubMenuOpen(
                                new Map(
                                    subMenuOpen
                                        .set("help", !subMenuOpen.get("help"))
                                        .set("story", false)
                                        .set("stats", false)
                                        .set("tours", false),
                                ),
                            );
                        }}
                    >
                        <Tooltip title="Get Help">
                            <ListItemIcon className={classes.itemIcon}>
                                <HelpOutline />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText classes={{ primary: classes.itemPrimary }}>Get Help</ListItemText>
                    </ListItem>

                    <ListItem
                        onClick={handleDrawerClose}
                        className={clsx(classes.item, {
                            [classes.hide]: !open,
                        })}
                        button
                    >
                        <Tooltip title="Expand Nav">
                            <ListItemIcon className={classes.itemIcon}>
                                <ChevronRight />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            classes={{
                                primary: classes.itemPrimary,
                            }}
                        >
                            Expand
                        </ListItemText>
                    </ListItem>

                    <ListItem
                        onClick={handleDrawerOpen}
                        className={clsx(classes.item, {
                            [classes.hide]: open,
                        })}
                        button
                    >
                        <Tooltip title="Collapse Nav">
                            <ListItemIcon className={classes.itemIcon}>
                                <ChevronLeft />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText
                            classes={{
                                primary: classes.itemPrimary,
                            }}
                        >
                            Collapse
                        </ListItemText>
                    </ListItem>

                    <ListItem onClick={logout} button className={clsx(classes.item)}>
                        <Tooltip title="Logout">
                            <ListItemIcon className={classes.itemIcon}>
                                <ExitToApp />
                            </ListItemIcon>
                        </Tooltip>
                        <ListItemText classes={{ primary: classes.itemPrimary }}>Logout</ListItemText>
                    </ListItem>
                </List>
            </Drawer>

            {/** Sub Drawer */}
            <Popper
                anchorEl={anchorEl}
                placement={"right-end"}
                keepMounted
                popperOptions={{ positionFixed: true }}
                role={undefined}
                className={localClasses.popper}
                open={
                    (subMenuOpen.get("tours") ||
                        subMenuOpen.get("help") ||
                        subMenuOpen.get("story") ||
                        subMenuOpen.get("stats")) ??
                    false
                }
            >
                <Paper className={localClasses.menuPaper} elevation={0}>
                    <MenuItem
                        onClick={() => {
                            setSubMenuOpen(
                                new Map(
                                    subMenuOpen
                                        .set("tours", false)
                                        .set("help", false)
                                        .set("story", false)
                                        .set("stats", false),
                                ),
                            );
                            setAnchorEl(null);
                        }}
                        className={localClasses.subMenuHeader}
                        button
                    >
                        <ListItemIcon className={classes.itemIcon}>
                            <Close />
                        </ListItemIcon>
                    </MenuItem>
                    <Divider style={{ marginBottom: "8px" }} />
                    {subMenuOpen.get("tours") ? (
                        <>
                            <MenuItem
                                component={Link}
                                to="/home"
                                button
                                className={clsx(classes.item, activeRoute("/home") && classes.itemActiveItem)}
                                onClick={() => {
                                    setSubMenuOpen(
                                        new Map(
                                            subMenuOpen
                                                .set("tours", false)
                                                .set("help", false)
                                                .set("stats", false)
                                                .set("story", false),
                                        ),
                                    );
                                    setAnchorEl(null);
                                    setTourState((prev) => ({ ...prev, openTour: true }));
                                }}
                            >
                                <Tooltip title="Tour Home">
                                    <ListItemIcon className={classes.itemIcon}>
                                        <DirectionsWalk />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Tour Home</ListItemText>
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to="/explore"
                                button
                                className={clsx(classes.item, activeRoute("/explore") && classes.itemActiveItem)}
                                onClick={() => {
                                    setSubMenuOpen(
                                        new Map(
                                            subMenuOpen
                                                .set("tours", false)
                                                .set("help", false)
                                                .set("stats", false)
                                                .set("story", false),
                                        ),
                                    );
                                    setAnchorEl(null);
                                }}
                            >
                                <Tooltip title="Tour Explore">
                                    <ListItemIcon className={classes.itemIcon}>
                                        <DirectionsWalk />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Tour Explore</ListItemText>
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to="/classrooms"
                                button
                                className={clsx(classes.item, activeRoute("/classrooms") && classes.itemActiveItem)}
                                onClick={() => {
                                    setSubMenuOpen(
                                        new Map(
                                            subMenuOpen
                                                .set("tours", false)
                                                .set("help", false)
                                                .set("stats", false)
                                                .set("story", false),
                                        ),
                                    );
                                    setAnchorEl(null);
                                    setTourState((prev) => ({ ...prev, openTour: true }));
                                }}
                            >
                                <Tooltip title="Tour Classrooms">
                                    <ListItemIcon className={classes.itemIcon}>
                                        <DirectionsWalk />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Tour Classrooms</ListItemText>
                            </MenuItem>

                            <MenuItem
                                component={Link}
                                to="/add-users"
                                button
                                className={clsx(classes.item, activeRoute("/add-users") && classes.itemActiveItem)}
                                onClick={() => {
                                    setSubMenuOpen(
                                        new Map(
                                            subMenuOpen
                                                .set("tours", false)
                                                .set("help", false)
                                                .set("stats", false)
                                                .set("story", false),
                                        ),
                                    );
                                    setAnchorEl(null);
                                    setTourState((prev) => ({ ...prev, openTour: true }));
                                }}
                            >
                                <Tooltip title="Tour Add User">
                                    <ListItemIcon className={classes.itemIcon}>
                                        <DirectionsWalk />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Tour Add User</ListItemText>
                            </MenuItem>

                            {RegExp(/^\/classrooms\/view\/\d+\/view\/\d+$/i).test(location.pathname) &&
                                tourState.currentTour && (
                                    <MenuItem
                                        className={clsx(classes.item, "navTakeTourButton")}
                                        button
                                        onClick={() => {
                                            setSubMenuOpen(
                                                new Map(
                                                    subMenuOpen
                                                        .set("tours", !subMenuOpen.get("tours"))
                                                        .set("story", false)
                                                        .set("stats", false)
                                                        .set("help", false),
                                                ),
                                            );
                                            setAnchorEl(null);
                                            setTourState((prev) => ({
                                                ...prev,
                                                nextTourKey: "deliverSessionPart1",
                                            }));
                                            setTimeout(() => {
                                                setTourState((prev) => ({
                                                    ...prev,
                                                    openTour: true,
                                                }));
                                            }, 300);
                                        }}
                                    >
                                        <Tooltip title="Tour">
                                            <ListItemIcon className={classes.itemIcon}>
                                                <Build />
                                            </ListItemIcon>
                                        </Tooltip>
                                        <ListItemText classes={{ primary: classes.itemPrimary }}>
                                            Tour Session
                                        </ListItemText>
                                    </MenuItem>
                                )}
                        </>
                    ) : null}

                    {subMenuOpen.get("help") ? (
                        <>
                            <MenuItem button className={clsx(classes.item)}>
                                <a
                                    href="https://www.verbalwellbeing.com/frequently-asked-questions/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Tooltip title="Visit Website">
                                        <ListItemIcon className={classes.itemIcon}>
                                            <QuestionAnswerOutlined />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText classes={{ primary: classes.itemPrimary }}>FAQs</ListItemText>
                                </a>
                            </MenuItem>
                            <MenuItem className={classes.item} button onClick={() => setVideosOpen(true)}>
                                <Tooltip title="Tutorial Videos">
                                    <ListItemIcon className={classes.itemIcon}>
                                        <OndemandVideoOutlined />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Tutorial Videos</ListItemText>
                            </MenuItem>

                            <MenuItem button className={clsx(classes.item)}>
                                <a
                                    href="https://www.verbalwellbeing.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                        color: "inherit",
                                        textDecoration: "none",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <Tooltip title="Visit Website">
                                        <ListItemIcon className={classes.itemIcon}>
                                            <WebOutlined />
                                        </ListItemIcon>
                                    </Tooltip>
                                    <ListItemText classes={{ primary: classes.itemPrimary }}>
                                        Visit Website
                                    </ListItemText>
                                </a>
                            </MenuItem>

                            <MenuItem
                                className={clsx(classes.item)}
                                button
                                onClick={() => {
                                    if (window.FreshworksWidget) {
                                        window.FreshworksWidget("clear", "ticketForm");
                                        window.FreshworksWidget("identify", "ticketForm", {
                                            name: user?.firstName,
                                            email: user?.email,
                                        });
                                        window.FreshworksWidget("open");
                                    }
                                }}
                            >
                                <Tooltip title="Contact Us">
                                    <ListItemIcon className={classes.itemIcon}>
                                        <ContactSupportOutlined />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Contact Us</ListItemText>
                            </MenuItem>
                        </>
                    ) : null}

                    {subMenuOpen.get("story") ? (
                        <>
                            {storyCategories.map(({ id, children }) => (
                                <React.Fragment key={id}>
                                    {children.map(
                                        ({ id: childId, icon, path, permission }) =>
                                            hasPermission(permission as Permission) && (
                                                <MenuItem
                                                    component={Link}
                                                    to={path}
                                                    key={childId}
                                                    button
                                                    className={clsx(
                                                        classes.item,
                                                        activeRoute(path) && classes.itemActiveItem,
                                                    )}
                                                    onClick={() => {
                                                        setSubMenuOpen(
                                                            new Map(
                                                                subMenuOpen
                                                                    .set("tours", false)
                                                                    .set("help", false)
                                                                    .set("stats", false)
                                                                    .set("story", false),
                                                            ),
                                                        );
                                                        setAnchorEl(null);
                                                    }}
                                                >
                                                    <Tooltip title={childId}>
                                                        <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.itemPrimary,
                                                        }}
                                                    >
                                                        {childId}
                                                    </ListItemText>
                                                </MenuItem>
                                            ),
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    ) : null}

                    {subMenuOpen.get("stats") ? (
                        <>
                            {statsCategories.map(({ id, children }) => (
                                <React.Fragment key={id}>
                                    {children.map(
                                        ({ id: childId, icon, path, permission }) =>
                                            hasPermission(permission as Permission) && (
                                                <MenuItem
                                                    component={Link}
                                                    to={path}
                                                    key={childId}
                                                    button
                                                    className={clsx(
                                                        classes.item,
                                                        activeRoute(path) && classes.itemActiveItem,
                                                    )}
                                                    onClick={() => {
                                                        setSubMenuOpen(
                                                            new Map(
                                                                subMenuOpen
                                                                    .set("tours", false)
                                                                    .set("help", false)
                                                                    .set("stats", false)
                                                                    .set("story", false),
                                                            ),
                                                        );
                                                        setAnchorEl(null);
                                                    }}
                                                >
                                                    <Tooltip title={childId}>
                                                        <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                                    </Tooltip>
                                                    <ListItemText
                                                        classes={{
                                                            primary: classes.itemPrimary,
                                                        }}
                                                    >
                                                        {childId}
                                                    </ListItemText>
                                                </MenuItem>
                                            ),
                                    )}
                                </React.Fragment>
                            ))}
                        </>
                    ) : null}
                </Paper>
            </Popper>
            <TutorialVideosDialog open={videosOpen} setOpen={setVideosOpen} />
            <SubscriptionContactDialog open={subDialogOpen} setOpen={setSubDialogOpen} />
            <SubscriptionExpiredDialog open={subExpiredDialogOpen} setOpen={setSubExpiredDialogOpen} />
        </>
    );
}

export default withStyles(styles)(Navigator);
