import React from "react";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { Box, Link } from "@material-ui/core";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import { SecondaryButton } from "../../../layout/Button/Button";
import { useHistory } from "react-router";
import { Alert } from "@material-ui/lab";

export const LiveSessionBanner = (): JSX.Element => {
    const { item } = useFetch<ActiveProgramStats>();
    const liveSessions = item?.sessions.filter((session) => session.state === "InProgress" && session.isOnline);
    const history = useHistory();

    return (
        <>
            {liveSessions
                ? liveSessions.map((session) => (
                      <Box mb={2} key={session.id}>
                          <Alert
                              variant="filled"
                              severity="error"
                              action={
                                  <SecondaryButton
                                      variant="outlined"
                                      style={{ color: "white", borderColor: "white" }}
                                      onClick={() => history.push(`/start-session/${session.id}`)}
                                  >
                                      Join Live Session
                                  </SecondaryButton>
                              }
                              icon={<OndemandVideoIcon />}
                          >
                              A session you are facilitating on the programme{" "}
                              <Link
                                  color="inherit"
                                  component="button"
                                  onClick={() => history.push(`/start-session/${session.id}`)}
                              >
                                  {session?.Programme?.name}
                              </Link>{" "}
                              is live now
                          </Alert>
                      </Box>
                  ))
                : null}
        </>
    );
};
