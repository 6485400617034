import React from "react";
import { useAuth } from "../../../../contexts/auth.context";
import { Redirect } from "react-router-dom";
import { StoryCollectionType } from "../../../../models/modelTypes";
import { Crud } from "../../../../core/components/crud/Crud";
import { get, list, update } from "../../../../client/api/collection";
import { collectionsCRUDModel } from "./model";
import { CreateCollectionWizard } from "../wizard/CreateCollectionWizard";
import { ViewCollection } from "./ViewCollection";
import { StoryCollectionProvider } from "../contexts/collection.context";
import { CollectionListParameters } from "common/src/api-parameters/collections";
import { EditCollection } from "./EditCollection";

export const CollectionsScreen = (): JSX.Element => {
    const { user, hasPermission } = useAuth();
    const params = {
        includeMappedStory: true,
        includeThemes: true,
        includeCurriculum: true,
    } as CollectionListParameters;

    if (!user) {
        return <Redirect to="/403" />;
    }

    return (
        <StoryCollectionProvider>
            <Crud<StoryCollectionType, Record<string, unknown>>
                model={collectionsCRUDModel}
                noun="Story"
                plural="Story Collections"
                getData={list}
                listParams={params}
                itemParams={params}
                getItem={get}
                viewOnClick
                labelProperty={"name"}
                addComponent={CreateCollectionWizard}
                canAdd={hasPermission("StoryCreate")}
                viewComponent={ViewCollection}
                editComponent={EditCollection}
                updateItem={update}
                searchable
            />
        </StoryCollectionProvider>
    );
};
