import React from "react";
import SignUp from "../../../core/components/SignUp";
import { useOnboarding } from "./contexts/onboarding.context";

const OrgansationSignUp: React.FC = () => {
    const { signUp, details: { email = "", password = "" } = {} } = useOnboarding();

    return <SignUp onSignUp={signUp} initialEmail={email} initialPassword={password || ""} />;
};

export default OrgansationSignUp;
