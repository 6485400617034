import { Grid, TextField, Typography } from "@material-ui/core";
import { Formik, FieldArray, FormikErrors } from "formik";
import * as yup from "yup";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { FormActions } from "../../../../../core/components/FormActions";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { PrimaryButton, SecondaryButton } from "../../../../layout/Button/Button";
import { useStoryContext } from "../../story.context";

export const StoryParts = (): JSX.Element => {
    const { details, changeStep, submitStep, addNewStory, clearProvider, story, updateStory } = useStoryContext();
    const history = useHistory();

    const initialValues = {
        parts: details?.parts?.length
            ? details.parts
            : Array.from({ length: details.numParts }).map((_item, index) => {
                  return { part: index, content: "", figurativeNotes: "", imageUrl: "" };
              }),
    };

    const onSubmit = (values: {
        parts: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
    }) => {
        submitStep();
        const storyDetails = {
            id: details.id,
            storyName: details.storyName,
            author: details.author,
            ageRange: details.ageRange,
            synopsis: details.synopsis,
            resources: details.resources,
            resourcesROI: details.resourcesROI,
            type: details.type,
            overview: details.overview,
            themeIds: details.themeIds,
            colour: details.colour,
            ...values,
        };
        if (story && story.id) {
            updateStory(story.id, storyDetails).then(() => {
                clearProvider();
                history.push(`/stories/view/${story.id}`);
            });
        } else {
            addNewStory(storyDetails).then(() => {
                clearProvider();
                history.push("/stories");
            });
        }
    };

    return (
        <>
            <WizardHeading overline={`${story ? "Edit" : "Create"} a Story`} heading="Add Parts" />
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={yup.object().shape({
                    parts: yup.array().of(
                        yup.object().shape({
                            imageUrl: yup
                                .string()
                                .url("This must be a valid URL")
                                .nullable(true)
                                .transform((_, val) => (typeof val === "string" && val.length > 0 ? val : null)),
                        }),
                    ),
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange }) => {
                    return (
                        <Grid container spacing={3}>
                            <FieldArray name="parts">
                                {() =>
                                    values.parts.map((part, index) => {
                                        const errorMsg = (
                                            errors?.parts as FormikErrors<{
                                                part: number;
                                                content: string;
                                                figurativeNotes: string;
                                                imageUrl: string | null;
                                            }>[]
                                        )?.[index]?.imageUrl;

                                        const hasError = errorMsg ? true : false;

                                        if (typeof part.imageUrl === "string" && part.imageUrl.length == 0) {
                                            part.imageUrl = null;
                                        }

                                        return (
                                            <Fragment key={part.part}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h4">{`Story Part ${
                                                        part.part + 1
                                                    }`}</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        multiline
                                                        variant="outlined"
                                                        name={`parts.${index}.content`}
                                                        label={`Part ${part.part + 1} Content`}
                                                        value={part.content}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        required
                                                        fullWidth
                                                        multiline
                                                        variant="outlined"
                                                        name={`parts.${index}.figurativeNotes`}
                                                        label={`Part ${part.part + 1} Figurative Notes`}
                                                        value={part.figurativeNotes}
                                                        onChange={handleChange}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        variant="outlined"
                                                        name={`parts.${index}.imageUrl`}
                                                        label={`Part ${part.part + 1} Image URL`}
                                                        value={part.imageUrl}
                                                        onChange={handleChange}
                                                        helperText={errorMsg}
                                                        error={hasError}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        );
                                    })
                                }
                            </FieldArray>

                            <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Input fields marked with an asterisk (*) are required
                            </Grid>

                            <Grid item xs={12}>
                                <FormActions
                                    left={
                                        <SecondaryButton size="large" onClick={() => changeStep(1)}>
                                            Back
                                        </SecondaryButton>
                                    }
                                    right={
                                        <PrimaryButton size="large" onClick={submitForm}>
                                            Save Story
                                        </PrimaryButton>
                                    }
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </>
    );
};
