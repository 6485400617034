import React, { ReactNode, useCallback, useState } from "react";
import { StoryCollectionType } from "../../../../models/modelTypes";
import { node } from "prop-types";
import { useIntQueryStringState } from "../../../../hooks/useQueryString";
import {
    add,
    addStoryToCollection,
    addWeeksToCollection,
    approve,
    review,
    update,
} from "../../../../client/api/collection";

interface StoryCollectionContextI {
    activeStep: number;
    completedSteps: { [k: number]: boolean };
    details: Partial<StoryCollectionType> & { numWeeks?: number };
    collection: StoryCollectionType | undefined;
    loading: boolean;

    setCurrentCollection: (collection?: StoryCollectionType) => void;
    changeStep: (newStep: number) => void;

    submitStep: (
        details?: Partial<StoryCollectionType> & {
            numWeeks?: number;
        },
    ) => void;

    addNewCollection: (collectionDetails: Partial<StoryCollectionType>) => Promise<void>;
    addCollectionWeeks: (
        collectionId: number,
        weeks: { order: number; learningObjective: string; themeId: number; storyId: number }[],
    ) => Promise<void>;
    updateCollection: (collectionId: number, collection: Partial<StoryCollectionType>) => Promise<void>;
    approveCollection: (collectionId: number) => Promise<void>;
    reviewCollection: (collectionId: number) => Promise<void>;
    addCollectionStory: (collectionId: number, story: { order: number; storyId: number }) => Promise<void>;

    clearProvider: () => Promise<void>;
}

const StoryCollectionContext = React.createContext({} as StoryCollectionContextI);

export const StoryCollectionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const initialDetails: Partial<StoryCollectionType> & {
        numWeeks?: number;
    } = {
        name: "",
        description: "",
        ageRange: "",
        specialTraining: false,
        type: "Group",
        curriculumId: 0,
        numWeeks: 1,
    };

    const [activeStep, setActiveStep] = useIntQueryStringState("step", 0);
    const [collection, setCollection] = useState<StoryCollectionType | undefined>();
    const [completedSteps, setCompletedSteps] = useState<{ [k: number]: boolean }>({});
    const [details, setDetails] = useState<Partial<StoryCollectionType> & { numWeeks?: number }>(initialDetails);
    const [loading, setLoading] = useState<boolean>(false);

    const setCurrentCollection = useCallback((collection?: StoryCollectionType) => {
        setCollection(collection);
    }, []);

    const changeStep = useCallback((newStep: number) => {
        setActiveStep(newStep);
    }, []);

    const submitStep = useCallback(
        (
            details?: Partial<StoryCollectionType> & {
                numWeeks?: number;
            },
        ) => {
            if (details) {
                setDetails(details);
            }
            setCompletedSteps((prevCompletedSteps) => ({ ...prevCompletedSteps, [activeStep]: true }));
        },
        [activeStep],
    );

    const addNewCollection = useCallback((collectionDetails: Partial<StoryCollectionType>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            add(collectionDetails)
                .then(async (response) => {
                    if (response.ok) {
                        const collectionResponse: StoryCollectionType = await response.json();
                        setCollection(collectionResponse);
                        resolve();
                    } else {
                        setCollection(undefined);
                        reject("Unable to add collection");
                    }
                })
                .catch(() => {
                    setCollection(undefined);
                    reject("Unable to add collection");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addCollectionWeeks = useCallback(
        (
            collectionId: number,
            weeks: { order: number; learningObjective: string; themeId: number; storyId: number }[],
        ) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                addWeeksToCollection(collectionId, weeks)
                    .then(async (response) => {
                        if (response.ok) {
                            const collectionResponse: StoryCollectionType = await response.json();
                            setCollection(collectionResponse);
                            resolve();
                        } else {
                            setCollection(undefined);
                            reject("Unable to add stories");
                        }
                    })
                    .catch(() => {
                        setCollection(undefined);
                        reject("Unable to add stories");
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const clearProvider = useCallback(() => {
        return new Promise<void>((resolve) => {
            setCollection(undefined);
            setDetails(initialDetails);
            setActiveStep(0);
            setCompletedSteps({});
            resolve();
        });
    }, []);

    const updateCollection = useCallback((collectionId: number, collection: Partial<StoryCollectionType>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            update(collectionId, collection)
                .then(async (response) => {
                    if (response.ok) {
                        const collectionResponse: StoryCollectionType = await response.json();
                        setCollection(collectionResponse);
                        resolve();
                    } else {
                        setCollection(undefined);
                        reject("Unable to update collection");
                    }
                })
                .catch(() => {
                    setCollection(undefined);
                    reject("Unable to update collection");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const approveCollection = useCallback((collectionId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            approve(collectionId)
                .then(async (response) => {
                    if (response.ok) {
                        const collectionResponse: StoryCollectionType = await response.json();
                        setCollection(collectionResponse);
                        resolve();
                    } else {
                        setCollection(undefined);
                        reject("Unable to approve collection");
                    }
                })
                .catch(() => {
                    setCollection(undefined);
                    reject("Unable to update collection");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const reviewCollection = useCallback((collectionId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            review(collectionId)
                .then(async (response) => {
                    if (response.ok) {
                        const collectionResponse: StoryCollectionType = await response.json();
                        setCollection(collectionResponse);
                        resolve();
                    } else {
                        setCollection(undefined);
                        reject("Unable to review collection");
                    }
                })
                .catch(() => {
                    setCollection(undefined);
                    reject("Unable to update collection");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const addCollectionStory = useCallback((collectionId: number, story: { order: number; storyId: number }) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            addStoryToCollection(collectionId, story)
                .then(async (response) => {
                    if (response.ok) {
                        const collectionResponse: StoryCollectionType = await response.json();
                        setCollection(collectionResponse);
                        resolve();
                    } else {
                        setCollection(undefined);
                        reject("Unable to add story to collection");
                    }
                })
                .catch(() => {
                    setCollection(undefined);
                    reject("Unable to add story to collection");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    return (
        <StoryCollectionContext.Provider
            value={{
                activeStep,
                completedSteps,
                details,
                changeStep,
                submitStep,
                collection,
                addNewCollection,
                loading,
                addCollectionWeeks,
                clearProvider,
                updateCollection,
                setCurrentCollection,
                approveCollection,
                reviewCollection,
                addCollectionStory,
            }}
        >
            {children}
        </StoryCollectionContext.Provider>
    );
};

StoryCollectionProvider.propTypes = {
    children: node,
};

export const useStoryCollection = (): StoryCollectionContextI =>
    React.useContext<StoryCollectionContextI>(StoryCollectionContext);
