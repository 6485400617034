import { Box, createStyles, Dialog, Grid, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { Close } from "@material-ui/icons";
import happy from "../../../img/Happy.png";
import sad from "../../../img/sad.png";
import angry from "../../../img/angry.png";
import calm from "../../../img/Calm.png";
import worried from "../../../img/Worried.png";
import school from "../../../img/school.png";
import family from "../../../img/family.png";
import friends from "../../../img/friends2.png";
import health from "../../../img/health.png";
import bully from "../../../img/bully.png";
import question from "../../../img/question.png";
import { StepperWizard } from "../../../core/components/wizard/StepperWizard";
import WizardStep from "../../../core/components/wizard/WizardStep";
import { FormActions } from "../../../core/components/FormActions";
import { PrimaryButton, SecondaryButton } from "../../layout/Button/Button";
import { StudentCheckInGrid } from "../components/StudentCheckInGrid";
import { useClassroom } from "../contexts/classroom.context";
import { CheckInMoodCard } from "./CheckInMoodCard";

type StudentCheckInProps = {
    open: boolean;
    moodType: string;
    sessionId?: number;
    setOpen: (open: boolean) => void;
    handleSetMood: (mood: string, reason: string) => void;
    moodTrackerClose: () => void;
};

export const StudentCheckIn: FC<StudentCheckInProps> = (props: StudentCheckInProps): JSX.Element => {
    const { open, setOpen, handleSetMood, moodTrackerClose, moodType, sessionId } = props;

    const { currentParticipant } = useClassroom();

    const [mood, setMood] = useState<string>("");
    const [activeStep, setActiveStep] = useState<number>(0);
    const [completedSteps, setCompletedSteps] = useState<{ [k: number]: boolean }>({});

    const handleSelectStudent = () => {
        setCompletedSteps({ ...completedSteps, 0: true });
        setActiveStep(1);
    };

    const handleSelectMood = (newMood: string, step?: number) => {
        setMood(newMood);
        if (step === 2) {
            setCompletedSteps({ ...completedSteps, 1: true });
            setActiveStep(step);
        } else {
            handleFinish(newMood, "");
        }
    };

    const handleFinish = (mood: string, reason: string) => {
        handleSetMood(mood, reason);
        setMood("");
        setCompletedSteps({});
        setActiveStep(0);
    };

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Dialog
                open={open}
                fullScreen
                onClose={() => {
                    setOpen(false);
                }}
            >
                <MuiDialogTitle>
                    <Box display="flex" justifyContent={"center"} flexDirection="column">
                        {activeStep === 1 ? (
                            <Typography variant="h2" align="center">
                                Hello {currentParticipant?.Participant?.name}, How are you feeling today?
                            </Typography>
                        ) : activeStep === 2 ? (
                            <Typography variant="h2" align="center">
                                Why do you feel {mood} today?
                            </Typography>
                        ) : null}
                    </Box>

                    <IconButton
                        className={classes.closeButton}
                        aria-label="close-profile"
                        onClick={() => setOpen(false)}
                    >
                        <Close />
                    </IconButton>
                </MuiDialogTitle>
                <Box maxWidth={"lg"} display="flex" justifyContent={"center"} p={4} height="100%">
                    <StepperWizard
                        activeStep={activeStep}
                        goToStep={setActiveStep}
                        orientation="horizontal"
                        hideStepper
                    >
                        <WizardStep identifier={0} heading={"Present"} completed={completedSteps[0]}>
                            <StudentCheckInGrid
                                sessionId={sessionId}
                                moodType={moodType}
                                handleSelectStudent={handleSelectStudent}
                            />
                            <Box display={"flex"} justifyContent={"center"} p={3}>
                                <PrimaryButton onClick={moodTrackerClose} size="large">
                                    Close
                                </PrimaryButton>
                            </Box>
                        </WizardStep>

                        <WizardStep identifier={1} heading={`Mood`} completed={completedSteps[1]}>
                            <Grid container spacing={6} justifyContent="center" style={{ height: "100%" }}>
                                <CheckInMoodCard
                                    title="Happy"
                                    img={happy}
                                    selectMood={() => handleSelectMood("Happy")}
                                    selected={mood}
                                />

                                <CheckInMoodCard
                                    title="Sad"
                                    img={sad}
                                    selectMood={() => handleSelectMood("Sad", 2)}
                                    selected={mood}
                                />

                                <CheckInMoodCard
                                    title="Calm"
                                    selectMood={() => handleSelectMood("Calm")}
                                    img={calm}
                                    selected={mood}
                                />

                                <CheckInMoodCard
                                    title="Angry"
                                    selectMood={() => handleSelectMood("Angry", 2)}
                                    img={angry}
                                    selected={mood}
                                />

                                <CheckInMoodCard
                                    title="Worried"
                                    selectMood={() => handleSelectMood("Worried", 2)}
                                    img={worried}
                                    selected={mood}
                                />

                                <Grid item xs={12}>
                                    <FormActions
                                        left={
                                            <>
                                                <SecondaryButton
                                                    size="large"
                                                    onClick={() => {
                                                        setCompletedSteps({ ...completedSteps, 0: false, 1: false });
                                                        setActiveStep(0);
                                                    }}
                                                >
                                                    Back
                                                </SecondaryButton>
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </WizardStep>

                        <WizardStep identifier={2} heading={`Reason`} completed={completedSteps[2]}>
                            <Grid container spacing={6} style={{ height: "100%" }}>
                                <CheckInMoodCard
                                    lg={3}
                                    sm={4}
                                    title="School"
                                    img={school}
                                    selectMood={() => handleFinish(mood, "School")}
                                />

                                <CheckInMoodCard
                                    lg={3}
                                    sm={4}
                                    title="Family"
                                    img={family}
                                    selectMood={() => handleFinish(mood, "Family")}
                                />

                                <CheckInMoodCard
                                    lg={3}
                                    sm={4}
                                    title="Friends"
                                    img={friends}
                                    selectMood={() => handleFinish(mood, "Friends")}
                                />

                                <CheckInMoodCard
                                    lg={3}
                                    sm={4}
                                    title="Bullying"
                                    img={bully}
                                    selectMood={() => handleFinish(mood, "Bullying")}
                                />

                                <CheckInMoodCard
                                    lg={3}
                                    sm={4}
                                    title="Health"
                                    img={health}
                                    selectMood={() => handleFinish(mood, "Health")}
                                />

                                <CheckInMoodCard
                                    lg={3}
                                    sm={4}
                                    title="Other"
                                    img={question}
                                    selectMood={() => handleFinish(mood, "Other")}
                                />

                                <Grid item xs={12}>
                                    <FormActions
                                        left={
                                            <>
                                                <SecondaryButton
                                                    size="large"
                                                    onClick={() => {
                                                        setCompletedSteps({ ...completedSteps, 1: false, 2: false });
                                                        setMood("");
                                                        setActiveStep(1);
                                                    }}
                                                >
                                                    Back
                                                </SecondaryButton>
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </WizardStep>
                    </StepperWizard>
                </Box>
            </Dialog>
        </>
    );
};
