import React from "react";
import { Button, Grid } from "@material-ui/core";
import { VModule, VModuleType } from "../../core/components/VModule";
import { MappedStoryType } from "../../models/modelTypes";
import { MappedStoryDetails } from "../story/components/MappedStoryDetails";
import { useHistory } from "react-router-dom";

type StoryOverviewProps = {
    story?: MappedStoryType;
};

export const StoryOverview: React.FC<StoryOverviewProps> = (props: StoryOverviewProps): JSX.Element => {
    const { story } = props;
    const history = useHistory();

    return (
        <Grid item xs={12}>
            <VModule type={VModuleType.Shade2}>
                <MappedStoryDetails story={story} />
                <Button
                    variant={"contained"}
                    color={"primary"}
                    size={"large"}
                    fullWidth
                    onClick={() => {
                        history.push(`/read-story/${story?.id}/`);
                    }}
                >
                    Read Story
                </Button>
            </VModule>
        </Grid>
    );
};

export default StoryOverview;
