import React, { useState } from "react";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { Redirect, useParams } from "react-router-dom";
import * as yup from "yup";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useAuth } from "../../contexts/auth.context";
import { PasswordMaxLength, PasswordMinLength, PasswordValidityChecks } from "common/build/api-parameters/users";
import { useMobile } from "../../hooks/useMobile";

const matchError = "Must contain a mixed case alphabetic characters, digits, and symbols";

export const SetPassword: React.FC<{ reset?: boolean }> = ({ reset = false }) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const loading = false;
    const { setPassword } = useAuth();
    const { isTablet, isMobile } = useMobile();

    const { token = "" } = useParams<{ token?: string }>();
    const onSubmit: (values: { password: string }) => Promise<void> = async ({ password }) => {
        try {
            await setPassword({ token, password });
            setSuccess(true);
        } catch (error) {
            console.error(error);
            setError(true);
        }
    };

    if (success) {
        if (!isTablet && isMobile) {
            return <Redirect to="/detect-mobile" />;
        } else {
            return <Redirect to="/home" />;
        }
    }

    if (error) {
        return (
            <Alert severity="error">
                This link is no longer valid.
                <br />
                To continue, please use the{" "}
                <Link variant="body1" color="inherit" underline="always" href="/login">
                    Login
                </Link>{" "}
                screen.
            </Alert>
        );
    }

    const title = reset ? "Reset Your Password" : "Secure Your Account";
    const brief = reset
        ? "Set a new, memorable password."
        : "Your account is almost ready! You just need to set a secure password to access your account in future";
    const buttonText = reset ? "Reset Password & Sign In" : "Set Password & Sign In";

    return (
        <Container maxWidth="sm">
            <Box mb={4}>
                <Typography variant="h2" component="h1" color="textPrimary" align="left">
                    {title}
                </Typography>
            </Box>
            <Box mb={2}>
                <Typography color="textSecondary" align="left">
                    {brief}
                </Typography>
            </Box>
            <Box mb={2}>
                <Typography color="textSecondary" align="left">
                    A secure password should be at least 8 characters long, containing mixed case characters, digits and
                    symbols.
                </Typography>
            </Box>
            <Formik
                initialValues={{
                    password: "",
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    password: PasswordValidityChecks.reduce(
                        (validation, expression) => validation.matches(expression, matchError),
                        yup.string().required("Password is required").min(PasswordMinLength).max(PasswordMaxLength),
                    ),
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting }) => {
                    return (
                        <Form>
                            <FormControl fullWidth margin={"normal"}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="password"
                                    label="Password"
                                    variant="outlined"
                                    value={values.password}
                                    onChange={handleChange}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    autoComplete="on"
                                    helperText={errors.password && touched.password && errors.password}
                                    error={errors.password ? true : false}
                                />
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <Button
                                    type="submit"
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                    fullWidth
                                >
                                    {!loading ? buttonText : <CircularProgress />}
                                </Button>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};

export const ResetPassword: React.FC = () => <SetPassword reset />;
