import { Box, Divider, Typography, useTheme } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ParticipantType, SessionType } from "../../../models/modelTypes";
import { date } from "../../../utils/date";
import { API_URL } from "../../../client/api/rest";
import { VModule, VModuleType } from "../../../core/components/VModule";
import { useHistory } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import { MappedStoryDetails } from "../../story/components/MappedStoryDetails";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { SessionFetchParameters } from "common/src/api-parameters/session";
import { Error } from "../../../core/components/Error";

interface ParticipantNextSessionDetailsProps {
    sessionId: number;
    participant: ParticipantType;
}

export const ParticipantNextSessionDetails: React.FC<ParticipantNextSessionDetailsProps> = (
    props: ParticipantNextSessionDetailsProps,
) => {
    const { sessionId, participant } = props;
    const { item, fetchItem } = useFetch<SessionType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [sessionState, setSessionState] = useState<string>("NotStarted");
    const [admitted, setAdmitted] = useState<boolean>(false);
    const [isOnline, setIsOnline] = useState<boolean>(false);
    const [enteringSession, setEnteringSession] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const history = useHistory();

    useEffect(() => {
        if (sessionId > 0) {
            setLoading(true);
            fetchItem(sessionId, { includeStory: true } as SessionFetchParameters)
                .then(() => {
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [sessionId]);

    useEffect(() => {
        if (item) {
            setSessionState(item.state);
            setAdmitted(item.isUsersAdmitted);
            setIsOnline(item.isOnline);
        }

        if (item?.isOnline) {
            const events = new EventSource(`${API_URL}/session/${sessionId}/updates`, {
                withCredentials: true,
            });

            events.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.state) {
                    setSessionState(data.state);
                }

                if (data.isUsersAdmitted) {
                    setAdmitted(data.isUsersAdmitted);
                }
            };

            return () => {
                events.close();
            };
        }
    }, [item]);

    useEffect(() => {
        if (isOnline && sessionState === "InProgress" && admitted) {
            setEnteringSession(true);
            setTimeout(() => {
                setEnteringSession(false);
                history.push(`/participant/${participant.id}/online-session/${sessionId}`);
            }, 3000);
        }
        if (!isOnline && sessionState === "InProgress") {
            setEnteringSession(true);
            setTimeout(() => {
                setEnteringSession(false);
                history.push(`/participant/${participant.id}/session/${sessionId}`);
            }, 3000);
        }

        // Todo: We should just push to the dashboard once we routing for these pages.
        if (sessionState === "Complete") {
            // history.push(`/participant/dashboard`);
            history.go(0);
        }
    }, [participant, isOnline, sessionState, admitted]);

    const theme = useTheme();

    if (loading) {
        return <Spinner />;
    }

    if (!item || error) {
        return <Error description={`Unable to load session`} />;
    }

    return (
        <>
            <Box p={3} color={theme.palette.common.white}>
                <Typography variant="h4" gutterBottom>
                    {participant.name}
                </Typography>
                <Box mb={2}>
                    <Divider light />
                </Box>
                <Box mb={2}>
                    <Typography variant="h6" color={"textSecondary"} gutterBottom>
                        Next Session
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        {item.isOnline ? "Online" : "In Person"} / {date(item.date)}
                        {item.MappedStory?.StoryCollection &&
                            item.MappedStory?.StoryCollection.type === "Group" &&
                            `/ ${item.MappedStory?.StoryCollection.Organisation.name}`}
                    </Typography>
                </Box>
                <VModule type={VModuleType.Shade2}>
                    <MappedStoryDetails session={item} />

                    <VModule type={VModuleType.Shade3}>
                        <Box color={theme.palette.common.white}>
                            {sessionState === "NotStarted" && (
                                <Typography variant="body2" gutterBottom>
                                    The session is due to start
                                    {date(item.date).includes("Today")
                                        ? " " + date(item.date)
                                        : " on " + date(item.date)}{" "}
                                    We&apos;re looking forward to seeing you.
                                </Typography>
                            )}
                            {sessionState === "InProgress" && !admitted && item.isOnline && (
                                <Typography variant="body2" gutterBottom>
                                    Your facilitator is preparing the session and will let you in shortly.
                                </Typography>
                            )}
                            {sessionState === "InProgress" && enteringSession && (
                                <Box textAlign="center">
                                    <Box mb={2}>
                                        <Spinner />
                                    </Box>
                                    <Typography variant="subtitle2" gutterBottom>
                                        Okay, we&apos;re bringing you into the session now.
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </VModule>
                </VModule>
            </Box>
        </>
    );
};
