import { Chip, createStyles, FormControl, Grid, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import React, { useEffect } from "react";
import { useFacilitator } from "../../../hooks/useFacilitator";
import { ClassroomType } from "../../../models/modelTypes";

type TeacherSelectProps = {
    currentUserId?: number;
    setFieldValue: (field: string, value?: number) => void;
    values: Partial<ClassroomType> & { isNotPrimary: boolean };
    errors?: Partial<ClassroomType> & { isNotPrimary: boolean };
};

export const TeacherSelect = (props: TeacherSelectProps): JSX.Element => {
    const { currentUserId, setFieldValue, values, errors } = props;

    const { facilitators, getAllFacilitators } = useFacilitator();

    useEffect(() => {
        getAllFacilitators();
    }, []);

    const useStyles = makeStyles(
        createStyles({
            teacherMenuItem: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "right",
            },
        }),
    );

    const classes = useStyles();

    return values.isNotPrimary ? (
        <Grid item xs={12}>
            <FormControl variant="outlined" error={!!errors?.primaryUserId} fullWidth>
                <InputLabel id="teacher-label" required>
                    Choose a main staff member
                </InputLabel>
                <Select
                    inputProps={{
                        className: classes.teacherMenuItem,
                    }}
                    variant="outlined"
                    className={classes.teacherMenuItem}
                    required
                    fullWidth
                    label="Choose a main staff member"
                    name="primaryUserId"
                    id="primaryUserId"
                    onChange={(e) => setFieldValue("primaryUserId", e.target.value as unknown as number)}
                >
                    {facilitators
                        ?.filter((f) => f.User.id !== currentUserId)
                        ?.map((facilitator) => (
                            <MenuItem
                                className={classes.teacherMenuItem}
                                key={facilitator.id}
                                value={facilitator.User.id}
                            >
                                {facilitator.User.firstName} {facilitator.User.lastName}
                                {
                                    <>
                                        <Chip label={facilitator.User.email} />
                                    </>
                                }
                            </MenuItem>
                        ))}
                </Select>
            </FormControl>
        </Grid>
    ) : (
        <></>
    );
};
