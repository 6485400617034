import React, { FC } from "react";
import { PieChartWidget } from "./PieChartWidget";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";

export const AgePieWidget: FC = () => {
    const { item } = useFetch<ActiveProgramStats>();

    return (
        <PieChartWidget
            spinWhileFetching
            header="Age Demographic"
            subHeader="Active programmes by age"
            data={
                item?.ageBreakdown && Object.keys(item?.ageBreakdown).length > 0
                    ? Object.keys(item?.ageBreakdown).map((key) => ({ x: key, y: item?.ageBreakdown[key] }))
                    : undefined
            }
        />
    );
};
