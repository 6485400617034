import React, { useEffect, useState } from "react";
import { List } from "../../../core/components/crud/List";
import { Box, Grid } from "@material-ui/core";
import { getQRCodesPDF } from "../../../client/api/programme";
import { Participant } from "../../../../../common/build/prisma/client";
import { useList } from "../../../core/components/crud/contexts/list.context";
import { SecondaryButton } from "../../layout/Button/Button";

type ProgrammeParticipantListProps = {
    programmeId: number;
};
export const ProgrammeParticipantList = (props: ProgrammeParticipantListProps): JSX.Element => {
    const { programmeId } = props;
    const { list } = useList<Participant>();
    const [hasParticipants, setHasParticipants] = useState<boolean>(false);

    useEffect(() => {
        setHasParticipants(list.length > 0);
    }, [list]);

    return (
        <>
            <Box display={"flex"} justifyContent={"flex-end"} mb={2}>
                {hasParticipants && (
                    <SecondaryButton onClick={() => getQRCodesPDF(programmeId)}>Get QR Codes</SecondaryButton>
                )}
            </Box>
            <Grid item xs={12}>
                <List pagination={false} includeHeader={false} padding={1} />
            </Grid>
        </>
    );
};
