import React, { useEffect, useState } from "react";
import { JitsiMeetExternalAPI } from "./jitsimeet-external-api";
import { PublicUser, User } from "common/build/prisma/client";

declare global {
    interface Window {
        JitsiMeetExternalAPI: typeof JitsiMeetExternalAPI;
    }
}

export interface JitsiProps {
    user?: User | PublicUser;
    roomName: string;
    height?: string;
    configuration?: Record<string, unknown>;
    setParticipantCount?: (count: number) => void;
}

export const JitsiVideoConference: React.FC<JitsiProps> = (props: JitsiProps): JSX.Element => {
    const { user, roomName, height, configuration, setParticipantCount } = props;
    const jitsiContainerId = "jitsi-meet-div";

    const [jitsi, setJitsi] = useState<JitsiMeetExternalAPI>();

    // load Jitsi script to window object
    const loadJitsiScript = async () => {
        return new Promise<unknown>((resolve) => {
            const script = document.createElement("script");
            script.src = "https://8x8.vc/external_api.js";
            script.async = true;
            script.onload = resolve;
            document.body.appendChild(script);
        });
    };

    const initialiseJitsi = async () => {
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        if (user) {
            const _jitsi = new window.JitsiMeetExternalAPI("8x8.vc", {
                roomName: `vpaas-magic-cookie-3f696c7896424eccbe4153030f695b56/${roomName}`,
                parentNode: document.getElementById(jitsiContainerId),
                userInfo: {
                    displayName: user?.firstName,
                },
                configOverwrite: {
                    disableDeepLinking: true,
                    prejoinPageEnabled: false,
                    defaultLocalDisplayName: "me",
                    defaultRemoteDisplayName: user?.firstName,
                    hideEmailInSettings: true,
                    hideConferenceSubject: true,
                    hideConferenceTimer: true,
                    disableShortcuts: true,
                    enableWelcomePage: false,
                    toolbarButtons: [
                        "microphone",
                        "camera",
                        "select-background",
                        "toggle-camera",
                        "fullscreen",
                        "fodeviceselection",
                        "chat",
                        "profile",
                        "etherpad",
                        "raisehand",
                        "videoquality",
                        "tileview",
                        "mute-everyone",
                    ],
                },
                interfaceConfigOverwrite: configuration ?? {
                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                    DISPLAY_WELCOME_PAGE_CONTENT: false,
                    MOBILE_APP_PROMO: false,
                    SHOW_JITSI_WATERMARK: false,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    HIDE_DEEP_LINKING_LOGO: true,
                    HIDE_INVITE_MORE_HEADER: true,
                    RECENT_LIST_ENABLED: false,
                    TILE_VIEW_MAX_COLUMNS: 2,
                },
            });

            setJitsi(_jitsi);
        }
    };

    useEffect(() => {
        initialiseJitsi();

        return () => jitsi?.dispose?.();
    }, []);

    useEffect(() => {
        // check number of participants every 3 seconds
        const interval = setInterval(() => {
            if (jitsi && setParticipantCount) {
                const count = jitsi.getNumberOfParticipants();
                setParticipantCount(count);
            }
        }, 3000);

        return () => clearInterval(interval);
    }, [jitsi, setParticipantCount]);

    return <div id={jitsiContainerId} style={{ height: `${height ?? "100%"}`, width: "100%" }} />;
};
