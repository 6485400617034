import React, { ReactNode, useCallback, useState } from "react";
import { node } from "prop-types";
import { Participant, PublicUser } from "common/build/prisma/client";
import { add } from "../../../client/api/public/user";
import { sendMagicLinkEmail } from "../../../client/api/public/auth";
import { useHistory } from "react-router-dom";
import { PublicUserInputFields, PublicUserType } from "../../../models/modelTypes";
import { validateInvite } from "../../../client/api/public/participant";

export enum HomeScreenView {
    Email = "Email",
    Details = "Details",
    Confirmation = "Confirmation",
}

interface HomeContextI {
    view: HomeScreenView;
    details?: Partial<PublicUser>;
    publicUser?: PublicUserType;
    participant?: Participant;
    preSurveyId?: string;
    isEmailUpdated: boolean;
    signInLinkSent: boolean;
    programmeId?: number;

    validateJwt: (jwt: string) => Promise<void>;
    changeView: (view: HomeScreenView) => void;
    onConfirmEmail: (email: string) => Promise<void>;
    onConfirmDetails: (signUpToken: string, details: PublicUserInputFields) => Promise<void>;
    sendLoginEmail: (email: string) => Promise<void>;
}

const HomeContext = React.createContext({} as HomeContextI);

export const HomeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [view, setView] = useState<HomeScreenView>(HomeScreenView.Email);
    const [details, setDetails] = useState<PublicUserInputFields & { email: string }>({
        email: "",
        phoneNumber: "",
        lastName: "",
        firstName: "",
    });
    const [publicUser, setPublicUser] = useState<PublicUserType | undefined>();
    const [participant, setParticpant] = useState<Participant | undefined>();
    const [isEmailUpdated, setIsEmailUpdated] = useState<boolean>(false);
    const [preSurveyId, setPreSurveyId] = useState<string>();
    const [programmeId, setProgrammeId] = useState<number>();
    const [signInLinkSent, setSignInLinkSent] = useState<boolean>(false);
    const [verificationToken, setVerificationToken] = useState<string>("");

    const history = useHistory();

    const validateJwt = useCallback((jwt: string) => {
        return new Promise<void>((resolve, reject) => {
            validateInvite(jwt)
                .then(async (response) => {
                    const {
                        participant,
                        publicUser,
                        preSurveyId,
                        programmeId,
                        signInInitiated = false,
                    } = await response.json();
                    setPreSurveyId(preSurveyId);
                    setProgrammeId(programmeId);
                    setDetails((currentDetails) => ({
                        ...(currentDetails ?? { firstName: "", lastName: "" }),
                        email: participant.email,
                    }));
                    setPublicUser(publicUser);
                    setParticpant(participant);
                    setVerificationToken(jwt);
                    if (signInInitiated) {
                        setSignInLinkSent(signInInitiated);
                        setView(HomeScreenView.Confirmation);
                    } else {
                        setView(participant && participant.email ? HomeScreenView.Details : HomeScreenView.Email);
                    }
                    resolve();
                })
                .catch(() => {
                    reject("JWT invalid");
                });
        });
    }, []);

    const changeView = useCallback((newView: HomeScreenView) => {
        setView(newView);
    }, []);

    const onConfirmEmail = useCallback(
        (email: string) => {
            return new Promise<void>((resolve, reject) => {
                setDetails({ ...details, email: email });
                setIsEmailUpdated(participant && participant.email && participant.email !== email ? true : false);
                if (publicUser && publicUser.isOnboardingComplete) {
                    sendMagicLinkEmail(publicUser.email)
                        .then(() => {
                            setSignInLinkSent(true);
                            setView(HomeScreenView.Confirmation);
                            resolve();
                        })
                        .catch(() => {
                            reject("Error sending magic link");
                        });
                } else {
                    setView(HomeScreenView.Details);
                    resolve();
                }
            });
        },
        [publicUser, participant],
    );

    const onConfirmDetails = useCallback(
        (signUpToken: string, confirmedDetails: PublicUserInputFields) => {
            return new Promise<void>(async (resolve, reject) => {
                await add({
                    ...confirmedDetails,
                    email: details?.email || "error",
                    token: signUpToken,
                })
                    .then(async (response) => {
                        const updatedUser: PublicUserType & { signInUrl?: string } = await response.json();
                        setPublicUser(updatedUser);
                        if (updatedUser.signInUrl) {
                            history.push(updatedUser.signInUrl.replace(/^https?\:\/\/.+?(\/.*$)/, "$1"));
                        } else {
                            sendMagicLinkEmail(updatedUser.email)
                                .then(() => {
                                    setSignInLinkSent(true);
                                    setView(HomeScreenView.Confirmation);
                                    resolve();
                                })
                                .catch(() => {
                                    reject("Error sending magic link");
                                });
                        }
                    })
                    .catch(() => {
                        reject("Error updating details");
                    });
            });
        },
        [publicUser, participant, details, verificationToken],
    );

    const sendLoginEmail = useCallback((email: string) => {
        return new Promise<void>((resolve, reject) => {
            sendMagicLinkEmail(email)
                .then(async (response) => {
                    const user = await response.json();
                    setPublicUser(user);
                    setSignInLinkSent(true);
                    setView(HomeScreenView.Confirmation);
                    resolve();
                })
                .catch(() => {
                    reject("Error sending magic link");
                });
        });
    }, []);

    return (
        <HomeContext.Provider
            value={{
                view,
                details,
                publicUser,
                participant,
                programmeId,
                isEmailUpdated,
                signInLinkSent,
                preSurveyId,
                validateJwt,
                changeView,
                onConfirmEmail,
                onConfirmDetails,
                sendLoginEmail,
            }}
        >
            {children}
        </HomeContext.Provider>
    );
};

HomeProvider.propTypes = {
    children: node,
};

export const useHome = (): HomeContextI => React.useContext(HomeContext);
