import React from "react";
import { Editor } from "@tinymce/tinymce-react";

interface ContentEditorProps {
    id: string;
    mappedStoryPartId: number;
    initialContent: string;
    content: Map<number, string> | undefined;
    setContent: (id: number, newContent: string) => void;
    setIsEdited: (edited: boolean) => void;
    setIsEditorLoaded: (loaded: boolean) => void;
}

export const tinymceAPI = "nrpm5h6plpjwirgif844lvc0psz471ukhnpdx32v2lnotnbz";

export const ContentEditor: React.FC<ContentEditorProps> = (props: ContentEditorProps) => {
    return (
        <div style={{ width: "100%" }}>
            <Editor
                apiKey={tinymceAPI}
                id={props.id}
                initialValue={props.initialContent}
                value={props.content?.get(props.mappedStoryPartId)}
                init={{
                    body_class: "fdsfsfsd",
                    menubar: false,
                    statusbar: false,
                    plugins: [
                        "advlist lists image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                        "autoresize",
                    ],
                    toolbar:
                        "undo redo | bold italic underline backcolor forecolor | \
    removeformat",
                }}
                onBeforeSetContent={(contentEvent) =>
                    (contentEvent.content = contentEvent.content?.replaceAll("\n\n", "<br /><br />"))
                }
                onDirty={() => props.setIsEdited(true)}
                onActivate={() => {
                    props.setIsEditorLoaded(true);
                }}
                onEditorChange={(content) => props.setContent(props.mappedStoryPartId, content)}
            />
        </div>
    );
};
