import { Session } from "common/build/prisma/client";
import { ParticipantSurvey } from "../../../models/modelTypes";
import { getData, patchData } from "../rest";
import { SessionFetchParameters } from "common/build/api-parameters/session";
import { objectToQueryString } from "../../../utils/api";

export const get = async (id: number, args: SessionFetchParameters): Promise<Session> =>
    await getData(`/public/session/${id}${objectToQueryString(args)}`);

export const completeParticipantSurvey = async (id: number, survey: ParticipantSurvey): Promise<Response> =>
    await patchData(`/public/session/${id}/complete-survey`, { survey });
