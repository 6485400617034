import { createStyles, makeStyles } from "@material-ui/core/styles";

const styles = createStyles({
    item: {
        padding: "0 8px !important",
    },
    container: {
        margin: "0 -8px !important",
        width: "unset",
    },
});

export const useGridSharedStyles = makeStyles(styles);
