import React from "react";
import { Switch, useRouteMatch } from "react-router-dom";
import { BaseRoute } from "../routing/PrivateRoute";
import { OrganisationsScreen } from "./screens/Organisations";
import { ViewOrganisation } from "./screens/ViewOrganisation";
import { CollectionsScreen } from "./screens/Collections";

export const StatsProvider = (): JSX.Element => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <BaseRoute
                path={`${path}/organisations/view/:id`}
                exact
                permission="StatsViewAll"
                component={ViewOrganisation}
            />
            <BaseRoute
                path={`${path}/organisations`}
                exact
                permission={"StatsViewAll"}
                component={OrganisationsScreen}
            />
            <BaseRoute
                path={`${path}/storycollections`}
                exact
                permission={"StatsViewAll"}
                component={CollectionsScreen}
            />
        </Switch>
    );
};
