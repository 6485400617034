import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    ThemeProvider,
    Toolbar,
    Typography,
} from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Navigator from "../../components/layout/Navigator";
import { useBaseLayoutStyle } from "./BaseLayout.style";
import { verbalTheme } from "./themes/verbal.theme";
import { BaseLayoutProvider } from "./BaseLayout.context";
import { Header } from "../../core/components/header";
import { useAuth } from "../../contexts/auth.context";
import { useOrganisation } from "../../hooks/useOrganisation";
import WarningIcon from "@material-ui/icons/Warning";
import { PrimaryButton } from "./Button/Button";
import { MobileNavigator } from "./MobileNavigator";
import clsx from "clsx";

const BaseLayout: React.FC<{ hasNav?: boolean }> = ({ children, hasNav = true }): React.ReactElement => {
    const { user } = useAuth();
    const { organisation, getOrganisation } = useOrganisation();
    const [demoDialog, setDemoDialog] = useState<boolean>(false);

    useEffect(() => {
        if (user) {
            getOrganisation(user.id);
        }
    }, [user]);

    const handleDemoContact = () => {
        setDemoDialog(false);
        if (window.FreshworksWidget) {
            window.FreshworksWidget("identify", "ticketForm", { name: user?.firstName, email: user?.email });
            window.FreshworksWidget("prefill", "ticketForm", {
                subject: "Full Account",
                description: "I would like to upgrade to a full account",
                priority: 1,
            });
            window.FreshworksWidget("open");
        }
    };

    const classes = useBaseLayoutStyle();
    return (
        <ThemeProvider theme={verbalTheme}>
            <CssBaseline />
            <BaseLayoutProvider>
                <Dialog open={demoDialog} onClose={() => setDemoDialog(false)}>
                    <DialogTitle>
                        <Typography variant="h4">Demo Mode</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h5">What is Demo Mode?</Typography>
                        <Typography paragraph>
                            Demo mode allows organisations to use and experience the Verbal Platform with some
                            restrictions. When in demo mode, all facilitators added to the system automatically have one
                            month training and there is no need to take specific training. In addition to this, all the
                            stories are limited to the first two parts. This is to allow you to get a sense of what
                            delivering and participating in programmes looks and feels like.
                        </Typography>
                        <Typography variant="h5">Why Demo Mode?</Typography>
                        <Typography paragraph>
                            Demo mode is useful for organisations that want to get an understanding of how the Verbal
                            Platform works without committing to having a full account. However, the Platform in demo
                            mode will not provide any value to the participants. This is because the stories on the
                            platform are carefully curated and tested within Verbal and every story, including every
                            part, is important to give the participants the best experience possible.
                        </Typography>
                        <Typography variant="h5">What Next?</Typography>
                        <Typography paragraph>
                            When your organisation has tried the system and you would like to upgrade to a full account,
                            please contact us by clicking the following link:{" "}
                            <Link variant="h6" component="button" onClick={handleDemoContact}>
                                Sign me up
                            </Link>
                            . We will be in contact with you once we receive your information and will upgrade your
                            account to a full account.
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <PrimaryButton onClick={() => setDemoDialog(false)}>OK</PrimaryButton>
                    </DialogActions>
                </Dialog>
                {organisation && organisation.Tier?.tier === "Demo" && (
                    <>
                        <AppBar position="fixed" elevation={0} className={classes.demoBar}>
                            <Toolbar style={{ justifyContent: "space-evenly" }}>
                                <WarningIcon style={{ fill: "white", paddingRight: ".5rem" }} fontSize="large" />
                                <Typography style={{ color: "white" }} color="primary" align="center">
                                    This organisation is in demo mode. Click learn more to find out what this means and
                                    how you can get a full account
                                </Typography>
                                <Button
                                    style={{ color: "white" }}
                                    disableRipple
                                    variant="text"
                                    onClick={() => setDemoDialog(true)}
                                >
                                    Learn More
                                </Button>
                            </Toolbar>
                        </AppBar>
                        <Toolbar />
                    </>
                )}
                {hasNav === false && <Header />}
                <div className={classes.root}>
                    {hasNav && (
                        <>
                            <nav className={clsx(classes.drawer, "navigatorContainer")}>
                                <Hidden xsDown implementation="css">
                                    <Navigator />
                                </Hidden>
                            </nav>
                        </>
                    )}
                    <main className={clsx(classes.main, "mainContainer")}>{children}</main>
                </div>
                {hasNav && <MobileNavigator />}
            </BaseLayoutProvider>
        </ThemeProvider>
    );
};

export const LayoutWithNav: React.FC = ({ children }) => <BaseLayout> {children} </BaseLayout>;
export const LayoutWithoutNav: React.FC = ({ children }) => <BaseLayout hasNav={false}> {children} </BaseLayout>;
