import { createStyles, makeStyles } from "@material-ui/core";
import { verbalTheme } from "../layout/themes/verbal.theme";

const styles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-around",
            overflow: "hidden",
            backgroundColor: theme.palette.background.paper,
        },
        homeDrawer: {
            width: 300,
            flexShrink: 0,
        },
        homeDrawerPaper: {
            width: 300,
            borderLeft: "none",
        },
        storyDrawer: {
            width: 500,
            [verbalTheme.breakpoints.between("sm", "md")]: {
                width: 325,
            },
            //flexShrink: 0,
        },
        storyDrawerPaper: {
            width: 500,
            borderLeft: "none",
            [verbalTheme.breakpoints.between("sm", "md")]: {
                width: 325,
            },
        },
        toolbar: theme.mixins.toolbar,
        imageList: {
            flexWrap: "nowrap",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
        },
        titleBarBottom: {
            //background: "transparent",
            flexWrap: "wrap",
            height: "auto",
            minHeight: verbalTheme.spacing(5),
            borderRadius: verbalTheme.spacing(1),
            background: "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
        },
        titleWrap: {
            marginLeft: 0,
            marginRight: 0,
            overflow: "hidden",
            flexGrow: 1,
            padding: "5px",
        },
        storyCard: {
            justifyContent: "space-between",
            flexDirection: "column",
            cursor: "pointer",
            width: "100%",
            height: "100%",
            minHeight: "200px",
            //minWidth: "100%",
        },
        images: {
            position: "relative",
            "-webkit-filter": "contrast(150%) saturate(110%)",
            filter: "contrast(150%) saturate(110%)",

            "&::before": {
                content: `""`,
                display: "block",
                height: "100%",
                width: "100%",
                top: "0",
                left: "0",
                position: "absolute",
                pointerEvents: "none",
                mixBlendMode: "multiply",
                background: "radial-gradient(50% 50%, circle closest-corner, transparent, rgba(0, 0, 0, 1))",
            },
        },
        keywordChip: {
            borderRadius: 0,
            margin: "0 0.5rem 0.5rem 0",
        },
    }),
);

export const useHomeStyles = styles;
