import { StoryCollectionType } from "../../models/modelTypes";
import { getData, patchData, postData } from "./rest";
import { objectToQueryString } from "../../utils/api";

export const list = async (args: Record<string, unknown>): Promise<{ items: StoryCollectionType[]; total: number }> =>
    await getData(`/collection${objectToQueryString(args)}`);

export const get = async (id: number, args: Record<string, unknown>): Promise<StoryCollectionType> =>
    await getData(`/collection/${id}${objectToQueryString(args)}`);

export const add = async (collection: Partial<StoryCollectionType>): Promise<Response> =>
    await postData(`/collection/`, { ...collection });

export const addWeeksToCollection = async (
    collectionId: number,
    weeks: { order: number; learningObjective: string; themeId: number; storyId: number }[],
): Promise<Response> => await patchData(`/collection/weeks/${collectionId}`, weeks);

export const update = async (collectionId: number, collection: Partial<StoryCollectionType>): Promise<Response> =>
    await patchData(`/collection/${collectionId}`, { ...collection });

export const approve = async (collectionId: number): Promise<Response> =>
    await patchData(`/collection/${collectionId}/approve`);

export const review = async (collectionId: number): Promise<Response> =>
    await patchData(`/collection/${collectionId}/submit-review`);

export const addStoryToCollection = async (
    collectionId: number,
    story: { order: number; storyId: number },
): Promise<Response> => await patchData(`/collection/story/${collectionId}`, { ...story });
