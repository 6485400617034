import React, { Fragment, useEffect, useRef, useState } from "react";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { MappedStoryPartType, MappedStoryQuestionType, MappedStoryType } from "../../../../models/modelTypes";
import Error from "../../../../core/components/Error";
import Spinner from "../../../layout/Spinner";
import { useParams } from "react-router-dom";
import { Create } from "@material-ui/icons";
import { Box, Button, Container, Grid, Hidden, IconButton, Typography } from "@material-ui/core";
import Breadcrumb from "../../../../core/components/Breadcrumb";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { DiscussionDialog } from "./DiscussionDialog";
import { useStory } from "../../../../hooks/useStory";
import { VModule, VModuleType } from "../../../../core/components/VModule";
import { useHistory } from "react-router";
import { VList } from "../../../../core/components/VList";
import { VListItem, VListItemType } from "../../../../core/components/VListItem";
import { QuestionSidePanel } from "./QuestionSidePanel";
import { useAuth } from "../../../../contexts/auth.context";

export const MapStory = (): JSX.Element => {
    const { id } = useParams<{ id: string }>();
    const { mappedStory, setCurrentMappedStory, publishMappedStory, addStoryPartQuestion, removeStoryPartQuestion } =
        useStory();
    const { hasPermission } = useAuth();
    const { item, fetching, fetchItem } = useFetch<MappedStoryType>();
    const [error, setError] = useState<boolean>(false);
    const [currentPart, setCurrentPart] = useState<Partial<MappedStoryPartType>>();
    const [existingQuestion, setExistingQuestion] = useState<MappedStoryQuestionType>();
    const [openQuestionDialog, setOpenQuestionDialog] = useState<boolean>(false);
    const [openSidePanel, setOpenSidePanel] = useState<boolean>(false);
    const [partCount, setPartCount] = useState(0);
    const breakRefs = useRef<HTMLDivElement[]>([]);
    const history = useHistory();

    useEffect(() => {
        if (parseInt(id)) {
            fetchItem(parseInt(id))
                .then(() => setError(false))
                .catch(() => setError(true));
        }
    }, [id]);

    useEffect(() => {
        if (item && item.Story) {
            setCurrentMappedStory(item);
        }
    }, [item]);

    if (error) {
        return <Error description="Unable to fetch story" />;
    }

    const saveQuestion = (question: Partial<MappedStoryQuestionType>) => {
        if (mappedStory && mappedStory.storyCollectionId && currentPart?.part !== undefined && question) {
            addStoryPartQuestion(mappedStory.id, currentPart.part, mappedStory.storyCollectionId, question);
            setExistingQuestion(undefined);
        }
    };

    const cancelQuestions = () => {
        setExistingQuestion(undefined);
    };

    const partTemplateLiteral = (text: TemplateStringsArray, partNum?: number) => {
        if (partNum !== undefined) {
            return `${text[0]}${partNum + 1}`;
        }
    };

    const publish = () => {
        if (item && item.id) {
            publishMappedStory(item.id)
                .then(() => history.push(`/collections/view/${item.storyCollectionId}`))
                .catch(() => setError(true));
        }
    };

    const removeQuestion = (questionOrder: number) => {
        if (item?.id && currentPart?.part !== undefined) {
            removeStoryPartQuestion(item.id, currentPart.part, questionOrder);
        }
    };

    const useStyles = makeStyles(
        createStyles({
            serif: {
                fontFamily: "'PT Serif', serif",
            },
            storyTitle: {
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: 1.5,
                [verbalTheme.breakpoints.up("sm")]: {
                    fontSize: "26px",
                },
                [verbalTheme.breakpoints.up("md")]: {
                    fontSize: "26px",
                },
            },
            questionsRoot: {
                padding: verbalTheme.spacing(3),
            },
            bold: {
                fontWeight: 700,
                opacity: "0.5",
            },
            storyBlock: {
                fontSize: "18px",
                marginBottom: "1em",
            },
            breakpointContainer: {
                marginTop: "1rem",
                marginBottom: "1rem",
            },
            breakpointContent: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem",
            },
            storyBreakNav: {
                position: "sticky",
                top: "0",
            },
            storyBreakInlineButton: {
                marginLeft: "-1rem",
                visibility: "hidden",
                padding: "0",
                fontSize: "0.5rem",
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box p={3}>
            {!fetching && item && mappedStory && mappedStory.StoryCollection && mappedStory.Story ? (
                <>
                    <Container>
                        <Box mb={2}>
                            <Breadcrumb
                                crumbs={[
                                    {
                                        name: "Collections",
                                        url: "/collections",
                                    },
                                    {
                                        name: mappedStory.StoryCollection.name,
                                        url: `/collections/view/${mappedStory.storyCollectionId}`,
                                    },
                                ]}
                                current={
                                    (mappedStory && mappedStory.Story && `${mappedStory.Story.storyName}`) ?? "Story"
                                }
                            />
                        </Box>
                        <Box mb={2}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={7} sm={8} md={9}>
                                    <Typography className={clsx(classes.serif, classes.storyTitle)} component="h1">
                                        {mappedStory.Story.storyName}
                                    </Typography>
                                    <Typography variant="body2" className={classes.bold}>
                                        By {mappedStory.Story.author}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <div className={clsx(classes.serif, classes.storyBlock)}>
                                    {mappedStory?.Story?.StoryParts?.map((mp) => {
                                        return (
                                            <>
                                                <div>
                                                    <p style={{ whiteSpace: "pre-line" }}>{mp.content}</p>
                                                </div>
                                                <div
                                                    key={mp.id}
                                                    ref={(el) => {
                                                        if (el !== null && !breakRefs.current.includes(el)) {
                                                            breakRefs.current.push(el);
                                                        }
                                                    }}
                                                >
                                                    <VModule
                                                        padded={false}
                                                        type={VModuleType.Shade1}
                                                        className={classes.breakpointContainer}
                                                    >
                                                        <div className={classes.breakpointContent}>
                                                            <div>
                                                                <Typography variant="h6">
                                                                    {partTemplateLiteral`Story Break ${mp?.part}`}
                                                                </Typography>
                                                                <Typography variant="subtitle2">
                                                                    {mappedStory.MappedStoryQuestion?.filter(
                                                                        (q) => q.mappedStoryPartId === mp.id,
                                                                    ).length ?? "No"}{" "}
                                                                    Questions added
                                                                </Typography>
                                                            </div>
                                                            <div>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        if (mp && mp.part !== undefined) {
                                                                            setCurrentPart(mp);
                                                                            setPartCount(mp.part + 1);
                                                                            setOpenSidePanel(true);
                                                                        }
                                                                    }}
                                                                >
                                                                    <Create />
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </VModule>
                                                </div>
                                            </>
                                        );
                                    })}
                                </div>
                            </Grid>
                            <Hidden smDown>
                                <Grid item md={4}>
                                    <VModule type={VModuleType.Shade1} className={classes.storyBreakNav}>
                                        <Typography variant={"h4"}>Story Breaks</Typography>
                                        {mappedStory &&
                                        mappedStory.Story &&
                                        mappedStory.Story.StoryParts &&
                                        mappedStory.Story.StoryParts.length > 0 ? (
                                            <>
                                                <Typography>You can jump to a break by clicking below</Typography>
                                                <VList>
                                                    {mappedStory.Story.StoryParts.map((part) => (
                                                        <Fragment key={`${part.id}`}>
                                                            <Grid container spacing={1}>
                                                                <VListItem
                                                                    divider={true}
                                                                    type={VListItemType.Primary}
                                                                    primaryText={`Story break ${part.part + 1}`}
                                                                    secondaryText={`${
                                                                        mappedStory.MappedStoryQuestion?.filter(
                                                                            (q) => q.mappedStoryPartId === part.id,
                                                                        ).length ?? 0
                                                                    } questions`}
                                                                    onClick={() =>
                                                                        breakRefs.current[part.part] &&
                                                                        breakRefs.current[part.part].scrollIntoView({
                                                                            behavior: "smooth",
                                                                        })
                                                                    }
                                                                >
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            setCurrentPart(part);
                                                                            setPartCount(part.part + 1);
                                                                            setOpenSidePanel(true);
                                                                        }}
                                                                    >
                                                                        <Create />
                                                                    </IconButton>
                                                                </VListItem>
                                                            </Grid>
                                                        </Fragment>
                                                    ))}
                                                </VList>
                                            </>
                                        ) : (
                                            <>
                                                <Typography>
                                                    This story has no parts. Please contact a Story Admin
                                                </Typography>
                                            </>
                                        )}
                                    </VModule>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={8}>
                                <Grid item container justifyContent={"space-between"}>
                                    <Grid item>
                                        <Button variant="contained" size="large" onClick={() => history.goBack()}>
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        {hasPermission("StoryReview") ? (
                                            <Button variant="contained" size="large" color="primary" onClick={publish}>
                                                Publish
                                            </Button>
                                        ) : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <QuestionSidePanel
                                collection={mappedStory.StoryCollection}
                                open={openSidePanel}
                                close={setOpenSidePanel}
                                partId={currentPart?.id}
                                story={mappedStory}
                                partCount={partCount}
                                openQuestionDialog={setOpenQuestionDialog}
                                setQuestionToEdit={setExistingQuestion}
                                removeQuestion={removeQuestion}
                            />
                        </Grid>
                        <DiscussionDialog
                            open={openQuestionDialog}
                            setOpen={setOpenQuestionDialog}
                            getQuestion={saveQuestion}
                            cancel={cancelQuestions}
                            questionCount={
                                mappedStory.MappedStoryQuestion?.filter((q) => q.mappedStoryPartId === currentPart?.id)
                                    .length ?? 0
                            }
                            collection={mappedStory.StoryCollection}
                            existingQuestion={existingQuestion}
                        />
                    </Container>
                </>
            ) : (
                <Spinner />
            )}
        </Box>
    );
};
