import React, { ComponentProps } from "react";
import Stepper from "@material-ui/core/Stepper";
import Wizard, { WizardChild } from "./Wizard";
import { Step, StepButton } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";

type VerticalWizardProps = ComponentProps<typeof Wizard>;

export const VerticalWizard: React.FC<VerticalWizardProps> = (props: VerticalWizardProps) => {
    const { activeStep, goToStep, hideStepper } = props;

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                flexGrow: 1,
            },
            stepperRoot: {
                backgroundColor: "transparent",
            },
            hidden: {
                display: "none",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item className={hideStepper ? classes.hidden : ""}>
                        <Stepper activeStep={activeStep} orientation="vertical" className={classes.stepperRoot}>
                            {React.Children.map(props.children, (step: WizardChild) => {
                                return (
                                    step &&
                                    step.props.heading && (
                                        <Step key={step.props.heading} {...step.props} completed={step.props.completed}>
                                            <StepButton onClick={() => goToStep(step.props.identifier)}>
                                                {step.props.heading}
                                            </StepButton>
                                        </Step>
                                    )
                                );
                            })}
                        </Stepper>
                    </Grid>
                    <Grid item xs={hideStepper ? 12 : 8}>
                        <Wizard {...props} />
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
