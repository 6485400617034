import { CollectionsList } from "common/build/api-parameters/stats";

const formatCompletionTime = (milliseconds: number): string => {
    if (milliseconds === 0) return "-";
    //const hours = Math.floor(avgCompletionTime / 36e5);
    const minutes = Math.floor(milliseconds / 60000); // - hours * 36e5
    const seconds = Math.floor((milliseconds - minutes * 60000) / 1000); // - (hours * 36e5)
    return `${minutes} minutes ${seconds < 10 ? 0 : ""}${seconds} seconds`; //:${minutes < 10 ? 0 : ""}${minutes}
};

export const collectionsCRUDModel = {
    name: {
        label: "Story Collection",
    },
    ageRange: {
        label: "Age Group",
        decorator: (value: unknown, property?: string, collection?: CollectionsList): string =>
            collection?.ageRange
                ? collection?.ageRange
                : collection?.curriculumAgeRange
                ? collection?.curriculumAgeRange
                : "",
    },
    curriculumId: {
        label: "Curriculum",
        decorator: (value: unknown, property?: string, collection?: CollectionsList): string =>
            collection?.curriculumName ? collection.curriculumName : "None",
    },
    Stories: {
        label: "Stories",
        decorator: (value: unknown, property?: string, collection?: CollectionsList): string =>
            String(collection?.storyCount),
    },
    type: {
        label: "Type",
    },
    approved: {
        label: "Approved for use",
        decorator: (value: unknown, property?: string, collection?: CollectionsList): string =>
            collection?.approved ? "Yes" : "No",
    },
    programmeCount: {
        label: "No Of Programmes",
        decorator: (value: unknown, property?: string, collection?: CollectionsList): string =>
            String(collection?.programmeCount),
    },
    calcTotalCompletionTime: {
        label: "Average Session Completion Time",
        decorator: (value: string, property?: string, collection?: CollectionsList): string =>
            formatCompletionTime((collection?.averageCompletionTime ?? 0) * 1000),
    },
    calcMaxCompletionTime: {
        label: "Maximum Session Completion Time",
        inTable: false,
        inExport: true,
        decorator: (value: string, property?: string, collection?: CollectionsList): string =>
            formatCompletionTime((collection?.maxCompletionTime ?? 0) * 1000),
    },
    calcMinCompletionTime: {
        label: "Minimum Session Completion Time",
        inTable: false,
        inExport: true,
        decorator: (value: string, property?: string, collection?: CollectionsList): string =>
            formatCompletionTime((collection?.minCompletionTime ?? 0) * 1000),
    },
};
