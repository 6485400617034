import React, { useEffect, useMemo, useRef, useState } from "react";
import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useSetTourState, useTrackedTourState } from "../tour/Store";
import { useHistory, useLocation } from "react-router-dom";
import { TourGuideI } from "../tour/Store";
import { createStyles, makeStyles } from "@material-ui/core";
import { verbalTheme } from "../layout/themes/verbal.theme";

type TourDetails = {
    lastClassroomId: number | undefined;
    lastProgrammeId: number | undefined;
};

const TourDetailsDefaults: TourDetails = {
    lastClassroomId: undefined,
    lastProgrammeId: undefined,
};

export const RTour = (): JSX.Element => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [disableKeyboard, setDisableKeyboard] = useState<boolean>(false);
    const locked = useRef<boolean>(false);
    const refTourDetails = useRef<TourDetails>(TourDetailsDefaults);

    const setTourState = useSetTourState();
    const tourState = useTrackedTourState();

    const location = useLocation();
    const historyHook = useHistory();

    useMemo(() => {
        setCurrentStep(0);
    }, [tourState.currentTour, tourState.currentTourKey]);

    const _reactours: Map<string, TourGuideI> = new Map<string, TourGuideI>();

    _reactours.set("homeTour", {
        routeRegExp: RegExp(/^\/home$/i),
        routeDefault: true,
        scrollOffset: -400,
        steps: [
            {
                selector: ".navigatorContainer",
                content: "To the left is the site's navigator. This can be used to navigate to other parts of the app",
                position: "right",
            },
            {
                selector: ".navHomeButton",
                content: "The Home button brings you here to the dashboard.",
            },
            {
                selector: ".dashboardContainer",
                content: "Your dashboard provides you with an overview of your school's programmes.",
            },
            {
                selector: ".upcomingSessionsContainer",
                content: "To the right of the dashboard you can find a list of the upcoming sessions.",
                position: "left",
            },
            {
                selector: ".navExploreButton",
                content:
                    "The Explore button brings you to a screen where you explore the curriculums available to your school.",
            },
            {
                selector: ".navClassroomsButton",
                content:
                    "The Classrooms button brings you to a screen where you can create and manage your school's classrooms.",
            },
            {
                selector: ".navAddUserButton",
                content:
                    "The Add User button brings you to a screen where you can add different types of users, each with different permissions.",
            },
            {
                selector: ".navUserAccountsButton",
                content:
                    "The User Accounts button brings you to a screen where you can manage all your school's users.",
            },
            {
                selector: ".navTakeTourButton",
                content:
                    "The Take Tour button will be visible on any screen where a tour is available. Clicking this button will start or resume that screen's tour.",
            },
            {
                selector: ".dashTutorialsWidget",
                content:
                    "Clicking here will open a popup containing useful video tutorials. Further explaining how to use this app.",
                position: "bottom",
            },
            {
                selector: ".dashClassroomsWidget",
                content: "Clicking here will bring you to your classroom's management screen.",
                position: "bottom",
            },
            {
                selector: ".dashExploreWidget",
                content: "Clicking here will bring you to your available curriculums screen.",
                position: "bottom",
            },
            {
                selector: ".dashContactUsWidget",
                content: "Click here, if you have any questions and wish to contact us.",
                position: "bottom",
            },
            {
                selector: ".dashActivityTitle",
                content: "Here you can see an overview of your school's activity.",
                position: "top",
                observe: ".dashActivitySection",
                highlightedSelectors: [".dashActivitySection"],
            },
            /* {
                content: ({ goTo, inDOM }) => (
                    <Box>
                        Go to Classrooms tour{" "}
                        <Button
                            onClick={() => {
                                goTo(0);
                                setTourKey("viewClassroomTour_Other");
                                history.push("/classrooms/view/1");
                            }}
                        >
                            Go to Step 1
                        </Button>
                        <br />
                        {inDOM && "🎉 Look at your step!"}
                    </Box>
                ),
            }, */
        ],
    });

    _reactours.set("viewClassroomTour", {
        routeRegExp: RegExp(/^\/classrooms\/view\/\d+(?:$|\?)/i),
        routeDefault: true,
        scrollOffset: -400,
        steps: [
            {
                content: "When in your classroom you will have your own unique dashboard.",
                position: "center",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classAciveProgWidget",
                mutationObservables: [".classAciveProgWidget"],
                content: "Here you will see your current programmes and how many you have completed.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classCountWidget",
                mutationObservables: [".classCountWidget"],
                content: "You can see how many children are registered in your class and can view your class list.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classWellbeingWidget",
                mutationObservables: [".classCountWidget"],
                content: "You will be able to see your class over all wellbeing score which is cumulative.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classMoodsTodayWidget",
                mutationObservables: [".classMoodsTodayWidget"],
                content: "Here you will see the results from your class mood tracker daily check in.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classMoodsAfterSessionWidget",
                mutationObservables: [".classMoodsAfterSessionWidget"],
                content:
                    "Here you will see the results for your childrens mood tracker after them have taken part in the session.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classStudentWellbeingOverview",
                mutationObservables: [".classStudentWellbeingOverview"],
                content: "This will show you each childs individual wellbeing card with mood tracker data.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classNegativeDriversOverview",
                mutationObservables: [".classNegativeDriversOverview"],
                content: "This is where you will start your daily mood tracker from.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".programmesListAddButton",
                mutationObservables: [".programmesListAddButton"],
                resizeObservables: [".programmesListAddButton", ".programmesListTitleCount"],
                content: "Click here to create your programme and access all materials.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#student-tab",
                mutationObservables: ["#student-tab"],
                content: "You now need to register your children to the platform.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomStudentsTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classAddStudentContainer",
                mutationObservables: [".classAddStudentContainer"],
                resizeObservables: [".classAddStudentContainer"],
                content:
                    "Here you can add the relevant details for each student and add them to your classroom by clicking the Add button on the right.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomStudentsTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".participantsList",
                mutationObservables: [".participantsList"],
                resizeObservables: [".participantsList"],
                content: "All the added students can be viewed in the list above.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomStudentsTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#overview-tab",
                mutationObservables: ["#overview-tab"],
                content: "Return to your dash by clicking overview tab to go back.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classCountWidget",
                mutationObservables: [".classCountWidget"],
                content:
                    "On your dash now you will see the total number of children registered in your class and also their individual wellbeing cards will have been created.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#staff-tab",
                mutationObservables: ["#staff-tab"],
                content: "To add a teacher and any other teacher staff, click the Add Staff tab.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomStaffTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classAddTeacherContainer",
                mutationObservables: [".classAddTeacherContainer"],
                resizeObservables: [".classAddTeacherContainer"],
                content:
                    "You can now add another teacher to your class to enable them to access your programmes and childrens mood tracker. They must already be on the system. Select from the droop down box to add a person.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomStaffTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: ".classTeacherListContainer",
                mutationObservables: [".classTeacherListContainer"],
                resizeObservables: [".classTeacherListContainer"],
                content: "Any teaching staff that have been added to your classroom will be listed below.",
                position: "top",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomStaffTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                },
            },
            {
                content: "",
                position: "center",
                stepInteraction: false,
                action: () => {
                    const element = getRef("classroomOverviewTab");
                    if (element != null && element.current != null && element.current.tabIndex != 0) {
                        element?.current?.click();
                    }
                    setTourState((prev) => ({ ...prev, openTour: false }));
                    setCurrentStep(0);
                },
            },
        ],
    });

    _reactours.set("addProgrammePart1", {
        routeRegExp: RegExp(/^\/classrooms\/view\/\d+\/add$/i),
        routeDefault: true,
        scrollOffset: -400,
        steps: [
            {
                selector: ".selectCollectionList",
                mutationObservables: [".selectCollectionList"],
                resizeObservables: [".selectCollectionList"],
                content: "You will now see all programmes available to your school which you can explore.",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".storyCollectionFilter1",
                highlightedSelectors: [".storyCollectionFilter2", ".storyCollectionFilter3"],
                mutationObservables: [".storyCollectionFilter1", ".storyCollectionFilter2", ".storyCollectionFilter3"],
                resizeObservables: [".storyCollectionFilter1", ".storyCollectionFilter2", ".storyCollectionFilter3"],
                content:
                    "You can also use the filters and select an age range to see what programmes are available for your specific childrens age category.",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".storyCollectionOption",
                mutationObservables: [".storyCollectionOption"],
                resizeObservables: [".storyCollectionOption"],
                content:
                    "Select your programme after reviewing the 6 key areas you will be addressing in the programme and a short overview of the curriculum.",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".guideNotesButton",
                mutationObservables: [".guideNotesButton"],
                resizeObservables: [".guideNotesButton"],
                content: 'A teacher\'s guide for each curriculum can be found by clicking the "Guide Notes" button.',
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".selectCollectionButton",
                mutationObservables: [".selectCollectionButton"],
                resizeObservables: [".selectCollectionButton"],
                content: "Press the green button to select of your chosen curriculum.",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".selectCollectionButton",
                mutationObservables: [".selectCollectionButton"],
                resizeObservables: [".selectCollectionButton"],
                content: "...",
                position: "center",
                stepInteraction: false,
                action: (element) => {
                    if (element != null) {
                        element.click();
                    }
                },
            },
        ],
    });

    _reactours.set("addProgrammePart2", {
        routeRegExp: RegExp(/^\/classrooms\/view\/\d+\/add\?step\=1$/i),
        routeDefault: true,
        scrollOffset: -400,
        steps: [
            {
                selector: ".inputProgrammeName",
                mutationObservables: [".inputProgrammeName"],
                resizeObservables: [".inputProgrammeName"],
                content: "Enter a name for this programme (e.g. Term 1).",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".inputProgrammeStartDate",
                mutationObservables: [".inputProgrammeStartDate"],
                resizeObservables: [".inputProgrammeStartDate"],
                content: "Enter the date you plan on starting this programme.",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".inputProgrammeStartTime",
                mutationObservables: [".inputProgrammeStartTime"],
                resizeObservables: [".inputProgrammeStartTime"],
                content: "Enter the time you plan on starting this programme.",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".inputProgrammeFrequency",
                mutationObservables: [".inputProgrammeFrequency"],
                resizeObservables: [".inputProgrammeFrequency"],
                content:
                    "Use the drop down box to select when you want to deliver your programme daily, weekly fortnightly or monthly. At this point the system will plan out all 6 stories for your delivery schedule",
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".createProgrammeButton",
                mutationObservables: [".createProgrammeButton"],
                resizeObservables: [".createProgrammeButton"],
                content: 'Click "Continue" to add your programme to your classroom.',
                position: "left",
                stepInteraction: false,
            },
            {
                selector: ".createProgrammeButton",
                mutationObservables: [".createProgrammeButton"],
                resizeObservables: [".createProgrammeButton"],
                content: "...",
                position: "center",
                stepInteraction: false,
                action: (element) => {
                    if (element != null) {
                        setTourState((prev) => ({ ...prev, nextTourKey: "addProgrammePart3" }));
                        const regExp = RegExp(/^\/classrooms\/view\/\d+/i);
                        if (regExp != null) {
                            const found = location.pathname.match(regExp);
                            if (found != null && found[0].length > 0) {
                                historyHook.push(found[0]);
                                return;
                            }
                        }
                        history.back();
                    }
                },
            },
        ],
    });

    _reactours.set("addProgrammePart3", {
        routeRegExp: RegExp(/^\/classrooms\/view\/\d+$/i),
        routeDefault: false,
        scrollOffset: -400,
        steps: [
            {
                selector: ".programmesList",
                mutationObservables: [".programmesList"],
                resizeObservables: [".programmesList"],
                content:
                    "Your programme has now been created. You can access your programme and it's materials by clicking it at the bottom of your classroom's dashboard.",
                position: "top",
                stepInteraction: false,
            },
        ],
    });

    _reactours.set("deliverSessionPart1", {
        routeRegExp: RegExp(/^\/classrooms\/view\/\d+\/view\/\d+$/i),
        routeDefault: false,
        disableDotsNavigation: true,
        disableKeyboard: ["left"],
        disableClose: true,
        onAfterOpen: function () {
            if (this.routeRegExp) {
                const loc = location.pathname.match(this.routeRegExp);
                const regExp = RegExp(/\d+/gi);
                if (loc != null) {
                    const found = loc[0].match(regExp);
                    if (found != null && found.length == 2 && found[0] && found[1]) {
                        refTourDetails.current.lastClassroomId = Number(found[0]);
                        refTourDetails.current.lastProgrammeId = Number(found[1]);
                    }
                }
            }
        },
        onBeforeClose: function () {
            setCurrentStep(0);
        },
        steps: [
            {
                selector: "#deliver-session",
                mutationObservables: ["#deliver-session"],
                resizeObservables: ["#deliver-session"],
                content: "Click the Deliver Session button to start today's story.",
                position: "right",
                action: () => {
                    // Reset state of page

                    // Close first popup
                    const cancelStartSessionBtn = getRef("cancelStartSessionBtn");
                    if (cancelStartSessionBtn != null && cancelStartSessionBtn.current != null) {
                        const button: HTMLButtonElement | null =
                            cancelStartSessionBtn?.current?.querySelector("button");
                        if (button != null) {
                            button.click();
                        }
                    }

                    // Close second popup
                    const cancelStartSessionBtn2 = getRef("cancelStartSessionBtn2");
                    if (cancelStartSessionBtn2 != null && cancelStartSessionBtn2.current != null) {
                        const button: HTMLButtonElement | null =
                            cancelStartSessionBtn2?.current?.querySelector("button");
                        if (button != null) {
                            button.click();
                        }
                    }
                },
            },
            {
                selector: ".openSessionConfirmDialog",
                mutationObservables: [".openSessionConfirmDialog"],
                resizeObservables: [".openSessionConfirmDialog"],
                content:
                    "Sessions are one use only. Ending a session that has started will mark it as complete and you will not be able to go back.",
                position: "top",
                action: () => {
                    // Click "Deliver Session" as long as none of the confirmation popups are on screen
                    const deliverSessionBtn = getRef("deliverSessionBtn");
                    const startSessionBtn = getRef("startSessionBtn");
                    const startSessionBtn2 = getRef("startSessionBtn2");
                    if (
                        deliverSessionBtn != null &&
                        deliverSessionBtn.current != null &&
                        startSessionBtn != null &&
                        startSessionBtn.current == null &&
                        startSessionBtn2 != null &&
                        startSessionBtn2.current == null
                    ) {
                        deliverSessionBtn?.current?.click();
                    }
                },
            },
            {
                selector: ".openSessionContinueButton",
                mutationObservables: [".openSessionContinueButton"],
                resizeObservables: [".openSessionContinueButton"],
                content: "Click the Continue button to start your session.",
                position: "bottom",
            },
            {
                selector: ".openSessionNotPreparedDialog",
                mutationObservables: [".openSessionNotPreparedDialog"],
                resizeObservables: [".openSessionNotPreparedDialog"],
                content: "If you have not yet prepared your session, we will remind you before you start the session.",
                position: "top",
                action: () => {
                    const startSessionBtn = getRef("startSessionBtn");
                    if (startSessionBtn != null && startSessionBtn.current != null) {
                        const button: HTMLButtonElement | null = startSessionBtn?.current?.querySelector("button");
                        if (button != null) {
                            button.click();
                        }
                    }
                },
            },
            {
                selector: ".openSessionSkipPrepareButton",
                mutationObservables: [".openSessionSkipPrepareButton"],
                resizeObservables: [".openSessionSkipPrepareButton"],
                content: "Click the Continue button to start your session.",
                position: "bottom",
            },
            {
                content: "...",
                position: "center",
                action: () => {
                    const startSessionBtn2 = getRef("startSessionBtn2");
                    if (startSessionBtn2 != null && startSessionBtn2.current != null) {
                        const button: HTMLButtonElement | null = startSessionBtn2?.current?.querySelector("button");
                        if (button != null) {
                            button.click();
                        }
                    }
                },
            },
        ],
    });

    _reactours.set("deliverSessionPart2", {
        routeRegExp: RegExp(/^\/classrooms\/start\-session\/\d+$/i),
        routeDefault: true,
        disableDotsNavigation: true,
        disableKeyboard: ["left"],
        disableClose: true,
        onBeforeClose: function () {
            setCurrentStep(0);
        },
        steps: [
            {
                selector: "#root",
                content: "The session has now started.",
                position: "center",
            },
            {
                selector: ".presentationModeButton",
                content: "Click the Presentation Mode button for a presentation friendly view.",
                position: "bottom",
            },
            {
                selector: "#root",
                content: "In this view, we hide the questions usually visible at the bottom of the page.",
                position: "center",
                action: () => {
                    const presentationBtn = getRef("sessionPresentationBtn");
                    if (
                        presentationBtn != null &&
                        presentationBtn.current != null &&
                        presentationBtn.current.classList.contains("enterPresentationMode")
                    ) {
                        presentationBtn?.current?.click();
                    }
                },
            },
            {
                selector: ".presentationModeButton",
                content: "Click the Leave Presentation Mode button to return to our normal view.",
                position: "bottom",
            },
            {
                selector: "#root",
                content: "Now we read part one of the story to the class.",
                position: "center",
                action: () => {
                    const presentationBtn = getRef("sessionPresentationBtn");
                    if (
                        presentationBtn != null &&
                        presentationBtn.current != null &&
                        presentationBtn.current.classList.contains("leavePresentationMode")
                    ) {
                        presentationBtn?.current?.click();
                    }
                },
            },
            {
                selector: ".storyPartQuestions",
                content:
                    "When you've finished reading this part of the story, it's time to ask the class some questions.",
                position: "right",
            },
            {
                selector: ".showQuestionsBtn",
                content: "Click the Show Questions button to reveal the question 1.",
                position: "right",
            },
            {
                selector: ".storyPartQuestions",
                resizeObservables: [".storyPartQuestions"],
                content: "After answering the first question... ",
                position: "right",
                action: () => {
                    const showQuestionsBtn = getRef("showQuestionsBtn");
                    if (showQuestionsBtn != null && showQuestionsBtn.current != null) {
                        showQuestionsBtn?.current?.click();
                    }
                },
            },
            {
                selector: ".nextQuestionBtn",
                content: "click Next to move on to question 2.",
                position: "right",
            },
            {
                selector: ".storyPartQuestions",
                resizeObservables: [".storyPartQuestions"],
                content: "Repeat this for question 2....",
                position: "right",
                action: () => {
                    const nextQuestionBtn = getRef("nextQuestionBtn");
                    if (
                        nextQuestionBtn != null &&
                        nextQuestionBtn.current != null &&
                        nextQuestionBtn.current.classList.contains("question1")
                    ) {
                        nextQuestionBtn?.current?.click();
                    }
                },
            },
            {
                selector: ".storyPartQuestions",
                resizeObservables: [".storyPartQuestions"],
                content: "... and question 3.",
                position: "right",
                action: () => {
                    const nextQuestionBtn = getRef("nextQuestionBtn");
                    if (
                        nextQuestionBtn != null &&
                        nextQuestionBtn.current != null &&
                        nextQuestionBtn.current.classList.contains("question2")
                    ) {
                        nextQuestionBtn?.current?.click();
                    }
                },
            },
            {
                selector: ".nextStoryPartBtn",
                resizeObservables: [".nextStoryPartBtn"],
                content: "Now you can move on to part 2 of the story.",
                position: "right",
            },
            {
                content: "Now that you've completed part 1 of the story let's skip to the end of part 3.",
                position: "center",
                action: () => {
                    const nextStoryPartBtn = getRef("nextStoryPartBtn");
                    if (nextStoryPartBtn != null && nextStoryPartBtn.current != null) {
                        nextStoryPartBtn?.current?.click();
                    }
                },
            },
            {
                selector: ".storyCompleteBtn",
                mutationObservables: [".storyCompleteBtn"],
                resizeObservables: [".storyCompleteBtn", ".storyPartQuestions"],
                content: "You just finished reading the third story part and answering it's relevant questions.",
                position: "center",
                action: () => {
                    // Not sure if locking is necessary anymore, but now harm in keeping it
                    if (locked != null && locked.current != null && !locked.current) {
                        locked.current = true;
                        const event = new CustomEvent("goToEnd");
                        document.dispatchEvent(event);
                        const storyCompleteBtn = getRef("storyCompleteBtn");
                        storyCompleteBtn?.current?.scrollIntoView();
                        //enableBodyScroll(document.body);
                        setTimeout(() => {
                            locked.current = false;
                            // For some reason scrolling to selector breaks
                            // calling "enableBodyScroll" fixes it
                            //console.log("Enabling Body Scroll");
                            //enableBodyScroll(document.body);
                            //disableBodyScroll(document.body);
                            //const storyCompleteBtn = getRef("storyCompleteBtn");
                            //storyCompleteBtn?.current?.scrollIntoView();
                        }, 200);
                    }
                },
            },
            {
                selector: ".storyCompleteBtn",
                mutationObservables: [".storyCompleteBtn"],
                resizeObservables: [".storyCompleteBtn", ".storyPartQuestions"],
                content: "You can click the Story Complete button to continue.",
                position: "right",
            },
            {
                content: "...",
                position: "center",
                action: () => {
                    const storyCompleteBtn = getRef("storyCompleteBtn");
                    if (storyCompleteBtn != null && storyCompleteBtn.current != null) {
                        storyCompleteBtn?.current?.click();
                    }
                },
            },
        ],
    });

    _reactours.set("deliverSessionPart3", {
        routeRegExp: RegExp(/^\/classrooms\/start\-session\/\d+/i),
        routeDefault: true,
        disableDotsNavigation: true,
        disableKeyboard: ["left"],
        disableClose: true,
        onBeforeClose: function () {
            setCurrentStep(0);
        },
        steps: [
            {
                content: "This is the Teacher's Survey page, where we ask for feedback on how the class went.",
                position: "center",
                action: () => {
                    // Skip to step 4 if Survey is skipped
                    const surveySaveAnswersBtn = getRef("surveySaveAnswersBtn");
                    if (surveySaveAnswersBtn == null || surveySaveAnswersBtn.current == null) {
                        setCurrentStep(4);
                    }
                },
            },
            {
                selector: ".storyQualitySurvey",
                content: "How engaging was the story?",
                position: "right",
            },
            {
                selector: ".annotationQualitySurvey",
                content: "How effective were the questions at sparking meaningful conversations?",
                position: "right",
            },
            {
                selector: ".saveAnswersBtn",
                content: "After answering both of these questions you can continue to the End Session screen.",
                position: "right",
            },
            {
                selector: ".sessionCompleteOptions",
                resizeObservables: [".sessionCompleteOptions", ".sessionCompleteImgContainer"],
                content:
                    "Congratulations! You just completed your first session. You can now end the session by clicking on the appropriate button below. You can also give the class a post-session mood tracker.",
                position: "right",
                action: () => {
                    const surveySaveAnswersBtn = getRef("surveySaveAnswersBtn");
                    if (surveySaveAnswersBtn != null && surveySaveAnswersBtn.current != null) {
                        surveySaveAnswersBtn?.current?.click();
                    }
                },
            },
            {
                content: "...",
                position: "center",
                action: () => {
                    console.log("tourDetails", refTourDetails.current);
                    setTourState((prev) => ({ ...prev, openTour: false }));
                    if (
                        typeof refTourDetails.current.lastClassroomId == "number" &&
                        typeof refTourDetails.current.lastProgrammeId == "number"
                    ) {
                        setTourState((prev) => ({ ...prev, openTour: false }));
                        historyHook.push(
                            `/classrooms/view/${refTourDetails.current.lastClassroomId}/view/${refTourDetails.current.lastProgrammeId}`,
                        );
                    } else {
                        historyHook.push("/home");
                    }
                },
            },
        ],
    });

    _reactours.set("classroomsListTour", {
        routeRegExp: RegExp(/^\/classrooms$/),
        routeDefault: true,
        steps: [
            {
                selector: "#classroomsList",
                content: "This is a list of classrooms you have been added as a teacher in",
                position: "bottom",
                stepInteraction: false,
            },
            {
                selector: "#classroom-settings",
                content: "This is where you can add teachers or students without going into the classroom",
                position: "bottom",
                stepInteraction: false,
            },
            {
                selector: "#goToClassroom",
                content: "You click here to view the classroom",
                position: "bottom",
                stepInteraction: false,
            },
            {
                selector: "#createClassroom",
                content:
                    "You can create a new classroom here. The next step will bring you to the Create Classroom screen",
                stepInteraction: false,
            },
            {
                content: "",
                action: () => {
                    const element = getRef("classroomCreate");
                    //console.log("BEFORE ACTION", refs);
                    //if (element != null && element.current != null) {
                    //console.log("IN ACTION");
                    element?.current?.click();
                    //}
                },
            },
        ],
    });

    _reactours.set("classroomsListTourEmpty", {
        routeRegExp: RegExp(/^\/classrooms\?empty$/),
        routeDefault: true,
        steps: [
            {
                selector: "#classroomsListEmpty",
                content: "this shows that you haven't created any classrooms yet",
                position: "bottom",
                stepInteraction: false,
            },
            {
                selector: "#createFirstClassroom",
                content:
                    "You can create you first classroom by clicking here. The next step will bring you to the Create Classroom screen",
                stepInteraction: false,
            },
            {
                content: "",
                action: () => {
                    const element = getRef("classroomCreate");
                    //console.log("BEFORE ACTION", refs);
                    //if (element != null && element.current != null) {
                    //console.log("IN ACTION");
                    element?.current?.click();
                    //}
                },
            },
        ],
    });

    _reactours.set("createClassroomTour", {
        routeRegExp: RegExp(/^\/classrooms\/add$/),
        routeDefault: true,
        steps: [
            {
                selector: ".classroom-name",
                content: "This is where you enter the classroom name.",
                position: "bottom",
                stepInteraction: false,
            },
            {
                selector: ".description",
                content: "This is where you enter a description about the classroom - this is optional.",
                position: "bottom",
                stepInteraction: false,
            },
            {
                selector: "#ageRange",
                content: "This is where you set the age range of the class",
                position: "bottom",
                stepInteraction: false,
            },
            {
                selector: ".mainTeacher",
                content:
                    "By default, the teacher creating the classroom is the main teacher. you can select this box if you are setting a classroom up for another teacher",
                stepInteraction: false,
            },
            {
                selector: ".cont-btn",
                content:
                    "when you have filled out all of the fields, this will save and create a new classroom. It will bring you back to the classrooms list which will have your newly created classroom listed",
                position: "bottom",
            },
            {
                selector: ".back-btn",
                content:
                    "This will bring you back to the list of classrooms and will not save any details you have entered into the fields",
                stepInteraction: false,
                position: "bottom",
            },

            {
                content: "",
                position: "bottom",
                action: () => {
                    history.back();
                    setTourState((prev) => ({ ...prev, openTour: false, nextTourKey: "" }));
                },
            },
        ],
    });

    _reactours.set("viewProgrammeTour", {
        routeRegExp: RegExp(/^\/classrooms\/view\/\d+\/view\/\d+/i),
        routeDefault: true,
        steps: [
            {
                selector: "#prog-label",
                content: "This shows the name of the programme.",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#prog-freq",
                content:
                    "This shows the number of sessions in the programme and their frequency (Weekly, Monthly, etc.)",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#prog-delete",
                content: "You can click here to delete your programme or restore a previously deleted programme",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#sessions-tab",
                content: "This is the sessions tab which shows the list of sessions. You are viewing this tab now",
                stepInteraction: false,
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#next-session",
                content: "This shows the details of the next session in the programme",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#edit-session",
                content: "You can click here to edit the session",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#session-story",
                content: "This shows the story that will be read in the session, and the story author.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#session-date",
                content:
                    "This shows the scheduled time and date of the session. This isn't enforced so you do not have to complete the session before the date displayed. It is simply for guidance.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#prepare-session",
                content:
                    "This is where you can prepare the session. You can review the story and discussion questions, and even annotate or highlight sections of the story to help you during delivery.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#deliver-session",
                content:
                    "This is where you can start the session. Ensure to read the dialog warning before confirming when starting a session.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#session-resources",
                content: "This gives you extra learning resources.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#all-sessions",
                content:
                    "This shows all of the sessions in the programme. Sessions marked with a tick have been completed.",
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#teachers-guide-tab",
                content: "This is where you can view guidance notes for this programme",
                position: "bottom",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: "#teachers-guide",
                content: "This is the guidance notes for this programme.",
                mutationObservables: ["#teachers-guide"],
                action: () => {
                    const element = getRef("programmeGuideTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                content:
                    "Thank you for taking the programme tour. You can start the tour again any time from the navigation bar",
                action: () => {
                    const element = getRef("programmeSessionTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
        ],
    });

    _reactours.set("prepareSessionTour", {
        routeRegExp: RegExp(/^\/prepare-session\/\d+$/i),
        routeDefault: true,
        steps: [
            {
                selector: '[data-tour="prep-session-bc"]',
                content:
                    "This is the breadcrumbs to show where you are. You can get back to the programme by clicking the Programme link",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-name"]',
                content: "This shows name of the story",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-synopsis-tab"]',
                content: "This is the synopsis tab which shows information about the story.",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-age"]',
                content: "This shows the age group the story is for",
                stepInteraction: false,
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-theme"]',
                content: "This shows main theme of the story",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-author"]',
                content: "You shows the author of the story",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-synopsis"]',
                content: "This shows the story synopsis which is a short overview of the story.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-objective"]',
                content: "This shows the learning objective of the story.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-tab"]',
                content: "This is the story tab where you can prepare the story before delivering the session.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-part-num"]',
                content: "This shows the current part.",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-story-part-num"]'],
                resizeObservables: ['[data-tour="prep-story-part-num"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-part-accord"]',
                content: "you can expand and minimise the story part here.",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-part-accord"]'],
                resizeObservables: ['[data-tour="prep-part-accord"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-alert"]',
                content: "This gives you a tip on how to prepare.",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-story-alert"]'],
                resizeObservables: ['[data-tour="prep-story-alert"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-story-content"]',
                content:
                    "This allows you to make edits to the story text such as make text larger, change text colour, highlight colour, and even add notes",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-story-alert"]'],
                resizeObservables: ['[data-tour="prep-story-alert"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-question-num"]',
                content: "This shows the question number.",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-question-num"]'],
                resizeObservables: ['[data-tour="prep-question-num"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-question"]',
                content: "This shows the question.",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-question"]'],
                resizeObservables: ['[data-tour="prep-question"]'],
                action: () => {
                    const showBtn = getRef("prepareShowQuestions");
                    if (showBtn != null && showBtn.current != null) {
                        showBtn?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prepare-why-ask"]',
                content: "This explain why you are asking the question",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prepare-why-ask"]'],
                resizeObservables: ['[data-tour="prepare-why-ask"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-question-keywords"]',
                content: "This explains the keywords in the question",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-question-keywords"]'],
                resizeObservables: ['[data-tour="prep-question-keywords"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-question-guide"]',
                content: "This helps guide you",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-question-guide"]'],
                resizeObservables: ['[data-tour="prep-question-guide"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-question-no-response"]',
                content: "This helps when there is no response from the student",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prep-question-no-response"]'],
                resizeObservables: ['[data-tour="prep-question-no-response"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prepare-story-save"]',
                content:
                    "you can save or cancel any edits or annotations you added to the story by clicking cancel or save here",
                stepInteraction: false,
                position: "bottom",
                mutationObservables: ['[data-tour="prepare-story-save"]'],
                resizeObservables: ['[data-tour="prepare-story-save"]'],
                action: () => {
                    const element = getRef("prepareStoryTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="prep-learn-tab"]',
                content:
                    "This is the learning resources tab where you can find extra learning resources to help you get the most out of the session.",
                stepInteraction: false,
                position: "bottom",
                action: () => {
                    const element = getRef("prepareResourcesTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                content: "Thank you for taking the tour. you can take it again at any time from the navigation menu",
                position: "bottom",
                action: () => {
                    const element = getRef("prepareSynopsisTab");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
        ],
    });

    _reactours.set("addUserTour", {
        routeRegExp: RegExp(/^\/add-users$/),
        routeDefault: true,
        steps: [
            {
                selector: '[data-tour="add-users-all"]',
                content: "This shows the different rolls within the system.",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("saveUserBackBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-permissions"]',
                content: "Each role has a list of permissions the user can do within the system. ",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("saveUserBackBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-role-btn"]',
                content: "You can click here to add a new user with the listed permissions",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("saveUserBackBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-name"]',
                content: "You can enter the users first name here",
                position: "bottom",
                stepInteraction: false,
                mutationObservables: ['[data-tour="add-users-name"]'],
                resizeObservables: ['[data-tour="add-users-name"]'],
                action: () => {
                    const element = getRef("addUserBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-surname"]',
                content: "You can enter the users surname here",
                position: "bottom",
                stepInteraction: false,
                mutationObservables: ['[data-tour="add-users-surname"]'],
                resizeObservables: ['[data-tour="add-users-surname"]'],
                action: () => {
                    const element = getRef("addUserBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-email"]',
                content: "You can enter the users email here",
                position: "bottom",
                stepInteraction: false,
                mutationObservables: ['[data-tour="add-users-email"]'],
                resizeObservables: ['[data-tour="add-users-email"]'],
                action: () => {
                    const element = getRef("addUserBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-role"]',
                content: "This reminds you of the user role type you selected",
                position: "bottom",
                stepInteraction: false,
                mutationObservables: ['[data-tour="add-users-role"]'],
                resizeObservables: ['[data-tour="add-users-role"]'],
                action: () => {
                    const element = getRef("addUserBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-add-btn"]',
                content:
                    "You click here to add the user when you have filled out all of the detail. The user will receive an email inviting them on to the system to set their password.",
                position: "bottom",
                stepInteraction: false,
                mutationObservables: ['[data-tour="add-users-add-btn"]'],
                resizeObservables: ['[data-tour="add-users-add-btn"]'],
                action: () => {
                    const element = getRef("addUserBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                selector: '[data-tour="add-users-back-btn"]',
                content: "You can click here to cancel adding a user which will close the dialog box.",
                position: "bottom",
                stepInteraction: false,
                mutationObservables: ['[data-tour="add-users-back-btn"]'],
                resizeObservables: ['[data-tour="add-users-back-btn"]'],
                action: () => {
                    const element = getRef("addUserBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
            },
            {
                content:
                    "Thank you for taking the add user tour. You can take the tour again any time by clicking the bus icon on the navigation menu",
                position: "bottom",
                stepInteraction: false,
                action: () => {
                    const element = getRef("saveUserBackBtn");
                    if (element != null && element.current != null) {
                        element?.current?.click();
                    }
                },
                // content: "...",
                // action: () => {
                //     setTourState((prev) => ({ ...prev, openTour: false, nextTourKey: "" }));
                //     history.back();
                // },
            },
        ],
    });

    useEffect(() => {
        setTourState((prev) => ({ ...prev, currentRoute: location.pathname + location.search }));
    }, [location]);

    useEffect(() => {
        if (tourState.tours == undefined || tourState.tours.size == 0) {
            setTourState((prev) => ({ ...prev, tours: _reactours }));
        }
    }, [location]);

    // const addTour = function (key: string, value: TourGuideI) {
    //     if (typeof key == "string" && key.length > 0) {
    //         tourState.tours.set(key, { ...TourGuideDefaults, ...value });
    //     }
    // };

    const getRef = function (key: string): React.MutableRefObject<HTMLElement | null> | undefined {
        return tourState.refs.get(key);
    };

    useEffect(() => {
        let tourKeyFound = false;

        if (
            typeof tourState.nextTourKey == "string" &&
            tourState.nextTourKey.length > 0 &&
            tourState.nextTourKey != tourState.currentTourKey
        ) {
            const nextTour = tourState.tours.get(tourState.nextTourKey);

            if (
                nextTour &&
                tourState.currentRoute &&
                nextTour?.routeRegExp != null &&
                nextTour?.routeRegExp.test(tourState.currentRoute)
            ) {
                setTourState((prev) => ({
                    ...prev,
                    currentTour: tourState.tours.get(tourState.nextTourKey),
                    currentTourKey: tourState.nextTourKey,
                }));
                tourKeyFound = true;
            }
        }

        if (!tourKeyFound) {
            if (tourState.currentRoute) {
                let routeFound = false;
                for (const [key, value] of tourState.tours) {
                    if (
                        value.routeDefault &&
                        value.routeRegExp != null &&
                        value.routeRegExp.test(tourState.currentRoute)
                    ) {
                        if (tourState.currentTourKey != key) {
                            setTourState((prev) => ({
                                ...prev,
                                currentTour: value,
                                currentTourKey: key,
                            }));
                        }
                        routeFound = true;
                        break;
                    }
                }
                if (!routeFound) {
                    setTourState((prev) => ({
                        ...prev,
                        currentTour: undefined,
                        currentTourKey: "",
                    }));
                }
            }
        }
    }, [tourState.nextTourKey, tourState.currentRoute]);

    const useStyles = makeStyles(() =>
        createStyles({
            tour: {
                border: "2px solid #1FAF8C",
                backgroundColor: verbalTheme.palette.background.paper,
                color: verbalTheme.palette.text.primary,
                boxShadows: verbalTheme.shadows[1],
                fontSize: 16,
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            {/* {console.log("Rendering RTour")} */}
            {tourState.currentTour != undefined ? (
                <Tour
                    disableFocusLock
                    accentColor={verbalTheme.palette.primary.main}
                    className={classes.tour}
                    steps={tourState.currentTour.steps}
                    isOpen={tourState.openTour}
                    onRequestClose={() => {
                        if (tourState.currentTour != null && !tourState.currentTour.disableClose) {
                            setTourState((prev) => ({ ...prev, openTour: false, nextTourKey: "" }));
                        }
                    }}
                    getCurrentStep={(curr) => {
                        setCurrentStep(curr);
                        if (tourState.currentTour != null && !disableKeyboard) {
                            setDisableKeyboard(true);
                            setTimeout(() => {
                                setDisableKeyboard(false);
                            }, 300);
                        }
                    }}
                    startAt={currentStep}
                    showCloseButton={true}
                    scrollOffset={
                        typeof tourState.currentTour.scrollOffset == "undefined"
                            ? 0
                            : tourState.currentTour.scrollOffset
                    }
                    scrollDuration={200}
                    onAfterOpen={(target) => {
                        if (tourState.currentTour != null && tourState.currentTour.onAfterOpen != null) {
                            tourState.currentTour?.onAfterOpen();
                        }
                        disableBodyScroll(target);
                    }}
                    onBeforeClose={(target) => {
                        if (tourState.currentTour != null && tourState.currentTour.onBeforeClose != null) {
                            tourState.currentTour?.onBeforeClose();
                        }
                        enableBodyScroll(target);
                    }}
                    disableInteraction
                    goToStep={currentStep}
                    disableKeyboardNavigation={disableKeyboard || tourState.currentTour.disableKeyboard}
                    disableDotsNavigation={
                        typeof tourState.currentTour.disableDotsNavigation == "undefined"
                            ? false
                            : tourState.currentTour.disableDotsNavigation
                    }
                    prevButton={<div></div>}
                />
            ) : null}
        </>
    );
};
