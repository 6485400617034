import React, { FC } from "react";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import barchartImg from "../../../../img/pie.svg";
import { SimpleBlankSlate } from "../../../../core/components/SimpleBlankSlate";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { VictoryPie, VictoryTheme } from "victory";
import { verbalTheme } from "../../../layout/themes/verbal.theme";

const useStyles = makeStyles(
    createStyles({
        widget: {
            height: "100%",
            minHeight: "235px",
        },
        widgetInner: {
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
            flexDirection: "column",
        },
        chartContainer: {
            "& svg": {
                maxHeight: "350px",
            },
            textAlign: "center",
        },
    }),
);

export const PieChartWidget: FC<{
    header: string;
    spinWhileFetching?: boolean;
    subHeader: string;
    noDataMessage?: string;
    data?: Record<string, unknown>[];
}> = ({ header, subHeader, spinWhileFetching = false, noDataMessage, data }) => {
    const classes = useStyles();
    const { fetching } = useFetch();
    const Loader = spinWhileFetching ? SpinWhileFetching : React.Fragment;
    return (
        <Grid item xs={12} md={6}>
            <Card className={classes.widget} variant="outlined">
                <CardContent className={clsx(classes.widgetInner)}>
                    {!fetching && !data ? (
                        <SimpleBlankSlate
                            image={<img src={barchartImg} />}
                            header={header}
                            subHeader={subHeader}
                            extra={
                                <Box mb={2}>
                                    <Typography variant={"body1"}>{noDataMessage}</Typography>
                                </Box>
                            }
                        />
                    ) : (
                        <>
                            <Box>
                                <Typography variant="h5">{header}</Typography>
                                <Typography variant="body2" gutterBottom>
                                    {subHeader}
                                </Typography>
                            </Box>
                            <Box className={classes.chartContainer}>
                                <Loader>
                                    <VictoryPie
                                        theme={VictoryTheme.material}
                                        animate={{
                                            duration: 2000,
                                        }}
                                        style={{
                                            labels: {
                                                fill: verbalTheme.palette.secondary.main,
                                                fontFamily: verbalTheme.typography.fontFamily,
                                            },
                                        }}
                                        data={data}
                                        labels={({ datum }) => {
                                            return `${datum.x}: ${Math.round(datum.y)}`;
                                        }}
                                    />
                                </Loader>
                            </Box>
                        </>
                    )}
                </CardContent>
            </Card>
        </Grid>
    );
};
