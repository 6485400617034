import { Button, Divider, Grid, makeStyles, Typography, createStyles } from "@material-ui/core";
import { RelatedQuestion } from "common/build/prisma/client";
import React, { useEffect, useRef, useState } from "react";
import { VModule } from "../../../../core/components/VModule";
import { VPill, VPillClosedQuestion } from "../../../../core/components/VPill";
import { MappedStoryPartType } from "../../../../models/modelTypes";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { QConditionButton } from "./QConditionButton";
import { QInfoModule } from "./QInfoModule";
import { useSetTourState, useTrackedTourState } from "../../../tour/Store";

type PrepareQuestionModuleType = {
    part?: MappedStoryPartType;
    mappedStoryId?: number;
};

export const PrepareQuestionModule = (props: PrepareQuestionModuleType): JSX.Element => {
    const { part, mappedStoryId } = props;

    const [questionRelated, setQuestionRelated] = useState<Map<string, string>>(new Map());
    // track what is open to display
    const [questionOpen, setQuestionOpen] = useState<Map<number, boolean>>(new Map());
    const [relatedOpen, setRelatedOpen] = useState<Map<string, boolean>>(new Map());

    const setTourState = useSetTourState();
    const tourState = useTrackedTourState();

    const refLearnMore = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (tourState.openTour) {
            const questions = part?.MappedStoryQuestion?.filter((q) => q.mappedStoryId === mappedStoryId).sort(
                (a, b) => a.order - b.order,
            );

            if (questions) {
                for (let i = 0; i < questions.length; i++) {
                    setQuestionOpen(new Map(questionOpen.set(questions[i].id, true)));
                }
            }
        }
    }, [tourState.openTour, part]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("prepareQLearn", refLearnMore) }));
        //tourState.refs.set("prepareQLearn", refLearnMore);
    }, [refLearnMore]);

    const handleConditionClick = (id: number, rq: Partial<RelatedQuestion>) => {
        if (rq.question) {
            setRelatedOpen(
                new Map(
                    relatedOpen.set(
                        JSON.stringify({
                            id: id,
                            condition: rq.condition,
                        }),
                        !relatedOpen.get(
                            JSON.stringify({
                                id: id,
                                condition: rq.condition,
                            }),
                        ) || false,
                    ),
                ),
            );
            setQuestionRelated(
                new Map(
                    questionRelated.set(
                        JSON.stringify({
                            id: id,
                            condition: rq.condition,
                        }),
                        rq.question,
                    ),
                ),
            );
        }
    };

    const useStyles = makeStyles(() =>
        createStyles({
            keywordChip: {
                borderRadius: 0,
                margin: "0 0.5rem 0.5rem 0",
            },
        }),
    );
    const classes = useStyles();

    return (
        <>
            <Grid item container spacing={4}>
                {part?.MappedStoryQuestion?.filter((q) => q.mappedStoryId === mappedStoryId)
                    .sort((a, b) => a.order - b.order)
                    .map((question, index) => {
                        return (
                            <Grid item xs={12} key={question.id}>
                                <Grid item container spacing={1} alignItems="center" data-tour="prepare-question-block">
                                    <Grid item xs={12} alignItems="center">
                                        <Grid item container direction="row" spacing={1} alignContent="center">
                                            {question.category === "Closed" ? (
                                                <>
                                                    <Grid item xs={1}></Grid>
                                                    <Grid item xs={11}>
                                                        <VPillClosedQuestion />
                                                    </Grid>
                                                </>
                                            ) : null}

                                            <Grid item xs={1}>
                                                <Typography
                                                    variant="h6"
                                                    color="primary"
                                                    data-tour={index === 0 ? "prep-question-num" : undefined}
                                                >
                                                    Q{question.order}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={11}>
                                                <Typography
                                                    style={{ whiteSpace: "pre-line" }}
                                                    data-tour={index === 0 ? "prep-question" : undefined}
                                                >
                                                    <b>
                                                        <i>{question.question}</i>
                                                    </b>
                                                    {"  "}
                                                </Typography>
                                            </Grid>

                                            <Grid item xs={1}></Grid>

                                            <Grid item xs={11} container spacing={1}>
                                                {question.RelatedQuestion && question.RelatedQuestion.length > 0
                                                    ? question.RelatedQuestion.map((rq, index) => (
                                                          <Grid key={index} item>
                                                              <QConditionButton
                                                                  questionId={question.id}
                                                                  rq={rq}
                                                                  handler={handleConditionClick}
                                                                  open={relatedOpen.get(
                                                                      JSON.stringify({
                                                                          id: question.id,
                                                                          condition: rq.condition,
                                                                      }),
                                                                  )}
                                                              />
                                                          </Grid>
                                                      ))
                                                    : null}

                                                <Grid item>
                                                    {questionOpen.get(question.id) ? (
                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            size="small"
                                                            onClick={() =>
                                                                setQuestionOpen(
                                                                    new Map(questionOpen.set(question.id, false)),
                                                                )
                                                            }
                                                        >
                                                            Read less
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outlined"
                                                            size="small"
                                                            onClick={() =>
                                                                setQuestionOpen(
                                                                    new Map(questionOpen.set(question.id, true)),
                                                                )
                                                            }
                                                        >
                                                            Learn about this question
                                                        </Button>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={
                                            relatedOpen.get(
                                                JSON.stringify({
                                                    id: question.id,
                                                    condition: "No",
                                                }),
                                            )
                                                ? {
                                                      display: "block",
                                                  }
                                                : {
                                                      display: "none",
                                                  }
                                        }
                                    >
                                        <VModule>
                                            <Typography color="primary" variant="caption" paragraph>
                                                <b>If the group say No ask</b>
                                            </Typography>
                                            <Typography style={{ whiteSpace: "pre-line" }}>
                                                {questionRelated.get(
                                                    JSON.stringify({
                                                        id: question.id,
                                                        condition: "No",
                                                    }),
                                                )}
                                            </Typography>
                                        </VModule>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                        style={
                                            relatedOpen.get(
                                                JSON.stringify({
                                                    id: question.id,
                                                    condition: "Yes",
                                                }),
                                            )
                                                ? {
                                                      display: "block",
                                                  }
                                                : {
                                                      display: "none",
                                                  }
                                        }
                                    >
                                        <VModule>
                                            <Typography color="primary" variant="caption" paragraph>
                                                <b>If the group say Yes ask</b>
                                            </Typography>
                                            <Typography style={{ whiteSpace: "pre-line" }}>
                                                {questionRelated.get(
                                                    JSON.stringify({
                                                        id: question.id,
                                                        condition: "Yes",
                                                    }),
                                                )}
                                            </Typography>
                                        </VModule>
                                    </Grid>

                                    <div data-tour={index === 0 ? "prepare-why-ask" : undefined}>
                                        <QInfoModule
                                            title="Why Ask?"
                                            content={question.explaination ?? undefined}
                                            open={questionOpen.get(question.id)}
                                        />
                                    </div>

                                    <div data-tour={index === 0 ? "prep-question-keywords" : undefined}>
                                        <QInfoModule
                                            title="Keywords"
                                            content={question.keywordDesc ?? undefined}
                                            open={questionOpen.get(question.id)}
                                        >
                                            {question.keywords?.split(" ").map((kw, index) => (
                                                <VPill
                                                    key={kw}
                                                    style={
                                                        index % 2 === 0
                                                            ? {
                                                                  backgroundColor: "#28B5BE",
                                                              }
                                                            : {
                                                                  backgroundColor: "#D3B61A",
                                                              }
                                                    }
                                                    className={classes.keywordChip}
                                                    label={kw}
                                                />
                                            ))}
                                        </QInfoModule>
                                    </div>

                                    <div data-tour={index === 0 ? "prep-question-guide" : undefined}>
                                        <QInfoModule
                                            title="Guide Note"
                                            content={question.prepGuide ?? undefined}
                                            open={questionOpen.get(question.id)}
                                        />
                                    </div>

                                    <div data-tour={index === 0 ? "prep-question-no-response" : undefined}>
                                        <Grid
                                            item
                                            xs={12}
                                            style={
                                                questionOpen.get(question.id)
                                                    ? {
                                                          display: "block",
                                                      }
                                                    : {
                                                          display: "none",
                                                      }
                                            }
                                        >
                                            <VModule>
                                                <Typography color="primary" variant="caption" paragraph>
                                                    <b>No Response?</b>
                                                </Typography>
                                                <Typography paragraph variant="body1">
                                                    <b>If the group are still not responding, try asking:</b>
                                                </Typography>
                                                <Typography style={{ whiteSpace: "pre-line" }} paragraph>
                                                    {question.prompt}
                                                </Typography>
                                                <Typography>
                                                    <b>
                                                        If this still doesn&apos;t generate a discussion you can move on
                                                        to the next question
                                                    </b>
                                                </Typography>
                                                <Typography color="primary">
                                                    <b>
                                                        Don&apos;t worry we will remind you again when you&apos;re in
                                                        delivery
                                                    </b>
                                                </Typography>
                                            </VModule>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ marginTop: verbalTheme.spacing(3) }}>
                                    <Divider
                                        style={{
                                            margin: 5,
                                        }}
                                        light
                                    />
                                </Grid>
                            </Grid>
                        );
                    })}
            </Grid>
        </>
    );
};
