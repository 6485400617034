import React, { PropsWithChildren } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

type ConfirmDialogProps = PropsWithChildren<{
    open: boolean;
    title: string;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm: () => void;
    onCancel: () => void;
}>;

export const ConfirmDialog = (props: ConfirmDialogProps): JSX.Element => {
    const {
        open,
        title,
        confirmButtonText = "Confirm",
        cancelButtonText = "Cancel",
        onConfirm,
        onCancel,
        children,
    } = props;

    return (
        <div>
            <Dialog open={open} onClose={onCancel}>
                <DialogTitle id="confirm-dialog-title">{title}</DialogTitle>
                <DialogContent>{children}</DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} variant={"text"}>
                        {cancelButtonText}
                    </Button>
                    <Button onClick={onConfirm} variant={"contained"} color="primary" autoFocus>
                        {confirmButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default ConfirmDialog;
