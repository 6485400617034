import React, { useCallback, createContext, useContext, ReactNode, useState } from "react";
import { useIntQueryStringState } from "../../../hooks/useQueryString";
import { StoryType } from "../../../models/modelTypes";
import { node } from "prop-types";
import { add, update, reviewStory, publishStory } from "../../../client/api/story";

interface StoryContextI {
    activeStep: number;
    completedSteps: { [k: number]: boolean };
    details: Partial<StoryType> & {
        numParts: number;
        themeIds?: { id: number }[];
        parts?: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
    };
    story?: StoryType;
    loading: boolean;

    changeStep: (newStep: number) => void;
    submitStep: (details?: Partial<StoryType> & { numParts: number; themeIds?: { id: number }[] }) => void;

    addNewStory: (
        storyDetails: Partial<StoryType> & {
            themeIds?: { id: number }[];
            parts: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
        },
    ) => Promise<void>;
    updateStory: (
        storyId: number,
        storyDetails: Partial<StoryType> & {
            themeIds?: { id: number }[];
            parts: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
        },
    ) => Promise<void>;
    clearProvider: () => Promise<void>;
    setCurrentDetails: (
        details?: Partial<StoryType> & {
            numParts: number;
            themeIds?: { id: number }[];
            parts?: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
        },
    ) => Promise<void>;
    setCurrentStory: (currentStory?: StoryType) => void;
    markStoryReviewed: (storyId: number) => Promise<void>;
    markStoryPublished: (storyId: number) => Promise<void>;
}

const StoryContext = createContext({} as StoryContextI);

export const StoryProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const initialDetails = {
        storyName: "",
        synopsis: "",
        author: "",
        resources: "",
        numParts: 0,
        themeIds: [],
        parts: [],
    };

    const [activeStep, setActiveStep] = useIntQueryStringState("step", 0);
    const [story, setStory] = useState<StoryType | undefined>();
    const [completedSteps, setCompletedSteps] = useState<{ [k: number]: boolean }>({});
    const [details, setDetails] = useState<
        Partial<StoryType> & {
            numParts: number;
            themeIds?: { id: number }[];
            parts?: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
        }
    >(initialDetails);
    const [loading, setLoading] = useState<boolean>(false);

    const changeStep = useCallback((newStep: number) => {
        setActiveStep(newStep);
    }, []);

    const submitStep = useCallback(
        (
            details?: Partial<StoryType> & {
                numParts: number;
                themeIds?: { id: number }[];
                parts?: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
            },
        ) => {
            if (details) {
                setDetails({ ...details });
            }
            setCompletedSteps((prevCompletedSteps) => ({ ...prevCompletedSteps, [activeStep]: true }));
        },
        [activeStep],
    );

    const addNewStory = useCallback(
        (
            storyDetails: Partial<StoryType> & {
                themeIds?: { id: number }[];
                parts: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
            },
        ) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                add(storyDetails)
                    .then(async (response: Response) => {
                        if (response.ok) {
                            const storyResponse: StoryType = await response.json();
                            setStory(storyResponse);
                            resolve();
                        } else {
                            reject("Unable to add new story");
                        }
                    })
                    .catch((error) => {
                        reject(error.message);
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const updateStory = useCallback(
        (
            storyId: number,
            storyDetails: Partial<StoryType> & {
                themeIds?: { id: number }[];
                parts: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
            },
        ) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);
                update(storyId, storyDetails)
                    .then(async (response: Response) => {
                        if (response.ok) {
                            const storyResponse: StoryType = await response.json();
                            setStory(storyResponse);
                            resolve();
                        } else {
                            reject("Unable to add new story");
                        }
                    })
                    .catch((error) => {
                        reject(error.message);
                    })
                    .finally(() => setLoading(false));
            });
        },
        [],
    );

    const clearProvider = useCallback(() => {
        return new Promise<void>((resolve) => {
            setStory(undefined);
            setDetails(initialDetails);
            setActiveStep(0);
            setCompletedSteps({});
            resolve();
        });
    }, []);

    const setCurrentDetails = useCallback(
        (
            currentDetails?: Partial<StoryType> & {
                numParts: number;
                themeIds?: { id: number }[];
                parts?: { part: number; content: string; figurativeNotes: string; imageUrl: string | null }[];
            },
        ) => {
            return new Promise<void>((resolve) => {
                if (currentDetails) setDetails(currentDetails);
                resolve();
            });
        },
        [],
    );

    const setCurrentStory = useCallback((currentStory?: StoryType) => {
        setStory(currentStory);
    }, []);

    const markStoryReviewed = useCallback((storyId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            reviewStory(storyId)
                .then(async (response: Response) => {
                    if (response.ok) {
                        const storyResponse: StoryType = await response.json();
                        setStory(storyResponse);
                        resolve();
                    } else {
                        setStory(undefined);
                        reject("Unable to update story");
                    }
                })
                .catch(() => {
                    setStory(undefined);
                    reject("Unable to update story");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    const markStoryPublished = useCallback((storyId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);
            publishStory(storyId)
                .then(async (response: Response) => {
                    if (response.ok) {
                        const storyResponse: StoryType = await response.json();
                        setStory(storyResponse);
                        resolve();
                    } else {
                        setStory(undefined);
                        reject("Unable to update story");
                    }
                })
                .catch(() => {
                    setStory(undefined);
                    reject("Unable to update story");
                })
                .finally(() => setLoading(false));
        });
    }, []);

    return (
        <StoryContext.Provider
            value={{
                activeStep,
                completedSteps,
                details,
                story,
                loading,
                changeStep,
                submitStep,
                addNewStory,
                updateStory,
                clearProvider,
                setCurrentDetails,
                setCurrentStory,
                markStoryReviewed,
                markStoryPublished,
            }}
        >
            {children}
        </StoryContext.Provider>
    );
};

StoryProvider.propTypes = {
    children: node,
};

export const useStoryContext = (): StoryContextI => useContext<StoryContextI>(StoryContext);
