import React from "react";
import List from "../../../core/components/crud/List";
import { Grid } from "@material-ui/core";

export const ClassroomStudentList = (): JSX.Element => {
    return (
        <>
            <Grid item xs={12}>
                <List pagination={false} includeHeader={false} padding={0} />
            </Grid>
        </>
    );
};
