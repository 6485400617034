import { Typography, Box, Grid, Button, Paper, Divider } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useSessionMode } from "../../contexts/sessionMode.context";
import { StoryQuestions } from "./StoryQuestions";
import { updateSessionState, startSessionSurvey } from "../../../../../client/api/session";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../../../layout/themes/verbal.theme";
import clsx from "clsx";
import { MappedStoryType, SessionType } from "../../../../../models/modelTypes";
import { useAuth } from "../../../../../contexts/auth.context";
import { VModule, VModuleType } from "../../../../../core/components/VModule";
import { useHistory } from "react-router-dom";
import { useUtilityStyles } from "../../../../../hooks/styles/UtilityStyles";
import { StoryImage } from "../../../components/StoryImage";
import { useSetTourState } from "../../../../tour/Store";

interface ReadStoryProps {
    mappedStory?: MappedStoryType;
    session?: SessionType;
}

export const ReadStory: React.FC<ReadStoryProps> = (props: ReadStoryProps) => {
    const history = useHistory();
    const { mappedStory, session } = props;
    const [currentContent, setCurrentContent] = useState<string | null | undefined>();
    const [contentPart1, setContentPart1] = useState<string | null | undefined>();
    const [contentPart2, setContentPart2] = useState<string | null | undefined>();
    const storyParts = mappedStory?.Story?.StoryParts ?? session?.MappedStory?.Story?.StoryParts;
    const story = mappedStory?.Story ?? session?.MappedStory?.Story;
    const isSession = Boolean(session);
    const isAtHome = Boolean(mappedStory);
    const { isAuthenticated } = useAuth();
    const [sessionInProgress, setSessionInProgress] = useState<boolean>(false);
    const myRef = React.createRef<HTMLDivElement>();
    const setTourState = useSetTourState();
    const refNextStoryPartBtn = useRef<HTMLButtonElement | null>(null);
    const refStoryCompleteBtn = useRef<HTMLButtonElement | null>(null);

    // Top: 0 takes us all the way back to the top of the page
    // Behavior: smooth keeps it smooth!
    const scrollToTop = () => {
        myRef.current?.scrollIntoView();
    };

    const {
        presentationMode,
        changeStep,
        currentPart: currentPartIndex,
        currentQuestion: currentQuestionIndex,
        changePart,
        changeQuestion,
        annotationsMode,
        showQuestions,
        togglePresentationMode,
        completedParts,
        submitStep,
    } = useSessionMode();

    const useStyles = makeStyles(
        createStyles({
            serif: {
                fontFamily: "'PT Serif', serif",
            },
            storyTitle: {
                fontSize: presentationMode ? "32px" : "32px",
                fontWeight: 700,
                lineHeight: 1.5,
                [verbalTheme.breakpoints.up("sm")]: {
                    fontSize: currentPartIndex > 0 ? "26px" : presentationMode ? "48px" : "52px",
                },
                [verbalTheme.breakpoints.up("md")]: {
                    fontSize: currentPartIndex > 0 ? "26px" : presentationMode ? "72px" : "64px",
                },
            },
            storyBlock: {
                fontSize: presentationMode ? "20px" : "18px",
                lineHeight: 2,
                [verbalTheme.breakpoints.up("sm")]: {
                    fontSize: presentationMode ? "22px" : "20px",
                },
                marginBottom: "1em",
                [verbalTheme.breakpoints.up("md")]: {
                    fontSize: presentationMode ? "24px" : "22px",
                },
            },
            questionsRoot: {
                padding: verbalTheme.spacing(3),
            },
            storyNavButton: {
                display: "block",
                marginBottom: verbalTheme.spacing(2),
                width: "100%",
                [verbalTheme.breakpoints.up("sm")]: {
                    width: "auto",
                },
            },
            clear: {
                overflow: "auto",
            },
            bold: {
                fontWeight: 700,
                opacity: "0.5",
            },
        }),
    );

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("nextStoryPartBtn", refNextStoryPartBtn) }));
    }, [refNextStoryPartBtn]);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("storyCompleteBtn", refStoryCompleteBtn) }));
    }, [refStoryCompleteBtn]);

    const completeSurvey = async () => {
        if (session && isAuthenticated) {
            await startSessionSurvey(session.id, { isSurveyInProgress: true });
            submitStep();
        } else {
            submitStep();
            changeStep(2);
        }
    };

    const completeSession = async () => {
        if (session && isAuthenticated) {
            await updateSessionState(session.id, { state: "Complete" });
            history.push("/programmes");
            submitStep();
            changeStep(2);
        } else {
            history.push("/participant/dashboard");
        }
    };

    const completeReadAtHomeSurvey = (mappedStoryId: number) => {
        history.push(`/survey/${mappedStoryId}`);
    };

    if (!storyParts || !story) {
        return (
            <Grid item xs={12}>
                <Typography color="error">{`Unable to load story`}</Typography>
            </Grid>
        );
    }

    // sort questions based on order here so we can still use currentIndex from the SessionMode Context
    const currentStoryPartsQuestions = storyParts[currentPartIndex]?.MappedStoryQuestion?.filter(
        (q) => q.mappedStoryId === mappedStory?.id || q.mappedStoryId === session?.mappedStoryId,
    )?.sort((a, b) => a.order - b.order);

    // Configure the content if the part or annotations change
    useEffect(() => {
        let editedStoryPart;
        if (isAuthenticated) {
            const facilitatorStoryPart = storyParts[currentPartIndex]?.FacilitatorStoryPart ?? [{ html: "" }];
            editedStoryPart = facilitatorStoryPart[0] ? facilitatorStoryPart[0].html : "";
        }
        const originalStoryPart = storyParts[currentPartIndex]?.content;
        setCurrentContent(
            annotationsMode && !presentationMode && editedStoryPart ? editedStoryPart : originalStoryPart,
        );
    }, [currentPartIndex, annotationsMode, storyParts, presentationMode]);

    // Configure the content parts (i.e. text before image, text after image) if the currentContent changes
    useEffect(() => {
        const patternContentPart1 = /^.{0,200}[^\n]*\n/s;
        //const patternContentPart3 = /\n.{0,300}$/s;
        const patternRemoveSurroundingWhitespaces = /^\s+|\s+$/s;

        const _contentPart1 = currentContent
            ?.match(patternContentPart1)?.[0]
            ?.replace(patternRemoveSurroundingWhitespaces, "");
        setContentPart1(_contentPart1);
        const _contentPart2 = currentContent
            ?.replace(patternContentPart1, "")
            ?.replace(patternRemoveSurroundingWhitespaces, "");
        setContentPart2(_contentPart2);

        //const _contentPart3 = _contentPart2?.match(patternContentPart3)?.[0]?.replace(patternRemoveSurroundingWhitespaces, "");
        //_contentPart2 = _contentPart2?.replace(patternContentPart3, "")?.replace(patternRemoveSurroundingWhitespaces, "");
    }, [currentContent]);

    // scroll to top if story part changes
    useEffect(() => {
        scrollToTop();
    }, [currentPartIndex]);

    // Set the session in progress and retrieve the stories
    useEffect(() => {
        setSessionInProgress(session?.state === "InProgress" ?? false);
        if (session && isAuthenticated) {
            updateSessionState(session.id, { state: "InProgress" });

            changePart(session.currentPart, session.id, isAuthenticated);
            changeQuestion(session.currentQuestion, session.id, isAuthenticated);
        } else {
            if (!presentationMode && isAuthenticated) {
                togglePresentationMode();
            }
        }
    }, [session]);

    // Preload Images
    useEffect(() => {
        storyParts.forEach((storyPart) => {
            if (storyPart && storyPart.imageUrl) {
                const img = new Image();
                img.src = storyPart.imageUrl;
            }
        });
    }, [storyParts]);

    //const goToEnd = useCallback(() => {}, []);
    const goToEnd = () => {
        if (session && isAuthenticated) {
            if (currentPartIndex != 2) {
                changePart(2, session.id, isAuthenticated);
            }
            setTimeout(() => {
                if (currentQuestionIndex != 2) {
                    changeQuestion(2, session.id, isAuthenticated);
                }
            }, 100);
        }
    };

    useEffect(() => {
        document.removeEventListener("goToEnd", goToEnd, false);
        document.addEventListener("goToEnd", goToEnd, false);

        return () => {
            document.removeEventListener("goToEnd", goToEnd, false);
        };
    }, []);

    const currentPart = currentPartIndex + 1;
    const nextPart = currentPart + 1;
    const nextPartIndex = currentPartIndex + 1;
    const previousPartIndex = currentPartIndex - 1;

    const hasAnotherPart = Boolean(storyParts[currentPartIndex + 1]);
    const hasAnotherQuestion = Boolean(
        currentStoryPartsQuestions && currentStoryPartsQuestions[currentQuestionIndex + 1],
    );

    const classes = useStyles();
    const utilityClasses = useUtilityStyles();

    const dropCapClass = currentPart === 1 && (!annotationsMode || presentationMode) ? "c-story-text" : undefined;

    return (
        <div ref={myRef}>
            <Box mb={4}>
                <Typography variant="body2" className={classes.bold}>
                    Part {currentPart} of {storyParts.length}
                </Typography>
                <Typography className={clsx(classes.serif, classes.storyTitle)} component="h1">
                    {story.storyName}
                </Typography>
                <Typography variant="body2" className={classes.bold}>
                    By {story.author}
                </Typography>
            </Box>

            <Box mb={4}>
                {story?.overview && currentPartIndex === 0 ? (
                    <div>
                        <Typography
                            style={{ fontWeight: "bold", textDecoration: "underline", marginBottom: 0 }}
                            className={clsx(classes.serif, classes.storyBlock)}
                        >
                            Overview of story until this extract - to read to participants
                        </Typography>

                        <Typography
                            style={{ whiteSpace: "pre-line", marginBottom: 0 }}
                            className={clsx(classes.serif, classes.storyBlock)}
                        >
                            {story?.overview}
                        </Typography>
                        <Divider style={{ marginBottom: "1rem" }} />
                    </div>
                ) : null}

                <div key={storyParts[currentPartIndex]?.id + "a"} className={dropCapClass}>
                    <Typography
                        style={{ whiteSpace: "pre-line", marginBottom: "40px" }}
                        className={clsx(classes.serif, classes.storyBlock)}
                        dangerouslySetInnerHTML={{ __html: contentPart1 ?? "" }}
                    />
                </div>

                {storyParts[currentPartIndex]?.imageUrl ? (
                    <StoryImage url={storyParts[currentPartIndex]?.imageUrl as string}></StoryImage>
                ) : null}

                <div key={storyParts[currentPartIndex]?.id + "b"}>
                    <Typography
                        style={{ whiteSpace: "pre-line", marginBottom: "40px" }}
                        className={clsx(classes.serif, classes.storyBlock)}
                        dangerouslySetInnerHTML={{ __html: contentPart2 ?? "" }}
                    />
                </div>
            </Box>

            {currentStoryPartsQuestions &&
                (isAuthenticated || !isSession || (isSession && !sessionInProgress)) &&
                !presentationMode && (
                    <Box mb={4} className="storyPartQuestions">
                        <Paper className={classes.questionsRoot}>
                            <StoryQuestions
                                inProgress={session?.state === "InProgress" ?? false}
                                isAtHome={isAtHome}
                                currentStoryPartsQuestions={currentStoryPartsQuestions}
                            />
                        </Paper>
                    </Box>
                )}
            {sessionInProgress && !isAuthenticated && (
                <VModule type={VModuleType.Shade1} className="u-marg-bottom">
                    <Box textAlign={"center"}>
                        <Typography variant="h6">
                            {currentPartIndex + 2 <= storyParts.length
                                ? `Please wait for your facilitator to bring you to part ${
                                      currentPartIndex + 2
                                  } of the story.`
                                : `Please wait for your facilitator to complete the session.`}
                        </Typography>
                    </Box>
                </VModule>
            )}
            {(!sessionInProgress || isAuthenticated || !isSession) && (
                <Box className={classes.clear}>
                    {currentPartIndex > 0 && (
                        <Button
                            className={clsx(utilityClasses.floatLeft, classes.storyNavButton)}
                            variant="contained"
                            size="large"
                            onClick={() => changePart(previousPartIndex, session?.id, isAuthenticated)}
                        >
                            Previous Story Part
                        </Button>
                    )}
                    {hasAnotherPart && (
                        <Button
                            ref={refNextStoryPartBtn}
                            className={clsx(utilityClasses.floatRight, classes.storyNavButton, "nextStoryPartBtn")}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => changePart(nextPartIndex, session?.id, isAuthenticated)}
                            disabled={
                                completedParts.findIndex((p) => p === currentPartIndex + 1) !== -1
                                    ? false
                                    : presentationMode
                                    ? false
                                    : hasAnotherQuestion || !showQuestions
                            }
                        >
                            Continue to Story Part {nextPart}
                        </Button>
                    )}
                    {!hasAnotherPart && (
                        <Button
                            ref={refStoryCompleteBtn}
                            className={clsx(utilityClasses.floatRight, classes.storyNavButton, "storyCompleteBtn")}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => {
                                if (session && isAuthenticated) {
                                    completeSurvey();
                                } else if (!session && mappedStory) {
                                    completeReadAtHomeSurvey(mappedStory.id);
                                } else {
                                    completeSession();
                                }
                            }}
                            disabled={presentationMode ? false : hasAnotherQuestion || !showQuestions}
                        >
                            {session && isAuthenticated
                                ? "Story Complete"
                                : !session && mappedStory
                                ? "Take a Survey"
                                : "Complete Story"}
                        </Button>
                    )}
                </Box>
            )}
        </div>
    );
};
