import { createStyles, makeStyles } from "@material-ui/core";
import { verbalTheme } from "./themes/verbal.theme";

export const drawerWidth = 220;

const styles = makeStyles((theme) =>
    createStyles({
        root: {
            display: "flex",
            height: "100%",
        },
        drawer: {
            [verbalTheme.breakpoints.up("sm")]: {
                flexShrink: 0,
                zIndex: 1,
            },
        },
        app: {
            flex: 1,
            display: "flex",
            flexDirection: "row",
        },
        main: {
            flex: 1,
            background: verbalTheme.palette.background.default,
        },
        demoBar: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: verbalTheme.palette.primary.main,
        },
        toolbar: theme.mixins.toolbar,
    }),
);

export const useBaseLayoutStyle = styles;
