import { Box, Grid, ImageList, ImageListItem, ImageListItemBar, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../core/components/Breadcrumb";
import { useCollection } from "../../hooks/useCollection";
import { useMobile } from "../../hooks/useMobile";
import { useThemes } from "../../hooks/useTheme";
import Spinner from "../layout/Spinner";
import { getColourClass } from "./BrowseHome";
import { useHomeStyles } from "./Home.style";
import { HomeViewItem } from "./HomeViewItem";

/**
 * Not Currently Used
 */
export const ViewCollectionTheme = (): JSX.Element => {
    const { id, themeId } = useParams<{ id: string; themeId: string }>();
    const { isMobile } = useMobile();
    const { theme, getTheme } = useThemes();
    const { collection, getCollection } = useCollection();
    const history = useHistory();

    useEffect(() => {
        if (parseInt(themeId)) {
            getTheme(parseInt(themeId));
        }
    }, [themeId]);

    useEffect(() => {
        if (parseInt(id)) {
            getCollection(parseInt(id));
        }
    }, [id]);

    const classes = useHomeStyles();

    return (
        <Box display={"flex"}>
            {theme && collection ? (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {isMobile && <div className={classes.toolbar} />}
                        <Box pl={4} pt={isMobile ? 4 : 2}>
                            <Breadcrumb
                                crumbs={[
                                    { name: "Explore", url: "/explore" },
                                    {
                                        name: collection.name,
                                        url: `/explore/collection/${id}`,
                                    },
                                ]}
                                current={theme.name}
                            />
                        </Box>
                        <HomeViewItem title={theme.name} description={theme.description} colour={theme.colour} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box bgcolor="secondaryShade.main" width={1} px={2}>
                            <Typography>Story Collections</Typography>
                            <ImageList gap={10} cols={isMobile ? 2 : 4}>
                                {theme.StoryCollection &&
                                    theme.StoryCollection.length > 0 &&
                                    theme.StoryCollection.map((item) => (
                                        <ImageListItem key={item.id}>
                                            <Box
                                                minHeight="100%"
                                                minWidth="100%"
                                                bgcolor={`${getColourClass({ colour: item.colour })}`}
                                                onClick={() => history.push(`/explore/collection/${item.id}`)}
                                                style={{ cursor: "pointer" }}
                                            />
                                            <ImageListItemBar
                                                className={classes.titleBarBottom}
                                                title={
                                                    <Box>
                                                        <Typography style={{ color: "white", whiteSpace: "pre-line" }}>
                                                            {item.name}
                                                        </Typography>
                                                    </Box>
                                                }
                                                position="bottom"
                                            />
                                        </ImageListItem>
                                    ))}
                            </ImageList>
                        </Box>
                    </Grid>
                </Grid>
            ) : (
                <Spinner />
            )}
        </Box>
    );
};
