import { MappedStoryPartType, StoryType } from "../../../models/modelTypes";

export const storiesCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    storyName: {
        label: "Name",
    },
    author: {
        label: "Author",
    },
    parts: {
        label: "Parts",
        inForm: false,
        decorator: (value: string, property?: string, story?: StoryType): string => {
            const parts: MappedStoryPartType[] | undefined = story?.StoryParts;
            return parts?.length && parts?.length > 0 ? String(parts?.length) : "0";
        },
    },
    isReviewed: {
        label: "Reviewed",
        inForm: false,
        decorator: (value: unknown, property?: string, story?: StoryType): string => (story?.isReviewed ? "Yes" : "No"),
    },
    isPublished: {
        label: "Published",
        inForm: false,
        decorator: (value: unknown, property?: string, story?: StoryType): string =>
            story?.isPublished ? "Yes" : "No",
    },
};
