import React, { useState } from "react";
import { MappedStoryType, StoryCollectionType } from "../../models/modelTypes";
import { useList } from "../../core/components/crud/contexts/list.context";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import { CollectionStories } from "./CollectionStories";
import StoryOverview from "./StoryOverview";
import { CollectionCard } from "../../core/components/CollectionCard";
import { NavigateBefore } from "@material-ui/icons";

export enum ParticipantCollectionsView {
    Collections = "Collections",
    Stories = "Stories",
    StorySynopsis = "StorySynopsis",
}

export const ParticipantCollections: React.FC = (): JSX.Element => {
    const { list } = useList<StoryCollectionType>();
    const [view, setView] = useState<ParticipantCollectionsView>(ParticipantCollectionsView.Collections);
    const [collectionStories, setCollectionStories] = useState<MappedStoryType[] | undefined>();
    const [collectionName, setCollectionName] = useState<string>();
    const [story, setStory] = useState<MappedStoryType>();
    const participantCollections: React.ReactElement[] = [];

    list.map((collection) => {
        participantCollections.push(
            <Grid item xs={12} md={6} key={collection.id}>
                {collection.Stories && (
                    <CollectionCard
                        borderRadius={16}
                        padded={true}
                        onClick={() => {
                            setCollectionStories(collection.Stories);
                            setCollectionName(collection.name);
                            setView(ParticipantCollectionsView.Stories);
                        }}
                    >
                        <Typography variant={"h6"} color="textPrimary">
                            {collection.name}
                        </Typography>
                        <Typography color="textSecondary" variant="body2">
                            {collection.Stories?.length} Stories
                        </Typography>
                    </CollectionCard>
                )}
            </Grid>,
        );
    });

    switch (view) {
        case ParticipantCollectionsView.Collections:
            return (
                <>
                    <Box mb={4}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            Collections
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        {participantCollections}
                    </Grid>
                </>
            );
        case ParticipantCollectionsView.Stories:
            return (
                <>
                    <Box mb={2}>
                        <Button
                            startIcon={<NavigateBefore />}
                            variant="text"
                            onClick={() => setView(ParticipantCollectionsView.Collections)}
                        >
                            Collections
                        </Button>
                    </Box>

                    <CollectionStories
                        collectionName={collectionName}
                        stories={collectionStories}
                        setView={setView}
                        setStory={setStory}
                    />
                </>
            );

        case ParticipantCollectionsView.StorySynopsis:
            return (
                <>
                    <Box mb={2}>
                        <Button
                            startIcon={<NavigateBefore />}
                            variant="text"
                            onClick={() => setView(ParticipantCollectionsView.Stories)}
                        >
                            {collectionName}
                        </Button>
                    </Box>

                    <StoryOverview story={story} />
                </>
            );

        default:
            return (
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h1">
                            Collections
                        </Typography>
                    </Grid>
                    {participantCollections}
                </Grid>
            );
    }
};

export default ParticipantCollections;
