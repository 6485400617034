import React, { ReactNode, useCallback } from "react";

export enum ToolbarMode {
    Light = "Light",
    Dark = "Dark",
}

export interface BaseLayoutContextI {
    toolbar: ReactNode | undefined;
    mode: ToolbarMode;

    addMenuItem: (id: string, element?: React.ReactElement) => void;
    removeMenuItem: (id: string) => void;
    clearMenuItems: () => void;
    setToolbarMode: (mode: ToolbarMode) => void;
}

export const BaseLayoutContext = React.createContext({} as BaseLayoutContextI);

export const BaseLayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [toolbar, setToolbar] = React.useState<ReactNode | undefined>();
    const [menuItems, setMenuItems] = React.useState<Record<string, ReactNode>>({});
    const [mode, setMode] = React.useState<ToolbarMode>(ToolbarMode.Dark);

    const configureToolbar = (menuItems: Record<string, ReactNode>) => {
        const menuItemsArray = Object.keys(menuItems)
            .reverse()
            .map(function (itemIndex) {
                return menuItems[itemIndex];
            });
        const toolbar = React.createElement("div", { key: "toolbar" }, menuItemsArray);
        setToolbar(toolbar);
    };

    const addMenuItem = useCallback(
        (id: string, node: ReactNode) => {
            menuItems[id] = node;
            setMenuItems(menuItems);
            configureToolbar(menuItems);
        },
        [menuItems],
    );

    const removeMenuItem = useCallback(
        (id: string) => {
            delete menuItems[id];
            setMenuItems(menuItems);
            configureToolbar(menuItems);
        },
        [menuItems],
    );

    const clearMenuItems = () => {
        for (const menuItem in menuItems) {
            delete menuItems[menuItem];
        }
        setMenuItems(menuItems);
    };

    const setToolbarMode = (mode: ToolbarMode) => {
        setMode(mode);
    };

    return (
        <BaseLayoutContext.Provider
            value={{ toolbar, mode, addMenuItem, clearMenuItems, removeMenuItem, setToolbarMode }}
        >
            {children as React.ReactNode}
        </BaseLayoutContext.Provider>
    );
};

export const useBaseLayout = (): BaseLayoutContextI => React.useContext<BaseLayoutContextI>(BaseLayoutContext);
