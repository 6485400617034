import { useCallback, useState } from "react";
import {
    addFacilitator,
    getFacilitatorInfo,
    getFacilitators,
    updateFacilitator,
    deleteFacilitator,
} from "../client/api/facilitator";
import { FacilitatorType, ProgrammeType } from "../models/modelTypes";
import { connectFacilitatorUserToProgramme, removeFacilitatorUserFromProgramme } from "../client/api/programme";
import { User } from "common/build/prisma/client";

interface FacilitatorI {
    facilitators: FacilitatorType[];
    facilitatorInfo?: FacilitatorType;
    facilitatorProgramme?: ProgrammeType;
    loading: boolean;

    setFacilitatorProgramme: (programme: ProgrammeType) => void;
    getAllFacilitators: () => void;
    getFacilitatorDetails: (id: number) => void;
    addNewFacilitator: (facilitatorDetails: Partial<FacilitatorType>) => void;
    updateExistingFacilitator: (updatedFacilitatorDetails: FacilitatorType) => void;
    deleteExistingFacilitator: (facilitatorToDeleteByID: number) => void;
    addFacilitatorToProgramme: (
        programmeId: number,
        facilitatorUserDetails: Partial<User>,
        create: boolean,
    ) => Promise<void>;
    removeFacilitatorFromProgramme: (programmeId: number, facilitatorId: number) => Promise<void>;
}

/**
 * Custom hook to get, list and add facilitators
 * Can list facilitators
 * Can get a single facilitator (deprecated)
 * Can add facilitators (deprecated)
 * Can update facilitator (deprecated)
 * Can delete facilitator
 * Can add facilitators to programmes
 * Can remove facilitator from programme
 */
export const useFacilitator = (): FacilitatorI => {
    const [facilitators, setFacilitators] = useState<FacilitatorType[]>([]);
    const [facilitatorInfo, setFacilitatorInfo] = useState<FacilitatorType>();
    const [loading, setLoading] = useState<boolean>(false);
    const [facilitatorProgramme, setFacilitatorProgramme] = useState<ProgrammeType>();

    const getAllFacilitators = useCallback(() => {
        setLoading(true);
        getFacilitators({ take: "1000" })
            .then(async (facilitatorsFromApi: FacilitatorType[]) => {
                setFacilitators(facilitatorsFromApi);
            })
            .catch(() => {
                setFacilitators([]);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const getFacilitatorDetails = useCallback((id) => {
        setLoading(true);
        getFacilitatorInfo(id)
            .then(async (facilitatorInfoFromApi: FacilitatorType) => {
                setFacilitatorInfo(facilitatorInfoFromApi);
            })
            .catch(() => {
                setFacilitatorInfo(undefined);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const addNewFacilitator = useCallback((facilitatorDetails: Partial<FacilitatorType>) => {
        setLoading(true);
        addFacilitator(facilitatorDetails)
            .then(async (response) => {
                const facilitatorInfoFromApi: FacilitatorType = await response.json();
                setFacilitatorInfo(facilitatorInfoFromApi);
            })
            .catch(() => {
                setFacilitatorInfo(undefined);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const updateExistingFacilitator = useCallback((updatedFacilitator: FacilitatorType) => {
        return new Promise<void>((resolve) => {
            setLoading(true);

            updateFacilitator(updatedFacilitator)
                .then(async (response) => {
                    if (response.ok) {
                        const facilitator: FacilitatorType = await response.json();
                        setFacilitatorInfo(facilitator);
                        setFacilitators((prevFacilitators: FacilitatorType[]) =>
                            prevFacilitators.map((f) =>
                                f.id === updatedFacilitator.id ? { ...updatedFacilitator } : f,
                            ),
                        );
                        resolve();
                    } else {
                        setFacilitatorInfo(undefined);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setFacilitatorInfo(undefined);
                    setLoading(false);
                });
        });
    }, []);

    const deleteExistingFacilitator = useCallback((facilitatorToDeleteByID: number) => {
        setLoading(true);

        deleteFacilitator(facilitatorToDeleteByID)
            .then(async (response) => {
                if (response.ok) {
                    setFacilitators((prevFacilitators: FacilitatorType[]) =>
                        prevFacilitators.filter((f) => f.id !== facilitatorToDeleteByID),
                    );
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const addFacilitatorToProgramme = useCallback(
        (programmeId: number, facilitatorUserDetails: Partial<User>, create: boolean) => {
            return new Promise<void>((resolve, reject) => {
                setLoading(true);

                connectFacilitatorUserToProgramme(programmeId, facilitatorUserDetails, create)
                    .then(async (response) => {
                        if (response.ok) {
                            const programmeResponse: ProgrammeType = await response.json();
                            setFacilitatorProgramme(programmeResponse);
                            resolve();
                        } else {
                            const responseBody = await response.json();
                            reject((responseBody && responseBody.message) ?? "Error adding facilitator");
                        }
                    })
                    .catch(() => {
                        reject("Error adding facilitator");
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            });
        },
        [],
    );

    const removeFacilitatorFromProgramme = useCallback((programmeId: number, facilitatorId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            removeFacilitatorUserFromProgramme(programmeId, facilitatorId)
                .then(async (response) => {
                    if (response.ok) {
                        const programmeResponse: ProgrammeType = await response.json();
                        setFacilitatorProgramme(programmeResponse);
                        resolve();
                    } else {
                        setFacilitatorProgramme(undefined);
                        reject("Unable to remove facilitator");
                    }
                })
                .catch(() => {
                    setFacilitatorProgramme(undefined);
                    reject("Unable to remove facilitator");
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    return {
        facilitators,
        facilitatorInfo,
        loading,
        getAllFacilitators,
        getFacilitatorDetails,
        addNewFacilitator,
        updateExistingFacilitator,
        deleteExistingFacilitator,
        addFacilitatorToProgramme,
        removeFacilitatorFromProgramme,
        facilitatorProgramme,
        setFacilitatorProgramme,
    };
};
