import React, { FC } from "react";
import { VGridContainer } from "./Grid/VGridContainer";
import {
    Button,
    Tabs,
    Tab,
    Typography,
    Box,
    useTheme,
    Container,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Switch,
} from "@material-ui/core";
import { VGridItem } from "./Grid/VGridItem";
import { Header } from "./Header/Header";
import { VAccordion, VAccordionItem } from "../../core/components/VAccordion";
import { VModule, VModuleType } from "../../core/components/VModule";
import { VPill, VPillNegative, VPillPositive } from "../../core/components/VPill";
import { VSwitch } from "../../core/components/VSwitch";
import { useState } from "react";
import { SurveyRating } from "../../core/components/SurveyRating";
import { CustomIconContainer } from "../../core/components/survey/Icons";
import { DestructiveButton, PrimaryButton, SecondaryButton } from "./Button/Button";
import {
    TypeHeading1,
    TypeHeading2,
    TypeHeading3,
    TypeHeading4,
    TypeHeading5,
    TypeHeading6,
} from "./Typography/Typography";
import Alert from "@material-ui/lab/Alert";

export const ComponentLibrary: FC = () => {
    const [switchValue, setSwitchValue] = useState<string>("A");

    const theme = useTheme();

    return (
        <>
            <Box p={4}>
                <TypeHeading1>Heading 5</TypeHeading1>
                <TypeHeading2>Heading 2</TypeHeading2>
                <TypeHeading3>Heading 3</TypeHeading3>
                <TypeHeading4>Heading 4</TypeHeading4>
                <TypeHeading5>Heading 5</TypeHeading5>
                <TypeHeading6>Heading 6</TypeHeading6>

                <VGridContainer spacing={2}>
                    <VGridItem>
                        <Button variant="contained" size={"large"}>
                            Large Default
                        </Button>
                        <PrimaryButton>Primary</PrimaryButton>
                        <SecondaryButton>Secondary</SecondaryButton>
                        <DestructiveButton>Destructive</DestructiveButton>
                    </VGridItem>
                    <VGridItem>
                        <Button variant="contained" color="primary" size={"medium"}>
                            Medium Primary
                        </Button>
                    </VGridItem>
                    <VGridItem>
                        <Button variant="contained" color="secondary" size={"small"}>
                            Small secondary
                        </Button>
                    </VGridItem>
                    <VGridItem>
                        <Button variant="contained" disabled>
                            Disabled
                        </Button>
                    </VGridItem>
                    <VGridItem>
                        <Button variant="contained" color="primary" href="#contained-buttons">
                            Link
                        </Button>
                    </VGridItem>
                </VGridContainer>

                <Typography variant={"h1"}>Heading 1</Typography>
                <Typography variant={"h2"}>Heading 2</Typography>
                <Typography variant={"h3"}>Heading 3</Typography>
            </Box>

            <Box p={4}>
                <Tabs indicatorColor="secondary" textColor="primary">
                    <Tab label="Sessions" id="session-tab" />
                    <Tab label="Participants" id="participant-tab" />
                    <Tab label="Report" id="report-tab" />
                </Tabs>
            </Box>

            <Box width={500}>
                <Card>
                    <Tabs indicatorColor="secondary" textColor="primary">
                        <Tab label="Sessions" id="session-tab" />
                        <Tab label="Participants" id="participant-tab" />
                        <Tab label="Report" id="report-tab" />
                    </Tabs>

                    <CardContent>fdsfsdf</CardContent>
                </Card>
            </Box>

            <Box p={4}>
                <Header title={"Programmes"} columnTitles={[]} />
            </Box>

            <Box p={4}>
                <VAccordion>
                    <VAccordionItem title={"Item 1"} key={"item-1"}>
                        <Typography variant={"h3"}>Here is Item 1 content</Typography>
                    </VAccordionItem>
                    <VAccordionItem title={"Item 2"} key={"item-2"}>
                        <Typography variant={"h3"}>Here is Item 2 content</Typography>
                    </VAccordionItem>
                </VAccordion>
            </Box>

            <Box p={4}>
                <VGridContainer spacing={4}>
                    <VGridItem>
                        <VModule shadow>
                            <Typography variant={"h3"}>Default</Typography>
                        </VModule>
                    </VGridItem>
                    <VGridItem>
                        <VModule type={VModuleType.Shade1}>
                            <Typography variant={"h3"}>Shade 1</Typography>
                        </VModule>
                    </VGridItem>
                    <VGridItem>
                        <VModule type={VModuleType.Shade2}>
                            <Box color={theme.palette.common.white}>
                                <Typography variant={"h3"}>Shade 2</Typography>
                            </Box>
                        </VModule>
                    </VGridItem>
                    <VGridItem>
                        <VModule type={VModuleType.Shade3}>
                            <Box color={theme.palette.primary.main} mb={2}>
                                <Typography variant={"h2"}>Shade 3</Typography>
                            </Box>
                            <Box color={theme.palette.common.white}>
                                <Typography variant={"h3"}>sub text</Typography>
                            </Box>
                        </VModule>
                    </VGridItem>
                </VGridContainer>
            </Box>

            <Box p={4}>
                <VGridContainer spacing={4}>
                    <VGridItem>
                        <VPill label={"Default"} />
                    </VGridItem>
                    <VGridItem>
                        <VPill label={"Primary"} color="primary" />
                    </VGridItem>
                    <VGridItem>
                        <VPill label={"Secondary"} color="secondary" />
                    </VGridItem>
                    <VGridItem>
                        <VPillNegative label={"Negative"} />
                    </VGridItem>
                    <VGridItem>
                        <VPillPositive label={"Positive"} />
                    </VGridItem>
                </VGridContainer>
            </Box>

            <Box p={4}>
                <Alert severity="error">This is an error alert — check it out!</Alert>
                <Alert severity="warning">This is a warning alert — check it out!</Alert>
                <Alert severity="info">This is an info alert — check it out!</Alert>
                <Alert severity="success">This is a success alert — check it out!</Alert>

                <FormControlLabel control={<Checkbox color="primary" name="checkedA" />} label="Secondary" />

                <Switch name="checkedA" color="primary" inputProps={{ "aria-label": "secondary checkbox" }} />
            </Box>

            <Box p={4}>
                <VSwitch
                    id="isOnline"
                    size="large"
                    value={switchValue}
                    exclusive
                    onChange={(_: React.MouseEvent<HTMLElement>, value: string) => setSwitchValue(value)}
                    options={[
                        { name: "Option A", value: "A" },
                        { name: "Option B", value: "B" },
                    ]}
                />
            </Box>

            <Box p={4}>
                <Container maxWidth={"sm"}>
                    <SurveyRating
                        name="story-quality"
                        defaultValue={1}
                        max={5}
                        IconContainerComponent={(props) => <CustomIconContainer name={"smiley"} {...props} />}
                        minLabel={"Not at all engaging"}
                        maxLabel={"Extremely engaging"}
                    />
                </Container>
            </Box>
        </>
    );
};
