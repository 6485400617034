import { Button, createStyles, makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { HeadlineFigureWidget } from "./HeadlineFigureWidget";

export const ExploreWidget: FC<{ minHeight?: string }> = ({ minHeight }): JSX.Element => {
    const history = useHistory();
    const useStyles = makeStyles(
        createStyles({
            imgPlacment: {
                height: "75%",
                width: "75%",
                margin: "auto",
            },
        }),
    );
    const classes = useStyles();
    return (
        <>
            <HeadlineFigureWidget minHeight={minHeight}>
                <div className={classes.imgPlacment}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="75%"
                        width="75%"
                        viewBox="0 0 48 48"
                        fill={verbalTheme.palette.primary.main}
                    >
                        <path d="M22.9 43.95q-3.95-.2-7.4-1.85-3.45-1.65-6-4.325Q6.95 35.1 5.475 31.55 4 28 4 24q0-4.15 1.575-7.8Q7.15 12.55 9.85 9.85q2.7-2.7 6.35-4.275Q19.85 4 24 4q7.45 0 12.95 4.7 5.5 4.7 6.75 11.8h-3.05q-.85-4.2-3.55-7.5-2.7-3.3-6.75-4.95v.9q0 1.75-1.2 3.05-1.2 1.3-2.95 1.3h-4.35v4.35q0 .85-.675 1.4-.675.55-1.525.55H15.5V24H21v6.25h-3.35l-10.2-10.2q-.25 1-.35 1.975Q7 23 7 24q0 6.75 4.55 11.65t11.35 5.3Zm19.6-1.3-6.7-6.7q-1.05.75-2.275 1.15-1.225.4-2.525.4-3.55 0-6.025-2.475Q22.5 32.55 22.5 29q0-3.55 2.475-6.025Q27.45 20.5 31 20.5q3.55 0 6.025 2.475Q39.5 25.45 39.5 29q0 1.3-.425 2.525-.425 1.225-1.125 2.325l6.7 6.65ZM31 34.5q2.3 0 3.9-1.6t1.6-3.9q0-2.3-1.6-3.9T31 23.5q-2.3 0-3.9 1.6T25.5 29q0 2.3 1.6 3.9t3.9 1.6Z" />
                    </svg>
                    {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="75%"
                        viewBox="0 0 24 24"
                        width="75%"
                        fill={verbalTheme.palette.primary.main}
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
                    </svg> */}
                </div>
                <Button size="small" color="primary" variant="contained" onClick={() => history.push("/explore")}>
                    Explore
                </Button>
            </HeadlineFigureWidget>
        </>
    );
};
