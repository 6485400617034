import React, { FC } from "react";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import clsx from "clsx";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";

export const HeadlineFigureWidget: FC<{
    header?: string;
    spinWhileFetching?: boolean;
    centeredHeader?: boolean;
    minHeight?: string;
}> = ({ header, children, spinWhileFetching = false, centeredHeader = true, minHeight = "235px" }) => {
    const useStyles = makeStyles(
        createStyles({
            widget: {
                height: "100%",
                minHeight: minHeight,
            },
            widgetInner: {
                display: "flex",
                height: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
            },
            centered: {
                textAlign: "center",
            },
            left: {
                textAlign: "left",
            },
        }),
    );

    const classes = useStyles();
    const Loader = spinWhileFetching ? SpinWhileFetching : React.Fragment;
    return (
        <Card className={classes.widget} variant={"outlined"}>
            <CardContent className={clsx(classes.widgetInner, centeredHeader ? classes.centered : classes.left)}>
                {header ? (
                    <Box>
                        <Typography variant="body2" gutterBottom>
                            {header}
                        </Typography>
                    </Box>
                ) : null}
                <Loader>{children}</Loader>
            </CardContent>
        </Card>
    );
};
