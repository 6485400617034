import { Box, Typography } from "@material-ui/core";
import React from "react";
import { MappedStoryType, RelatedStoriesType } from "../../../models/modelTypes";
import { VList } from "../../../core/components/VList";
import { VListItem, VListItemType } from "../../../core/components/VListItem";
import { VModule, VModuleType } from "../../../core/components/VModule";
import { ThemeProvider } from "@material-ui/core/styles";
import { verbalTheme } from "../../layout/themes/verbal.theme";

interface RelatedStoriesCardProps {
    stories: RelatedStoriesType[];
    onClickStory?: (story?: MappedStoryType) => void;
}

const theme = {
    ...verbalTheme,
    typography: { ...verbalTheme.typography, body1: { ...verbalTheme.typography.body1, fontSize: "14px" } },
};

export const RelatedStoriesCard: React.FC<RelatedStoriesCardProps> = (props: RelatedStoriesCardProps) => {
    return (
        <VModule key={"relatedStories"} square type={VModuleType.Shade1} padded={false}>
            <Box p={3} pb={0}>
                <Typography variant={"h5"} gutterBottom>
                    Related Stories
                </Typography>
            </Box>
            <ThemeProvider theme={theme}>
                <VList>
                    {props.stories.map((story, key) => {
                        return (
                            <VListItem
                                key={key + 1}
                                type={VListItemType.Primary}
                                avatar={key + 1}
                                divider
                                primaryText={story.RelatedMappedStory?.Story?.storyName}
                                secondaryText={`By ${story.RelatedMappedStory?.Story?.author}`}
                                onClick={() => props.onClickStory?.(story.RelatedMappedStory)}
                            />
                        );
                    })}
                </VList>
            </ThemeProvider>
        </VModule>
    );
};
