import React from "react";
import { Crud } from "../../../core/components/crud/Crud";
import { get, list } from "../../../client/api/programme";
import { programmesCRUDModel } from "./model";
import { ViewProgramme } from "./ViewProgramme";
import { ProgrammeType } from "../../../models/modelTypes";
import { useAuth } from "../../../contexts/auth.context";
import { Redirect } from "react-router-dom";
import { ProgrammeListParameters } from "../../../../../common/src/api-parameters/programmes";
import { ProgrammeProvider } from "../contexts/programme.context";
import { CreateProgrammeWizard } from "../wizard/ProgrammeWizard";

const params: ProgrammeListParameters = {
    includeStoryCollection: true,
    includeFacilitators: true,
};

export const ProgrammesScreen = (): JSX.Element => {
    const { user, hasPermission } = useAuth();

    if (!user) {
        return <Redirect to="/403" />;
    }

    return (
        <ProgrammeProvider>
            <Crud<ProgrammeType, ProgrammeListParameters>
                model={programmesCRUDModel}
                noun="Programme"
                getData={list}
                getItem={get}
                viewOnClick
                labelProperty={"name"}
                listParams={params}
                itemParams={params}
                searchable
                viewComponent={ViewProgramme}
                addComponent={CreateProgrammeWizard}
                programmeFilter={true}
                canAdd={hasPermission("ProgrammeCreate")}
            />
        </ProgrammeProvider>
    );
};
