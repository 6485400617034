import { Box, Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useAuth } from "../../../../contexts/auth.context";
import { SimpleBlankSlate } from "../../../../core/components/SimpleBlankSlate";
import { HeadlineFigureWidget } from "../../../dashboard/Widgets/Headlines/HeadlineFigureWidget";
import { MoodType } from "../../../../models/modelTypes";

export const ClassWellbeingBreakdownWidget: FC<{ moods: MoodType[]; empty: boolean; title: string }> = (props: {
    moods: MoodType[];
    empty: boolean;
    title: string;
}): JSX.Element => {
    const { hasPermission } = useAuth();
    const { moods, empty, title } = props;

    const totalMoods = moods?.length ?? 0;

    const happyMoods = moods?.filter((m) => m?.mood === "Happy").length ?? 0;
    const sadMoods = moods?.filter((m) => m?.mood === "Sad").length ?? 0;
    const angryMoods = moods?.filter((m) => m?.mood === "Angry").length ?? 0;
    const worriedMoods = moods?.filter((m) => m?.mood === "Worried").length ?? 0;
    const calmMoods = moods?.filter((m) => m?.mood === "Calm").length ?? 0;

    const positiveMoodPercent = Math.round(((happyMoods + calmMoods) / totalMoods) * 100);
    const negativeMoodPercent = 100 - positiveMoodPercent;

    let startingPos = 0;

    const moodinfo: { name: string; value: number; start: number; color: string }[] = [
        { name: "Happy", value: happyMoods ?? 0, start: 0, color: "#e9e900" },
        { name: "Sad", value: sadMoods ?? 0, start: 0, color: "#4472c4" },
        { name: "Angry", value: angryMoods ?? 0, start: 0, color: "#d90000" },
        { name: "Worried", value: worriedMoods ?? 0, start: 0, color: "#808080" },
        { name: "Calm", value: calmMoods ?? 0, start: 0, color: "#68df6f" },
    ]
        .filter((mood) => mood.value > 0)
        .map((mood) => {
            mood.start = startingPos;
            startingPos += mood.value;
            return mood;
        });

    const getSVGProgressPath = (
        cx: number,
        cy: number,
        thickness: number,
        radius: number,
        startValue: number,
        endValue: number,
        color: string,
        title: string | null,
    ) => {
        const innerradius = radius - thickness;

        let path = "";
        if (startValue == 0 && endValue == totalMoods) {
            //Draws full circle donut
            path = `
            M ${cx} ${cy - radius}
            A ${radius} ${radius} 0 1 0 ${cx} ${cy + radius}
            A ${radius} ${radius} 0 1 0 ${cx} ${cy - radius}
            Z
            M ${cx} ${cy - innerradius}
            A ${innerradius} ${innerradius} 0 1 1 ${cx} ${cy + innerradius}
            A ${innerradius} ${innerradius} 0 1 1 ${cx} ${cy - innerradius}
            Z`;
        } else {
            //Draws Pie Segment
            const radiansPerUnit = (2 * Math.PI) / totalMoods;
            const startAngleRadians = startValue * radiansPerUnit;
            const endAngleRadians = endValue * radiansPerUnit;

            const outerFromCX = cx + radius * Math.cos(startAngleRadians);
            const outerFromCY = cy + radius * Math.sin(startAngleRadians);
            const outerToCX = cx + radius * Math.cos(endAngleRadians);
            const outerToCY = cy + radius * Math.sin(endAngleRadians);

            const innerFromCX = cx + innerradius * Math.cos(endAngleRadians);
            const innerFromCY = cy + innerradius * Math.sin(endAngleRadians);
            const innerToCX = cx + innerradius * Math.cos(startAngleRadians);
            const innerToCY = cy + innerradius * Math.sin(startAngleRadians);

            const largeArc = endAngleRadians - startAngleRadians > radiansPerUnit * (totalMoods / 2) ? 1 : 0;

            path = `
                M${innerToCX} ${innerToCY}
                L${outerFromCX},${outerFromCY}
                A${radius},${radius} 0 ${largeArc},1 ${outerToCX} ${outerToCY}
                L${innerFromCX},${innerFromCY}
                A${innerradius},${innerradius} 0 ${largeArc},0 ${innerToCX} ${innerToCY}
                Z`;
        }

        return (
            <path d={path} fill={color} transform={`rotate(-90 ${cx} ${cy})`}>
                {title === null ? <></> : <title>{title}</title>}
            </path>
        );
    };

    return (
        <HeadlineFigureWidget centeredHeader={true} header={hasPermission("ClassroomReportView") ? title : ""}>
            {hasPermission("ClassroomReportView") ? (
                empty === false ? (
                    <>
                        <Box m="auto">
                            <svg height="120" width="150" viewBox="0 0 20 20">
                                {moodinfo.map((mood) =>
                                    getSVGProgressPath(
                                        10,
                                        10,
                                        8,
                                        8,
                                        mood.start,
                                        mood.start + mood.value,
                                        mood.color,
                                        `${mood.name}: ${mood.value}`,
                                    ),
                                )}
                            </svg>
                        </Box>

                        <Grid
                            container
                            spacing={0}
                            style={{ width: "100%", marginTop: 2 }}
                            justifyContent="space-between"
                            alignItems="baseline"
                        >
                            {moodinfo.map((mood) => (
                                <Grid container item key={`moodlegend_${mood.name}`} xs={6}>
                                    <Typography variant={"body2"} style={{ color: mood.color, marginRight: "1em" }}>
                                        ●
                                    </Typography>
                                    <Typography variant={"body2"}>{mood.name}</Typography>
                                </Grid>
                            ))}
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            style={{ width: "100%", marginTop: 2 }}
                            justifyContent="space-between"
                            alignItems="baseline"
                        >
                            <Grid item xs={9}>
                                <Typography variant={"body2"} align="left">
                                    Positive Emotions:
                                </Typography>
                            </Grid>
                            <Grid item xs={3} justifyContent="flex-end">
                                <Typography variant={"body2"} align="right">
                                    {positiveMoodPercent}%
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}
                            style={{ width: "100%" }}
                            justifyContent="space-between"
                            alignItems="baseline"
                        >
                            <Grid item xs={9}>
                                <Typography variant={"body2"} align="left">
                                    Negative Emotions:
                                </Typography>
                            </Grid>
                            <Grid item xs={3} justifyContent="flex-end">
                                <Typography variant={"body2"} align="right">
                                    {negativeMoodPercent}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Box>
                            <Typography variant={"body2"}>
                                Your data will be available here after the mood tracker has been taken.
                            </Typography>
                        </Box>
                        <Box></Box>
                    </>
                )
            ) : (
                <SimpleBlankSlate
                    header={"Todays Wellbeing"}
                    content={`You do not have permission to view todays wellbeing`}
                    extra={
                        <>
                            <Box mb={2}>
                                <Typography variant={"body2"} color={"primary"}>
                                    (Available for Staff Members and School Administrators only)
                                </Typography>
                            </Box>
                        </>
                    }
                />
            )}
        </HeadlineFigureWidget>
    );
};
