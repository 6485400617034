import { Session } from "common/build/prisma/client";
import dayjs from "dayjs";
import * as yup from "yup";
import { ProgrammeType } from "../../../models/modelTypes";
import { sortSessions } from "../../../utils/sorts";

export const programmesCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Programme Name",
    },
    pathwayId: {
        label: "Story Collection",
        inForm: false,
        decorator: (value: string, property?: string, programme?: ProgrammeType): string =>
            programme?.StoryCollection ? programme?.StoryCollection.name : "None",
    },
    ageRange: {
        label: "Age Group",
        validation: yup.string().required("Age Range is required"),
    },
    nextSession: {
        label: "Next Session",
        inForm: false,
        decorator: (value: string, property?: string, programme?: ProgrammeType): string => {
            const deleted = programme?.deleted;
            const sessions: Session[] = programme?.Sessions ?? [];
            const activeSessions = [...sessions].filter((session) => session.state !== "Complete").sort(sortSessions);
            return deleted || activeSessions.length < 1
                ? "None Upcoming"
                : dayjs(activeSessions[0].date).format("D MMM YYYY [at] HH:mm");
        },
    },
};
