import React from "react";
import { HomeScreen } from "../components/public/HomeScreen";
import { HomeProvider } from "../components/public/hooks/home.context";
import { Box, Button, Container } from "@material-ui/core";
import { useSignUpStyles } from "../components/public/signup-signin/signup.style";
import VerbalLogo from "../img/VerbalLogo";
import { Link } from "react-router-dom";

type InvitationPageProps = {
    isSignIn?: boolean;
};
export const InvitationPage: React.FC<InvitationPageProps> = ({ isSignIn }) => {
    const classes = useSignUpStyles();
    return (
        <HomeProvider>
            <div className={classes.root}>
                <Container maxWidth="xs" className="c-island">
                    <Box mb={4}>
                        <VerbalLogo color={"white"} width={"175px"} />
                    </Box>
                    <HomeScreen isSignIn={isSignIn} />
                </Container>
                <Button
                    component={Link}
                    to="/login"
                    color={"secondary"}
                    style={{
                        backgroundColor: "#121738",
                        color: "white",
                        position: "fixed",
                        bottom: "1em",
                        right: "1em",
                    }}
                >
                    Organisation Login
                </Button>
            </div>
        </HomeProvider>
    );
};
