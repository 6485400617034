import { Participant, Prisma } from "common/build/prisma/client";
import { deleteData, getData, patchData, postData } from "./rest";
import { objectToQueryString } from "../../utils/api";
import { ParticipantListParameters } from "common/build/api-parameters/participants";

export const list = async (args: ParticipantListParameters): Promise<{ items: Participant[]; total: number }> =>
    await getData(`/participant${objectToQueryString(args)}`);

export const get = async (id: number, args: ParticipantListParameters): Promise<Participant> =>
    await getData(`/participant/${id}${objectToQueryString(args)}`);

export const add = async (participant: Partial<Participant>): Promise<Response> =>
    await postData(`/participant/`, { ...participant });

export const update = async (
    id: number,
    editedUser: Partial<Prisma.ParticipantUpdateInput | Prisma.ParticipantUncheckedUpdateInput>,
): Promise<Response> => await patchData(`/participant/${id}`, { ...editedUser });

export const deleteParticipant = async (id: number): Promise<Response> => await deleteData(`/participant/${id}`);
