import React from "react";
import { Checkbox, FormGroup, FormHelperText, FormControlLabel, FormControl } from "@material-ui/core";

interface CheckboxProps {
    id: string;
    value: boolean;
    setFieldValue: (field: string, value: boolean) => void;
    label: string;
    leftOption: string;
    rightOption: string;
    helperText?: string;
    selectedHelperText?: string | React.ReactElement;
    unSelectedHelperText?: string | React.ReactElement;
}

export const CheckboxInput = (props: CheckboxProps): JSX.Element => {
    const { id, value, setFieldValue, label, helperText, selectedHelperText, unSelectedHelperText } = props;

    return (
        <FormControl>
            <FormGroup>
                <FormControlLabel
                    label={label}
                    control={
                        <Checkbox
                            checked={value}
                            name={id}
                            onChange={(event) => setFieldValue(id, event.target.checked)}
                        />
                    }
                />
            </FormGroup>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {value && selectedHelperText && <FormHelperText>{selectedHelperText}</FormHelperText>}
            {!value && unSelectedHelperText && <FormHelperText>{unSelectedHelperText}</FormHelperText>}
        </FormControl>
    );
};
