import React, { useState } from "react";
import { PublicUser } from "common/build/prisma/client";
import { Button, TextField, Typography, Box, CircularProgress } from "@material-ui/core";
import { Form, Formik, FormikHelpers } from "formik";
import * as yup from "yup";
import { useHome } from "../hooks/home.context";

type EnterEmailProps = {
    isSignIn?: boolean;
};

export const EnterEmail: React.FC<EnterEmailProps> = ({ isSignIn = false }) => {
    const { details, onConfirmEmail, sendLoginEmail } = useHome();
    const [processing, setProcessing] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    const initialValues: Partial<PublicUser> = {
        email: details?.email ?? "",
    };

    const onSubmit = (values: Partial<PublicUser>, formikHelpers: FormikHelpers<Partial<PublicUser>>) => {
        const { setSubmitting } = formikHelpers;
        if (values.email) {
            setProcessing(true);
            if (isSignIn) {
                sendLoginEmail(values.email)
                    .then(() => {
                        setError(undefined);
                    })
                    .catch(() => {
                        setError("Error signing in");
                    })
                    .finally(() => {
                        setProcessing(false);
                        setSubmitting(false);
                    });
            } else {
                onConfirmEmail(values.email)
                    .then(() => {
                        setError(undefined);
                    })
                    .catch(() => {
                        setError("Error setting up account");
                    })
                    .finally(() => {
                        setProcessing(false);
                        setSubmitting(false);
                    });
            }
        }
    };

    return (
        <>
            <Box mb={2}>
                {details && details.email ? (
                    <>
                        <Box mb={1}>
                            <Typography variant="h5" align="left">
                                Confirm your email address
                            </Typography>
                        </Box>
                        <Typography variant="body1" align="left">
                            If you&apos;d like to use a different email address add it below
                        </Typography>
                    </>
                ) : (
                    <Typography variant="h5" align="left">
                        Enter your email address
                    </Typography>
                )}
            </Box>
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    email: yup.string().email("Please enter a valid email").required("Email is required").max(100),
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting }) => {
                    return (
                        <Form style={{ width: "100%" }}>
                            <TextField
                                autoFocus
                                fullWidth={true}
                                id="email"
                                label="Email Address"
                                variant="outlined"
                                value={values.email}
                                onChange={handleChange}
                                helperText={errors.email && touched.email && errors.email}
                                error={errors.email ? true : false}
                                required
                                inputProps={{
                                    maxLength: 100,
                                }}
                            />

                            <Box width="100%" mt={2}>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    color="secondary"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                    type="submit"
                                >
                                    {!processing ? "Continue" : <CircularProgress />}
                                </Button>
                            </Box>

                            {error && <Typography color="error">{error}</Typography>}
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};
export default EnterEmail;
