import React from "react";
import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { usePublicLayoutStyle } from "./PublicLayout.style";
import { publicTheme } from "../themes/public.theme";

export const PublicLayout: React.FC = ({ children }): React.ReactElement => {
    const classes = usePublicLayoutStyle();
    return (
        <ThemeProvider theme={publicTheme}>
            <CssBaseline />
            <div className={classes.root}>
                <main className={classes.main}>{children}</main>
            </div>
        </ThemeProvider>
    );
};
