import { Box, Drawer, Grid, Hidden } from "@material-ui/core";
import React from "react";
import { BrowseHome } from "./BrowseHome";
import { CollectionsAZ } from "./CollectionsAZ";
import { useMobile } from "../../hooks/useMobile";
import { useHomeStyles } from "./Home.style";
import { BrowseProvider } from "./contexts/browse.context";

export const ExploreScreen = (): JSX.Element => {
    const { isMobile, isTablet } = useMobile();

    const classes = useHomeStyles();

    return (
        <BrowseProvider>
            <Box display={"flex"} overflow="hidden" maxWidth={"100vw"} pt={3}>
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ paddingRight: 0 }}>
                        {!isTablet && isMobile && <div className={classes.toolbar} />}
                        <BrowseHome />
                    </Grid>

                    <Hidden only={["md", "lg", "xl", "sm", "xs"]}>
                        <Box bgcolor="secondaryShade.main" px={2}>
                            <Grid item xs={12}>
                                <CollectionsAZ />
                            </Grid>
                        </Box>
                    </Hidden>
                </Grid>

                <Hidden only={["md", "lg", "xl", "sm", "xs"]}>
                    <Drawer
                        className={classes.homeDrawer}
                        variant="permanent"
                        classes={{ paper: classes.homeDrawerPaper }}
                        anchor="right"
                    >
                        <CollectionsAZ />
                    </Drawer>
                </Hidden>
            </Box>
        </BrowseProvider>
    );
};
