import React from "react";
import * as yup from "yup";
import { EditSessionForm } from "./EditSessionForm";
import { VideoConferenceSelect } from "./VideoConferenceSelect";
import { ToggleInput } from "../../../core/components/ToggleInput";
import { CrudProvider } from "../../../core/components/crud/contexts/crud.context";
import { FetchProvider } from "../../../core/components/crud/contexts/fetch.context";
import { SessionType } from "../../../models/modelTypes";
import { get, update } from "../../../client/api/session";
import dayjs from "dayjs";
import { SessionFetchParameters } from "common/src/api-parameters/session";
import { InputCheckboxProps, InputDateProps } from "../../../core/components/crud/InputTypes";
import { Alert } from "@material-ui/lab";

export const EditSessionScreen = (): JSX.Element => {
    const dateGroup = { row: true };
    const sessionEditModel = {
        date: {
            ...InputDateProps,
            label: "Start Date",
            validation: yup.string().required("Start Date is required"),
            inputGroup: dateGroup,
            submitValueDecorator: (_: unknown, values: Record<string, unknown>) =>
                dayjs(`${values.date} ${values.time}`, "YYYY-MM-DD HH:mm").toDate(),
        },
        time: {
            label: "Start Time",
            validation: yup.string().required("Start Time is required"),
            inputProps: {
                type: "time",
                inputProps: {
                    step: 300,
                },
                InputLabelProps: {
                    shrink: true,
                },
            },
            inputGroup: dateGroup,
            inputValueDecorator: (_: unknown, values: Record<string, unknown>) =>
                dayjs(values.date as Date).format("HH:mm") ?? "08:30",
        },
        isOnline: {
            label: "Is Online",
            InputComponent: ToggleInput,
            inputProps: {
                label: `How / Where will the sessions be held?`,
                leftOption: "Online",
                rightOption: `In-Person`,
            },
        },
        shouldUpdateFutureSessions: {
            ...InputCheckboxProps,
            label: "Do you want to arrange all future session in the programme based on the chosen frequency?",
            inputProps: {
                selectedHelperText: (
                    <Alert severity="info">
                        The dates and times of future sessions will change based on your changes to this session
                    </Alert>
                ),
                unSelectedHelperText: (
                    <Alert severity="info">The dates and times of future sessions will NOT be changed</Alert>
                ),
            },
        },
        providerType: {
            InputComponent: VideoConferenceSelect,
        },
        providerURL: {
            inForm: false,
            validation: yup.string().when("providerType", {
                is: "BuiltIn",
                then: yup.string().nullable(),
                otherwise: yup
                    .string()
                    .matches(
                        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9-_#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                        "Video URL must be a valid URL",
                    )
                    .required(),
            }),
        },
    };

    return (
        <CrudProvider<SessionType> model={sessionEditModel} noun="Session" updateItem={update}>
            <FetchProvider
                noun={"session"}
                getItem={get}
                includes={{ includeAllSessions: true, includeStory: true } as SessionFetchParameters}
            >
                <EditSessionForm />
            </FetchProvider>
        </CrudProvider>
    );
};
