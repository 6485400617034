import React, { useEffect } from "react";
import { Grid, TextField, Typography } from "@material-ui/core";
import { VModule, VModuleType } from "../../../../core/components/VModule";
import { ToggleInput } from "../../../../core/components/ToggleInput";
import { useFormikContext } from "formik";
import { DestructiveButton } from "../../../layout/Button/Button";
import { useAuth } from "../../../../contexts/auth.context";

type ConditionQuestionProps = {
    count: number;
    condition: boolean;
    question: string;
    explanation?: string;
    beenApproved?: boolean;
    removeCondition?: () => void;
};

export const ConditionQuestion: React.FC<ConditionQuestionProps> = (props: ConditionQuestionProps) => {
    const { count, condition, question, explanation, removeCondition, beenApproved = false } = props;
    const { hasPermission } = useAuth();

    const { setFieldValue, handleChange } = useFormikContext();

    const conditionName = `condition${count}`;
    const questionName = `conQuestion${count}`;
    const explanationName = `conExplanation${count}`;

    const handleRemoveQuestion = () => {
        if (removeCondition) {
            setFieldValue(questionName, "");
            setFieldValue(explanationName, "");
            removeCondition();
        }
    };

    useEffect(() => {
        setFieldValue(conditionName, condition);
    }, [conditionName, condition]);

    return (
        <Grid item xs={12}>
            <VModule type={VModuleType.Shade1}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography variant="h6">Condition {count}</Typography>
                            {removeCondition && !beenApproved && hasPermission("StoryAddQuestion") ? (
                                <DestructiveButton onClick={handleRemoveQuestion}>Remove</DestructiveButton>
                            ) : null}
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <ToggleInput
                            id={conditionName}
                            setFieldValue={setFieldValue}
                            label={`If the answer is`}
                            leftOption={`Yes`}
                            rightOption={`No`}
                            value={condition}
                            disabled={!hasPermission("StoryAddQuestion") || beenApproved}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            autoFocus
                            required
                            disabled={!hasPermission("StoryAddQuestion") || beenApproved}
                            name={questionName}
                            label="Question"
                            variant="outlined"
                            multiline
                            value={question}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            size="small"
                            name={explanationName}
                            variant="outlined"
                            label="Explanation"
                            disabled={!hasPermission("StoryAddQuestion") || beenApproved}
                            multiline
                            value={explanation}
                            fullWidth
                            onChange={handleChange}
                        />
                    </Grid>
                </Grid>
            </VModule>
        </Grid>
    );
};
