import React, { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Grid,
    IconButton,
    Typography,
    Drawer,
    Avatar,
    Divider,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { CalendarToday, Check, Close } from "@material-ui/icons";
import { MappedStoryQuestionType, MappedStoryType, StoryCollectionType } from "../../../../models/modelTypes";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { DestructiveButton, PrimaryButton } from "../../../layout/Button/Button";
import { VPill } from "../../../../core/components/VPill";
import { useAuth } from "../../../../contexts/auth.context";

type QuestionSidePanelProps = {
    open: boolean;
    partCount: number;
    close: (open: boolean) => void;
    openQuestionDialog: (open: boolean) => void;
    setQuestionToEdit: (question?: MappedStoryQuestionType) => void;
    partId?: number;
    story?: MappedStoryType;
    removeQuestion: (questionOrder: number) => void;
    collection?: StoryCollectionType;
};

export const QuestionSidePanel: React.FC<QuestionSidePanelProps> = (props: QuestionSidePanelProps): JSX.Element => {
    const { open, partCount, story, partId, close, openQuestionDialog, setQuestionToEdit, removeQuestion, collection } =
        props;

    const { hasPermission } = useAuth();

    const [openConfirmDelete, setOpenConfirmDelete] = useState<boolean>(false);
    const [questionOrder, setQuestionOrder] = useState<number>();
    const [partNum, setPartNum] = useState<number>();

    const isPublicCollection = collection?.type === "Public";

    useEffect(() => {
        if (story) {
            const pn = story?.Story?.StoryParts?.find((p) => p.id === partId)?.part;
            if (pn !== undefined) {
                setPartNum(pn + 1);
            }
        }
    }, [story, partCount]);

    const deleteQuestion = () => {
        if (questionOrder) {
            removeQuestion(questionOrder);
        }
        setOpenConfirmDelete(false);
    };

    const useStyles = makeStyles(
        createStyles({
            sidePanel: {
                display: open ? "flex" : "none",
                overflowX: "hidden",
            },
            drawerPaper: {
                overflowX: "hidden",
                backgroundColor: "white",
                color: "inherit",
                width: "400px",
            },
            icon: {
                width: verbalTheme.spacing(10),
                height: verbalTheme.spacing(10),
                alignSelf: "center",
                color: "#fff",
                backgroundColor: "#1FAF8C",
            },
            header: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
            gridRowItem: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            emptyContainer: {
                position: "absolute",
                top: "25%",
                transform: "translate(0, -25%)",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Drawer
                variant="persistent"
                anchor="right"
                open={open}
                className={classes.sidePanel}
                classes={{ paper: classes.drawerPaper }}
            >
                <Box mx={2} mt={1}>
                    <div className={classes.header}>
                        <Typography variant="h5">Story Break {partNum !== undefined ? partNum : partCount}</Typography>
                        <IconButton onClick={() => close(false)}>
                            <Close />
                        </IconButton>
                    </div>
                </Box>
                <Divider light />
                {partId &&
                story &&
                story.MappedStoryQuestion &&
                story.MappedStoryQuestion.filter((q) => q.mappedStoryPartId === partId)?.length > 0 ? (
                    <div>
                        {story.MappedStoryQuestion.filter((q) => q.mappedStoryPartId === partId).map((question) => {
                            return (
                                <>
                                    <Box mb={1} m={2} key={question.order}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" gutterBottom>
                                                    Question {question.order}
                                                </Typography>
                                                <Typography>{question.question}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container direction="row" spacing={1}>
                                                    {isPublicCollection && (
                                                        <Grid item className={classes.gridRowItem}>
                                                            <VPill
                                                                variant={
                                                                    question.hint1 || question.hint2
                                                                        ? "default"
                                                                        : "outlined"
                                                                }
                                                                size="small"
                                                                label="Hints"
                                                                icon={
                                                                    question.hint1 || question.hint2 ? <Check /> : <></>
                                                                }
                                                                color={
                                                                    question.hint1 || question.hint2
                                                                        ? "primary"
                                                                        : "default"
                                                                }
                                                                style={
                                                                    question.hint1 || question.hint2
                                                                        ? { color: "white" }
                                                                        : {}
                                                                }
                                                            />
                                                        </Grid>
                                                    )}

                                                    <Grid item className={classes.gridRowItem}>
                                                        <VPill
                                                            variant={question.explaination ? "default" : "outlined"}
                                                            size="small"
                                                            label="Why Ask This?"
                                                            icon={question.explaination ? <Check /> : <></>}
                                                            color={question.explaination ? "primary" : "default"}
                                                            style={question.explaination ? { color: "white" } : {}}
                                                        />
                                                    </Grid>

                                                    <Grid item className={classes.gridRowItem}>
                                                        <VPill
                                                            variant={question.keywordDesc ? "default" : "outlined"}
                                                            size="small"
                                                            label="Purpose"
                                                            icon={question.keywordDesc ? <Check /> : <></>}
                                                            color={question.keywordDesc ? "primary" : "default"}
                                                            style={question.keywordDesc ? { color: "white" } : {}}
                                                        />
                                                    </Grid>

                                                    <Grid item className={classes.gridRowItem}>
                                                        <VPill
                                                            variant={question.keywords ? "default" : "outlined"}
                                                            size="small"
                                                            label="Keywords"
                                                            icon={question.keywords ? <Check /> : <></>}
                                                            color={question.keywords ? "primary" : "default"}
                                                            style={question.keywords ? { color: "white" } : {}}
                                                        />
                                                    </Grid>

                                                    <Grid item className={classes.gridRowItem}>
                                                        <VPill
                                                            variant={question.prompt ? "default" : "outlined"}
                                                            size="small"
                                                            label="Help"
                                                            icon={question.prompt ? <Check /> : <></>}
                                                            color={question.prompt ? "primary" : "default"}
                                                            style={question.prompt ? { color: "white" } : {}}
                                                        />
                                                    </Grid>

                                                    <Grid item className={classes.gridRowItem}>
                                                        <VPill
                                                            variant={question.prepGuide ? "default" : "outlined"}
                                                            size="small"
                                                            label="Guide Note"
                                                            icon={question.prepGuide ? <Check /> : <></>}
                                                            color={question.prepGuide ? "primary" : "default"}
                                                            style={question.prepGuide ? { color: "white" } : {}}
                                                        />
                                                    </Grid>

                                                    {question.RelatedQuestion &&
                                                        question.RelatedQuestion.length > 0 && (
                                                            <Grid item className={classes.gridRowItem}>
                                                                <VPill
                                                                    size="small"
                                                                    color="primary"
                                                                    icon={<Check />}
                                                                    label={`${question.RelatedQuestion.length} conditional questions`}
                                                                    style={{ color: "white" }}
                                                                />
                                                            </Grid>
                                                        )}
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            setQuestionToEdit(question);
                                                            openQuestionDialog(true);
                                                        }}
                                                    >
                                                        View/Edit
                                                    </Button>
                                                    {story?.MappedStoryQuestion?.filter(
                                                        (q) => q.mappedStoryPartId === partId,
                                                    ).length === question.order &&
                                                        hasPermission("StoryAddQuestion") && (
                                                            <DestructiveButton
                                                                onClick={() => {
                                                                    setQuestionOrder(question.order);
                                                                    setOpenConfirmDelete(true);
                                                                }}
                                                            >
                                                                Delete
                                                            </DestructiveButton>
                                                        )}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}></Grid>
                                        </Grid>
                                    </Box>
                                    <Divider light />
                                </>
                            );
                        })}
                        {hasPermission("StoryAddQuestion") && (
                            <Box m={2}>
                                <PrimaryButton
                                    fullWidth
                                    onClick={() => {
                                        setQuestionToEdit(undefined);
                                        openQuestionDialog(true);
                                    }}
                                >
                                    Add Question
                                </PrimaryButton>
                            </Box>
                        )}
                    </div>
                ) : (
                    <div className={classes.emptyContainer}>
                        <Box textAlign="center">
                            <Box justifyContent="center" display="flex" mt={5} mb={2}>
                                <Avatar className={classes.icon}>
                                    <CalendarToday fontSize="large" />
                                </Avatar>
                            </Box>
                            <Box mb={2}>
                                <Typography variant={"h5"} gutterBottom>
                                    No questions
                                </Typography>
                            </Box>
                            <Typography gutterBottom>
                                There are currently 0 questions added to this story break
                            </Typography>
                            <PrimaryButton onClick={() => openQuestionDialog(true)}>Add Question</PrimaryButton>
                        </Box>
                    </div>
                )}
                <Dialog open={openConfirmDelete} onClose={() => setOpenConfirmDelete(false)}>
                    <DialogTitle>Are you sure?</DialogTitle>
                    <DialogContent>
                        Are you sure you want to delete this question? This will also delete any conditional questions
                        linked to this question
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenConfirmDelete(false)}>Cancel</Button>
                        <DestructiveButton onClick={deleteQuestion}>Delete</DestructiveButton>
                    </DialogActions>
                </Dialog>
            </Drawer>
        </>
    );
};
