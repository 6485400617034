import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";

const surveyLg = "700px";
const surveyMd = "600px";

export const useStoryStyles = makeStyles(
    createStyles({
        storyContainer: {
            maxWidth: "100%",
            padding: verbalTheme.spacing(4, 3),
            [verbalTheme.breakpoints.up("sm")]: {
                padding: verbalTheme.spacing(4, 4),
            },
            [verbalTheme.breakpoints.up("md")]: {
                maxWidth: "850px",
                padding: verbalTheme.spacing(6, 6),
            },
        },
        surveyContainer: {
            maxWidth: "100%",
            padding: verbalTheme.spacing(2, 3),
            [verbalTheme.breakpoints.up("sm")]: {
                padding: verbalTheme.spacing(6, 6),
                maxWidth: surveyMd,
            },
            [verbalTheme.breakpoints.up("md")]: {
                maxWidth: surveyLg,
            },
        },
    }),
);
