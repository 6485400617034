import React, { useEffect, useState } from "react";
import { Box, Container, Grid, ThemeProvider, Typography } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { useLoginLayoutStyle } from "./AuthenticationLayout.style";
import { verbalTheme } from "../themes/verbal.theme";
import VerbalLogo from "../../../img/VerbalLogo";
import { useUtilityStyles } from "../../../hooks/styles/UtilityStyles";
import { useLocation } from "react-router-dom";

export const AuthenticationLayout: React.FC = ({ children }): React.ReactElement => {
    const utilityClasses = useUtilityStyles();
    const classes = useLoginLayoutStyle();
    const location = useLocation();

    const [isLogin, setIsLogin] = useState<boolean>(true);

    useEffect(() => {
        if (location.pathname === "/login") {
            setIsLogin(true);
        } else {
            setIsLogin(false);
        }
    }, [location.pathname]);

    return (
        <ThemeProvider theme={verbalTheme}>
            <CssBaseline />
            <main className={classes.main}>
                <Grid container className={utilityClasses.height100}>
                    <Grid item xs={12} md={5} className={classes.infoSection}>
                        <Box
                            p={3}
                            bgcolor={isLogin ? "primary.main" : "secondary.main"}
                            height={1}
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                        >
                            <Container maxWidth={"sm"}>
                                <Box mb={4} maxWidth={"200px"} flex="alignItems">
                                    <VerbalLogo color={"white"} />
                                </Box>
                                <Typography variant={"body1"}>
                                    <Box color={"white"} alignSelf="center">
                                        {!isLogin && (
                                            <>
                                                <Typography variant="h4" align={"left"} gutterBottom>
                                                    Here to sign up your Organisation?
                                                </Typography>
                                                <Typography variant={"subtitle1"} align={"left"} gutterBottom>
                                                    1. Enter primary Email and Organisation details.
                                                </Typography>
                                                <Typography variant={"subtitle1"} align={"left"} gutterBottom>
                                                    2. Create the account.
                                                </Typography>
                                                <Typography variant={"subtitle1"} align={"left"} gutterBottom>
                                                    3. Invite members of your organisation onto the system while
                                                    assigning them suitable roles.
                                                </Typography>
                                            </>
                                        )}

                                        {isLogin && (
                                            <>
                                                <Typography variant="h4" align={"left"} gutterBottom>
                                                    Already have an Org Account with Verbal?
                                                </Typography>
                                                <Typography variant={"subtitle1"} align={"left"} gutterBottom>
                                                    1. Contact your orgs primary admin.
                                                </Typography>
                                                <Typography variant={"subtitle1"} align={"left"} gutterBottom>
                                                    2. They will create an account for you.
                                                </Typography>
                                                <Typography variant={"subtitle1"} align={"left"} gutterBottom>
                                                    3. Check your inbox for the signup email.
                                                </Typography>
                                                <Typography variant={"subtitle1"} align={"left"} gutterBottom>
                                                    4. Login using your new account
                                                </Typography>
                                            </>
                                        )}
                                    </Box>
                                </Typography>
                            </Container>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Box p={3} height={1} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                            <Container maxWidth={"md"}>
                                <Box mb={3}>
                                    <Box mb={4} textAlign={"center"} className={utilityClasses.hideOnDesktop}>
                                        <VerbalLogo width={"200px"} />
                                    </Box>
                                </Box>
                                {children}
                            </Container>
                        </Box>
                    </Grid>
                </Grid>
            </main>
        </ThemeProvider>
    );
};
