import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import SessionCompleteImg from "../../../../../img/session-complete.svg";

type LobbyProps = {
    showExitSession: (show: boolean) => void;
};

export const Lobby: React.FC<LobbyProps> = (props: LobbyProps): JSX.Element => {
    const { showExitSession } = props;

    return (
        <>
            <Box display={"grid"} justifyContent={"center"} mb={3}>
                <Box width={120} textAlign={"center"}>
                    <img src={SessionCompleteImg} />
                </Box>
            </Box>
            <Box mb={3} textAlign={"center"}>
                <Typography variant="h3" color="textPrimary">
                    Session Complete
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <Button variant="contained" size="large" color="primary" onClick={() => showExitSession(true)}>
                    {"End Session"}
                </Button>
            </Box>
        </>
    );
};
