import React from "react";
import { Box, Typography } from "@material-ui/core";
import SessionCompleteImg from "../../../img/session-complete.svg";

export const SurveyClosePage: React.FC = (): JSX.Element => {
    return (
        <>
            <Box display={"grid"} justifyContent={"center"} mb={3} mt={5}>
                <Box width={120} textAlign={"center"}>
                    <img src={SessionCompleteImg} />
                </Box>
            </Box>
            <Box mb={3} textAlign={"center"}>
                <Typography variant="h2" color="textPrimary">
                    Survey Complete
                </Typography>
            </Box>
            <Box mb={3} textAlign="center">
                <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                    Thanks so much for taking part in our survey. Your contribution greatly helps us to improve our
                    programmes
                </Typography>
            </Box>
            <Box display="flex" justifyContent="center">
                <Typography variant={"h3"} color="textPrimary">
                    You can now close this window and return to the dashboard
                </Typography>
            </Box>
        </>
    );
};
