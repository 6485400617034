import React, { useEffect } from "react";

type FreshworksWidget = {
    (...args: unknown[]): void;
    q: unknown[];
};

declare global {
    interface Window {
        fwSettings: { widget_id: number };
        FreshworksWidget: FreshworksWidget;
    }
}

export const FreshdeskWidget = (): JSX.Element => {
    useEffect(() => {
        if (window.FreshworksWidget) {
            window.FreshworksWidget("hide", "launcher");
        }
    }, []);

    return <></>;
};
