import React, { useState } from "react";
import { Button, TextField, Grid, Typography, Box, CircularProgress, Container } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import { Breadcrumb } from "../Breadcrumb";
import { useHistory } from "react-router";
import * as yup from "yup";
import { useCrud } from "./contexts/crud.context";
import AsyncSearch from "../AsyncSearch";
import { Model } from "./Crud";
import { ObjectShape } from "yup/lib/object";

export const getValidationSchema = <T extends Record<string, unknown>>(model: Model<T>): ObjectShape => {
    return Object.fromEntries(Object.entries(model).map(([k, { validation }]) => (validation ? [k, validation] : [])));
};

export const Add = <T extends Record<string, unknown>>(): JSX.Element => {
    const { add, model, noun } = useCrud<T>();
    const { goBack } = useHistory();

    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>();

    const onSave = (values: T, formikHelpers: FormikHelpers<T & { [key: string]: string | number }>) => {
        const { setSubmitting } = formikHelpers;
        setLoading(true);
        add(values)
            .then(() => {
                setError(undefined);
                goBack();
            })
            .catch((err: string) => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
                setSubmitting(false);
            });
    };

    return (
        <Box p={4}>
            <Container maxWidth={"md"}>
                <Box mb="3rem">
                    <Box mb={3}>
                        <Breadcrumb current={`Add new ${noun.toLowerCase()}`} />
                    </Box>
                    <Typography variant="h2" color="textPrimary" align="left">
                        {`Add new ${noun}`}
                    </Typography>
                </Box>
                <Formik
                    initialValues={{} as T & { [key: string]: string | number }}
                    onSubmit={onSave}
                    validationSchema={yup.object(getValidationSchema<T>(model))}
                    validateOnChange={false}
                >
                    {({ submitForm, handleChange, isSubmitting, errors, setFieldValue }) => {
                        return (
                            <>
                                <Grid container alignItems="flex-start" spacing={2}>
                                    {Object.entries(model)
                                        .filter(([, { inForm = true }]) => inForm)
                                        .map(([property, { label = `${property[0].toUpperCase() + property
                                                        .substring(1)
                                                        .toLowerCase()}`, InputComponent = TextField, inputProps = {} }], index) => {
                                            if (InputComponent === AsyncSearch) {
                                                inputProps = {
                                                    ...inputProps,
                                                    onSelected: (property: string, value: string) =>
                                                        setFieldValue(property, value ? parseInt(value) : undefined),
                                                };
                                            }

                                            return (
                                                <Grid key={index} item sm={6} xs={12}>
                                                    <InputComponent
                                                        fullWidth={true}
                                                        id={property}
                                                        label={label}
                                                        variant="outlined"
                                                        onChange={handleChange}
                                                        helperText={errors[property] && errors[property]}
                                                        error={errors[property] ? true : false}
                                                        {...(index === 0 && InputComponent === TextField
                                                            ? { autoFocus: true }
                                                            : undefined)}
                                                        {...inputProps}
                                                    />
                                                </Grid>
                                            );
                                        })}

                                    {Object.entries(model).filter(
                                        ([, { inForm = true, inputProps = { required: false } }]) =>
                                            inForm && inputProps.required,
                                    ).length > 0 && (
                                        <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                            Input fields marked with an asterisk (*) are required
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <Grid container justifyContent="space-between">
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    disabled={isSubmitting}
                                                    onClick={() => {
                                                        goBack();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="contained"
                                                    size="large"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={submitForm}
                                                >
                                                    {!loading ? "Save" : <CircularProgress size={30} />}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {error && (
                                        <Grid item xs={12}>
                                            <Typography color="error">{error}</Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            </>
                        );
                    }}
                </Formik>
            </Container>
        </Box>
    );
};
export default Add;
