import { Box, ImageList, ImageListItem, ImageListItemBar, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { CurriculumType } from "../../models/modelTypes";
import { getTopTen, getVerbalPicks } from "../../client/api/home";
import { Skeleton } from "@material-ui/lab";
import { useMobile } from "../../hooks/useMobile";
import { useHistory } from "react-router-dom";
import { useHomeStyles } from "./Home.style";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { useAuth } from "../../contexts/auth.context";

type ColourClass = {
    colour:
        | "Red"
        | "Orange"
        | "Yellow"
        | "Green"
        | "Cyan"
        | "Blue"
        | "Magneta"
        | "Crimson"
        | "Silver"
        | "Bronze"
        | "BrightYellow"
        | "Aqua"
        | "Purple"
        | "BrightOrange"
        | "BrightRed"
        | "LightGreen"
        | "NightBlue";
};

export const getColourClass = (props: ColourClass): string => {
    const { colour } = props;

    switch (colour) {
        case "Red":
            return "cardRed.main";
        case "Orange":
            return "cardOrange.main";
        case "Yellow":
            return "cardYellow.main";
        case "Green":
            return "cardGreen.main";
        case "Cyan":
            return "cardCyan.main";
        case "Blue":
            return "cardBlue.main";
        case "Magneta":
            return "cardMagneta.main";
        case "Crimson":
            return "cardCrimson.main";
        case "Silver":
            return "cardSilver.main";
        case "BrightYellow":
            return "cardBrightYellow.main";
        case "Aqua":
            return "cardAqua.main";
        case "Purple":
            return "cardPurple.main";
        case "BrightOrange":
            return "cardBrightOrange.main";
        case "BrightRed":
            return "cardBrightRed.main";
        case "LightGreen":
            return "cardLightGreen.main";
        case "NightBlue":
            return "cardNightBlue.main";
        default:
            return "cardRed.main";
    }
};

export const BrowseHome = (): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(false);
    const [verbalPicks, setVerbalPicks] = useState<CurriculumType[]>([]);
    const [topTen, setTopTen] = useState<CurriculumType[]>([]);
    const { isMobile, isTablet } = useMobile();
    const { user } = useAuth();

    const history = useHistory();

    useEffect(() => {
        setLoading(true);
        getVerbalPicks().then((value) => {
            setVerbalPicks(value);
        });

        getTopTen()
            .then((value) => {
                setTopTen(value);
            })
            .finally(() => setLoading(false));
    }, []);

    const classes = useHomeStyles();

    return (
        <Box p={!isTablet && isMobile ? 2 : 3} pt={0} pr={!isTablet && isMobile ? 0 : 4} maxWidth="100vw" pl={4}>
            <>
                <Box mb={3} mt={!isTablet && isMobile && 2}>
                    <Typography variant="h2" color="textPrimary" align="left">
                        Hello {user?.firstName}
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="h3" color="textPrimary" align="left">
                        Browse Curriculums
                    </Typography>
                </Box>
                <Box className={classes.root} mb={3}>
                    <Box width={"100%"}>
                        <ImageList
                            gap={10}
                            className={classes.imageList}
                            cols={isTablet ? 3.5 : isMobile ? 2.5 : 4}
                            id="our-picks"
                        >
                            {verbalPicks.length > 0 && !loading
                                ? verbalPicks.map((item) => {
                                      const isComingSoon = item.StoryCollection?.length < 1;
                                      return (
                                          <ImageListItem key={item.id}>
                                              {isComingSoon ? (
                                                  <div
                                                      style={{
                                                          cursor: "not-allowed",
                                                          height: "100%",
                                                          width: "100%%",
                                                          margin: "auto",
                                                          border: "1px solid black",
                                                          borderRadius: verbalTheme.spacing(1),
                                                          display: "flex",
                                                          justifyContent: "center",
                                                      }}
                                                  >
                                                      <svg
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          height="75%"
                                                          viewBox="0 0 24 24"
                                                          width="75%"
                                                          fill={verbalTheme.palette.secondary.main}
                                                      >
                                                          <path d="M0 0h24v24H0z" fill="none" />
                                                          <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                                                      </svg>
                                                  </div>
                                              ) : (
                                                  <Box
                                                      minHeight="100%"
                                                      p={2}
                                                      borderRadius={10}
                                                      textAlign="center"
                                                      bgcolor={`${getColourClass({ colour: item.colour })}`}
                                                      onClick={() =>
                                                          history.push({
                                                              pathname: `/explore/curriculum/${item.id}`,
                                                              state: {
                                                                  from: `${"/explore"}`,
                                                              },
                                                          })
                                                      }
                                                      style={{
                                                          cursor: "pointer",
                                                      }}
                                                  />
                                              )}

                                              <ImageListItemBar
                                                  style={{ textAlign: "center" }}
                                                  className={classes.titleBarBottom}
                                                  classes={{
                                                      titleWrap: classes.titleWrap,
                                                  }}
                                                  title={
                                                      <Typography
                                                          style={{
                                                              color: "white",
                                                              whiteSpace: "pre-wrap",
                                                          }}
                                                      >
                                                          <b>
                                                              {item.name.toUpperCase()}
                                                              {isComingSoon ? " (COMING SOON)" : ""}
                                                          </b>
                                                      </Typography>
                                                  }
                                                  position="bottom"
                                              />
                                          </ImageListItem>
                                      );
                                  })
                                : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                      <ImageListItem key={item}>
                                          <Skeleton variant="rect" width="100%" height="100%" />
                                      </ImageListItem>
                                  ))}
                        </ImageList>
                    </Box>
                </Box>

                <Box mb={3} mt={4}>
                    <Box mb={2}>
                        <Typography
                            variant="h3"
                            color="textPrimary"
                            align="left"
                            style={{ paddingRight: verbalTheme.spacing(3) }}
                        >
                            Top 10 Curriculums
                        </Typography>
                    </Box>
                    <ImageList
                        id="top-curriculums"
                        gap={10}
                        className={isMobile ? classes.imageList : ""}
                        cols={isTablet ? (isMobile ? 3.5 : 3) : isMobile ? 1.5 : 5}
                    >
                        {topTen.length > 0 && !loading
                            ? topTen.map((item) => {
                                  const isComingSoon = true; //item.StoryCollection?.length < 1;
                                  return (
                                      <ImageListItem key={item.id}>
                                          {isComingSoon ? (
                                              <div
                                                  style={{
                                                      cursor: "not-allowed",
                                                      height: "100%",
                                                      width: "100%%",
                                                      margin: "auto",
                                                      border: "1px solid black",
                                                      borderRadius: verbalTheme.spacing(1),
                                                      display: "flex",
                                                      justifyContent: "center",
                                                  }}
                                              >
                                                  <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      height="75%"
                                                      viewBox="0 0 24 24"
                                                      width="75%"
                                                      fill={verbalTheme.palette.secondary.main}
                                                  >
                                                      <path d="M0 0h24v24H0z" fill="none" />
                                                      <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                                                  </svg>
                                              </div>
                                          ) : (
                                              <Box
                                                  minHeight="100%"
                                                  p={2}
                                                  borderRadius={10}
                                                  textAlign="center"
                                                  bgcolor={`${getColourClass({ colour: item.colour })}`}
                                                  onClick={() =>
                                                      history.push({
                                                          pathname: `/explore/curriculum/${item.id}`,
                                                          state: {
                                                              from: `${"/explore"}`,
                                                          },
                                                      })
                                                  }
                                                  style={{
                                                      cursor: "pointer",
                                                  }}
                                              />
                                          )}

                                          <ImageListItemBar
                                              style={{ textAlign: "center" }}
                                              className={classes.titleBarBottom}
                                              classes={{
                                                  titleWrap: classes.titleWrap,
                                              }}
                                              title={
                                                  <Typography
                                                      style={{
                                                          color: "white",
                                                          whiteSpace: "pre-wrap",
                                                      }}
                                                  >
                                                      <b>
                                                          {item.name.toUpperCase()}
                                                          {isComingSoon ? " (COMING SOON)" : ""}
                                                      </b>
                                                  </Typography>
                                              }
                                              position="bottom"
                                          />
                                      </ImageListItem>
                                  );
                              })
                            : // .map((item) => {
                              //       return (
                              //           <ImageListItem key={item.id}>
                              //               <Box
                              //                   minHeight="100%"
                              //                   //minWidth="100%"
                              //                   p={2}
                              //                   bgcolor={`${getColourClass({ colour: item.colour })}`}
                              //                   onClick={() =>
                              //                       history.push({
                              //                           pathname: `/explore/curriculum/${item.id}`,
                              //                           state: {
                              //                               from: `${isNewHome ? "/home" : "/explore"}`,
                              //                           },
                              //                       })
                              //                   }
                              //                   style={{
                              //                       cursor: "pointer",
                              //                   }}
                              //               />
                              //               <ImageListItemBar
                              //                   className={classes.titleBarBottom}
                              //                   title={
                              //                       <Typography
                              //                           style={{
                              //                               fontWeight: "bold",
                              //                               color: "white",
                              //                               whiteSpace: "pre-line",
                              //                           }}
                              //                       >
                              //                           {item.name}
                              //                       </Typography>
                              //                   }
                              //                   position="bottom"
                              //               />
                              //           </ImageListItem>
                              //       );
                              //   })
                              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                                  <ImageListItem key={item}>
                                      <Skeleton variant="rect" width="100%" height="100%" />
                                  </ImageListItem>
                              ))}
                    </ImageList>
                </Box>
            </>
        </Box>
    );
};
