import { Avatar, Box, Button, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { VPill } from "../../core/components/VPill";
import { useMobile } from "../../hooks/useMobile";
import { verbalTheme } from "../layout/themes/verbal.theme";

export const getColourCode = (colour: string): string => {
    switch (colour) {
        case "Red":
            return "#CA2D23";
        case "Orange":
            return "#DD752A";
        case "Yellow":
            return "#D3B619";
        case "Green":
            return "#78AF1F";
        case "Cyan":
            return "#27B5BE";
        case "Blue":
            return "#6361B2";
        case "Magneta":
            return "#AA3BA6";
        case "Crimson":
            return "#D33057";
        case "Silver":
            return "#818390";
        case "Bronze":
            return "#A77A50";
        case "BrightYellow":
            return "#FFF700";
        case "Aqua":
            return "#0DFFFF";
        case "Purple":
            return "#AE0CFF";
        case "BrightOrange":
            return "#FF7A15";
        case "BrightRed":
            return "#FF0000";
        case "LightGreen":
            return "#007919";
        case "NightBlue":
            return "#033F77";
        default:
            return "";
    }
};

type HomeViewItemProps = {
    title?: string;
    description?: string | null;
    colour?:
        | "Red"
        | "Orange"
        | "Yellow"
        | "Green"
        | "Cyan"
        | "Blue"
        | "Magneta"
        | "Crimson"
        | "Silver"
        | "Bronze"
        | "BrightYellow"
        | "Aqua"
        | "Purple"
        | "BrightOrange"
        | "BrightRed"
        | "LightGreen"
        | "NightBlue";
    ageRange?: string | null;
};

export const HomeViewItem: React.FC<HomeViewItemProps> = (props: HomeViewItemProps): JSX.Element => {
    const { title, colour, description, ageRange } = props;
    const { isTablet, isMobile } = useMobile();

    const [shortDesc, setShortDesc] = useState<string>();
    const [restDesc, setRestDesc] = useState<string>();
    const [showMoreDesc, setShowMoreDesc] = useState<boolean>(false);

    const hasShade = colour
        ? ["BrightYellow", "Aqua", "Purple", "BrightOrange", "BrightRed", "LightGreen", "NightBlue"].includes(colour)
        : false;

    const shadeList = [];
    if (colour && hasShade) {
        switch (colour) {
            case "BrightYellow":
                shadeList.push("#FFF023", "#FFF042", "#FFF55D", "#FFFD75", "#FCFF8B");
                break;
            case "Aqua":
                shadeList.push("#3DF9FF", "#61FFFB", "#86FFF5", "#A8FFEC", "#C0FFEA");
                break;
            case "Purple":
                shadeList.push("#BA33FF", "#B74DFF", "#C56DFF", "#D691FF", "#E1ADFF");
                break;
            case "BrightOrange":
                shadeList.push("#FF8735", "#FF9F57", "#FFB570", "#FFC287", "#FFC787");
                break;
            case "BrightRed":
                shadeList.push("#FF292F", "#FF4550", "#FF6670", "#FF848D", "#FFA1AC");
                break;
            case "LightGreen":
                shadeList.push("##008F1E", "#009C21", "#00C529", "#00ED32", "#00FF35");
                break;
            case "NightBlue":
                shadeList.push("#044F93", "#055AA9", "#0668C3", "#077E2", "#0781F1");
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (description) {
            setShortDesc(description.split(/\s+/).slice(0, 20).join(" "));
            setRestDesc(description.split(/\s+/).slice(20).join(" "));
        }
    }, [description]);

    return (
        <Box px={!isTablet && isMobile ? 2 : 4} pt={0} pb={0} height="100%">
            <Box>
                <Typography variant="h2" color="textPrimary" align="left" gutterBottom>
                    {title ? title : <Skeleton />}
                </Typography>
                {ageRange && (
                    <Box mb={3}>
                        <VPill label={`Age range ${ageRange} years`} />
                    </Box>
                )}
            </Box>

            <>
                <Box
                    ml={isMobile ? "-16px" : 0}
                    mr={isMobile ? "-32px" : 0}
                    px={2}
                    pl={!isTablet && isMobile ? 2 : isTablet ? 2 : 0}
                    minWidth={"100%"}
                    justifyContent={"center"}
                >
                    <Avatar
                        style={{
                            color: verbalTheme.palette.getContrastText(getColourCode(colour ?? "Red")),
                            backgroundColor: getColourCode(colour ?? "Red"),
                            margin: verbalTheme.spacing(2),
                            marginLeft: `${isTablet ? verbalTheme.spacing(2) : 0}`,
                            marginTop: "0",
                            paddingLeft: "0",
                            float: "left",
                            height: verbalTheme.spacing(15),
                            width: verbalTheme.spacing(15),
                        }}
                    >
                        {title?.trim()?.split(" ")?.[0]?.[0]}
                        {title?.trim()?.split(" ")?.[1]?.[0]}
                    </Avatar>

                    <Box pt={0} px={2}>
                        <Typography>
                            {shortDesc ? (
                                <>
                                    {shortDesc}
                                    {restDesc ? (
                                        <>
                                            <span style={{ display: `${showMoreDesc ? "none" : "inline"}` }}>...</span>
                                            <span
                                                style={{ display: `${showMoreDesc ? "inline" : "none"}` }}
                                            >{` ${restDesc}`}</span>
                                            <Button
                                                size="small"
                                                style={{
                                                    display: "flex",
                                                    marginTop: verbalTheme.spacing(2),
                                                    marginLeft: "auto",
                                                    marginRight: "auto",
                                                }}
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setShowMoreDesc(!showMoreDesc)}
                                            >
                                                Read {showMoreDesc ? "Less" : "More"}
                                            </Button>
                                        </>
                                    ) : null}
                                </>
                            ) : (
                                <Skeleton />
                            )}
                        </Typography>
                    </Box>
                </Box>
            </>
        </Box>
    );
};
