import React, { useState, useEffect } from "react";
import {
    AppBar,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    makeStyles,
    Toolbar,
    Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import VerbalLogo from "../../img/VerbalLogo";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import {
    BookmarkBorder,
    //Dashboard,
    ExitToApp,
    Explore,
    Home,
    LibraryBooks,
    People,
    SchoolOutlined,
    Settings,
    Domain,
} from "@material-ui/icons";
import clsx from "clsx";
import { activeRoute, categoryProps } from "./Navigator";
import { ContactSupportOutlined } from "@material-ui/icons";
import { useAuth } from "../../contexts/auth.context";
import largeV from "../../img/large-v-nav.svg";
import { useOrganisation } from "../../hooks/useOrganisation";
import { getCustomerPortalUrl } from "../../client/api/public/stripe";
import { SubscriptionContactDialog } from "../organisation/SubscriptionContactDialog";
import { SubscriptionExpiredDialog } from "../organisation/SubscriptionExpiredDialog";

export const MobileNavigator = (): JSX.Element => {
    const { user, hasPermission, logout } = useAuth();

    const [openNav, setOpenNav] = useState<boolean>(false);
    // Subscription Dialog
    const { organisation, getOrganisation } = useOrganisation();

    useEffect(() => {
        if (user) {
            getOrganisation(user.id);
        }
    }, [user]);
    const [subDialogOpen, setSubDialogOpen] = useState<boolean>(false);
    const [subExpiredDialogOpen, setSubExpiredDialogOpen] = useState<boolean>(false);

    const handleMyAccount = async () => {
        const isAdmin = user?.Role.name === "Admin";
        if (
            isAdmin &&
            organisation &&
            (organisation.subscriptionStatus === "active" || organisation?.subscriptionStatus === "trialing") &&
            organisation.stripeCustomerId
        ) {
            await getCustomerPortalUrl(organisation.stripeCustomerId).then(({ url }) => window.open(url, "_blank"));
        } else {
            setSubDialogOpen(true);
        }
    };

    const categories = [
        {
            id: "Menu",
            children: [
                { id: "Home", icon: <Home />, path: "/home", permission: "DashboardView" },
                { id: "Explore", icon: <Explore />, path: "/explore", permission: "DashboardView" },
                //{ id: "Dashboard", icon: <Dashboard />, path: "/dashboard", permission: "DashboardView" },
                user?.isSchool
                    ? { id: "Classrooms", icon: <SchoolOutlined />, path: "/classrooms", permission: "ClassroomView" }
                    : {
                          id: "Programmes",
                          icon: <BookmarkBorder />,
                          path: "/programmes",
                          permission: "ProgrammeViewAll",
                      },
                {
                    id: "Add User",
                    icon: <People />,
                    path: "/add-users",
                    permission: "SettingsView",
                },
                { id: "Organisations", icon: <Domain />, path: "/organisations", permission: "OrganisationViewAll" },
            ] as categoryProps[],
        },
        {
            id: "Account",
            children: [
                { id: "User Accounts", icon: <Settings />, path: "/users", permission: "SettingsView" },
            ] as categoryProps[],
        },
    ];

    const useStyles = makeStyles((theme) => ({
        background: {
            height: "100%",
            width: "100%",
            position: "fixed",
            top: 0,
            right: 0,
            backgroundImage: `url(${largeV})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            backgroundColor: theme.palette.secondary.main,
            color: "white",
            zIndex: 1000,
            transition: "transform .8s cubic-bezier(0.86, 0, 0.07, 1)",
            overflow: "hidden",
        },
        toolbar: theme.mixins.toolbar,
        list: {
            width: "100%",
            paddingTop: "2rem",
            alignContent: "center",
        },
        item: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            color: "rgba(255, 255, 255, 0.7)",
            "&:hover,&:focus": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
        },
        itemActiveItem: {
            color: theme.palette.primary.main,
            backgroundColor: "rgba(255, 255, 255, 0.08)",
        },
        itemPrimary: {
            fontSize: "inherit",
            fontWeight: 700,
        },
        itemIcon: {
            minWidth: "auto",
            marginRight: theme.spacing(3),
        },
    }));

    const classes = useStyles();

    return (
        <Hidden smUp>
            <AppBar position="fixed" elevation={0} color={"secondary"}>
                <Toolbar style={{ justifyContent: "space-between", margin: "1rem" }}>
                    <div>
                        <VerbalLogo width="50%" color={"white"} />
                    </div>
                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => setOpenNav(!openNav)}>
                        {openNav ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            {openNav && (
                <>
                    <div className={classes.background}>
                        <div className={classes.toolbar} />
                        <List disablePadding component="nav" className={classes.list}>
                            {categories.map(({ id, children }) => (
                                <React.Fragment key={id}>
                                    {children.map(
                                        ({ id: childId, icon, path, permission }) =>
                                            hasPermission(permission) && (
                                                <ListItem
                                                    key={`li_${id}`}
                                                    component={Link}
                                                    to={path}
                                                    button
                                                    onClick={() => setOpenNav(false)}
                                                    className={clsx(
                                                        classes.item,
                                                        activeRoute(path) && classes.itemActiveItem,
                                                    )}
                                                >
                                                    <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                                    <ListItemText classes={{ primary: classes.itemPrimary }}>
                                                        {childId}
                                                    </ListItemText>
                                                </ListItem>
                                            ),
                                    )}
                                </React.Fragment>
                            ))}
                            {user && user.isSchool && !user.isVerbal && (
                                <ListItem onClick={() => handleMyAccount()} button className={clsx(classes.item)}>
                                    <ListItemIcon className={classes.itemIcon}>
                                        <LibraryBooks />
                                    </ListItemIcon>
                                    <ListItemText classes={{ primary: classes.itemPrimary }}>My Account</ListItemText>
                                </ListItem>
                            )}

                            <ListSubheader style={{ marginTop: "2rem" }}>
                                <Typography variant="caption" style={{ color: "rgba(255, 255, 255, 0.7)" }}>
                                    ABOUT US
                                </Typography>
                            </ListSubheader>

                            <ListItem
                                button
                                className={clsx(classes.item)}
                                onClick={() => {
                                    if (window.FreshworksWidget) {
                                        window.FreshworksWidget("clear", "ticketForm");
                                        window.FreshworksWidget("identify", "ticketForm", {
                                            name: user?.firstName,
                                            email: user?.email,
                                        });
                                        setOpenNav(false);
                                        window.FreshworksWidget("open");
                                    }
                                }}
                            >
                                <ListItemIcon className={classes.itemIcon}>
                                    <ContactSupportOutlined />
                                </ListItemIcon>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Contact Us</ListItemText>
                            </ListItem>

                            <ListItem onClick={logout} button className={clsx(classes.item)}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <ExitToApp />
                                </ListItemIcon>
                                <ListItemText classes={{ primary: classes.itemPrimary }}>Logout</ListItemText>
                            </ListItem>
                        </List>
                    </div>
                </>
            )}
            <SubscriptionContactDialog open={subDialogOpen} setOpen={setSubDialogOpen} />
            <SubscriptionExpiredDialog open={subExpiredDialogOpen} setOpen={setSubExpiredDialogOpen} />
        </Hidden>
    );
};
