import React from "react";
import Button from "@material-ui/core/Button";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { Box, Link, ThemeProvider } from "@material-ui/core";
import { SurveyDialogView, useSurveyDialog } from "../../public/hooks/surveyDialog.context";
import { VDialog } from "../../../core/components/VDialog";
import SurveyImg from "../../../img/survey-img.svg";

export const SurveyDialogMain = (): JSX.Element => {
    const { participantId, programmeId, preSurveyId, changeView, shouldOpenDialog, openDialog, setHasOpenedSurvey } =
        useSurveyDialog();
    const surveyURL = `https://www.surveymonkey.co.uk/r/${preSurveyId}?partId=${participantId}&progId=${programmeId}`;

    return (
        <ThemeProvider theme={verbalTheme}>
            <VDialog
                icon={<img src={SurveyImg} />}
                open={openDialog}
                header={"Pre Programme Survey"}
                content={"Please take some time to complete the pre programme survey before sessions start"}
                actions={
                    <>
                        <Box mb={2}>
                            <Button
                                onClick={() => {
                                    shouldOpenDialog(false);
                                    setHasOpenedSurvey(true);
                                }}
                                variant="contained"
                                color="primary"
                                size={"large"}
                                target="_blank"
                                href={surveyURL}
                            >
                                Take Survey
                            </Button>
                        </Box>
                        <Button onClick={() => shouldOpenDialog(false)} variant="text" color="primary" size={"large"}>
                            {`I'll take it later`}
                        </Button>
                    </>
                }
                bottomContent={
                    <>
                        <Box textAlign={"center"} pb={4}>
                            <Link
                                onClick={() => {
                                    changeView(SurveyDialogView.OptOut);
                                }}
                                component={"button"}
                                variant={"body2"}
                                color={"textPrimary"}
                            >
                                Opt Out
                            </Link>
                        </Box>
                    </>
                }
            />
        </ThemeProvider>
    );
};
export default SurveyDialogMain;
