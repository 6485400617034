import { Button, TextField, InputAdornment, IconButton, Box, Typography, Container } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Formik, Form, FormikHelpers } from "formik";
import React, { useState } from "react";
import * as yup from "yup";
import { checkEmail } from "../../client/api/public/organisation";

export type SignUpInputs = {
    name: string;
    email: string;
    password: string;
};

type SignUpProps = {
    onSignUp: (values: SignUpInputs) => void;
    initialOrg?: string;
    initialEmail?: string;
    initialPassword?: string;
};

export const SignUp: React.FC<SignUpProps> = (props: SignUpProps) => {
    const { onSignUp, initialOrg = "", initialEmail = "", initialPassword = "" } = props;

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const onSubmit = (values: SignUpInputs, helpers: FormikHelpers<SignUpInputs>) => {
        const { setSubmitting, setFieldError } = helpers;
        checkEmail(values.name, values.email)
            .then(() => onSignUp(values))
            .catch(async (error) => {
                // Check if 400 error message was thrown due to org or email issue
                // Then, set error on correct field
                const response = await error.json();
                if (response.message.includes("organisation")) {
                    setFieldError(
                        "name",
                        "This Organisations has already been created and set up. Please contact your primary Admin to get an account",
                    );
                }
                if (response.message.includes("Email")) {
                    setFieldError("email", "Email Address already in use, please use a different email or sign in");
                }
                setSubmitting(false);
            });
    };

    return (
        <>
            <Container maxWidth={"sm"}>
                <Box mb={4}>
                    <Typography variant="h2" component={"h1"} color="textPrimary" align="left">
                        Sign Up
                    </Typography>
                </Box>
                <Formik
                    initialValues={{
                        name: initialOrg,
                        email: initialEmail,
                        password: initialPassword,
                    }}
                    onSubmit={onSubmit}
                    validationSchema={yup.object({
                        name: yup.string().required("Organisation name is required"),
                        email: yup.string().email("A valid email is required").required("Email is required").max(100),
                        password: yup
                            .string()
                            .min(8, "Please enter a password with 8 or more characters")
                            .matches(/[a-z]/, "Password must contain at least one lower case letter")
                            .matches(/[A-Z]/, "Password must contain at least one upper case letter")
                            .matches(/\d/, "Password must contain at least one digit")
                            .matches(
                                /[^A-z\d]/,
                                "Password must contain at least one special character, except underscore (_)",
                            )
                            .required("Password is required"),
                    })}
                    validateOnChange={false}
                >
                    {({ submitForm, errors, values, handleChange, touched, isSubmitting }) => {
                        return (
                            <Form>
                                <FormControl fullWidth margin={"normal"}>
                                    <TextField
                                        autoFocus
                                        fullWidth={true}
                                        id="name"
                                        label="Organisation Name"
                                        variant="outlined"
                                        value={values.name}
                                        onChange={handleChange}
                                        helperText={errors.name && touched.name && errors.name}
                                        error={errors.name ? true : false}
                                        required
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin={"normal"}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        variant="outlined"
                                        value={values.email}
                                        onChange={handleChange}
                                        helperText={errors.email && touched.email && errors.email}
                                        error={errors.email ? true : false}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </FormControl>
                                <FormControl fullWidth margin={"normal"}>
                                    <TextField
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        variant="outlined"
                                        value={values.password}
                                        onChange={handleChange}
                                        type={showPassword ? "text" : "password"}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                        onMouseDown={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        autoComplete="on"
                                        helperText={errors.password && touched.password && errors.password}
                                        error={errors.password ? true : false}
                                    />
                                </FormControl>

                                <FormControl fullWidth margin={"normal"}>
                                    <Typography style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                        Input fields marked with an asterisk (*) are required
                                    </Typography>
                                </FormControl>

                                <FormControl fullWidth margin={"normal"}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        onClick={submitForm}
                                        fullWidth
                                    >
                                        Sign Up
                                    </Button>
                                </FormControl>
                            </Form>
                        );
                    }}
                </Formik>
            </Container>
        </>
    );
};
export default SignUp;
