import React from "react";
import { MappedStoryType } from "../../models/modelTypes";
import { Box, Grid, Typography } from "@material-ui/core";
import { VList } from "../../core/components/VList";
import { VListItem, VListItemType } from "../../core/components/VListItem";
import { VModule } from "../../core/components/VModule";
import { ParticipantCollectionsView } from "./ParticipantCollections";

type CollectionStoriesProps = {
    stories?: MappedStoryType[];
    collectionName?: string;
    setView: (view: ParticipantCollectionsView) => void;
    setStory: (story: MappedStoryType) => void;
};

export const CollectionStories: React.FC<CollectionStoriesProps> = (props: CollectionStoriesProps): JSX.Element => {
    const { stories, collectionName, setView, setStory } = props;

    return (
        <>
            <Box mb={4}>
                <Typography variant="h4">{collectionName}</Typography>
                <Typography color="textSecondary" variant="body2">
                    {stories?.length} Stories{" "}
                </Typography>
            </Box>

            <Grid item xs={12}>
                <VModule padded={false}>
                    <VList color="primary">
                        {stories &&
                            stories.map((story: MappedStoryType, key: number) => {
                                // don't show divider on last list item
                                let showDivider = true;
                                if (key === stories.length - 1) {
                                    showDivider = false;
                                }
                                return (
                                    <VListItem
                                        divider={showDivider}
                                        key={key + 1}
                                        type={VListItemType.Primary}
                                        avatar={key + 1}
                                        primaryText={story.Story?.storyName}
                                        secondaryText={`By ${story.Story?.author}`}
                                        onClick={() => {
                                            setStory(story);
                                            setView(ParticipantCollectionsView.StorySynopsis);
                                        }}
                                    />
                                );
                            })}
                    </VList>
                </VModule>
            </Grid>
        </>
    );
};
