import { Button } from "@material-ui/core";
import { RelatedQuestion } from "common/build/prisma/client";
import React from "react";

type QConditionButtonProps = {
    questionId: number;
    rq: Partial<RelatedQuestion>;
    handler: (id: number, rq: Partial<RelatedQuestion>) => void;
    open?: boolean;
};

export const QConditionButton = (props: QConditionButtonProps): JSX.Element => {
    const { open, questionId, rq, handler } = props;

    return (
        <Button
            size="small"
            variant={open ? "contained" : "outlined"}
            color="primary"
            onClick={() => handler(questionId, rq)}
        >
            {rq.condition === "Yes" ? "If answer is YES" : "If answer is NO"}
        </Button>
    );
};
