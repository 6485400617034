import React from "react";
import { Box, BoxProps } from "@material-ui/core";
import { createStyles, makeStyles, ThemeProvider } from "@material-ui/core/styles";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";
import { publicTheme } from "../../components/layout/themes/public.theme";

export enum CollectionCardType {
    Shade1 = "Shade1",
    Shade2 = "Shade2",
    Shade3 = "Shade3",
}

const LightTheme: React.FC = ({ children }) => <ThemeProvider theme={verbalTheme}>{children}</ThemeProvider>;
const DarkTheme: React.FC = ({ children }) => <ThemeProvider theme={publicTheme}>{children}</ThemeProvider>;
const DarkishTheme: React.FC = ({ children }) => (
    <ThemeProvider
        theme={{
            ...publicTheme,
            palette: {
                ...publicTheme.palette,
                background: { ...publicTheme.palette.background, paper: publicTheme.palette.secondary.light },
            },
        }}
    >
        {children}
    </ThemeProvider>
);
const LightishTheme: React.FC = ({ children }) => (
    <ThemeProvider
        theme={{
            ...verbalTheme,
            palette: {
                ...verbalTheme.palette,
                background: { ...verbalTheme.palette.background, paper: verbalTheme.palette.secondaryShade.main },
            },
        }}
    >
        {children}
    </ThemeProvider>
);

const useStyles = makeStyles(
    createStyles({
        card: {
            backgroundColor: verbalTheme.palette.common.white,
            "&:hover": {
                cursor: "pointer",
            },
        },
    }),
);

type CollectionCardProps = BoxProps & {
    type?: CollectionCardType;
    children: React.ReactNode;
    padded?: boolean;
};

export const CollectionCard: React.FC<CollectionCardProps> = (props: CollectionCardProps): JSX.Element => {
    const { type, children, padded = true, ...rest } = props;

    const classes = useStyles();

    let ThemeComponent;
    switch (type) {
        // Light
        case CollectionCardType.Shade1:
            ThemeComponent = LightishTheme;
            break;
        // darkish
        case CollectionCardType.Shade2:
            ThemeComponent = DarkishTheme;
            break;
        /// v dark
        case CollectionCardType.Shade3:
            ThemeComponent = DarkTheme;
            break;
        default:
            ThemeComponent = LightTheme;
            break;
    }

    return (
        <ThemeComponent>
            <Box p={padded ? 3 : 0} className={classes.card} {...rest}>
                {children}
            </Box>
        </ThemeComponent>
    );
};
