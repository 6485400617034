import React from "react";
import { useHome } from "../hooks/home.context";
import { Typography } from "@material-ui/core";

export const Confirmation = (): JSX.Element => {
    const { publicUser, details, isEmailUpdated, signInLinkSent } = useHome();

    return (
        <>
            {publicUser &&
                signInLinkSent &&
                (isEmailUpdated ? (
                    <>
                        <Typography variant="h5" component="h1" align="center">
                            {`Your email address has been updated, we've sent your personal sign in link to ${details?.email}. 
                         `}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h5" component="h1" align="center">
                            {`Hi ${publicUser?.firstName}, your personal sign in link has been sent to ${publicUser?.email} `}
                        </Typography>
                    </>
                ))}

            {!publicUser && (
                <Typography variant="h5" component="h1" align="center">
                    An error has occurred processing your sign in, please contact your programme organiser
                </Typography>
            )}
        </>
    );
};
export default Confirmation;
