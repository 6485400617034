import React, { FC, useState } from "react";
import { HeadlineFigureWidget } from "../HeadlineFigureWidget";
import { Button, createStyles, makeStyles } from "@material-ui/core";
import { TutorialVideosDialog } from "../../../../support/TutorialVideosDialog";
import { verbalTheme } from "../../../../layout/themes/verbal.theme";

export const TutorialVideosWidget: FC<{ minHeight?: string }> = ({ minHeight = "235px" }): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false);

    const useStyles = makeStyles(
        createStyles({
            imgPlacment: {
                height: "75%",
                width: "75%",
                margin: "auto",
            },
        }),
    );
    const classes = useStyles();

    return (
        <>
            <HeadlineFigureWidget minHeight={minHeight} header={" "}>
                <div className={classes.imgPlacment}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24"
                        height="75%"
                        viewBox="0 0 24 24"
                        width="75%"
                        fill={verbalTheme.palette.primary.main}
                    >
                        <g>
                            <rect fill="none" height="24" width="24" />
                        </g>
                        <g>
                            <g>
                                <path d="M9.19,6.35c-2.04,2.29-3.44,5.58-3.57,5.89L2,10.69l4.05-4.05c0.47-0.47,1.15-0.68,1.81-0.55L9.19,6.35L9.19,6.35z M11.17,17c0,0,3.74-1.55,5.89-3.7c5.4-5.4,4.5-9.62,4.21-10.57c-0.95-0.3-5.17-1.19-10.57,4.21C8.55,9.09,7,12.83,7,12.83 L11.17,17z M17.65,14.81c-2.29,2.04-5.58,3.44-5.89,3.57L13.31,22l4.05-4.05c0.47-0.47,0.68-1.15,0.55-1.81L17.65,14.81 L17.65,14.81z M9,18c0,0.83-0.34,1.58-0.88,2.12C6.94,21.3,2,22,2,22s0.7-4.94,1.88-6.12C4.42,15.34,5.17,15,6,15 C7.66,15,9,16.34,9,18z M13,9c0-1.1,0.9-2,2-2s2,0.9,2,2s-0.9,2-2,2S13,10.1,13,9z" />
                            </g>
                        </g>
                    </svg>
                </div>

                <Button size={"small"} variant="contained" color="primary" onClick={() => setOpen(true)}>
                    Set Up
                </Button>
            </HeadlineFigureWidget>
            <TutorialVideosDialog open={open} setOpen={setOpen} />
        </>
    );
};
