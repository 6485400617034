import * as yup from "yup";
import { AsyncSearch } from "../../core/components/AsyncSearch";
import { list as listRoles } from "../../client/api/role";
import { email } from "../../core/components/crud/decorators";
import { RoleName } from "common/build/prisma/client";
import { UserType, RoleType as RoleWithPermissions } from "../../models/modelTypes";
import { useAuth } from "../../contexts/auth.context";
import { RoleType } from "./entities/RoleType";
import { Model } from "../../core/components/crud/Crud";

const isUserTheCurrentlyLoggedInUser = (userId: number) => {
    const { user } = useAuth();

    return userId === user?.id;
};

export const usersCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Name",
        inForm: false,
        decorator: (value: string, property: string, user: UserType): string => `${user.firstName} ${user.lastName}`,
    },
    firstName: {
        label: "First Name",
        inTable: false,
        inputProps: {
            required: true,
        },
        validation: yup.string().required("First Name is required"),
    },
    lastName: {
        label: "Surname",
        inTable: false,
        inputProps: {
            required: true,
        },
        validation: yup.string().required("Surname is required"),
    },
    email: {
        label: "Email",
        validation: yup.string().email("A valid email is required").required("Email is required").max(100),
        inputProps: {
            required: true,
        },
        decorator: (value: string): JSX.Element => email(value),
    },
    roleId: {
        label: "Role",
        validation: yup.string().required("Role is required"),
        InputComponent: AsyncSearch,
        inputProps: {
            label: "Role",
            property: "roleId",
            required: true,
            onSearchChange: async (): Promise<{ items: RoleWithPermissions[]; total: number }> => {
                const { items, total } = await listRoles();
                return new Promise<{ items: RoleWithPermissions[]; total: number }>((resolve) =>
                    resolve({
                        items: items.map(
                            (item) =>
                                ({ ...item, name: RoleType[item.name] as unknown as RoleName }) as RoleWithPermissions,
                        ),
                        total,
                    }),
                );
            },
        },
        decorator: (value: string, property: string, user: UserType): string => RoleType[user.Role.name],
        disabled: (data: { id: number }): boolean => isUserTheCurrentlyLoggedInUser(data.id),
    },
} as Model<UserType>;
