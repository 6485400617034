import React, { useEffect, useState } from "react";
import { Grid, Typography, Box, Container } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { Breadcrumb } from "../Breadcrumb";
import { useFetch } from "./contexts/fetch.context";
import { useCrud } from "./contexts/crud.context";
import Spinner from "../../../components/layout/Spinner";
import { Error } from "../Error";

export type ViewProps = {
    labelProperty?: string;
};

export const View = <T extends Record<string, unknown>>(props: ViewProps): JSX.Element => {
    const { labelProperty } = props;
    const { item, fetchItem } = useFetch<T>();
    const { noun } = useCrud<T>();

    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setLoading(true);
        fetchItem(parseInt(id))
            .then(() => {
                setError(false);
            })
            .catch(() => {
                setError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error description={`Unable to load ${noun}`} />;
    }

    return (
        <>
            <Container maxWidth={"md"}>
                <Box mb="3rem">
                    <Breadcrumb
                        current={
                            labelProperty && item && item[labelProperty]
                                ? `Viewing ${item[labelProperty]}`
                                : `Viewing ${noun}`
                        }
                    />
                    <Typography variant="h1" color="textPrimary" align="left">
                        {`Viewing ${noun}`}
                    </Typography>
                </Box>
                <Grid item xs={12}>
                    <>
                        <Typography variant="h3" color="textPrimary" align="left">
                            Placeholder View Screen
                        </Typography>
                        <Typography variant="h4" color="textPrimary" align="left">
                            {`${noun}: ${labelProperty && item ? item[labelProperty] : ""}`}
                        </Typography>
                    </>
                    )
                </Grid>
            </Container>
        </>
    );
};
export default View;
