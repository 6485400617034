import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Link as MLink } from "@material-ui/core";
import { LinkBaseProps } from "@material-ui/core/Link/Link";
import { TypographyProps } from "@material-ui/core/Typography";

export const VTextLink: FC<
    { to: string } & Partial<
        Omit<
            LinkBaseProps & {
                TypographyClasses?: TypographyProps["classes"];
                underline?: "none" | "hover" | "always";
            },
            "href"
        >
    >
> = ({ to, children, ...rest }) => (
    <Link to={to}>
        <MLink variant="body1" component="span" {...rest}>
            {children}
        </MLink>
    </Link>
);
