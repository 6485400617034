import { OrganisationsList } from "common/build/api-parameters/stats";
import dayjs from "dayjs";

export const organisationsCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Name",
    },
    fullAddress: {
        label: "Address",
        decorator: (value: string, property?: string, organisation?: OrganisationsList): string => {
            return [
                organisation?.addressLine1,
                organisation?.addressLine2,
                organisation?.city,
                organisation?.postcode,
                organisation?.country,
            ]
                .filter(Boolean)
                .join(", ");
        },
    },
    tier: {
        label: "Tier",
    },
    totalUsers: {
        label: "No. Users",
    },
    totalActiveUsers: {
        label: "Total Active Users",
    },
    lastAccessed: {
        label: "Last Login",
        decorator: (value: string, property?: string, organisation?: OrganisationsList): string => {
            if (!organisation?.dateUserLastAccessed) return "No users have logged in";
            return dayjs(organisation?.dateUserLastAccessed).format("D MMM YYYY [at] HH:mm");
        },
    },
    totalParticipantsInClassrooms: {
        label: "Total Children",
    },
    totalDaysMoodsTracked: {
        label: "Total Mood Tracker Usage",
    },
    subscriptionStatus: {
        label: "Subscription Status",
        decorator: (value: string, property?: string, organisation?: OrganisationsList): string => {
            if (!organisation?.subscriptionStatus) return "No Subscription";
            return organisation?.subscriptionStatus.charAt(0).toUpperCase() + organisation?.subscriptionStatus.slice(1);
        },
    },
    trialEnd: {
        label: "Trial End Date",
        decorator: (value: string, property?: string, organisation?: OrganisationsList): string => {
            if (!organisation?.trialEndDate) return "No Trial End Date";
            return dayjs(organisation?.trialEndDate * 1000).format(" D MMM YYYY [at] HH:mm");
        },
    },
};
