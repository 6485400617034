import React, { PropsWithChildren, ComponentProps, ChangeEvent } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";
import { ExpandMore } from "@material-ui/icons";

type VAccordionItemProps = PropsWithChildren<{
    title: string;
    key: string;
    expanded?: boolean;
    defaultExpanded?: boolean;
    showHeader?: boolean;
    onChange?: (event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => void;
}>;

const accordionBorder = "1px solid rgba(255,255,255,0.1)";

const useStyles = makeStyles({
    root: {
        width: "100%",
        borderRadius: 0,
        borderTop: accordionBorder,
        borderBottom: accordionBorder,
        [verbalTheme.breakpoints.up("md")]: {
            borderRadius: "8px",
            border: accordionBorder,
        },
    },
});

export const VAccordionItem: React.FC<VAccordionItemProps> = (props: VAccordionItemProps) => {
    const classes = useStyles();
    const { title, key, children, expanded, defaultExpanded, showHeader = false, onChange } = props;
    return (
        <Accordion
            defaultExpanded={defaultExpanded ?? true}
            expanded={expanded}
            key={key}
            className={classes.root}
            onChange={onChange}
        >
            {showHeader && (
                <AccordionSummary
                    expandIcon={expanded ? undefined : <ExpandMore />}
                    aria-controls={`${key}-content`}
                    id={`${key}-header`}
                >
                    <Typography>{title}</Typography>
                </AccordionSummary>
            )}
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};

export type AccordionItem = React.ReactElement<ComponentProps<typeof VAccordionItem>>;

type VAccordionProps = {
    children: AccordionItem[] | AccordionItem;
};

export const VAccordion: React.FC<VAccordionProps> = (props: VAccordionProps) => {
    const { children } = props;
    return <div>{children}</div>;
};
