import {
    Box,
    Container,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
} from "@material-ui/core";
import React from "react";
import Add from "../../core/components/crud/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";

export const AddUser = (): JSX.Element => {
    return (
        <>
            <Add />
            <Box p={4}>
                <Container maxWidth={"md"}>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">Permission</TableCell>
                                    <TableCell align="center">Story Admin</TableCell>
                                    <TableCell align="center">Story Publisher</TableCell>
                                    <TableCell align="center">Story Annotator</TableCell>
                                    <TableCell align="center">Story Selector</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center">View Stories</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">View Story Collections</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Create Stories</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Add Story Questions</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Review Stories</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Review Story Collections</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Edit Story Collections</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Publish Stories</TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Publish Story Collection</TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">View Curriculums</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Create Curriculums</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">View Themes</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Create Themes</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Create Story Collections</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center">Edit Stories</TableCell>
                                    <TableCell align="center">
                                        <CheckIcon color="primary" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                    <TableCell align="center">
                                        <CloseIcon color="error" />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <></>
                </Container>
            </Box>
        </>
    );
};
