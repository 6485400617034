import { node } from "prop-types";
import React, { createContext, FC, ReactNode, useCallback, useContext, useState } from "react";
import { getCurriculumsAZ, getTopTen, getVerbalPicks } from "../../../client/api/home";
import { CurriculumType, MappedStoryType, StoryCollectionType } from "../../../models/modelTypes";

interface BrowseContextI {
    topTen: CurriculumType[];
    verbalPicks: CurriculumType[];
    allAlphabetical: CurriculumType[];

    currentCurriculum?: CurriculumType;

    currentCollection?: StoryCollectionType;
    previousCollection?: StoryCollectionType;
    nextCollection?: StoryCollectionType;

    currentStory?: MappedStoryType;
    previousStory?: MappedStoryType;
    nextStory?: MappedStoryType;

    loading: boolean;

    fetchTopTen: () => void;
    fetchVerbalPicks: () => void;
    fetchAtoZ: () => void;

    setCurrentCurriculum: (curriculum?: CurriculumType) => void;

    setCurrentCollection: (collection?: StoryCollectionType) => void;
    setPreviousCollection: (collection?: StoryCollectionType) => void;
    setNextCollection: (collection?: StoryCollectionType) => void;

    setCurrentStory: (story?: MappedStoryType) => void;
    setPreviousStory: (story?: MappedStoryType) => void;
    setNextStory: (story?: MappedStoryType) => void;
}

const BrowseContext = createContext({} as BrowseContextI);

export const BrowseProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [topTen, setTopTen] = useState<CurriculumType[]>([]);
    const [verbalPicks, setVerbalPicks] = useState<CurriculumType[]>([]);
    const [allAlphabetical, setAllAlphabetical] = useState<CurriculumType[]>([]);

    const [currentCurriculum, setCurrentCurriculum] = useState<CurriculumType>();

    const [currentCollection, setCurrentCollection] = useState<StoryCollectionType>();
    const [nextCollection, setNextCollection] = useState<StoryCollectionType>();
    const [previousCollection, setPreviousCollection] = useState<StoryCollectionType>();

    const [currentStory, setCurrentStory] = useState<MappedStoryType>();
    const [previousStory, setPreviousStory] = useState<MappedStoryType>();
    const [nextStory, setNextStory] = useState<MappedStoryType>();

    const [loading, setLoading] = useState<boolean>(false);

    const fetchTopTen = useCallback(() => {
        setLoading(true);
        getTopTen()
            .then((top: CurriculumType[]) => setTopTen(top))
            .finally(() => setLoading(false));
    }, []);

    const fetchVerbalPicks = useCallback(() => {
        setLoading(true);
        getVerbalPicks()
            .then((picks: CurriculumType[]) => setVerbalPicks(picks))
            .finally(() => setLoading(false));
    }, []);

    const fetchAtoZ = useCallback(() => {
        setLoading(true);
        getCurriculumsAZ()
            .then((cs) => setAllAlphabetical(cs))
            .finally(() => setLoading(false));
    }, []);

    return (
        <BrowseContext.Provider
            value={{
                topTen,
                verbalPicks,
                allAlphabetical,
                currentCurriculum,
                currentCollection,
                previousCollection,
                nextCollection,
                currentStory,
                previousStory,
                nextStory,
                loading,
                fetchTopTen,
                fetchVerbalPicks,
                fetchAtoZ,
                setCurrentCurriculum,
                setCurrentCollection,
                setPreviousCollection,
                setNextCollection,
                setCurrentStory,
                setPreviousStory,
                setNextStory,
            }}
        >
            {children}
        </BrowseContext.Provider>
    );
};

BrowseProvider.propTypes = {
    children: node,
};

export const useBrowse = (): BrowseContextI => useContext<BrowseContextI>(BrowseContext);
