import { InputAdornment, TextField } from "@material-ui/core";
import { SearchSharp } from "@material-ui/icons";
import React, { useEffect, useState } from "react";

type SearchProps = {
    className?: string;
    onSearch: (searchTerm: string) => void;
    delay?: number;
};

export const Search = (props: SearchProps): JSX.Element => {
    const { onSearch, delay = 500, className } = props;

    const [query, setQuery] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (query != null) {
            const searchTimeout = setTimeout(() => onSearch(query), delay);

            return () => clearTimeout(searchTimeout);
        }
    }, [query]);

    const handleSearch = (e: { target: { value: string } }) => {
        setQuery(e.target.value);
    };

    return (
        <TextField
            id="search"
            type="search"
            className={className}
            variant={"outlined"}
            fullWidth
            label="Search"
            InputProps={{
                startAdornment: (
                    <InputAdornment position={"start"}>
                        <SearchSharp />
                    </InputAdornment>
                ),
            }}
            onChange={handleSearch}
        />
    );
};
export default Search;
