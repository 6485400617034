import { Prisma } from "common/build/prisma/client";
import { patchData, postData } from "../rest";
import { PublicUserInputFields } from "../../../models/modelTypes";

export const add = async (user: PublicUserInputFields & { email: string; token: string }): Promise<Response> =>
    await postData(`/public/user`, user);

export const update = async (
    id: number,
    editedUser: Partial<Prisma.PublicUserUpdateInput | Prisma.PublicUserUncheckedUpdateInput> & {
        token: string;
        participantId: number | undefined;
    },
): Promise<Response> => await patchData(`/public/user/${id}`, { ...editedUser });
