import { Box, createStyles, Dialog, IconButton, makeStyles, Typography, useMediaQuery } from "@material-ui/core";
import React, { ChangeEvent, useState } from "react";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Close } from "@material-ui/icons";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { VAccordion, VAccordionItem } from "../../core/components/VAccordion";

type TutorialVideosDialogProps = {
    open: boolean;
    setOpen: (open: boolean) => void;
};

export const TutorialVideosDialog = (props: TutorialVideosDialogProps): JSX.Element => {
    const { open, setOpen } = props;
    const fullScreen = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    const [expanded, setExpanded] = useState<Map<string, boolean>>(
        new Map([
            ["about-verbal", true],
            ["add-users", false],
            ["setup-programme", false],
            ["School SETUP", false],
            ["add-facilitators", false],
            ["register-participants", false],
            ["prepare-session", false],
            ["deliver-session", false],
            ["programme-report", false],
            ["bonus-content", false],
        ]),
    );

    const handleExpandChange = (key: string, isExpanded: boolean) => {
        const keys = [...expanded.keys()];
        keys.forEach((k) => {
            if (k === key) {
                expanded.set(k, isExpanded);
            } else {
                expanded.set(k, false);
            }
        });
        setExpanded(new Map(expanded));
    };

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
        }),
    );

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth={"md"} fullScreen={fullScreen}>
            <MuiDialogTitle>
                <Typography variant="h4">Here are some videos to help get you started</Typography>
                <IconButton className={classes.closeButton} aria-label="close-training" onClick={() => setOpen(false)}>
                    <Close />
                </IconButton>
            </MuiDialogTitle>
            <MuiDialogContent>
                <VAccordion>
                    <Box mb={2}>
                        <VAccordionItem
                            title={"What is Verbal Wellbeing"}
                            key="about-verbal"
                            showHeader={true}
                            defaultExpanded={false}
                            expanded={expanded.get("about-verbal")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("about-verbal", isExpanded);
                            }}
                        >
                            <iframe
                                src="https://player.vimeo.com/video/680972593?h=eb594206f1"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="How do I set up my school?"
                            key="School SETUP"
                            defaultExpanded={false}
                            expanded={expanded.get("School SETUP")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("School SETUP", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915183684?h=bf25877b23"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="How do I create a programme for my class?"
                            key="setup-programme"
                            defaultExpanded={false}
                            expanded={expanded.get("setup-programme")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("setup-programme", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915183780?h=cf456cf09e"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="How do I add other teachers in my school to the account?"
                            key="add-users"
                            defaultExpanded={false}
                            expanded={expanded.get("add-users")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("add-users", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915184187?h=fb42f38926"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="How do I add a Facilitators"
                            key="add-facilitators"
                            defaultExpanded={false}
                            expanded={expanded.get("add-facilitators")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("add-facilitators", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915184166?h=81f78d6b44"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="How do I access my bonus content?"
                            key="bonus-content"
                            defaultExpanded={false}
                            expanded={expanded.get("bonus-content")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("bonus-content", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915183924?h=2f8a75a2a7"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="How to register my students"
                            key="register-participants"
                            defaultExpanded={false}
                            expanded={expanded.get("register-participants")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("register-participants", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915183754?h=b53fa2c072"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="Can I prepare a session before delivery?"
                            key="prepare-session"
                            defaultExpanded={false}
                            expanded={expanded.get("prepare-session")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("prepare-session", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915183825?h=f3c5aea8bb"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>

                    <Box mb={2}>
                        <VAccordionItem
                            showHeader={true}
                            title="Can I get reporting data for my programme?"
                            key="programme-report"
                            defaultExpanded={false}
                            expanded={expanded.get("programme-report")}
                            onChange={(_event: ChangeEvent<Record<string, unknown>>, isExpanded: boolean) => {
                                handleExpandChange("programme-report", isExpanded);
                            }}
                        >
                            <iframe
                                title="vimeo-player"
                                src="https://player.vimeo.com/video/915184016?h=b46349df3b"
                                width="100%"
                                height="360"
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </VAccordionItem>
                    </Box>
                </VAccordion>
            </MuiDialogContent>
        </Dialog>
    );
};
