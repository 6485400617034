import { Box, Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { FC, useEffect, useRef } from "react";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import largeV from "../../../img/large-v-nav.svg";
import { CheckCircleOutline, HighlightOffOutlined } from "@material-ui/icons";
import { RoleName } from "common/build/prisma/client";
import { RoleType } from "../entities/RoleType";
import { useAuth } from "../../../contexts/auth.context";
import { useSetTourState } from "../../tour/Store";

type NewUserCardProps = {
    roleName: RoleName;
    isSelected?: boolean;
    index?: number;

    onAdd: (roleName: RoleName) => void;
};

export const NewUserCard: FC<NewUserCardProps> = (props: NewUserCardProps): JSX.Element => {
    const { roleName, onAdd, isSelected, index } = props;
    const { user } = useAuth();

    const setTourState = useSetTourState();

    const refAddBtn = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("addUserBtn", refAddBtn) }));
    }, [refAddBtn]);

    const displayName =
        user?.isSchool && roleName === "ProgrammeCoordinator"
            ? "Teaching Staff"
            : Object.values(RoleType)[Object.keys(RoleType).indexOf(roleName)];

    const useStyles = makeStyles(
        createStyles({
            userCard: {
                color: verbalTheme.palette.common.white,
                padding: verbalTheme.spacing(2),
                backgroundColor: verbalTheme.palette.secondary.main,
                backgroundImage: `url(${largeV})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center bottom",
                borderRadius: verbalTheme.spacing(2),
            },
        }),
    );

    const classes = useStyles();

    return (
        <Box
            display="flex"
            className={classes.userCard}
            height="100%"
            width="100%"
            flexDirection={"column"}
            justifyContent="space-between"
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box
                        display="flex"
                        alignItems={"center"}
                        justifyContent="center"
                        minHeight={verbalTheme.spacing(8)}
                    >
                        <Typography variant="h4" align="center">
                            {displayName}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>View Dashboard</Typography>
                        <CheckCircleOutline />
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>View Programmes</Typography>
                        <CheckCircleOutline />
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>Prepare Sessions</Typography>
                        <CheckCircleOutline />
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>Deliver Sessions</Typography>
                        <CheckCircleOutline />
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>Edit Sessions</Typography>
                        <CheckCircleOutline />
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>Invite Participants</Typography>
                        <CheckCircleOutline />
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>Create Programmes</Typography>
                        <CheckCircleOutline />
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>Invite Support Staff</Typography>
                        {roleName !== "Facilitator" ? <CheckCircleOutline /> : <HighlightOffOutlined color="error" />}
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>View Programme Reports</Typography>
                        {roleName !== "Facilitator" ? <CheckCircleOutline /> : <HighlightOffOutlined color="error" />}
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>
                            Invite {user?.isSchool ? "Teaching Staff" : "Programme Coordinators"}
                        </Typography>
                        {roleName === "Admin" ? <CheckCircleOutline /> : <HighlightOffOutlined color="error" />}
                    </Box>

                    <Box mb={1.5} display="flex" justifyContent="space-between" alignItems="center">
                        <Typography style={{ lineHeight: 1 }}>Invite School Admins</Typography>
                        {roleName === "Admin" ? <CheckCircleOutline /> : <HighlightOffOutlined color="error" />}
                    </Box>
                </Grid>
            </Grid>
            <Box mt={1}>
                <Button
                    ref={refAddBtn}
                    data-tour={index === 1 ? "add-users-role-btn" : undefined}
                    color={"primary"}
                    variant={isSelected ? "outlined" : "contained"}
                    fullWidth
                    onClick={() => onAdd(roleName)}
                >
                    Add
                </Button>
            </Box>
        </Box>
    );
};
