import { Avatar, Box, Card, CardContent, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { VPillPositive } from "../../../core/components/VPill";
import { StoryCollectionType } from "../../../models/modelTypes";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { getColourClass } from "../BrowseHome";
import { useHomeStyles } from "../Home.style";
import { getColourCode } from "../HomeViewItem";
import CollectionIcon from "../../../img/collectionsIcon.png";

type StoryCollectionCardProps = {
    item: StoryCollectionType;
    curriculumId: number;
    curriculumColour?:
        | "Red"
        | "Orange"
        | "Yellow"
        | "Green"
        | "Cyan"
        | "Blue"
        | "Magneta"
        | "Crimson"
        | "Silver"
        | "Bronze"
        | "BrightYellow"
        | "Aqua"
        | "Purple"
        | "BrightOrange"
        | "BrightRed"
        | "LightGreen"
        | "NightBlue";
    index: number;
};

export const StoryCollectionCard = (props: StoryCollectionCardProps): JSX.Element => {
    const { item, curriculumId, index, curriculumColour } = props;

    const [shadeList, setShadeList] = useState<string[]>([]);

    const location = useLocation<Record<string, string>>();
    const history = useHistory();

    const isNewHome = location.state ? (location.state.from.indexOf("/home") > -1 ? true : false) : false;

    useEffect(() => {
        const hasShade = curriculumColour
            ? ["BrightYellow", "Aqua", "Purple", "BrightOrange", "BrightRed", "LightGreen", "NightBlue"].includes(
                  curriculumColour,
              )
            : false;
        if (curriculumColour && hasShade) {
            switch (curriculumColour) {
                case "BrightYellow":
                    setShadeList(["#FFF023", "#FFF042", "#FFF55D", "#FFFD75", "#FCFF8B"]);
                    break;
                case "Aqua":
                    setShadeList(["#3DF9FF", "#61FFFB", "#86FFF5", "#A8FFEC", "#C0FFEA"]);
                    break;
                case "Purple":
                    setShadeList(["#BA33FF", "#B74DFF", "#C56DFF", "#D691FF", "#E1ADFF"]);
                    break;
                case "BrightOrange":
                    setShadeList(["#FF8735", "#FF9F57", "#FFB570", "#FFC287", "#FFC787"]);
                    break;
                case "BrightRed":
                    setShadeList(["#FF292F", "#FF4550", "#FF6670", "#FF848D", "#FFA1AC"]);
                    break;
                case "LightGreen":
                    setShadeList(["#008F1E", "#009C21", "#00C529", "#00ED32", "#00FF35"]);
                    break;
                case "NightBlue":
                    setShadeList(["#044F93", "#055AA9", "#0668C3", "#077E2", "#0781F1"]);
                    break;
                default:
                    break;
            }
        }
    }, [curriculumColour]);

    const classes = useHomeStyles();

    console.log(curriculumColour);
    console.log(shadeList);

    return (
        <Card
            className={classes.storyCard}
            onClick={() => {
                history.push({
                    pathname: `/explore/curriculum/${curriculumId}/collection/${item.id}`,
                    state: { from: `${isNewHome ? "/home" : "/explore"}` },
                });
            }}
            elevation={2}
        >
            <CardContent
                style={{
                    height: "100%",
                    padding: 0,
                    display: "flex",
                }}
            >
                <Box
                    bgcolor={
                        shadeList[index]
                            ? shadeList[index]
                            : `${getColourClass({
                                  colour: item.colour,
                              })}`
                    }
                    style={{
                        color: verbalTheme.palette.getContrastText(
                            shadeList[index] ? shadeList[index] : getColourCode(item?.colour ?? "Red"),
                        ),
                    }}
                    position="relative"
                    height="100%"
                    width="100%"
                >
                    <Box
                        position={"absolute"}
                        top={0}
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"row"}
                        alignItems={"center"}
                    >
                        <Avatar
                            alt="Collection"
                            src={CollectionIcon}
                            style={{
                                width: verbalTheme.spacing(8),
                                height: verbalTheme.spacing(8),
                            }}
                            // style={{
                            //     //margin: verbalTheme.spacing(2),
                            //     padding: 0,
                            //     width: verbalTheme.spacing(7),
                            //     height: verbalTheme.spacing(7),
                            //     objectFit: "cover",
                            //     backgroundSize: "auto",
                            //     //color: verbalTheme.palette.getContrastText(getColourCode(item?.colour ?? "Red")),
                            //     //backgroundColor: getColourCode(item?.colour ?? "Red"),
                            //     border: "1px solid black",
                            // }}
                        />
                        {/* {item?.name?.trim()?.split(" ")?.[0]?.charAt(0)}
                            {item?.name?.trim()?.split(" ")?.[1]?.charAt(0)}
                        </Avatar> */}
                        <Typography
                            style={{
                                paddingTop: verbalTheme.spacing(1),
                                color: verbalTheme.palette.getContrastText(
                                    shadeList[index] ? shadeList[index] : getColourCode(item?.colour ?? "Red"),
                                ),
                            }}
                        >
                            {item?.name}
                        </Typography>
                    </Box>
                    <Box position={"absolute"} bottom={0} bgcolor={"rgba(0,0,0, 0.5)"} width="100%">
                        <Box
                            m={1}
                            display="flex"
                            flexDirection={"row"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <VPillPositive label={`Age range ${item?.ageRange} years`} />
                        </Box>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
