import { CurriculumType } from "../../../models/modelTypes";

export const curriculumCRUDModel = {
    id: {
        inTable: false,
        inForm: false,
    },
    name: {
        label: "Curriculum",
    },
    description: {
        label: "Description",
    },
    purpose: {
        label: "Purpose",
        inTable: false,
    },
    pickOrder: {
        label: "Verbal Pick Order",
    },
    StoryCollection: {
        label: "Collections",
        inForm: false,
        decorator: (value: unknown, property?: string, curriculum?: CurriculumType): string =>
            curriculum?.StoryCollection?.length ? String(curriculum.StoryCollection.length) : "0",
    },
};
