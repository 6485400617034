import { getData } from "./rest";
import { objectToQueryString } from "../../utils/api";
import { ListParameters } from "common/build/api-parameters/common";
import { OrganisationsList, OrganisationsOverview, CollectionsList } from "common/build/api-parameters/stats";
import { UserType } from "../../models/modelTypes";

export const getOrganisationsOverview = async (args: Record<string, unknown>): Promise<OrganisationsOverview> =>
    await getData(`/stats/organisationoverview/${objectToQueryString(args)}`);

export const getOrganisationsList = async (
    args: ListParameters,
): Promise<{ items: OrganisationsList[]; total: number }> =>
    await getData(`/stats/organisationlist${objectToQueryString(args)}`);

export const getOrganisationsDetail = async (id: number, args: Record<string, unknown>): Promise<OrganisationsList> =>
    await getData(`/stats/organisationlist/${id}${objectToQueryString(args)}`);

export const getOrganisationsUserList = async (
    id: number,
    args: ListParameters,
): Promise<{ items: UserType[]; total: number }> =>
    await getData(`/stats/organisationuserlist/${id}${objectToQueryString(args)}`);

export const getCollectionsList = async (args: ListParameters): Promise<{ items: CollectionsList[]; total: number }> =>
    await getData(`/stats/collectionlist${objectToQueryString(args)}`);

export const getCollectionsDetail = async (id: number, args: Record<string, unknown>): Promise<CollectionsList> =>
    await getData(`/stats/collectionlist/${id}${objectToQueryString(args)}`);
