import React from "react";
import {
    Box,
    TextField,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import { VModule, VModuleType } from "../../../core/components/VModule";
import { SessionType, ProgrammeType } from "../../../models/modelTypes";
import { ConferenceProviderType } from "common/build/programmes/conferenceProviderType";

interface VideoConferenceSelectProps {
    setFieldValue: (field: string, value?: string) => void;
    values: Partial<SessionType> | Partial<ProgrammeType>;
    errors?: Partial<SessionType> | Partial<ProgrammeType>;
}

export const VideoConferenceSelect = (props: VideoConferenceSelectProps): JSX.Element => {
    const { setFieldValue, values, errors } = props;

    return values.isOnline ? (
        <VModule type={VModuleType.Shade1}>
            <Typography>
                <b>We take care of your video calls right here within Verbal!</b> If you would prefer to use a third
                party such as Zoom, simply select it in the dropdown.
            </Typography>
            <Box mt={4}>
                <FormControl variant="outlined" error={errors?.providerType ? true : false} fullWidth>
                    <Select
                        labelId={"video-conference-label"}
                        id={"video-conference"}
                        value={values.providerType}
                        onChange={(e) => {
                            values.providerURL = "";
                            setFieldValue("providerType", e.target.value as string);
                        }}
                        label={"Video Conferencing"}
                    >
                        {Object.entries(ConferenceProviderType).map((provider, id) => (
                            <MenuItem key={id} value={provider[0]}>
                                {provider[1]}
                            </MenuItem>
                        ))}
                    </Select>
                    <InputLabel id="video-conference-label">Video Conferencing</InputLabel>
                    <FormHelperText>{errors?.providerType}</FormHelperText>
                </FormControl>
                {values.providerType !== "BuiltIn" ? (
                    <FormControl fullWidth margin={"normal"}>
                        <TextField
                            fullWidth
                            id="video-url"
                            label="Video URL"
                            variant="outlined"
                            value={values.providerURL ?? ""}
                            onChange={(e) => setFieldValue("providerURL", e.target.value)}
                            helperText={errors?.providerURL}
                            error={errors?.providerURL ? true : false}
                        />
                    </FormControl>
                ) : null}
            </Box>
            {values.providerType && helperText(values.providerType)}
        </VModule>
    ) : (
        <></>
    );
};

function helperText(videoConferenceType: string): React.ReactNode {
    let helperText = <></>;
    switch (videoConferenceType) {
        case "GoogleMeet":
            helperText = (
                <Typography>
                    <h3>Where do I get my meeting URL?</h3>
                    <ol>
                        <li>
                            Visit{" "}
                            <a target="_blank" href="https://meet.google.com" rel="noreferrer">
                                meet.google.com
                            </a>{" "}
                            and create a <b>New Meeting</b> choosing the <b>Schedule in Google Calendar</b> option.
                        </li>
                        <li>
                            Create a <b>recurring appointment</b> for the lifetime of the programme.
                        </li>
                        <li>
                            Once created copy the meeting link (e.g. https://meet.google.com/xxx-xxxx-xxx) into the box
                            above
                        </li>
                    </ol>
                    Find out more at:{" "}
                    <a target="_blank" href="https://support.google.com/meet/answer/9302870" rel="noreferrer">
                        https://support.google.com/meet/answer/9302870
                    </a>
                </Typography>
            );
            break;
        case "MicrosoftTeams":
            helperText = (
                <Typography>
                    <h3>Where do I get my meeting URL?</h3>
                    <ol>
                        <li>
                            From <b>Microsoft Teams</b>, create a new <b>meeting</b> from the <b>calendar</b>.
                        </li>
                        <li>
                            Select a recurring appointment for the lifetime of the programme and save. Then select your
                            meeting from the calendar and copy the meeting link into the box above
                        </li>
                        <li>N.B. The meeting link only displays when people are invited.</li>
                    </ol>
                    Find out more at:{" "}
                    <a
                        target="_blank"
                        href="https://support.microsoft.com/en-us/office/create-a-link-or-a-code-for-joining-a-team-11b0de3b-9288-4cb4-bc49-795e7028296f"
                        rel="noreferrer"
                    >
                        https://support.microsoft.com/en-us/office/create-a-link-or-a-code-for-joining-a-team
                    </a>
                </Typography>
            );
            break;
        case "Zoom":
            helperText = (
                <Typography>
                    <h3>Where do I get my meeting URL?</h3>
                    <ol>
                        <li>
                            From the <b>Zoom app</b>, schedule a new <b>reoccurring meeting</b>.
                        </li>
                        <li>
                            Create a <b>reoccurring appointment</b> for the lifetime of the programme and select your
                            preferred calendar.
                        </li>
                        <li>
                            Copy the URL (e.g. https://zoom.us/j/...) from the calendar appointment into the box above.
                            <i>
                                <br />
                                Don&apos;t forget to save your appointment before exiting the calendar.
                            </i>
                        </li>
                    </ol>
                    Find out more at:{" "}
                    <a
                        target="_blank"
                        href="https://support.zoom.us/hc/en-us/articles/214973206-Scheduling-recurring-meetings"
                        rel="noreferrer"
                    >
                        https://support.zoom.us/hc/en-us/articles/214973206-Scheduling-recurring-meetings
                    </a>
                </Typography>
            );
            break;
        case "Other":
            helperText = (
                <Typography>
                    <h3>Where do I get my meeting URL?</h3>
                    <p>
                        Create a reoccurring appointment for the lifetime of the programme and copy the URL into the box
                        above.
                    </p>
                    <p>
                        Joining instructions will not be sent to your participants, so please ensure they can join your
                        meeting.
                    </p>
                </Typography>
            );
            break;
        case "BuiltIn":
        default:
            break;
    }
    return helperText;
}
