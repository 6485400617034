import React, { useEffect, useRef, useState } from "react";
import { Button, Container, DialogContentText, Drawer, FormControlLabel, Switch, Typography } from "@material-ui/core";
import { StepperWizard } from "../../../../core/components/wizard/StepperWizard";
import WizardStep from "../../../../core/components/wizard/WizardStep";
import { useSessionMode } from "../contexts/sessionMode.context";
import { Register } from "./steps/Register";
import { ReadStory } from "./steps/ReadStory";
import { ListProvider } from "../../../../core/components/crud/contexts/list.context";
import { useHistory, useParams } from "react-router-dom";
import { list } from "../../../../client/api/participants";
import { ParticipantType, SessionType } from "../../../../models/modelTypes";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { updateSessionState } from "../../../../client/api/session";
import { useBaseLayout } from "../../../layout/BaseLayout.context";
import ConfirmDialog from "../../../../core/components/ConfirmDialog";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { MeetingRoom } from "../meeting/MeetingRoom";
import { useUtilityStyles } from "../../../../hooks/styles/UtilityStyles";
import { useStoryStyles } from "../../../../hooks/styles/StoryStyles";
import { Survey, SurveyType } from "./steps/Survey";
import Spinner from "../../../layout/Spinner";
import { Error } from "../../../../core/components/Error";
import { SessionFetchParameters } from "common/src/api-parameters/session";
import { ParticipantListParameters } from "common/build/api-parameters/participants";
import { API_URL } from "../../../../client/api/rest";
import { Lobby } from "./steps/Lobby";
import { publicTheme } from "../../../layout/themes/public.theme";

export const SessionModeWizard = (): JSX.Element => {
    const history = useHistory();
    const { item, fetchItem } = useFetch<SessionType>();
    const { addMenuItem, clearMenuItems } = useBaseLayout();
    const [showExitSession, setShowExitSession] = useState<boolean>(false);
    const [showVideoPanel, setShowVideoPanel] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [showCancel, setShowCancel] = useState<boolean>(false);
    const [hasFacilitatorCompletedSurvey, setHasFacilitatorCompletedSurvey] = useState<boolean>(false);
    const {
        activeStep,
        completedSteps,
        changeStep,
        presentationMode,
        annotationsMode,
        toggleAnnotationsMode,
        togglePresentationMode,
        changePart,
        onAdmitParticipants,
        participantsAdmitted,
        toggleHideSession,
        hidden,
        submitStep,
        participantCount,
    } = useSessionMode();

    const ref = useRef<HTMLDivElement | null>(null);

    const { id } = useParams<{ id?: string }>();

    const useStyles = makeStyles(
        createStyles({
            sessionModeLayout: {
                display: "grid",
                gridTemplateColumns:
                    item?.isOnline && showVideoPanel && !presentationMode
                        ? activeStep === 3
                            ? "auto 80vw"
                            : "auto 400px"
                        : "auto",
                height: "calc(100vh - 71px)",
            },
            storyPanel: {
                overflow: "auto",
            },
            videoPanel: {
                display: showVideoPanel ? "flex" : "none",
                overflow: "hidden",
            },
            drawerPaper: {
                position: "relative",
                backgroundColor: item?.providerType === "BuiltIn" ? "black" : "#F9F9FB",
                color: item?.providerType === "BuiltIn" ? "white" : "inherit",
                width: "100%",
                borderLeft: "none",
            },
            largeContainer: {
                maxWidth: "1500px",
            },
            expDialogHeader: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
            pill: {
                backgroundColor: publicTheme.palette.themePill.main,
                color: verbalTheme.palette.common.white,
            },
        }),
    );

    const AnnotationSwitch = withStyles({
        switchBase: {
            color: presentationMode ? "black" : "white",
            "&$checked": {
                color: presentationMode ? "black" : "white",
            },
            "&$checked + $track": {
                backgroundColor: presentationMode ? verbalTheme.palette.primary.main : verbalTheme.palette.primary.main,
                opacity: 1,
            },
        },
        checked: {},
        track: {
            backgroundColor: presentationMode ? "black" : "white",
        },
    })(Switch);

    useEffect(() => {
        ref.current?.scroll(0, 0);
    }, [activeStep]);

    useEffect(() => {
        if (item) {
            changePart(item.currentPart);
            if (item.isUsersAdmitted) {
                onAdmitParticipants();
            }

            const events = new EventSource(`${API_URL}/session/${item.id}/updates`, {
                withCredentials: true,
            });

            events.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (parseInt(data.part) >= 0) {
                    changePart(data.part);
                }
                if (data.isUsersAdmitted) {
                    onAdmitParticipants();
                    if (data.isRegisterTaken) {
                        changeStep(1);
                    }
                }
                if (data.isSurveyInProgress) {
                    changeStep(2);
                }
                if (data.annotationQualityMeasure && data.storyQualityMeasure) {
                    setHasFacilitatorCompletedSurvey(true);
                }
            };

            return () => {
                events.close();
            };
        }
    }, [item]);

    const toggleVideoPanelMode = () => {
        setShowVideoPanel((prev) => !prev);
    };

    const exitSession = async () => {
        if (id) {
            if (item?.isOnline && participantsAdmitted) {
                await updateSessionState(parseInt(id), { state: "Complete" });
            } else if (!item?.isOnline) {
                await updateSessionState(parseInt(id), { state: "Complete" });
            }
            history.push(`/programmes/view/${item?.programmeId}`);
        }
    };

    const cancelSession = async () => {
        if (id) {
            await updateSessionState(parseInt(id), {
                state: "NotStarted",
                isUsersAdmitted: false,
                isRegisterTaken: false,
                currentPart: 0,
            });
            history.push(`/programmes/view/${item?.programmeId}`);
        }
    };

    const classes = useStyles();
    const utilityClasses = useUtilityStyles();
    const storyClasses = useStoryStyles();

    const videoPanelSwitch = (
        <div className="c-toolbar-item">
            <FormControlLabel
                control={<AnnotationSwitch checked={showVideoPanel} onChange={toggleVideoPanelMode} />}
                label={
                    <Typography
                        variant="body2"
                        color={presentationMode ? "textPrimary" : "inherit"}
                        className={utilityClasses.bold}
                    >
                        <b>Video Panel</b>
                    </Typography>
                }
            />
        </div>
    );

    useEffect(() => {
        if (item?.isRegisterTaken && item?.isOnline && participantsAdmitted) {
            submitStep();
            changeStep(1);
        } else if (item?.isRegisterTaken && !item?.isOnline) {
            submitStep();
            changeStep(1);
        }
    }, [item, participantsAdmitted]);

    useEffect(() => {
        if (id && parseInt(id) > 0) {
            setLoading(true);
            fetchItem(parseInt(id), { includeStory: true } as SessionFetchParameters)
                .then(() => {
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError(true);
        }
    }, [id]);

    useEffect(() => {
        clearMenuItems();

        if (!presentationMode) {
            addMenuItem(
                "ExitSession",
                <div className="c-toolbar-item">
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => {
                            if (participantsAdmitted || !item?.isOnline) {
                                setShowExitSession(true);
                            } else {
                                exitSession();
                            }
                        }}
                    >
                        {activeStep === 3 ? "End Session" : "Exit Session"}
                    </Button>
                </div>,
            );
        }

        if (
            item?.isOnline &&
            item?.Programme?.Facilitators &&
            participantCount <= item?.Programme.Facilitators.length
        ) {
            addMenuItem(
                "HideSession",
                <div className="c-toolbar-item">
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => {
                            if (
                                participantsAdmitted &&
                                item?.Programme?.Facilitators &&
                                participantCount <= item?.Programme.Facilitators.length
                            ) {
                                setShowCancel(true);
                            } else {
                                toggleHideSession();
                            }
                        }}
                    >
                        {participantsAdmitted &&
                        item?.Programme?.Facilitators &&
                        participantCount <= item?.Programme.Facilitators.length
                            ? "Cancel Session "
                            : hidden
                            ? "Show Session"
                            : "Hide Session"}
                    </Button>
                </div>,
            );
        }

        switch (activeStep) {
            case 0:
                addMenuItem("Register", <>{item?.isOnline && videoPanelSwitch}</>);
                break;
            case 1:
                addMenuItem(
                    "ReadingInPerson",
                    <>
                        {!presentationMode && (
                            <div className="c-toolbar-item">
                                <FormControlLabel
                                    control={
                                        <AnnotationSwitch checked={annotationsMode} onChange={toggleAnnotationsMode} />
                                    }
                                    label={
                                        <Typography
                                            variant="body2"
                                            color={presentationMode ? "textPrimary" : "inherit"}
                                            className={utilityClasses.bold}
                                        >
                                            <b>Annotations</b>
                                        </Typography>
                                    }
                                />
                            </div>
                        )}
                        {item?.isOnline && !presentationMode && videoPanelSwitch}
                        {!item?.isOnline && (
                            <div className="c-toolbar-item">
                                <Button
                                    variant={presentationMode ? "contained" : "text"}
                                    color={presentationMode ? "primary" : "inherit"}
                                    onClick={togglePresentationMode}
                                >
                                    {presentationMode ? "Leave" : ""} Presentation Mode
                                </Button>
                            </div>
                        )}
                    </>,
                );
                break;
        }
    }, [
        activeStep,
        presentationMode,
        annotationsMode,
        showVideoPanel,
        item,
        hidden,
        participantsAdmitted,
        participantCount,
    ]);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error description={`Unable to load session`} />;
    }

    return (
        <div className={classes.sessionModeLayout}>
            <div className={classes.storyPanel} ref={ref}>
                <Container className={storyClasses.storyContainer}>
                    <ConfirmDialog
                        open={showCancel}
                        title="Cancel Session"
                        onConfirm={cancelSession}
                        onCancel={() => setShowCancel(false)}
                        confirmButtonText="Cancel Session"
                        cancelButtonText="Return to Session"
                    >
                        <DialogContentText>
                            {`If you exit this session it will not be marked as completed and can be started at a later date and time by editing the session details in the programme overview screen`}
                        </DialogContentText>
                    </ConfirmDialog>
                    {showExitSession && (
                        <ConfirmDialog
                            open={showExitSession}
                            title={`End Session`}
                            onConfirm={exitSession}
                            onCancel={() => setShowExitSession(false)}
                            confirmButtonText={"End Session"}
                            cancelButtonText="Return to Session"
                        >
                            <DialogContentText>
                                {activeStep === 3
                                    ? "Ending the session will close the chat down and mark the session as complete. Are you happy to continue?"
                                    : `Ending the session will mark the session as complete. Are you sure you want to end this session?`}
                            </DialogContentText>
                        </ConfirmDialog>
                    )}
                    {item && (
                        <ListProvider<ParticipantType>
                            plural={"Participants"}
                            getData={list}
                            includes={{ programmeId: item.programmeId, take: 100 } as ParticipantListParameters}
                            pagination={false}
                        >
                            <StepperWizard
                                orientation="horizontal"
                                activeStep={activeStep}
                                goToStep={changeStep}
                                hideStepper={presentationMode || activeStep === 3}
                            >
                                <WizardStep identifier={0} heading={"Register"} completed={completedSteps[0]}>
                                    <Register />
                                </WizardStep>
                                <WizardStep identifier={1} heading={"Story"} completed={completedSteps[1]}>
                                    <ReadStory session={item} />
                                </WizardStep>
                                <WizardStep identifier={2} heading={"Survey"} completed={completedSteps[2]}>
                                    <Survey
                                        type={SurveyType.Facilitator}
                                        session={item}
                                        hasFacilitatorCompletedSurvey={hasFacilitatorCompletedSurvey}
                                        setHasFacilitatorCompletedSession={setHasFacilitatorCompletedSurvey}
                                    />
                                </WizardStep>
                                {item.isOnline ? (
                                    <WizardStep identifier={3} heading="Lobby" completed={completedSteps[3]}>
                                        <Lobby showExitSession={setShowExitSession} />
                                    </WizardStep>
                                ) : (
                                    <></>
                                )}
                            </StepperWizard>
                        </ListProvider>
                    )}
                </Container>
            </div>
            {item?.isOnline && !presentationMode && (
                <Drawer
                    variant="persistent"
                    anchor="right"
                    open={showVideoPanel && !presentationMode}
                    className={classes.videoPanel}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    {<MeetingRoom session={item} meetingName={item.guid} />}
                </Drawer>
            )}
        </div>
    );
};
export default SessionModeWizard;
