import React from "react";
import Crud from "../../../core/components/crud/Crud";
import { getCollectionsList, getCollectionsDetail } from "../../../client/api/stats";
import { collectionsCRUDModel } from "../contexts/collections.context";
import { CollectionListParameters } from "common/src/api-parameters/collections";
import { CollectionsList } from "common/build/api-parameters/stats";

export const CollectionsScreen = (): JSX.Element => {
    const params = {
        includeMappedStory: true,
        includeThemes: true,
        includeCurriculum: true,
    } as CollectionListParameters;

    return (
        <Crud<CollectionsList, Record<string, unknown>>
            model={collectionsCRUDModel}
            noun="Story"
            plural="Story Collections"
            getData={getCollectionsList}
            listParams={params}
            itemParams={params}
            getItem={getCollectionsDetail}
            labelProperty={"name"}
            searchable
            canExport={true}
        />
    );
};
