import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { SecondaryButton } from "../../../layout/Button/Button";
import { HeadlineFigureWidget } from "./HeadlineFigureWidget";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import { VTextLink } from "../../../common/VTextLink";

export const ActiveProgrammesHeadlineWidget: FC<{ isFacilitator: boolean }> = ({ isFacilitator }) => {
    const { item } = useFetch<ActiveProgramStats>();
    const count = item?.programmes.length || 0;
    const hasNoActiveProgrammes = count === 0;
    return (
        <HeadlineFigureWidget header={isFacilitator ? "Your Programmes" : "Active Programmes"}>
            <Box>
                <SpinWhileFetching>
                    <Typography variant="h2">{count}</Typography>
                </SpinWhileFetching>
            </Box>
            <Box>
                {!isFacilitator && (
                    <>
                        {hasNoActiveProgrammes ? (
                            <VTextLink to="/programmes/add">
                                <SecondaryButton fullWidth>Schedule Programme</SecondaryButton>
                            </VTextLink>
                        ) : (
                            <VTextLink to="/programmes">
                                <SecondaryButton fullWidth>View Programmes</SecondaryButton>
                            </VTextLink>
                        )}
                    </>
                )}
            </Box>
        </HeadlineFigureWidget>
    );
};
