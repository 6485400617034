import React, { ComponentProps } from "react";
import Stepper from "@material-ui/core/Stepper";
import Wizard, { WizardChild } from "./Wizard";
import { Step, StepButton, Box } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/core/styles";

type HorizontalWizardProps = ComponentProps<typeof Wizard>;

export const HorizontalWizard: React.FC<HorizontalWizardProps> = (props: HorizontalWizardProps) => {
    const { activeStep, goToStep, hideStepper } = props;

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                flexGrow: 1,
            },
            stepperRoot: {
                backgroundColor: "transparent",
                padding: 0,
            },
            hidden: {
                display: "none",
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <div className={classes.root}>
                <Box className={hideStepper ? classes.hidden : ""} mb={6}>
                    <Stepper activeStep={activeStep} orientation="horizontal" className={classes.stepperRoot}>
                        {React.Children.map(props.children, (step: WizardChild) => {
                            return (
                                step &&
                                step.props.heading && (
                                    <Step key={step.props.heading} {...step.props} completed={step.props.completed}>
                                        <StepButton onClick={() => goToStep(step.props.identifier)}>
                                            {step.props.heading}
                                        </StepButton>
                                    </Step>
                                )
                            );
                        })}
                    </Stepper>
                </Box>
                <Wizard {...props} />
            </div>
        </>
    );
};
