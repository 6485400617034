import { Box, makeStyles, Typography, createStyles, Grid, Button, Paper } from "@material-ui/core";
import clsx from "clsx";
import { RelatedQuestion } from "common/build/prisma/client";
import React, { Fragment, useEffect, useState } from "react";
import { VModule, VModuleType } from "../../../../../core/components/VModule";
import { VPill, VPillClosedQuestion } from "../../../../../core/components/VPill";
import { useUtilityStyles } from "../../../../../hooks/styles/UtilityStyles";
import { MappedStoryPartType, MappedStoryQuestionType, StoryType } from "../../../../../models/modelTypes";
import { verbalTheme } from "../../../../layout/themes/verbal.theme";
import { RelatedQuestionCard } from "../../../components/RelatedQuestionCard";
import { StoryImage } from "../../../components/StoryImage";

type StoryBodyProps = {
    story?: StoryType;
    parts?: MappedStoryPartType[];
    currentPartIndex: number;
    currentPartQuestions: MappedStoryQuestionType[];
    currentQuestionIndex: number;
    currentRelatedQuestion?: Partial<RelatedQuestion>;
    setCurrentPartIndex: (index: number) => void;
    setCurrentQuestionIndex: (index: number) => void;
    setCurrentRelatedQuestion: (rq?: Partial<RelatedQuestion>) => void;
};

export const StoryBody = (props: StoryBodyProps): JSX.Element => {
    const {
        story,
        parts,
        currentPartIndex,
        currentPartQuestions,
        currentQuestionIndex,
        currentRelatedQuestion,
        setCurrentPartIndex,
        setCurrentQuestionIndex,
        setCurrentRelatedQuestion,
    } = props;

    const [openPrompt, setOpenPrompt] = useState<boolean>(false);
    const [contentPart1, setContentPart1] = useState<string | null | undefined>();
    const [contentPart2, setContentPart2] = useState<string | null | undefined>();

    const useStyles = makeStyles(() =>
        createStyles({
            content: {
                padding: 0,
                margin: 0,
            },
            serif: {
                fontFamily: "'PT Serif', serif",
            },
            storyTitle: {
                fontSize: "32px",
                fontWeight: 700,
                lineHeight: 1.5,
                [verbalTheme.breakpoints.up("sm")]: {
                    fontSize: "26px",
                },
                [verbalTheme.breakpoints.up("md")]: {
                    fontSize: "26px",
                },
            },
            storyBlock: {
                fontSize: "18px",
                lineHeight: 2,
                [verbalTheme.breakpoints.up("sm")]: {
                    fontSize: "20px",
                },
                marginBottom: "1em",
                [verbalTheme.breakpoints.up("md")]: {
                    fontSize: "22px",
                },
            },
            storyNavButton: {
                display: "block",
                marginBottom: verbalTheme.spacing(2),
                width: "100%",
                [verbalTheme.breakpoints.up("sm")]: {
                    width: "auto",
                },
            },
            clear: {
                overflow: "auto",
            },
            bold: {
                fontWeight: 700,
                opacity: "0.5",
            },
            actions: {
                display: "block",
                clear: "both",
                overflow: "auto",
            },
            questionsRoot: {
                padding: verbalTheme.spacing(3),
            },
            keywordChip: {
                borderRadius: 0,
                margin: "0 0.5rem 0.5rem 0",
            },
        }),
    );
    const classes = useStyles();
    const utilityClasses = useUtilityStyles();

    const dropCapClass = currentPartIndex + 1 === 1 ? "c-story-text" : undefined;
    const currentContent = parts?.[currentPartIndex]?.content;

    // Configure the content parts (i.e. text before image, text after image) if the currentContent changes
    useEffect(() => {
        const patternContentPart1 = /^.{0,200}[^\n]*\n/s;
        //const patternContentPart3 = /\n.{0,300}$/s;
        const patternRemoveSurroundingWhitespaces = /^\s+|\s+$/s;

        const _contentPart1 = currentContent
            ?.match(patternContentPart1)?.[0]
            ?.replace(patternRemoveSurroundingWhitespaces, "");
        setContentPart1(_contentPart1);
        const _contentPart2 = currentContent
            ?.replace(patternContentPart1, "")
            ?.replace(patternRemoveSurroundingWhitespaces, "");
        setContentPart2(_contentPart2);

        //const _contentPart3 = _contentPart2?.match(patternContentPart3)?.[0]?.replace(patternRemoveSurroundingWhitespaces, "");
        //_contentPart2 = _contentPart2?.replace(patternContentPart3, "")?.replace(patternRemoveSurroundingWhitespaces, "");
    }, [currentContent]);

    // Preload Images
    useEffect(() => {
        parts?.forEach((storyPart) => {
            if (storyPart && storyPart.imageUrl) {
                const img = new Image();
                img.src = storyPart.imageUrl;
            }
        });
    }, [parts]);

    return (
        <>
            <Box mb={4}>
                <Typography variant="body2" className={classes.bold}>
                    Part {currentPartIndex + 1} of {parts?.length}
                </Typography>
                <Typography className={clsx(classes.serif, classes.storyTitle)} component="h1">
                    {story?.storyName}
                </Typography>
                <Typography variant="body2" className={classes.bold}>
                    By {story?.author}
                </Typography>
            </Box>

            <Box mb={4}>
                <div key={parts?.[currentPartIndex]?.id + "a"} className={dropCapClass}>
                    <Typography
                        style={{ whiteSpace: "pre-line", marginBottom: "40px" }}
                        className={clsx(classes.serif, classes.storyBlock)}
                        dangerouslySetInnerHTML={{ __html: contentPart1 ?? "" }}
                    />
                </div>

                {parts?.[currentPartIndex]?.imageUrl ? (
                    <StoryImage url={parts?.[currentPartIndex]?.imageUrl as string}></StoryImage>
                ) : null}

                <div key={parts?.[currentPartIndex]?.id + "b"}>
                    <Typography
                        style={{ whiteSpace: "pre-line", marginBottom: "40px" }}
                        className={clsx(classes.serif, classes.storyBlock)}
                        dangerouslySetInnerHTML={{ __html: contentPart2 ?? "" }}
                    />
                </div>
            </Box>

            <Box mb={4}>
                <Paper className={classes.questionsRoot}>
                    <Box mb={3}>
                        <Grid container spacing={2} direction="row">
                            <Grid item>
                                <Typography variant="h5">
                                    Question {currentQuestionIndex + 1} of {currentPartQuestions.length}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {currentPartQuestions[currentQuestionIndex]?.category === "Closed" ? (
                                    <VPillClosedQuestion />
                                ) : null}
                            </Grid>
                        </Grid>
                        <Typography gutterBottom={true} style={{ whiteSpace: "pre-line" }}>
                            {currentPartQuestions[currentQuestionIndex]?.question}
                        </Typography>

                        <Box py={1}>
                            {currentPartQuestions[currentQuestionIndex]?.keywords &&
                                (currentPartQuestions[currentQuestionIndex]?.keywords?.length ?? 0) > 0 &&
                                currentPartQuestions[currentQuestionIndex]?.keywords?.split(" ").map((kw, index) => (
                                    <VPill
                                        key={kw}
                                        style={
                                            index % 2 === 0
                                                ? {
                                                      backgroundColor: "#28B5BE",
                                                  }
                                                : {
                                                      backgroundColor: "#D3B61A",
                                                  }
                                        }
                                        className={classes.keywordChip}
                                        label={kw}
                                    />
                                ))}
                        </Box>

                        <Grid container spacing={1} direction="row">
                            {currentPartQuestions[currentQuestionIndex]?.category === "Closed" &&
                                currentPartQuestions[currentQuestionIndex]?.RelatedQuestion?.map((rq, index) => {
                                    const condition = rq.condition;
                                    return (
                                        <Fragment key={index}>
                                            {condition === "Yes" ? (
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color={rq === currentRelatedQuestion ? "primary" : "default"}
                                                        onClick={() => {
                                                            if (currentRelatedQuestion?.condition === rq.condition) {
                                                                setCurrentRelatedQuestion(undefined);
                                                            } else {
                                                                setCurrentRelatedQuestion(rq);
                                                            }
                                                        }}
                                                    >
                                                        If group say <br /> YES
                                                    </Button>
                                                </Grid>
                                            ) : (
                                                <Grid item>
                                                    <Button
                                                        variant="contained"
                                                        color={rq === currentRelatedQuestion ? "primary" : "default"}
                                                        onClick={() => {
                                                            if (currentRelatedQuestion?.condition === rq.condition) {
                                                                setCurrentRelatedQuestion(undefined);
                                                            } else {
                                                                setCurrentRelatedQuestion(rq);
                                                            }
                                                        }}
                                                    >
                                                        If group say <br /> NO
                                                    </Button>
                                                </Grid>
                                            )}
                                        </Fragment>
                                    );
                                })}
                        </Grid>

                        <Box>
                            {currentPartQuestions[currentQuestionIndex]?.category === "Closed" &&
                                currentRelatedQuestion && (
                                    <RelatedQuestionCard relatedQuestion={currentRelatedQuestion} />
                                )}
                        </Box>

                        {currentPartQuestions[currentQuestionIndex]?.category === "Closed" &&
                            (currentRelatedQuestion || openPrompt) && (
                                <Box pt={currentRelatedQuestion ? 0 : 2}>
                                    <Button
                                        onClick={() => {
                                            setOpenPrompt(!openPrompt);
                                        }}
                                        variant="contained"
                                        color="secondary"
                                    >
                                        Still No Response?
                                    </Button>
                                </Box>
                            )}

                        {currentPartQuestions[currentQuestionIndex]?.category === "Open" && (
                            <Box pt={1}>
                                <Button
                                    onClick={() => {
                                        setOpenPrompt(!openPrompt);
                                    }}
                                    variant="contained"
                                    color="secondary"
                                >
                                    No Response?
                                </Button>
                            </Box>
                        )}

                        {openPrompt ? (
                            <Box py={2}>
                                <VModule type={VModuleType.Shade1}>
                                    <Typography gutterBottom>
                                        <strong>If the group are still not responding, try asking:</strong>
                                    </Typography>
                                    <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
                                        {currentPartQuestions[currentQuestionIndex]?.prompt}
                                    </Typography>
                                    <Typography>
                                        <strong>
                                            If this still doesn&apos;t generate a discussion you can move on to the next
                                            question
                                        </strong>
                                    </Typography>
                                </VModule>
                            </Box>
                        ) : null}

                        <div className={classes.actions}>
                            {currentQuestionIndex > 0 && (
                                <Box mt={1} ml={0}>
                                    <Button
                                        className={utilityClasses.floatLeft}
                                        variant="contained"
                                        onClick={() => {
                                            setOpenPrompt(false);
                                            setCurrentQuestionIndex(currentQuestionIndex - 1);
                                            setCurrentRelatedQuestion(undefined);
                                        }}
                                    >
                                        Previous
                                    </Button>
                                </Box>
                            )}
                            {currentPartQuestions[currentQuestionIndex + 1] && (
                                <Box mt={1} mr={0}>
                                    <Button
                                        className={utilityClasses.floatRight}
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            setOpenPrompt(false);
                                            setCurrentQuestionIndex(currentQuestionIndex + 1);
                                            setCurrentRelatedQuestion(undefined);
                                        }}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            )}
                        </div>
                    </Box>
                </Paper>
            </Box>
            <Box className={classes.clear}>
                {currentPartIndex > 0 && (
                    <Button
                        className={clsx(utilityClasses.floatLeft, classes.storyNavButton)}
                        variant="contained"
                        size="large"
                        onClick={() => {
                            setCurrentPartIndex(currentPartIndex - 1);
                            setCurrentQuestionIndex(0);
                            setCurrentRelatedQuestion(undefined);
                        }}
                    >
                        Previous Story Part
                    </Button>
                )}
                {parts?.[currentPartIndex + 1] && (
                    <Button
                        className={clsx(utilityClasses.floatRight, classes.storyNavButton)}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={() => {
                            setCurrentPartIndex(currentPartIndex + 1);
                            setCurrentQuestionIndex(0);
                            setCurrentRelatedQuestion(undefined);
                        }}
                    >
                        Continue to Story Part {currentPartIndex + 2}
                    </Button>
                )}
            </Box>
        </>
    );
};
