import React, { useEffect } from "react";
import { Button, createStyles, Dialog, Grid, IconButton, makeStyles, TextField, Typography } from "@material-ui/core";
import { UserRating } from "common/build/prisma/client";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Formik } from "formik";
import CloseIcon from "@material-ui/icons/Close";
import { verbalTheme } from "../layout/themes/verbal.theme";
import { useAuth } from "../../contexts/auth.context";
import { SurveyRating } from "../../core/components/SurveyRating";
import dayjs from "dayjs";
import * as yup from "yup";

export const UserRatingDialog: React.FC = (): JSX.Element => {
    const { addUserRating, user } = useAuth();
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (
            user &&
            dayjs(user.registrationDate).isBefore(dayjs().subtract(2, "weeks")) &&
            (dayjs(user.lastUserRatingDate).isBefore(dayjs().subtract(3, "months"), "day") || !user.lastUserRatingDate)
        ) {
            setOpen(true);
        }
    }, [user]);

    const onSubmit = (values?: Partial<UserRating>) => {
        addUserRating(values)
            .then(() => {
                setOpen(false);
            })
            .catch(() => {
                // We don't want to be intrusive with this, just let it go.
            });
    };

    const onCancel = () => {
        onSubmit();
    };

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Dialog onClose={onCancel} open={open}>
                <MuiDialogTitle>
                    <Typography variant="h3" component="h3">
                        How&apos;re we doing?
                    </Typography>
                    <IconButton className={classes.closeButton} aria-label="close" onClick={onCancel}>
                        <CloseIcon />
                    </IconButton>
                </MuiDialogTitle>
                <Formik
                    initialValues={{
                        rating: 1,
                        message: "",
                    }}
                    validationSchema={yup.object({
                        rating: yup.number(),
                        message: yup.string().optional(),
                    })}
                    onSubmit={onSubmit}
                >
                    {({ submitForm, errors, values, handleChange, touched }) => {
                        return (
                            <>
                                <MuiDialogContent>
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <SurveyRating
                                                name="rating"
                                                defaultValue={1}
                                                max={5}
                                                onChange={handleChange}
                                                minLabel={"Not useful"}
                                                maxLabel={"Extremely useful"}
                                                value={values.rating}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                multiline
                                                rows={4}
                                                name="message"
                                                label="Suggestions"
                                                fullWidth
                                                onChange={handleChange}
                                                value={values.message}
                                                variant={"outlined"}
                                                error={!!errors.message}
                                                helperText={errors.message && touched.message && errors.message}
                                            />
                                        </Grid>
                                    </Grid>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button onClick={onCancel} variant="contained" size="large">
                                        Cancel
                                    </Button>
                                    <Button onClick={submitForm} variant="contained" color={"primary"} size="large">
                                        Send
                                    </Button>
                                </MuiDialogActions>
                            </>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
};
export default UserRatingDialog;
