import { ModelField } from "./Crud";
import dayjs from "dayjs";
import { CheckboxInput } from "../CheckboxInput";
import { date } from "./decorators";

export const InputDateProps: ModelField<unknown, unknown> = {
    inputProps: { type: "date", InputLabelProps: { shrink: true } },
    inputValueDecorator: (value: unknown) => dayjs(value as Date).format("YYYY-MM-DD"),
    decorator: (value: unknown): string => date(value as string),
};

export const InputCheckboxProps: ModelField<unknown, boolean> = {
    InputComponent: CheckboxInput,
    decorator: (value: boolean): string => (!!value ? "yes" : "no"),
};
