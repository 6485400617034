import React from "react";
import { Box, Typography } from "@material-ui/core";
import { TypeHeading1 } from "../../components/layout/Typography/Typography";

type WizardHeadingProps = {
    overline?: string;
    heading?: string;
};

export const WizardHeading = (props: WizardHeadingProps): JSX.Element => {
    const { overline, heading } = props;

    return (
        <Box mb={4}>
            <Typography variant={"overline"}>{overline}</Typography>
            <TypeHeading1 variant="h3" color="textPrimary" align="left">
                {heading}
            </TypeHeading1>
        </Box>
    );
};
