import { User } from "common/build/prisma/client";
import { UserType } from "../../models/modelTypes";
import { deleteData, getData, patchData, postData } from "./rest";
import { objectToQueryString } from "../../utils/api";
import { ListParameters } from "../../../../common/build/api-parameters/common";
import { ActiveProgramStats, ClassroomStats } from "common/build/api-parameters/users";

export const list = async (
    args: Record<string, unknown> & ListParameters,
): Promise<{ items: UserType[]; total: number }> => await getData(`/user${objectToQueryString(args)}`);

export const get = async (id: number, args: Record<string, unknown>): Promise<UserType> =>
    await getData(`/user/${id}${objectToQueryString(args)}`);

export const add = async (user: User): Promise<Response> => await postData(`/user/`, { ...user });

export const update = async (id: number, editedUser: Partial<User>): Promise<Response> =>
    await patchData(`/user/${id}`, { ...editedUser });

export const deleteUser = async (id: number): Promise<Response> => await deleteData(`/user/${id}`);

export const getStats = (): Promise<ActiveProgramStats> => getData("/user/activeProgrammes");

export const getMoodleURL = (): Promise<string> => getData("/user/moodle-login");

export const getEssentialCourseStatus = (): Promise<boolean> => getData("/user/moodle-complete");

export const getClassroomStats = (id: number): Promise<ClassroomStats> => getData(`/user/classroomStats/${id}`);

export const addMany = async (user: User[]): Promise<Response> => await postData(`/user/create-many`, { ...user });
