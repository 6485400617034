import React, { useState } from "react";
import {
    Checkbox,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableContainer,
    Table,
    TablePagination,
    Paper,
    Grid,
} from "@material-ui/core";
import { useList } from "./contexts/list.context";
import Spinner from "../../../components/layout/Spinner";
import { Model } from "./Crud";
import { simple } from "./decorators";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { verbalTheme } from "../../../components/layout/themes/verbal.theme";
import { SecondaryButton } from "../../../components/layout/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";

type CrudProps<T extends Record<string, unknown>> = {
    model: Model<T>;
    pagination?: boolean;
    checkboxSelection?: boolean;
    onRowClick?: (data: T) => void;
    onRowSelected?: (rowId: number, checked: boolean) => void;
    onSelectAllRows?: (selectedRowIds: number[]) => void;
    onRowDelete?: (rowID: number) => void;
    onRowRestore?: (rowID: number) => void;
    selectedRowIds?: number[];
    onExport?: (() => void) | null;
    canDelete?: boolean;
    userStatus?: string;
};

export const PagedList = <T extends { id: number; [key: string]: unknown }>(props: CrudProps<T>): JSX.Element => {
    // ToDo: list selection could be refactored to use the checked methods and state on useList
    const {
        model,
        checkboxSelection = false,
        onRowClick,
        onRowSelected,
        onSelectAllRows,
        selectedRowIds = [],
        pagination = true,
        onExport = null,
        onRowDelete,
        onRowRestore,
        canDelete,
        userStatus,
    } = props;
    const { list, total = 0, initialised, currentPage, handlePageChange } = useList<T>();
    const [hoveredRow, setHoveredRow] = useState<number | null>(null);
    const [itemToDelete, setItemToDelete] = useState<T | null>(null);
    const [itemToRestore, setItemToRestore] = useState<T | null>(null);

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                width: "100%",
            },
            paper: {
                width: "100%",
                marginBottom: verbalTheme.spacing(4),
            },
            table: {
                minWidth: 750,
            },
            visuallyHidden: {
                border: 0,
                clip: "rect(0 0 0 0)",
                height: 1,
                margin: -1,
                overflow: "hidden",
                padding: 0,
                position: "absolute",
                top: 20,
                width: 1,
            },
            tableHead: {
                fontWeight: 700,
                "& th:last-child": {
                    borderRadius: "0px 8px 0px 0px",
                },
                "& th:first-child": {
                    borderRadius: "8px 0px 0px 0px",
                },
            },
            tableBody: {
                "& tr:last-child td": {
                    borderBottom: "0",
                },
            },
        }),
    );

    const classes = useStyles();

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onSelectAllRows) {
            if (event.target.checked) {
                const newSelecteds = list.map((item) => item.id as number);
                onSelectAllRows(newSelecteds);
                return;
            }
            onSelectAllRows([]);
        }
    };

    const isSelected = (id: number) => (checkboxSelection ? selectedRowIds?.indexOf(id) !== -1 : false);

    const onPageChange = (_event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        handlePageChange(page);
    };

    if (!initialised) {
        return <Spinner />;
    }

    return (
        <>
            <Dialog open={itemToDelete !== null} onClose={() => setItemToDelete(null)}>
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete{" "}
                        {itemToDelete ? `${itemToDelete.firstName} ${itemToDelete.lastName}` : "this item"}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setItemToDelete(null)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (itemToDelete !== null) {
                                onRowDelete?.(itemToDelete.id);
                            }
                            setItemToDelete(null);
                        }}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={itemToRestore !== null} onClose={() => setItemToRestore(null)}>
                <DialogTitle>{"Confirm Restore"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to restore{" "}
                        {itemToRestore ? `${itemToRestore.firstName} ${itemToRestore.lastName}` : "this item"}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setItemToRestore(null)} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            if (itemToRestore !== null) {
                                onRowRestore?.(itemToRestore.id);
                            }
                            setItemToRestore(null);
                        }}
                        color="primary"
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.root}>
                <Paper className={classes.paper}>
                    <TableContainer>
                        <Table size={"medium"}>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    {checkboxSelection && (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                indeterminate={
                                                    selectedRowIds?.length > 0 && selectedRowIds?.length < list.length
                                                }
                                                checked={list.length > 0 && selectedRowIds?.length === list.length}
                                                onChange={handleSelectAllClick}
                                                inputProps={{ "aria-label": "select all" }}
                                            />
                                        </TableCell>
                                    )}
                                    {Object.entries(model)
                                        .filter(([, { inTable = true }]) => inTable)
                                        .map(([property, { label = `${property[0].toUpperCase() + property
                                                        .substring(1)
                                                        .toLowerCase()}` }], index) => (
                                            <TableCell key={index}>{label}</TableCell>
                                        ))}
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {list.map((data) => {
                                    const isItemSelected = isSelected(data.id as number);
                                    const isHovered = hoveredRow === data.id;
                                    return (
                                        <TableRow
                                            hover
                                            onMouseEnter={() => setHoveredRow(data.id)}
                                            onMouseLeave={() => setHoveredRow(null)}
                                            style={{
                                                backgroundColor: isHovered ? "lightgrey" : "",
                                            }}
                                            onClick={() => (onRowClick ? onRowClick(data as unknown as T) : undefined)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={data.id}
                                            selected={isItemSelected}
                                        >
                                            {checkboxSelection && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onChange={(e) =>
                                                            onRowSelected
                                                                ? onRowSelected(data.id as number, e.target.checked)
                                                                : undefined
                                                        }
                                                    />
                                                </TableCell>
                                            )}
                                            {Object.entries(model).map(
                                                ([property, { decorator = simple, inTable = true }]) =>
                                                    inTable && (
                                                        <TableCell
                                                            key={property}
                                                            style={
                                                                property === "email"
                                                                    ? { whiteSpace: "normal", wordBreak: "break-word" }
                                                                    : undefined
                                                            }
                                                        >
                                                            {decorator(
                                                                data[property] as string,
                                                                property,
                                                                data as unknown as T,
                                                            )}
                                                        </TableCell>
                                                    ),
                                            )}
                                            {canDelete && (
                                                <TableCell>
                                                    {userStatus === "Archived" ? (
                                                        <SecondaryButton onClick={() => setItemToRestore(data)}>
                                                            Restore
                                                        </SecondaryButton>
                                                    ) : (
                                                        <SecondaryButton onClick={() => setItemToDelete(data)}>
                                                            Delete
                                                        </SecondaryButton>
                                                    )}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            {onExport || pagination ? (
                <Grid container alignItems="center">
                    {onExport && (
                        <Grid item xs={6}>
                            <SecondaryButton onClick={onExport}>Export to CSV</SecondaryButton>
                        </Grid>
                    )}
                    {pagination && (
                        <Grid item xs={4}>
                            <TablePagination<"div">
                                component="div"
                                count={total}
                                rowsPerPage={10}
                                page={currentPage}
                                onPageChange={onPageChange}
                                rowsPerPageOptions={[]}
                            />
                        </Grid>
                    )}
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
export default PagedList;
