import { Classroom } from "common/build/prisma/client";
import { objectToQueryString } from "../../utils/api";
import { getData, patchData, postData } from "./rest";

export const add = async (classroomDetails: Partial<Classroom>): Promise<Response> =>
    await postData(`/classroom`, { ...classroomDetails });

export const get = async (id: number, args: Record<string, unknown>): Promise<Classroom> =>
    await getData(`/classroom/${id}${objectToQueryString(args)}`);

export const list = async (args: Record<string, unknown>): Promise<{ items: Classroom[]; total: number }> =>
    await getData(`/classroom${objectToQueryString(args)}`);

export const update = async (id: number, classroomDetails: Partial<Classroom>): Promise<Response> =>
    await patchData(`/classroom/${id}`, { ...classroomDetails });

export const addParticipant = async (
    id: number,
    studentDetails: {
        id: number;
        name?: string;
        email?: string | null;
        phoneNumber?: string | null;
        organisationId?: number;
    },
): Promise<Response> => await postData(`/classroom/${id}/student`, { ...studentDetails });

export const addTeacher = async (id: number, userId: number): Promise<Response> =>
    await postData(`/classroom/${id}/teacher`, { userId });

export const removeTeacher = async (id: number, userId: number): Promise<Response> =>
    await postData(`/classroom/${id}/teacher/remove`, { userId });

export const addMood = async (id: number, participantDetails: Record<string, unknown>): Promise<Response> =>
    await postData(`/classroom/${id}/student/add/mood`, { ...participantDetails });

export const editClassroom = async (id: number, classroomId: number): Promise<Response> =>
    await postData(`/classroom/${id}/edit`, { classroomId });
