import React, { Component } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { WrapperComponent } from "@testing-library/react-hooks";
import Spinner from "../../layout/Spinner";
import { useParticipantAuth } from "../../../contexts/participant-auth.context";

type ParticipantRouteProps = {
    isPublic?: boolean;
    layout?: WrapperComponent<unknown>;
    component: React.FC | Component;
} & RouteProps;

export const ParticipantRoute: React.FC<ParticipantRouteProps> = ({
    isPublic = false,
    layout: Layout,
    component: Component,
    ...routeProps
}) => {
    const { isAuthenticated, isAuthenticating } = useParticipantAuth();
    return (
        <Route
            {...routeProps}
            render={(props) => {
                if (!isAuthenticated && !isPublic) {
                    if (isAuthenticating) {
                        return <Spinner />;
                    }
                    return <Redirect to="/sign-in/" />;
                }

                return (
                    <>
                        {Layout ? (
                            <Layout>
                                <Component {...props} />
                            </Layout>
                        ) : (
                            <Component {...props} />
                        )}
                    </>
                );
            }}
        />
    );
};
