import { Participant, SurveyType } from "common/build/prisma/client";
import { getData, postData } from "../rest";
import { objectToQueryString } from "../../../utils/api";
import { ListParameters } from "../../../../../common/src/api-parameters/common";
import { PublicParticipantFetchParameters } from "../../../../../common/src/api-parameters/participants";

export const list = async (args: ListParameters): Promise<{ items: Participant[]; total: number }> =>
    await getData(`/public/participant${objectToQueryString(args)}`);

export const get = async (id: number, args: PublicParticipantFetchParameters): Promise<Participant> =>
    await getData(`/public/participant/${id}${objectToQueryString(args)}`);

export const surveyOptOut = async (id: number, programmeId: number): Promise<Response> =>
    await postData(`/public/participant/${id}/programme/${programmeId}/survey-opt-out`);

export const surveyComplete = async (id: number, programmeId: number, surveyType: SurveyType): Promise<Response> =>
    await postData(`/public/participant/${id}/programme/${programmeId}/complete-survey`, { surveyType });

export const validateInvite = async (jwt: string): Promise<Response> =>
    await postData(`/public/participant/validate-invite`, { jwt: jwt });
