import React from "react";
import { Dialog } from "@material-ui/core";
import { DialogTitle } from "./PathwayModal";
import MuiDialogContent from "@material-ui/core/DialogContent";

interface SpecialTrainingDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const SpecialTrainingDialog: React.FC<SpecialTrainingDialogProps> = (
    props: SpecialTrainingDialogProps,
): JSX.Element => {
    const { open, setOpen } = props;
    return (
        <>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle id="collection-special-training-dialog" onClose={() => setOpen(false)}>
                    Specialist Training
                </DialogTitle>
                <MuiDialogContent>
                    <p>
                        This collection requires specialist training. This is normally available to Social Workers and
                        specialist Teachers within schools working with vulnerable children & young people. Ensure the
                        programme you are creating is for these type of users.
                    </p>

                    <p>
                        You will also need to ensure that the facilitator delivering this session has specialist
                        training as they won&apos;t be able to deliver the session without it
                    </p>
                </MuiDialogContent>
            </Dialog>
        </>
    );
};
