import React, { useState } from "react";
import { AddProgrammeType } from "../../programme/wizard/steps/ProgrammeDetails";
import dayjs from "dayjs";
import { useProgramme } from "../../programme/contexts/programme.context";
import { Formik } from "formik";
import * as yup from "yup";
import { WizardHeading } from "../../../core/components/WizardHeading";
import { ProgrammeFrequency } from "../../programme/entities/ProgrammeDetails";
import { useClassroom } from "../contexts/classroom.context";
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FormActions } from "../../../core/components/FormActions";
import { PrimaryButton } from "../../layout/Button/Button";
import { useHistory } from "react-router-dom";
import { addClassroomProgramme, list } from "../../../client/api/programme";

export const ClassProgrammeDetails = (): JSX.Element => {
    const history = useHistory();
    const { details, submitStep } = useProgramme();

    const { classroom } = useClassroom();

    const { name, startDate, frequency } = details;

    const [error, setError] = useState();
    const [loading, setLoading] = useState<boolean>(false);

    const onSubmit = (values: Partial<AddProgrammeType>) => {
        setLoading(true);
        const { date, time, ...programmeDetails } = values;
        const newDate = dayjs(`${date} ${time}`, "YYYY-MM-DD HH:mm").toDate();
        const stepData = {
            ...programmeDetails,
            startDate: newDate,
            storyCollectionId: details.storyCollectionId,
            ageRange: classroom?.ageRange,
            isGroup: true,
            isOnline: false,
            classroomId: classroom?.id,
        };
        submitStep(stepData);

        addClassroomProgramme(stepData)
            .then(() => {
                setError(undefined);
                setLoading(false);
                history.push(`/classrooms/view/${classroom?.id}`);
            })
            .catch((error) => {
                setError(error);
            });
    };

    return (
        <form>
            <WizardHeading overline={"Schedule a programme"} heading={"Programme details"} />
            <Formik
                initialValues={{
                    name: name ?? "",
                    date: dayjs(startDate).format("YYYY-MM-DD") ?? dayjs(new Date()).format("YYYY-MM-DD"),
                    time: dayjs(startDate).format("HH:mm") ?? "08:30",
                    frequency: frequency ?? ProgrammeFrequency.Weekly,
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    name: yup
                        .string()
                        .required("Programme name is required")
                        .test(
                            "unique",
                            "Programme with this name already exists at this date and time.",
                            (value, testContext) => {
                                if (!!testContext.parent.date && !!testContext.parent.time) {
                                    return new Promise((resolve) => {
                                        list({
                                            name: value,
                                            startDate: dayjs(
                                                `${testContext.parent.date} ${testContext.parent.time}`,
                                                "YYYY-MM-DD HH:mm",
                                            ).toDate(),
                                            take: 1,
                                        }).then((values) => {
                                            setTimeout(() => resolve(values?.total === 0));
                                        });
                                    });
                                }
                                return true;
                            },
                        ),
                    date: yup.string().required("A Start Date is required"),
                    time: yup.string().required("A Start Time is required"),
                    frequency: yup.string().required("Frequency is required"),
                })}
                validateOnChange={false}
                enableReinitialize
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <Grid container spacing={3}>
                            <Grid item xs={12} className="inputProgrammeName">
                                <TextField
                                    autoFocus
                                    required
                                    name="name"
                                    variant="outlined"
                                    label="Programme Name"
                                    value={values.name}
                                    fullWidth
                                    onChange={handleChange}
                                    helperText={errors.name && touched.name && errors.name}
                                    error={!!errors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={6} className="inputProgrammeStartDate">
                                        <TextField
                                            name="date"
                                            id="date"
                                            label="Start Date"
                                            type="date"
                                            variant="outlined"
                                            value={values.date}
                                            onChange={handleChange}
                                            helperText={errors.date && touched.date && errors.date}
                                            error={!!errors.date}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6} className="inputProgrammeStartTime">
                                        <TextField
                                            name="time"
                                            id="time"
                                            label="Start Time"
                                            type="time"
                                            variant="outlined"
                                            inputProps={{
                                                step: 300,
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={values.time}
                                            onChange={handleChange}
                                            helperText={errors.time && touched.time && errors.time}
                                            error={!!errors.time}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="inputProgrammeFrequency">
                                        <FormControl variant="outlined" error={!!errors.frequency} fullWidth>
                                            <InputLabel id="frequency-label" required>
                                                Frequency
                                            </InputLabel>
                                            <Select
                                                labelId="frequency-label"
                                                name="frequency"
                                                id="frequency"
                                                value={values.frequency}
                                                label="Frequency"
                                                onChange={(e) => setFieldValue("frequency", e.target.value)}
                                                fullWidth
                                            >
                                                {Object.entries(ProgrammeFrequency).map(([value, label]) => {
                                                    return (
                                                        <MenuItem key={value} value={value}>
                                                            {label}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                            <FormHelperText>
                                                {errors.frequency && touched.frequency && errors.frequency}
                                            </FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                Input fields marked with an asterisk (*) are required
                            </Grid>

                            <Grid item xs={12}>
                                {error && (
                                    <Box mb={2}>
                                        <Alert severity="error">{error}</Alert>
                                    </Box>
                                )}
                                <FormActions
                                    right={
                                        <>
                                            <PrimaryButton
                                                size="large"
                                                disabled={isSubmitting || loading}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    submitForm();
                                                }}
                                                className="createProgrammeButton"
                                            >
                                                {!loading ? "Continue" : <CircularProgress />}
                                            </PrimaryButton>
                                        </>
                                    }
                                />
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </form>
    );
};
