import { createStyles, makeStyles } from "@material-ui/core";
import { publicTheme } from "../../layout/themes/public.theme";
import LargeV from "../../../img/large-v.png";

const styles = createStyles({
    root: {
        display: "flex",
        flex: 1,
        background: publicTheme.palette.primary.main,
        backgroundImage: `url(${LargeV})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "bottom",
        [publicTheme.breakpoints.up("md")]: {
            backgroundImage: "none",
        },
        padding: publicTheme.spacing(2),
        "& label": {
            color: "white",
        },
        "& label.Mui-focused": {
            color: "white",
        },
        "& label.Mui-error, & label.Mui-error .MuiFormLabel-asterisk, & .MuiFormHelperText-root.Mui-error": {
            color: publicTheme.palette.secondary.main,
        },
        "& .MuiOutlinedInput-root": {
            color: "white",
            "& fieldset": {
                borderColor: "white",
            },
            "&:hover fieldset": {
                borderColor: "white",
            },
            "&.Mui-focused fieldset": {
                borderColor: "white",
            },
            "&.Mui-error .MuiOutlinedInput-notchedOutline": {
                borderColor: publicTheme.palette.secondary.main,
                borderWidth: "2PX",
            },
        },
    },
    logo: {
        width: "145px",
        marginBottom: publicTheme.spacing(8),
    },
});

export const useSignUpStyles = makeStyles(styles);
