import { createStyles, makeStyles } from "@material-ui/core/styles";

export const useAvatarStyles = makeStyles(
    createStyles({
        backgroundWhite: {
            backgroundColor: "white",
        },
        backgroundBlack: {
            backgroundColor: "black",
        },
    }),
);
