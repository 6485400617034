import { Box, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { HeadlineFigureWidget } from "../../dashboard/Widgets/Headlines/HeadlineFigureWidget";
import Spinner from "../../layout/Spinner";

export const NumberStatWidget: FC<{
    header: string;
    stat: string;
    secondarytext?: string | null;
    reduceFont?: boolean | null;
    loading: boolean;
}> = (props: {
    header: string;
    stat: string;
    secondarytext?: string | null;
    reduceFont?: boolean | null;
    loading: boolean;
}): JSX.Element => {
    const { header, stat, secondarytext, reduceFont, loading } = props;
    const statClass = reduceFont ? "h3" : "h2";
    return (
        <HeadlineFigureWidget centeredHeader={true} header={header}>
            <Box display={"flex"} flexDirection="row" justifyContent={"center"} alignItems="center">
                {loading ? <Spinner /> : <Typography variant={statClass}>{stat}</Typography>}
            </Box>
            <Box>
                <Typography variant="body1">{secondarytext}</Typography>
            </Box>
        </HeadlineFigureWidget>
    );
};
