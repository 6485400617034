import React, { useState } from "react";
import { Box, Button, CircularProgress, Container, FormControl, TextField, Typography } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Alert } from "@material-ui/lab";
import { useAuth } from "../../contexts/auth.context";

const emailError = "A valid email address is required";

export const Forgot: React.FC = () => {
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const loading = false;
    const { initReset } = useAuth();

    const onSubmit: (values: { email: string }) => Promise<void> = async ({ email }) => {
        try {
            const response = await initReset({ email });
            setSuccess(response);
            setError(!response);
        } catch (error) {
            console.error(error);
            setError(true);
        }
    };

    if (success) {
        return (
            <Container maxWidth="sm">
                <Box mb={4}>
                    <Typography variant="h2" component="h1" color="textPrimary" align="left">
                        Reset Your Password
                    </Typography>
                </Box>
                <Alert severity="success">
                    Password reset process begun - Please check your email for a reset link.
                </Alert>
            </Container>
        );
    }

    return (
        <Container maxWidth="sm">
            <Box mb={4}>
                <Typography variant="h2" component="h1" color="textPrimary" align="left">
                    Reset Your Password
                </Typography>
            </Box>
            <Box mb={4}>
                <Typography color="textSecondary" align="left">
                    Enter your email address below. We will send an email to this address to securely reset your
                    password.
                </Typography>
            </Box>
            <Formik
                initialValues={{
                    email: "",
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    email: yup.string().required(emailError).email(emailError).max(100),
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting }) => {
                    return (
                        <Form>
                            <FormControl fullWidth margin={"normal"}>
                                <TextField
                                    autoFocus
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    variant="outlined"
                                    value={values.email}
                                    onChange={handleChange}
                                    helperText={errors.email && touched.email && errors.email}
                                    error={errors.email ? true : false}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </FormControl>
                            {error && (
                                <Alert severity="error">
                                    Unable to reset, please check your email address and try again
                                </Alert>
                            )}
                            <FormControl fullWidth margin="normal">
                                <Button
                                    type="submit"
                                    size="large"
                                    color="primary"
                                    variant="contained"
                                    disabled={isSubmitting}
                                    onClick={submitForm}
                                    fullWidth
                                >
                                    {!loading ? "Email me a Reset Link" : <CircularProgress />}
                                </Button>
                            </FormControl>
                        </Form>
                    );
                }}
            </Formik>
        </Container>
    );
};
