import React from "react";
import Button from "@material-ui/core/Button";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { Box, ThemeProvider } from "@material-ui/core";
import { SurveyDialogView, useSurveyDialog } from "../../public/hooks/surveyDialog.context";
import { VDialog } from "../../../core/components/VDialog";
import OptOutImg from "../../../img/opt-out.svg";

export const SurveyDialogOptOut = (): JSX.Element => {
    const { changeView, optOut, openDialog, shouldOpenDialog } = useSurveyDialog();

    return (
        <ThemeProvider theme={verbalTheme}>
            <VDialog
                icon={<img src={OptOutImg} />}
                open={openDialog}
                maxWidth={"xs"}
                header={"Are you sure you want to opt out of surveys?"}
                content={`<p>Replying to our survey helps us continue to do some good in this world, and goodness knows we need more of that. 
                Your response will tell us if our programmes are effective and how to improve them, that way you're doing your bit too!</p>
                   <p>We get it; some people need to opt-out. No problem. We genuinely hope you enjoy and benefit from our programme.</p>    
                    <p>The Verbal Team</p>`}
                actions={
                    <>
                        <Box mb={2}>
                            <Button
                                variant="contained"
                                color="primary"
                                size={"large"}
                                onClick={() => {
                                    optOut();
                                    changeView(SurveyDialogView.OptedOut);
                                }}
                            >
                                Yes, opt me out
                            </Button>
                        </Box>
                        <Button onClick={() => shouldOpenDialog(false)} variant="text" size={"large"} color="primary">
                            No thanks
                        </Button>
                    </>
                }
            />
        </ThemeProvider>
    );
};
export default SurveyDialogOptOut;
