import React from "react";
import { SurveyDialogView, useSurveyDialog } from "../../public/hooks/surveyDialog.context";
import SurveyDialogMain from "./SurveyDialogMain";
import SurveyDialogOptedOut from "./SurveyDialogOptedOut";
import SurveyDialogOptOut from "./SurveyDialogOptOut";

export const SurveyDialog = (): JSX.Element => {
    const { view } = useSurveyDialog();

    switch (view) {
        case SurveyDialogView.Main:
            return <SurveyDialogMain />;
        case SurveyDialogView.OptOut:
            return <SurveyDialogOptOut />;
        case SurveyDialogView.OptedOut:
            return <SurveyDialogOptedOut />;
        default:
            return <SurveyDialogMain />;
    }
};
export default SurveyDialog;
