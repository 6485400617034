import React, { Fragment, useEffect, useState } from "react";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { useCrud } from "../../../../core/components/crud/contexts/crud.context";
import { MappedStoryType, StoryCollectionType, WeekType } from "../../../../models/modelTypes";
import {
    Avatar,
    Box,
    Button,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Paper,
    Select,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../core/components/Breadcrumb";
import Spinner from "../../../layout/Spinner";
import { Error } from "../../../../core/components/Error";
import { PathwayCard } from "../../../programme/wizard/steps/PathwayCard";
import { Alert, Skeleton } from "@material-ui/lab";
import { VPill, VPillAmber, VPillPositive } from "../../../../core/components/VPill";
import { useHistory } from "react-router";
import { useAuth } from "../../../../contexts/auth.context";
import { useStoryCollection } from "../contexts/collection.context";
import { DestructiveButton, PrimaryButton } from "../../../layout/Button/Button";
import { TabPanel } from "../../../programme/screens/ViewProgramme";
import { date } from "../../../../utils/date";
import { Close } from "@material-ui/icons";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useStory } from "../../../../hooks/useStory";
import { VTooltip } from "../../../../core/components/VTooltip";
import { PreviewDialog } from "./PreviewDialog";

type ViewCollectionProps = {
    labelProperty?: string;
};

export const ViewCollection = (props: ViewCollectionProps): JSX.Element => {
    const { labelProperty } = props;
    const { approveCollection, reviewCollection, addCollectionStory, collection, setCurrentCollection } =
        useStoryCollection();
    const { item, fetching, fetchItem } = useFetch<StoryCollectionType>();
    const { noun } = useCrud<StoryCollectionType>();
    const history = useHistory();
    const { hasPermission } = useAuth();
    const { stories, listStories } = useStory();

    const [error, setError] = useState<boolean>(false);
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [isReviewed, setIsReviewed] = useState<boolean>(false);
    const [confirmApprove, setConfirmApprove] = useState<boolean>(false);
    const [confirmReview, setConfirmReview] = useState<boolean>(false);
    const [confirmEdit, setConfirmEdit] = useState<boolean>(false);

    // learning objective tooltips
    const [learnTooltip, setLearnTooltip] = useState<Map<number, string>>(new Map());

    const [tabValue, setTabValue] = useState<number>(0);

    // Preview Delivery
    const [openPreview, setOpenPreview] = useState<boolean>(false);
    const [previewStory, setPreviewStory] = useState<MappedStoryType>();

    // Select story
    const [openSelectStoryDialog, setOpenSelectStoryDialog] = useState<boolean>(false);
    const [currentWeek, setCurrentWeek] = useState<Partial<WeekType> & { storyId?: number }>();

    const isStoriesNotPublished = item?.Stories?.some((story) => !story.published);

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        if (parseInt(id)) {
            fetchItem(parseInt(id))
                .then(() => {
                    setError(false);
                })
                .catch(() => setError(true));
        }
    }, [id, collection]);

    useEffect(() => {
        listStories();
    }, []);

    // Tooltips moved here due to react re-render limits
    useEffect(() => {
        if (item && item.Weeks && item.Weeks.length > 0) {
            item.Weeks.forEach((week) =>
                setLearnTooltip(new Map(learnTooltip.set(week.order, week.learningObjective))),
            );
        }
    }, [item]);

    if (error) {
        return <Error description={`Unable to load ${noun}`} />;
    }

    const handleApproveCollection = () => {
        if (item && item.id) {
            approveCollection(item.id).then(() => {
                setIsApproved(true);
                setConfirmApprove(false);
            });
        }
    };

    const handleReviewCollection = () => {
        if (item && item.id) {
            reviewCollection(item.id).then(() => {
                setIsReviewed(true);
                setConfirmReview(false);
            });
        }
    };

    const handleAddStory = () => {
        if (item && currentWeek && currentWeek.order && currentWeek.storyId) {
            addCollectionStory(item.id, { order: currentWeek.order, storyId: currentWeek.storyId }).then(() => {
                setOpenSelectStoryDialog(false);
                //fetchItem(item.id),
            });
        }
    };

    const handleEditCollection = () => {
        if (item && item.id) {
            setCurrentCollection(item);
            history.push(`/collections/edit/${item.id}`);
        }
    };

    const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
        setTabValue(newValue);
    };

    const useStyles = makeStyles(() =>
        createStyles({
            header: {
                backgroundColor: "#F9F9FB",
            },
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
        }),
    );
    const classes = useStyles();

    return (
        <>
            <Box p={2} className={classes.header}>
                <Box mb={2}>
                    <Breadcrumb
                        current={labelProperty && item && item[labelProperty] ? `${item[labelProperty]}` : `${noun}`}
                    />
                </Box>
                <Box>
                    {!fetching && item ? (
                        <PathwayCard key={item.id} storyCollection={item} />
                    ) : (
                        <Box display={"flex"} alignItems={"center"} height={"100%"} width={"100%"}>
                            <Skeleton variant="rect" height="100%" width="100%" />
                        </Box>
                    )}
                </Box>
            </Box>

            {!fetching && item ? (
                <>
                    <Tabs value={tabValue} onChange={handleTabChange} textColor="primary">
                        <Tab label="Weeks" id="weeks-tab" />
                        <Tab label="Meta" id="meta-tab" />
                    </Tabs>
                    <TabPanel index={0} value={tabValue}>
                        {hasPermission("StoryCollectionUpdate") ? (
                            <Box pl={1}>
                                <Button variant="outlined" color="secondary" onClick={() => setConfirmEdit(true)}>
                                    Edit
                                </Button>
                            </Box>
                        ) : null}

                        {!isReviewed &&
                        !item.approved &&
                        !isStoriesNotPublished &&
                        !item.reviewed &&
                        hasPermission("StoryCollectionReview") ? (
                            <Box pl={3} pt={2}>
                                <Button variant="outlined" color="secondary" onClick={() => setConfirmReview(true)}>
                                    Submit for review
                                </Button>
                            </Box>
                        ) : null}

                        {item.reviewed &&
                        !isApproved &&
                        !item.approved &&
                        !isStoriesNotPublished &&
                        hasPermission("StoryCollectionPublish") ? (
                            <Box pl={3} pt={2}>
                                <Button variant="contained" color="secondary" onClick={() => setConfirmApprove(true)}>
                                    Approve for use
                                </Button>
                            </Box>
                        ) : null}

                        {item.Weeks?.length && item.Weeks.length > 0 ? (
                            <Box p={1}>
                                <Alert
                                    severity="info"
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: verbalTheme.spacing(2),
                                    }}
                                >
                                    <ul>
                                        <li>
                                            Hover over the <span style={{ color: "#E84848" }}>Week</span> number to see
                                            the learning objective for that week.
                                        </li>
                                        <li>
                                            Click the pill in the <span style={{ color: "#E84848" }}>State</span> column
                                            to annotate the story sections with questions.
                                        </li>
                                        <li>
                                            Click the eye icon in the <span style={{ color: "#E84848" }}>Preview</span>{" "}
                                            column to preview how the story will look for the facilitator.
                                        </li>
                                    </ul>
                                </Alert>
                                <TableContainer component={Paper} square>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Week</TableCell>
                                                <TableCell>Theme</TableCell>
                                                <TableCell>Story</TableCell>
                                                <TableCell align="center">Parts</TableCell>
                                                <TableCell align="center">State</TableCell>
                                                <TableCell align="center">Preview</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {item.Weeks.map((week) => {
                                                const story = item.Stories?.filter(
                                                    (story) => story?.order === week?.order,
                                                )?.[0];

                                                return (
                                                    <TableRow key={week.id}>
                                                        <TableCell align="center">
                                                            <VTooltip title={learnTooltip.get(week.order) ?? ""}>
                                                                <Avatar
                                                                    style={{
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        border: "none",
                                                                        outline: "inherit",
                                                                        backgroundColor:
                                                                            verbalTheme.palette.primary.main,
                                                                        cursor: "pointer",
                                                                    }}
                                                                >
                                                                    {week.order}
                                                                </Avatar>
                                                            </VTooltip>
                                                        </TableCell>
                                                        <TableCell>{week?.Theme?.name ?? "-"}</TableCell>
                                                        <TableCell>
                                                            {story?.Story?.storyName ?? (
                                                                <VPill
                                                                    variant="outlined"
                                                                    color="primary"
                                                                    label={"Select Story"}
                                                                    onClick={() => {
                                                                        setCurrentWeek(week);
                                                                        setOpenSelectStoryDialog(true);
                                                                    }}
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {story?.Story?.StoryParts?.length &&
                                                            story?.Story.StoryParts?.length > 0
                                                                ? story?.Story.StoryParts?.length
                                                                : "-"}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {story === undefined ? (
                                                                "-"
                                                            ) : story?.published ? (
                                                                <VPillPositive
                                                                    label={"Published"}
                                                                    onClick={() =>
                                                                        hasPermission("StoryCollectionView") &&
                                                                        history.push(`/map/story/${story?.id}`)
                                                                    }
                                                                />
                                                            ) : (
                                                                <VPillAmber
                                                                    label={"Draft"}
                                                                    onClick={() =>
                                                                        hasPermission("StoryCollectionView") &&
                                                                        history.push(`/map/story/${story?.id}`)
                                                                    }
                                                                />
                                                            )}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {story ? (
                                                                <IconButton
                                                                    style={{
                                                                        padding: 0,
                                                                        margin: 0,
                                                                        backgroundColor: "transparent",
                                                                    }}
                                                                    color="primary"
                                                                    onClick={() => {
                                                                        setPreviewStory(story);
                                                                        setOpenPreview(true);
                                                                    }}
                                                                >
                                                                    <VisibilityIcon fontSize="large" />
                                                                </IconButton>
                                                            ) : (
                                                                "-"
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        ) : (
                            <Box p={3}>
                                <Alert severity="warning">
                                    There are no stories in this collection. Please contact a story admin
                                </Alert>
                            </Box>
                        )}
                        <Dialog open={confirmApprove} onClose={() => setConfirmApprove(false)}>
                            <DialogTitle>Approve collection for use in programmes?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Approving this collection means that it can be used to create programmes. Have you
                                    reviewed each story and the related questions for each? Once this collection is
                                    approved, the collection, stories and questions cannot be edited or changed. Please
                                    give all of these a thorough review before approving this collection.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <PrimaryButton autoFocus onClick={() => setConfirmApprove(false)}>
                                    Cancel
                                </PrimaryButton>
                                <DestructiveButton onClick={handleApproveCollection}>Approve</DestructiveButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={confirmReview} onClose={() => setConfirmReview(false)}>
                            <DialogTitle>Submit collection for review?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Submitting this collection for review will notify all story publishers to give this
                                    collection a final review. Please ensure you have reviewed each story and the
                                    related questions for each story.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <PrimaryButton autoFocus onClick={() => setConfirmReview(false)}>
                                    Cancel
                                </PrimaryButton>
                                <DestructiveButton onClick={handleReviewCollection}>Submit</DestructiveButton>
                            </DialogActions>
                        </Dialog>

                        <Dialog open={confirmEdit} onClose={() => setConfirmEdit(false)}>
                            <DialogTitle>Edit collection details?</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    Editing the collection will allow you to update the collection name, age range,
                                    description and associated curriculum. If you choose to edit, you will be brought to
                                    the edit collection screen. Do you wish to edit the collection now?
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <PrimaryButton autoFocus onClick={() => setConfirmEdit(false)}>
                                    Cancel
                                </PrimaryButton>
                                <DestructiveButton onClick={handleEditCollection}>Edit</DestructiveButton>
                            </DialogActions>
                        </Dialog>
                    </TabPanel>

                    <TabPanel index={1} value={tabValue}>
                        <Grid container spacing={3}>
                            {item?.createdDate ? (
                                <Grid item xs={12}>
                                    <Typography variant="h6">Created on</Typography>
                                    <Typography>{date(item?.createdDate)}</Typography>
                                </Grid>
                            ) : null}

                            {item?.ReviewUser?.firstName ? (
                                <Grid item xs={12}>
                                    <Typography variant="h6">Reviewed By</Typography>
                                    <Typography>{`${item?.ReviewUser?.firstName} ${item?.ReviewUser?.lastName ?? ""} ${
                                        item?.ReviewUser?.email ? `/ ${item.ReviewUser.email}` : ""
                                    }`}</Typography>
                                </Grid>
                            ) : null}

                            {item?.PublishUser?.firstName ? (
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">Approved By</Typography>
                                    <Typography>{`${item?.PublishUser?.firstName} ${
                                        item?.PublishUser?.lastName ?? ""
                                    } ${item?.PublishUser?.email ? `/ ${item.PublishUser.email}` : ""}`}</Typography>
                                </Grid>
                            ) : null}

                            {item?.approvedDate ? (
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">Approved on</Typography>
                                    <Typography>{date(item?.approvedDate)}</Typography>
                                </Grid>
                            ) : null}
                        </Grid>
                    </TabPanel>

                    <PreviewDialog
                        openPreview={openPreview}
                        setOpenPreview={setOpenPreview}
                        previewStory={previewStory}
                    />

                    <Dialog open={openSelectStoryDialog}>
                        <MuiDialogTitle>
                            <Typography variant="h4" component="h3">
                                Select Story for <strong>Week {currentWeek?.order}</strong>
                            </Typography>
                            <IconButton
                                className={classes.closeButton}
                                aria-label="close"
                                onClick={() => setOpenSelectStoryDialog(false)}
                            >
                                <Close />
                            </IconButton>
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <Paper style={{ padding: verbalTheme.spacing(2) }}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Learning Objective
                                        </Typography>
                                        <Typography gutterBottom>{currentWeek?.learningObjective}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Theme
                                        </Typography>
                                        <VPillPositive size="small" label={currentWeek?.Theme?.name} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography variant="h6" gutterBottom>
                                            Story
                                        </Typography>
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="story-label" required>
                                                Story for this week
                                            </InputLabel>
                                            <Select
                                                variant="outlined"
                                                style={{
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "pre",
                                                    wordBreak: "break-all",
                                                }}
                                                label="Story for this week"
                                                labelId="story-label"
                                                onChange={(
                                                    event: React.ChangeEvent<{
                                                        name?: string;
                                                        value: unknown;
                                                    }>,
                                                ) =>
                                                    setCurrentWeek({
                                                        ...currentWeek,
                                                        storyId: event.target.value as number,
                                                    })
                                                }
                                                value={currentWeek?.storyId}
                                            >
                                                {stories.map((story) => (
                                                    <MenuItem
                                                        style={{
                                                            whiteSpace: "unset",
                                                            wordBreak: "break-word",
                                                        }}
                                                        key={story.id}
                                                        value={story.id}
                                                    >
                                                        {story.storyName}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                        Input fields marked with an asterisk (*) are required
                                    </Grid>
                                </Grid>
                            </Paper>
                        </MuiDialogContent>
                        <DialogActions>
                            <DestructiveButton
                                onClick={() => {
                                    setCurrentWeek(undefined);
                                    setOpenSelectStoryDialog(false);
                                }}
                            >
                                Cancel
                            </DestructiveButton>
                            <PrimaryButton
                                onClick={() => {
                                    handleAddStory();
                                }}
                            >
                                Save
                            </PrimaryButton>
                        </DialogActions>
                    </Dialog>
                </>
            ) : (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Spinner />
                </Box>
            )}
        </>
    );
};
export default ViewCollection;
