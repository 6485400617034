import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { MappedStoryType, SessionType } from "../../../models/modelTypes";
import { VModule, VModuleType } from "../../../core/components/VModule";
import { VGridContainer } from "../../layout/Grid/VGridContainer";
import { date } from "../../../utils/date";
import { RelatedStoriesCard } from "../../../components/session/components/RelatedStoriesCard";
import { useHistory } from "react-router-dom";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useProgrammeParticipant } from "../../participant/ProgrammeParticipant.context";
import { useEffect } from "react";
import { sortSessions } from "../../../utils/sorts";
interface NextSessionCardProps {
    sessions: SessionType[];
    previous?: boolean;
    showRelatedStories?: boolean;
    buttonVariant?: "text" | "outlined" | "contained";
    onViewNextSessionDetails?: (nextSession: SessionType) => void;
    onClickRelatedStory?: (story?: MappedStoryType) => void;
}

export const ParticipantSessionCard: React.FC<NextSessionCardProps> = (props: NextSessionCardProps) => {
    const {
        sessions,
        previous,
        showRelatedStories = true,
        onViewNextSessionDetails,
        buttonVariant,
        onClickRelatedStory,
    } = props;
    const history = useHistory();
    const orderedSessions = [...sessions].sort(sortSessions);
    const { configureNextSession, configurePreviousSession, configureSurveyState, previousSession, nextSession } =
        useProgrammeParticipant();

    const session = previous ? previousSession : nextSession;
    configurations(orderedSessions, configurePreviousSession, configureNextSession, configureSurveyState);

    if (!session) {
        return <VGridContainer item xs={12}></VGridContainer>;
    }

    const useStyles = makeStyles(
        createStyles({
            card: {
                overflow: "hidden",
            },
        }),
    );

    useEffect(() => {
        configurations(orderedSessions, configurePreviousSession, configureNextSession, configureSurveyState);
    }, [orderedSessions, configurePreviousSession, configureNextSession, configureSurveyState]);

    const classes = useStyles();

    return (
        <Grid item xs={12} md={previousSession ? 6 : 12}>
            <VModule className={classes.card} padded={false} type={VModuleType.Shade2}>
                <Box p={3}>
                    <Box mb={2}>
                        <Typography variant="h6" color={"textSecondary"} gutterBottom>
                            {previous ? "Last" : "Next"} Session
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            {session.isOnline ? "Online" : "In Person"} / {date(session.date)}
                        </Typography>
                    </Box>
                    <Box mb={2}>
                        <Typography variant="h5" gutterBottom>
                            {session.MappedStory?.Story?.storyName}
                        </Typography>
                    </Box>

                    <Button
                        variant={buttonVariant}
                        color={"primary"}
                        size={"large"}
                        fullWidth
                        onClick={() => {
                            if (!previous && onViewNextSessionDetails) {
                                onViewNextSessionDetails(session);
                            }
                            if (session.state === "Complete") {
                                history.push(`/read-story/${session.MappedStory?.id}/${session.id}/`);
                            }
                        }}
                    >
                        {session.state === "Complete"
                            ? "Read Now"
                            : session.state === "InProgress"
                            ? "Join Now"
                            : "View Details"}
                    </Button>
                </Box>
                {session.MappedStory?.MyRelatedStories &&
                    session.MappedStory?.MyRelatedStories?.length > 0 &&
                    showRelatedStories && (
                        <RelatedStoriesCard
                            stories={session.MappedStory?.MyRelatedStories}
                            onClickStory={onClickRelatedStory}
                        />
                    )}
            </VModule>
        </Grid>
    );
};
function configurations(
    orderedSessions: SessionType[],
    configurePreviousSession: (previousSession?: SessionType) => void,
    configureNextSession: (nextSession: SessionType) => void,
    configureSurveyState: (isFinalSessionOrAllComplete: boolean, previousSession?: SessionType | undefined) => void,
) {
    const idx = orderedSessions.findIndex((p) => p.state === "InProgress" || p.state === "NotStarted");
    const upcomingSession = orderedSessions[idx];
    const isFinalSession = upcomingSession !== undefined && orderedSessions[idx + 1] === undefined;
    const isAllSessionsComplete = upcomingSession === undefined;
    const previousSession = orderedSessions[isAllSessionsComplete ? orderedSessions.length - 1 : idx - 1] ?? undefined;
    configurePreviousSession(previousSession);
    configureNextSession(upcomingSession);
    configureSurveyState(isFinalSession || isAllSessionsComplete, isFinalSession ? upcomingSession : previousSession);
}
