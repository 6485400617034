import React from "react";
import { Card, CardProps, CardContent, ThemeProvider, Typography, Box } from "@material-ui/core";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";

type VCardProps = CardProps & {
    title?: string;
    subtitle?: string;
    children: React.ReactNode;
};

export const VCard: React.FC<VCardProps> = (props: VCardProps): JSX.Element => {
    const { title, subtitle, children, ...rest } = props;

    return (
        <ThemeProvider theme={verbalTheme}>
            <Card {...rest}>
                <CardContent>
                    <Box mb={2}>
                        {title && <Typography variant="h6">{title}</Typography>}
                        {subtitle && <Typography variant="body2">{subtitle}</Typography>}
                    </Box>
                    {children}
                </CardContent>
            </Card>
        </ThemeProvider>
    );
};
