import React from "react";
import Crud from "../../../core/components/crud/Crud";
import { list, get, add, update } from "../../../client/api/theme";
import { useAuth } from "../../../contexts/auth.context";
import { themeCRUDModel } from "./model";
import { Redirect } from "react-router-dom";
import { ThemeType } from "../../../models/modelTypes";
import { AddTheme } from "./AddTheme";

export const ThemesScreen = (): JSX.Element => {
    const { user, hasPermission } = useAuth();

    if (!user) {
        return <Redirect to="403" />;
    }

    return (
        <Crud<ThemeType, Record<string, unknown>>
            model={themeCRUDModel}
            noun="Story"
            plural="Themes"
            getData={list}
            getItem={get}
            addItem={add}
            labelProperty="name"
            addComponent={AddTheme}
            searchable
            canAdd={hasPermission("StoryCreate")}
            updateItem={update}
        />
    );
};
