import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { SessionType } from "../../../models/modelTypes";
import Edit from "../../../core/components/crud/Edit";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import dayjs from "dayjs";

export const EditSessionForm = (): JSX.Element => {
    const { item } = useFetch<SessionType>();

    const validate = async (values?: SessionType & { time: string; shouldUpdateFutureSessions: boolean }) => {
        const errors = {} as { date?: string; time?: string };

        const newDate = dayjs(`${values?.date} ${values?.time}`, "YYYY-MM-DD HH:mm");
        const allSessions = item?.Programme?.Sessions;
        if (values && !values.shouldUpdateFutureSessions && allSessions) {
            const nextSession = allSessions.find((session) => session.sessionNumber === (item?.sessionNumber ?? 1) + 1);
            const previousSession = allSessions.find(
                (session) => session.sessionNumber === (item?.sessionNumber ?? 1) - 1,
            );

            if (nextSession && dayjs(nextSession.date) < newDate) {
                errors.date = `This session cannot be after your next session, did you mean to check "Arrange all future sessions in the programme?"`;
                errors.time = `This session cannot be after your next session, did you mean to check "Arrange all future sessions in the programme?"`;
            }

            if (previousSession && dayjs(previousSession.date) > newDate) {
                errors.date = `This session cannot be before your previous session`;
                errors.time = `This session cannot be before your previous session`;
            }
        }

        const oldDate = dayjs(item?.date);
        const now = dayjs();
        if (newDate !== oldDate && newDate < now) {
            errors.date = `This session cannot be in the past`;
            errors.time = `This session cannot be in the past`;
        }

        return errors;
    };

    return (
        <Grid item xs={12}>
            <Edit<SessionType & { time: string; shouldUpdateFutureSessions: boolean }>
                validate={validate}
                validateOnBlur={false}
                validateOnChange={false}
                labelProperty={`sessionNumber`}
                crumbs={[
                    { name: "Programmes", url: `/programmes` },
                    { name: item?.Programme?.name ?? "Programme", url: `/programmes/view/${item?.programmeId}` },
                ]}
                subHeading={
                    item?.MappedStory?.Story ? (
                        <>
                            <Typography variant="h2" color="textPrimary" align="left">
                                {item.MappedStory.Story.storyName}
                            </Typography>
                            <Typography variant="h3" color="textPrimary" align="left">
                                By {item?.MappedStory?.Story?.author}
                            </Typography>
                        </>
                    ) : undefined
                }
            />
        </Grid>
    );
};
