import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { VTextLink } from "../../../common/VTextLink";
import { Alert } from "@material-ui/lab";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";
import { HasPermission } from "../../../auth/HasPermission";

export const NoProgrammesAlert: FC = () => {
    const { item, fetching } = useFetch<ActiveProgramStats>();
    const hasOrFetchingPrograms = fetching || item?.programmes?.length;

    if (hasOrFetchingPrograms) {
        return null;
    }

    return (
        <HasPermission permission="ProgrammeCreate">
            <Box mb={2}>
                <Alert severity="info">
                    To get started you should <VTextLink to="/programmes/add">schedule a programme</VTextLink>
                </Alert>
            </Box>
        </HasPermission>
    );
};
