import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    createStyles,
    Dialog,
    Grid,
    IconButton,
    makeStyles,
    TextField,
    Typography,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { Formik } from "formik";
import * as yup from "yup";
import { User } from "common/build/prisma/client";
import { verbalTheme } from "../../../layout/themes/verbal.theme";
import { Alert } from "@material-ui/lab";
import { useFacilitator } from "../../../../hooks/useFacilitator";
import { ProgrammeType } from "../../../../models/modelTypes";
import { Close } from "@material-ui/icons";

type ProgrammeFacilitatorModalProps = {
    programmeId: number;
    open: boolean;
    setOpen: (open: boolean) => void;
    setProgramme: (programme: ProgrammeType) => void;
};

const ProgrammeFacilitatorModal: React.FC<ProgrammeFacilitatorModalProps> = (
    props: ProgrammeFacilitatorModalProps,
): JSX.Element => {
    const [error, setError] = useState<string | undefined>();
    const { addFacilitatorToProgramme, facilitatorProgramme } = useFacilitator();

    useEffect(() => {
        if (facilitatorProgramme) {
            props.setProgramme(facilitatorProgramme);
        }
    }, [facilitatorProgramme]);

    const onSubmit = (values: Partial<User>) => {
        addFacilitatorToProgramme(props.programmeId, values, true)
            .then(() => {
                setError(undefined);
                props.setOpen(false);
            })
            .catch((err) => {
                setError(err);
            });
    };

    const onCancel = () => {
        props.setOpen(false);
        setError(undefined);
    };

    const useStyles = makeStyles(
        createStyles({
            closeButton: {
                position: "absolute",
                right: verbalTheme.spacing(1.5),
                top: verbalTheme.spacing(1.5),
                color: verbalTheme.palette.grey[500],
            },
        }),
    );

    const classes = useStyles();

    return (
        <>
            <Dialog onClose={() => props.setOpen(false)} open={props.open}>
                <MuiDialogTitle>
                    <Typography variant="h4" component="h3">
                        Add a New Facilitator
                    </Typography>
                    <IconButton className={classes.closeButton} aria-label="close" onClick={() => props.setOpen(false)}>
                        <Close />
                    </IconButton>
                </MuiDialogTitle>
                <Formik
                    initialValues={{
                        firstName: "",
                        lastName: "",
                        email: "",
                    }}
                    onSubmit={onSubmit}
                    validationSchema={yup.object({
                        firstName: yup.string().required("First name is required"),
                        lastName: yup.string().required("Last name is required"),
                        email: yup.string().email("Please enter a valid email").required("Email is required").max(100),
                    })}
                >
                    {({ submitForm, errors, values, handleChange, touched }) => {
                        return (
                            <>
                                <MuiDialogContent>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="firstName"
                                                label="First Name"
                                                fullWidth
                                                onChange={handleChange}
                                                value={values.firstName}
                                                variant={"outlined"}
                                                error={!!errors.firstName}
                                                helperText={errors.firstName && touched.firstName && errors.firstName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                name="lastName"
                                                label="Surname"
                                                fullWidth
                                                onChange={handleChange}
                                                value={values.lastName}
                                                variant={"outlined"}
                                                error={!!errors.lastName}
                                                helperText={errors.lastName && touched.lastName && errors.lastName}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                name="email"
                                                label="Email Address"
                                                onChange={handleChange}
                                                fullWidth
                                                value={values.email}
                                                variant={"outlined"}
                                                error={!!errors.email}
                                                helperText={errors.email && touched.email && errors.email}
                                                inputProps={{ maxLength: 100 }}
                                            />
                                        </Grid>
                                        {error && (
                                            <Grid item xs={12} md={12}>
                                                <Box mb={2}>
                                                    <Alert severity="error">{error}</Alert>
                                                </Box>
                                            </Grid>
                                        )}
                                    </Grid>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <Button onClick={onCancel} variant="contained" size="large">
                                        Cancel
                                    </Button>
                                    <Button onClick={submitForm} variant="contained" color={"primary"} size="large">
                                        Invite new facilitator
                                    </Button>
                                </MuiDialogActions>
                            </>
                        );
                    }}
                </Formik>
            </Dialog>
        </>
    );
};
export default ProgrammeFacilitatorModal;
