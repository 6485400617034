import React, { Fragment, useEffect, useState } from "react";
import { Button, TextField, Grid, Typography, Box, DialogContentText, Container } from "@material-ui/core";
import { Formik, FormikHelpers } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Spinner from "../../../components/layout/Spinner";
import { Breadcrumb } from "../Breadcrumb";
import * as yup from "yup";
import { Action, makeGroup } from "./Crud";
import { ConfirmDialog } from "../ConfirmDialog";
import { useFetch } from "./contexts/fetch.context";
import { useCrud } from "./contexts/crud.context";
import AsyncSearch from "../AsyncSearch";
import { Error } from "../Error";
import { DestructiveButton, PrimaryButton, SecondaryButton } from "../../../components/layout/Button/Button";
import { Delete } from "@material-ui/icons";

type EditProps<T> = {
    canDelete?: boolean;
    labelProperty?: string;
    actions?: Action<T>[];
    crumbs?: { name: string; url: string }[];
    subHeading?: JSX.Element;
    validateOnChange?: boolean;
    validateOnBlur?: boolean;
    validate?: (values?: T) => Promise<unknown>;
};

export const Edit = <T extends Record<string, unknown>>(props: EditProps<T>): JSX.Element => {
    const { goBack } = useHistory();

    const { canDelete, labelProperty, actions, crumbs, subHeading, validateOnChange, validateOnBlur, validate } = props;

    const { item, fetchItem } = useFetch<T>();
    const { deleteItem, update, model, noun } = useCrud<T>();

    const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();
    const [loading, setLoading] = useState<boolean>();
    const [saving, setSaving] = useState<boolean>();
    const [fetchError, setFetchError] = useState<boolean>();

    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setLoading(true);
        fetchItem(parseInt(id))
            .then(() => {
                setFetchError(false);
            })
            .catch(() => {
                setFetchError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    const onSave = (
        values: T & {
            [key: string]: string | number;
        },
        formikHelpers: FormikHelpers<
            T & {
                [key: string]: string | number;
            }
        >,
    ) => {
        const { setSubmitting } = formikHelpers;
        setSaving(true);
        update(parseInt(id), getOnSubmitValues(values))
            .then(() => {
                setError(undefined);
                goBack();
            })
            .catch((err) => {
                setError(err);
            })
            .finally(() => {
                setSaving(false);
                setSubmitting(false);
            });
    };

    const onDelete = () => {
        deleteItem(parseInt(id)).then(() => {
            goBack();
        });
    };

    if (loading) {
        return <Spinner />;
    }

    if (fetchError || !item) {
        return <Error description={`Unable to load ${noun}`} />;
    }

    const getValidationSchema = () => {
        return Object.fromEntries(
            Object.entries(model)
                .filter(([, { validation }]) => validation)
                .map(([k, { validation }]) => [
                    k,
                    typeof validation === "function" ? validation(item[k], { ...item }) : validation,
                ]),
        );
    };

    const getOnSubmitValues = (values: T) => {
        return values
            ? (Object.fromEntries(
                  Object.entries(model).map(([k, { submitValueDecorator }]) => [
                      k,
                      submitValueDecorator ? submitValueDecorator(values[k], { ...values }) : values[k],
                  ]),
              ) as T)
            : values;
    };

    const getInitialValues = () => {
        return item
            ? Object.fromEntries(
                  Object.entries(model).map(([k, { inputValueDecorator }]) => [
                      k,
                      inputValueDecorator ? inputValueDecorator(item[k], { ...item }) : item[k],
                  ]),
              )
            : [];
    };

    // ToDo: The biggest TS mess ever. Clean this up. Relates to incorrect types in model again.
    const label =
        item && labelProperty
            ? (model as unknown as Record<string, unknown>)[labelProperty] && !item[labelProperty]
                ? model.hasOwnProperty(labelProperty) &&
                  (
                      (model as Record<string, unknown>)[labelProperty] as {
                          decorator: (a: undefined, b: string, c: T) => string;
                      }
                  ).decorator(undefined, labelProperty, item)
                : item[labelProperty]
            : noun;

    return (
        <>
            <Box p={4}>
                {showDeleteConfirm && (
                    <ConfirmDialog
                        open={showDeleteConfirm}
                        title={`Delete ${noun}`}
                        onConfirm={onDelete}
                        onCancel={() => setShowDeleteConfirm(false)}
                    >
                        <DialogContentText>{`Are you sure you want to delete ${label}?`}</DialogContentText>
                    </ConfirmDialog>
                )}
                <Container maxWidth={"md"}>
                    <Box mb="3rem">
                        <Box mb={3}>
                            <Breadcrumb crumbs={crumbs} current={`Editing ${noun} ${label}`} />
                        </Box>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between">
                                <Grid item xs={12}>
                                    <Typography variant="h1" color="textPrimary" align="left">
                                        {`Editing ${noun} ${label}`}
                                    </Typography>
                                    <Box mt={3}>{subHeading}</Box>
                                </Grid>
                                <Grid item>
                                    {canDelete && (
                                        <Button
                                            size="small"
                                            color="primary"
                                            startIcon={<Delete />}
                                            disabled={saving || !id}
                                            onClick={() => setShowDeleteConfirm(true)}
                                        >
                                            {`Delete ${noun}`}
                                        </Button>
                                    )}
                                    {id &&
                                        item &&
                                        actions &&
                                        actions.length > 0 &&
                                        actions.map(({ label, onClick, visible }) => {
                                            const isVisible = !visible || visible(item);

                                            if (!isVisible) {
                                                return null;
                                            } else {
                                                return (
                                                    <DestructiveButton
                                                        key={`action-${label}`}
                                                        size="small"
                                                        startIcon={<Delete />}
                                                        onClick={() => onClick(parseInt(id), item)}
                                                    >
                                                        {label}
                                                    </DestructiveButton>
                                                );
                                            }
                                        })}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Formik
                        validate={validate}
                        initialValues={getInitialValues() as T & { [key: string]: string | number }}
                        onSubmit={onSave}
                        validateOnChange={validateOnChange}
                        validateOnBlur={validateOnBlur}
                        validationSchema={yup.object(
                            getValidationSchema() as {
                                [key: string]: yup.AnySchema<unknown, unknown, unknown>;
                            },
                        )}
                    >
                        {({ submitForm, values, handleChange, isSubmitting, errors, setFieldValue }) => {
                            let currentGroup: Record<string, unknown> | undefined = undefined;
                            let currentGroupInputs: JSX.Element[] = [];

                            return (
                                <>
                                    <Grid container alignItems="flex-start" spacing={2}>
                                        {Object.entries(model)
                                            .filter(([, { inForm = true }]) => inForm)
                                            .map(([property, { label = `${property[0].toUpperCase() + property
                                                            .substring(1)
                                                            .toLowerCase()}`, InputComponent = TextField, inputProps = {}, disabled, inputGroup }], index) => {
                                                if (InputComponent === AsyncSearch) {
                                                    inputProps = {
                                                        ...inputProps,
                                                        setFieldValue: (property: string, value: unknown) =>
                                                            setFieldValue(property, value),
                                                        onSelected: (property: string, value: string) =>
                                                            setFieldValue(
                                                                property,
                                                                value ? parseInt(value) : undefined,
                                                            ),
                                                    };
                                                } else {
                                                    inputProps = {
                                                        ...(index === 0 && InputComponent === TextField
                                                            ? { autoFocus: true }
                                                            : undefined),
                                                        ...inputProps,
                                                        setFieldValue: (
                                                            property: string,
                                                            value: unknown,
                                                            shouldValidate?: boolean,
                                                        ) => setFieldValue(property, value, shouldValidate),
                                                        values,
                                                        errors,
                                                    };
                                                }
                                                let inputContent: JSX.Element | null = (
                                                    <InputComponent
                                                        fullWidth={true}
                                                        id={property}
                                                        label={label}
                                                        variant="outlined"
                                                        multiline
                                                        value={values[property]}
                                                        onChange={handleChange}
                                                        helperText={errors[property] && errors[property]}
                                                        error={errors[property] ? true : false}
                                                        disabled={disabled ? disabled(item) : false}
                                                        {...inputProps}
                                                    />
                                                );

                                                if (currentGroup != inputGroup) {
                                                    const groupContent = makeGroup(currentGroup, currentGroupInputs);
                                                    currentGroup = inputGroup;

                                                    if (inputGroup) {
                                                        currentGroupInputs = [inputContent];
                                                        inputContent = null;
                                                    }

                                                    if (groupContent || inputContent) {
                                                        return (
                                                            <Fragment key={property}>
                                                                {groupContent}
                                                                {inputContent}
                                                            </Fragment>
                                                        );
                                                    }
                                                }

                                                if (inputGroup && inputContent) {
                                                    currentGroupInputs.push(inputContent);
                                                    return null;
                                                }

                                                return (
                                                    <Grid key={property} item xs={12}>
                                                        {inputContent}
                                                    </Grid>
                                                );
                                            })}
                                        {makeGroup(currentGroup, currentGroupInputs)}

                                        {Object.entries(model).filter(
                                            ([, { inForm = true, inputProps = { required: false } }]) =>
                                                inForm && inputProps.required,
                                        ).length > 0 && (
                                            <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                                Input fields marked with an asterisk (*) are required
                                            </Grid>
                                        )}
                                        <Grid item xs={12}>
                                            <Grid container justifyContent="space-between">
                                                <Grid item>
                                                    <SecondaryButton
                                                        size={"large"}
                                                        disabled={isSubmitting}
                                                        onClick={() => {
                                                            goBack();
                                                        }}
                                                    >
                                                        Cancel
                                                    </SecondaryButton>
                                                </Grid>
                                                <Grid item>
                                                    <PrimaryButton
                                                        size={"large"}
                                                        disabled={isSubmitting}
                                                        onClick={submitForm}
                                                    >
                                                        Save
                                                    </PrimaryButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {error && (
                                            <Grid item xs={12}>
                                                <Typography color="error">{error}</Typography>
                                            </Grid>
                                        )}
                                    </Grid>
                                </>
                            );
                        }}
                    </Formik>
                </Container>
            </Box>
        </>
    );
};
export default Edit;
