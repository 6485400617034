import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { useOnboarding } from "../../contexts/onboarding.context";
import { WizardHeading } from "../../../../../core/components/WizardHeading";
import { VListItem, VListItemType } from "../../../../../core/components/VListItem";
import { Product } from "../../entities/Products";
import { SecondaryButton } from "../../../../layout/Button/Button";
import { verbalTheme } from "../../../../layout/themes/verbal.theme";

export const Subscription: React.FC = () => {
    const { changeStep, plans, planStep } = useOnboarding();

    const handlePlanSelection = async (plan: Product) => {
        planStep(plan);
        changeStep(4);
    };

    return (
        <>
            <WizardHeading
                overline={"Let's get you set up"}
                heading={"Choose a subscription plan to access your 28 day FREE trial"}
            />
            <div style={{ marginTop: -20, marginBottom: 30 }}>
                <p>
                    Our average cost is based on £2.00 per pupil with a maximum cost for a whole school per year of
                    £600.
                </p>
                <p>
                    We recognise the challenges and pressures faced by every school.To help you provide your pupils with
                    the best learning experience please select the plan that matches your school size from the list
                    below so that we can apply a discount.
                </p>
            </div>
            <Grid container alignItems="flex-start" spacing={3}>
                {plans && plans.length > 0 ? (
                    plans
                        .filter((plan) => plan.name !== "Schools with more than 900 pupils")
                        .map((item) => (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    key={item.id}
                                    style={{ paddingBottom: verbalTheme.spacing(2) }}
                                >
                                    <VListItem
                                        type={VListItemType.Primary}
                                        primaryText={item.name}
                                        secondaryText={"(with my discount)"}
                                        onClick={() => {
                                            handlePlanSelection(item);
                                        }}
                                        textProps={{ color: "primary" }}
                                    ></VListItem>
                                </Grid>
                            </>
                        ))
                ) : (
                    <Grid item xs={6} style={{ paddingBottom: verbalTheme.spacing(2) }}>
                        <CircularProgress />
                    </Grid>
                )}
                {plans &&
                    plans.length > 0 &&
                    plans
                        .filter((plan) => plan.name === "Schools with more than 900 pupils")
                        .map((item) => (
                            <>
                                <Grid
                                    item
                                    xs={6}
                                    md={4}
                                    key={item.id}
                                    style={{ paddingBottom: verbalTheme.spacing(2) }}
                                >
                                    <VListItem
                                        type={VListItemType.Primary}
                                        primaryText={item.name}
                                        onClick={() => {
                                            handlePlanSelection(item);
                                        }}
                                        textProps={{ color: "primary" }}
                                    ></VListItem>
                                </Grid>
                            </>
                        ))}
            </Grid>
            {plans && plans.length > 0 && (
                <Grid container alignItems="flex-start" spacing={4}>
                    <Grid item style={{ paddingTop: verbalTheme.spacing(5) }}>
                        <SecondaryButton size="large" onClick={() => changeStep(2)}>
                            Back
                        </SecondaryButton>
                    </Grid>
                </Grid>
            )}
        </>
    );
};
