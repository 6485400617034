import { Organisation, Tier, User } from "common/build/prisma/client";
import { useCallback, useState } from "react";
import { get } from "../client/api/organisation";
import { addOrganisation, addSchool } from "../client/api/public/organisation";
import { useAuth } from "../contexts/auth.context";

interface OrganisationI {
    loading: boolean;
    organisation?: Organisation & { Tier: Tier };

    addNewOrganisation: (organisationDetails: Partial<Organisation & User>) => Promise<void>;
    addSchoolOrganisation: (organisationDetails: Partial<Organisation & User>) => Promise<void>;
    getOrganisation: (userId: number) => Promise<void>;
}

/**
 * Custom hook to create a new organisation or get an existing organisation
 */
export const useOrganisation = (): OrganisationI => {
    const [loading, setLoading] = useState<boolean>(false);
    const [organisation, setOrganisation] = useState<Organisation & { Tier: Tier }>();

    const { addToken } = useAuth();

    const addNewOrganisation = useCallback((organisationDetails: Partial<Organisation & User>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            addOrganisation(organisationDetails)
                .then(async (response) => {
                    if (response.ok) {
                        const { token: tokenFromApi }: { token: string } = await response.json();
                        addToken(tokenFromApi);
                        resolve();
                    } else {
                        const responseBody = await response.json();
                        reject((responseBody && responseBody.message) ?? "Unable to add organisation");
                    }
                })
                .catch((error) => {
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const addSchoolOrganisation = useCallback((organisationDetails: Partial<Organisation & User>) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            addSchool(organisationDetails)
                .then(async (response) => {
                    if (response.ok) {
                        const { token: tokenFromApi }: { token: string } = await response.json();
                        addToken(tokenFromApi);
                        resolve();
                    } else {
                        const responseBody = await response.json();
                        reject((responseBody && responseBody.message) ?? "Unable to add organisation");
                    }
                })
                .catch((error) => {
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const getOrganisation = useCallback((userId: number) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            get(userId)
                .then(async (response) => {
                    setOrganisation(response);
                    resolve();
                })
                .catch((error) => {
                    setOrganisation(undefined);
                    reject(error.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    return {
        loading,
        organisation,
        addNewOrganisation,
        addSchoolOrganisation,
        getOrganisation,
    };
};
