import { Typography, Box, Button, Grid } from "@material-ui/core";
import { Session, Participant, Prisma } from "common/build/prisma/client";
import React, { useMemo, useState } from "react";
import PagedList from "../../../../../core/components/crud/PagedList";
import { useParams } from "react-router-dom";
import { update } from "../../../../../client/api/session";
import { useList } from "../../../../../core/components/crud/contexts/list.context";
import { ParticipantType, SessionType } from "../../../../../models/modelTypes";
import { ProgrammeParticipantAdd } from "../../../../programme/participants/ProgrammeParticipantAdd";
import { useFetch } from "../../../../../core/components/crud/contexts/fetch.context";
import { useSessionMode } from "../../contexts/sessionMode.context";
import { surveyStatus } from "../../../../programme/participants/model";
import { Model } from "../../../../../core/components/crud/Crud";
import { VCard } from "../../../../layout/Card/VCard";
import { SimpleBlankSlate } from "../../../../../core/components/SimpleBlankSlate";
import barchartImg from "../../../../../img/barchart.svg";

export const createParticipantModel: (s?: SessionType) => Model<Participant> = (session) => {
    return {
        id: {
            inTable: false,
        },
        name: {
            label: "Name",
        },
        hasCompletedPreSurvey: {
            inTable: true,
            inForm: false,
            label: "Pre Survey",
            decorator: (value: string, property: string, participant: ParticipantType): JSX.Element =>
                surveyStatus(property, participant, undefined, session),
        },
        hasCompletedPostSurvey: {
            inTable: true,
            inForm: false,
            label: "Post Survey",
            decorator: (value: string, property: string, participant: ParticipantType): JSX.Element =>
                surveyStatus(property, participant, undefined, session),
        },
    };
};

export const Register: React.FC = () => {
    const { item, error: errorFetching } = useFetch<Session>();
    const [inProgress, setInProgress] = useState<boolean>(false);
    const { changeStep, submitStep, participantsAdmitted } = useSessionMode();
    const { fetchList, checked, toggleCheck, check, list, plural } = useList<ParticipantType, number>();
    const { id } = useParams<{ id?: string }>();

    const model = useMemo(() => createParticipantModel(item), [item]);

    if (!id || errorFetching) {
        return (
            <Grid item xs={12}>
                <Typography color="error">{`Unable to load session`}</Typography>
            </Grid>
        );
    }

    const completeRegister = async () => {
        setInProgress(true);
        checked.forEach(async (existingParticipantId) => {
            const params = {
                isRegisterTaken: true,
                ParticipantToSession: {
                    upsert: {
                        create: {
                            participantId: existingParticipantId,
                            isPresent: true,
                        },
                        update: {
                            participantId: existingParticipantId,
                            isPresent: true,
                        },
                        where: {
                            participantId_sessionId: {
                                participantId: existingParticipantId,
                                sessionId: item?.id,
                            },
                        },
                    },
                },
            } as Prisma.SessionUpdateInput;

            await update(parseInt(id), params);
        });
        setInProgress(false);
        fetchList();
        submitStep();
        changeStep(1);
    };

    return (
        <Box mb={3}>
            <Box mb={3}>
                <Box mb={3}>
                    <Typography variant="h2" component="h1" color="textPrimary" align="left">
                        Please complete the register before starting
                    </Typography>
                </Box>
            </Box>
            <Box mb={3}>
                {list.length === 0 ? (
                    <Box mb={2}>
                        <VCard>
                            <SimpleBlankSlate
                                header={plural}
                                image={<img src={barchartImg} />}
                                subHeader={`No ${plural} have been added`}
                            />
                        </VCard>
                    </Box>
                ) : (
                    <PagedList<Participant>
                        pagination={false}
                        checkboxSelection
                        model={model}
                        onSelectAllRows={(selectedRowIds) => check(selectedRowIds)}
                        onRowClick={(params) => toggleCheck(params.id)}
                        selectedRowIds={checked}
                    />
                )}

                {item?.programmeId && <ProgrammeParticipantAdd wideName programmeId={item.programmeId} />}
            </Box>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    onClick={completeRegister}
                    disabled={inProgress || (item?.isOnline && !participantsAdmitted)}
                >
                    Continue
                </Button>
            </Box>
        </Box>
    );
};
