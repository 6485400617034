import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useCurriculum } from "../../../hooks/useCurriculum";
import * as yup from "yup";
import {
    Box,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FormActions } from "../../../core/components/FormActions";
import { PrimaryButton } from "../../layout/Button/Button";
import { useHistory } from "react-router";
import { WizardHeading } from "../../../core/components/WizardHeading";
import { CurriculumType } from "../../../models/modelTypes";
import { Stop } from "@material-ui/icons";
import { CardColour } from "common/build/prisma/client";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { useParams } from "react-router-dom";
import Spinner from "../../layout/Spinner";
import { Error } from "../../../core/components/Error";

export const EditCurriculum = (): JSX.Element => {
    const { item, fetchItem } = useFetch<CurriculumType>();
    const { updateCurriculum } = useCurriculum();
    const [error, setError] = useState();
    const [loading, setLoading] = useState<boolean>();
    const [fetchError, setFetchError] = useState<boolean>();

    const history = useHistory();
    const { id } = useParams<{ id: string }>();

    useEffect(() => {
        setLoading(true);
        fetchItem(parseInt(id))
            .then(() => {
                setFetchError(false);
            })
            .catch(() => {
                setFetchError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [id]);

    if (fetchError || !item?.id) {
        return <Error description={`Unable to load Curriculum`} />;
    }

    const submit = (values: Partial<CurriculumType>) => {
        const { name, description, purpose, colour, pickOrder } = values;
        updateCurriculum(item.id, {
            name: name,
            description: description,
            purpose: purpose,
            colour: colour,
            pickOrder: pickOrder,
        })
            .then(() => {
                setError(undefined);
                history.push("/curriculums");
            })
            .catch((error) => setError(error));
    };

    if (loading) {
        return <Spinner />;
    }

    if (fetchError || !item?.id) {
        return <Error description={`Unable to load Curriculum`} />;
    }

    return (
        <Box p={4}>
            <Container maxWidth={"md"}>
                <WizardHeading heading="Add New Curriculum" />
                <Formik
                    initialValues={{
                        name: item.name,
                        description: item.description,
                        purpose: item.purpose,
                        colour: item.colour ? (item.colour as CardColour) : ("Cyan" as CardColour),
                        pickOrder: item.pickOrder ?? undefined,
                    }}
                    onSubmit={submit}
                    enableReinitialize={true}
                    validationSchema={yup.object({
                        name: yup.string().required("Curriculum name is required"),
                        description: yup.string().required("Curriculum description is required"),
                        //ageRange: yup.string().required("Curriculum age range is required"),
                    })}
                    validateOnChange={false}
                >
                    {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                        return (
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        required
                                        name="name"
                                        variant="outlined"
                                        label="Curriculum Name"
                                        value={values.name}
                                        fullWidth
                                        onChange={handleChange}
                                        helperText={errors.name && touched.name && errors.name}
                                        error={!!errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        name="description"
                                        variant="outlined"
                                        label="Description"
                                        value={values.description}
                                        fullWidth
                                        multiline
                                        onChange={handleChange}
                                        helperText={errors.description && touched.description && errors.description}
                                        error={!!errors.description}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        name="purpose"
                                        variant="outlined"
                                        label="Purpose"
                                        value={values.purpose}
                                        fullWidth
                                        multiline
                                        onChange={handleChange}
                                        helperText={errors.purpose && touched.purpose && errors.purpose}
                                        error={!!errors.purpose}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl variant="outlined" error={!!errors.pickOrder} fullWidth>
                                        <InputLabel id="verbal-pick-label" required>
                                            Verbal Pick
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            label="Verbal Pick"
                                            labelId="verbal-pick-label"
                                            name="pickOrder"
                                            id="pickOrder"
                                            value={values.pickOrder}
                                            onChange={(e) => {
                                                setFieldValue("pickOrder", e.target.value);
                                            }}
                                        >
                                            <MenuItem key={0} value={undefined}>
                                                None
                                            </MenuItem>
                                            <MenuItem key={1} value={1}>
                                                1
                                            </MenuItem>
                                            <MenuItem key={2} value={2}>
                                                2
                                            </MenuItem>
                                            <MenuItem key={3} value={3}>
                                                3
                                            </MenuItem>
                                            <MenuItem key={4} value={4}>
                                                4
                                            </MenuItem>
                                            <MenuItem key={5} value={5}>
                                                5
                                            </MenuItem>
                                            <MenuItem key={6} value={6}>
                                                6
                                            </MenuItem>
                                            <MenuItem key={7} value={7}>
                                                7
                                            </MenuItem>
                                            <MenuItem key={8} value={8}>
                                                8
                                            </MenuItem>
                                            <MenuItem key={9} value={9}>
                                                9
                                            </MenuItem>
                                            <MenuItem key={10} value={10}>
                                                10
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl variant="outlined" error={!!errors.colour} fullWidth>
                                        <InputLabel id="colour-label" required>
                                            Card Colour
                                        </InputLabel>
                                        <Select
                                            fullWidth
                                            label="Card Colour"
                                            labelId="colour-label"
                                            name="colour"
                                            id="colour"
                                            value={values.colour}
                                            onChange={(e) => {
                                                setFieldValue("colour", e.target.value);
                                            }}
                                        >
                                            <MenuItem key={0} value={""} disabled>
                                                Please select
                                            </MenuItem>
                                            <MenuItem key={"Red"} value={"Red"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#CA2D23" }} />
                                                    Red
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Orange"} value={"Orange"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#DD752A" }} />
                                                    Orange
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Yellow"} value={"Yellow"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#D3B619" }} />
                                                    Yellow
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Green"} value={"Green"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#78AF1F" }} />
                                                    Green
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Cyan"} value={"Cyan"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#27B5BE" }} />
                                                    Cyan
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Blue"} value={"Blue"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#6361B2" }} />
                                                    Blue
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Magneta"} value={"Magneta"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#AA3BA6" }} />
                                                    Magneta
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Crimson"} value={"Crimson"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#D33057" }} />
                                                    Crimson
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Silver"} value={"Silver"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#818390" }} />
                                                    Silver
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Bronze"} value={"Bronze"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#A77A50" }} />
                                                    Bronze
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"BrightYellow"} value={"BrightYellow"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#FFF700" }} />
                                                    Bright Yellow
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Aqua"} value={"Aqua"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#0DFFFF" }} />
                                                    Aqua
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"Purple"} value={"Purple"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#AE0CFF" }} />
                                                    Purple
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"BrightOrange"} value={"BrightOrange"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#FF7A15" }} />
                                                    Bright Orange
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"BrightRed"} value={"BrightRed"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#FF0000" }} />
                                                    Bright Red
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"LightGreen"} value={"LightGreen"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#007919" }} />
                                                    Light Green
                                                </div>
                                            </MenuItem>
                                            <MenuItem key={"NightBlue"} value={"NightBlue"}>
                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                    <Stop fontSize="large" style={{ fill: "#033F77" }} />
                                                    Night Blue
                                                </div>
                                            </MenuItem>
                                        </Select>
                                        <FormHelperText>
                                            {errors.colour && touched.colour && errors.colour}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    Input fields marked with an asterisk (*) are required
                                </Grid>

                                <Grid item xs={12}>
                                    {error && (
                                        <Box mb={2}>
                                            <Alert severity="error">{error}</Alert>
                                        </Box>
                                    )}
                                    <FormActions
                                        right={
                                            <PrimaryButton
                                                size="large"
                                                disabled={isSubmitting}
                                                onClick={(event) => {
                                                    event.preventDefault();
                                                    submitForm();
                                                }}
                                            >
                                                Save
                                            </PrimaryButton>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        );
                    }}
                </Formik>
            </Container>
        </Box>
    );
};
