import { TextField, FormControl, InputLabel, FormHelperText, Grid } from "@material-ui/core";
import React from "react";
import { useOnboarding } from "../../contexts/onboarding.context";
import { Formik } from "formik";
import * as yup from "yup";
import SelectCountry from "../../../../../core/components/SelectCountry";
import { PrimaryButton, SecondaryButton } from "../../../../layout/Button/Button";
import { FormActions } from "../../../../../core/components/FormActions";
import { WizardHeading } from "../../../../../core/components/WizardHeading";

type AddressInputs = {
    addressLine1: string;
    addressLine2: string;
    city: string;
    postcode: string;
    country: string;
};

export const Address: React.FC = () => {
    const { changeStep, details, submitStep } = useOnboarding();

    const { addressLine1, addressLine2, city, postcode, country } = details;

    const onSubmit = (values: AddressInputs) => {
        submitStep(values);
        changeStep(2);
    };

    return (
        <form>
            <WizardHeading overline={"Let's get you set up"} heading={"Add an Address"} />
            <Formik
                initialValues={{
                    addressLine1: addressLine1 ?? "",
                    addressLine2: addressLine2 ?? "",
                    city: city ?? "",
                    postcode: postcode ?? "",
                    country: country ?? "",
                }}
                onSubmit={onSubmit}
                validationSchema={yup.object({
                    addressLine1: yup.string().required("Address Line 1 is required"),
                    city: yup.string().required("City is required"),
                    country: yup.string().required("Country is required"),
                })}
                validateOnChange={false}
            >
                {({ submitForm, errors, values, handleChange, touched, isSubmitting, setFieldValue }) => {
                    return (
                        <>
                            <Grid container alignItems="flex-start" spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        fullWidth
                                        id="addressLine1"
                                        label="Address Line 1"
                                        variant="outlined"
                                        value={values.addressLine1}
                                        onChange={handleChange}
                                        required
                                        helperText={errors.addressLine1 && touched.addressLine1 && errors.addressLine1}
                                        error={errors.addressLine1 ? true : false}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        id="addressLine2"
                                        label="Address Line 2"
                                        variant="outlined"
                                        value={values.addressLine2}
                                        onChange={handleChange}
                                        helperText={errors.addressLine2 && touched.addressLine2 && errors.addressLine2}
                                        error={errors.addressLine2 ? true : false}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="city"
                                        label="City"
                                        variant="outlined"
                                        value={values.city}
                                        onChange={handleChange}
                                        required
                                        helperText={errors.city && touched.city && errors.city}
                                        error={errors.city ? true : false}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        fullWidth
                                        id="postcode"
                                        label="Postcode"
                                        variant="outlined"
                                        value={values.postcode}
                                        onChange={handleChange}
                                        helperText={errors.postcode && touched.postcode && errors.postcode}
                                        error={errors.postcode ? true : false}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl variant="outlined" error={errors.country ? true : false} fullWidth>
                                        <SelectCountry
                                            id="country"
                                            value={values.country}
                                            onChange={(e) => setFieldValue("country", e.target.value)}
                                            label="Country"
                                        />
                                        <InputLabel id="country-label" required>
                                            Country
                                        </InputLabel>
                                        <FormHelperText>
                                            {errors.country && touched.country && errors.country}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                    Input fields marked with an asterisk (*) are required
                                </Grid>

                                <Grid item xs={12}>
                                    <FormActions
                                        left={
                                            <>
                                                <SecondaryButton size="large" onClick={() => changeStep(0)}>
                                                    Back
                                                </SecondaryButton>
                                            </>
                                        }
                                        right={
                                            <>
                                                <PrimaryButton
                                                    variant="contained"
                                                    size="large"
                                                    color="primary"
                                                    disabled={isSubmitting}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        submitForm();
                                                    }}
                                                >
                                                    Continue
                                                </PrimaryButton>
                                            </>
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </>
                    );
                }}
            </Formik>
        </form>
    );
};
