import React, { useEffect } from "react";
import { WizardStep } from "../../../core/components/wizard/WizardStep";
import ProgrammeDetails from "./steps/ProgrammeDetails";
import PathwayStep from "./steps/Pathway";
import { useProgramme } from "../contexts/programme.context";
import { Box, Container } from "@material-ui/core";
import { useOrganisation } from "../../../hooks/useOrganisation";
import { useAuth } from "../../../contexts/auth.context";
import { ProgrammeFacilitators } from "./steps/ProgrammeFacilitators";
import { StepperWizard } from "../../../core/components/wizard/StepperWizard";
import { useParams } from "react-router-dom";
import { useCollection } from "../../../hooks/useCollection";
import { useMobile } from "../../../hooks/useMobile";

export const CreateProgrammeWizard: React.FC = (): JSX.Element => {
    const { paramId } = useParams<{ paramId?: string }>();
    const { activeStep, completedSteps, changeStep, clearProvider, submitStep } = useProgramme();
    const { user } = useAuth();
    const { organisation, getOrganisation } = useOrganisation();
    const { collection, getCollection } = useCollection();
    const { isMobile } = useMobile();

    useEffect(() => {
        if (paramId && parseInt(paramId)) {
            getCollection(parseInt(paramId));
        }
    }, [paramId]);

    useEffect(() => {
        if (collection) {
            submitStep({ storyCollectionId: collection.id, ageRange: collection.ageRange });
            if (activeStep === 0) {
                changeStep(1);
            }
        }
    }, [collection]);

    useEffect(() => {
        if (user) {
            getOrganisation(user.id);
        }
        if (completedSteps[2]) {
            clearProvider();
        }
    }, []);

    return (
        <Box p={4}>
            <Container maxWidth={"md"}>
                {isMobile && <div style={{ paddingTop: "64px" }} />}
                <StepperWizard activeStep={activeStep} goToStep={changeStep} orientation={"horizontal"}>
                    <WizardStep identifier={0} heading={isMobile ? " " : "Collection"} completed={completedSteps[0]}>
                        <PathwayStep />
                    </WizardStep>
                    <WizardStep
                        identifier={1}
                        heading={isMobile ? " " : "Programme Details"}
                        completed={completedSteps[1]}
                    >
                        <ProgrammeDetails organisation={organisation} />
                    </WizardStep>

                    <WizardStep identifier={2} heading={isMobile ? " " : "Facilitator"} completed={completedSteps[2]}>
                        <ProgrammeFacilitators />
                    </WizardStep>
                </StepperWizard>
            </Container>
        </Box>
    );
};
