import React, { useEffect, useState } from "react";
import { SimpleBlankSlate } from "../../../core/components/SimpleBlankSlate";
import { StoryCollectionType } from "../../../models/modelTypes";
import { useAuth } from "../../../contexts/auth.context";

type TeacherNotesProps = {
    storyCollection?: StoryCollectionType;
};

export const TeacherNotes = (props: TeacherNotesProps): JSX.Element => {
    const { storyCollection } = props;
    const { user } = useAuth();

    const [url, setUrl] = useState<string>();

    useEffect(() => {
        if (user?.Organisation?.country === "Republic of Ireland") {
            setUrl(storyCollection?.guideURLROI ?? undefined);
        } else {
            setUrl(storyCollection?.guideURL ?? undefined);
        }
    }, [user?.Organisation?.country, storyCollection]);

    return (
        <div>
            {url ? (
                <div style={{ minHeight: "900px" }}>
                    <iframe
                        src={`${url}`}
                        width="100%"
                        height="700"
                        allowFullScreen
                        allow="autoplay"
                        title={`${storyCollection?.name} Teacher Notes.pdf`}
                    ></iframe>
                </div>
            ) : (
                <SimpleBlankSlate header="No Guidance Notes for this programme" />
            )}
        </div>
    );
};
