import React from "react";
import {
    Box,
    createStyles,
    Dialog,
    DialogContent,
    DialogProps,
    makeStyles,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { verbalTheme } from "../../components/layout/themes/verbal.theme";

type VDialogProps = DialogProps & {
    icon?: React.ReactElement;
    header?: string;
    content?: string;
    actions?: React.ReactElement;
    bottomContent?: React.ReactElement;
};

export const VDialog: React.FC<VDialogProps> = (props: VDialogProps) => {
    const useStyles = makeStyles(() =>
        createStyles({
            dialogContent: {
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: verbalTheme.spacing(5),
                "&:first-child": {
                    paddingTop: verbalTheme.spacing(5),
                },
                "&:only-child": {
                    paddingTop: verbalTheme.spacing(5),
                },
            },
        }),
    );
    const classes = useStyles();

    const { icon, header, content, actions, bottomContent, ...rest } = props;
    const fullScreen = useMediaQuery(verbalTheme.breakpoints.down("sm"));

    return (
        <Dialog {...rest} maxWidth={"xs"} fullScreen={fullScreen}>
            <DialogContent className={classes.dialogContent}>
                <Box mb={2} textAlign={"center"}>
                    {icon}
                </Box>
                <Box mb={2}>
                    <Typography variant="h5" component="h2" align={"center"}>
                        {header}
                    </Typography>
                </Box>
                <Box mb={2}>
                    {content && (
                        <Typography
                            variant="body1"
                            color="textPrimary"
                            component="p"
                            align={"center"}
                            dangerouslySetInnerHTML={{ __html: content }}
                        ></Typography>
                    )}
                </Box>
                {actions}
            </DialogContent>
            {bottomContent}
        </Dialog>
    );
};
