import { node } from "prop-types";
import React, { ReactNode, useCallback, useState } from "react";
import { ProgrammeType } from "../../../models/modelTypes";
import { addProgramme, addProgrammeStoryCollection, generateSessions, update } from "../../../client/api/programme";
import { useIntQueryStringState } from "../../../hooks/useQueryString";

interface ProgrammeContextI {
    activeStep: number;
    completedSteps: { [k: number]: boolean };
    details: Partial<ProgrammeType>;
    programme: ProgrammeType | undefined;
    setCurrentProgramme: (programme: ProgrammeType | undefined) => void;
    loading: boolean;

    changeStep: (newStep: number) => void;

    completeStep: (step: number) => void;

    submitStep: (details: Partial<ProgrammeType>) => void;

    submitDetails: (details: Partial<ProgrammeType>) => void;

    addStoryCollectionToProgramme: (storyCollectionId: number, programmeId: number | undefined) => Promise<void>;

    addNewProgramme: (programmeDetails: Partial<ProgrammeType>) => Promise<void>;

    updateProgramme: (id: number, programmeDetails: Partial<ProgrammeType>) => Promise<void>;

    generateProgrammeSessions: (programmeId: number | undefined) => Promise<void>;

    clearProvider: () => Promise<void>;
}

const ProgrammeContext = React.createContext({} as ProgrammeContextI);

export const ProgrammeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [activeStep, setActiveStep] = useIntQueryStringState("step", 0);
    const [completedSteps, setCompletedSteps] = React.useState<{ [k: number]: boolean }>({});
    const initialDetails: Partial<ProgrammeType> = {
        name: "",
        isGroup: true,
        ageRange: "",
        isOnline: false,
        startDate: undefined,
        frequency: undefined,
        storyCollectionId: undefined,
    };
    const [details, setDetails] = useState<Partial<ProgrammeType>>(initialDetails);
    const [programme, setProgramme] = useState<ProgrammeType | undefined>();
    const [loading, setLoading] = useState<boolean>(false);

    const setCurrentProgramme = useCallback((programme: ProgrammeType | undefined) => {
        setProgramme(programme);
    }, []);

    const changeStep = useCallback((newStep: number) => {
        setActiveStep(newStep);
    }, []);

    const completeStep = useCallback((step: number) => {
        setCompletedSteps((prev) => ({ ...prev, [step]: true }));
    }, []);

    const submitStep = useCallback(
        (details: Partial<ProgrammeType>) => {
            setDetails(details);
            setCompletedSteps((prevCompletedSteps) => ({ ...prevCompletedSteps, [activeStep]: true }));
        },
        [activeStep],
    );

    const submitDetails = useCallback((details: Partial<ProgrammeType>) => {
        setDetails(details);
    }, []);

    const addNewProgramme = useCallback((programmeDetails: Partial<ProgrammeType>) => {
        return new Promise<void>((resolve) => {
            setLoading(true);

            addProgramme(programmeDetails)
                .then(async (response) => {
                    if (response.ok) {
                        const programmeResponse: ProgrammeType = await response.json();
                        if (programmeResponse.id && programmeResponse.storyCollectionId) {
                            addProgrammeStoryCollection(programmeResponse.storyCollectionId, programmeResponse.id).then(
                                () => {
                                    generateSessions(programmeResponse.id).then(async (resp) => {
                                        if (resp.ok) {
                                            const programmeResponseWithSessions: ProgrammeType = await resp.json();
                                            setProgramme(programmeResponseWithSessions);
                                            resolve();
                                        }
                                    });
                                },
                            );
                        } else {
                            setProgramme(programmeResponse);
                            resolve();
                        }
                    } else {
                        setProgramme(undefined);
                    }
                })
                .catch(() => {
                    setProgramme(undefined);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const updateProgramme = useCallback((id: number, programmeDetails: Partial<ProgrammeType>) => {
        return new Promise<void>((resolve) => {
            setLoading(true);

            update(id, programmeDetails)
                .then(async (response) => {
                    if (response.ok) {
                        const programmeResponse: ProgrammeType = await response.json();
                        setProgramme(programmeResponse);
                        resolve();
                    } else {
                        setProgramme(undefined);
                    }
                })
                .catch(() => {
                    setProgramme(undefined);
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const addStoryCollectionToProgramme = useCallback((storyCollectionId: number, programmeId: number | undefined) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            addProgrammeStoryCollection(storyCollectionId, programmeId)
                .then(async (response) => {
                    if (response.ok) {
                        const programmeResponse: ProgrammeType = await response.json();
                        setProgramme(programmeResponse);
                        resolve();
                    } else {
                        setProgramme(undefined);
                        reject("Error adding pathway to programme");
                    }
                })
                .catch(() => {
                    setProgramme(undefined);
                    reject("Error adding pathway to programme");
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const generateProgrammeSessions = useCallback((programmeId: number | undefined) => {
        return new Promise<void>((resolve, reject) => {
            setLoading(true);

            generateSessions(programmeId)
                .then(async (response) => {
                    if (response.ok) {
                        const programmeResponse: ProgrammeType = await response.json();
                        setProgramme(programmeResponse);
                        resolve();
                    } else {
                        setProgramme(undefined);
                        reject("Unable to generate programme sessions");
                    }
                })
                .catch(() => {
                    setProgramme(undefined);
                    reject("Unable to generate programme sessions");
                })
                .finally(() => {
                    setLoading(false);
                });
        });
    }, []);

    const clearProvider = useCallback(() => {
        return new Promise<void>((resolve) => {
            setProgramme(undefined);
            setDetails(initialDetails);
            setActiveStep(0);
            setCompletedSteps({});
            resolve();
        });
    }, []);

    return (
        <ProgrammeContext.Provider
            value={{
                activeStep,
                completedSteps,
                details,
                changeStep,
                completeStep,
                submitStep,
                submitDetails,
                loading,
                programme,
                addNewProgramme,
                addStoryCollectionToProgramme,
                generateProgrammeSessions,
                updateProgramme,
                setCurrentProgramme,
                clearProvider,
            }}
        >
            {children}
        </ProgrammeContext.Provider>
    );
};

ProgrammeProvider.propTypes = {
    children: node,
};

export const useProgramme = (): ProgrammeContextI => React.useContext<ProgrammeContextI>(ProgrammeContext);
