import React from "react";
import { Switch } from "react-router-dom";
import { InvitationPage } from "../../../pages/invitationPage";
import { PublicLayout } from "../../layout/public/PublicLayout";
import { ReadingLayout } from "../../layout/public/session/ReadingLayout";
import { ParticipantDashboard } from "../../participant/Dashboard";
import { ParticipantOnlineSessionPage } from "../../../pages/ParticipantOnlineSessionPage";
import { ParticipantInPersonSessionPage } from "../../../pages/ParticipantInPersonSessionPage";
import { ReadStoryPage } from "../../../pages/ReadStoryPage";
import { ParticipantRoute } from "./ParticipantRoute";
import { ReadAtHomeSurvey } from "../../participant/ReadAtHomeSurvey";
import { SurveyClosePage } from "../../participant/surveyDialog/SurveyClosePage";

const ParticipantRoutes = (): React.ReactElement => {
    return (
        <>
            <Switch>
                <ParticipantRoute
                    isPublic
                    layout={PublicLayout}
                    path="/participant/survey/close"
                    component={SurveyClosePage}
                />
                <ParticipantRoute isPublic layout={PublicLayout} path="/invite/:jwt?" component={InvitationPage} />
                <ParticipantRoute
                    isPublic
                    layout={PublicLayout}
                    path="/sign-in/:jwt?"
                    component={() => <InvitationPage isSignIn />}
                />
                <ParticipantRoute
                    layout={PublicLayout}
                    path="/participant/dashboard"
                    exact
                    component={ParticipantDashboard}
                />
                <ParticipantRoute
                    layout={ReadingLayout}
                    path="/participant/:participantId/online-session/:sessionId"
                    exact
                    component={ParticipantOnlineSessionPage}
                />
                <ParticipantRoute
                    layout={ReadingLayout}
                    path="/participant/:participantId/session/:sessionId"
                    exact
                    component={ParticipantInPersonSessionPage}
                />
                <ParticipantRoute layout={ReadingLayout} path="/read-story/:id/:sessionId?" component={ReadStoryPage} />
                <ParticipantRoute layout={ReadingLayout} path="/survey/:id" component={ReadAtHomeSurvey} />
            </Switch>
        </>
    );
};

export default ParticipantRoutes;
