import React from "react";
import { SimpleBlankSlate } from "../../core/components/SimpleBlankSlate";

type FileViewerProps = {
    url?: string | null;
    title?: string;
    noneMessage?: string;
};

export const FileViewer = (props: FileViewerProps): JSX.Element => {
    const { url, title, noneMessage } = props;

    return url ? (
        <div style={{ minHeight: "900px" }}>
            <iframe
                src={`${url}`}
                width="100%"
                height="700"
                allowFullScreen
                //allow="autoplay"
                title={`${title}`}
            ></iframe>
        </div>
    ) : (
        <SimpleBlankSlate header={noneMessage} />
    );
};
