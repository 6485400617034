import { Box, Typography } from "@material-ui/core";
import { ActiveProgramStats } from "common/build/api-parameters/users";
import React from "react";
import { useFetch } from "../../../../../core/components/crud/contexts/fetch.context";
import { SpinWhileFetching } from "../../../../common/SpinWhileFetching";
import { VTextLink } from "../../../../common/VTextLink";
import { SecondaryButton } from "../../../../layout/Button/Button";
import { HeadlineFigureWidget } from "../HeadlineFigureWidget";

export const ReviewedStoriesHeadlineWidget = (): JSX.Element => {
    const { item } = useFetch<ActiveProgramStats>();
    return (
        <HeadlineFigureWidget header={"My Reviewed Stories"}>
            <Box>
                <SpinWhileFetching>
                    <Typography variant="h2">{item?.myReviewedStories}</Typography>
                </SpinWhileFetching>
            </Box>
            <Box>
                <VTextLink to="/stories">
                    <SecondaryButton fullWidth>View Stories</SecondaryButton>
                </VTextLink>
            </Box>
        </HeadlineFigureWidget>
    );
};
