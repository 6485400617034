import { createMuiTheme } from "@material-ui/core";
// We create a MUI Theme object, then we override it using variables from the mui theme in the override.
// This overridden theme object is exported for use in `ThemeProvider` and custom styles.

declare module "@material-ui/core/styles/createPalette" {
    interface PaletteOptions {
        secondaryShade?: PaletteColorOptions;
        borderShade?: PaletteColorOptions;
        cardRed?: PaletteColorOptions;
        cardOrange?: PaletteColorOptions;
        cardYellow?: PaletteColorOptions;
        cardGreen?: PaletteColorOptions;
        cardCyan?: PaletteColorOptions;
        cardBlue?: PaletteColorOptions;
        cardMagneta?: PaletteColorOptions;
        cardCrimson?: PaletteColorOptions;
        cardSilver?: PaletteColorOptions;
        cardBronze?: PaletteColorOptions;
        cardBrightYellow?: PaletteColorOptions;
        cardAqua?: PaletteColorOptions;
        cardPurple?: PaletteColorOptions;
        cardBrightOrange?: PaletteColorOptions;
        cardBrightRed?: PaletteColorOptions;
        cardLightGreen?: PaletteColorOptions;
        cardNightBlue?: PaletteColorOptions;
    }
    interface Palette {
        secondaryShade: PaletteColor;
        borderShade: PaletteColor;
        cardRed?: PaletteColor;
        cardOrange?: PaletteColor;
        cardYellow?: PaletteColor;
        cardGreen?: PaletteColor;
        cardCyan?: PaletteColor;
        cardBlue?: PaletteColor;
        cardMagneta?: PaletteColor;
        cardCrimson?: PaletteColor;
        cardSilver?: PaletteColor;
        cardBronze?: PaletteColor;
    }
}

const muiTheme = createMuiTheme({
    //Main theming options(Colors and Type etc.)
    palette: {
        background: {
            default: "#ffffff",
        },
        primary: {
            main: "#1FAF8C",
            light: "#D2EFE8",
        },
        secondary: {
            main: "#121738",
            dark: "#21243C",
            light: "#2F3455",
        },
        text: {
            primary: "#121738",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
        },
        secondaryShade: {
            main: "#F0F2F8",
        },
        borderShade: {
            main: "rgba(224, 224, 224, 1)",
        },
        error: {
            main: "#E84848",
            dark: "#C22323",
            light: "#F6DCDC",
        },
        success: {
            main: "#1FAF8C",
            dark: "#14553E",
            light: "#EBF7F3",
        },
        warning: {
            main: "#DF9132",
            dark: "#6A4C16",
            light: "#FDF5E7",
        },
        info: {
            main: "#3484E0",
            dark: "#121738",
            light: "#EAF4FC",
        },
        cardRed: {
            main: "#CA2D23",
        },
        cardOrange: {
            main: "#DD752A",
        },
        cardYellow: {
            main: "#D3B619",
        },
        cardGreen: {
            main: "#78AF1F",
        },
        cardCyan: {
            main: "#27B5BE",
        },
        cardBlue: {
            main: "#6361B2",
        },
        cardMagneta: {
            main: "#AA3BA6",
        },
        cardCrimson: {
            main: "#D33057",
        },
        cardSilver: {
            main: "#818390",
        },
        cardBronze: {
            main: "#A77A50",
        },
        cardBrightYellow: {
            main: "#FFF700",
        },
        cardAqua: {
            main: "#0DFFFF",
        },
        cardPurple: {
            main: "#AE0CFF",
        },
        cardBrightOrange: {
            main: "#FF7A15",
        },
        cardBrightRed: {
            main: "#FF0000",
        },
        cardLightGreen: {
            main: "#007919",
        },
        cardNightBlue: {
            main: "#033F77",
        },
    },
    typography: {
        fontFamily: "'Montserrat',sans-serif",
        h1: {
            fontWeight: 700,
            fontSize: "4rem",
            letterSpacing: 0.5,

            "@media (max-width:900px)": {
                fontSize: "3rem",
            },
        },
        h2: {
            fontSize: "3rem",
            fontWeight: 700,

            "@media (max-width:900px)": {
                fontSize: "2.3rem",
            },
        },
        h3: {
            fontWeight: 700,
            fontSize: "2rem",

            "@media (max-width:900px)": {
                fontSize: "1.5rem",
            },
        },
        h4: {
            fontWeight: 700,
            fontSize: "1.6rem",

            "@media (max-width:900px)": {
                fontSize: "1.2rem",
            },
        },
        h5: {
            fontWeight: 700,
            fontSize: "1.2rem",
            letterSpacing: 0.5,

            "@media (max-width:900px)": {
                fontSize: "0.9rem",
            },
        },
        h6: {
            fontWeight: 700,
            fontSize: "1rem",
        },
        subtitle1: {
            fontWeight: 700,
        },
        body2: {
            fontSize: "0.9rem",
        },
        overline: {
            fontSize: "0.9rem",
            textTransform: "uppercase",
        },
    },
    shape: {
        borderRadius: 8,
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

export const verbalTheme = {
    ...muiTheme,
    //Sets Props at a global level
    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The default props to change
            disableRipple: true, // No more ripple, on the whole application 💣!
        },
        MuiTab: {
            disableRipple: true,
        },
    },
    //Override Styling on components at a global level
    overrides: {
        MuiContainer: {
            // maxWidthMd: {
            //     maxWidth: "1100px !important",
            // },
        },

        MuiCardContent: {
            root: {
                padding: muiTheme.spacing(2),
            },
        },

        MuiTableCell: {
            head: {
                fontWeight: 700,
            },
        },

        MuiDialogTitle: {
            root: {
                margin: 0,
                padding: muiTheme.spacing(3),
                paddingBottom: muiTheme.spacing(1),
            },
        },

        MuiDialogContent: {
            root: {
                padding: muiTheme.spacing(3),
                paddingTop: muiTheme.spacing(1),
                paddingBottom: muiTheme.spacing(1),
            },
        },

        MuiDialogActions: {
            root: {
                padding: muiTheme.spacing(3),
                paddingTop: muiTheme.spacing(1),
            },
        },

        MuiDialog: {
            container: {
                padding: muiTheme.spacing(1),
            },
            paperFullScreen: {
                borderRadius: muiTheme.shape,
            },
        },

        MuiBackdrop: {
            root: {
                backgroundColor: "rgba(0,0,0,0.7)",
            },
        },

        MuiInputBase: {
            root: {
                minHeight: "52px",
            },
        },
        MuiInputLabel: {
            root: {
                top: "-1px",
            },
            formControl: {
                top: "-1px",
            },
        },
        MuiAlert: {
            root: {
                fontWeight: "700",
            },
            standardError: {
                color: muiTheme.palette.error.main,
                backgroundColor: muiTheme.palette.error.light,
            },
        },
        MuiDrawer: {
            paper: {
                backgroundColor: muiTheme.palette.secondaryShade.main,
            },
        },
        MuiPaper: {
            root: {
                border: "0",
                boxShadow: "0px 0px 22px rgba(0,0,0,0.08)",
            },
            elevation1: {
                boxShadow: "0px 0px 22px rgba(0,0,0,0.08)",
            },
        },

        //BUTTON STYLES

        MuiButton: {
            sizeSmall: {
                paddingLeft: muiTheme.spacing(1.5),
                paddingRight: muiTheme.spacing(1.5),
            },
            sizeLarge: {
                height: 52,
                fontSize: 16,
            },
            contained: {
                backgroundColor: muiTheme.palette.secondaryShade.main,
                color: muiTheme.palette.text.primary,
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
                "&:hover": {
                    boxShadow: "none",
                },
            },
            containedPrimary: {
                backgroundColor: muiTheme.palette.primary.main,
                color: "white",
                "&:hover": {
                    boxShadow: "none",
                    backgroundColor: muiTheme.palette.primary.dark,
                },
            },
            containedSecondary: {
                backgroundColor: muiTheme.palette.secondary.main,
                color: "white",
                "&:hover": {
                    boxShadow: "none",
                    backgroundColor: muiTheme.palette.secondary.dark,
                },
            },
            label: {
                textTransform: "none",
                //color: "white",
                fontWeight: 700,
            },
        },
        MuiTabs: {
            root: {
                //borderBottom: "1px solid rgba(224, 224, 224, 1)",
                //borderTop: "1px solid #D0D1D7",
            },
            flexContainer: {
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                padding: muiTheme.spacing(0, 2),
            },
            indicator: {
                backgroundColor: muiTheme.palette.common.white,
                height: 0,
            },
        },
        MuiTab: {
            root: {
                fontWeight: "700",
                textTransform: "none",
                marginRight: muiTheme.spacing(0.25),
                borderRadius: muiTheme.spacing(1, 1, 0, 0),
                "&.Mui-selected": {
                    borderTop: "1px solid rgba(224, 224, 224, 1)",
                    borderLeft: "1px solid rgba(224, 224, 224, 1)",
                    borderRight: "1px solid rgba(224, 224, 224, 1)",
                    borderBottom: "1px solid white",
                    backgroundColor: muiTheme.palette.common.white,
                    marginBottom: "-1px",
                    // "&:hover": {
                    //     backgroundColor: "#1FAF8C!important",
                    // },
                },
                [muiTheme.breakpoints.down("md")]: {
                    //fontWeight: "400",
                    fontSize: "0.8rem",
                    marginRight: muiTheme.spacing(0.1),
                },
            },
        },
        MuiTabPanel: {
            root: {
                backgroundColor: muiTheme.palette.common.white,
            },
        },
        MuiIconButton: {
            root: {
                padding: muiTheme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#404854",
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: muiTheme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                marginRight: 0,
                "& svg": {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },

        MuiStepper: {
            vertical: {
                paddingTop: muiTheme.spacing(1),
            },
        },

        MuiStepConnector: {
            root: {
                display: "none",
            },
            line: {
                display: "none",
            },
        },

        MuiStep: {
            horizontal: {
                marginRight: muiTheme.spacing(4),
            },
            vertical: {
                marginBottom: muiTheme.spacing(2),
            },
        },

        MuiStepLabel: {
            label: {
                color: muiTheme.palette.secondary.main,
            },
            active: {
                fontWeight: "700!important",
            },
        },

        MuiStepIcon: {
            root: {
                width: "1.1em",
                height: "1.1em",
                fontWeight: "700",
                overflow: "inherit",
            },
            completed: {
                stroke: "none",
                color: muiTheme.palette.primary.main,
            },
            active: {
                color: muiTheme.palette.primary.main,
                fontWeight: "700!important",
            },
            text: {
                fill: muiTheme.palette.common.white,
            },
        },

        MuiStepButton: {
            root: {
                width: "auto",
                textAlign: "left",
            },
            horizontal: {
                padding: muiTheme.spacing(2, 1, 2, 1),
            },
        },
        MuiToggleButton: {
            root: {
                borderColor: muiTheme.palette.primary.main,
                color: muiTheme.palette.primary.main,
                fontWeight: 700,
                "&:hover": {
                    backgroundColor: muiTheme.palette.primary.light,
                    borderColor: muiTheme.palette.primary.main,
                    color: muiTheme.palette.common.white,
                },
                "&.Mui-selected": {
                    backgroundColor: muiTheme.palette.primary.main,
                    borderColor: muiTheme.palette.primary.main,
                    color: muiTheme.palette.common.white,
                    "&:hover": {
                        backgroundColor: "#1FAF8C!important",
                    },
                },
            },
        },
        MuiListItem: {
            gutters: {
                padding: muiTheme.spacing(1, 3),
            },
        },
        MuiListItemAvatar: {
            root: {
                minWidth: "46px",
            },
        },

        MuiLink: {
            hover: {
                cursor: "pointer",
            },
        },
    },
};
