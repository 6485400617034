import React from "react";
import { SessionModeProvider } from "../components/session/sessionMode/contexts/sessionMode.context";
import { get } from "../client/api/public/session";
import { ParticipantOnlineSession } from "../components/session/components/ParticipantOnlineSession";
import { FetchProvider } from "../core/components/crud/contexts/fetch.context";

export const ParticipantInPersonSessionPage = (): JSX.Element => {
    return (
        <SessionModeProvider>
            <FetchProvider noun={"session"} getItem={get}>
                <ParticipantOnlineSession />
            </FetchProvider>
        </SessionModeProvider>
    );
};
