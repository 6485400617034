import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { useAlert } from "../../contexts/alert.context";

const VAlert = (): React.ReactElement => {
    const { alert } = useAlert();
    return (
        <>
            {alert ? (
                <Snackbar key={alert.id} open={Boolean(alert)}>
                    <Alert severity={alert.severity}>{alert.msg}</Alert>
                </Snackbar>
            ) : null}
        </>
    );
};

export default VAlert;
