import React from "react";
import Crud from "../../../core/components/crud/Crud";
import { curriculumCRUDModel } from "./model";
import { list, get, update } from "../../../client/api/curriculum";
import { CurriculumType } from "../../../models/modelTypes";
import { CurriculumListParameters } from "../../../../../common/build/api-parameters/curriculum";
import { useAuth } from "../../../contexts/auth.context";
import { Redirect } from "react-router-dom";
import { AddCurriculum } from "./AddCurriculum";
import { EditCurriculum } from "./EditCurriculum";

export const CurriculumsScreen = (): JSX.Element => {
    const { user, hasPermission } = useAuth();

    if (!user) {
        return <Redirect to="/403" />;
    }

    const params = {
        includeThemes: true,
        includeCollections: true,
    };

    return (
        <Crud<CurriculumType, CurriculumListParameters>
            model={curriculumCRUDModel}
            noun="Story"
            plural="Curriculums"
            getData={list}
            listParams={params}
            itemParams={params}
            getItem={get}
            labelProperty="name"
            addComponent={AddCurriculum}
            editComponent={EditCurriculum}
            searchable
            canAdd={hasPermission("StoryCollectionCreate")}
            updateItem={update}
        />
    );
};
