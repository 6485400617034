import React, { ChangeEvent, MutableRefObject, useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
    AutocompleteRenderGroupParams,
    AutocompleteRenderOptionState,
} from "@material-ui/lab/Autocomplete";
import Spinner from "../../components/layout/Spinner";
import { isString, useField } from "formik";
import { PopperProps } from "@material-ui/core/Popper/Popper";

type Option<T> = {
    value: string;
    text: unknown;
    additional: T;
};

type AsyncInputProps<T> = {
    inputRef?: MutableRefObject<HTMLInputElement | undefined>;
    label: string;
    onSearchChange: (searchTerm: string) => Promise<{ items: T[]; total: number }>;
    onSelected: (record?: T) => void;
    freeSolo: boolean;
    valueProperty: keyof T & string;
    textProperty: keyof T & string;
    renderOption?: (option: Option<T>, state: AutocompleteRenderOptionState) => React.ReactNode;
    renderGroup?: (params: AutocompleteRenderGroupParams) => React.ReactNode;
    groupBy?: (option: Option<T>) => string;
    PopperComponent?: React.ComponentType<PopperProps>;
    ListboxComponent?: React.ComponentType<React.HTMLAttributes<HTMLElement>>;
    error: boolean;
    helperText?: string;
};

export const AsyncInput = <T,>(props: AsyncInputProps<T>): JSX.Element => {
    const {
        inputRef,
        label,
        onSearchChange,
        onSelected,
        valueProperty,
        textProperty,
        freeSolo,
        renderOption,
        renderGroup,
        groupBy,
        PopperComponent,
        ListboxComponent,
        error,
        helperText,
    } = props;

    const [options, setOptions] = useState<Option<T>[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [searching, setSearching] = useState<boolean>(false);
    const [defaultValue, setDefaultValue] = useState<Option<T>>();
    const [fetchError, setFetchError] = useState<boolean>();

    const [fieldProps, , { setValue }] = useField(textProperty);

    const search = async (event: ChangeEvent<unknown>, searchTerm: string) => {
        setSearching(true);
        try {
            const { items } = await onSearchChange(searchTerm);
            const options: Option<T>[] = items.map((item) => ({
                value: `${item[valueProperty]}`,
                text: item[textProperty],
                additional: item,
            }));
            setOptions(options);
        } catch (error) {
            console.error(error);
        }
        setSearching(false);
    };

    useEffect(() => {
        if (!fieldProps.value) {
            setValue("");
            setOptions([]);
        }
    }, [fieldProps.value]);

    useEffect(() => {
        setLoading(true);
        onSearchChange(fieldProps.value)
            .then(({ items }) => {
                setFetchError(false);
                const options: Option<T>[] = items.map(
                    (item): Option<T> => ({
                        value: `${item[valueProperty]}`,
                        text: item[textProperty],
                        additional: item as unknown as T,
                    }),
                );
                setOptions(options);
                const selectedOption = options.find((option) => option.value === `${fieldProps.value}`);
                if (selectedOption) {
                    setDefaultValue(selectedOption);
                }
            })
            .catch(() => {
                setFetchError(true);
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);
    return (
        <>
            {loading && <Spinner />}
            {!loading && (
                <Autocomplete
                    PopperComponent={PopperComponent}
                    freeSolo={freeSolo}
                    id={textProperty}
                    loading={searching}
                    options={options}
                    defaultValue={defaultValue}
                    getOptionSelected={(option, value) => option.value === value.value}
                    getOptionLabel={(option: Option<T>) => (option.text ? (option.text as string) : "")}
                    onChange={(event, newValue: string | Option<T> | null) => {
                        if (newValue === null) {
                            // when a selected value is cleared
                            onSelected();
                        } else if (isString(newValue)) {
                            // When typed value is selected
                            onSearchChange(newValue);
                        } else {
                            // when a full value is selected
                            onSelected(newValue.additional);
                        }
                    }}
                    onInputChange={(event, value) => {
                        if (event !== null) {
                            setValue(value);
                            search(event, value);
                        }
                    }}
                    inputValue={fieldProps.value}
                    renderInput={(params) => {
                        return (
                            <TextField
                                inputRef={inputRef}
                                autoComplete="off"
                                {...fieldProps}
                                {...params}
                                label={label}
                                fullWidth
                                variant="outlined"
                                error={error || fetchError}
                                helperText={fetchError ? `Error fetching ${textProperty}` : helperText}
                            />
                        );
                    }}
                    renderOption={renderOption}
                    renderGroup={renderGroup}
                    groupBy={groupBy}
                    ListboxComponent={ListboxComponent}
                />
            )}
        </>
    );
};
