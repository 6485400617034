import {
    Button,
    Container,
    createStyles,
    DialogContentText,
    FormControlLabel,
    makeStyles,
    Switch,
    Typography,
    withStyles,
} from "@material-ui/core";
import { SessionFetchParameters } from "common/build/api-parameters/session";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { API_URL } from "../../../client/api/rest";
import { updateSessionState } from "../../../client/api/session";
import { useFetch } from "../../../core/components/crud/contexts/fetch.context";
import { useStoryStyles } from "../../../hooks/styles/StoryStyles";
import { useUtilityStyles } from "../../../hooks/styles/UtilityStyles";
import { SessionType } from "../../../models/modelTypes";
import { useBaseLayout } from "../../layout/BaseLayout.context";
import Spinner from "../../layout/Spinner";
import { publicTheme } from "../../layout/themes/public.theme";
import { verbalTheme } from "../../layout/themes/verbal.theme";
import { useSessionMode } from "../../session/sessionMode/contexts/sessionMode.context";
import { Error } from "../../../core/components/Error";
import ConfirmDialog from "../../../core/components/ConfirmDialog";
import { StepperWizard } from "../../../core/components/wizard/StepperWizard";
import WizardStep from "../../../core/components/wizard/WizardStep";
import { ReadStory } from "../../session/sessionMode/wizard/steps/ReadStory";
import { Survey, SurveyType } from "../../session/sessionMode/wizard/steps/Survey";
import { useSetTourState } from "../../../components/tour/Store";
import clsx from "clsx";

export const ClassSessionWizard = (): JSX.Element => {
    const history = useHistory();
    const { item, fetchItem } = useFetch<SessionType>();
    const { addMenuItem, clearMenuItems } = useBaseLayout();
    const [showExitSession, setShowExitSession] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const setTourState = useSetTourState();

    const [hasFacilitatorCompletedSurvey, setHasFacilitatorCompletedSurvey] = useState<boolean>(false);
    const {
        activeStep,
        completedSteps,
        changeStep,
        presentationMode,
        annotationsMode,
        toggleAnnotationsMode,
        togglePresentationMode,
        changePart,
    } = useSessionMode();

    const ref = useRef<HTMLDivElement | null>(null);
    const refPresentationBtn = useRef<HTMLButtonElement | null>(null);

    const { id } = useParams<{ id?: string }>();
    const location = useLocation<{ classroomId: number }>();
    //const [classroomId, setClassroomId] = useState<number>();
    const classroomId = location.state?.classroomId;

    const useStyles = makeStyles(
        createStyles({
            sessionModeLayout: {
                display: "grid",
                gridTemplateColumns: "auto",
                height: "calc(100vh - 71px)",
            },
            storyPanel: {
                overflow: "auto",
            },
            largeContainer: {
                maxWidth: "1500px",
            },
            expDialogHeader: {
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            },
            pill: {
                backgroundColor: publicTheme.palette.themePill.main,
                color: verbalTheme.palette.common.white,
            },
        }),
    );

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("sessionPresentationBtn", refPresentationBtn) }));
    }, [refPresentationBtn]);

    const AnnotationSwitch = withStyles({
        switchBase: {
            color: presentationMode ? "black" : "white",
            "&$checked": {
                color: presentationMode ? "black" : "white",
            },
            "&$checked + $track": {
                backgroundColor: presentationMode ? verbalTheme.palette.primary.main : verbalTheme.palette.primary.main,
                opacity: 1,
            },
        },
        checked: {},
        track: {
            backgroundColor: presentationMode ? "black" : "white",
        },
    })(Switch);

    useEffect(() => {
        ref.current?.scroll(0, 0);
    }, [activeStep]);

    useEffect(() => {
        if (item) {
            changePart(item.currentPart);

            const events = new EventSource(`${API_URL}/session/${item.id}/updates`, {
                withCredentials: true,
            });

            events.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (parseInt(data.part) >= 0) {
                    changePart(data.part);
                }
                if (data.isSurveyInProgress) {
                    changeStep(1);
                }
                if (data.annotationQualityMeasure && data.storyQualityMeasure) {
                    setHasFacilitatorCompletedSurvey(true);
                }
            };

            return () => {
                events.close();
            };
        }
    }, [item]);

    const exitSession = async () => {
        if (id) {
            if (!item?.isOnline) {
                await updateSessionState(parseInt(id), { state: "Complete" });
            }
            //TODO
            history.push(`/classrooms/view/${classroomId}/view/${item?.programmeId}`);
        }
    };

    const classes = useStyles();
    const utilityClasses = useUtilityStyles();
    const storyClasses = useStoryStyles();

    useEffect(() => {
        if (id && parseInt(id) > 0) {
            setLoading(true);
            fetchItem(parseInt(id), { includeStory: true } as SessionFetchParameters)
                .then(() => {
                    setError(false);
                })
                .catch(() => {
                    setError(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setError(true);
        }
    }, [id]);

    useEffect(() => {
        clearMenuItems();

        if (!presentationMode) {
            addMenuItem(
                "ExitSession",
                <div className="c-toolbar-item">
                    <Button
                        variant="text"
                        color="inherit"
                        onClick={() => {
                            if (!item?.isOnline) {
                                setShowExitSession(true);
                            } else {
                                exitSession();
                            }
                        }}
                    >
                        {"End Session"}
                    </Button>
                </div>,
            );
        }

        switch (activeStep) {
            case 0:
                addMenuItem(
                    "ReadingInPerson",
                    <>
                        {!presentationMode && (
                            <div className="c-toolbar-item">
                                <FormControlLabel
                                    control={
                                        <AnnotationSwitch checked={annotationsMode} onChange={toggleAnnotationsMode} />
                                    }
                                    label={
                                        <Typography
                                            variant="body2"
                                            color={presentationMode ? "textPrimary" : "inherit"}
                                            className={utilityClasses.bold}
                                        >
                                            <b>Annotations</b>
                                        </Typography>
                                    }
                                />
                            </div>
                        )}
                        <div className="c-toolbar-item">
                            <Button
                                ref={refPresentationBtn}
                                variant={presentationMode ? "contained" : "text"}
                                color={presentationMode ? "primary" : "inherit"}
                                onClick={togglePresentationMode}
                                className={clsx(
                                    "presentationModeButton",
                                    presentationMode ? "leavePresentationMode" : "enterPresentationMode",
                                )}
                            >
                                {presentationMode ? "Leave" : ""} Presentation Mode
                            </Button>
                        </div>
                    </>,
                );
                break;
        }
    }, [activeStep, presentationMode, annotationsMode, item]);

    if (loading) {
        return <Spinner />;
    }

    if (error) {
        return <Error description={`Unable to load session`} />;
    }

    return (
        <div className={classes.sessionModeLayout}>
            <div className={classes.storyPanel} ref={ref}>
                <Container className={storyClasses.storyContainer}>
                    {showExitSession && (
                        <ConfirmDialog
                            open={showExitSession}
                            title={`End Session`}
                            onConfirm={exitSession}
                            onCancel={() => setShowExitSession(false)}
                            confirmButtonText={"End Session"}
                            cancelButtonText="Return to Session"
                        >
                            <DialogContentText>
                                {`Ending the session will mark the session as complete. Are you sure you want to end this session?`}
                            </DialogContentText>
                        </ConfirmDialog>
                    )}
                    {item && (
                        <StepperWizard
                            orientation="horizontal"
                            activeStep={activeStep}
                            goToStep={changeStep}
                            hideStepper={presentationMode}
                        >
                            <WizardStep identifier={0} heading={"Story"} completed={completedSteps[0]}>
                                <ReadStory session={item} />
                            </WizardStep>
                            <WizardStep identifier={1} heading={"Survey"} completed={completedSteps[1]}>
                                <Survey
                                    classroomId={item?.Programme?.classroomId ?? undefined}
                                    type={SurveyType.Facilitator}
                                    session={item}
                                    hasFacilitatorCompletedSurvey={hasFacilitatorCompletedSurvey}
                                    setHasFacilitatorCompletedSession={setHasFacilitatorCompletedSurvey}
                                />
                            </WizardStep>
                        </StepperWizard>
                    )}
                </Container>
            </div>
        </div>
    );
};
