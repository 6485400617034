import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { InputAdornment, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { useCurriculum } from "../../../../hooks/useCurriculum";
import { Curriculum } from "common/build/prisma/client";
import { useProgramme } from "../../contexts/programme.context";
import { PathwayCard } from "./PathwayCard";
import { StoryCollectionType } from "../../../../models/modelTypes";
import { WizardHeading } from "../../../../core/components/WizardHeading";
import { Search } from "@material-ui/icons";
import { useAuth } from "../../../../contexts/auth.context";

const PathwayStep = (): JSX.Element => {
    const { user } = useAuth();
    const { listAll, getCurriculum, curriculums, curriculumInfo, getAllCurriculums } = useCurriculum();
    const { submitStep, changeStep } = useProgramme();

    const [results, setResults] = useState<StoryCollectionType[]>([]);
    const [searchString, setSearchString] = useState<string>("");

    useEffect(() => {
        listAll();
    }, []);

    const handleChangeCurriculum = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value !== "") {
            const value = parseInt(event.target.value as string);
            getCurriculum(value);
        } else {
            resetAllCurriculums();
        }
    };

    const handleChangeAge = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        if (event.target.value !== "") {
            const value = event.target.value as string;
            getAllCurriculums(value);
        } else {
            listAll().then(() => resetAllCurriculums());
            //resetAllCurriculums();
        }
    };

    useEffect(() => {
        if (curriculumInfo) {
            setResults(curriculumInfo?.StoryCollection);
        }
    }, [curriculumInfo]);

    useEffect(() => {
        resetAllCurriculums();
    }, [curriculums]);

    const resetAllCurriculums = () => {
        if (curriculums) {
            const tempResults: StoryCollectionType[] = [];
            curriculums.forEach((curriculum) => {
                tempResults.push(...curriculum.StoryCollection);
            });
            setResults(tempResults);
        }
    };

    const savePathway = (storyCollectionId: number, ageRange: string) => {
        submitStep({ storyCollectionId: storyCollectionId, ageRange: ageRange });
        changeStep(1);
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(event.target.value);
    };

    useEffect(() => {
        const tempResults: StoryCollectionType[] = [];
        if (searchString && searchString.length > 0) {
            curriculums?.forEach((curriculum) => {
                curriculum.StoryCollection?.forEach((pathway) => {
                    if (pathway.name?.toLowerCase().includes(searchString.toLowerCase())) {
                        tempResults.push(pathway);
                    }
                });
            });
            setResults(tempResults);
        } else {
            resetAllCurriculums();
        }
    }, [searchString]);

    const ageRanges = [
        ...(() => {
            const ageRanges: string[] = [];
            for (let i = 4; i < 17; i++) {
                ageRanges.push(`${i}-${i + 1}`);
            }
            return ageRanges;
        })(),
    ];

    if (!user?.isSchool) ageRanges.push("18+");

    return (
        <>
            <WizardHeading overline={"Schedule a programme"} heading={"Select Collection"} />
            <Grid container spacing={3} justify={"space-between"} className="selectCollectionList">
                <Grid item xs={6} sm={4} className="storyCollectionFilter1">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="curriculums">Filter by Curriculum</InputLabel>
                        <Select
                            fullWidth
                            labelId="curriculums"
                            label="Filter by Curriculum"
                            name="curriculums"
                            onChange={handleChangeCurriculum}
                            variant={"outlined"}
                        >
                            <MenuItem value={""}>All Curriculums</MenuItem>
                            {curriculums?.map((curriculum: Curriculum) => (
                                <MenuItem key={curriculum.id} value={curriculum.id}>
                                    {curriculum.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6} sm={4} className="storyCollectionFilter2">
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="ages">Filter by Age Range</InputLabel>
                        <Select
                            fullWidth
                            labelId="ages"
                            label="Filter by Age Range"
                            name="ages"
                            onChange={handleChangeAge}
                            variant={"outlined"}
                        >
                            <MenuItem key={0} value={""} disabled>
                                Please select
                            </MenuItem>
                            {ageRanges.map((ageRange) => (
                                <MenuItem key={ageRange} value={ageRange}>
                                    {ageRange}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item sm={4} xs={6} className="storyCollectionFilter3">
                    <TextField
                        autoFocus
                        id="standard-search"
                        type="search"
                        name="searchString"
                        placeholder="Search"
                        onChange={handleSearch}
                        fullWidth
                        value={searchString}
                        variant={"outlined"}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position={"start"}>
                                    <Search />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                {results?.map((storyCollection: StoryCollectionType) => (
                    <PathwayCard key={storyCollection.id} storyCollection={storyCollection} save={savePathway} />
                ))}
            </Grid>
        </>
    );
};

export default PathwayStep;
