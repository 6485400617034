import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { SpinWhileFetching } from "../../../common/SpinWhileFetching";
import { HeadlineFigureWidget } from "../../../dashboard/Widgets/Headlines/HeadlineFigureWidget";
import { ClassroomStats } from "common/build/api-parameters/users";
import { useFetch } from "../../../../core/components/crud/contexts/fetch.context";

export const ClassActiveProgWidget: FC = () => {
    const { item } = useFetch<ClassroomStats>();
    const activeCount = item?.activeProgrammes || 0;
    const completedCount = item?.completeProgrammes || 0;
    return (
        <>
            <HeadlineFigureWidget header="Programmes In Progress">
                <Box>
                    <SpinWhileFetching>
                        <Typography variant="h2">{activeCount}</Typography>
                    </SpinWhileFetching>
                </Box>
                <Box>
                    <Typography variant="body1">Programmes Completed</Typography>
                </Box>
                <Box>
                    <SpinWhileFetching>
                        <Typography variant="h2">{completedCount}</Typography>
                    </SpinWhileFetching>
                </Box>
                <Box></Box>
            </HeadlineFigureWidget>
        </>
    );
};
