import React, { useState, useEffect, useRef } from "react";
import { Typography, Box, Button, Grid } from "@material-ui/core";
import { ParticipantType, SessionType } from "../../../../../models/modelTypes";
import SurveyRating from "../../../../../core/components/SurveyRating";
import { CustomIconContainer } from "../../../../../core/components/survey/Icons";
import { updateSessionState } from "../../../../../client/api/session";
import { completeParticipantSurvey } from "../../../../../client/api/public/session";
import { useHistory } from "react-router-dom";
import SessionCompleteImg from "../../../../../img/session-complete.svg";
import { Alert } from "@material-ui/lab";
import { get } from "../../../../../client/api/public/participant";
import { useSessionMode } from "../../contexts/sessionMode.context";
import { verbalTheme } from "../../../../layout/themes/verbal.theme";
import { useAuth } from "../../../../../contexts/auth.context";
import { useSetTourState } from "../../../../tour/Store";
import { useTrackedTourState } from "../../../../tour/Store";

export enum SurveyType {
    Facilitator = "Facilitator",
    Participant = "Participant",
}

export type SurveyProps = {
    type: string;
    session?: SessionType;
    participantId?: number;
    hasFacilitatorCompletedSurvey?: boolean;
    classroomId?: number;
    setSurveyDone?: (done: boolean) => void;
    setHasFacilitatorCompletedSession?: (completed: boolean) => void;
};

export const Survey = (props: SurveyProps): JSX.Element => {
    const {
        type,
        session,
        participantId,
        setSurveyDone,
        hasFacilitatorCompletedSurvey,
        setHasFacilitatorCompletedSession,
        classroomId,
    } = props;
    const [storyQualityMeasure, setStoryQualityMeasure] = useState<number>();
    const [annotationQualityMeasure, setAnnotationQualityMeasure] = useState<number>();
    const [wellbeingQualityMeasure, setWellbeingQualityMeasure] = useState<number>();
    const [sessionComplete, setSessionComplete] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<boolean>(false);
    const [hasAlreadyCompletedSurvey, setHasAlreadyCompletedSurvey] = useState<boolean>(false);
    const history = useHistory();
    const { submitStep, changeStep } = useSessionMode();
    const { hasPermission } = useAuth();
    const setTourState = useSetTourState();
    const tourState = useTrackedTourState();
    const refSaveAnswersBtn = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        setTourState((prev) => ({ ...prev, refs: prev.refs.set("surveySaveAnswersBtn", refSaveAnswersBtn) }));
    }, [refSaveAnswersBtn]);

    useEffect(() => {
        if (participantId && session) {
            get(participantId, { sessionId: session.id }).then((participant: ParticipantType) => {
                if (participant.ParticipantToSession && participant.ParticipantToSession.length > 0) {
                    setHasAlreadyCompletedSurvey(true);
                }
            });
        }
    }, [participantId, session]);

    useEffect(() => {
        if (
            setHasFacilitatorCompletedSession &&
            session &&
            session.annotationQualityMeasure &&
            session.storyQualityMeasure
        ) {
            setHasFacilitatorCompletedSession(true);
        }
    }, [session]);

    if (!session || !session.Programme || !session.Programme.surveyQuestions) {
        return (
            <Grid item xs={12}>
                <Typography color="error">{`Unable to load survey`}</Typography>
            </Grid>
        );
    }

    const saveSurvey = () => {
        if (tourState.openTour) {
            setValidationError(false);
            if (type === SurveyType.Facilitator) {
                setSessionComplete(true);
                if (setHasFacilitatorCompletedSession) {
                    setHasFacilitatorCompletedSession(true);
                }
            } else {
                setSessionComplete(true);
                if (setSurveyDone) {
                    setSurveyDone(true);
                }
            }
            return;
        }
        if (type === SurveyType.Facilitator) {
            if (storyQualityMeasure && annotationQualityMeasure) {
                updateSessionState(session.id, { storyQualityMeasure, annotationQualityMeasure }).then(() => {
                    setValidationError(false);
                    setSessionComplete(true);
                    if (setHasFacilitatorCompletedSession) {
                        setHasFacilitatorCompletedSession(true);
                    }
                });
            } else {
                setValidationError(true);
            }
        } else {
            if (participantId && storyQualityMeasure && annotationQualityMeasure && wellbeingQualityMeasure) {
                completeParticipantSurvey(session.id, {
                    participantId: participantId,
                    storyQualityMeasure,
                    annotationQualityMeasure,
                    wellbeingQualityMeasure,
                }).then(() => {
                    setValidationError(false);
                    setSessionComplete(true);
                    if (setSurveyDone) {
                        setSurveyDone(true);
                    }
                });
            } else {
                setValidationError(true);
            }
        }
    };

    const getQuestion = (name: string, index: number) => {
        if (!session?.Programme?.surveyQuestions) return "";

        return (session?.Programme?.surveyQuestions as Record<string, string[]>)[name][index];
    };

    return (
        <Box mb={3} maxWidth={"700px"}>
            {!sessionComplete && !hasAlreadyCompletedSurvey && !hasFacilitatorCompletedSurvey ? (
                <>
                    <Box mb={3}>
                        <Typography variant="h2" color="textPrimary" align="left">
                            {type === SurveyType.Facilitator ? "Teacher" : "Participant"} Survey
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <Typography variant="body1" color="textPrimary" align="left">
                            Please take some time to answer these quick questions.
                        </Typography>
                    </Box>
                    <Box mb={3}>
                        <>
                            {type === SurveyType.Facilitator && (
                                <>
                                    <Box mb={4}>
                                        <Box mb={2}>
                                            <Typography variant="h6" color="textPrimary" align="left">
                                                1. {getQuestion("facilitator", 0)}
                                            </Typography>
                                        </Box>
                                        <SurveyRating
                                            name="story-quality"
                                            value={storyQualityMeasure}
                                            max={5}
                                            IconContainerComponent={(props) => (
                                                <CustomIconContainer name={"number"} {...props} />
                                            )}
                                            onChange={(_, value) => {
                                                setStoryQualityMeasure(value ?? 1);
                                            }}
                                            minLabel={"Not at all engaging"}
                                            maxLabel={"Extremely engaging"}
                                            className="storyQualitySurvey"
                                        />
                                    </Box>
                                    <Box mb={4}>
                                        <Box mb={2}>
                                            <Typography variant="h6" color="textPrimary" align="left">
                                                2. {getQuestion("facilitator", 1)}
                                            </Typography>
                                        </Box>
                                        <SurveyRating
                                            name="annotation-quality"
                                            value={annotationQualityMeasure}
                                            max={5}
                                            IconContainerComponent={(props) => (
                                                <CustomIconContainer name={"number"} {...props} />
                                            )}
                                            onChange={(_, value) => {
                                                setAnnotationQualityMeasure(value ?? 1);
                                            }}
                                            minLabel={"Not effective at all"}
                                            maxLabel={"Extremely effective"}
                                            className="annotationQualitySurvey"
                                        />
                                    </Box>
                                </>
                            )}
                            {type === SurveyType.Participant && (
                                <>
                                    {session.Programme.ageRange !== "18+" ? (
                                        <>
                                            <Box mb={4}>
                                                <Box mb={2}>
                                                    <Typography variant="h6" color="textPrimary" align="left">
                                                        1. {getQuestion("childParticipant", 0)}
                                                    </Typography>
                                                </Box>
                                                <SurveyRating
                                                    name="story-quality"
                                                    value={storyQualityMeasure}
                                                    max={5}
                                                    onChange={(_, value) => {
                                                        setStoryQualityMeasure(value ?? 1);
                                                    }}
                                                />
                                            </Box>
                                            <Box mb={4}>
                                                <Box mb={2}>
                                                    <Typography variant="h6" color="textPrimary" align="left">
                                                        2. {getQuestion("childParticipant", 1)}
                                                    </Typography>
                                                </Box>
                                                <SurveyRating
                                                    name="annotation-quality"
                                                    value={annotationQualityMeasure}
                                                    max={5}
                                                    onChange={(_, value) => {
                                                        setAnnotationQualityMeasure(value ?? 1);
                                                    }}
                                                />
                                            </Box>
                                            <Box mb={4}>
                                                <Box mb={2}>
                                                    <Typography variant="h6" color="textPrimary" align="left">
                                                        3. {getQuestion("childParticipant", 2)}
                                                    </Typography>
                                                </Box>
                                                <SurveyRating
                                                    name="wellbeing-quality"
                                                    value={wellbeingQualityMeasure}
                                                    max={5}
                                                    onChange={(_, value) => {
                                                        setWellbeingQualityMeasure(value ?? 1);
                                                    }}
                                                    IconContainerComponent={(props) => (
                                                        <CustomIconContainer name={"smiley"} {...props} />
                                                    )}
                                                    minLabel={"Very Sad"}
                                                    maxLabel={"Very Happy"}
                                                />
                                            </Box>
                                        </>
                                    ) : (
                                        <>
                                            <Box mb={4}>
                                                <Box mb={2}>
                                                    <Typography variant="h6" color="textPrimary" align="left">
                                                        1. {getQuestion("adultParticipant", 0)}
                                                    </Typography>
                                                </Box>
                                                <SurveyRating
                                                    name="story-quality"
                                                    value={storyQualityMeasure}
                                                    max={5}
                                                    onChange={(_, value) => {
                                                        setStoryQualityMeasure(value ?? 1);
                                                    }}
                                                />
                                            </Box>
                                            <Box mb={4}>
                                                <Box mb={2}>
                                                    <Typography variant="h6" color="textPrimary" align="left">
                                                        2. {getQuestion("adultParticipant", 1)}
                                                    </Typography>
                                                </Box>
                                                <SurveyRating
                                                    name="annotation-quality"
                                                    value={annotationQualityMeasure}
                                                    max={5}
                                                    onChange={(_, value) => {
                                                        setAnnotationQualityMeasure(value ?? 1);
                                                    }}
                                                />
                                            </Box>
                                            <Box mb={4}>
                                                <Box mb={2}>
                                                    <Typography variant="h6" color="textPrimary" align="left">
                                                        3. {getQuestion("adultParticipant", 2)}
                                                    </Typography>
                                                </Box>
                                                <SurveyRating
                                                    name="wellbeing-quality"
                                                    value={wellbeingQualityMeasure}
                                                    max={5}
                                                    onChange={(_, value) => {
                                                        setWellbeingQualityMeasure(value ?? 1);
                                                    }}
                                                    IconContainerComponent={(props) => (
                                                        <CustomIconContainer name={"number"} {...props} />
                                                    )}
                                                    minLabel={"Very Low"}
                                                    maxLabel={"Very High"}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    </Box>
                    {validationError && (
                        <Box mb={2}>
                            <Alert severity="error">Please answer all questions before submitting the survey</Alert>
                        </Box>
                    )}
                    <Box display="flex" justifyContent="flex-end">
                        <Button
                            ref={refSaveAnswersBtn}
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={saveSurvey}
                            className="saveAnswersBtn"
                        >
                            Save Answers
                        </Button>
                    </Box>
                </>
            ) : (
                <>
                    <Box display={"grid"} justifyContent={"center"} mb={3} className="sessionCompleteImgContainer">
                        <Box width={120} textAlign={"center"}>
                            <img src={SessionCompleteImg} />
                        </Box>
                    </Box>
                    <Box mb={3} textAlign={"center"}>
                        <Typography variant="h3" color="textPrimary">
                            Session Complete
                        </Typography>
                    </Box>
                    <Box mb={3} textAlign={"center"}>
                        {type === SurveyType.Facilitator ? (
                            <Typography variant="subtitle1" color="textPrimary">
                                Thank you for delivering today&apos;s session.{" "}
                                {session?.isOnline
                                    ? "Please wait for all participants to submit their survey&apos;s before ending the session"
                                    : ""}
                                .
                            </Typography>
                        ) : (
                            <>
                                <Typography variant="subtitle1" color="textPrimary" gutterBottom>
                                    Thanks so much for taking part in today&apos;s session and sending us what you
                                    thought, we really hope you enjoyed it and look forward to welcoming you to the next
                                    session.
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    Please feel free to stay on and chat until your facilitator ends the session.
                                </Typography>
                            </>
                        )}
                    </Box>
                    <Box display="flex" justifyContent="center" className="sessionCompleteOptions">
                        <Button
                            style={{ margin: verbalTheme.spacing(2) }}
                            variant="contained"
                            color="primary"
                            onClick={async () => {
                                if (type === SurveyType.Facilitator) {
                                    if (session?.isOnline) {
                                        submitStep();
                                        changeStep(3);
                                    } else {
                                        await updateSessionState(session.id, { state: "Complete" });
                                        if (classroomId) {
                                            history.push(
                                                `/classrooms/view/${classroomId}/view/${session?.programmeId}`,
                                            );
                                        } else {
                                            history.push(`/programmes/view/${session?.programmeId}`);
                                        }
                                    }
                                } else {
                                    history.push("/participant/dashboard");
                                }
                            }}
                        >
                            {type === SurveyType.Facilitator
                                ? session?.isOnline
                                    ? "To Lobby"
                                    : "End this session and return to all sessions"
                                : "Return to Dashboard"}
                        </Button>

                        {hasPermission("ProgrammeReportView") && !session.isOnline ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ margin: verbalTheme.spacing(2) }}
                                onClick={async () => {
                                    await updateSessionState(session.id, { state: "Complete" });
                                    if (classroomId) {
                                        history.push(`/classrooms/view/${classroomId}`, {
                                            startCheckIn: true,
                                            checkinSessionId: session.id,
                                        });
                                    } else {
                                        history.push(`/programmes/view/${session?.programmeId}?tab=data`);
                                    }
                                }}
                            >
                                {classroomId
                                    ? "Take class mood tracker"
                                    : "End this session and view the data for this programme"}
                            </Button>
                        ) : null}

                        {type === SurveyType.Facilitator && !session.isOnline ? (
                            <Button
                                variant="contained"
                                style={{ margin: verbalTheme.spacing(2) }}
                                onClick={async () => {
                                    await updateSessionState(session.id, { state: "Complete" });
                                    history.push(`/home`);
                                }}
                            >
                                End this session and return to the Home Screen
                            </Button>
                        ) : null}
                    </Box>
                </>
            )}
        </Box>
    );
};
