import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { VTooltip } from "../../../core/components/VTooltip";
import { useMobile } from "../../../hooks/useMobile";
import { MoodType } from "../../../models/modelTypes";
import { verbalTheme } from "../../layout/themes/verbal.theme";

type AverageWellbeingBarProps = {
    allMoods: MoodType[];
    positiveMoods: MoodType[];
    negativeMoods: MoodType[];
    neutralMoods: MoodType[];
};

export const AverageWellbeingBar = (props: AverageWellbeingBarProps): JSX.Element => {
    const { allMoods, positiveMoods, negativeMoods, neutralMoods } = props;
    const { isMobile, isTablet } = useMobile();

    const [openTooltip, setOpenTooltip] = useState<Map<string, boolean>>(
        new Map([
            ["positive", false],
            ["negative", false],
            ["neutral", false],
        ]),
    );

    let positivePercent = Math.round((100 / allMoods.length) * positiveMoods.length);
    let negativePercent = Math.round((100 / allMoods.length) * negativeMoods.length);
    let neutralPercent = Math.round((100 / allMoods.length) * neutralMoods.length);

    if (positivePercent + negativePercent + neutralPercent !== 100) {
        //Fix 3 values not equaling 100% in the UI
        if (negativeMoods.length > 0) {
            negativePercent = 100 - (positivePercent + neutralPercent);
        } else if (neutralMoods.length > 0) {
            neutralPercent = 100 - positivePercent;
        } else {
            positivePercent = 100;
        }
    }

    return (
        <Box
            margin={2}
            marginBottom={0}
            marginTop={1}
            display={"flex"}
            flexDirection="row"
            justifyContent={"space-between"}
        >
            {positiveMoods && positiveMoods.length > 0 ? (
                <VTooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    disableHoverListener
                    disableFocusListener
                    title={!isTablet && isMobile ? `${positivePercent}%` : ""}
                    open={openTooltip.get("positive")}
                    onOpen={() => setOpenTooltip(new Map(openTooltip.set("positive", true)))}
                    onClose={() => setOpenTooltip(new Map(openTooltip.set("positive", false)))}
                >
                    <Box
                        borderRadius={`8px ${negativeMoods?.length > 0 || neutralMoods.length > 0 ? "0" : "8px"} ${
                            negativeMoods?.length > 0 || neutralMoods.length > 0 ? "0" : "8px"
                        } 8px`}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        bgcolor={verbalTheme.palette.primary.main}
                        width="100%"
                        minWidth={`${positivePercent}%`}
                        style={{ color: verbalTheme.palette.common.white }}
                        onClick={() =>
                            setOpenTooltip(new Map(openTooltip.set("positive", !openTooltip.get("positive"))))
                        }
                    >
                        <span
                            style={{
                                fontSize: `${!isTablet && isMobile ? "24px" : "32px"}`,
                                paddingRight: !isTablet && isMobile ? 0 : verbalTheme.spacing(1),
                            }}
                        >
                            &#128513;
                        </span>{" "}
                        {!isTablet && isMobile ? null : `${positivePercent}%`}
                    </Box>
                </VTooltip>
            ) : null}

            {neutralMoods && neutralMoods.length > 0 ? (
                <VTooltip
                    disableHoverListener
                    disableFocusListener
                    title={!isTablet && isMobile ? `${neutralPercent}%` : ""}
                    open={openTooltip.get("neutral")}
                    onOpen={() => setOpenTooltip(new Map(openTooltip.set("neutral", true)))}
                    onClose={() => setOpenTooltip(new Map(openTooltip.set("neutral", false)))}
                >
                    <Box
                        borderRadius={`${positiveMoods?.length > 0 ? "0" : "8px"} ${
                            negativeMoods?.length > 0 ? "0" : "8px"
                        } ${negativeMoods?.length > 0 ? "0" : "8px"} ${positiveMoods?.length > 0 ? "0" : "8px"} `}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        bgcolor={verbalTheme.palette.grey[500]}
                        width="100%"
                        minWidth={`${neutralPercent}%`}
                        style={{ color: verbalTheme.palette.common.white }}
                        onClick={() => setOpenTooltip(new Map(openTooltip.set("neutral", !openTooltip.get("neutral"))))}
                    >
                        <span
                            style={{
                                fontSize: `${!isTablet && isMobile ? "24px" : "32px"}`,
                                paddingRight: !isTablet && isMobile ? 0 : verbalTheme.spacing(1),
                            }}
                        >
                            &#128528;
                        </span>
                        {!isTablet && isMobile ? null : `${neutralPercent}%`}
                    </Box>
                </VTooltip>
            ) : null}

            {negativeMoods && negativeMoods.length > 0 ? (
                <VTooltip
                    title={!isTablet && isMobile ? `${negativePercent}%` : ""}
                    disableHoverListener
                    disableFocusListener
                    open={openTooltip.get("negative")}
                    onOpen={() => setOpenTooltip(new Map(openTooltip.set("negative", true)))}
                    onClose={() => setOpenTooltip(new Map(openTooltip.set("negative", false)))}
                >
                    <Box
                        borderRadius={`${positiveMoods?.length > 0 || neutralMoods?.length > 0 ? "0" : "8px"} 8px 8px ${
                            positiveMoods?.length > 0 || neutralMoods?.length > 0 ? "0" : "8px"
                        }`}
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        bgcolor={verbalTheme.palette.error.main}
                        width="100%"
                        minWidth={`${negativePercent}%`}
                        style={{ color: verbalTheme.palette.common.white }}
                        onClick={() =>
                            setOpenTooltip(new Map(openTooltip.set("negative", !openTooltip.get("negative"))))
                        }
                    >
                        <span
                            style={{
                                fontSize: `${!isTablet && isMobile ? "24px" : "32px"}`,
                                paddingRight: !isTablet && isMobile ? 0 : verbalTheme.spacing(1),
                            }}
                        >
                            &#128532;
                        </span>
                        {!isTablet && isMobile ? null : `${negativePercent}%`}
                    </Box>
                </VTooltip>
            ) : null}
        </Box>
    );
};
