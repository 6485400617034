import React from "react";
import { FetchProvider } from "../../../core/components/crud/contexts/fetch.context";
import { get } from "../../../client/api/session";
import { SessionModeProvider } from "./contexts/sessionMode.context";
import { SessionModeWizard } from "./wizard/SessionModeWizard";
import { SessionFetchParameters } from "common/src/api-parameters/session";

export const SessionModeScreen = (): JSX.Element => {
    return (
        <SessionModeProvider>
            <FetchProvider noun={"session"} getItem={get} includes={{ includeStory: true } as SessionFetchParameters}>
                <SessionModeWizard />
            </FetchProvider>
        </SessionModeProvider>
    );
};
export default SessionModeScreen;
