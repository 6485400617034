import { ProgrammeType } from "../../models/modelTypes";
import { getData } from "./rest";

type ReportData = {
    x: string;
    y: number;
};

export type AttendanceData = {
    session: number;
    attendance: number;
};

export type WellbeingData = {
    session: string;
    wellbeing: number;
};

type ProgrammeData = {
    pre: number;
    post: number;
};

type ProgrammeSurveyData = {
    expected: number;
    actual: number;
};

export type ProgrammeReportData = {
    programme: ProgrammeType;
    attendance: AttendanceData[];
    genderBreakdown: ReportData[];
    wellbeingBreakdown: WellbeingData[];
    programmeWellbeing: ProgrammeData;
    resilience: ProgrammeData;
    senseOfCommunity: ProgrammeData;
    likelyToRecommend: number;
    improvedMentalHealth: number;
    newPerspectives: number;
    optedOut: number;
    preProgrammeResponses: ProgrammeSurveyData;
    postProgrammeResponses: ProgrammeSurveyData;
};

export const getProgrammeReportData = async (id: number): Promise<ProgrammeReportData> =>
    await getData(`/programme-report/${id}`);
